import {multiPolygon} from "@turf/helpers";
import {cutPolygons} from "../../../utils/map";
import SelectGeoshapeEntities from "../../alerts/creation/SelectGeoshapeEntities";
import {Cluster} from "../../../types/api/clusters";
import {useMapLoadingContext} from "../../../context/baseMap/MapLoadingContext";
import {useLazyGetGlobalGeoshapesQuery} from "../../../redux/api/geoshapes";
import {useEventContext} from "../../../context/event/EventContext";

export default function CutEntitySelect({cluster}: { cluster: Cluster }) {

  const {
    getGlobalPolygonsFromCluster,
    onCutPolygons,
  } = useEventContext();
  const [
    isLoadingMap,
    setIsLoadingMap,
  ] = useMapLoadingContext();
  const [getGeoshapes] = useLazyGetGlobalGeoshapesQuery();

  return <SelectGeoshapeEntities
    disabledSelect={isLoadingMap}
    selectGeoShape={async (opt, entity) => {
      setIsLoadingMap(true)
      try {
        const entityPolygon = await getGeoshapes({uuid: opt.globalpolygon}).unwrap();
        const cutterGeometry = multiPolygon(entityPolygon.geometry.coordinates)
        const affectedGeometries = cutPolygons(getGlobalPolygonsFromCluster(cluster.uuid).map(el => ({
          uuid: el.properties.uuid,
          coordinates: el.geometry.coordinates
        })), cutterGeometry)
        await onCutPolygons({
          cutterGeometry,
          affectedGeometries,
          involvedClusters: [cluster.uuid]
        })

      } catch {

      } finally {
        setIsLoadingMap(false)
      }
    }}
  />
}