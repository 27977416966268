import {useTranslation} from 'react-i18next';
import ResponsiveContent from '../../../layout/ResponsiveContent';
import {useNavigate, useParams} from 'react-router-dom';
import {AlertLogs, useGetAlertLogsPaginatedQuery, useGetOutcomeChoicesQuery,} from '../../../redux/api/alertLogs';
import React, {useEffect, useMemo, useState} from 'react';
import {Col, Pagination, Row, Space, Table} from 'antd';
import {ColumnsType} from 'antd/lib/table';
import {DocumentsTableColumnHeader} from '../../../components/documents/TablesViews/DocumentsTableColumnHeader';
import _, {capitalize} from 'lodash';
import TableSorter from '../../../components/common/TableSorter';
import {useGetUserProfileQuery} from '../../../redux/api/locales';
import {dateToLocaleString} from '../../../utils/dateParsers';
import {RoundActionButton} from '../../../components/event/eventMovingHistory/MovingHistoryItemDrawer';
import {fromFeToBeSorter} from '../../../utils/parser';
import {PaginationConfig} from 'antd/lib/pagination';
import {OutcomeFilter} from './filters/OutcomeFilter';
import DateEditFilter from './filters/DateEditFilter';
import {
  AlertsLogTableFilters,
  AlertsLogTableSorters,
  DEFAULT_ALERT_LOGS_TABLE_FILTERS,
  DEFAULT_ALERT_LOGS_TABLE_SORTERS,
} from '../../../types/internal/tables/alertLogs';
import SearchFilter from '../../../components/common/filters/SearchFilter';
import {isAlertView} from "../../../components/Authentication/permissions";
import RefreshAlertSource from "./RefreshAlertSource";
import DataSourceLastUpdate from "./DataSourceLastUpdate";
import {skipToken} from "@reduxjs/toolkit/query";
import dayjs from "dayjs";

const DataSourceDetail = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {uuid: alertSourceUuid} = useParams();

  const [
    pagination,
    setPagination,
  ] = useState<PaginationConfig>({
    current: 1,
    pageSize: +(window.sessionStorage.getItem('alert_logs_page_size') || '10') || 10,
    showSizeChanger: true,
    showTotal: (total, range) => t('table.pageSizeOfTotal', {
      rangeStart: range[0],
      rangeEnd: range[1],
      total,
    }),
    onChange: (page, pageSize) => {
      window.sessionStorage.setItem('alert_logs_page_size', `${pageSize}`);
      setPagination(prevPag => ({...prevPag, current: page, pageSize}));
    },
    style: {textAlign: 'right'},
  });

  const [
    sorters,
    setSorters,
  ] = useState<AlertsLogTableSorters>(DEFAULT_ALERT_LOGS_TABLE_SORTERS);

  const [
    filters,
    setFilters,
  ] = useState<AlertsLogTableFilters>(DEFAULT_ALERT_LOGS_TABLE_FILTERS);


  const
    {
      data,
      isLoading,
      isFetching,
      refetch,
      fulfilledTimeStamp
    } = useGetAlertLogsPaginatedQuery(alertSourceUuid ? {
          page: pagination.current,
          page_size: pagination.pageSize,
          alertsource: alertSourceUuid,
          ...(filters.alert_not_created ? {
            alert_not_created: filters.alert_not_created,
          } : {}),
          ...(filters.date_creation.at(0) ? {
            date_creation_after: dayjs(filters.date_creation.at(0), "YYYY-MM-DD").startOf('day').toISOString()
          } : {}),
          ...(filters.date_creation.at(1) ? {
            date_creation_before: dayjs(filters.date_creation.at(1), "YYYY-MM-DD").endOf('day').toISOString(),
          } : {}),
          ...(filters.outcome ? {
            outcome: filters.outcome.map(labeledValue => labeledValue.value).join(','),
          } : {}),
          ...(!_.isEmpty(fromFeToBeSorter(sorters)) ? {
            ordering: fromFeToBeSorter(sorters),
          } : {}),
        } : skipToken);
  const {data: choices} = useGetOutcomeChoicesQuery();

  const {
    data: userData,
    isFetching: isFetchingUserData,
    isLoading: isLoadingUserData,
  } = useGetUserProfileQuery();

  useEffect(() => {
    if (filters)
      setPagination(pagination => ({...pagination, current: 1}));
  }, [filters]);

  const columns: ColumnsType<AlertLogs> = useMemo(() => (
    [
      {
        key: 'date_creation',
        title: (
          <DocumentsTableColumnHeader
            title={capitalize(t('alerts.dateCreation'))}
            sorter={<TableSorter
              value={sorters.date_creation}
              onChange={type => setSorters(sorters => ({...sorters, date_creation: type}))}
            />}
            filter={<DateEditFilter
              start={filters.date_creation[0]}
              end={filters.date_creation[1]}
              onStartChange={(value) => setFilters(filters => ({
                ...filters,
                date_creation: [value, filters.date_creation[1]],
              }))}
              onEndChange={(value) => setFilters(filters => ({
                ...filters,
                date_creation: [filters.date_creation[0], value],
              }))}
            />}
            isFilterDisabled={_.isEmpty(filters.date_creation.at(0)) && _.isEmpty(filters.date_creation.at(1))}
            onFilterReset={() => setFilters(filters => ({...filters, date_creation: [undefined, undefined]}))}

          />
        ),
        dataIndex: 'date_creation',
        render: (value) => userData ? dateToLocaleString(value, userData?.language, 'HH:mm:ss') : null,
      },
      {
        key: 'alert_not_created',
        title: (
          <DocumentsTableColumnHeader
            title={capitalize(t('alerts.detail'))}
            sorter={<TableSorter
              value={sorters.alert_not_created}
              onChange={type => setSorters(sorters => ({...sorters, alert_not_created: type}))}
            />}
            filter={<SearchFilter
              selectedFilter={filters.alert_not_created || undefined}
              placeholder={`${capitalize(t('alerts.searchAlertDetail'))}...`}
              onChange={value => {
                setFilters(filters => ({...filters, alert_not_created: value}));
              }}
            />}
            isFilterDisabled={_.isEmpty(filters.alert_not_created)}
            onFilterReset={() => setFilters(filters => ({...filters, alert_not_created: null}))}

          />
        ),
        dataIndex: 'alert_not_created',
      },
      {
        key: 'outcome',
        title: (
          <DocumentsTableColumnHeader
            title={capitalize(t('alerts.outcome'))}
            sorter={<TableSorter
              value={sorters.outcome}
              onChange={type => setSorters(sorters => ({...sorters, outcome: type}))}
            />}
            filter={<OutcomeFilter
              activeFilters={filters.outcome || []}
              handleFilterChange={(values) => setFilters(filters => ({...filters, outcome: values}))}
            />}
            isFilterDisabled={_.isEmpty(filters.outcome)}
            onFilterReset={() => setFilters(filters => ({...filters, outcome: []}))}
          />
        ),
        dataIndex: 'outcome',
        render: (text) => choices?.map(choice => ({
          label: choice.text,
          value: choice.label,
        })).find(option => option.value === text)?.label || '',
      },
      ...userData?.type && isAlertView(userData?.type) ? [{
        title: capitalize(t('actions.goToDestination', {destination: t('alerts.alert')})),
        key: 'alert',
        dataIndex: 'alert',
        render: (text: string | null) =>
          !!text && (
            <RoundActionButton
              onClick={(e) => navigate(`/alerts/${text}`)}
            />
          ),
      }] : [],
    ]
  ), [choices, filters.alert_not_created, filters.date_creation, filters.outcome, navigate, sorters.alert_not_created, sorters.date_creation, sorters.outcome, t, userData]);

  return (
    <>
      <ResponsiveContent
        title={t('settings.detailDataSource')}
        goBack={() => navigate('/settings/data-sources')}
        goBackText={t('settings.dataSource', {count: 2})}
        extra={!!alertSourceUuid && <Space>
          <DataSourceLastUpdate key={fulfilledTimeStamp} alertSourceUuid={alertSourceUuid}/>
          <RefreshAlertSource alertSourceUuid={alertSourceUuid} onFinish={refetch}/>
        </Space>}
      >
        <Table
          <AlertLogs>
          columns={columns}
          pagination={false}
          dataSource={data?.results || []}
          rowKey={'uuid'}
          loading={isLoading || isFetching || isLoadingUserData || isFetchingUserData}
          scroll={{x: true}}
        />
        <Row
          style={{marginTop: '1rem'}}
          justify={'center'}
        >
          <Col>
            <Pagination
              total={data?.count}
              {...pagination}
            />
          </Col>
        </Row>
      </ResponsiveContent>
    </>
  );
};

export default DataSourceDetail;