import {Button, ButtonProps, Card, Col, Row, Typography} from 'antd';
import {EditOutlined} from '@ant-design/icons';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import {DummyMapContainer} from '../../common/map/DummyMapContainer';
import {GoogleLayers} from '../../../types/internal/Map';
import {BaseWMSLayer} from '../../common/map/BaseWMSLayer';
import LayerVisibility from './LayerVisibility';
import DeleteLayer from './DeleteLayer';
import {useNavigate} from 'react-router-dom';
import PermissionRoute from '../../Authentication/PermissionRoute';
import {isLayerMapDelete, isLayerMapEdit} from '../../Authentication/permissions';

const {Text} = Typography;
export default function LayerListItem({
                                        viewButton,
                                        editButton,
                                        deleteButton,
                                        isInternal,
                                        isActive,
                                        uuid,
                                        frontendName,
                                        source,
                                        wmsSource,
                                        notes,
                                        name,
                                      }:
                                        {
                                          frontendName: string,
                                          name: string,
                                          source: string,
                                          wmsSource: string,
                                          notes: string,
                                          isInternal: boolean,
                                          isActive: boolean,
                                          uuid: string,
                                          viewButton?: ButtonProps,
                                          editButton?: ButtonProps,
                                          deleteButton?: ButtonProps
                                        }) {

  const {t} = useTranslation();
  const navigate = useNavigate();

  return (
    <Card>
      <Row
        gutter={[24, 24]}
        wrap
        align={'middle'}
      >
        <Col xs={24} sm={12} md={2}>
          <DummyMapContainer
            tileLayer={GoogleLayers.satellite}
            withStyle={{height: '64px', width: '64px'}}
          >
            <BaseWMSLayer
              url={wmsSource}
              layers={name}
              isInternal={isInternal}
            />
          </DummyMapContainer>
        </Col>
        <Col
          xs={24} sm={12} md={5}
          style={{display: 'flex', flexDirection: 'column'}}
        >
          <Text type={'secondary'}>
            {t('layers.name').toUpperCase()}
          </Text>
          <Text ellipsis={{tooltip: capitalize(frontendName || '-')}}>
            {frontendName || '-'}
          </Text>
        </Col>
        <Col
          xs={24} sm={12} md={8}
          style={{display: 'flex', flexDirection: 'column'}}
        >
          <Text type={'secondary'}>
            {t('layers.dataSource').toUpperCase()}
          </Text>
          <Text ellipsis>{source || '-'}</Text>
        </Col>
        <Col xs={24} sm={12} md={5} style={{display: 'flex', flexDirection: 'column'}}>
          <Text type={'secondary'}>
            {t('layers.notes').toUpperCase()}
          </Text>
          <Text ellipsis={{tooltip: capitalize(notes) || '-'}}>
            {notes || '-'}
          </Text>
        </Col>
        <Col
          xs={24} sm={24} md={4}
          style={{textAlign: 'right'}}
        >
          <PermissionRoute isAuthorized={isLayerMapEdit}>
            <LayerVisibility
              isActive={isActive}
              uuid={uuid}
              button={viewButton}
            />

            <Button
              size={'large'}
              type={'text'}
              icon={<EditOutlined/>}
              onClick={() => navigate(`${uuid}/edit`)}
              {...editButton}/>
          </PermissionRoute>
          <PermissionRoute isAuthorized={isLayerMapDelete}>
            <DeleteLayer
              uuid={uuid}
              button={deleteButton}
            />
          </PermissionRoute>
        </Col>
      </Row>
    </Card>
  );
}