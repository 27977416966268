import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import {useForm} from 'antd/lib/form/Form';
import EpicenterModal from './EpicenterModal';
import {ButtonProps, FormProps, ModalProps} from 'antd';
import {EditOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import {useEventContext} from "../../../context/event/EventContext";
import {usePatchEventMutation} from "../../../redux/api/events";
import {useMessageContext} from "../../../context/message/MessageContext";

export default function EditEpicenter({modalProps, formProps, modalButton, submitButton}: {
  modalProps?: ModalProps,
  formProps?: FormProps,
  modalButton?: ButtonProps,
  submitButton?: ButtonProps
}) {
  const {t} = useTranslation();
  const [form] = useForm();
  const {event} = useEventContext()
  const {setFieldsValue} = form
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [patchEvent, {isSuccess, isLoading, isError}] = usePatchEventMutation()
  const setMessage = useMessageContext()

  useEffect(() => {
    if (isSuccess) {
      setMessage({success: capitalize(t('form.editSuccessMessage'))})
      setIsModalOpen(false)
    }
  }, [isSuccess, setMessage, t]);

  useEffect(() => {
    if (isError) {
      setMessage({error: capitalize(t('form.editFailMessage'))})
    }
  }, [isError, setMessage, t]);
  const handleInsertEpicenter = ({lat, lng}: { lat: number, lng: number }) => {
    if (event?.uuid) {
      patchEvent({epicenter: {lat, lng}, uuid: event?.uuid})
    }
  }

  useEffect(() => {
    if (isModalOpen) {
      setFieldsValue(event?.epicenter || {})
    }
  }, [event?.epicenter, isModalOpen, setFieldsValue]);

  return <EpicenterModal
    modalProps={{
      title: capitalize(t('actions.editItem', {item: t('events.epicenter')})),
      open: isModalOpen,
      onCancel: () => setIsModalOpen(false),
      ...modalProps,
    }}
    formProps={{
      form: form,
      initialValues: event?.epicenter || {},
      onFinish: values => handleInsertEpicenter(values),
      ...formProps,
    }}
    modalButton={{
      children: capitalize(t('actions.edit')),
      icon: <EditOutlined/>,
      type: 'primary',
      onClick: () => setIsModalOpen(true),
      ...modalButton,
    }}
    submitButton={{
      children: capitalize(t('actions.editItem', {item: t('events.epicenter')})),
      loading: isLoading,
      disabled: isLoading,
      ...submitButton,
    }}
  />;
}