import {
  Button,
  Card,
  Col,
  ConfigProvider,
  Descriptions,
  DescriptionsProps,
  Divider,
  Row,
  Space,
  Typography,
} from 'antd';
import {capitalize} from 'lodash';
import EventTypeFromUuid from '../../../common/icons/EventTypeFromUuid';
import React, {CSSProperties} from 'react';
import {useTranslation} from 'react-i18next';
import DateTimeFormat from '../../../../locales/DateTimeFormat';
import AlertDocumentsList from './AlertDocumentsList';
import AlertAssetList from './AlertAssetsList';
import EventDetail from './EventDetail';
import {EditOutlined} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';
import {useAlertContext} from '../../../../context/alertDetails/AlertDetails';
import {translateLevelToText} from '../../../../utils/assets';
import PermissionRoute from '../../../Authentication/PermissionRoute';
import {
  isAlertDocumentEdit,
  isAlertDocumentView,
  isAlertEdit,
  isAssetsListView,
} from '../../../Authentication/permissions';
import {AlertModel} from '../../../../types/api/alerts';
import {VALUE_NOT_PRESENT} from '../../../../utils/translation';
import CustomLatitudeNumericFormat from '../../../common/customNumeric/CustomLatitudeNumericFormat';
import CustomLongitudeNumericFormat from '../../../common/customNumeric/CustomLongitudeNumericFormat';
import UpdateShakemapButton from './UpdateShakemapButton';

const {Title, Text} = Typography;

function CustomDivider() {
  return <Divider style={{marginTop: '1.5rem', marginBottom: '1.5rem'}}/>;
}

const DescriptionData = ({alert}: { alert: AlertModel }) => {
  
  const {t} = useTranslation();
  
  const items: DescriptionsProps['items'] = [
    {
      label: capitalize(t('alerts.editDate')),
      children: <DateTimeFormat>{alert.date_edit}</DateTimeFormat>,
    },
    {
      label: capitalize(t('alerts.creationMode')),
      children: capitalize(t(alert.is_user_created ? 'alerts.isUserCreated' : 'alerts.isNotUserCreated')),
    },
    {
      label: capitalize(t('events.forecastOrHappened')),
      children: capitalize(t(alert.is_forecast ? 'alerts.isForecast' : 'alerts.isNotForecast')),
    },
    {
      label: capitalize(t('alerts.geoArea')),
      children:
        alert.geographic_area_list.map(el => `${el.name} (${capitalize(t(translateLevelToText(el.level)))})`).join(' - '),
    },
    {
      label: capitalize(t('alerts.datasource')),
      children: alert.source_url,
    },
    {
      label: capitalize(t('alerts.startDate')),
      children: alert.start_datetime ? <DateTimeFormat>{alert.start_datetime}</DateTimeFormat> : VALUE_NOT_PRESENT,
    },
    {
      label: capitalize(t('alerts.endDate')),
      children: alert.end_datetime ? <DateTimeFormat>{alert.end_datetime}</DateTimeFormat> : VALUE_NOT_PRESENT,
    },
    ...alert?.epicenter ? [{
      label: capitalize(t('events.latLonEpicenter')),
      children: <Space>
        <div><CustomLatitudeNumericFormat value={alert.epicenter.lat} displayType={'text'}/>,</div>
        <CustomLongitudeNumericFormat value={alert.epicenter.lng} displayType={'text'}/>
      </Space>,
    }] : [],
    {
      label: capitalize(t('alerts.description')),
      children: <div style={{whiteSpace: 'pre-line'}}>{alert.description || VALUE_NOT_PRESENT}</div>,
    },
  ];
  
  return <ConfigProvider
    theme={{
      components: {
        Descriptions: {
          itemPaddingBottom: 0,
        },
      },
    }}
  >
    <Descriptions
      layout={'vertical'}
      bordered={false}
      colon={false}
      items={items}
      column={{xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 2}}
      labelStyle={{
        fontWeight: 'bold',
        color: 'rgba(0, 0, 0, 0.88)',
      }}
      contentStyle={{marginBottom: '1rem'}}
    />
  </ConfigProvider>;
};

export default function AlertDetail({style}: {
  style?: CSSProperties
}) {
  
  const {t} = useTranslation();
  const navigate = useNavigate();
  
  const [alert, , isLoading] = useAlertContext();
  
  return (
    <>
      <Card
        bodyStyle={{padding: '24px'}}
        loading={isLoading}
        style={style}
      >
        {alert && (
          <>
            <Row justify={'space-between'} align={'middle'} wrap={false}>
              <Col>
                <Title
                  level={3}
                  style={{marginTop: 0}}
                >
                  {alert.title}
                </Title>
              </Col>
              {alert.state !== 'CONNECTED' && alert.is_user_created && (
                <PermissionRoute isAuthorized={isAlertEdit}>
                  <Col flex={1}>
                    <Button
                      icon={<EditOutlined style={{fontSize: '1.5rem'}}/>}
                      type={'text'}
                      onClick={() => navigate(`/alerts/${alert.uuid}/edit`)}
                    />
                  </Col>
                </PermissionRoute>
              )}
            </Row>
            <Row justify={'space-between'} style={{marginTop: '0.5rem'}} align={'middle'}>
              <Col>
                <EventTypeFromUuid
                  uuid={alert?.event_type} title={{ellipsis: {tooltip: true}}}
                  iconStyle={{width: '1.75rem', height: '1.75rem'}}
                />
              </Col>
              {alert?.is_updatable && (
                <Col>
                  <UpdateShakemapButton/>
                </Col>)
              }
            
            </Row>
            {(alert.state === 'ARCHIVED' || alert.state === 'CONNECTED') && (
              <EventDetail event={alert.event}/>
            )}
            <CustomDivider/>
            <DescriptionData alert={alert}/>
            <PermissionRoute isAuthorized={isAlertDocumentView}>
              <CustomDivider/>
              <Row>
                <Col flex={1}>
                  <Title level={5} style={{margin: 0}}>
                    {capitalize(t('documents.document', {count: 2}))}
                    <PermissionRoute isAuthorized={isAlertDocumentEdit}>
                      <Button
                        icon={<EditOutlined/>}
                        type={'text'}
                        onClick={() => navigate(`/alerts/${alert.uuid}/documents`)}
                      />
                    </PermissionRoute>
                  </Title>
                  <AlertDocumentsList uuid={alert.uuid}/>
                </Col>
              </Row>
            </PermissionRoute>
            <PermissionRoute
              isAuthorized={isAssetsListView}
            >
              <CustomDivider/>
              <Row>
                <Col flex={1}>
                  <Title level={5} style={{margin: 0}}>
                    {alert.assets_count} {capitalize(t('alerts.involvedAssets'))}
                  </Title>
                  <AlertAssetList uuid={alert.uuid}/>
                </Col>
              </Row>
            </PermissionRoute>
          </>
        )}
      </Card>
    </>
  );
}