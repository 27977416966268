import {createBrowserRouter, Navigate, RouterProvider} from 'react-router-dom';
import Assets from '../pages/Assets';
import Error from '../pages/errors/Error';
import Asset from '../pages/Asset';
import RootLayout from '../layout/RootLayout';
import Login from '../pages/Login';
import ProtectedRoute from '../components/Authentication/ProtectedRoute';
import VerifyRefreshToken from '../components/Authentication/VerifyRefreshToken';
import {getRefreshToken} from '../components/Authentication/authUtils';
import SSOInterceptor from '../pages/SSOInterceptor';
import SSODenied from '../pages/SSODenied';
import Logout from '../pages/Logout';
import {AddAsset} from '../pages/AddAsset';
import SimplifiedLayout from '../layout/SimplifiedLayout';
import {AddAssetContext} from '../context/addAsset/AddAssetContext';
import LocaleProvider from '../locales/LocaleProvider';
import LogRocketConfig from '../LogRocketConfig';
import Settings from '../pages/settings/Settings';
import LayersSettings from '../pages/settings/layers/LayersSettings';
import {EditAsset} from '../pages/EditAsset';
import {EditAssetContext} from '../context/editAsset/EditAssetContext';
import ImportReportsSettings from '../pages/settings/importReport/ImportReportsSettings';
import EventTypeSettings from '../pages/settings/events/EventTypeSettings';
import ImportReport from '../pages/settings/importReport/ImportReport';
import {EventsMap} from '../pages/EventsMap';
import CreateLayer from '../pages/settings/layers/CreateLayer';
import EditLayer from '../pages/settings/layers/EditLayer';
import UploadLayer from '../pages/settings/layers/UploadLayer';
import EventTypesSettings from '../pages/settings/events/EventTypesSettings';
import Documents from '../pages/Documents';
import AlertsStandard from '../pages/alerts/AlertsStandard';
import AlertCreation from '../components/alerts/creation/AlertCreation';
import MessageContext from '../context/message/MessageContext';
import AlertsHistory from '../pages/alerts/AlertsHistory';
import AlertsSuggestion from '../pages/alerts/AlertsSuggestion';
import AlertDocuments from '../pages/alerts/AlertDocuments';
import Alert from '../pages/alerts/Alert';
import AlertEdit from '../components/alerts/edit/AlertEdit';
import {AlertContext} from '../context/alertDetails/AlertContext';
import PermissionRoute from '../components/Authentication/PermissionRoute';
import {
  isAlertCreation,
  isAlertDocumentView,
  isAlertEdit,
  isAlertView,
  isAssetsListView,
  isDocumentsListView,
  isEventCreation,
  isEventTypesView,
  isEventView,
  isImportDataView,
  isLayerMapCreation,
  isLayerMapEdit,
  isLayerMapView,
  isUserCreatedAssetCreation,
} from '../components/Authentication/permissions';
import NotAuthorized from '../components/Authentication/NotAuthorized';
import EventCreationFromScratch from '../pages/events/EventCreationFromScratch';
import EventList from '../pages/events/EventList';
import {EventRedirect} from '../pages/EventRedirect';
import DataSourcesSettings from '../pages/settings/dataSources/DataSourcesSettings';
import DataSourceDetail from '../pages/settings/dataSources/DataSourceDetail';

export const router = createBrowserRouter([
  {
    element: <MessageContext/>,
    children: [
      {
        path: 'login',
        element:
          <ProtectedRoute
            verifyConditionVisibility={() => !getRefreshToken()}
            redirectUrl={'assets'}
          >
            <Login/>
          </ProtectedRoute>,
      },
      {
        path: 'logout',
        element: <Logout/>,
      },
      {
        path: 'sso',
        element: <SSOInterceptor/>,
      },
      {
        path: 'sso-denied',
        element: <SSODenied/>,
      },
      {
        element: <VerifyRefreshToken/>,
        children: [
          {
            element: <LocaleProvider/>,
            children: [
              {
                element: <LogRocketConfig/>,
                children: [
                  {
                    element: <RootLayout/>,
                    errorElement: <Error/>,
                    children: [
                      {
                        path: 'not-authorized',
                        element: <NotAuthorized/>,
                      },
                      {
                        path: 'assets',
                        element: <PermissionRoute
                          isAuthorized={isAssetsListView}
                          redirect
                        >
                          <Assets/>
                        </PermissionRoute>,
                        id: '|assets|',
                      },
                      {
                        path: 'documents',
                        element: <PermissionRoute
                          isAuthorized={isDocumentsListView}
                          redirect
                        >
                          <Documents/>
                        </PermissionRoute>,
                        id: '|documents|',
                      },
                      {
                        path: 'alerts',
                        element: <PermissionRoute isAuthorized={isAlertView} redirect>
                          <AlertsStandard/>
                        </PermissionRoute>,
                        id: '|alerts|',
                      },
                      {
                        path: 'alerts/create',
                        element: <PermissionRoute isAuthorized={isAlertCreation} redirect>
                          <AlertCreation/>
                        </PermissionRoute>,
                        id: '|alerts|create',
                      },
                      {
                        path: 'alerts/:uuid',
                        element: <PermissionRoute
                          isAuthorized={isAlertView}
                          redirect
                        >
                          <AlertContext>
                            <Alert/>
                          </AlertContext>
                        </PermissionRoute>,
                        id: '|alerts|id',
                      },
                      {
                        path: 'alerts/:uuid/edit',
                        element: <PermissionRoute
                          isAuthorized={isAlertEdit}
                          redirect
                        >
                          <AlertEdit/>
                        </PermissionRoute>,
                        id: '|alerts|id-edit',
                      },
                      {
                        path: 'alerts/:uuid/documents',
                        element: <PermissionRoute
                          isAuthorized={isAlertDocumentView}
                        >
                          <AlertDocuments/>
                        </PermissionRoute>,
                        id: '|alerts|id-documents',
                      },
                      {
                        path: 'alerts-history',
                        element: <PermissionRoute
                          isAuthorized={isAlertView}
                          redirect
                        >
                          <AlertsHistory/>
                        </PermissionRoute>,
                        id: '|alerts|history',
                      },
                      {
                        path: 'alerts-suggestion',
                        element: <PermissionRoute
                          isAuthorized={isAlertView}
                          redirect
                        >
                          <AlertsSuggestion/>
                        </PermissionRoute>,
                        id: '|alerts|suggestion',
                      },
                      {
                        path: 'settings',
                        element: <Settings/>,
                        id: '|settings|',
                      },
                      {
                        path: 'settings/events',
                        element: <PermissionRoute
                          isAuthorized={isEventTypesView}
                          redirect
                        >
                          <EventTypesSettings/>
                        </PermissionRoute>,
                        id: '|settings|events',
                      },
                      {
                        path: 'settings/events/:uuid',
                        element: <PermissionRoute
                          isAuthorized={isEventTypesView}
                          redirect
                        >
                          <EventTypeSettings/>
                        </PermissionRoute>,
                        id: '|settings|events-id',
                      },
                      {
                        path: 'settings/layers',
                        element: <PermissionRoute
                          isAuthorized={isLayerMapView}
                          redirect
                        >
                          <LayersSettings/>
                        </PermissionRoute>,
                        id: '|settings|layers',
                      },
                      {
                        path: 'settings/layers/create',
                        element: <PermissionRoute
                          isAuthorized={isLayerMapCreation}
                          redirect
                        >
                          <CreateLayer/>
                        </PermissionRoute>,
                        id: '|settings|layers-create',
                      },
                      {
                        path: 'settings/layers/:uuid/edit',
                        element: <PermissionRoute
                          isAuthorized={isLayerMapEdit}
                          redirect
                        >
                          <EditLayer/>
                        </PermissionRoute>,
                        id: '|settings|layers-id-edit',
                      },
                      {
                        path: 'settings/layers/upload-file',
                        element: <PermissionRoute
                          isAuthorized={isLayerMapCreation}
                          redirect
                        >
                          <UploadLayer/>
                        </PermissionRoute>,
                        id: '|settings|layers-upload',
                      },
                      {
                        path: 'settings/data-sources',
                        element: <DataSourcesSettings/>,
                        id: '|settings|datasources',
                      },
                      
                      {
                        path: 'settings/data-sources/:uuid',
                        element: <DataSourceDetail/>,
                        id: '|settings|datasources-id',
                      },
                      
                      {
                        path: 'settings/import-report',
                        element: <PermissionRoute
                          isAuthorized={isImportDataView}
                          redirect
                        >
                          <ImportReportsSettings/>
                        </PermissionRoute>,
                        id: '|settings|importreport',
                      },
                      {
                        path: 'settings/import-report/:uuid',
                        element: <PermissionRoute
                          isAuthorized={isImportDataView}
                          redirect
                        >
                          <ImportReport/>
                        </PermissionRoute>,
                        id: '|settings|importreport-id',
                      },
                      {
                        path: 'events',
                        element: <PermissionRoute
                          isAuthorized={isEventView}
                          redirect
                        >
                          <EventList/>
                        </PermissionRoute>,
                        id: '|events|',
                      },
                      {
                        path: 'events/create',
                        element: <PermissionRoute
                          isAuthorized={isEventCreation}
                          redirect
                        >
                          <EventCreationFromScratch/>
                        </PermissionRoute>
                        ,
                        id: '|events|create',
                      },
                    ],
                  },
                  {
                    element: <SimplifiedLayout/>,
                    errorElement: <Error/>,
                    children: [
                      {
                        path: 'assets/create',
                        element: <PermissionRoute isAuthorized={isUserCreatedAssetCreation} redirect>
                          <AddAssetContext>
                            <AddAsset/>
                          </AddAssetContext>
                        </PermissionRoute>,
                        id: '|assets|create',
                      },
                      {
                        path: 'assets/:assetId/edit',
                        element: <EditAssetContext>
                          <EditAsset/>
                        </EditAssetContext>,
                        id: '|assets|id-edit',
                      },
                      {
                        path: 'assets/:assetId',
                        element: <PermissionRoute
                          isAuthorized={isAssetsListView}
                          redirect
                        >
                          <Asset/>
                        </PermissionRoute>,
                        id: '|assets|id',
                      },
                      {
                        path: 'map',
                        element: <EventsMap/>,
                        id: '|map|',
                      },
                    ],
                  },
                  {
                    element: <EventRedirect/>,
                    path: 'events/:uuid',
                    errorElement: <Error/>,
                    id: '|events|id',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/assets"/>,
  },
]);

export default function Router() {
  return <RouterProvider router={router}/>;
}