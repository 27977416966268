import {AlertGroup} from "../../../types/api/alerts";
import React from "react";
import ConnectAlertGroupToEvent from "./ConnectAlertGroupToEvent";

export default function AddAlertGroupToSpecificEvent({alertGroup, onFinish}: { alertGroup: AlertGroup, onFinish?: () => void }) {
  return (
    <ConnectAlertGroupToEvent
      alertGroupUuid={alertGroup.uuid}
      eventUuid={alertGroup.event}
      afterConnect={onFinish}
    />
  )
}