import {Card, Col, Row, Space, Typography} from 'antd';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
import RemoveButton from '../../common/buttons/RemoveButton';
import EditEpicenter from './EditEpicenter';
import React from 'react';
import {usePatchEventMutation} from "../../../redux/api/events";
import {useEventContext} from "../../../context/event/EventContext";
import CustomLongitudeNumericFormat from "../../common/customNumeric/CustomLongitudeNumericFormat";
import CustomLatitudeNumericFormat from "../../common/customNumeric/CustomLatitudeNumericFormat";
import {useMessageContext} from "../../../context/message/MessageContext";

export default function EpicenterCard() {

  const {t} = useTranslation();
  const {event} = useEventContext()
  const [patchEvent] = usePatchEventMutation()
  const setMessage = useMessageContext()

  const handleRemoveEpicenter = () => {
    if (event?.uuid) {
      patchEvent({epicenter: null, uuid: event?.uuid})
      .then(() => {
          setMessage({
            success: capitalize(t('form.deleteSuccessMessage')),
          })
        })
        .catch(() => {
          setMessage({
            error: capitalize(t('form.deleteFailMessage')),
          })
        })
    }
  }

  return <Card
    title={null}
  >
    <Row justify={'space-between'} align={'middle'} gutter={[16, 16]}>
      <Col>
        <Space direction={'vertical'}>
          <Typography.Text strong>{capitalize(t('address.latitude'))}</Typography.Text>
          <CustomLatitudeNumericFormat value={event?.epicenter?.lat} displayType={"text"}/>
        </Space>
      </Col>
      <Col flex={1}>
        <Space direction={'vertical'}>
          <Typography.Text strong>{capitalize(t('address.longitude'))}</Typography.Text>
          <CustomLongitudeNumericFormat value={event?.epicenter?.lng} displayType={"text"}/>
        </Space>
      </Col>
      <Col>
        <Space wrap>
          <RemoveButton onClick={handleRemoveEpicenter}/>
          <EditEpicenter/>
        </Space>
      </Col>

    </Row>
  </Card>;
}