import {List} from 'antd';
import {useTranslation} from 'react-i18next';
import React, {useEffect, useState} from 'react';
import {ImageListItem} from './ImageListItem';
import {useLazyGetAssetImagesQuery} from '../../../redux/api/documents';
import {useParams} from 'react-router-dom';
import {CustomLoading} from '../../common/CustomLoading';
import {isAssetImageDelete, isAssetImageEdit, isAssetImageView} from '../../Authentication/permissions';
import {useGetUserProfileQuery} from '../../../redux/api/locales';

export const ImageList = () => {

  const {t} = useTranslation();
  const {assetId} = useParams();

  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const {
    data: userData,
  } = useGetUserProfileQuery();

  const isEditing = userData?.type ? isAssetImageEdit(userData?.type) : false;
  const isRemoving = userData?.type ? isAssetImageDelete(userData?.type) : false;
  const isDelete = userData?.type ? isAssetImageView(userData?.type) : false;

  const [
    getImages,
    {
      isLoading: isAssetImagesLoading,
      isFetching: isAssetImagesFetching,
      data: assetsImages,
    },
  ] = useLazyGetAssetImagesQuery();

  useEffect(() => {
    if (assetId) {
      getImages({uuid: assetId, page: currentPage, page_size: currentPageSize});
    }
  }, [assetId, currentPage, getImages, currentPageSize]);

  return <>
    <List
      dataSource={assetsImages?.results}
      header={null}
      footer={null}
      loading={
        {
          spinning: isAssetImagesLoading || isAssetImagesFetching,
          indicator: <CustomLoading/>,
        }
      }
      pagination={{
        total: assetsImages?.count || 0,
        showSizeChanger: true,
        current: currentPage,
        onChange: page => setCurrentPage(page),
        onShowSizeChange: (current, pagesize) => {
          setCurrentPage(current);
          setCurrentPageSize(pagesize);
        },
        showTotal: (total, range) => t('table.pageSizeOfTotal', {
          rangeStart: range[0],
          rangeEnd: range[1],
          total,
        }),
        position: 'bottom',
        align: 'center',
      }}
      grid={{
        gutter: 16, xs: 1,
        sm: 2,
        md: 3,
        lg: 4,
        xl: 4,
        xxl: 4,
      }}
      renderItem={(item) => (
        <List.Item>
          <ImageListItem
            imageItem={item}
            withDownload={isDelete}
            withEdit={isEditing}
            withDelete={isRemoving}
            assetId={assetId}
          />
        </List.Item>
      )}
    />
  </>;
};