import {useTranslation} from "react-i18next";
import {useMessageContext} from "../../../context/message/MessageContext";
import {capitalize} from "lodash";
import {Button, Typography} from "antd";
import {DownloadOutlined} from "@ant-design/icons";
import {useLazyDownloadImportReportQuery} from "../../../redux/api/importReport";

export default function ImportReportDownload() {
  const {t} = useTranslation()
  const setMessage = useMessageContext()
  const [getReport, {isFetching}] = useLazyDownloadImportReportQuery()
  const handleClick = () => {
    getReport()
      .then(res => {
        setMessage({
          success: capitalize(t('documents.downloadSuccess'))
        })
      })
      .catch(err => {
        setMessage({
          error: capitalize(t('documents.downloadError'))
        })
      })
  }

  return <Button
    block
    disabled={isFetching}
    loading={isFetching}
    icon={<DownloadOutlined/>}
    onClick={handleClick}
  >
    <Typography.Text>{capitalize(t('dataImport.detailIssuesReport'))}</Typography.Text>
  </Button>
}