import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '../store';
import {PaginationConfig} from 'antd/lib/pagination';
import {
  AlertsDocumentsTableFilters,
  AlertsDocumentsTableSorters,
  AssetsDocumentsTableFilters,
  AssetsDocumentsTableSorters,
  DEFAULT_ALERTS_DOCUMENTS_TABLE_FILTERS,
  DEFAULT_ALERTS_DOCUMENTS_TABLE_SORTERS,
  DEFAULT_ASSETS_DOCUMENTS_TABLE_FILTERS,
  DEFAULT_ASSETS_DOCUMENTS_TABLE_SORTERS,
  DEFAULT_EVENT_TYPES_DOCUMENTS_TABLE_FILTERS,
  DEFAULT_EVENT_TYPES_DOCUMENTS_TABLE_SORTERS,
  DEFAULT_EVENTS_DOCUMENTS_TABLE_FILTERS,
  DEFAULT_EVENTS_DOCUMENTS_TABLE_SORTERS,
  DEFAULT_NOT_ASSOCIATED_DOCUMENTS_TABLE_FILTERS,
  DEFAULT_NOT_ASSOCIATED_DOCUMENTS_TABLE_SORTERS,
  EventsDocumentsTableFilters,
  EventsDocumentsTableSorters,
  EventTypesDocumentsTableFilters,
  EventTypesDocumentsTableSorters,
  NotAssociatedDocumentsTableFilters,
  NotAssociatedDocumentsTableSorters,
} from '../../types/internal/DocumentsTable';

// Define a type for the slice state
export interface DocumentsSlice {
  assetsDocuments: {
    filters: AssetsDocumentsTableFilters,
    sorters: AssetsDocumentsTableSorters,
    pagination: PaginationConfig,
  },
  eventTypesDocuments: {
    filters: EventTypesDocumentsTableFilters,
    sorters: EventTypesDocumentsTableSorters,
    pagination: PaginationConfig,
  },
  eventsDocuments: {
    filters: EventsDocumentsTableFilters,
    sorters: EventsDocumentsTableSorters,
    pagination: PaginationConfig,
  },
  alertsDocuments: {
    filters: AlertsDocumentsTableFilters,
    sorters: AlertsDocumentsTableSorters,
    pagination: PaginationConfig,
  },
  notAssociatedDocuments: {
    filters: NotAssociatedDocumentsTableFilters,
    sorters: NotAssociatedDocumentsTableSorters,
    pagination: PaginationConfig,
  },
}

// Define the initial state using that type
const initialState = {
  assetsDocuments: {
    filters: DEFAULT_ASSETS_DOCUMENTS_TABLE_FILTERS,
    sorters: DEFAULT_ASSETS_DOCUMENTS_TABLE_SORTERS,
    pagination: {
      current: 1,
      pageSize: +(window.sessionStorage.getItem('assets_document_page_size') || 10),
    },
  },
  eventTypesDocuments: {
    filters: DEFAULT_EVENT_TYPES_DOCUMENTS_TABLE_FILTERS,
    sorters: DEFAULT_EVENT_TYPES_DOCUMENTS_TABLE_SORTERS,
    pagination: {
      current: 1,
      pageSize: +(window.sessionStorage.getItem('event_types_document_page_size') || 10),
    },
  },
  eventsDocuments: {
    filters: DEFAULT_EVENTS_DOCUMENTS_TABLE_FILTERS,
    sorters: DEFAULT_EVENTS_DOCUMENTS_TABLE_SORTERS,
    pagination: {
      current: 1,
      pageSize: +(window.sessionStorage.getItem('events_document_page_size') || 10),
    },
  },
  alertsDocuments: {
    filters: DEFAULT_ALERTS_DOCUMENTS_TABLE_FILTERS,
    sorters: DEFAULT_ALERTS_DOCUMENTS_TABLE_SORTERS,
    pagination: {
      current: 1,
      pageSize: +(window.sessionStorage.getItem('alert_document_page_size') || 10),
    },
  },
  notAssociatedDocuments: {
    filters: DEFAULT_NOT_ASSOCIATED_DOCUMENTS_TABLE_FILTERS,
    sorters: DEFAULT_NOT_ASSOCIATED_DOCUMENTS_TABLE_SORTERS,
    pagination: {
      current: 1,
      pageSize: +(window.sessionStorage.getItem('not_associated_document_page_size') || 10),
    },
  },
} as DocumentsSlice;

export const documentsSlice = createSlice({
  name: 'documents',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    /*Assets documents*/
    setAssetsDocumentsFilters: (state, {payload}: PayloadAction<Partial<AssetsDocumentsTableFilters>>) => {
      state.assetsDocuments.filters = {...state.assetsDocuments.filters, ...payload};
      state.assetsDocuments.pagination = {...state.assetsDocuments.pagination, current: 1};
    },
    setAssetsDocumentsSorters: (state, {payload}: PayloadAction<Partial<AssetsDocumentsTableSorters>>) => {
      state.assetsDocuments.sorters = {...state.assetsDocuments.sorters, ...payload};
    },
    setAssetsDocumentsTablePagination: (state, {payload}: PayloadAction<Partial<PaginationConfig>>) => {
      state.assetsDocuments.pagination = payload;
      if (payload.pageSize) {
        window.sessionStorage.setItem('assets_document_page_size', `${payload.pageSize}`);
      }
    },
    resetAssetsDocumentsFiltersAndSorters: (state) => {
      state.assetsDocuments.filters = DEFAULT_ASSETS_DOCUMENTS_TABLE_FILTERS;
      state.assetsDocuments.sorters = DEFAULT_ASSETS_DOCUMENTS_TABLE_SORTERS;
      state.assetsDocuments.pagination = {...state.assetsDocuments.pagination, current: 1};
    },
    
    /*Event types documents*/
    setEventTypesDocumentsFilters: (state, {payload}: PayloadAction<Partial<EventTypesDocumentsTableFilters>>) => {
      state.eventTypesDocuments.filters = {...state.eventTypesDocuments.filters, ...payload};
      state.eventTypesDocuments.pagination = {...state.eventTypesDocuments.pagination, current: 1};
    },
    setEventTypesDocumentsSorters: (state, {payload}: PayloadAction<Partial<EventTypesDocumentsTableSorters>>) => {
      state.eventTypesDocuments.sorters = {...state.eventTypesDocuments.sorters, ...payload};
    },
    setEventTypesDocumentsTablePagination: (state, {payload}: PayloadAction<Partial<PaginationConfig>>) => {
      state.eventTypesDocuments.pagination = payload;
      if (payload.pageSize) {
        window.sessionStorage.setItem('event_types_document_page_size', `${payload.pageSize}`);
      }
    },
    resetEventTypesDocumentsFiltersAndSorters: (state) => {
      state.eventTypesDocuments.filters = DEFAULT_EVENT_TYPES_DOCUMENTS_TABLE_FILTERS;
      state.eventTypesDocuments.sorters = DEFAULT_EVENT_TYPES_DOCUMENTS_TABLE_SORTERS;
      state.eventTypesDocuments.pagination = {...state.eventTypesDocuments.pagination, current: 1};
    },
    
    /*Events documents*/
    setEventsDocumentsFilters: (state, {payload}: PayloadAction<Partial<EventsDocumentsTableFilters>>) => {
      state.eventsDocuments.filters = {...state.eventsDocuments.filters, ...payload};
      state.eventsDocuments.pagination = {...state.eventsDocuments.pagination, current: 1};
    },
    setEventsDocumentsSorters: (state, {payload}: PayloadAction<Partial<EventsDocumentsTableSorters>>) => {
      state.eventsDocuments.sorters = {...state.eventsDocuments.sorters, ...payload};
    },
    setEventsDocumentsTablePagination: (state, {payload}: PayloadAction<Partial<PaginationConfig>>) => {
      state.eventsDocuments.pagination = payload;
      if (payload.pageSize) {
        window.sessionStorage.setItem('events_document_page_size', `${payload.pageSize}`);
      }
    },
    resetEventsDocumentsFiltersAndSorters: (state) => {
      state.eventsDocuments.filters = DEFAULT_EVENTS_DOCUMENTS_TABLE_FILTERS;
      state.eventsDocuments.sorters = DEFAULT_EVENTS_DOCUMENTS_TABLE_SORTERS;
      state.eventsDocuments.pagination = {...state.eventsDocuments.pagination, current: 1};
    },
    
    /*Alerts documents*/
    setAlertsDocumentsFilters: (state, {payload}: PayloadAction<Partial<AlertsDocumentsTableFilters>>) => {
      state.alertsDocuments.filters = {...state.alertsDocuments.filters, ...payload};
      state.alertsDocuments.pagination = {...state.alertsDocuments.pagination, current: 1};
    },
    setAlertsDocumentsSorters: (state, {payload}: PayloadAction<Partial<AlertsDocumentsTableSorters>>) => {
      state.alertsDocuments.sorters = {...state.alertsDocuments.sorters, ...payload};
    },
    setAlertsDocumentsTablePagination: (state, {payload}: PayloadAction<Partial<PaginationConfig>>) => {
      state.alertsDocuments.pagination = payload;
      if (payload.pageSize) {
        window.sessionStorage.setItem('alert_document_page_size', `${payload.pageSize}`);
      }
    },
    resetAlertsDocumentsFiltersAndSorters: (state) => {
      state.alertsDocuments.filters = DEFAULT_ALERTS_DOCUMENTS_TABLE_FILTERS;
      state.alertsDocuments.sorters = DEFAULT_ALERTS_DOCUMENTS_TABLE_SORTERS;
      state.alertsDocuments.pagination = {...state.alertsDocuments.pagination, current: 1};
    },
    
    /*Not associated documents*/
    setNotAssociatedDocumentsFilters: (state, {payload}: PayloadAction<Partial<NotAssociatedDocumentsTableFilters>>) => {
      state.notAssociatedDocuments.filters = {...state.notAssociatedDocuments.filters, ...payload};
      state.notAssociatedDocuments.pagination = {...state.notAssociatedDocuments.pagination, current: 1};
    },
    setNotAssociatedDocumentsSorters: (state, {payload}: PayloadAction<Partial<NotAssociatedDocumentsTableSorters>>) => {
      state.notAssociatedDocuments.sorters = {...state.notAssociatedDocuments.sorters, ...payload};
    },
    setNotAssociatedDocumentsTablePagination: (state, {payload}: PayloadAction<Partial<PaginationConfig>>) => {
      state.notAssociatedDocuments.pagination = payload;
      if (payload.pageSize) {
        window.sessionStorage.setItem('not_associated_document_page_size', `${payload.pageSize}`);
      }
    },
    resetNotAssociatedDocumentsFiltersAndSorters: (state) => {
      state.notAssociatedDocuments.filters = DEFAULT_NOT_ASSOCIATED_DOCUMENTS_TABLE_FILTERS;
      state.notAssociatedDocuments.sorters = DEFAULT_NOT_ASSOCIATED_DOCUMENTS_TABLE_SORTERS;
      state.notAssociatedDocuments.pagination = {...state.notAssociatedDocuments.pagination, current: 1};
    },
    
  },
});

export const {
  /*assets documents*/
  setAssetsDocumentsFilters,
  setAssetsDocumentsSorters,
  setAssetsDocumentsTablePagination,
  resetAssetsDocumentsFiltersAndSorters,
  /*Event types documents*/
  setEventTypesDocumentsFilters,
  setEventTypesDocumentsSorters,
  setEventTypesDocumentsTablePagination,
  resetEventTypesDocumentsFiltersAndSorters,
  /*Events documents*/
  setEventsDocumentsFilters,
  setEventsDocumentsSorters,
  setEventsDocumentsTablePagination,
  resetEventsDocumentsFiltersAndSorters,
  /*Alerts document*/
  setAlertsDocumentsFilters,
  setAlertsDocumentsSorters,
  setAlertsDocumentsTablePagination,
  resetAlertsDocumentsFiltersAndSorters,
  /*Not associated documents*/
  setNotAssociatedDocumentsFilters,
  setNotAssociatedDocumentsSorters,
  setNotAssociatedDocumentsTablePagination,
  resetNotAssociatedDocumentsFiltersAndSorters,
} = documentsSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAssetsDocuments = (state: RootState) => state.documents.assetsDocuments;
export const selectEventTypesDocuments = (state: RootState) => state.documents.eventTypesDocuments;
export const selectEventsDocuments = (state: RootState) => state.documents.eventsDocuments;
export const selectAlertsDocuments = (state: RootState) => state.documents.alertsDocuments;
export const selectNotAssociatedDocuments = (state: RootState) => state.documents.notAssociatedDocuments;

export const selectDocuments = (state: RootState) => state.documents;

export default documentsSlice.reducer;
