import _, {capitalize} from 'lodash';
import {AutoComplete, Typography} from 'antd';
import {SearchOutlined} from '@ant-design/icons';
import {CustomLoading} from '../CustomLoading';
import {useLazyGetGeoCodeQuery} from '../../../redux/api/geocoding';
import {useTranslation} from 'react-i18next';
import {useEffect, useMemo, useState} from 'react';
import {DefaultOptionType} from 'antd/es/select';
import Label from '../Label';

const {Text} = Typography;
export const GeocodingSearchSelect = (props: {
  onSelect: (value: string, option: DefaultOptionType) => void,
  disabled?: boolean,
}) => {

  const {t} = useTranslation();

  const [
    getGeocode,
    {
      isError: hasGeocodeFailed,
      isLoading: isGeocodeLoading,
      isFetching: isGeocodeFetching,
      data: geocodeData,
    },
  ] = useLazyGetGeoCodeQuery();

  const [searchedLocation, setSearchedLocation] = useState<string>('');
  const [suggestedLocations, setSuggestedLocations] = useState<google.maps.GeocoderResult[]>([]);

  const debouncedSearch = useMemo(() =>
    _.debounce(
      async (value) => {
        if (value) {
          getGeocode({address: value});
        }
      }, 400), [getGeocode]);

  useEffect(() => {
    if (geocodeData)
      setSuggestedLocations(geocodeData.results);
  }, [geocodeData]);

  return (
    <>
      <Label label={capitalize(t('address.searchAddress'))} htmlFor={'search'}/>
      <AutoComplete
        style={{display: 'flex'}}
        allowClear={true}
        disabled={props.disabled === true}
        options={suggestedLocations?.map((item, index) => (
          {
            key: index,
            label: item.formatted_address,
            value: item.formatted_address,
            ...item,
          }
        ))}
        placeholder={`${capitalize(t('address.address'))}, ${t('address.city')}, ${t('address.province')}, ${t('address.region')}, ${t('address.country')}`}
        value={searchedLocation}
        onChange={setSearchedLocation}
        id={'search'}
        suffixIcon={<SearchOutlined/>}
        // @ts-ignore wrong type declaration from antd (autocomplete is a base inputComponent)
        autoComplete={'off'}
        getPopupContainer={triggerNode => triggerNode.parentElement}
        filterOption={false}
        notFoundContent={(
          <div style={{padding: 5}}>
            {
              suggestedLocations.length === 0 &&
              !hasGeocodeFailed &&
              !isGeocodeFetching &&
              !isGeocodeLoading && (
                searchedLocation ? (
                  <Text type={'secondary'}>
                    {`${capitalize(t('form.noResults'))}...`}
                  </Text>
                ) : (
                  <Text type={'secondary'}>
                    {`${capitalize(t('form.typeToSearch'))}...`}
                  </Text>
                ))}
            {
              (isGeocodeLoading || isGeocodeFetching) && (
                <CustomLoading/>
              )}
            {
              hasGeocodeFailed && (
                <Text type={'secondary'}>
                  {`${capitalize(t('misc.somethingWentWrongDuringFetch'))}...`}
                </Text>
              )}
          </div>
        )}
        onSearch={debouncedSearch}
        onSelect={props.onSelect}
      />
    </>
  );
};