import {Card, Col, Form, FormProps, Input, Row} from 'antd';
import {AlertCreationForm} from '../../../types/internal/Alerts';
import AntFormItem from '../../common/AntFormItem';
import {capitalize} from 'lodash';
import SelectEventTypes from '../creation/SelectEventTypes';
import {LocalizedDatePicker} from '../../common/LocalizedDatePicker';
import {CardTitle} from '../creation/AlertCreation';
import {useTranslation} from 'react-i18next';

export default function AlertDetailForm({form}: { form?: FormProps }) {

  const {t} = useTranslation();

  return (
    <Card>
      <CardTitle text={t('alerts.detail')}/>
      <Form<AlertCreationForm>
        layout={'vertical'}
        {...form}
      >
        <Row gutter={40}>
          <Col xs={24} md={12}>
            <AntFormItem
              name={'title'}
              label={capitalize(t('alerts.title'))}
              rules={[
                {
                  required: true,
                  message: capitalize(t('form.requiredField', {field: t('alerts.title')})),
                  validator: (_, value) => {
                    if (!value || value.trim() === '') {
                      return Promise.reject();
                    }
                    return Promise.resolve();
                  },
                }]}
            >
              <Input/>
            </AntFormItem>
          </Col>
          <Col xs={24} md={12}>
            <AntFormItem
              name={'event_type'}
              label={capitalize(t('events.eventType', {count: 1}))}
              rules={[
                {
                  required: true,
                  message: capitalize(t('form.requiredField', {field: t('events.eventType', {count: 1})})),
                },
              ]}
            >
              <SelectEventTypes/>
            </AntFormItem>
          </Col>
          <Col xs={24} md={12}>
            <AntFormItem
              name={'source_url'}
              label={capitalize(t('alerts.datasource'))}
            >
              <Input/>
            </AntFormItem>
          </Col>
        </Row>
        <Row gutter={40}>
          <Col xs={24} md={12}>
            <AntFormItem
              name={'start_datetime'}
              label={capitalize(t('alerts.startDate'))}
              rules={[
                {
                  required: true,
                  message: capitalize(t('form.requiredField', {field: t('alerts.startDate')})),
                },
                ({getFieldValue}) => ({
                  validator(_, value) {
                    const end = getFieldValue('end_datetime');
                    if (!value || !end || value.isBefore(end)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The start can\'t be after the end!'));
                  },
                  message: capitalize(t('form.rangeStartError')),
                }),
              ]}
            >
              <LocalizedDatePicker
                showTime={true}
                style={{width: '100%'}}
              />
            </AntFormItem>

          </Col>
          <Col xs={24} md={12}>
            <AntFormItem
              name={'end_datetime'}
              label={capitalize(t('alerts.endDate'))}
              rules={[
                ({getFieldValue}) => ({
                  validator(_, value) {
                    const end = getFieldValue('start_datetime');
                    if (!value || !end || value.isAfter(end)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The end can\'t be before the start!'));
                  },
                  message: capitalize(t('form.rangeEndError')),
                }),
              ]}
            >
              <LocalizedDatePicker
                showTime={true}
                style={{width: '100%'}}
              />
            </AntFormItem>
          </Col>
          <Col xs={24}>
            <AntFormItem
              name={'description'}
              label={capitalize(t('misc.description'))}
            >
              <Input.TextArea/>
            </AntFormItem>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}