import {Button, Col, Divider, Modal, Row, Skeleton, Typography} from 'antd';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
import {dateToLocaleString} from '../../../../utils/dateParsers';
import {useGetUserProfileQuery} from '../../../../redux/api/locales';
import {DocumentType, useLazyGetUrlQuery} from '../../../../redux/api/documents';
import {FolderOpenOutlined} from '@ant-design/icons';
import {VALUE_NOT_PRESENT} from "../../../../utils/translation";

const {Title, Text, Paragraph} = Typography;
export const DocumentDetailsModal = ({documentItem, open, setOpen}: {
  documentItem: DocumentType,
  open: boolean,
  setOpen: (open: boolean) => void | null,
}) => {

  const {t} = useTranslation();

  const {
    data: userData,
    isFetching: isFetchingUserData,
    isLoading: isLoadingUserData,
  } = useGetUserProfileQuery();
  const [
    getUrl,
  ] = useLazyGetUrlQuery();

  return (
    <Modal
      open={open}
      centered={true}
      title={<>
        <Row>
          <Col>
            <Title level={5} style={{margin: 0}}>
              {documentItem.name}
            </Title>
          </Col>
        </Row>
        <Row>
          <Col>
            <Text type={'secondary'} style={{fontWeight: 400}}>
              {(t('documents.uploadedBy', {name: documentItem.user_creation}))}
            </Text>
          </Col>
        </Row>
      </>}
      onCancel={() => setOpen(false)}
      footer={<Button
        type={'primary'}
        onClick={() => setOpen(false)}
      >
        {capitalize(t('actions.close'))}
      </Button>}
    >
      {(isFetchingUserData || isLoadingUserData) && (
        <Skeleton active={true}/>
      )}
      {!(isFetchingUserData || isLoadingUserData) && userData && (
        <>
          <Divider/>
          <Row style={{marginTop: '1.5rem', marginBottom: '1.5rem'}}>
            <Col span={12}>
              <Text strong={true}>
                {capitalize(t('documents.startDate'))}
              </Text>
            </Col>
            <Col span={12}>
              {documentItem.start_date && <Text>
                {dateToLocaleString(documentItem.start_date, userData.language)}
              </Text>}
              {!documentItem.start_date && <Text type={'secondary'}>
                {VALUE_NOT_PRESENT}
              </Text>}
            </Col>
          </Row>
          <Row style={{marginTop: '1.5rem', marginBottom: '1.5rem'}}>
            <Col span={12}>
              <Text strong={true}>
                {capitalize(t('documents.endDate'))}
              </Text>
            </Col>
            <Col span={12}>
              {documentItem.end_date && <Text>
                {dateToLocaleString(documentItem.end_date, userData.language)}
              </Text>}
              {!documentItem.end_date && <Text type={'secondary'}>
                {VALUE_NOT_PRESENT}
              </Text>}
            </Col>
          </Row>
          <Row style={{marginBottom: '1.5rem'}}>
            <Col span={12}>
              <Text strong={true}>
                {capitalize(t('documents.description'))}
              </Text>
            </Col>
            {documentItem.description && (
              <Col span={24}>
                <Paragraph style={{marginBottom: 0, marginTop: '0.5rem'}}>
                  {documentItem.description}
                </Paragraph>
              </Col>
            )}
            {!documentItem.description && (
              <Col span={12}>
                <Text type={'secondary'}>
                  {VALUE_NOT_PRESENT}
                </Text>
              </Col>
            )}
          </Row>
          <Row>
            <Col>
              <Button
                icon={<FolderOpenOutlined/>}
                onClick={async () => {
                  const awsUrl = await getUrl({documentUuid: documentItem.uuid}).unwrap();
                  fetch(awsUrl.url, {
                    method: 'GET',
                    headers: {},
                  })
                    .then(response => {
                      response.arrayBuffer().then(function (buffer) {
                        //const link = document.createElement("a");
                        window.open(awsUrl.url, '_blank');
                        //link.href = awsUrl.url;
                        //link.target = "_blank";
                        //document.body.appendChild(link);
                        //link.click();
                      });
                    })
                    .catch(err => {
                      console.log(err);
                    });
                }}
              >
                {capitalize(t('actions.viewItem', {item: t('documents.document', {count: 1})}))}
              </Button>
            </Col>
          </Row>
          <Divider/>
        </>
      )}

    </Modal>
  );
};