import {apiSlice} from './baseApi';
import {GenericPaginatedApi, GenericPaginatedParamsApi} from '../../types/api/paginatedApi';
import {
  EventType,
  EventTypeCreationParams,
  EventTypeDocumentsParams,
  EventTypeEditParams,
  EventTypeTranslation,
} from '../../types/api/eventTypes';
import {DocumentType} from './documents';

export const eventTypes = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getEventTypesPaginated: build.query<GenericPaginatedApi<EventType>, GenericPaginatedParamsApi & {
      search_by_name?: string
    }>({
      query: (params) => ({
        params,
        url: '/event-types/',
      }),
      providesTags: (res, error, arg) =>
        res
          ? [
            ...res?.results.map(({uuid}) => ({type: 'EventTypes' as const, id: uuid})),
            {type: 'EventTypes', id: 'LIST'},
            'Locale',
          ]
          : [{type: 'EventTypes', id: 'LIST'}, 'Locale'],
    }),
    getEventTypesNotPaginated: build.query<EventType[], void>({
      query: () => ({
        params: {paginate: false},
        url: '/event-types/',
      }),
      providesTags: (res, error, arg) =>
        res
          ? [
            ...res.map(({uuid}) => ({type: 'EventTypes' as const, id: uuid})),
            {type: 'EventTypes', id: 'LIST'},
            'Locale',
          ]
          : [{type: 'EventTypes', id: 'LIST'}, 'Locale'],
    }),
    getEventTypesForInfiniteScrolling: build.query<GenericPaginatedApi<EventType>, GenericPaginatedParamsApi & {
      search_by_name?: string
    }>({
      query: (params) => ({
        params,
        url: '/event-types/',
      }),
      serializeQueryArgs: ({queryArgs}) => {
        const newQueryArgs = {...queryArgs};
        delete newQueryArgs.page;
        return newQueryArgs;
      },
      merge: (currentCache, newItems, query) => {
        if ((query.arg.page || 0) > 1) {
          return {
            ...currentCache,
            ...newItems,
            results: [...currentCache.results, ...newItems.results],
          };
        }
        return newItems;
      },
    }),
    getEventType: build.query<EventType, { uuid: string }>({
      query: ({uuid}) => ({
        url: `/event-types/${uuid}/`,
      }),
      providesTags: (result, error, params) => [{type: 'EventTypes', id: params.uuid}, 'Locale'],
    }),
    getEventTypeTranslation: build.query<EventTypeTranslation, { uuid: string }>({
      query: ({uuid}) => ({
        url: `/event-types/${uuid}/translations/`,
      }),
      providesTags: (res, error, arg) => [{type: 'EventTypes', id: arg.uuid}],
    }),
    createEventType: build.mutation<EventType, EventTypeCreationParams>({
      query: (args) => ({
        url: `/event-types/`,
        method: 'POST',
        body: args,
      }),
      invalidatesTags: [{type: 'EventTypes', id: 'LIST'}],
    }),
    editEventType: build.mutation<EventType, EventTypeEditParams>({
      query: ({uuid, ...args}) => ({
        url: `/event-types/${uuid}/`,
        method: 'PATCH',
        body: args,
      }),
      invalidatesTags: (result, error, arg) => [{type: 'EventTypes', id: arg.uuid}, {type: 'EventTypes', id: 'LIST'}],
    }),
    deleteEventType: build.mutation<void, { uuid: string }>({
      query: ({uuid}) => ({
        url: `/event-types/${uuid}/`,
        method: 'DELETE',
      }),
      invalidatesTags: [{type: 'EventTypes', id: 'LIST'}],
    }),
    getEventTypeDocuments: build.query<GenericPaginatedApi<DocumentType>, EventTypeDocumentsParams>({
      query: ({uuid, ...params}) => ({
        params,
        url: `/event-types/${uuid}/documents/`,
      }),
      providesTags: (res, error, arg) =>
        res
          ? [...res?.results.map(({uuid}) => ({type: 'Documents' as const, id: uuid})), 'Documents']
          : ['Documents'],
    }),
    addDocumentToEventType: build.mutation<void, { eventTypeUuid: string, documentUuid: string }>({
      query: ({eventTypeUuid, documentUuid}) => ({
        url: `/event-types/${eventTypeUuid}/add_document/`,
        method: 'PATCH',
        body: {
          document_uuid: documentUuid,
        },
      }),
      invalidatesTags: ['Documents'],
    }),
    removeDocumentToEventType: build.mutation<void, { eventTypeUuid: string, documentUuid: string }>({
      query: ({eventTypeUuid, documentUuid}) => ({
        url: `/event-types/${eventTypeUuid}/remove_document/`,
        method: 'PATCH',
        body: {
          document_uuid: documentUuid,
        },
      }),
      invalidatesTags: ['Documents'],
    }),
    
    
  }),
});

export const {
  useLazyGetEventTypesPaginatedQuery,
  useGetEventTypesNotPaginatedQuery,
  useLazyGetEventTypesForInfiniteScrollingQuery,
  useLazyGetEventTypeTranslationQuery,
  useCreateEventTypeMutation,
  useDeleteEventTypeMutation,
  useEditEventTypeMutation,
  useLazyGetEventTypeDocumentsQuery,
  useAddDocumentToEventTypeMutation,
  useRemoveDocumentToEventTypeMutation,
  useGetEventTypeQuery,
} = eventTypes;