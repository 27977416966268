import {useLazyGetAlertDocumentsQuery} from '../../../../redux/api/alerts';
import React, {useEffect} from 'react';
import {CustomLoading} from '../../../common/CustomLoading';
import {Button, Col, List, Row, Space} from 'antd';
import {DownloadOutlined, FileOutlined} from '@ant-design/icons';
import {capitalize} from 'lodash';
import {useLazyGetUrlQuery} from '../../../../redux/api/documents';
import {useMessageContext} from '../../../../context/message/MessageContext';
import {useTranslation} from 'react-i18next';

export default function AlertDocumentsList({uuid}: { uuid: string }) {

  const setMessage = useMessageContext();
  const {t} = useTranslation();
  const [getData, {data, isFetching, isUninitialized}] = useLazyGetAlertDocumentsQuery();
  const [getUrl] = useLazyGetUrlQuery();

  const handleDownload = async (documentUuid: string) => {
    const awsUrl = await getUrl({documentUuid}).unwrap();
    fetch(awsUrl.url, {
      method: 'GET',
      headers: {},
    })
      .then(response => {
        response.arrayBuffer().then(function (buffer) {
          const url = URL.createObjectURL(new Blob([buffer]));
          const responseContentDisposition = ((new URL(awsUrl.url)).searchParams.get('response-content-disposition')) || '';
          const filename = responseContentDisposition.substring(responseContentDisposition.indexOf('"') + 1, responseContentDisposition.lastIndexOf('"'));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${filename}`); //or any other extension
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(url);
        });
      })
      .catch(err => {
        console.log(err);
        setMessage({error: capitalize(t('form.fetchingFailMessage'))});
      });
  };

  useEffect(() => {
    if (uuid) {
      getData({uuid});
    }
  }, [getData, uuid]);

  return <List
    dataSource={data}
    header={null}
    footer={null}
    loading={{
      spinning: isFetching || isUninitialized,
      indicator: <CustomLoading/>,
    }}
    renderItem={(item) => (
      <List.Item>
        <Row justify={'space-between'} align={'middle'} style={{width: '100%'}}>
          <Col>
            <Space>
              <FileOutlined/>
              {item.name}
            </Space>
          </Col>
          <Col>
            <Button
              icon={<DownloadOutlined style={{fontSize: '1.5rem'}}/>}
              type={'text'}
              onClick={() => handleDownload(item.uuid)}
            />
          </Col>
        </Row>
      </List.Item>
    )}
  />;

}