import {useEffect, useState} from 'react';
import _, {capitalize} from 'lodash';
import {Select, Spin, Typography} from 'antd';
import {OptionLabelValue} from '../../../types/internal/OptionLabelValue';
import {useTranslation} from 'react-i18next';
import Label from '../Label';
import {DefaultOptionType} from 'antd/es/select';
import {useLazySearchAssetsBySigiQuery} from '../../../redux/api/assets';

const {Text} = Typography;
export const MasterAssetSearchSelect = (props: {
  value: OptionLabelValue | null,
  onClear: () => void,
  onSelect: (value: OptionLabelValue, option: DefaultOptionType) => void,
  disabled?: boolean,
}) => {

  const {t} = useTranslation();

  const [searchAssets, {
    isLoading,
    isFetching,
    isError,
    isSuccess,
    data,
  }] = useLazySearchAssetsBySigiQuery();

  const [
    options,
    setOptions,
  ] = useState<OptionLabelValue[]>([]);

  const [
    searchedContactPerson,
    setSearchedContactPerson,
  ] = useState<string>('');

  const debouncedSearch = _.debounce((searchQuery) => {
    setSearchedContactPerson(searchQuery);
    searchQuery ? searchAssets({sigi: searchQuery}) : setOptions([]);
  }, 400);

  useEffect(() => {
    if (isError)
      setOptions([]);
    if (isSuccess && data) {
      setOptions(data?.map(option => ({
        label: `${option.sigi_code}`,
        value: option.uuid,
      })) || []);
    }
  }, [data, isError, isSuccess]);

  return (
    <>
      <Label label={capitalize(t('assets.masterAssetCode'))} htmlFor={'master_asset'}/>
      <Select
        <OptionLabelValue>
        placeholder={capitalize(t('assets.masterAssetCode'))}
        id={'master_asset'}
        style={{marginBottom: 20, width: '100%'}}
        value={props.value}
        disabled={props.disabled === true}
        labelInValue={true}
        filterOption={false}
        onSearch={debouncedSearch}
        onSelect={props.onSelect}
        onClear={props.onClear}
        allowClear={true}
        showSearch={true}
        notFoundContent={<div style={{padding: 5}}>
          {(isFetching || isLoading) && (
            <Spin size="small"/>
          )}
          {!(isFetching || isLoading) && isError && (
            <Text type={'secondary'}>
              {capitalize(t('misc.somethingWentWrongDuringFetch'))}...
            </Text>
          )}
          {!(isFetching || isLoading) && !isError && searchedContactPerson !== '' && (
            <Text type={'secondary'}>
              {capitalize(t('assets.noMasterAssetAvailable'))}
            </Text>
          )}
          {!(isFetching || isLoading) && !isError && !searchedContactPerson && (
            <Text type={'secondary'}>
              {capitalize(t('assets.startTypingToSearchMasterAsset'))}...
            </Text>
          )}
        </div>}
        options={options}
      />
    </>
  );
};