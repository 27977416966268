import {Card, Col, Image, Row, Typography} from 'antd';
import {DocumentType, useDeleteDocumentMutation} from '../../../redux/api/documents';
import {DocumentActions} from '../../common/documents/documentActions/DocumentActions';

const {Text} = Typography;
export const ImageListItem = ({imageItem, withDownload, withEdit, withDelete, assetId}: {
  imageItem: DocumentType,
  withDownload?: boolean,
  withEdit?: boolean,
  withDelete?: boolean,
  assetId?: string,
}) => {

  const [
    deleteImage,
    {
      isLoading: isDeletingImage,
    },
  ] = useDeleteDocumentMutation();

  const handleDelete = () => {
    if (assetId) {
      return deleteImage({documentUuid: imageItem.uuid}).unwrap();
    } else {
      return Promise.reject();
    }
  };

  return (
    <>
      <Card
        cover={<Image
          alt={imageItem.name}
          width={'100%'}
          height={'160px'}
          style={{objectFit: 'cover'}}
          src={imageItem.url}
        />}
        loading={isDeletingImage}
        bodyStyle={{padding: '0.5rem'}}
      >
        <Row wrap={false}>
          <Col flex={'auto'}>
            <Text ellipsis={{tooltip: imageItem.name}}>
              {imageItem.name}
            </Text>
          </Col>
          <Col flex={'none'}>
            <DocumentActions
              documentItem={imageItem}
              withDetails={true}
              isDetailsOptionInDropdownMenu={true}
              withDownload={withDownload}
              withEdit={withEdit}
              withDelete={withDelete}
              onDelete={handleDelete}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};