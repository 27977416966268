import L from 'leaflet';
import {Feature, MultiPolygon} from '@turf/helpers';
import {Polygon as PolygonHelper} from '@turf/helpers/dist/js/lib/geojson';

class CustomLeafletPolygonLayer extends L.Polygon {
  private uuid: string;
  private feature_index: number;
  
  constructor(latlngs: L.LatLngExpression[][] | L.LatLngExpression[][][], options: L.PathOptions, uuid: string, feature_index: number) {
    super(latlngs, options);
    this.uuid = uuid;
    this.feature_index = feature_index;
  }
  
  getProperties(): { uuid: string, feature_index: number, geometry: Feature<PolygonHelper | MultiPolygon, any> } {
    return {uuid: this.uuid, feature_index: this.feature_index, geometry: super.toGeoJSON(6)};
  }
  
}

export default CustomLeafletPolygonLayer;