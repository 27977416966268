import ResponsiveContent from '../../layout/ResponsiveContent';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
import {useState} from 'react';
import {Radio} from 'antd';
import ActiveEventsList from '../../components/events/activeEventsList/ActiveEventsList';
import ArchivedEvents from '../../components/events/archivedEventsTable/ArchivedEvents';

export default function EventList() {

  const [currentSelection, setCurrentSelection] = useState<'active' | 'archived'>('active');

  const {t} = useTranslation();
  return <ResponsiveContent
    title={capitalize(t('events.list'))}
    extra={<Radio.Group
      value={currentSelection}
      onChange={(e) => setCurrentSelection(e.target.value)}
    >
      <Radio.Button
        value="active"
      >
        {capitalize(t('events.activeEvents'))}
      </Radio.Button>
      <Radio.Button
        value="archived"
      >
        {capitalize(t('events.archivedEvents'))}
      </Radio.Button>
    </Radio.Group>
    }
  >
    {currentSelection === 'active' && <ActiveEventsList/>}
    {currentSelection === 'archived' && <ArchivedEvents/>}
  </ResponsiveContent>;
}