import NewDrawControls, {CutGeometries} from '../../common/map/controls/newDrawControls/NewDrawControls';
import {useEventContext} from '../../../context/event/EventContext';
import {useCallback, useEffect, useMemo, useRef} from 'react';
import L from 'leaflet';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import {Feature, multiPolygon, MultiPolygon} from '@turf/helpers';
import chroma from 'chroma-js';
import {EventContextType} from "../../../context/event/EventContextTypes";
import {useControlsAction} from "../../../context/baseMap/ControlContext";
import {ASSET_MAP_ACTIONS} from "../../common/map/constants/map";
import {useMapLoadingContext} from "../../../context/baseMap/MapLoadingContext";

const ClusterMapDrawControlWrapper = ({clusterUuid}: {
  clusterUuid: string,
}) => {
  
  const {t} = useTranslation();

  const context = useEventContext();
  const {
    onChangePolygonOrder,
    getClusterFromUuid,
    getGlobalPolygonsFromCluster,
    onRemovePolygon,
    onInsertPolygon,
    onEditPolygon,
  } = context

  const contextRef = useRef<EventContextType>();
  const [, setLoading] = useMapLoadingContext()

  useEffect(() => {
    contextRef.current = context;
  }, [context]);

  const clusterGlobalPolygons = useMemo(() => getGlobalPolygonsFromCluster(clusterUuid), [clusterUuid, getGlobalPolygonsFromCluster])
  const {action: {currentAction, actionEvent}, changeAction} = useControlsAction();

  const shapeCreationHandler = useCallback((createdFeature: Feature<MultiPolygon, {}>, map: L.Map) => {
    return new Promise<void>((onResolve, onReject) => {
      onInsertPolygon({type: 'draw', payload: createdFeature.geometry.coordinates, cluster: clusterUuid})
        .then(onResolve)
        .catch(onReject);
    });
  }, [clusterUuid, onInsertPolygon]);
  
  const shapeDeletionHandler = useCallback((uuid: string, map: L.Map) => {
    return new Promise<void>((onResolve, onReject) => {
      onRemovePolygon(uuid)
        .then(() => {
          onResolve();
        })
        .catch((error) => {
          console.error(capitalize(t('form.deleteFailMessage')));
          onReject(error);
        });
    });
  }, [onRemovePolygon, t]);
  
  const shapeCutHandler = useCallback((cutterGeometry: Feature<MultiPolygon, {}>, affectedGeometries: CutGeometries[], map: L.Map) => {
    return new Promise<void>((onResolve, onReject) => {
      if (contextRef.current?.onCutPolygons) {
        contextRef.current?.onCutPolygons({
          cutterGeometry,
          affectedGeometries,
          involvedClusters: [clusterUuid],
        })
          .then(() => {
            onResolve();
          })
          .catch((error) => {
            console.error(capitalize(t('form.deleteFailMessage')));
            onReject(error);
          })
      } else {
        onReject()
      }
    });
  }, [clusterUuid, t]);

  const shapeEditHandler = useCallback((editedFeature: Feature<MultiPolygon, {}>, uuid: string, map: L.Map) => {
    return new Promise<void>((onResolve, onReject) => {
      onEditPolygon(editedFeature, uuid)
        .then(() => onResolve())
        .catch(() => onReject())
    });
  }, [onEditPolygon]);

  const cluster = getClusterFromUuid(clusterUuid);

  useEffect(() => {
    if (currentAction === ASSET_MAP_ACTIONS.ADD_GEOMETRY_FROM_LAYER_QUERY) {
      changeAction(ASSET_MAP_ACTIONS.PAN);
      shapeCreationHandler(multiPolygon(actionEvent.geometry), actionEvent.map)
        .finally(() => setLoading(false));
    }
  }, [actionEvent, changeAction, currentAction, setLoading, shapeCreationHandler]);
  
  return (
    <NewDrawControls
      drawnGeometries={clusterGlobalPolygons.map(el => ({
        coordinates: el.geometry.coordinates,
        uuid: el.properties.uuid,
        pathOptions: cluster?.color_code ?
          {color: chroma(cluster?.color_code || 'blue').alpha(0.7).hex()} :
          {},
      }))}
      onAfterShapeCreation={shapeCreationHandler}
      onPolygonsDelete={shapeDeletionHandler}
      onAfterShapeCut={shapeCutHandler}
      onSendToBack={(polygonUuid) => onChangePolygonOrder(polygonUuid, 'back')}
      onBringToFront={(polygonUuid) => onChangePolygonOrder(polygonUuid, 'front')}
      canEditShapes
      onAfterShapeEdit={shapeEditHandler}
    />
  );
};
export default ClusterMapDrawControlWrapper;