import {createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState} from 'react';

export type WMSLayerOpacityContextType = [
  opacity: number,
  setOpacity: Dispatch<SetStateAction<number>>
]

const OpacityContext = createContext<WMSLayerOpacityContextType>([
    0.5,
    () => null,
  ],
);

export const useWMSLayerOpacityContext = () => useContext(OpacityContext);

export const WMSLayerOpacityContext = (props: PropsWithChildren) => {
  const [
    opacity,
    setOpacity,
  ] = useState<number>(0.5);

  return (
    <OpacityContext.Provider value={[opacity, setOpacity]}>
      {props.children}
    </OpacityContext.Provider>
  );
};