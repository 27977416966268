// FILTERS
import {Sorter} from './sorters';
import {OptionLabelValue} from '../OptionLabelValue';
import {GetEventsParams} from '../../api/getEventsResult';
import {GetAlertsParams} from '../../api/alerts';
import dayjs from 'dayjs';

const eventsBooleanFilters = [
  'is_clusters_null',
  'is_end_datetime_null',
  'is_start_datetime_null',
] as const;
export type EventsBooleanFilters = (typeof eventsBooleanFilters)[number];
const isEventsBooleanFilters = (x: any): x is EventsBooleanFilters => eventsBooleanFilters.includes(x);

const eventsStringFilters = [
  'end_datetime_after',
  'end_datetime_before',
  'start_datetime_after',
  'start_datetime_before',
  'search_by_code',
  'search_by_name',
] as const;
export type EventsStringFilters = (typeof eventsStringFilters)[number];
const isEventsStringFilters = (x: any): x is EventsStringFilters => eventsStringFilters.includes(x);

const eventsOptionLabelValueFilters = [
  'code',
  'event_type',
  'name',
  'reduced_state',
] as const;
export type EventsOptionLabelValueFilters = (typeof eventsOptionLabelValueFilters)[number];
export const isEventsOptionLabelValueFilters = (x: any): x is EventsOptionLabelValueFilters => eventsOptionLabelValueFilters.includes(x);

const eventsNumberFilters = [
  'clusters_max',
  'clusters_min',
] as const;
export type EventsNumberFilters = (typeof eventsNumberFilters)[number];
const isEventsNumberFilters = (x: any): x is EventsNumberFilters => eventsNumberFilters.includes(x);

export type EventsFiltersFE = Partial<
  Record<EventsBooleanFilters, boolean> &
  Record<EventsStringFilters, string> &
  Record<EventsOptionLabelValueFilters, OptionLabelValue[]> &
  Record<EventsNumberFilters, number>
>

export type EventsFilters = (
  typeof eventsBooleanFilters |
  typeof eventsStringFilters |
  typeof eventsOptionLabelValueFilters |
  typeof eventsNumberFilters)[number];
export const isEventsFilters = (x: any): x is EventsFilters => [
  ...eventsBooleanFilters,
  ...eventsStringFilters,
  ...eventsOptionLabelValueFilters,
  ...eventsNumberFilters].includes(x);

export function fromFeToBeEventsFilters(tableFilters: EventsFiltersFE) {
  let parsedFilters: GetEventsParams = {};
  for (const [key, value] of Object.entries(tableFilters)) {
    if (
      isEventsBooleanFilters(key) ||
      isEventsStringFilters(key) ||
      isEventsNumberFilters(key)) {
      switch (key) {
        case 'start_datetime_before':
        case 'end_datetime_before':
          if (typeof value === 'string') {
            parsedFilters = {
              ...parsedFilters,
              [key as keyof GetAlertsParams]: dayjs(value, 'YYYY-MM-DD').endOf('day').toISOString(),
            };
          }
          break;
        case 'start_datetime_after':
        case 'end_datetime_after':
          if (typeof value === 'string') {
            parsedFilters = {
              ...parsedFilters,
              [key as keyof GetAlertsParams]: dayjs(value, 'YYYY-MM-DD').startOf('day').toISOString(),
            };
          }
          break;
        default:
          parsedFilters = {...parsedFilters, [key as keyof GetEventsParams]: value};
      }
    } else if (isEventsOptionLabelValueFilters(key)) {
      const parsedValue = (value as OptionLabelValue[]).map(el => el.value).join(',');
      parsedFilters = {...parsedFilters, [key as keyof GetEventsParams]: parsedValue};
    }
  }
  return parsedFilters;
}

// COLUMNS
export const archivedEventsTableColumns = [
  // 'clusters',
  'code',
  'event_type',
  'end_datetime',
  'name',
  'reduced_state',
  'start_datetime',
] as const;

export type ArchivedEventsTableColumns = (typeof archivedEventsTableColumns)[number]
export const isArchivedEventsTableColumns = (x: any): x is ArchivedEventsTableColumns => archivedEventsTableColumns.includes(x);

// SORTERS
export type ArchivedEventsSortersFE = Partial<{
  [K in ArchivedEventsTableColumns]: Sorter
}>
