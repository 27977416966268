import {INITIAL_CENTER, INITIAL_ZOOM, MAX_ZOOM, MIN_ZOOM} from './constants/map';
import {MapContainer} from 'react-leaflet';
import {Controls} from './Controls';
import {BaseLayer} from './BaseLayer';
import {BaseMapProps, GoogleLayers} from '../../../types/internal/Map';
import React, {useEffect} from 'react';
import L from 'leaflet';
import {capitalize} from 'lodash';
import {useGetUserProfileQuery} from '../../../redux/api/locales';
import {useTranslation} from 'react-i18next';

export const BaseMap = (props: BaseMapProps) => {
  
  const {t} = useTranslation();
  
  const {
    data: userData,
  } = useGetUserProfileQuery();
  
  useEffect(() => {
    if (userData?.language) {
      
      L.drawLocal.edit.handlers.edit.tooltip.subtext = '';
      L.drawLocal.edit.handlers.edit.tooltip.text = capitalize(t('map.dragToEditFeature'));
      L.drawLocal.edit.handlers.remove.tooltip.text = capitalize(t('map.clickOnAFeatureToRemove'));
      L.drawLocal.draw.handlers.polygon.tooltip.cont = capitalize(t('map.clickToContinueDrawing'));
      L.drawLocal.draw.handlers.polygon.tooltip.end = capitalize(t('map.clickFirstPointToClose'));
      L.drawLocal.draw.handlers.polygon.tooltip.start = capitalize(t('map.clickToStart'));
      L.drawLocal.draw.handlers.circle.radius = capitalize(t('map.radius'));
      L.drawLocal.draw.handlers.circle.tooltip.start = capitalize(t('map.clickAndDragToDrawCircle'));
      L.drawLocal.draw.handlers.simpleshape.tooltip.end = capitalize(t('map.releaseMouseToFinishDrawing'));
      L.drawLocal.draw.handlers.polyline.tooltip.start = capitalize(t('map.clickToStartDrawingPolyline'));
      L.drawLocal.draw.handlers.polyline.tooltip.cont = capitalize(t('map.clickToContinueDrawingPolyline'));
      L.drawLocal.draw.handlers.polyline.tooltip.end = capitalize(t('map.clickToEndDrawingPolyline'));
      L.drawLocal.draw.handlers.polyline.error = capitalize(t('map.errorDrawingPolyline'));
    }
  }, [t, userData?.language]);
  
  return (
    <MapContainer
      center={props.withCenter || INITIAL_CENTER}
      zoom={props.withInitialZoom || INITIAL_ZOOM}
      minZoom={MIN_ZOOM}
      maxZoom={MAX_ZOOM}
      zoomControl={false}
      attributionControl={false}
      preferCanvas={true}
      style={{
        width: '100%',
        height: '100%',
        borderRadius: 20,
        ...props.withStyle,
      }}
    >
      <BaseLayer/>
      <div id={'map-controls'}>
        <Controls
          withZoom={props.withZoom}
          withLayerPicker={props.withLayerPicker}
          withBaseLayers={props.withBaseLayers || [GoogleLayers.roadmap, GoogleLayers.terrain, GoogleLayers.satellite, GoogleLayers.hybrid]}
          withGeoSearch={props.withGeoSearch}
          withWMSLayers={props.withWMSLayers}
          withPopoverConfiguration={props.withPopoverConfiguration}
          withLegend={props.withLegend}
          withQuerableLayers={props.withQuerableLayers}
          withStreetView={props.withStreetView}
          withAssetFilterFromLayer={props.withAssetFilterFromLayer}
          withScreenshot={props.withScreenshot}
          withSoftLoading={props.withSoftLoading}
        />
        {props.children}
      </div>
    </MapContainer>
  );
};