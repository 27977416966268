import ResponsiveContent from '../../layout/ResponsiveContent';
import {Result} from 'antd';
import {useTranslation} from 'react-i18next';
import ToAssetsListButton from '../assets/ToAssetsListButton';
import {capitalize} from 'lodash';

export default function NotAuthorized() {

  const {t} = useTranslation();

  return <ResponsiveContent>
    <Result
      status={'403'}
      title={'403'}
      subTitle={capitalize(t('authentication.notAuthorized'))}
      extra={<ToAssetsListButton/>}
    />
  </ResponsiveContent>;
}