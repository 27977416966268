import {Entity, GlobalPolygon} from '../../redux/api/geoshapes';
import {ClusterAsset, EventPolygon} from './EventContextTypes';
import {Cluster} from '../../types/api/clusters';
import {cloneDeep} from 'lodash';
import {notAssignedAssetColor} from '../../utils/colors';

type EventContextReducerState = {
  assets: ClusterAsset[],
  entities: Entity[],
  eventPolygons: EventPolygon[],
  globalPolygons: GlobalPolygon[],
}

type EventContextReducerActionType =
  {
    type: 'changePolygonOrder',
    payload: {
      position: 'start' | 'end',
      globalPolygonUuid: string,
    }
  }
  |
  {
    type: 'initialize',
    payload: {
      assets: ClusterAsset[]
      entities: Entity[],
      eventPolygons: EventPolygon[],
      globalPolygons: GlobalPolygon[],
    }
  }
  |
  {
    type: 'removeGlobalPolygon',
    payload: {
      globalPolygonUuid: string[]
    }
  }
  |
  {
    type: 'insertPolygons',
    payload: {
      eventPolygons: EventPolygon[],
      globalPolygons: GlobalPolygon[],
      entities?: Entity[],
    }
  }
  |
  {
    type: 'updatePolygon',
    payload: {
      globalPolygonUuid: string,
      clusterUuid: string,
    }
  }
  |
  {
    type: 'updateAssets',
    payload: { cluster: Cluster | null, assets: string[] }[]
  }

export const initialStateEventContextReducer = {
  assets: [],
  entities: [],
  eventPolygons: [],
  globalPolygons: [],
} as EventContextReducerState;

export function eventContextReducer(state: EventContextReducerState, action: EventContextReducerActionType) {
  
  const {type, payload} = action;
  
  switch (type) {
    case 'changePolygonOrder':
      const foundEventPolygon = state.eventPolygons.find(el => el.globalPolygon === payload.globalPolygonUuid);
      const foundGlobalPolygon = state.globalPolygons.find(el => el.properties.uuid === payload.globalPolygonUuid);
      
      const filteredEventPolygons = state.eventPolygons.filter(el => el.globalPolygon !== payload.globalPolygonUuid);
      const filteredGlobalPolygons = state.globalPolygons.filter(el => el.properties.uuid !== payload.globalPolygonUuid);
      
      if (foundEventPolygon && foundGlobalPolygon) {
        if (payload.position === 'start') {
          return {
            ...state,
            eventPolygons: [foundEventPolygon, ...filteredEventPolygons],
            globalPolygons: [foundGlobalPolygon, ...filteredGlobalPolygons],
          };
        } else {
          return {
            ...state,
            eventPolygons: [...filteredEventPolygons, foundEventPolygon],
            globalPolygons: [...filteredGlobalPolygons, foundGlobalPolygon],
          };
        }
      }
      return state;
    
    case 'initialize':
      return {
        assets: payload.assets,
        entities: payload.entities,
        eventPolygons: payload.eventPolygons,
        globalPolygons: payload.globalPolygons,
      };
    
    case 'insertPolygons':
      return {
        ...state,
        globalPolygons: [...state.globalPolygons, ...payload.globalPolygons],
        ...!!payload.entities && {entities: [...state.entities, ...payload.entities]},
        eventPolygons: [...state.eventPolygons, ...payload.eventPolygons],
      };
    
    // given a globalPolygonUuid remove entities / globalpolygons / eventPolygons target with that uuid
    case 'removeGlobalPolygon':
      const eventPolygonsUpdated = state.eventPolygons.filter(el => !payload.globalPolygonUuid.includes(el.globalPolygon));
      const entitiesToRemove = state.eventPolygons.filter(el => payload.globalPolygonUuid.includes(el.globalPolygon)).map(el => el.entity_uuid);
      
      return {
        ...state,
        globalPolygons: state.globalPolygons.filter(el => !payload.globalPolygonUuid.includes(el.properties.uuid)),
        entities: state.entities.filter(el => !entitiesToRemove.includes(el.uuid)),
        eventPolygons: eventPolygonsUpdated,
      };
    
    case 'updateAssets':
      const assetsUpdated = cloneDeep(state.assets);
      payload.forEach(({cluster, assets}) => {
        assets.forEach(clusterAsset => {
          const foundAsset = assetsUpdated.find(asset => asset.uuid === clusterAsset);
          if (foundAsset) {
            foundAsset.cluster_template = cluster?.uuid || null
            if (cluster) {
              foundAsset.color = cluster.color_code;
            } else {
              foundAsset.color = notAssignedAssetColor;
            }
          }
        });
      });
      
      return {
        ...state,
        assets: assetsUpdated,
      };
    
    case 'updatePolygon':
      return {
        ...state,
        eventPolygons: state.eventPolygons.map(el => {
          return el.globalPolygon === payload.globalPolygonUuid ?
            {...el, cluster: payload.clusterUuid}
            :
            el;
        }),
      };
    default:
      return state;
  }
  
}