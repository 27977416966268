import ArchivedAlert from '../../components/alerts/alert/ArchivedAlert';
import NotManagedAlert from '../../components/alerts/alert/NotManagedAlert';
import ConnectedAlert from '../../components/alerts/alert/ConnectedAlert';
import {useAlertContext} from '../../context/alertDetails/AlertDetails';
import {AssetDetailsDrawer} from '../../components/asset/assetDrawer/AssetDetailsDrawer';
import {useTranslation} from "react-i18next";

export default function Alert() {

  const [alert] = useAlertContext();
  const {t} = useTranslation()
  const alertUuid = alert?.uuid

  return <>
    {alertUuid && <AssetDetailsDrawer
      goBack={{
        path: `/alerts/${alertUuid}`,
        label: t('alerts.alert', {count: 1})
      }}
    />}
    {alert?.state === 'TO_MANAGE' && <NotManagedAlert/>}
    {alert?.state === 'ARCHIVED' && <ArchivedAlert/>}
    {alert?.state === 'CONNECTED' && <ConnectedAlert/>}
  </>;
}