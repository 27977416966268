import {Button, ButtonProps, Col, Form, FormProps, Input, Radio, Row, Select} from 'antd';
import EventTypeFromUuid from '../../common/icons/EventTypeFromUuid';
import {useTranslation} from 'react-i18next';
import {useState} from 'react';
import AntFormItem from '../../common/AntFormItem';
import {capitalize} from 'lodash';
import SelectEventTypes from '../../alerts/creation/SelectEventTypes';
import {EVENT_STATES} from '../../../types/api/getEventsResult';
import {LocalizedDatePicker} from '../../common/LocalizedDatePicker';
import {EventCreationFormType} from '../../../types/internal/events';
import SelectEvent from "../SelectEvent";

export default function EventCreationForm({form, confirmButton, isEventTypeDisabled}: {
  form?: FormProps,
  confirmButton?: ButtonProps,
  isEventTypeDisabled?: boolean
}) {

  const {t} = useTranslation();
  const [currentEventType, setCurrentEventType] = useState(form?.initialValues?.event_type || '');

  return <Form
    <EventCreationFormType>
    layout={'vertical'}
    {...form}
  >
    {currentEventType && <EventTypeFromUuid uuid={currentEventType} iconStyle={{width: '1.75rem', height: '1.75rem'}}/>}
    <AntFormItem
      name={'event_type'}
      label={capitalize(t('events.eventType', {count: 1}))}
      rules={[
        {
          required: true,
          message: capitalize(t('form.requiredField', {field: t('events.eventType', {count: 1})})),
        },
      ]}
    >
      <SelectEventTypes
        disabled={isEventTypeDisabled}
        onSelect={(value, option) => setCurrentEventType(value)}
      />
    </AntFormItem>
    <AntFormItem
      name={'name'}
      label={capitalize(t('events.name', {count: 1}))}
      rules={[
        {
          required: true,
          message: capitalize(t('form.requiredField', {field: t('events.name')})),
        },
      ]}
    >
      <Input/>
    </AntFormItem>
    <AntFormItem
      name={'description'}
      label={capitalize(t('misc.description'))}
    >
      <Input.TextArea/>
    </AntFormItem>
    <AntFormItem
      name={'has_ancestor_event'}
      label={capitalize(t('events.hasAncestorEvent'))}
      rules={[
        {
          required: true,
          message: capitalize(t('form.requiredField', {field: t('events.hasAncestorEvent')})),
        },
      ]}
    >
      <Radio.Group>
        <Radio value={true}>{capitalize(t('misc.yes'))}</Radio>
        <Radio value={false}>{capitalize(t('misc.no'))}</Radio>
      </Radio.Group>
    </AntFormItem>
    <Form.Item
      noStyle
      shouldUpdate={(prevValues, currentValues) => prevValues.has_ancestor_event !== currentValues.has_ancestor_event}
    >
      {({getFieldValue}) =>
        getFieldValue('has_ancestor_event') ? (
          <AntFormItem
            name={'ancestor_event'}
            label={capitalize(t('events.ancestorEvent'))}
            rules={[
              {
                required: true,
                message: capitalize(t('form.requiredField', {field: t('events.ancestorEvent')})),
              },
            ]}
          >
            <SelectEvent/>
          </AntFormItem>
        ) : null
      }
    </Form.Item>
    <AntFormItem
      name={'state'}
      label={capitalize(t('events.state'))}
      rules={[
        {
          required: true,
          message: capitalize(t('form.requiredField', {field: t('events.state')})),
        },
      ]}
    >
      <Radio.Group>
        <Radio value={EVENT_STATES.Forecast}>{capitalize(t('events.forecast'))} </Radio>
        <Radio value={EVENT_STATES.Happened}>{capitalize(t('events.happened'))}</Radio>
      </Radio.Group>
    </AntFormItem>
    <Form.Item
      noStyle
      shouldUpdate={(prevValues, currentValues) => prevValues.state !== currentValues.state}
    >
      {({getFieldValue}) =>
        getFieldValue('state') === EVENT_STATES.Forecast ? (
          <AntFormItem
            name={'forecast_start_datetime'}
            label={capitalize(t('events.dateStartForecast'))}
            rules={[
              {
                required: true,
                message: capitalize(t('form.requiredField', {field: t('events.dateStartForecast')})),
              },
            ]}
          >
            <LocalizedDatePicker
              showTime={true}
            />
          </AntFormItem>
        ) : null
      }
    </Form.Item>
    <Form.Item
      noStyle
      shouldUpdate={(prevValues, currentValues) => prevValues.state !== currentValues.state}
    >
      {({getFieldValue}) =>
        getFieldValue('state') === EVENT_STATES.Happened ? (
          <AntFormItem
            name={'start_datetime'}
            label={capitalize(t('events.dateStartEvent'))}
            rules={[
              {
                required: true,
                message: capitalize(t('form.requiredField', {field: t('events.dateStartEvent')})),
              },
            ]}
          >
            <LocalizedDatePicker
              showTime={true}
            />
          </AntFormItem>
        ) : null
      }
    </Form.Item>
    <Row
      justify={'end'}
    >
      <Col>
        <Button
          htmlType={'submit'}
          type={'primary'}
          children={capitalize(t('actions.confirm'))}
          {...confirmButton}
        />
      </Col>
    </Row>
  </Form>;
}