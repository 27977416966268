import {Col, Collapse, ConfigProvider, List, Row, Skeleton, Typography} from 'antd';
import {GetFeatureInfoResponse} from '../../../redux/api/map';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';

const {Text, Title} = Typography;

const FeatureInfoFeatureList = ({feature}: {
  feature: { bbox: number[], id: string, properties: { [p: string]: string } }
}) => {
  return (
    <>
      <List
        key={feature.id}
        dataSource={
          Object
            .entries(feature.properties)
            .map(([key, value]) =>
              ({key: key, value: value}))}
        renderItem={(item) => (
          <List.Item key={item.key}>
            <Row
              justify={'space-between'}
              align={'middle'}
              wrap={false}
              style={{width: '100%'}}
            >
              <Col span={12}>
                <Text strong={true}>
                  {item.key}:
                </Text>
              </Col>
              <Col span={12}>
                <Text
                  type={'secondary'}
                  style={{fontSize: 12}}
                >
                  {item.value}
                </Text>
              </Col>
            </Row>
          </List.Item>
        )}
        pagination={{
          position: 'bottom',
          align: 'end',
          defaultPageSize: 10,
          pageSizeOptions: [10, 15, 20],
          hideOnSinglePage: true,
          size: 'small',
        }}
      />
    </>
  );
};

export const GetFeatureInfoProperties = (props: {
  data?: GetFeatureInfoResponse,
  loading?: boolean,
  error?: boolean,
  success?: boolean
}) => {
  
  const {t} = useTranslation();
  
  return (<>
    <Title level={5}>
      {capitalize(t('map.queryResults'))}:
    </Title>
    {props.loading === true && <Skeleton active={true}/>}
    {!props.loading && props.error === true && (
      <Text type={'warning'}>
        {capitalize(t('map.somethingWentWrongDuringQueryFetch'))}
      </Text>
    )}
    {!props.loading && props.success === true && (
      <>
        {props.data?.features.length === 0 && (
          <Text type={'secondary'}>
            {capitalize(t('map.noDataQuery'))}
          </Text>)}
        <ConfigProvider
          theme={{
            components: {
              List: {
                itemPadding: '2px 0',
              },
            },
          }}
        >
          <Collapse
            accordion={true}
            bordered={false}
            defaultActiveKey={0}
            items={props.data?.features.map((feature, index) => ({
              key: index,
              label: `${capitalize(t('misc.result'))} ${index + 1}:`,
              showArrow: false,
              children: <FeatureInfoFeatureList feature={feature}/>,
            }))}
          />
        </ConfigProvider>
      </>
    )}
  </>);
};