import {Dayjs} from 'dayjs';
import {DatePicker} from 'antd';
import {useGetUserProfileQuery} from '../../../redux/api/locales';

type TableFilterProps =
  {
    selectedFilters: [Dayjs, Dayjs] | null,
    onChange: (values: [Dayjs, Dayjs]) => void
  }

export default function DateRangeFilter(props: TableFilterProps) {

  const {data} = useGetUserProfileQuery();
  const formatDate = data?.language === 'it' ? 'DD / MM / YYYY' : 'YYYY / MM / DD';

  const content = <>
    <DatePicker.RangePicker
      allowClear={false}
      value={props.selectedFilters}
      format={formatDate}
      onChange={values => {
        if (values) {
          props.onChange([values[0] as Dayjs, values[1] as Dayjs]);
        }
      }}
    />
  </>;

  return (
    <div
    >
      {content}
    </div>);
}