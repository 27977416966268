import {PropsWithChildren, useEffect} from "react";
import {Button, Card, Col, message, Popconfirm, Row, Skeleton, Space, Typography} from "antd";
import {useAssetDetails} from "../../context/assetDetails/AssetDetails";
import {capitalize} from "lodash";
import {useTranslation} from "react-i18next";
import {EditOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {red} from "@ant-design/colors";
import {useNavigate, useParams} from "react-router-dom";
import {useDeleteAssetsMutation} from "../../redux/api/assets";
import {dateToLocaleString} from "../../utils/dateParsers";
import {useGetUserProfileQuery} from "../../redux/api/locales";
import PermissionRoute from "../Authentication/PermissionRoute";
import {
  isGeolocationAutomaticAssetEdit,
  isUserCreatedAssetDelete,
  isUserCreatedAssetEdit
} from "../Authentication/permissions";
import DownloadAssetReport from "../asset/DownloadAssetReport";

const {Text, Title} = Typography;
export const AssetSummaryCard = (props: PropsWithChildren) => {

  const {assetId} = useParams();
  const {t} = useTranslation();
  const [, {asset, loading}] = useAssetDetails();

  const navigate = useNavigate();

  const [messageApi, contextHolder] = message.useMessage();

  const [
    deleteAsset,
    {
      isSuccess: isAssetDeleteSuccessful,
      isLoading: isDeletingAsset,
      isError: hasAssetDeleteFailed
    }] = useDeleteAssetsMutation();

  const {
    data: userData,
    isFetching: isFetchingUserData,
    isLoading: isLoadingUserData,
  } = useGetUserProfileQuery();

  useEffect(() => {
    if (isAssetDeleteSuccessful) {
      navigate("/assets/")
    }
  }, [isAssetDeleteSuccessful, navigate]);

  useEffect(() => {
    if (hasAssetDeleteFailed) {
      messageApi.open({type: "error", content: capitalize(t("assets.assetDeleteFailed"))});
    }
  }, [hasAssetDeleteFailed, messageApi, t]);


  return <Card style={{width: "100%", backgroundColor: "#E6F4FF"}}>
    {loading && isFetchingUserData && isLoadingUserData && <Skeleton active={true}/>}
    {!loading && !isFetchingUserData && !isLoadingUserData && (<>
        {contextHolder}
        <Row justify={"center"}>
          <Col>
            <Title level={5} style={{marginTop: 0}}>
              {asset.sigi_code}
            </Title>
          </Col>
        </Row>
        <Row justify={"center"}>
          <Col>
            <Text strong={true} style={{fontSize: 13}}>
              {[
                asset.address ? capitalize((asset.address).toLowerCase()) : null,
                asset.admin_level_3 ? capitalize(asset.admin_level_3.toLowerCase()) : null,
                asset.admin_level_1 ? capitalize(asset.admin_level_1.toLowerCase()) : null,
              ].filter(string => string !== null)
                .join(", ")}
            </Text>
          </Col>
        </Row>
        {asset.date_edit && userData && <Row justify={"center"} style={{margin: "1rem 0 1.5rem 0 "}}>
          <Col>
            <Text type={"secondary"} style={{fontSize: 13}}>
              {/*@ts-ignore*/}
              {`${capitalize(t("misc.lastUpdated"))}: ${dateToLocaleString(asset.date_edit, userData.language)}`}
            </Text>
          </Col>
        </Row>}
        <Space direction={"vertical"}
               size={"small"}
               style={{display: "flex"}}>
          <DownloadAssetReport/>
          {!!assetId && <PermissionRoute
            isAuthorized={u => asset.is_user_created ? isUserCreatedAssetEdit(u) : isGeolocationAutomaticAssetEdit(u)}>
            <Button
              type={"primary"}
              style={{width: "100%"}}
              icon={<EditOutlined/>}
              onClick={() => navigate(`/assets/${assetId}/edit/`)}>
              {capitalize(t("form.edit"))}
            </Button>
          </PermissionRoute>
          }
          {!!assetId && (
            <PermissionRoute
              isAuthorized={u => asset.is_user_created ? isUserCreatedAssetDelete(u) : false}>
              <Popconfirm title={capitalize(t("assets.deleteBuilding"))}
                          description={capitalize(t("assets.assetDeleteConfirm"))}
                          placement={"rightTop"}
                          icon={<QuestionCircleOutlined style={{color: red[4]}}/>}
                          onConfirm={() => deleteAsset({uuid: assetId})}
                          okText={capitalize(t("form.delete"))}
                          okButtonProps={{danger: true}}>
                <Button type={"text"}
                        style={{width: "100%"}}
                        disabled={!asset.is_user_created}
                        loading={isDeletingAsset}
                        danger={true}>
                  {capitalize(t("assets.deleteBuilding"))}
                </Button>
              </Popconfirm>
            </PermissionRoute>
          )}
        </Space>
      </>
    )}
  </Card>
}