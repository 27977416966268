import {Avatar, Modal, ModalProps, Select, SelectProps, Space} from 'antd';
import React from 'react';
import {Cluster} from '../../../types/api/clusters';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';

export const NO_CLUSTER_VALUE = '';


export function ClusterOption({cluster}: { cluster: Cluster | null }) {

  const {t} = useTranslation()

  return cluster ? <Space>
      <Avatar
        size={16}
        style={{backgroundColor: cluster.color_code}}
      />
      {cluster.name}
    </Space> :
    <>{capitalize(t('clusters.noClusterAssigned'))}</>
}

export function SelectCluster({select, clusters, nullValue = true}: {
  select?: SelectProps,
  clusters?: Cluster[],
  nullValue?: boolean,
}) {

  return <Select
    style={{width: '100%'}}
    {...select}
    value={nullValue ? (select?.value || NO_CLUSTER_VALUE) : select?.value}
  >
    {
      clusters?.map(cluster => <Select.Option
        key={cluster.uuid || 'noValue'}
        value={cluster.uuid}
      >
        <ClusterOption cluster={cluster}/>
      </Select.Option>)
    }
    {nullValue && <Select.Option value={NO_CLUSTER_VALUE}>
      <ClusterOption cluster={null}/>
    </Select.Option>}
  </Select>
}