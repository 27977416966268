import React from "react";
import EventPolygons from "./EventPolygons";
import EpicenterMarker from "./EpicenterMarker";
import EventAssetMarkers from "./EventAssetMarkers";
import {useEventCenterMap} from "../../../customHooks/useCenterMap";
import {useMapLoadingContext} from "../../../context/baseMap/MapLoadingContext";

export default function SummaryEventMap() {

  const [loading] = useMapLoadingContext()
  useEventCenterMap(loading)

  return <>
    <EventAssetMarkers type={'onlyEvent'}/>
    <EventPolygons/>
    <EpicenterMarker/>
  </>;
}