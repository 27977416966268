import {useAssetDetails} from "../../../context/assetDetails/AssetDetails";
import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {PaginationConfig} from "antd/lib/pagination";
import {
  BiodiversityFiltersFE,
  BiodiversitySortersFE,
  fromFeToBeBiodiversityTableFilters,
  getFiltersOfTheBiodiversityTableColumn
} from "../../../types/internal/tables/biodiversityTable";
import {useGetAssetBiodiversityPaginatedQuery} from "../../../redux/api/asset";
import {capitalize, cloneDeep, isEmpty} from "lodash";
import {fromFeToBeSorter} from "../../../utils/parser";
import {skipToken} from "@reduxjs/toolkit/query";
import {Sorter} from "../../../types/internal/tables/sorters";
import {DocumentsTableColumnHeader} from "../../documents/TablesViews/DocumentsTableColumnHeader";
import TableSorter from "../../common/TableSorter";
import {Pagination, Table, Typography} from "antd";
import {BiodiversityInput} from "../../assets/AssetsTable/filters/BiodiversityDistance";
import {BIODIVERSITY_SOURCE} from "../../../types/api/assets";
import {ALL_BIODIVERSITY_SOURCE} from "../../assets/AssetsTable/filters/BiodiversitySourceFilter";
import CoordinatesAssetMissing from "../../common/CoordinatesAssetMissing";

export default function BiodiversityTable({currentSource}: {
  currentSource?: BIODIVERSITY_SOURCE | (typeof ALL_BIODIVERSITY_SOURCE)
}) {
  const [_, {asset}] = useAssetDetails()
  const {t} = useTranslation()
  const [pagination, setPagination] = useState<PaginationConfig>({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    showTotal: (total, range) => t('table.pageSizeOfTotal', {
      rangeStart: range[0],
      rangeEnd: range[1],
      total,
    }),
    onChange: (page, pageSize) => {
      setPagination(prevPag => ({...prevPag, current: page, pageSize}))
    },
    style: {textAlign: 'center', marginTop: '1rem'},
  })
  const [sorters, setSorters] = useState<BiodiversitySortersFE>({})
  const [filters, setFilters] = useState<BiodiversityFiltersFE>({})
  const isMissingCoordinates = asset?.position === null

  const {
    data,
    isFetching,
    isUninitialized
  } = useGetAssetBiodiversityPaginatedQuery(asset?.uuid && !isMissingCoordinates ? {
    assetUuid: asset.uuid,
    page: pagination.current,
    page_size: pagination.pageSize,
    ...currentSource && currentSource !== 'ALL' && {data_source: currentSource},
    ...fromFeToBeBiodiversityTableFilters(filters),
    ...(!isEmpty(fromFeToBeSorter(sorters)) ? {
      ordering: fromFeToBeSorter(sorters),
    } : {}),
  } : skipToken)


  const handleUpdateSorter = (sorter: keyof BiodiversitySortersFE, value: Sorter) => {
    if (value) {
      setSorters(prevState => ({
        ...prevState,
        [sorter]: value
      }))
    } else {
      setSorters(prevState => {
        const updatedState = cloneDeep(prevState)
        delete updatedState[sorter]
        return updatedState
      })
    }
  }

  const onRemoveFilter = (filters: (keyof BiodiversityFiltersFE)[]) => {
    setFilters(prevState => {
      const updatedState = cloneDeep(prevState)
      filters.forEach(filter => delete updatedState[filter])
      return updatedState
    })
    setPagination(prevPagination => ({...prevPagination, current: 1}))
  }

  const onUpdateFilter = (updatedFilters: BiodiversityFiltersFE) => {
    setFilters(prevState => ({...prevState, ...updatedFilters}))
    setPagination(prevPagination => ({...prevPagination, current: 1}))
  }
  const distance = filters['distance'] === undefined ? "" : filters['distance']

  const columns = [
    {
      key: 'name',
      dataIndex: ['biodiversity', 'name'],
      title: capitalize(t('assets.biodiversityName'))
    },
    {
      key: 'designation',
      dataIndex: ['biodiversity', 'designation'],
      title: capitalize(t('assets.biodiversityDesignation'))
    },
    {
      key: 'designation_eng',
      dataIndex: ['biodiversity', 'designation_eng'],
      title: capitalize(t('assets.biodiversityDesignationEng'))
    },
    {
      key: 'designation_type',
      dataIndex: ['biodiversity', 'designation_type'],
      title: capitalize(t('assets.biodiversityDesignationType'))
    },
    {
      key: 'wdpa_id',
      dataIndex: ['biodiversity', 'wdpa_id'],
      title: 'WDPA ID'
    },
    {
      key: 'distance',
      dataIndex: 'distance',
      title: <DocumentsTableColumnHeader
        title={capitalize(t('assets.biodiversityDistance'))}
        sorter={<TableSorter
          value={sorters.distance || null}
          onChange={(v) => handleUpdateSorter('distance', v)}
        />}
        filter={<div style={{maxWidth: '15rem', display: 'flex', gap: '0.5rem', flexDirection: 'column'}}>
          <Typography.Text>{capitalize(t('assets.biodiversityMaxDistance'))}</Typography.Text>
          <BiodiversityInput
            value={distance}
            onValueChange={({floatValue}) => {
              if (typeof floatValue === 'number' && floatValue >= 0) {
                onUpdateFilter({distance: floatValue})
              } else {
                onRemoveFilter(getFiltersOfTheBiodiversityTableColumn('distance'))
              }
            }}
          />
        </div>}
        onFilterReset={() => onRemoveFilter(getFiltersOfTheBiodiversityTableColumn('distance'))}
        isFilterDisabled={!filters.distance}
      />,
    }
  ]


  return <>
    {isMissingCoordinates ?
      <CoordinatesAssetMissing/>
      :
      <>
        <Table
          loading={(isFetching || isUninitialized) && !isMissingCoordinates}
          columns={columns}
          pagination={false}
          scroll={{x: true}}
          rowKey={'uuid'}
          dataSource={data?.results}
        />
        {!!data?.count && <Pagination
          {...pagination}
          total={data?.count}
        />}
      </>}
  </>
}