import {createContext, Dispatch, SetStateAction, useContext, useEffect, useState} from 'react';
import {Outlet} from 'react-router-dom';
import {message, MessageArgsProps} from 'antd';
import {useSelector} from 'react-redux';
import {selectMessage} from '../../redux/slices/messages';

export type Message = { success: string } | { error: string } | { custom: MessageArgsProps }

const Context = createContext<Dispatch<SetStateAction<Message | undefined>>>(() => undefined);
export const useMessageContext = () => useContext(Context);

export default function MessageContext() {
  const [antdMessage, setAntdMessage] = useState<Message | undefined>();
  const [messageApi, contextHolder] = message.useMessage();
  const rtkMessage = useSelector(selectMessage);

  useEffect(() => {
    if (antdMessage && 'success' in antdMessage) {
      messageApi.open({type: 'success', content: antdMessage.success});
    }
    if (antdMessage && 'error' in antdMessage) {
      messageApi.open({type: 'error', content: antdMessage.error});
    }
    if (antdMessage && 'custom' in antdMessage) {
      messageApi.open(antdMessage.custom);
    }
  }, [antdMessage, messageApi]);

  useEffect(() => {
    setAntdMessage(rtkMessage);
  }, [rtkMessage]);

  return <Context.Provider value={setAntdMessage}>
    <Outlet/>
    {contextHolder}
  </Context.Provider>;
}