import Icon from '@ant-design/icons';

const MathCompassSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20px"
    height="20px"
    viewBox="0 0 20 20" version="1.1"
  >
    <g id="surface1">
      <path
        d="M 10 0 C 9.691406 0 9.445312 0.25 9.445312 0.554688 L 9.445312 2.269531 C 7.867188 2.53125 6.667969 3.902344 6.667969 5.554688 C 6.667969 5.925781 6.726562 6.28125 6.839844 6.617188 L 1.488281 15.882812 C 0.667969 17.304688 1.152344 19.125 2.578125 19.949219 C 2.75 20.046875 2.964844 19.988281 3.066406 19.816406 L 6.167969 14.445312 L 9.445312 14.445312 L 9.445312 15.554688 C 9.445312 15.863281 9.691406 16.109375 10 16.109375 C 10.308594 16.109375 10.554688 15.863281 10.554688 15.554688 L 10.554688 14.445312 L 13.832031 14.445312 L 16.933594 19.816406 C 17.03125 19.988281 17.25 20.046875 17.421875 19.949219 C 18.84375 19.125 19.332031 17.304688 18.511719 15.882812 L 13.160156 6.617188 C 13.273438 6.285156 13.332031 5.925781 13.332031 5.554688 C 13.332031 3.902344 12.132812 2.53125 10.554688 2.269531 L 10.554688 0.554688 C 10.554688 0.25 10.308594 0 10 0 Z M 10 3.332031 C 8.773438 3.332031 7.777344 4.328125 7.777344 5.554688 C 7.777344 6.78125 8.773438 7.777344 10 7.777344 C 11.226562 7.777344 12.222656 6.78125 12.222656 5.554688 C 12.222656 4.328125 11.226562 3.332031 10 3.332031 Z M 10 8.890625 C 10.203125 8.890625 10.398438 8.871094 10.59375 8.835938 L 13.1875 13.332031 L 10.554688 13.332031 L 10.554688 12.222656 C 10.554688 11.914062 10.308594 11.667969 10 11.667969 C 9.691406 11.667969 9.445312 11.914062 9.445312 12.222656 L 9.445312 13.332031 L 6.808594 13.332031 L 9.40625 8.835938 C 9.601562 8.871094 9.800781 8.890625 10 8.890625 Z M 17.4375 18.472656 L 11.652344 8.449219 C 11.980469 8.261719 12.277344 8.019531 12.523438 7.734375 L 17.546875 16.4375 C 17.929688 17.097656 17.859375 17.894531 17.4375 18.472656 Z M 2.449219 16.4375 L 7.476562 7.734375 C 7.722656 8.019531 8.015625 8.261719 8.34375 8.449219 L 2.558594 18.472656 C 2.136719 17.894531 2.070312 17.097656 2.449219 16.4375 Z M 2.449219 16.4375 "
      />
    </g>
  </svg>
);

export const MathCompassIcon = () => (
  <Icon
    component={MathCompassSvg}
    className={'custom-icon'}
  />
);