import {Checkbox, Form, Input} from 'antd';
import {useTranslation} from 'react-i18next';
import {capitalize, debounce} from 'lodash';
import {NumericFormat, NumericFormatProps} from 'react-number-format';
import {useGetUserProfileQuery} from '../../../redux/api/locales';

export default function NumbersFilter({
                                        min,
                                        max,
                                        isNull,
                                        isNullable = true,
                                        onMaxChange,
                                        onMinChange,
                                        onNullChange,
                                        numericProps,
                                      }: {
  onNullChange?: (value: boolean) => void,
  onMinChange: (value: number | undefined) => void,
  onMaxChange: (value: number | undefined) => void,
  isNullable?: boolean,
  min?: number,
  max?: number,
  isNull?: boolean,
  numericProps?: NumericFormatProps
}) {

  const {t} = useTranslation();

  const debounceUpdateMin = debounce(onMinChange, 500);
  const debounceUpdateMax = debounce(onMaxChange, 500);

  const {data} = useGetUserProfileQuery();
  let props: NumericFormatProps<any> = {
    customInput: Input,
    disabled: isNull,
    decimalScale: 2,
    ...numericProps,
  };

  if (data?.language === 'it') {
    props = {
      thousandSeparator: '.',
      decimalSeparator: ',',
      ...props,
    };
  }

  return <Form
    layout={'vertical'}
  >
    <Form.Item
      label={capitalize(t('misc.min'))}
    >
      <NumericFormat
        value={min === undefined ? '' : min}
        onValueChange={value => {
          debounceUpdateMin(value.floatValue);
        }}
        placeholder={isNull ? '' : capitalize(t('table.filterPlaceholder', {column: t('misc.min')}))}
        {...props}
      />
    </Form.Item>
    <Form.Item
      label={capitalize(t('misc.max'))}
    >
      <NumericFormat
        value={max === undefined ? '' : max}
        onValueChange={value => {
          debounceUpdateMax(value.floatValue);
        }}
        placeholder={isNull ? '' : capitalize(t('table.filterPlaceholder', {column: t('misc.max')}))}
        {...props}
      />
    </Form.Item>
    {isNullable && <Checkbox
      checked={isNull}
      onChange={e => onNullChange && onNullChange(e.target.checked)}
      style={{marginTop: '1rem'}}
    >
      {capitalize(t('misc.nullValue'))}
    </Checkbox>}
  </Form>;
}