import {createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState} from 'react';

export type MapDrawerContextType = [
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>
];

const DrawerContext = createContext<MapDrawerContextType>([
  false,
  () => null,
]);

export const useMapDrawerContext = () => useContext(DrawerContext);

export const MapDrawerContext = (props: PropsWithChildren) => {

  const [isOpen, setIsOpen] = useState(false);

  return (<DrawerContext.Provider value={[isOpen, setIsOpen]}>
    {props.children}
  </DrawerContext.Provider>);
};