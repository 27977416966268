import DownloadButton from "../../common/buttons/DownloadButton";
import {capitalize} from "lodash";
import {useTranslation} from "react-i18next";
import {useLazyDownloadEventHistoryQuery} from "../../../redux/api/events";
import {useEventContext} from "../../../context/event/EventContext";
import {useMessageContext} from "../../../context/message/MessageContext";
import {ButtonProps} from "antd";

export default function EventHistoryDownloadButton({buttonProps}: { buttonProps?: ButtonProps }) {

  const {t} = useTranslation()
  const setMessage = useMessageContext()
  const [downloadReport, {isLoading}] = useLazyDownloadEventHistoryQuery()
  const {event} = useEventContext()

  return <DownloadButton
    loading={isLoading}
    disabled={isLoading}
    onClick={async () => {
      if (event?.uuid) {
        try {
          await downloadReport({eventUuid: event.uuid}).unwrap()
          setMessage({
            success: capitalize(t('documents.downloadSuccess'))
          })
        } catch {
          setMessage({
            error: capitalize(t('documents.downloadError'))
          })
        }
      }
    }}
    children={capitalize(t('actions.downloadItem', {item: 'report'}))}
    {...buttonProps}
  />
}