import {Form, FormItemProps, Typography} from 'antd';

type ExtendedProps = FormItemProps & { isBoldLabel?: boolean }

export default function AntFormItem({isBoldLabel = true, ...props}: ExtendedProps) {
  const {label} = props;

  return <Form.Item
    {...props}
    label={isBoldLabel ? <Typography.Text strong>{label}</Typography.Text> : label}
  />;
}