import {useState} from 'react';
import {Button, Col, Modal, Row, Typography} from 'antd';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import {useEventContext} from '../../../context/event/EventContext';
import {useGetUserProfileQuery} from '../../../redux/api/locales';
import {dateToLocaleString} from '../../../utils/dateParsers';
import {EVENT_STATES} from "../../../types/api/getEventsResult";
import {usePatchEventMutation} from "../../../redux/api/events";
import {VALUE_NOT_PRESENT} from "../../../utils/translation";

const {Text} = Typography;
export const EventNotHappenedModal = () => {

  const {t} = useTranslation();

  const {event} = useEventContext();
  const {
    data: userData,
  } = useGetUserProfileQuery();
  const [updateEvent] = usePatchEventMutation()

  const [
    isModalOpen,
    setIsModalOpen,
  ] = useState(false);

  return (<>
    <Modal
      title={capitalize(t('events.eventNotOccurred'))}
      centered={true}
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      okButtonProps={{
        danger: true,
        type: 'primary',
        onClick: () => {
          if (event) {
            updateEvent({uuid: event?.uuid, state: EVENT_STATES["Not Happened"]})
          }
        }
      }}
      onOk={() => {
        setIsModalOpen(false);
      }}
      okText={capitalize(t('events.closeAndArchive'))}
    >
      <Row style={{marginTop: '1rem'}}>
        <Col>
          <Text>
            {capitalize(t('events.eventNotHappenedModal1'))}
          </Text>
        </Col>
      </Row>
      <Row style={{marginTop: '1rem'}}>
        <Col>
          <Text strong={true}>
            {(event?.forecast_start_datetime && userData?.language) ? dateToLocaleString(event.forecast_start_datetime, userData.language, 'HH:mm') : VALUE_NOT_PRESENT}
          </Text>
        </Col>
      </Row>
      <Row style={{marginTop: '1rem'}}>
        <Col>
          <Text>
            {capitalize(t('events.eventNotHappenedModal2'))}
          </Text>
        </Col>
      </Row>
    </Modal>
    <Button type={'default'} onClick={() => setIsModalOpen(true)}>
      {capitalize(t('events.eventNotOccurred'))}
    </Button>
  </>);
};