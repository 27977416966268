import {Feature, multiPolygon, MultiPolygon, Position} from '@turf/helpers';
import {isContained, toMultiPolygon} from './polygons';
import difference from '@turf/difference';
import booleanEqual from '@turf/boolean-equal';
import {CutGeometries, DrawableGeometry} from '../components/common/map/controls/newDrawControls/NewDrawControls';
import {AssetMarker} from '../types/api/getEventsResult';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import {cloneDeep} from 'lodash';

export function cutPolygons(drawnGeometries: DrawableGeometry[], cutterPolygon: Feature<MultiPolygon, any>) {
  return drawnGeometries
    .map(geometry => {
      const polygonToCut = multiPolygon(geometry.coordinates);
      if (isContained(polygonToCut, cutterPolygon)) {
        return {uuid: geometry.uuid, coordinates: null};
      } else {
        const polygonsDifference = difference(polygonToCut, cutterPolygon);
        
        if (polygonsDifference && !booleanEqual(polygonToCut, toMultiPolygon(polygonsDifference))) {
          const diffPolygon: Feature<MultiPolygon, {}> = toMultiPolygon(polygonsDifference);
          return {
            uuid: geometry.uuid,
            coordinates: diffPolygon.geometry.coordinates,
          };
        } else {
          return undefined;
        }
      }
    })
    .filter(geometry => geometry !== undefined) as CutGeometries[];
}

export function getMarkersIncludedInPolygons(markers: AssetMarker[], polygons: {
  uuid: string,
  coordinates: Position[][][]
}[]) {
  return markers.map(marker => ({
    markerUuid: marker.uuid,
    includedPolygons: polygons.filter(polygon => booleanPointInPolygon(
      [marker.position.lng, marker.position.lat],
      multiPolygon(polygon.coordinates))),
  }));
}

export type AssetRequest = { cluster: string | null, assets: string[] }
export function insertAssetRequest(
  currentRequests: AssetRequest[],
  newRequest: { asset: string, cluster: string | null }) {
  const updatedRequests = cloneDeep(currentRequests);
  const isAlreadyPresent = updatedRequests.find(el => newRequest.cluster === el.cluster);
  if (isAlreadyPresent) {
    isAlreadyPresent.assets = [...isAlreadyPresent.assets, newRequest.asset];
  } else {
    updatedRequests.push({cluster: newRequest.cluster, assets: [newRequest.asset]});
  }
  return updatedRequests;
  
}