import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '../store';
import {Message} from '../../context/message/MessageContext';

// Define a type for the slice state
type MessagesState = { message: Message | undefined }

// Define the initial state using that type
const initialState = {
  message: undefined,
} as MessagesState;

export const messagesSlice = createSlice({
  name: 'messages',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setMessage: (state, {payload}: PayloadAction<Message>) => {
      state.message = payload;
    },
  },
});

export const {
  setMessage,
} = messagesSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectMessage = (state: RootState) => state.messages.message;
export default messagesSlice.reducer;
