import {Popup, useMap} from 'react-leaflet';
import React, {useCallback, useEffect, useState} from 'react';
import {useLazyGetAlertPolygonsNotPaginatedQuery} from '../../../../redux/api/alerts';
import {
  GlobalPolygon,
  useLazyGetGeoshapesEntityQuery,
  useLazyGetGlobalGeoshapesQuery
} from '../../../../redux/api/geoshapes';
import L from 'leaflet';
import {useMessageContext} from '../../../../context/message/MessageContext';
import {useAlertContext} from '../../../../context/alertDetails/AlertDetails';
import {CustomPolygon} from '../../../common/map/CustomPolygon';
import {flipTurfFeatureCoordinates} from '../../../../utils/polygons';
import {multiPolygon} from '@turf/helpers';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import AlertPolygonInfo from './AlertPolygonInfo';
import {SelectCluster} from '../../../events/importAlertsAreas/AssignCluster';
import {useEventContext} from '../../../../context/event/EventContext';
import {Divider, Typography} from 'antd';
import {useMapLoadingContext} from "../../../../context/baseMap/MapLoadingContext";
import useCenterMap from "../../../../customHooks/useCenterMap";

export default function AlertPolygons({isImportAlert = false}: { isImportAlert?: boolean }) {

  const map = useMap();
  const [alert] = useAlertContext();
  const setMessage = useMessageContext();
  const [globalPolygons, setGlobalPolygons] = useState<GlobalPolygon[]>([]);
  const {t} = useTranslation();

  const [getAlertPolygons, {data: alertPolygons}] = useLazyGetAlertPolygonsNotPaginatedQuery();
  const [getPolygons] = useLazyGetGlobalGeoshapesQuery();
  const [getEntity] = useLazyGetGeoshapesEntityQuery();
  const {clusters, onInsertPolygon, eventPolygons, entities} = useEventContext();
  const [, setLoading] = useMapLoadingContext()

  const handleGetPolygons = useCallback(async () => {
    if (alert) {
      try {
        const res = await getAlertPolygons({uuid: alert.uuid}).unwrap();
        const promises = res.map(el => getPolygons({uuid: el.globalpolygon}).unwrap());
        const polygons = await Promise.all(promises);
        setGlobalPolygons(polygons);
      } catch {
        setMessage({error: capitalize(t('form.fetchingFailMessage'))});
      } finally {

      }
    }
  }, [alert, getAlertPolygons, getPolygons, setMessage, t]);

  useEffect(() => {
    handleGetPolygons();
  }, [handleGetPolygons]);


  const marker = alert?.epicenter || undefined
  useCenterMap({map, globalPolygons, marker})


  return <>
    {globalPolygons.map((polygon) => {

      const globalPolygonUuid = polygon.properties.uuid;
      const alertPolygon = alertPolygons?.find(el => el.globalpolygon === globalPolygonUuid);
      const color = alertPolygon?.data?.color || '';
      const polygonAlreadyImported = !!(eventPolygons && alertPolygon && entities &&
        (
          eventPolygons.some(eventPolygon => eventPolygon.alert_polygon_uuid === alertPolygon.uuid) ||
          entities.some(entity => entity.uuid === alertPolygon.entity_uuid)
        )
      )

      return (
        <CustomPolygon
          key={globalPolygonUuid}
          positions={flipTurfFeatureCoordinates(multiPolygon(polygon.geometry.coordinates)).geometry.coordinates as L.LatLngExpression[][][]}
          pathOptions={{
            color,
          }}
        >
          {alertPolygon && <Popup className={'alert-polygon-popup'}>
            <AlertPolygonInfo alertPolygon={alertPolygon}/>
            {isImportAlert && <>
              <Divider/>
              <Typography.Title level={5} style={{margin: 0}}>
                {capitalize(t('clusters.assignCluster'))}
              </Typography.Title>

              {polygonAlreadyImported && <Typography.Paragraph italic>
                {capitalize(t('alerts.polygonAlreadyAssigned'))}
              </Typography.Paragraph>}
              {!polygonAlreadyImported && <SelectCluster
                clusters={clusters}
                select={{
                  style: {marginTop: '0.5rem'},
                  onSelect: async (value) => {
                    setLoading(true)
                    try {
                      if (alertPolygon.entity_uuid) {
                        const entity = await getEntity({uuid: alertPolygon.entity_uuid}).unwrap()
                        await onInsertPolygon({type: 'entity', payload: entity, cluster: value});
                      } else {
                        await onInsertPolygon({type: 'globalPolygon', payload: polygon, cluster: value});
                      }
                    } catch {
                    } finally {
                      setLoading(false)
                    }
                  },
                }}
              />}
            </>}
          </Popup>}
        </CustomPolygon>
      );
    })}
  </>;
}