import {Typography} from 'antd';
import {capitalize} from 'lodash';
import EventTypeNames from '../EventTypeNames';
import React from 'react';
import {EventTypeForm} from '../../../../types/internal/eventTypes';
import {useTranslation} from 'react-i18next';

export default function EventTypeNamesForm({eventValue, setEventValue}:
                                              {
                                                eventValue: EventTypeForm,
                                                setEventValue: React.Dispatch<React.SetStateAction<EventTypeForm>>
                                              }) {

  const {t} = useTranslation();

  return <EventTypeNames
    enLabel={{
      input: {
        value: eventValue.name_en || '',
        onChange: (e) => setEventValue(prevState => ({...prevState, name_en: e.target.value})),
      },
      formItem: {
        label: <Typography.Text
          strong
        >{capitalize(t('events.eventTypeName_en'))}</Typography.Text>,
      },
    }}
    itLabel={{
      input: {
        value: eventValue.name_it || '',
        onChange: (e) => setEventValue(prevState => ({...prevState, name_it: e.target.value})),
      },
      formItem: {
        label: <Typography.Text
          strong
        >{capitalize(t('events.eventTypeName_it'))}</Typography.Text>,
      },
    }}
  />;
}