import {useGetEventCommunicationQuery, usePatchCommunicationMutation} from "../../../../redux/api/events";
import EditButton from "../../../common/buttons/EditButton";
import {Button, Col, Form, Modal, Row, Skeleton} from "antd";
import CommunicationForm from "../CommunicationForm";
import {skipToken} from "@reduxjs/toolkit/query";
import {useState} from "react";
import {capitalize} from "lodash";
import {useTranslation} from "react-i18next";
import {useMessageContext} from "../../../../context/message/MessageContext";
import {CommunicationStatus} from "../../../../types/api/communications";
import PermissionRoute from "../../../Authentication/PermissionRoute";
import {isEventCommunicationEdit} from "../../../Authentication/permissions";

export default function EditCommunication({communicationUuid, eventUuid}: {
  communicationUuid: string,
  eventUuid: string
}) {

  const [patchCommunication, {isLoading: isLoadingPatch}] = usePatchCommunicationMutation()
  const [form] = Form.useForm()
  const {t} = useTranslation()
  const setMessage = useMessageContext()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const {
    data: eventCommunicationData,
    isFetching: isFetchingEventCommunicationData,
    isUninitialized: isUninitializedEventCommunicationData
  } = useGetEventCommunicationQuery(isModalOpen ? {
    communicationUuid,
    eventUuid
  } : skipToken)

  const isLoadingEventData = isFetchingEventCommunicationData || isUninitializedEventCommunicationData
  const handleSubmit = async (type: CommunicationStatus.draft | CommunicationStatus.sent) => {
    try {
      await form.validateFields()
      const values = form.getFieldsValue()
      await patchCommunication({...values, eventUuid, status: type, communicationUuid}).unwrap()
      setIsModalOpen(false)
      setMessage({success: capitalize(t('form.editSuccessMessage'))});
    } catch {
      setMessage({error: capitalize(t('form.editFailMessage'))});
    }
  }

  return <PermissionRoute isAuthorized={isEventCommunicationEdit}>
    <Modal
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      title={capitalize(t('actions.editItem', {item: t('events.communication', {count: 1})}))}
      footer={<Row justify={'end'} gutter={[16, 16]}>
        <Col>
          <Button
            onClick={() => handleSubmit(CommunicationStatus.draft)}
            disabled={isLoadingEventData || isLoadingPatch}>
            {capitalize(t('events.communicationSave'))}
          </Button>
        </Col>
        <Col>
          <Button
            disabled={isLoadingEventData || isLoadingPatch}
            type={'primary'}
            onClick={() => handleSubmit(CommunicationStatus.sent)}>
            {capitalize(t('mailTemplate.sendCommunication'))}
          </Button>
        </Col>
      </Row>
      }
    >
      {isLoadingEventData && <Skeleton/>}
      {!isLoadingEventData && <CommunicationForm
        key={eventCommunicationData?.uuid}
        form={form}
        initialValues={eventCommunicationData}
      />}
    </Modal>
    <EditButton
      onClick={() => setIsModalOpen(true)}
      children={null}
      type={'text'}
    />
  </PermissionRoute>
}