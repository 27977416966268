import {useRefreshTokenMutation} from '../../redux/api/authApiSlice';
import {useEffect, useState} from 'react';
import {AUTH_FROM_LOGIN, getRefreshToken, REDIRECT_TO} from './authUtils';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {setAccessToken} from '../../redux/slices/auth';
import Loading from '../../pages/Loading';
import {useMessageContext} from '../../context/message/MessageContext';
import {useTranslation} from 'react-i18next';

export default function VerifyRefreshToken() {
  const [refreshToken] = useRefreshTokenMutation();
  const [isRefreshTokenVerified, setIsRefreshTokenVerified] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const setMessage = useMessageContext();
  const {t} = useTranslation();

  useEffect(() => {
    const verify = async () => {
      const isAuthFromLogin = !!sessionStorage.getItem(AUTH_FROM_LOGIN);
      if (!isAuthFromLogin) {
        const refreshTokenInLS = getRefreshToken();
        let isTokenValid = false;
        if (refreshTokenInLS) {
          try {
            const {access} = await refreshToken({refresh: refreshTokenInLS}).unwrap();
            dispatch(setAccessToken({token: access}));
            isTokenValid = true;
          } catch {
          }
        }
        if (!isTokenValid) {
          sessionStorage.setItem(REDIRECT_TO, location.pathname);
          setMessage({error: t('misc.unauthorizedError')});
          navigate('/logout');
        }
      } else {
        sessionStorage.removeItem((AUTH_FROM_LOGIN));
      }
      setIsRefreshTokenVerified(true);
    };
    verify();
    // eslint-disable-next-line
  }, [dispatch, navigate, refreshToken]);

  return !isRefreshTokenVerified ? <Loading/> : <Outlet/>;
}