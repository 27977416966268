import {Button, ButtonProps, Col, Divider, Modal, ModalProps, Row, Typography} from 'antd';
import {TitleProps} from 'antd/lib/typography/Title';
import {ParagraphProps} from 'antd/lib/typography/Paragraph';

export default function ModalMessage({title, paragraph, modal, confirmButton}: {
  title: TitleProps,
  paragraph: ParagraphProps,
  modal?: ModalProps,
  confirmButton?: ButtonProps
}) {

  return <Modal
    footer={null}
    centered
    closable={false}
    {...modal}
  >
    <Typography.Title
      level={5}
      style={{margin: 0}}
      {...title}
    />
    <Divider/>
    <Typography.Paragraph
      style={{padding: '1rem 0'}}
      {...paragraph}
    />
    <Divider/>
    <Row
      justify={'end'}
    >
      {!!confirmButton && <Col>
        <Button
          type={'primary'}
          {...confirmButton}
        />
      </Col>}
    </Row>
  </Modal>;
}