import {useEventContext} from "../../context/event/EventContext";
import {Button, Typography} from "antd";
import {DownloadOutlined} from "@ant-design/icons";
import {useLazyDownloadEventAssetsReportQuery} from "../../redux/api/events";
import {capitalize} from "lodash";
import {useTranslation} from "react-i18next";
import {useMessageContext} from "../../context/message/MessageContext";

export default function EventAssetsDownloadButton() {
  const {event} = useEventContext()
  const {t} = useTranslation()
  const setMessage = useMessageContext()
  const [getReport, {isFetching}] = useLazyDownloadEventAssetsReportQuery()
  const handleClick = () => {
    if (event?.uuid) {
      getReport({eventUuid: event.uuid})
        .then(res => {
          setMessage({
            success: capitalize(t('documents.downloadSuccess'))
          })
        })
        .catch(err => {
          setMessage({
            error: capitalize(t('documents.downloadError'))
          })
        })
    }
  }

  return <Button
    block
    disabled={isFetching}
    loading={isFetching}
    icon={<DownloadOutlined/>}
    onClick={handleClick}
  >
    <Typography.Text>{capitalize(t('events.downloadAssets'))}</Typography.Text>
  </Button>
}