import {
  Button,
  ButtonProps,
  Card,
  CardProps,
  Col,
  Form,
  FormItemProps,
  FormProps,
  Input,
  Popover,
  Row,
  Select,
  Space,
  Upload,
} from 'antd';
import {Trans, useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
import {useState} from 'react';
import {OptionLabelValue} from '../../../types/internal/OptionLabelValue';
import {QuestionCircleTwoTone, UploadOutlined} from '@ant-design/icons';

export default function LayerForm({isEditing, form, card, button, uploadFormItem}: {
  isEditing?: boolean,
  form?: FormProps,
  card?: CardProps,
  button?: ButtonProps,
  uploadFormItem?: FormItemProps
}) {

  const {t} = useTranslation();
  const [uploadMode, setUploadMode] = useState<'ext' | 'rei'>(form?.initialValues?.upload_mode || 'ext');
  const [formInstance] = Form.useForm();
  const selectOptions: OptionLabelValue[] = [
    {label: capitalize(t('layers.form.url')), value: 'ext'},
    {label: capitalize(t('layers.form.zip')), value: 'rei'},
  ];

  return <Form
    form={formInstance}
    layout={'vertical'}
    onFinish={values => console.log(values)}
    initialValues={{
      upload_mode: uploadMode,
    }}
    {...form}
  >
    <Card
      {...card}
    >
      <Row gutter={[24, 24]}>
        <Col xs={24} md={12}>
          <Form.Item
            label={capitalize(t('layers.form.title'))}
            name={'frontend_name'}
            rules={[
              {
                required: true,
                message: capitalize(t('layers.form.layerNameRequired')),
                validator: (_, value) => {
                  if (!value || value.trim() === '') {
                    return Promise.reject();
                  }
                  return Promise.resolve();
                },
              }]}
          >
            <Input/>
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            label={capitalize(t('layers.form.layerSource'))}
            name={'layer_source'}
            rules={[
              {
                required: true,
                message: capitalize(t('layers.form.layerSourceRequired')),
                validator: (_, value) => {
                  if (!value || value.trim() === '') {
                    return Promise.reject();
                  }
                  return Promise.resolve();
                },
              }]}
          >
            <Input/>
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item
            label={capitalize(t('layers.form.notes'))}
            name={'description'}
            rules={[{type: 'string', max: 256}]}
          >
            <Input.TextArea maxLength={256}/>
          </Form.Item>
        </Col>
        {!isEditing && <Col xs={24} md={12}>
          <Form.Item
            label={capitalize(t('layers.form.uploadMode'))}
            name={'upload_mode'}
          >
            <Select
              options={selectOptions}
              onSelect={value => setUploadMode(value)}
            />
          </Form.Item>
        </Col>}
        {uploadMode === 'ext' && <Col xs={24}>
          <Form.Item
            name={'frontend_url'}
            label={<Space>
              {capitalize(t('layers.form.url'))}
              <Popover
                title={capitalize(t('layers.form.formatUrl'))}
                content={t('layers.form.tipUrl')}
              >
                <QuestionCircleTwoTone/>
              </Popover>
            </Space>}
            rules={[{required: true, type: 'url'}]}
          >
            <Input/>
          </Form.Item>
        </Col>}
        {!isEditing && uploadMode === 'rei' && <Col xs={24}>
          <Form.Item
            name={'layer_file'}
            label={<Space>
              {capitalize(t('actions.uploadFile'))}
              <Popover
                title={capitalize(t('layers.form.formatFile'))}
                content={<Trans
                  i18nKey={'layers.form.tipFile'} components={{li: <li/>, ul: <ul/>}}
                />}
              >
                <QuestionCircleTwoTone/>
              </Popover>
            </Space>}
            rules={[{required: true}]}
            {...uploadFormItem}
          >
            <Upload.Dragger
              maxCount={1}
              beforeUpload={file => {
                if (file.type === 'application/zip') {
                  formInstance.setFieldValue('layer_file', file);
                }
                return false;
              }}
              accept={'.zip,.rar,.7zip'}

            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1.5rem',
                  alignItems: 'center',
                  padding: '2.5rem 0',
                }}
              >
                <UploadOutlined
                  style={{
                    fontSize: '3rem',
                    color: '#1677ff',
                  }}
                />
                {capitalize(t('documents.dragOrSelect'))}
              </div>
            </Upload.Dragger>
          </Form.Item>
        </Col>}
      </Row>
    </Card>
    <Row justify={'end'} style={{padding: '1rem 0'}}>
      <Col>
        <Form.Item>
          <Button
            type={'primary'}
            htmlType={'submit'}
            {...button}
          />
        </Form.Item>

      </Col>
    </Row>
  </Form>;
}