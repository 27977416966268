import L from "leaflet";
import {GlobalPolygon} from "../redux/api/geoshapes";
import {useEventContext} from "../context/event/EventContext";
import {useMap} from "react-leaflet";
import {useCallback, useEffect, useMemo} from "react";
import {DrawableGeometryExtend} from "../context/alertSteps/AlertStepsContext";
import bbox from "@turf/bbox";
import {multiPolygon} from "@turf/helpers";

export function useEventClusterCenterMap(clusterUuid: string, isLoading: boolean) {

  const map = useMap();
  const {globalPolygons, event, assets, getGlobalPolygonsFromCluster} = useEventContext()
  const clusterGlobalPolygons = useMemo(() => getGlobalPolygonsFromCluster(clusterUuid), [clusterUuid, getGlobalPolygonsFromCluster])
  const clusterAssets = assets.filter(el => el.cluster_template === clusterUuid)

  const marker = clusterAssets.length ? clusterAssets[0].position : event?.epicenter || (assets.length && assets[0].position) || undefined
  const polygons = clusterGlobalPolygons.length ? clusterGlobalPolygons : globalPolygons
  useCenterMap({map, globalPolygons: polygons, marker, isLoading})
  return
}

export function useEventCenterMap(isLoading: boolean) {
  const {globalPolygons, event, assets} = useEventContext()
  const map = useMap();
  const eventAssets = assets.filter(el => el.cluster_template)
  const marker = event?.epicenter || (eventAssets.length && eventAssets[0].position) || undefined
  useCenterMap({map, globalPolygons, marker, isLoading})
  return
}

export function useAlertCenterMap({map, drawnGeometries = [], isLoading = false}: {
  map: L.Map,
  drawnGeometries?:  DrawableGeometryExtend[],
  isLoading?: boolean
}) {

  const centerMap = useCallback(() => {
    if (!isLoading) {
      if (drawnGeometries.length) {
        const bBoxes = drawnGeometries.map(el => bbox(multiPolygon(el.coordinates)));
        const mainBoundingBox = L.latLngBounds([]);
        bBoxes.forEach((bbox) => {
          mainBoundingBox.extend(L.latLngBounds(
            L.latLng(bbox[1], bbox[0]),
            L.latLng(bbox[3], bbox[2]),
          ));
        });
        map.fitBounds(mainBoundingBox);
      }
    }
  }, [drawnGeometries, isLoading, map])

  useEffect(() => {
    centerMap()
  }, [centerMap]);

  return
}

export default function useCenterMap({map, globalPolygons = [], marker, isLoading = false}: {
  map: L.Map,
  globalPolygons?: GlobalPolygon[],
  marker?: { lat: number, lng: number },
  isLoading?: boolean
}) {

  const centerMap = useCallback(() => {
    if (!isLoading) {
      if (globalPolygons.length) {
        const bBoxes = globalPolygons.map(polygon => polygon.bbox);
        const mainBoundingBox = L.latLngBounds([]);
        bBoxes.forEach((bbox) => {
          mainBoundingBox.extend(L.latLngBounds(
            L.latLng(bbox[1], bbox[0]),
            L.latLng(bbox[3], bbox[2]),
          ));
        });
        map.fitBounds(mainBoundingBox);
      }
      if (!globalPolygons.length && marker) {
        map.setView(marker, 10)
      }
    }
  }, [globalPolygons, isLoading, map, marker])

  useEffect(() => {
    centerMap()
  }, [centerMap]);

  return
}