import {CustomIconComponentProps} from '@ant-design/icons/lib/components/Icon';
import Icon from '@ant-design/icons';

const ManSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg" width="24px" height="24px"
    viewBox="-1 -1 21 21" version="1.1"
  >
    <g id="surface1">
      <path
        style={{stroke: '#000', strokeWidth: 0.5}}
        d="M 9.542969 0.0546875 C 9.191406 0.140625 8.902344 0.308594 8.609375 0.601562 C 8.199219 1.019531 8.039062 1.40625 8.039062 1.992188 C 8.042969 3.480469 9.621094 4.410156 10.941406 3.707031 C 11.863281 3.214844 12.230469 2.058594 11.761719 1.113281 C 11.347656 0.28125 10.425781 -0.160156 9.542969 0.0546875 Z M 9.542969 0.0546875 "
      />
      <path
        style={{stroke: '#000', strokeWidth: 0.5}}
        d="M 7.308594 4.769531 C 6.636719 4.902344 6.066406 5.480469 5.9375 6.167969 C 5.914062 6.300781 5.898438 7.5 5.898438 9.382812 C 5.898438 12.003906 5.90625 12.402344 5.957031 12.53125 C 6.078125 12.816406 6.304688 12.976562 6.597656 12.980469 C 6.851562 12.984375 7.058594 12.871094 7.191406 12.660156 L 7.304688 12.484375 L 7.304688 7.460938 L 7.398438 7.402344 C 7.472656 7.351562 7.550781 7.351562 7.625 7.398438 C 7.632812 7.398438 7.644531 10.046875 7.65625 13.28125 L 7.675781 19.160156 L 7.757812 19.335938 C 7.941406 19.722656 8.316406 19.960938 8.75 19.960938 C 9.183594 19.957031 9.554688 19.71875 9.730469 19.324219 L 9.824219 19.121094 L 9.835938 15.714844 C 9.847656 12.371094 9.847656 12.304688 9.921875 12.265625 C 9.976562 12.234375 10.023438 12.234375 10.078125 12.265625 C 10.152344 12.304688 10.152344 12.371094 10.164062 15.734375 L 10.175781 19.160156 L 10.285156 19.382812 C 10.527344 19.871094 11.046875 20.097656 11.582031 19.945312 C 11.757812 19.890625 11.851562 19.832031 12.011719 19.671875 C 12.371094 19.3125 12.34375 19.847656 12.34375 13.257812 C 12.34375 8.046875 12.351562 7.460938 12.40625 7.40625 C 12.480469 7.328125 12.5 7.328125 12.609375 7.40625 L 12.695312 7.464844 L 12.695312 9.964844 C 12.695312 12.378906 12.699219 12.46875 12.773438 12.617188 C 12.984375 13.035156 13.570312 13.113281 13.894531 12.765625 C 14.105469 12.539062 14.101562 12.628906 14.101562 9.382812 C 14.101562 7.5 14.085938 6.300781 14.0625 6.167969 C 13.929688 5.464844 13.363281 4.898438 12.660156 4.765625 C 12.402344 4.71875 7.558594 4.71875 7.308594 4.769531 Z M 7.308594 4.769531 "
      />
    </g>
  </svg>
);

export const ManIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ManSvg} {...props}/>
);