import Icon from '@ant-design/icons';

const BringToFrontSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg" width="20px" height="20px"
    viewBox="0 0 20 20" version="1.1"
  >
    <g id="surface1">
      <path
        d="M 2.046875 1.253906 C 1.714844 1.347656 1.34375 1.726562 1.25 2.0625 C 1.226562 2.15625 1.210938 3.863281 1.210938 7.015625 C 1.210938 11.328125 1.21875 11.839844 1.273438 12.007812 C 1.359375 12.257812 1.664062 12.582031 1.921875 12.699219 L 2.128906 12.792969 L 11.855469 12.792969 L 12.117188 12.664062 C 12.410156 12.519531 12.546875 12.382812 12.691406 12.070312 L 12.792969 11.855469 L 12.792969 2.128906 L 12.699219 1.921875 C 12.582031 1.664062 12.257812 1.359375 12.007812 1.273438 C 11.839844 1.21875 11.332031 1.210938 6.996094 1.214844 C 4.226562 1.214844 2.117188 1.234375 2.046875 1.253906 Z M 11.753906 2.035156 C 12.007812 2.125 11.992188 1.824219 11.992188 7.03125 L 11.992188 11.765625 L 11.765625 11.992188 L 7.035156 11.992188 C 3.582031 11.992188 2.28125 11.980469 2.210938 11.949219 C 1.980469 11.84375 1.992188 12.085938 2 6.941406 C 2.011719 2.296875 2.011719 2.234375 2.089844 2.148438 C 2.132812 2.101562 2.203125 2.046875 2.246094 2.027344 C 2.371094 1.976562 11.605469 1.984375 11.753906 2.035156 Z M 11.753906 2.035156 "
      />
      <path
        d="M 13.59375 7.597656 L 13.59375 8.007812 L 15.644531 8.007812 C 17.089844 8.007812 17.71875 8.019531 17.789062 8.050781 C 18.019531 8.15625 18.007812 7.914062 18 13.0625 L 17.988281 17.773438 L 17.878906 17.878906 L 17.773438 17.988281 L 13.0625 18 C 7.914062 18.007812 8.15625 18.019531 8.050781 17.789062 C 8.019531 17.71875 8.007812 17.089844 8.007812 15.644531 L 8.007812 13.59375 L 7.183594 13.59375 L 7.195312 15.730469 L 7.207031 17.871094 L 7.300781 18.078125 C 7.417969 18.339844 7.746094 18.644531 8 18.726562 C 8.167969 18.785156 8.683594 18.789062 13.070312 18.78125 L 17.949219 18.769531 L 18.15625 18.65625 C 18.390625 18.527344 18.550781 18.359375 18.679688 18.113281 L 18.769531 17.949219 L 18.78125 13.070312 C 18.789062 8.683594 18.785156 8.167969 18.726562 8 C 18.644531 7.746094 18.339844 7.417969 18.078125 7.300781 L 17.871094 7.207031 L 15.734375 7.195312 L 13.59375 7.183594 Z M 13.59375 7.597656 "
      />
      <path
        d="M 13.59375 8.789062 C 13.59375 9.175781 13.59375 9.179688 13.6875 9.179688 C 13.898438 9.179688 14.058594 8.808594 13.945312 8.589844 C 13.90625 8.515625 13.714844 8.398438 13.632812 8.398438 C 13.609375 8.398438 13.59375 8.542969 13.59375 8.789062 Z M 13.59375 8.789062 "
      />
      <path
        d="M 15.820312 8.445312 C 15.691406 8.511719 15.574219 8.753906 15.609375 8.882812 C 15.652344 9.050781 15.8125 9.179688 15.984375 9.179688 C 16.179688 9.179688 16.273438 9.128906 16.351562 8.976562 C 16.519531 8.652344 16.148438 8.277344 15.820312 8.445312 Z M 15.820312 8.445312 "
      />
      <path
        d="M 14.527344 9.722656 C 14.4375 9.8125 14.414062 9.871094 14.414062 10 C 14.414062 10.457031 15.066406 10.542969 15.179688 10.101562 C 15.277344 9.710938 14.8125 9.441406 14.527344 9.722656 Z M 14.527344 9.722656 "
      />
      <path
        d="M 16.996094 9.664062 C 16.640625 9.863281 16.800781 10.390625 17.21875 10.390625 C 17.363281 10.390625 17.496094 10.289062 17.5625 10.132812 C 17.703125 9.800781 17.320312 9.484375 16.996094 9.664062 Z M 16.996094 9.664062 "
      />
      <path
        d="M 13.59375 11.210938 C 13.59375 11.457031 13.609375 11.601562 13.632812 11.601562 C 13.714844 11.601562 13.90625 11.484375 13.945312 11.410156 C 14.058594 11.191406 13.898438 10.820312 13.6875 10.820312 C 13.59375 10.820312 13.59375 10.824219 13.59375 11.210938 Z M 13.59375 11.210938 "
      />
      <path
        d="M 15.746094 10.902344 C 15.5625 11.074219 15.5625 11.339844 15.746094 11.507812 C 15.867188 11.617188 16.136719 11.609375 16.265625 11.488281 C 16.453125 11.3125 16.421875 10.972656 16.214844 10.859375 C 16.082031 10.792969 15.84375 10.8125 15.746094 10.902344 Z M 15.746094 10.902344 "
      />
      <path
        d="M 14.632812 12.050781 C 14.472656 12.136719 14.417969 12.222656 14.414062 12.390625 C 14.414062 12.613281 14.585938 12.792969 14.804688 12.792969 C 14.941406 12.792969 14.988281 12.769531 15.082031 12.664062 C 15.234375 12.496094 15.234375 12.308594 15.089844 12.148438 C 14.957031 12.003906 14.785156 11.96875 14.632812 12.050781 Z M 14.632812 12.050781 "
      />
      <path
        d="M 17.050781 12.027344 C 16.902344 12.089844 16.816406 12.226562 16.816406 12.402344 C 16.816406 12.597656 16.898438 12.710938 17.089844 12.773438 C 17.25 12.824219 17.46875 12.75 17.539062 12.617188 C 17.597656 12.507812 17.597656 12.300781 17.542969 12.191406 C 17.457031 12.035156 17.222656 11.957031 17.050781 12.027344 Z M 17.050781 12.027344 "
      />
      <path
        d="M 13.394531 13.257812 C 13.175781 13.382812 13.148438 13.75 13.347656 13.90625 C 13.710938 14.191406 14.199219 13.691406 13.894531 13.339844 C 13.789062 13.214844 13.542969 13.175781 13.394531 13.257812 Z M 13.394531 13.257812 "
      />
      <path
        d="M 15.785156 13.265625 C 15.671875 13.347656 15.574219 13.574219 15.609375 13.683594 C 15.621094 13.730469 15.679688 13.816406 15.734375 13.875 C 15.984375 14.144531 16.457031 13.898438 16.390625 13.535156 C 16.339844 13.265625 16 13.117188 15.785156 13.265625 Z M 15.785156 13.265625 "
      />
      <path
        d="M 8.398438 13.632812 C 8.398438 13.714844 8.515625 13.90625 8.589844 13.945312 C 8.808594 14.058594 9.179688 13.898438 9.179688 13.6875 C 9.179688 13.59375 9.175781 13.59375 8.789062 13.59375 C 8.542969 13.59375 8.398438 13.609375 8.398438 13.632812 Z M 8.398438 13.632812 "
      />
      <path
        d="M 10.820312 13.6875 C 10.820312 13.898438 11.191406 14.058594 11.410156 13.945312 C 11.484375 13.90625 11.601562 13.714844 11.601562 13.632812 C 11.601562 13.609375 11.457031 13.59375 11.210938 13.59375 C 10.824219 13.59375 10.820312 13.59375 10.820312 13.6875 Z M 10.820312 13.6875 "
      />
      <path
        d="M 9.722656 14.527344 C 9.507812 14.742188 9.605469 15.105469 9.898438 15.179688 C 10.289062 15.277344 10.558594 14.8125 10.277344 14.527344 C 10.1875 14.4375 10.128906 14.414062 10 14.414062 C 9.871094 14.414062 9.8125 14.4375 9.722656 14.527344 Z M 9.722656 14.527344 "
      />
      <path
        d="M 12.160156 14.484375 C 11.960938 14.640625 11.957031 14.929688 12.15625 15.097656 C 12.316406 15.234375 12.5 15.226562 12.664062 15.082031 C 12.769531 14.988281 12.792969 14.941406 12.792969 14.804688 C 12.792969 14.667969 12.769531 14.621094 12.664062 14.527344 C 12.515625 14.394531 12.296875 14.375 12.160156 14.484375 Z M 12.160156 14.484375 "
      />
      <path
        d="M 14.527344 14.527344 C 14.308594 14.75 14.414062 15.125 14.714844 15.179688 C 15.003906 15.234375 15.234375 15.003906 15.179688 14.710938 C 15.125 14.414062 14.746094 14.308594 14.527344 14.527344 Z M 14.527344 14.527344 "
      />
      <path
        d="M 16.980469 14.457031 C 16.890625 14.507812 16.796875 14.699219 16.796875 14.828125 C 16.796875 14.890625 16.84375 14.976562 16.929688 15.0625 C 17.105469 15.238281 17.300781 15.246094 17.464844 15.082031 C 17.636719 14.910156 17.632812 14.683594 17.457031 14.519531 C 17.335938 14.40625 17.117188 14.378906 16.980469 14.457031 Z M 16.980469 14.457031 "
      />
      <path
        d="M 8.632812 15.65625 C 8.464844 15.738281 8.390625 15.875 8.414062 16.050781 C 8.453125 16.324219 8.738281 16.472656 8.976562 16.351562 C 9.128906 16.273438 9.179688 16.179688 9.179688 15.988281 C 9.179688 15.820312 9.082031 15.683594 8.917969 15.621094 C 8.792969 15.574219 8.792969 15.574219 8.632812 15.65625 Z M 8.632812 15.65625 "
      />
      <path
        d="M 11.039062 15.644531 C 10.878906 15.730469 10.824219 15.816406 10.820312 15.988281 C 10.820312 16.179688 10.871094 16.273438 11.023438 16.351562 C 11.335938 16.515625 11.695312 16.1875 11.566406 15.851562 C 11.492188 15.652344 11.222656 15.546875 11.039062 15.644531 Z M 11.039062 15.644531 "
      />
      <path
        d="M 13.453125 15.640625 C 13.195312 15.753906 13.125 16.035156 13.304688 16.246094 C 13.550781 16.539062 13.980469 16.378906 13.984375 15.992188 C 13.984375 15.714844 13.703125 15.53125 13.453125 15.640625 Z M 13.453125 15.640625 "
      />
      <path
        d="M 15.828125 15.644531 C 15.429688 15.816406 15.621094 16.453125 16.050781 16.390625 C 16.449219 16.332031 16.519531 15.796875 16.152344 15.640625 C 15.992188 15.574219 15.988281 15.574219 15.828125 15.644531 Z M 15.828125 15.644531 "
      />
      <path
        d="M 9.808594 16.851562 C 9.621094 16.957031 9.550781 17.242188 9.671875 17.417969 C 9.707031 17.46875 9.796875 17.53125 9.867188 17.5625 C 9.980469 17.609375 10.019531 17.609375 10.132812 17.5625 C 10.289062 17.496094 10.390625 17.363281 10.390625 17.21875 C 10.390625 16.898438 10.078125 16.703125 9.808594 16.851562 Z M 9.808594 16.851562 "
      />
      <path
        d="M 12.265625 16.824219 C 12.046875 16.910156 11.941406 17.171875 12.046875 17.375 C 12.121094 17.523438 12.230469 17.585938 12.402344 17.585938 C 12.582031 17.585938 12.691406 17.515625 12.757812 17.355469 C 12.824219 17.199219 12.785156 17.007812 12.660156 16.898438 C 12.582031 16.828125 12.359375 16.785156 12.265625 16.824219 Z M 12.265625 16.824219 "
      />
      <path
        d="M 14.601562 16.859375 C 14.382812 16.976562 14.34375 17.265625 14.523438 17.457031 C 14.683594 17.632812 14.910156 17.636719 15.082031 17.464844 C 15.246094 17.300781 15.238281 17.105469 15.0625 16.929688 C 14.914062 16.78125 14.777344 16.761719 14.601562 16.859375 Z M 14.601562 16.859375 "
      />
      <path
        d="M 17.050781 16.832031 C 16.722656 16.96875 16.742188 17.480469 17.078125 17.570312 C 17.25 17.613281 17.363281 17.585938 17.476562 17.476562 C 17.773438 17.175781 17.441406 16.671875 17.050781 16.832031 Z M 17.050781 16.832031 "
      />
    </g>
  </svg>
);

export const BringToFrontIcon = () => (
  <Icon
    component={BringToFrontSvg}
    className={'custom-icon'}
  />
);