import React, {useEffect, useMemo, useState} from 'react';
import {capitalize} from 'lodash';
import {
  AlertOutlined,
  FileOutlined,
  HeatMapOutlined,
  InfoCircleOutlined,
  MailOutlined,
  PushpinOutlined,
} from '@ant-design/icons';
import {Col, Layout, Menu, Row} from 'antd';
import {CustomSider} from '../common/CustomSider';
import {EventSummaryCard} from '../common/EventSummaryCard';
import {EventDetailsCard} from './eventDetails/EventDetailsCard';
import {EventStatusCard} from './eventStatus/EventStatusCard';
import {EventMovingHistoryCard} from './eventMovingHistory/EventMovingHistoryCard';
import EventCreationAlertsSection from '../events/creation/eventAlerts/EventCreationAlertsSection';
import EventDocuments from '../documents/TablesViews/EventDocuments';
import {useEventContext} from '../../context/event/EventContext';
import {CHANGES, PHASE} from '../../types/api/getEventsResult';
import SimplifiedLayout from '../../layout/SimplifiedLayout';
import {GoogleLayers} from '../../types/internal/Map';
import {BaseMapContainer} from '../common/map/BaseMapContainer';
import SummaryEventMap from './map/SummaryEventMap';
import {useTranslation} from 'react-i18next';
import Communications from './communications/Communications';
import {isEventCreation, isEventEdit} from "../Authentication/permissions";
import {useGetUserProfileQuery} from "../../redux/api/locales";

const {Content} = Layout;

const EventLiveContent = () => {

  const [
    currentStep,
    setCurrentStep,
  ] = useState('details');

  const {t} = useTranslation();
  const {event} = useEventContext();

  const items = useMemo(() => ([
    {
      key: 'details',
      label: capitalize(t('events.details')),
      icon: <InfoCircleOutlined/>,
    },
    {
      key: 'map',
      label: capitalize(t('map.map')),
      icon: <HeatMapOutlined/>,
    },
    {
      key: 'movingHistory',
      label: capitalize(t('events.changesHistory')),
      icon: <PushpinOutlined/>,
    },
    {
      key: 'communications',
      label: capitalize(t('events.communication', {count: 2})),
      icon: <MailOutlined/>,
    },
    {
      key: 'alerts',
      label: capitalize(t('events.alertsConnected')),
      icon: <AlertOutlined/>,
    },
    {
      key: 'documents',
      label: capitalize(t('documents.document', {count: 2})),
      icon: <FileOutlined/>,
    },
  ]), [t]);

  return <>
    <CustomSider
      backButtonLabel={capitalize(t('actions.goBackDestination', {destination: t('events.list')}))}
      destination={'/events'}
      title={capitalize(t('events.details'))}
      content={
        <Menu
          theme="dark"
          defaultSelectedKeys={['details']}
          onSelect={(item) => setCurrentStep(item.key)}
          mode="inline"
          items={items}
        />
      }
      summaryCard={<EventSummaryCard/>}
    />
    <Content style={{overflowY: 'scroll', display: 'flex', flexDirection: 'column'}}>
      <div style={{margin: '1.5rem 0 1.5rem 1rem', flexGrow: 1}}>
        <Row style={{height: '100%', width: '100%'}}>
          <Col span={24} style={{height: '100%', width: '100%'}}>
            {currentStep === 'details' && (
              <div style={{display: 'flex', flexDirection: 'column', height: '100%', gap: '1rem'}}>
                <EventDetailsCard/>
                <EventStatusCard/>
              </div>
            )}
            {currentStep === 'map' && (
              <BaseMapContainer
                withZoom={true}
                withGeoSearch={true}
                withLayerPicker={true}
                withBaseLayers={[GoogleLayers.roadmap, GoogleLayers.hybrid]}
                withScreenshot={true}
                withStyle={{width: '100%'}}
              >
                <SummaryEventMap/>
              </BaseMapContainer>
            )}
            {currentStep === 'movingHistory' && (
              <EventMovingHistoryCard/>
            )}
            {currentStep === 'communications' && (
              <Communications/>
            )}
            {currentStep === 'alerts' && (
              <EventCreationAlertsSection/>
            )}
            {!!event?.uuid && currentStep === 'documents' && (
              <EventDocuments/>
            )}
          </Col>
        </Row>
      </div>
    </Content>
  </>;
};

export default function EventLive() {

  const {event, isEditing} = useEventContext();
  const {
    data: userData,
  } = useGetUserProfileQuery();

  const isVisible = (event?.phase === PHASE.LIVE && !isEditing) ||
      (userData?.type && event?.phase === PHASE.EDIT && !isEventEdit(userData?.type)) ||
      (userData?.type && event?.phase === PHASE.CREATE && !isEventCreation(userData?.type))

  const {setPolygonsAndAssetsForTheMap} = useEventContext()
  useEffect(() => {
    if (isVisible) {
      setPolygonsAndAssetsForTheMap(CHANGES.WITHOUT)
    }
  }, [isVisible, setPolygonsAndAssetsForTheMap]);

  return (
    isVisible ? <SimplifiedLayout>
      <Layout style={{position: 'relative'}}>
        <EventLiveContent/>
      </Layout>
    </SimplifiedLayout> : null
  );
}