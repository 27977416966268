import {Card, Col, Input, Row, Select, Skeleton, Space, Typography} from 'antd';
import {CustomNumericInput} from '../common/CustomNumericInput';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import {useGetAssetsOptionsQuery} from '../../redux/api/assets';
import {SigiStatus} from '../../types/internal/AddAssetForm';
import {CustomLoading} from '../common/CustomLoading';
import {CheckCircleTwoTone, WarningTwoTone} from '@ant-design/icons';
import {yellow} from '@ant-design/colors';
import {LocalizedDatePicker} from '../common/LocalizedDatePicker';
import {useEditAssetDetailsContext} from '../../context/editAsset/EditAssetDetailsContext';
import {useEditFormContext} from '../../context/editAsset/EditAssetFormContext';
import Label from '../common/Label';
import {CompaniesSearchSelect} from '../common/searchSelect/CompaniesSearchSelect';
import {OptionLabelValue} from '../../types/internal/OptionLabelValue';
import {MasterAssetSearchSelect} from '../common/searchSelect/MasterAssetSearchSelect';
import {useEffect} from 'react';
import {useAssetDetails} from '../../context/assetDetails/AssetDetails';
import {useParams} from 'react-router-dom';

const {Text} = Typography;
export const EditAssetDetailFormCard = () => {

  const {t} = useTranslation();
  const {assetId} = useParams();

  const [setAssetUuid] = useAssetDetails();
  const [details, setDetails] = useEditAssetDetailsContext();
  const {isSigiValid, checkSigi} = useEditFormContext();
  const {isUserCreated, isLoading} = useEditFormContext();
  const {data: assetsOptions} = useGetAssetsOptionsQuery();

  useEffect(() => {
    if (assetId) {
      setAssetUuid(assetId);
    }
  }, [assetId, setAssetUuid]);

  return (
    <Card title={capitalize(t('assets.buildingDetails'))}>
      {isLoading && <Skeleton active={true}/>}
      {!isLoading && (
        <>
          <Row gutter={[40, 0]} align={'middle'}>
            <Col xs={24} lg={12}>
              <Label
                label={capitalize(t('assets.sigiCode'))} required={true}
                htmlFor={'sigi_code'}
              />
              <Input
                placeholder={capitalize(t('assets.sigiCode'))}
                disabled={!isUserCreated}
                id={'sigi_code'}
                value={details.sigi_code || ''}
                onChange={e => {
                  setDetails(details => ({
                    ...details,
                    sigi_code: e.target.value || null,
                  }));
                  checkSigi(e.target.value);
                }}
                style={{marginBottom: 20}}
              />
            </Col>
            <Col xs={24} lg={12}>
              <Space direction={'horizontal'} wrap={false}>
                {isSigiValid === SigiStatus.loading && (
                  <>
                    <CustomLoading style={{fontSize: 18}}/>
                    <Text type={'secondary'}>{capitalize(t('assets.validatingSigiCode'))}</Text>
                  </>
                )}
                {isSigiValid === SigiStatus.valid && (
                  <>
                    <CheckCircleTwoTone
                      twoToneColor={'#52c41a'} style={{fontSize: 18}}
                    />
                    <Text type={'secondary'}>{capitalize(t('assets.sigiCodeIsValid'))}</Text>
                  </>
                )}
                {isSigiValid === SigiStatus.not_valid && (
                  <>
                    <WarningTwoTone twoToneColor={yellow[5]} style={{fontSize: 18}}/>
                    <Text type={'secondary'}>{capitalize(t('assets.sigiCodeIsNotValid'))}</Text>
                  </>
                )}
              </Space>

            </Col>
          </Row>
          <Row gutter={[40, 0]}>
            <Col xs={24} lg={12}>
              <MasterAssetSearchSelect
                value={details.master_asset}
                disabled={!isUserCreated}
                onClear={() => {
                  setDetails(details => ({
                    ...details,
                    master_asset: null,
                  }));
                }}
                onSelect={(value, options) => {
                  setDetails(details => ({
                    ...details,
                    master_asset: options as OptionLabelValue,
                  }));
                }}
              />
            </Col>
          </Row>
          <Row gutter={[40, 0]}>
            <Col xs={24} lg={12}>
              <Label label={capitalize(t('assets.buildingType'))} htmlFor={'building_type'}/>
              <Select
                value={assetsOptions?.building_type.find(option => option.value === details.building_type)}
                disabled={!isUserCreated}
                onChange={e => {
                  if (e.value === 'SSR') {
                    setDetails(details => ({...details, building_type: e.value}));
                  } else {
                    setDetails(details => ({
                      ...details,
                      building_type: e.value,
                      building_type_detail: null,
                    }));
                  }
                }}
                id={'building_type'}
                labelInValue={true}
                options={assetsOptions?.building_type}
                style={{display: 'flex', marginBottom: 20}}
              />
            </Col>
            {details.building_type === 'SSR' && <Col xs={24} lg={12}>
              <Label
                label={capitalize(t('assets.buildingTypeDetail'))}
                htmlFor={'building_type_detail'}
              />
              <Select
                options={assetsOptions?.building_type_detail}
                value={assetsOptions?.building_type_detail.find(option => option.value === details.building_type_detail)}
                disabled={!isUserCreated}
                onChange={e => setDetails(details => ({
                  ...details,
                  building_type_detail: e.value,
                }))}
                id={'building_type_detail'}
                labelInValue={true}
                style={{display: 'flex', marginBottom: 20}}
              />
            </Col>}
          </Row>
          <Row gutter={[40, 0]}>
            <Col xs={24} lg={12}>
              <Label label={capitalize(t('assets.surfaceTotal'))} htmlFor={'total_surface'}/>
              <CustomNumericInput
                placeholder={capitalize(t('assets.surfaceTotal'))}
                value={details.surface_total}
                disabled={!isUserCreated}
                id={'total_surface'}
                suffix={' m²'}
                onValueChange={value =>
                  setDetails(details => ({
                    ...details,
                    surface_total: value.floatValue || 0,
                  }))
                }
                style={{marginBottom: 20, display: 'flex'}}
              />
            </Col>
            <Col xs={24} lg={12}>
              <Label
                label={capitalize(t('assets.surfaceBasement'))} htmlFor={'basement_surface'}
              />
              <CustomNumericInput
                placeholder={capitalize(t('assets.surfaceBasement'))}
                suffix={' m²'}
                id={'basement_surface'}
                value={details.surface_basement}
                disabled={!isUserCreated}
                onValueChange={value =>
                  setDetails(details => ({
                    ...details,
                    surface_basement: value.floatValue || 0,
                  }))}
                style={{marginBottom: 20}}
              />
            </Col>
          </Row>
          <Row gutter={[40, 0]}>
            <Col xs={24} lg={12}>
              <Label label={capitalize(t('assets.openingDate'))} htmlFor={'opening_date'}/>
              <LocalizedDatePicker
                value={details.opening_date}
                disabled={!isUserCreated}
                placeholder={t('assets.openingDate')}
                id={'opening_date'}
                onChange={e => setDetails(details => ({...details, opening_date: e}))}
                style={{marginBottom: 20, display: 'flex'}}
              />
            </Col>
            <Col xs={24} lg={12}>
              <Label label={capitalize(t('assets.closingDate'))} htmlFor={'closing_date'}/>
              <LocalizedDatePicker
                placeholder={t('assets.closingDate')}
                disabled={!isUserCreated}
                value={details.closing_date}
                id={'closing_date'}
                onChange={e => setDetails(details => ({...details, closing_date: e}))}
                style={{marginBottom: 20, display: 'flex'}}
              />
            </Col>
          </Row>
          <Row gutter={[40, 0]}>
            <Col xs={24} lg={12}>
              <CompaniesSearchSelect
                values={details.companies}
                disabled={!isUserCreated}
                onClear={() => {
                  setDetails(details => ({
                    ...details,
                    companies: [],
                  }));
                }}
                onChange={(value, options) => {
                  setDetails(details => ({
                    ...details,
                    companies: value as OptionLabelValue[],
                  }));
                }}
              />
            </Col>
            <Col xs={24} lg={12}>
              <Label label={capitalize(t('assets.totalResources'))} htmlFor={'totalResources'}/>
              <CustomNumericInput
                placeholder={t('assets.totalResources')}
                suffix={''}
                decimalScale={0}
                id={'totalResources'}
                value={details.total_resources}
                disabled={!isUserCreated}
                onValueChange={value =>
                  setDetails(details => ({
                    ...details,
                    total_resources: value.floatValue || null,
                  }))}
                style={{marginBottom: 20}}
              />
            </Col>
          </Row>
          <Row gutter={[40, 0]}>
            <Col xs={24} lg={12}>
              <Label label={capitalize(t('assets.isOwned'))} htmlFor={'ownership'}/>
              <Select
                options={assetsOptions?.ownership}
                value={assetsOptions?.ownership.find(option => option.value === details.ownership)}
                disabled={!isUserCreated}
                onChange={e => setDetails(details => ({...details, ownership: e.value}))}
                id={'ownership'}
                labelInValue={true}
                style={{display: 'flex', marginBottom: 20}}
              />
            </Col>
          </Row>
        </>
      )}
    </Card>
  );
};