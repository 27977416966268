import {apiSlice} from './baseApi';
import {GenericPaginatedApi} from '../../types/api/paginatedApi';

export type AlertSource = {
  'uuid': string,
  'name': string,
  'threshold': string | null,
  'description': string,
  'is_threshold_editable': boolean,
  'last_refresh': string,
  'schedule_info': string | null
}

export type ThresholdInfo = {
  'threshold': null | number,
  'is_editable': boolean,
  'unit': string | null,
  'min_value': null | number,
  'max_value': null | number,
}

export type AlertSourceTask = {
  'data': {
    'status': string,
    'text': string,
    'task_id': string
  },
  'status': number
}

const alertSourceApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAlertSourcePaginated: build.query<GenericPaginatedApi<AlertSource>, {
      page?: number,
      page_size?: number,
    }>({
      query: (params) => {
        return {
          url: '/alert-source/',
          params,
          paginate: true,
        };
      },
      providesTags: (res, error, arg) =>
        res ?
          [
            ...res?.results.map(({uuid}) => (
              {type: 'AlertSource' as const, id: uuid}
            )),
            {type: 'AlertSource', id: 'LIST'},
            'Locale',
          ]
          : [
            {type: 'AlertSource', id: 'LIST'},
            'Locale',
          ],
    }),
    getAlertSource: build.query<AlertSource, { alertSourceUuid: string }>({
      query: ({alertSourceUuid}) => {
        return {
          url: `/alert-source/${alertSourceUuid}/`,
        };
      },
      providesTags: (res, error, arg) => [{type: 'AlertSource', id: arg.alertSourceUuid}, 'Locale'],
    }),
    
    getThresholdInfo: build.query<ThresholdInfo, { alertSourceUuid: string }>({
      query: ({alertSourceUuid}) => {
        return {
          url: `/alert-source/${alertSourceUuid}/get_threshold_info/`,
        };
      },
    }),
    setThresholdAlertSource: build.mutation<void, { threshold: number, alertSourceUuid: string }>({
      query: ({alertSourceUuid, ...body}) => {
        return {
          url: `/alert-source/${alertSourceUuid}/set_threshold/`,
          method: 'PATCH',
          body,
        };
      },
      async onQueryStarted({alertSourceUuid, ...patch}, {dispatch, queryFulfilled}) {
        const patchResult = dispatch(
          alertSourceApi.util.updateQueryData('getThresholdInfo', {alertSourceUuid}, (draft) => {
            Object.assign(draft, patch);
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      
      invalidatesTags: (res, error, arg) => [
        {type: 'AlertSource', id: arg.alertSourceUuid}],
    }),
    refreshAllAlertSourcesData: build.query<AlertSourceTask[], void>({
      query: () => {
        return {
          url: '/alert-source/refresh_all_a_s_data/',
        };
      },
    }),
    refreshAlertSourceData: build.query<AlertSourceTask, { alertSourceUuid: string }>({
      query: ({alertSourceUuid}) => {
        return {
          url: `/alert-source/${alertSourceUuid}/refresh_one_a_s_data/`,
        };
      },
      transformResponse: (res: any, meta: any, arg) => {
        return {data: res, status: meta.response.status};
      },
    }),
    getAlertSourceTaskStatus: build.query<{ text: string, status: number, task_id: string }, { task_id: string }>({
      query: (params) => {
        return {
          url: '/alert-source/get_task_status/',
          params,
        };
      },
      transformResponse: (res: any, meta: any, arg) => {
        return {...res, status: meta.response.status, ...arg};
      },
    }),
    
  }),
});

export const {
  useGetAlertSourcePaginatedQuery,
  useGetAlertSourceQuery,
  useSetThresholdAlertSourceMutation,
  useGetThresholdInfoQuery,
  useLazyRefreshAllAlertSourcesDataQuery,
  useLazyGetAlertSourceTaskStatusQuery,
  useLazyRefreshAlertSourceDataQuery,
} = alertSourceApi;