import {useEffect, useState} from 'react';

export enum ConnectionStatus {
  wentOffline,
  wentOnline,
  online
}

export const useConnectionCheck = () => {
  
  const [status, setStatus] = useState<ConnectionStatus>(ConnectionStatus.online);
  
  useEffect(() => {
    const handleOnline = () => {
      setStatus(ConnectionStatus.wentOnline);
    };
    
    const handleOffline = () => {
      setStatus(ConnectionStatus.wentOffline);
    };
    
    window.addEventListener('online', handleOnline, {passive: true});
    window.addEventListener('offline', handleOffline, {passive: true});
    
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);
  
  return status;
};
