import {isReportsCreation} from "../Authentication/permissions";
import {Button, Modal, Radio, Tooltip, Typography} from "antd";
import {DownloadOutlined} from "@ant-design/icons";
import {capitalize} from "lodash";
import PermissionRoute from "../Authentication/PermissionRoute";
import {useTranslation} from "react-i18next";
import {useLazyDownloadAssetReportQuery} from "../../redux/api/asset";
import {useEffect, useState} from "react";
import {useMessageContext} from "../../context/message/MessageContext";
import {useAssetDetails} from "../../context/assetDetails/AssetDetails";

export default function DownloadAssetReport() {

    const {t} = useTranslation()
    const [download, {isSuccess, isError, isFetching}] = useLazyDownloadAssetReportQuery()
    const setAntdMessage = useMessageContext()
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [type, setType] = useState<'pdf' | 'docx'>('pdf')
    const [, {asset, loading}] = useAssetDetails();

    const closeModal = () => setIsModalVisible(false)
    const openModal = () => setIsModalVisible(true)

    const handleDownload = () => {
        if (asset.uuid) {
            download({
                assetUuid: asset.uuid,
                out_format: type,
                name: `${t('assets.downloadDetailsFilename')}_${asset.sigi_code}`
            })
        }
    }

    useEffect(() => {
        if (isSuccess) {
            setAntdMessage({
                success: capitalize(t('documents.downloadSuccess'))
            })
            closeModal()
        }
    }, [isSuccess, setAntdMessage, t])

    useEffect(() => {
        if (isError) {
            setAntdMessage({
                error: capitalize(t('documents.downloadError'))
            })
        }
    }, [isError, setAntdMessage, t])

    return <PermissionRoute isAuthorized={isReportsCreation}>
        <Modal
            title={capitalize(t('misc.downloadReport'))}
            open={isModalVisible}
            onCancel={closeModal}
            onOk={handleDownload}
            okButtonProps={{
                disabled: isFetching,
                loading: isFetching
            }}
        >
            <Typography.Paragraph>
                {capitalize(t('assets.downloadDetailsDescription'))}
            </Typography.Paragraph>
            <Radio.Group onChange={(e) => setType(e.target.value)} value={type}>
                <Radio value={'pdf'}>.pdf</Radio>
                <Radio value={'docx'}>.docx</Radio>
            </Radio.Group>

        </Modal>
        {!asset.position && <Tooltip
            title={capitalize(t('assets.downloadDetailsDisabled'))}
        >
            <Button
                type={"default"}
                style={{width: "100%"}}
                icon={<DownloadOutlined/>}
                disabled={!asset.position}
            >
                {capitalize(t("assets.downloadDetails"))}
            </Button>
        </Tooltip>
        }
        {!!asset.position && <Button
            type={"default"}
            style={{width: "100%"}}
            icon={<DownloadOutlined/>}
            onClick={openModal}
        >
            {capitalize(t("assets.downloadDetails"))}
        </Button>}
    </PermissionRoute>
}