import {parseOptions, useLazyGetAssetsRelatedOptionsForInfiniteScrollingQuery,} from '../../../../redux/api/assets';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {OptionLabelValue} from '../../../../types/internal/OptionLabelValue';
import {useDispatch, useSelector} from 'react-redux';
import {
  addFilters,
  insertFilterInExcludeMode,
  removeFilterInExcludeMode,
  removeFilters,
  selectAssets,
  selectAssetsTableFilters
} from '../../../../redux/slices/assets';
import {capitalize} from 'lodash';
import {getTranslationAssetTableColumnKey} from '../../../../utils/translation';
import {useTranslation} from 'react-i18next';
import {titleCase} from '../../../../utils/parser';
import {AssetsFiltersFE, fromFeToBeAssetsFilters} from "../../../../types/internal/tables/assetsTable";
import {selectMapFilters} from "../../../../redux/slices/mapFilters";
import NEWListWithSearchFilter from "../../../common/filters/NEWListWithSearchFilter";

export default function AssetRelatedOptionsFilter({field}: {
  field: 'address'
    | 'admin_level_1'
    | 'admin_level_2'
    | 'admin_level_3'
    | 'building_type'
    | 'building_type_detail'
    | 'companies'
    | 'contact_people'
    | 'country'
    | 'is_user_created'
    | 'master_asset'
    | 'ownership'
    | 'postal_code'
    | 'realestate_garrison'
    | 'realestate_hub'
    | 'sigi_code'
}) {

  const [getData, {
    data,
    isLoading,
    isFetching,
    isUninitialized,
    originalArgs,
  }] = useLazyGetAssetsRelatedOptionsForInfiniteScrollingQuery()
  const [querySearch, setQuerySearch] = useState<string>('');
  const options: OptionLabelValue[] = useMemo(() => parseOptions(data?.results || []).map(el =>
      ['address', 'admin_level_1', 'admin_level_3'].includes(field) ?
        {...el, label: el.label ? titleCase(el.label) : ''}
        : el),
    [data?.results, field]);
  const total = data?.count || 0;
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const placeholder = capitalize(t('table.filterPlaceholder', {column: t(getTranslationAssetTableColumnKey(field))}));

  const assetsTableFiltersWithoutCurrentFilterRef = useRef<AssetsFiltersFE>({})
  const assetsTableFilters = useSelector(selectAssetsTableFilters)
  const polygonFilter = useSelector(selectMapFilters).polygons;
  const selectedFilters = assetsTableFilters[field] || [];
  const filtersInExcludeMode = useSelector(selectAssets).assetsTable.filtersInExcludeMode
  const isExclude = filtersInExcludeMode.some(el => el === field)

  useEffect(() => {
    const {[field]: currentFilter, ...otherFilters} = assetsTableFilters
    assetsTableFiltersWithoutCurrentFilterRef.current = otherFilters
  }, [assetsTableFilters, field]);


  const handleGetNext = useCallback(() => {
    getData({
      column: field,
      ...!!querySearch && {column_search: querySearch},
      ...fromFeToBeAssetsFilters(assetsTableFiltersWithoutCurrentFilterRef.current, filtersInExcludeMode),
      ...!!polygonFilter?.length && {polygon_uuid: polygonFilter.map(polygon => polygon.globalpolygon).join(',')},
      page: (originalArgs?.page || 0) + 1,
      page_size: 15,
    });
  }, [field, filtersInExcludeMode, getData, originalArgs?.page, polygonFilter, querySearch]);

  useEffect(() => {
    getData({
      column: field,
      ...!!querySearch && {column_search: querySearch},
      ...fromFeToBeAssetsFilters(assetsTableFiltersWithoutCurrentFilterRef.current, filtersInExcludeMode),
      ...!!polygonFilter?.length && {polygon_uuid: polygonFilter.map(polygon => polygon.globalpolygon).join(',')},
      page: 1,
      page_size: 15,
    });
  }, [field, filtersInExcludeMode, getData, polygonFilter, querySearch]);

  return <>
    <NEWListWithSearchFilter
      getNext={handleGetNext}
      options={options}
      selectedOptions={selectedFilters}
      setSelectedOptions={values => {
        if (values.length) {
          dispatch(addFilters({[field]: values}));
        } else {
          dispatch(removeFilters(field));
        }
      }}
      total={total}
      isLoadingOptions={isLoading || isFetching || isUninitialized}
      querySearch={querySearch}
      setQuerySearch={setQuerySearch}
      search={{placeholder}}
      isExcludeFeature={true}
      isExclude={isExclude}
      setExcludeMode={() => {
        dispatch(insertFilterInExcludeMode(field));
        dispatch(removeFilters(field))
      }}
      setDeclarativeMode={() => {
        dispatch(removeFilterInExcludeMode(field));
        dispatch(removeFilters(field))
      }}
    />
  </>;
}