import _ from 'lodash';
import {OptionLabelValue} from '../types/internal/OptionLabelValue';
import {AlertModel, GetAlertsParams} from '../types/api/alerts';
import dayjs, {isDayjs} from 'dayjs';
import {AlertCreationForm, AlertTableFilters} from '../types/internal/Alerts';
import {Sorter} from '../types/internal/tables/sorters';
import {EventsMapFiltersBE, EventsMapFiltersFE} from '../context/emergenciesMap/EventsContext';
import {REDUCED_STATES} from '../types/api/getEventsResult';

export function fromFeToBeSorter(
  sorters: { [key: string]: Sorter }) {
  const ordering: string[] = [];
  for (const [key, value] of Object.entries(sorters)) {
    switch (value) {
      case 'ascend':
        ordering.push(key);
        break;
      case 'descend':
        ordering.push(`-${key}`);
        break;
    }
  }
  return ordering.join(',');
}

export function titleCase(str: string) {
  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
}

export const parseAlertForm = (values: AlertModel) => {
  return {
    description: values.description,
    end_datetime: values.end_datetime ? dayjs(values.end_datetime) : undefined,
    event_type: values.event_type,
    source_url: values.source_url,
    start_datetime: values.start_datetime ? dayjs(values.start_datetime) : undefined,
    title: values.title,
  } as AlertCreationForm;
};

export const parseAlertFormForBe = (values: AlertCreationForm) => {
  return {
    ...!!values.description && {description: values.description},
    event_type: values.event_type,
    ...!!values.source_url && {source_url: values.source_url},
    ...!!values.start_datetime && {start_datetime: values.start_datetime.toISOString()},
    ...!!values.end_datetime && {end_datetime: values.end_datetime.toISOString()},
    title: values.title,
  };
};

export function fromFeToBeAlertsFilter(filters: AlertTableFilters) {
  let parsedFilters: GetAlertsParams = {};
  for (const [key, value] of Object.entries(filters)) {
    if (!_.isEmpty(value)) {
      switch (key as keyof AlertTableFilters) {
        case 'event':
        case 'event_type':
        case 'is_forecast':
        case 'is_user_created':
          const param = (value as OptionLabelValue[]).map(el => el.value).toString();
          parsedFilters = {...parsedFilters, [key]: param};
          break;
        case 'title':
        case 'source_url':
          parsedFilters = {...parsedFilters, [`search_by_${key}`]: value};
          break;
        case 'end_datetime':
        case 'start_datetime':
          if (value?.length === 2) {
            const after = value[0];
            const before = value[1];
            parsedFilters = {...parsedFilters, [`${key}_after`]: after, [`${key}_before`]: before};
          }
          break;
        case 'assets_count':
          const min = (value as [number, number])[0];
          const max = (value as [number, number])[1];
          parsedFilters = {...parsedFilters, [`${key}_min`]: min, [`${key}_max`]: max};
          break;
      }
    }
  }
  return parsedFilters;
}

export function fromFeToBeEventsMap(filters: EventsMapFiltersFE): EventsMapFiltersBE {
  
  const beFilters: EventsMapFiltersBE = {};
  for (const [key, value] of Object.entries(filters)) {
    switch (key) {
      case 'start_datetime_after':
      case 'start_datetime_before':
        if (isDayjs(value)) {
          beFilters[key] = value.toISOString();
        }
        break;
      case 'event_type':
        if (Array.isArray(value) && value.length) {
          beFilters.event_type = value.toString();
        }
        break;
      case 'reduced_state':
        if (value) {
          beFilters.reduced_state = value as REDUCED_STATES;
        }
        break;
    }
    
  }
  return beFilters;
}