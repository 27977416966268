import {Input, InputProps} from 'antd';
import {FunctionComponent, useMemo} from 'react';

import {NumberFormatValues, NumericFormat, NumericFormatProps} from 'react-number-format';

export function isPercentageAllowed(values: NumberFormatValues): boolean {
  const floatValue = values.floatValue;
  return (!floatValue || (floatValue >= 0 && floatValue <= 100));
}

export const CustomNumericInput: FunctionComponent<NumericFormatProps<InputProps> & {
  onChange?: (x: number | undefined) => void,
  hasPlaceholder?: boolean
}> =
  ({
     onChange,
     hasPlaceholder = true,
     ...props
   }) => {

    const customNumberFormat = useMemo(() => {
      return <NumericFormat
        allowNegative={false}
        suffix={' €'}
        thousandSeparator={'.'}
        decimalSeparator=","
        customInput={Input}
        decimalScale={props.decimalScale !== undefined ? props.decimalScale : 2}
        fixedDecimalScale={true}
        style={{whiteSpace: 'nowrap', ...props.style}}
        {...(props.suffix?.includes('%') && {isAllowed: isPercentageAllowed})}
        {...(hasPlaceholder ? (props.suffix?.includes('%') ? {placeholder: '0.00 %'} : {placeholder: '0.00 €'}) : {})}
        {...props}
        // in order to works with antd (because the onChange of NumberFormat passes the formatted string)
        //https://ant.design/components/form/#components-form-demo-customized-form-controls

        /*onValueChange={(value) => {
          onChange?.(value.floatValue || 0)
        }}*/
        // onChange={(e: any) => console.log(e)}
      />;
    }, [hasPlaceholder, props]);

    return customNumberFormat;
  };
