import {apiSlice} from './baseApi';
import {GenericPaginatedApi, GenericPaginatedParamsApi} from '../../types/api/paginatedApi';
import {EmailTemplate} from '../../types/api/templateEmails';
import {sanitize} from 'dompurify';

export type getTemplateEmailsParams = {
  language?: string,
  ordering?: string,
  search_by_name?: string,
  tag?: string,
  title?: string,
}

const templateEmailsApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getTemplateEmails: build.query<GenericPaginatedApi<EmailTemplate>, GenericPaginatedParamsApi & getTemplateEmailsParams>({
      query: (args) => ({
        url: '/template-emails/',
        params: {
          ...args,
        },
      }),
      transformResponse: ({results, ...res}: GenericPaginatedApi<EmailTemplate>) => ({
        ...res,
        results: results.map(el => ({...el, text: sanitize(el.text)})),
      }),
      providesTags: (res, error, arg) =>
        res
          ? [
            ...res?.results.map(({uuid}) => ({type: 'TemplateEmail' as const, id: uuid})),
            {type: 'TemplateEmail', id: 'LIST'},
          ]
          : [{type: 'TemplateEmail', id: 'LIST'}],
    }),
    getTemplateEmailsForInfiniteScrolling: build.query<GenericPaginatedApi<EmailTemplate>, GenericPaginatedParamsApi & getTemplateEmailsParams>({
      query: ({page = 1, ...args}) => {
        return ({
          url: '/template-emails/',
          params: {
            page,
            ...args,
          },
        });
      },
      serializeQueryArgs: ({queryArgs}) => {
        const newQueryArgs = {...queryArgs};
        delete newQueryArgs.page;
        return newQueryArgs;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems, query) => {
        if ((query.arg.page || 0) > 1) {
          return {
            ...currentCache,
            ...newItems,
            results: [...currentCache.results, ...newItems.results.map(el => ({...el, text: sanitize(el.text)}))],
          };
        }
        return newItems;
      },
    }),
    createTemplateEmail: build.mutation<EmailTemplate, {
      title: string,
      tags: string[],
      text: string,
      language: 'it' | 'en'
    }>({
      query: ({text, ...args}) => ({
        url: '/template-emails/',
        method: 'POST',
        body: {
          ...args,
          text: sanitize(text),
        },
      }),
      invalidatesTags: [{type: 'TemplateEmail', id: 'LIST'}],
    }),
    deleteTemplateEmail: build.mutation<EmailTemplate, { templateUuid: string }>({
      query: (args) => ({
        url: `/template-emails/${args.templateUuid}/`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        {type: 'TemplateEmail', id: arg.templateUuid},
        {type: 'TemplateEmail', id: 'LIST'},
      ],
    }),
    editTemplateEmail: build.mutation<EmailTemplate, { templateUuid: string } & {
      title: string,
      tags: string[],
      text: string,
      language: 'it' | 'en'
    }>({
      query: ({templateUuid, text, ...body}) => ({
        url: `/template-emails/${templateUuid}/`,
        method: 'PUT',
        body: {
          ...body,
          text: sanitize(text),
        },
      }),
      invalidatesTags: (result, error, arg) => [{type: 'TemplateEmail', id: arg.templateUuid}, {
        type: 'TemplateEmail',
        id: 'LIST',
      }],
    }),
  }),
});

export const {
  useGetTemplateEmailsQuery,
  useCreateTemplateEmailMutation,
  useLazyGetTemplateEmailsForInfiniteScrollingQuery,
  useDeleteTemplateEmailMutation,
  useEditTemplateEmailMutation,
} = templateEmailsApi;