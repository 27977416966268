import dayjs from 'dayjs';
import {useTranslation} from 'react-i18next';
import {Form} from 'antd';
import {capitalize} from 'lodash';
import {LocalizedDatePicker} from '../../../../components/common/LocalizedDatePicker';

export default function DateEditFilter({start, end, onEndChange, onStartChange}: {
  onStartChange: (value: string | undefined) => void,
  onEndChange: (value: string | undefined) => void,
  start?: string,
  end?: string,
}) {
  
  const {t} = useTranslation();
  
  return (
    <Form layout={'vertical'}>
      <Form.Item label={capitalize(t('misc.start'))}>
        <LocalizedDatePicker
          value={start ? dayjs(start) : undefined}
          onChange={(v) => onStartChange(v ? v.format('YYYY-MM-DD') : undefined)}
        />
      
      </Form.Item>
      <Form.Item label={capitalize(t('misc.end'))}>
        <LocalizedDatePicker
          value={end ? dayjs(end) : undefined}
          onChange={(v) => onEndChange(v ? v.format('YYYY-MM-DD') : undefined)}
        />
      </Form.Item>
    </Form>);
}