import {createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useReducer, useState} from 'react';
import { GetEventsParams, REDUCED_STATES} from "../../types/api/getEventsResult";
import {Dayjs} from "dayjs";
import {EventsReducer, INITIAL_EVENTS_REDUCER_STATE} from "./EventsReducer";

export type EventsMapFiltersBE = Pick<GetEventsParams, 'reduced_state' | 'start_datetime_before' | 'start_datetime_after' | 'event_type'>
export type EventsMapFiltersFE = {
  'reduced_state': REDUCED_STATES | null,
  'start_datetime_after': Dayjs | null,
  'start_datetime_before': Dayjs | null,
  'event_type': string[]
}

export type EventsContextType = {
  filtersFE: EventsMapFiltersFE
  setFiltersFE: Dispatch<SetStateAction<EventsMapFiltersFE>>,
  selectEvent: (eventUuid: string) => void,
  unselectEvent: () => void
  showEventInMap: (eventUuid: string) => void,
  hideEventFromMap: (eventUuid: string) => void,
  reset: () => void
  selectedEvent: string | null,
  eventsInMap: string[]
}

export const DEFAUL_FILTERS_FE = {
  reduced_state: REDUCED_STATES.active,
  start_datetime_after: null,
  start_datetime_before: null,
  event_type: [],
}

export const NOT_SET_DEFAUL_FILTERS_FE = {
  reduced_state: null,
  start_datetime_after: null,
  start_datetime_before: null,
  event_type: [],
}

const Events = createContext<EventsContextType>({
  filtersFE: DEFAUL_FILTERS_FE,
  setFiltersFE: () => undefined,
  selectEvent: () => undefined,
  unselectEvent: () => undefined,
  showEventInMap: () => undefined,
  hideEventFromMap: () => undefined,
  reset: () => undefined,
  selectedEvent: null,
  eventsInMap: [],
})

export const useEventsContext = () => useContext(Events);

export const EventsContext = (props: PropsWithChildren) => {

  const [filtersFE, setFiltersFE] = useState<EventsMapFiltersFE>(DEFAUL_FILTERS_FE)
  const [{selectedEvent, eventsInMap}, eventsDispatch] = useReducer(EventsReducer, INITIAL_EVENTS_REDUCER_STATE)

  return (
    <Events.Provider
      value={{
        filtersFE,
        setFiltersFE,
        selectedEvent,
        eventsInMap,
        selectEvent: (payload) => eventsDispatch({payload: {eventUuid: payload}, type: 'selectEvent'}),
        unselectEvent: () => eventsDispatch({type: 'unselectEvent'}),
        showEventInMap: (payload) => eventsDispatch({payload: {eventUuid: payload}, type: 'showEventInMap'}),
        hideEventFromMap: (payload) => eventsDispatch({payload: {eventUuid: payload}, type: 'hideEventFromMap'}),
        reset: () => eventsDispatch({type: 'reset'}),
      }}>
      {props.children}
    </Events.Provider>
  );
};