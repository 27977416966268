import {useMapLoadingContext} from "../../../context/baseMap/MapLoadingContext";
import SelectGeoshapeEntities from "../creation/SelectGeoshapeEntities";
import {useAlertStepsContext} from "../../../context/alertSteps/AlertStepsContext";
import {useCallback} from "react";
import {OptionPolygon} from "../creation/AlertCreation";
import {Entity, useLazyGetGlobalGeoshapesQuery} from "../../../redux/api/geoshapes";
import {multiPolygon} from "@turf/helpers";
import {cutPolygons} from "../../../utils/map";
import {capitalize} from "lodash";
import {useTranslation} from "react-i18next";

export default function AlertGeoshapesSelect({type}: { type: 'cut' | 'insert' }) {

  const [
    isLoadingMap,
    setIsLoadingMap,
  ] = useMapLoadingContext();

  const {drawnGeometries, setDrawnGeometries, drawCount, setDrawCount} = useAlertStepsContext()
  const [getGeoshapes] = useLazyGetGlobalGeoshapesQuery();
  const {t} = useTranslation()

  const handleAddPolygonFromEntity = useCallback(async (opt: OptionPolygon, entity?: Entity) => {
    if (entity) {
      setIsLoadingMap(true)
      try {
        const polygon = await getGeoshapes({uuid: opt.globalpolygon}).unwrap()
        if (type === 'insert') {
          setDrawnGeometries(prevState => [...prevState, {
            coordinates: polygon.geometry.coordinates,
            uuid: opt.globalpolygon,
            entity: opt.value,
            name: opt.label
          }])
        } else {
          const cutterGeometry = multiPolygon(polygon.geometry.coordinates)
          const affectedGeometries = cutPolygons(drawnGeometries, cutterGeometry)

          setDrawnGeometries(prevState => {
            let updatedState = [...prevState]
            affectedGeometries.forEach(affectedGeometry => {
              const prevGeometry = updatedState.find(el => el.uuid === affectedGeometry.uuid)
              if (prevGeometry) {
                updatedState = updatedState.filter(el => el.uuid !== prevGeometry.uuid)
                if (affectedGeometry.coordinates) {
                  const updatedCounter = drawCount
                  setDrawCount(drawCount)
                  updatedState.push({
                    uuid: (updatedCounter).toString(),
                    coordinates: affectedGeometry.coordinates,
                    drawCount: updatedCounter,
                    name: capitalize(t('map.manualPolygon'))
                  })
                }
              }
            })
            return updatedState
          })

        }

      } catch {

      } finally {
        setIsLoadingMap(false)
      }

    }
  }, [drawCount, drawnGeometries, getGeoshapes, setDrawCount, setDrawnGeometries, setIsLoadingMap, type]);

  const handleRemoveGlobalPolygonOfAnEntity = (entityUuid: string) => {
    setDrawnGeometries(prevState => prevState.filter(el => el.entity !== entityUuid))
  };

  const selectedEntities = drawnGeometries
    .filter(el => el.entity)
    .map(el => el.entity as string)

  return <SelectGeoshapeEntities
    disabledSelect={isLoadingMap}
    selectGeoShape={handleAddPolygonFromEntity}
    unselectGeoShapes={(entityUuid, globalPolygonUuid) => handleRemoveGlobalPolygonOfAnEntity(entityUuid)}
    selectedGeoShapes={selectedEntities}
  />
}