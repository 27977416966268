import {Col, Pagination, Row, Table} from 'antd';
import {useTranslation} from 'react-i18next';
import _, {capitalize} from 'lodash';
import {ColumnsType} from 'antd/lib/table';
import {useGetTemplateEmailsQuery} from '../../../redux/api/templateEmails';
import {CustomLoading} from '../../common/CustomLoading';
import React from 'react';
import {ExpandableTagList} from '../../common/ExpandableTagList';
import {EmailTemplate} from '../../../types/api/templateEmails';
import {useDispatch, useSelector} from 'react-redux';
import {
  selectTemplateMail,
  setTemplateEmailFilters,
  setTemplateEmailPagination,
  setTemplateEmailSorters,
} from '../../../redux/slices/templateMails';
import {DocumentsTableColumnHeader} from '../../documents/TablesViews/DocumentsTableColumnHeader';
import TableSorter from '../../common/TableSorter';
import {TemplateEmailTitleFilter} from './filters/TemplateEmailTitleFilter';
import {TemplateEmailTagFilter} from './filters/TemplateEmailTagFilter';
import {TemplateEmailLanguageFilter} from './filters/TemplateEmailLanguageFilter';
import {fromFeToBeSorter} from '../../../utils/parser';
import {TemplateMailActions} from './TemplateMailActions';

export default function TemplateMailTable() {

  const {t} = useTranslation();
  const dispatch = useDispatch();

  const pagination = useSelector(selectTemplateMail).pagination;
  const filters = useSelector(selectTemplateMail).filters;
  const sorters = useSelector(selectTemplateMail).sorters;

  const {
    data: mailTemplates,
    isLoading: areMailTemplatesLoading,
    isFetching: areMailTemplatesFetching,
  } = useGetTemplateEmailsQuery({
    page: pagination.current,
    page_size: pagination.pageSize,
    ...(filters.title ? {
      title: filters.title?.map(title => title.value).join(','),
    } : {}),
    ...(filters.tags ? {
      tags: filters.tags?.map(tag => tag.value).join(','),
    } : {}),
    ...(filters.language ? {
      language: filters.language.map(language => language.value).join(','),
    } : {}),
    ordering: fromFeToBeSorter(sorters),
  });

  const columns: ColumnsType<any> = [
    {
      title: (
        <DocumentsTableColumnHeader
          title={capitalize(t('mailTemplate.title'))}
          sorter={<TableSorter
            value={sorters.title}
            onChange={type => dispatch(setTemplateEmailSorters({title: type}))}
          />}
          filter={<TemplateEmailTitleFilter
            activeFilters={filters.title}
            handleFilterChange={(values) => dispatch(setTemplateEmailFilters({title: values}))}
          />}
          onFilterReset={() => dispatch(setTemplateEmailFilters({title: null}))}
          isFilterDisabled={_.isEmpty(filters.title)}
        />
      ),
      key: 'title',
      dataIndex: 'title',
    },
    {
      title: (
        <DocumentsTableColumnHeader
          title={capitalize(t('mailTemplate.tag'))}
          sorter={<TableSorter
            value={sorters.tags}
            onChange={type => dispatch(setTemplateEmailSorters({tags: type}))}
          />}
          filter={<TemplateEmailTagFilter
            activeFilters={filters.tags}
            handleFilterChange={(values) => dispatch(setTemplateEmailFilters({tags: values}))}
          />}
          onFilterReset={() => dispatch(setTemplateEmailFilters({tags: null}))}
          isFilterDisabled={_.isEmpty(filters.tags)}
        />
      ),
      key: 'tags',
      dataIndex: 'tags',
      render: (text: string, record: EmailTemplate, index: number) => {

        return (<ExpandableTagList
          tags={record.tags.map(tag => ({
            uuid: tag.frontend_name,
            content: tag.frontend_name,
            color: tag.color,
          }))}
        />);
      },
    },
    {
      title: (
        <DocumentsTableColumnHeader
          title={capitalize(t('mailTemplate.language'))}
          sorter={<TableSorter
            value={sorters.language}
            onChange={type => dispatch(setTemplateEmailSorters({language: type}))}
          />}
          filter={<TemplateEmailLanguageFilter
            activeFilters={filters.language}
            handleFilterChange={(values) => dispatch(setTemplateEmailFilters({language: values}))}
          />}
          onFilterReset={() => dispatch(setTemplateEmailFilters({language: null}))}
          isFilterDisabled={_.isEmpty(filters.language)}
        />
      ),
      key: 'language',
      dataIndex: 'language',
      render: (text: string, record: EmailTemplate, index: number) => {
        return <ExpandableTagList
          customTag={false}
          tags={[{
            uuid: record.language,
            content: record.language === 'it' ? capitalize(t('misc.italian')) : capitalize(t('misc.english')),
          }]}
        />;
      },
    },
    {
      key: 'actions',
      render: (text: string, record: EmailTemplate, index: number) => {
        return <TemplateMailActions template={record}/>;
      },
    },

  ];

  const isLoading = areMailTemplatesLoading || areMailTemplatesFetching;

  return (
    <div
      style={{minHeight: '30rem'}}
    >
      <Table
        dataSource={(mailTemplates?.results.map(result => ({...result, key: result.uuid}))) || []}
        columns={columns}
        pagination={false}
        scroll={{x: true}}
        loading={
          {
            spinning: isLoading,
            indicator: <CustomLoading/>,
          }
        }
      />
      <Row
        justify={'center'}
        style={{marginTop: '1rem'}}
      >
        <Col>
          <Pagination
            disabled={!mailTemplates?.count}
            showSizeChanger={true}
            total={mailTemplates?.count}
            showTotal={(total, range) => t('table.pageSizeOfTotal', {
              rangeStart: range[0],
              rangeEnd: range[1],
              total,
            })}
            onChange={(current, pageSize) => {
              dispatch(setTemplateEmailPagination({current, pageSize}));
            }}
            {...pagination}
          />
        </Col>
      </Row>
    </div>
  );
}