import {createContext, PropsWithChildren, useCallback, useContext, useEffect, useState} from 'react';
import {useAssetDetails} from '../assetDetails/AssetDetails';
import {SigiStatus} from '../../types/internal/AddAssetForm';
import {useEditLocationContext} from './EditAssetLocationContext';
import {useEditAssetDetailsContext} from './EditAssetDetailsContext';
import {useEditAssetRealEstateContext} from './EditAssetRealEstateFormContext';
import {router} from '../../routing/Router';
import _, {capitalize} from 'lodash';
import {useLazyGetAssetBySigiCodeQuery} from '../../redux/api/asset';
import {usePatchAssetMutation} from '../../redux/api/assets';
import {message} from 'antd';
import {useTranslation} from 'react-i18next';

export type EditAssetForm = {
  isFirstStepComplete: () => boolean,
  isSecondStepComplete: () => boolean,
  isThirdStepComplete: () => boolean,
  isUserCreated: boolean
  submit: () => void,
  isLoading: boolean,
  isSigiValid: SigiStatus,
  checkSigi: Function,
};

const EditFormContext = createContext<EditAssetForm>({
  isFirstStepComplete: () => false,
  isSecondStepComplete: () => false,
  isThirdStepComplete: () => false,
  isUserCreated: false,
  submit: () => null,
  isLoading: false,
  isSigiValid: SigiStatus.to_be_checked,
  checkSigi: () => null,
});

export const useEditFormContext = () => useContext(EditFormContext);

export const EditAssetFormContext = (props: PropsWithChildren) => {

  const [location] = useEditLocationContext();
  const [details] = useEditAssetDetailsContext();
  const [realEstateDetails] = useEditAssetRealEstateContext();

  const [, {asset, loading: isAssetLoading}] = useAssetDetails();

  const {t} = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();

  const [isUserCreated, setIsUserCreated] = useState(false);
  const [
    sigiStatus,
    setSigiStatus,
  ] = useState<SigiStatus>(SigiStatus.to_be_checked);

  const [
    validateSigi,
    {
      data: assetsWithGivenSigiCode,
      isSuccess: isSigiCodeLookupSuccessful,
      isError: hasSigiCodeLookupFailed,
      isLoading: isSigiCodeLookupLoading,
      isFetching: isSigiCodeLookupFetching,
    },
  ] = useLazyGetAssetBySigiCodeQuery();

  const [
    patchAsset,
    {
      data: patchedAsset,
      isSuccess: isPatchSuccessful,
      isError: hasPatchFailed,
    }] = usePatchAssetMutation();

  const isFirstStepComplete = useCallback(() => {
    return (
        (location.position !== null &&
          location.country !== null &&
          location.country.trim() !== '' &&
          location.address !== null &&
          location.address.trim() !== '' &&
          location.admin_level_1 !== null &&
          location.admin_level_1.trim() !== '' &&
          location.admin_level_2 !== null &&
          location.admin_level_2.trim() !== '' &&
          location.admin_level_3 !== null &&
          location.admin_level_3.trim() !== '' &&
          location.postal_code !== null &&
          location.postal_code.trim() !== ''
        ) && asset.is_user_created) ||
      (location.position !== null && !asset.is_user_created);
  }, [asset.is_user_created, location]);

  const isSecondStepComplete = useCallback(() => {
    return details.sigi_code !== null && details.sigi_code.trim() !== '' && (sigiStatus === SigiStatus.valid || details.sigi_code === asset.sigi_code);
  }, [asset.sigi_code, details.sigi_code, sigiStatus]);

  const isThirdStepComplete = useCallback(() => {
    return true;
  }, []);

  useEffect(() => {
    if (asset) {
      setIsUserCreated(asset.is_user_created);
    }
  }, [asset]);

  useEffect(() => {
    let redirect: NodeJS.Timeout;
    if (isPatchSuccessful && patchedAsset) {
      messageApi.success(capitalize(t('form.editSuccessMessage')));
      redirect = setTimeout(() => router.navigate(-1), 2000);
    }
    return () => {
      if (redirect)
        clearTimeout(redirect);
    };
  }, [isPatchSuccessful, messageApi, patchedAsset, t]);

  useEffect(() => {
    if (hasPatchFailed) {
      messageApi.error(capitalize(t('form.editFailMessage')));
    }
  }, [hasPatchFailed, messageApi, t]);

  const sigiValidation = (sigi_code: string) => sigi_code ? validateSigi({sigi_code: sigi_code}) : setSigiStatus(SigiStatus.not_valid);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSigiValidation = useCallback(_.debounce(sigiValidation, 500), []);

  useEffect(() => {
    if (hasSigiCodeLookupFailed || (isSigiCodeLookupSuccessful && assetsWithGivenSigiCode && assetsWithGivenSigiCode.length >= 1)) {
      setSigiStatus(SigiStatus.not_valid);
      return;
    }
    if (isSigiCodeLookupFetching || isSigiCodeLookupLoading) {
      setSigiStatus(SigiStatus.loading);
      return;
    }
    if (isSigiCodeLookupSuccessful && assetsWithGivenSigiCode && assetsWithGivenSigiCode.length === 0) {
      setSigiStatus(SigiStatus.valid);
      return;
    }
  }, [assetsWithGivenSigiCode, hasSigiCodeLookupFailed, isSigiCodeLookupFetching, isSigiCodeLookupLoading, isSigiCodeLookupSuccessful]);

  const submit = () => {
    if (details.sigi_code !== null && location.position) {
      patchAsset({
        uuid: asset.uuid,
        patch: {
          ...location,
          ...details,
          ...realEstateDetails,
          sigi_code: details.sigi_code,
          position: {lat: location.position[0], lng: location.position[1]},
          opening_date: details?.opening_date?.format('YYYY-MM-DD') || null,
          closing_date: details?.closing_date?.format('YYYY-MM-DD') || null,
          realestate_hub: realEstateDetails.realestate_hub ? realEstateDetails.realestate_hub.value : null,
          contact_people: realEstateDetails.contact_people.map(person => person.value),
          companies: details.companies.map(company => company.value),
          master_asset: details.master_asset !== null ? details.master_asset.value : null,
        },
      });
    }

  };

  return (<EditFormContext.Provider
    value={
      {
        isFirstStepComplete,
        isSecondStepComplete,
        isThirdStepComplete,
        isUserCreated,
        submit,
        isLoading: isAssetLoading,
        isSigiValid: sigiStatus,
        checkSigi: debouncedSigiValidation,
      }}
  >
    {contextHolder}
    {props.children}
  </EditFormContext.Provider>);
};