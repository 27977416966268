import {Card, ConfigProvider, Drawer, DrawerProps, Typography} from 'antd';
import {ReactNode} from 'react';

export default function SettingsDrawer({header, detail, children, ...drawerProps}: DrawerProps & {
  header: ReactNode,
  detail: ReactNode
}) {
  return (
    <ConfigProvider
      theme={{
        token: {
          zIndexPopupBase: 100000,
        },
        components: {
          Drawer: {
            colorBgElevated: '#F0F2F5',
          },
        },
      }}
    >
      <Drawer
        title={null}
        closeIcon={false}
        width={'80%'}
        size={'large'}
        {...drawerProps}
      >
        <div style={{display: 'flex', flexDirection: 'column', gap: '2rem', backgroundColor: '#F0F2F5'}}>
          <Typography.Title level={3}>{header}</Typography.Title>
          <Card bodyStyle={{padding: '2rem'}}>{detail}</Card>
          <Card bodyStyle={{padding: '1.5rem'}}>{children}</Card>
        </div>
      </Drawer>
    </ConfigProvider>
  );
}