import {CHANGES, PHASE,} from '../../types/api/getEventsResult';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import {
  useGetEventClusterTemplatesQuery,
  useGetEventQuery,
  useLazyGetEventClustersAssetsNotPaginatedQuery,
  useLazyGetEventClustersPolygonsNotPaginatedQuery,
  useRemoveClusterMutation,
  useUpdateClusterAssetsMutation,
  useUpdateEventClustersPolygonsMutation
} from '../../redux/api/events';
import {skipToken} from '@reduxjs/toolkit/query';
import {
  Entity,
  GlobalPolygon,
  useLazyGetGeoshapesEntityQuery,
  useLazyGetGlobalGeoshapesQuery,
} from '../../redux/api/geoshapes';
import {capitalize} from 'lodash';
import {useLazyGetAssetMapQuery} from '../../redux/api/assetsMap';
import {notAssignedAssetColor} from '../../utils/colors';
import {Feature, MultiPolygon, multiPolygon, Position, Properties} from '@turf/helpers';
import RemovePolygonAsset from "../../components/events/clusters/RemovePolygonAsset";
import {Form, Modal, Radio, Space, Typography} from "antd";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import {useForm} from "antd/lib/form/Form";
import {VALUE_NOT_PRESENT} from "../../utils/translation";
import {useLazyGetAssetsNotPaginatedQuery} from "../../redux/api/assets";
import {NO_CLUSTER_VALUE} from "../../components/events/importAlertsAreas/AssignCluster";
import {useTranslation} from "react-i18next";
import {eventContextReducer, initialStateEventContextReducer} from "./eventContextReducer";
import {
  ClusterAsset,
  EventContextType,
  EventPolygon,
  EventPolygonInsert,
  OnCutPolygonParams,
} from "./EventContextTypes";
import {Cluster} from "../../types/api/clusters";
import {AssetRequest, getMarkersIncludedInPolygons, insertAssetRequest} from "../../utils/map";
import {AssetsTableRecord} from "../../types/api/assets";

const Event = createContext<EventContextType>({
  assets: [],
  clusters: undefined,
  entities: [],
  event: undefined,
  eventPolygons: undefined,
  getClusterFromUuid: () => undefined,
  getEntityFromUuid: () => undefined,
  getEventPolygonsFromCluster: () => [],
  getEventPolygonFromGlobalPolygonUuid: () => undefined,
  getGlobalPolygonFromUuid: () => undefined,
  getGlobalPolygonsFromCluster: () => [],
  globalPolygons: [],
  isEditing: false,
  onChangePolygonCluster: () => new Promise(() => null),
  onChangePolygonOrder: () => new Promise(() => null),
  onCutPolygons: () => new Promise(() => null),
  onInsertPolygon: () => new Promise(() => null),
  onEditPolygon: () => new Promise(() => null),
  onRemovePolygon: () => new Promise(() => null),
  onRemoveCluster: () => new Promise(() => null),
  refetchEvent: () => new Promise(() => null),
  setPolygonsAndAssetsForTheMap: () => new Promise(() => null),
  setIsEditing: () => undefined,
  updateClusterAsset: () => new Promise(() => null),
});
export const useEventContext = () => useContext(Event);

const DEFAULT_FILTERS = {
  building_type: 'SSR,SNR',
  is_closing_date_null: true,
  total_resources_min: 1,
}

export default function EventContext({children, eventUuid}: PropsWithChildren & { eventUuid: string | undefined }) {

  const {t} = useTranslation()
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [modal, contextHolder] = Modal.useModal();

  const [{
    assets,
    entities,
    eventPolygons,
    globalPolygons,
  }, eventDispatch] = useReducer(eventContextReducer, initialStateEventContextReducer);

  const {data: event, refetch} = useGetEventQuery(eventUuid ? {uuid: eventUuid} : skipToken);
  const eventPhase = event?.phase

  // refetch because eventtype clusters could change and automatic tags can't be set
  const {data: clusters} = useGetEventClusterTemplatesQuery(eventUuid ? {eventUuid} : skipToken, {refetchOnMountOrArgChange: true});

  const [getPolygons] = useLazyGetEventClustersPolygonsNotPaginatedQuery();
  const [getClusterAssets] = useLazyGetEventClustersAssetsNotPaginatedQuery();
  const [getAllTheAssets] = useLazyGetAssetMapQuery();
  const [getGeoshapes] = useLazyGetGlobalGeoshapesQuery();
  const [getEntity] = useLazyGetGeoshapesEntityQuery();

  const [updatePolygon] = useUpdateEventClustersPolygonsMutation();
  const [updateAssets] = useUpdateClusterAssetsMutation();
  const [getAssets] = useLazyGetAssetsNotPaginatedQuery()
  const [removeCluster] = useRemoveClusterMutation();

  const [removePolygonForm] = useForm()
  const [insertPolygonForm] = useForm()

  const getClusterFromUuid = useCallback((uuid: string) => {
    return clusters?.find(cl => cl.uuid === uuid);
  }, [clusters]);

  const getGlobalPolygonFromUuid = (uuid: string) => {
    return globalPolygons?.find(gp => gp.properties.uuid === uuid);
  };

  const getEventPolygonFromGlobalPolygonUuid = useCallback((uuid: string) => {
    return eventPolygons?.find(ep => ep.globalPolygon === uuid);
  }, [eventPolygons]);

  const getEntityFromUuid = (uuid: string) => {
    return entities?.find(ent => ent.uuid === uuid);
  };

  const getGlobalPolygonsFromCluster = (clusterUuid: string) => {
    const selectedEventPolygons = eventPolygons.filter(el => el.cluster === clusterUuid);
    return globalPolygons.filter(gp => selectedEventPolygons.some(el => el.globalPolygon === gp.properties.uuid));
  };

  const getEventPolygonsFromCluster = (clusterUuid: string) => {
    return eventPolygons.filter(el => el.cluster === clusterUuid);
  };

  const onUpdateAssets = useCallback(async (requests: {
    cluster: string | null,
    assets: string[]
  }[], text_note: string | null = null) => {
    const updatedRequests = requests.filter(req => req.assets.length)

    if (eventUuid) {
      const promises = updatedRequests.map(el => updateAssets({
        eventUuid: eventUuid,
        cluster_template: el.cluster || null,
        assets: el.assets,
        text_note: text_note
      }).unwrap());
      try {
        await Promise.all(promises);
        const requestsWithCluster: { cluster: Cluster | null, assets: string[] }[] = []

        updatedRequests.forEach(el => {
          if (assets.length) {
            if (el.cluster) {
              const foundCluster = getClusterFromUuid(el.cluster)
              if (foundCluster) {
                requestsWithCluster.push({...el, cluster: foundCluster})
              }
            } else {
              requestsWithCluster.push({...el, cluster: null})
            }
          }
        })

        eventDispatch({
          type: 'updateAssets',
          payload: requestsWithCluster
        })
      } catch {

      }

    }

  }, [assets.length, eventUuid, getClusterFromUuid, updateAssets])

  const onRemovePolygonForm = useCallback(async ({
                                                   currentAssetsRequests = [],
                                                   globalPolygonUuid,
                                                   initialValues,
                                                   assetsWithInfo
                                                 }: {
    globalPolygonUuid: string,
    initialValues: { ["key"]: string },
    assetsWithInfo: AssetsTableRecord[],
    currentAssetsRequests: AssetRequest[]
  }) => {
    let isToRemove = true
    let newAssetsRequests: AssetRequest[] = currentAssetsRequests
    try {
      await modal.confirm({
        icon: null,
        closable: true,
        title: capitalize(t('events.solveConflictsTitle')),
        okText: capitalize(t('actions.confirm')),
        content: <>
          <Typography.Paragraph>{capitalize(t('events.solveConflictsDescription'))}</Typography.Paragraph>
          <Form
            style={{margin: '1rem 0'}}
            key={globalPolygonUuid}
            form={removePolygonForm}
            layout={'vertical'}
            initialValues={initialValues}
          >
            <RemovePolygonAsset
              assets={assetsWithInfo}
            />
          </Form>
        </>,
        onCancel: (close) => {
          isToRemove = false
          close()
        },
        onOk: async (close) => {
          const values = await removePolygonForm.validateFields()

          for (const [asset, initialValue] of Object.entries(initialValues)) {
            const parsedInitialValue = initialValue === NO_CLUSTER_VALUE ? null : initialValue
            const parsedCurrentValue = values[asset] === NO_CLUSTER_VALUE ? null : values[asset]
            if (parsedInitialValue !== parsedCurrentValue) {
              newAssetsRequests = insertAssetRequest(newAssetsRequests, {asset, cluster: parsedCurrentValue})
            }
          }
          close()
        }
      })
    } catch {

    }
    return {
      isToRemove,
      assetsRequest: newAssetsRequests
    }

  }, [modal, removePolygonForm, t])

  const onRemovePolygon = async (globalPolygonUuid: string) => {
      if (eventUuid) {
        const globalPolygonToRemove = getGlobalPolygonFromUuid(globalPolygonUuid)
        const eventPolygonToRemove = getEventPolygonFromGlobalPolygonUuid(globalPolygonUuid)
        const assetsNotIncludedByOtherPolygons: ClusterAsset[] = []
        const assetsIncludedByOtherPolygon: (ClusterAsset & { globalPolygonUuid: string })[] = []
        const assetsIncludedByOtherPolygons: ClusterAsset[] = []

        if (globalPolygonToRemove && eventPolygonToRemove) {
          assets.forEach(asset => {
            if (
              asset.cluster_template === eventPolygonToRemove?.cluster &&
              booleanPointInPolygon([asset.position.lng, asset.position.lat], {
                type: 'MultiPolygon',
                coordinates: globalPolygonToRemove?.geometry.coordinates
              })) {
              const intersectedPolygons = globalPolygons.filter(el =>
                el.properties.uuid !== globalPolygonToRemove.properties.uuid &&
                booleanPointInPolygon(
                  [asset.position.lng, asset.position.lat],
                  multiPolygon(el.geometry.coordinates))
              )
              switch (intersectedPolygons.length) {
                case 0:
                  assetsNotIncludedByOtherPolygons.push(asset)
                  break;
                case 1:
                  assetsIncludedByOtherPolygon.push({...asset, globalPolygonUuid: intersectedPolygons[0].properties.uuid})
                  break;
                default:
                  assetsIncludedByOtherPolygons.push(asset)
                  break;
              }
            }
          })
        }

        let assetsRequests: { cluster: string | null, assets: string[] }[] = [{
          cluster: null,
          assets: assetsNotIncludedByOtherPolygons.map(el => el.uuid)
        }]
        assetsIncludedByOtherPolygon.forEach(asset => {
          const currentEventPolygon = getEventPolygonFromGlobalPolygonUuid(asset.globalPolygonUuid)
          if (currentEventPolygon) {
            assetsRequests = insertAssetRequest(assetsRequests, {asset: asset.uuid, cluster: currentEventPolygon.cluster})
          }
        })
        let isToRemove = true
        try {
          if (assetsIncludedByOtherPolygons.length) {
            const initialValues = assetsIncludedByOtherPolygons.reduce((previousValue, asset) => ({
              ...previousValue,
              [asset.uuid]: asset.cluster_template || VALUE_NOT_PRESENT,
            }), {} as { ["key"]: string })
            const assets = await getAssets({
              filters: {
                ...DEFAULT_FILTERS,
                polygon_uuid: globalPolygonToRemove?.properties.uuid,
              }
            }).unwrap()
            const assetsWithInfo = assets.filter(el => assetsIncludedByOtherPolygons.some(clusterAsset => clusterAsset.uuid === el.uuid))
            try {
              const formResponse = await onRemovePolygonForm({
                currentAssetsRequests: assetsRequests,
                globalPolygonUuid,
                assetsWithInfo,
                initialValues
              })
              isToRemove = formResponse.isToRemove
              assetsRequests = formResponse.assetsRequest
            } catch {
              isToRemove = false
            } finally {
              removePolygonForm.resetFields();
            }
          }
          if (isToRemove) {
            await Promise.all([
              onUpdateAssets(assetsRequests),
              updatePolygon({
                event_uuid: eventUuid,
                cluster_template: null,
                globalpolygon: globalPolygonUuid,
                geoshape: null,
              })
            ])
            eventDispatch(
              {
                type: 'removeGlobalPolygon',
                payload: {
                  globalPolygonUuid: [globalPolygonUuid]
                }
              })
          }
        } catch {

        }


      }

    }
  ;

  // important! functions that interact with react leaflet draw needs pure functions to work properly
  const assetsRef = useRef<ClusterAsset[]>([])
  useEffect(() => {
    assetsRef.current = assets
  }, [assets]);
  const eventPolygonsRef = useRef<EventPolygon[]>([])
  useEffect(() => {
    eventPolygonsRef.current = eventPolygons
  }, [eventPolygons]);
  const globalPolygonsRef = useRef<GlobalPolygon[]>([])
  useEffect(() => {
    globalPolygonsRef.current = globalPolygons
  }, [globalPolygons]);

  const onCutPolygons = useCallback(async ({
                                             cutterGeometry,
                                             involvedClusters,
                                             affectedGeometries,
                                           }: OnCutPolygonParams) => {
    try {
      if (eventUuid) {
        const assetsToRemove = assetsRef.current.filter(asset => {
          let isClusterSelected: boolean;
          if (involvedClusters !== 'all') {
            isClusterSelected = involvedClusters.some(el => asset.cluster_template === el)
          } else {
            isClusterSelected = !!asset.cluster_template
          }
          return isClusterSelected &&
            booleanPointInPolygon([asset.position.lng, asset.position.lat],
              cutterGeometry)
        })
          .map(el => el.uuid)


        const geometriesToInsert: { clusterUuid: string | null, coordinates: Position[][][] }[] = []
        const globalPolygonsToRemove: string[] = []

        affectedGeometries.forEach(affectedGeometry => {
          if (affectedGeometry.coordinates) {
            const currentCluster = eventPolygonsRef.current.find(el => el.globalPolygon === affectedGeometry.uuid)?.cluster
            if (currentCluster) {
              geometriesToInsert.push({
                clusterUuid: currentCluster,
                coordinates: affectedGeometry.coordinates
              })
            } else {
              console.error('error in searching cluster of the polygon')
              throw Error('error in searching cluster of the polygon')
            }

          }
          globalPolygonsToRemove.push(affectedGeometry.uuid)
        })

        const responses = await Promise.all([
          Promise.all(geometriesToInsert.map(geometriesToInsert => updatePolygon({
            event_uuid: eventUuid,
            cluster_template: geometriesToInsert.clusterUuid,
            globalpolygon: null,
            geoshape: {type: 'MultiPolygon', coordinates: geometriesToInsert.coordinates},
          }).unwrap())),
          onUpdateAssets([{cluster: null, assets: assetsToRemove}]),
          Promise.all(globalPolygonsToRemove.map(currentGlobalPolygonUuid => updatePolygon({
            event_uuid: eventUuid,
            cluster_template: null,
            globalpolygon: currentGlobalPolygonUuid,
            geoshape: null,
          }).unwrap())),
        ])

        const eventPolygonsToInsert = responses[0]
        const newGeoshapesPromises = eventPolygonsToInsert.flatMap(geometry => geometry ? [getGeoshapes({uuid: geometry.globalpolygon}).unwrap()] : [])
        const newGeoshapesResponses = await Promise.all(newGeoshapesPromises)

        eventDispatch(
          {
            type: 'removeGlobalPolygon',
            payload: {
              globalPolygonUuid: globalPolygonsToRemove
            }
          })

        eventDispatch({
          type: 'insertPolygons',
          payload: {
            globalPolygons: newGeoshapesResponses,
            eventPolygons: eventPolygonsToInsert.map(el => ({
              cluster: el.cluster_template,
              globalPolygon: el.globalpolygon,
              polygon_name: el.polygon_name,
              entity_uuid: el.entity_uuid,
            })) as EventPolygon[]
          }
        })

      }

    } catch {

    }


  }, [eventUuid, getGeoshapes, onUpdateAssets, updatePolygon])


  const onRemoveCluster = async (clusterUuid: string) => {
    if (eventUuid) {
      try {
        const response = await removeCluster({eventUuid: eventUuid, cluster_template: clusterUuid}).unwrap();

        eventDispatch({
          type: 'removeGlobalPolygon',
          payload: {
            globalPolygonUuid: response.globalpolygons
          }
        })

        eventDispatch({
          type: 'updateAssets',
          payload: [
            {
              cluster: null,
              assets: response.assets
            }
          ]
        })

      } catch {

      }
    }
  };

  const onInsertPolygonForm = useCallback(async () => {
    let isToInsert = true
    let isHardAssign = false
    try {
      await modal.confirm({
        icon: null,
        closable: true,
        title: capitalize(t('events.insertPolygonTitle')),
        okText: capitalize(t('actions.confirm')),
        content: <>
          <Typography.Paragraph>{capitalize(t('events.insertPolygonDescription'))}</Typography.Paragraph>
          <Form
            style={{margin: '1rem 0'}}
            form={insertPolygonForm}
            layout={'vertical'}
            initialValues={{is_hard_assign: false}}
          >
            <Form.Item
              name={'is_hard_assign'}
            >
              <Radio.Group>
                <Space direction="vertical">
                  <Radio value={false}>{capitalize(t('events.insertPolygonFiltered'))}</Radio>
                  <Radio value={true}>{capitalize(t('events.insertPolygonAll'))}</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Form>
        </>,
        onCancel: (close) => {
          isToInsert = false
          close()
        },
        onOk: async (close) => {
          const values = await insertPolygonForm.validateFields()
          isHardAssign = values.is_hard_assign
          close()
        }
      })
    } catch {
      isToInsert = false
    }
    return {
      isToInsert,
      isHardAssign
    }

  }, [insertPolygonForm, modal, t])


  const onInsertPolygon = useCallback(async ({type, payload, cluster}: EventPolygonInsert) => {
    if (eventUuid) {
      try {
        let assetsInPolygon: ClusterAsset[] = []
        let assetsInPolygonNotAssigned: ClusterAsset[] = []
        let polygon: Feature<MultiPolygon, Properties>;

        switch (type) {
          case "entity":
            const result = await getGeoshapes({uuid: payload.globalpolygon}).unwrap();
            polygon = multiPolygon(result.geometry.coordinates)
            break;
          case "draw":
            polygon = multiPolygon(payload)
            break;
          case "globalPolygon":
            polygon = multiPolygon(payload.geometry.coordinates)
            break;
        }

        assetsInPolygon = assetsRef.current.filter(asset =>
          asset.cluster_template !== cluster &&
          booleanPointInPolygon([asset.position.lng, asset.position.lat], multiPolygon(polygon.geometry.coordinates)))
        assetsInPolygonNotAssigned = assetsInPolygon.filter(el => !el.cluster_template)

        const isNotAutomaticAssign = assetsInPolygon.length > assetsInPolygonNotAssigned.length
        let isToInsert = true
        let isHardAssign = false

        try {
          if (isNotAutomaticAssign) {
            const formResponse = await onInsertPolygonForm()
            isToInsert = formResponse.isToInsert
            isHardAssign = formResponse.isHardAssign
          }

          if (isToInsert) {
            let polygonPromise: Promise<any>
            switch (type) {
              case "entity":
                polygonPromise = updatePolygon({
                  event_uuid: eventUuid,
                  cluster_template: cluster,
                  globalpolygon: payload.globalpolygon,
                  geoshape: null,
                }).unwrap();
                break;
              case "draw":
                polygonPromise = updatePolygon({
                  event_uuid: eventUuid,
                  cluster_template: cluster,
                  globalpolygon: null,
                  geoshape: {type: 'MultiPolygon', coordinates: payload},
                }).unwrap();
                break;
              case "globalPolygon":
                polygonPromise = updatePolygon({
                  event_uuid: eventUuid,
                  cluster_template: cluster,
                  globalpolygon: payload.properties.uuid,
                  geoshape: null,
                }).unwrap();
                break;
            }

            const updateResponse = await Promise.all([
              onUpdateAssets([{
                cluster,
                assets: isHardAssign ? assetsInPolygon.map(el => el.uuid) : assetsInPolygonNotAssigned.map(el => el.uuid)
              }]),
              polygonPromise
            ])

            const globalPolygon = await getGeoshapes({uuid: updateResponse[1].globalpolygon}).unwrap();

            eventDispatch({
              type: 'insertPolygons',
              payload: {
                ...type === 'entity' && {entities: [payload]},
                globalPolygons: [globalPolygon],
                eventPolygons: [{
                  cluster: cluster,
                  globalPolygon: updateResponse[1].globalpolygon,
                  polygon_name: updateResponse[1].polygon_name,
                  entity_uuid: updateResponse[1].entity_uuid,
                  alert_polygon_uuid: updateResponse[1].alert_polygon_uuid
                }]
              }
            })


          }

        } catch {

        } finally {
          insertPolygonForm.resetFields()
        }

      } catch {
        console.log('error in inserting polygon')
      }


    }
  }, [eventUuid, getGeoshapes, insertPolygonForm, onInsertPolygonForm, onUpdateAssets, updatePolygon])

  const onEditPolygon = useCallback(async (geometry: Feature<MultiPolygon, Properties>, globalPolygonUuid: string) => {
    if (eventUuid) {
      try {
        const eventPolygon = getEventPolygonFromGlobalPolygonUuid(globalPolygonUuid)
        const globalPolygon = getGlobalPolygonFromUuid(globalPolygonUuid)
        if (eventPolygon && globalPolygon) {
          const allTheAssetsInOldPolygon = assetsRef.current.filter(asset => booleanPointInPolygon([asset.position.lng, asset.position.lat], multiPolygon(globalPolygon.geometry.coordinates)))
          const allTheAssetsInNewPolygon = assetsRef.current.filter(asset => booleanPointInPolygon([asset.position.lng, asset.position.lat], multiPolygon(geometry.geometry.coordinates)))
          const assetsInOldPolygonWithSameCluster = allTheAssetsInOldPolygon.filter(asset => asset.cluster_template === eventPolygon.cluster)
          let assetsRequests: { cluster: string | null, assets: string[] }[] = []

          try {
            const assetsToInsert = allTheAssetsInNewPolygon.filter(newPolygonAsset => newPolygonAsset.cluster_template !== eventPolygon.cluster)
            const assetsToInsertNotAssigned = assetsToInsert.filter(el => !el.cluster_template)
            const isNotAutomaticAssign = assetsToInsert.length > assetsToInsertNotAssigned.length
            let isToInsert = true
            let isToRemove = true
            let isHardAssign = false

            // insert assets form
            if (isNotAutomaticAssign) {
              const formInsertResponse = await onInsertPolygonForm()
              isToInsert = formInsertResponse.isToInsert
              isHardAssign = formInsertResponse.isHardAssign
            }
            const assetsToIterate = isHardAssign ? assetsToInsert : assetsToInsertNotAssigned
            assetsToIterate.forEach(asset => {
              assetsRequests = insertAssetRequest(assetsRequests, {cluster: eventPolygon.cluster, asset: asset.uuid})
            })

            // remove assets form
            if (isToInsert) {
              const assetsToRemove = assetsInOldPolygonWithSameCluster.filter(oldPolygonAsset => allTheAssetsInNewPolygon.every(newPolygonAsset => newPolygonAsset.uuid !== oldPolygonAsset.uuid))
              const assetsToRemoveWithGlobalPolygons = getMarkersIncludedInPolygons(assetsToRemove, globalPolygonsRef.current.filter(el => el.properties.uuid !== globalPolygonUuid).map(el => ({
                uuid: el.properties.uuid,
                coordinates: el.geometry.coordinates
              })))

              const assetsIncludedByOtherPolygons: ClusterAsset[] = []
              assetsToRemoveWithGlobalPolygons.forEach(el => {
                if (el.includedPolygons.length === 0) {
                  assetsRequests = insertAssetRequest(assetsRequests, {asset: el.markerUuid, cluster: null})
                }
                if (el.includedPolygons.length === 1) {
                  const eventPolygon = getEventPolygonFromGlobalPolygonUuid(el.includedPolygons[0].uuid)
                  if (eventPolygon) {
                    assetsRequests = insertAssetRequest(assetsRequests, {
                      asset: el.markerUuid,
                      cluster: eventPolygon.cluster
                    })
                  }
                }
                if (el.includedPolygons.length > 1) {
                  const currentAsset = assetsToRemove.find(asset => asset.uuid === el.markerUuid)
                  if (currentAsset) {
                    assetsIncludedByOtherPolygons.push(currentAsset)
                  }
                }
              })

              if (assetsIncludedByOtherPolygons.length) {
                const initialValues = assetsIncludedByOtherPolygons.reduce((previousValue, asset) => ({
                  ...previousValue,
                  [asset.uuid]: asset.cluster_template || VALUE_NOT_PRESENT,
                }), {} as { ["key"]: string })
                const assetsInPolygon = await getAssets({
                  filters: {
                    ...DEFAULT_FILTERS,
                    polygon_uuid: globalPolygonUuid,
                  }
                }).unwrap()
                const assetsWithInfo = assetsInPolygon.filter(el => assetsIncludedByOtherPolygons.some(clusterAsset => clusterAsset.uuid === el.uuid))
                const formRemoveResponse = await onRemovePolygonForm({
                  currentAssetsRequests: assetsRequests,
                  globalPolygonUuid,
                  assetsWithInfo,
                  initialValues
                })
                isToRemove = formRemoveResponse.isToRemove
                assetsRequests = formRemoveResponse.assetsRequest
              }
            }

            if (isToInsert && isToRemove) {
              const [oldpolygon, newPolygon, ...otherPromises] = await Promise.all([
                // old polygon to remove
                updatePolygon({
                  event_uuid: eventUuid,
                  cluster_template: null,
                  globalpolygon: globalPolygonUuid,
                  geoshape: null
                }).unwrap(),
                // new polygon to insert
                updatePolygon({
                  event_uuid: eventUuid,
                  cluster_template: eventPolygon.cluster,
                  globalpolygon: null,
                  geoshape: {type: 'MultiPolygon', coordinates: geometry.geometry.coordinates}
                }).unwrap(),
                // assets to update
                onUpdateAssets(assetsRequests),
              ])


              // update polygons state
              const globalPolygonRes = await getGeoshapes({uuid: newPolygon.globalpolygon}).unwrap();

              eventDispatch({
                type: 'insertPolygons',
                payload: {
                  globalPolygons: [globalPolygonRes],
                  eventPolygons: [{
                    cluster: eventPolygon.cluster,
                    globalPolygon: newPolygon.globalpolygon,
                    polygon_name: newPolygon.polygon_name,
                    entity_uuid: newPolygon.entity_uuid,
                    alert_polygon_uuid: newPolygon.alert_polygon_uuid
                  }]
                }
              })
              eventDispatch({
                type: 'removeGlobalPolygon',
                payload: {
                  globalPolygonUuid: [globalPolygonUuid],
                }
              })
            }


          } catch {

          } finally {
            insertPolygonForm.resetFields()
            removePolygonForm.resetFields();
          }


        } else {
          throw new Error('event polygon not present')
        }


      } catch {

      }
    }
  }, [eventUuid, getAssets, getEventPolygonFromGlobalPolygonUuid, getGeoshapes, getGlobalPolygonFromUuid, insertPolygonForm, onInsertPolygonForm, onRemovePolygonForm, onUpdateAssets, removePolygonForm, updatePolygon])
  const onChangePolygonCluster = async (globalPolygonUuid: string, clusterUuid: string) => {
    if (eventUuid) {
      try {
        const polygon = await updatePolygon({
          event_uuid: eventUuid,
          cluster_template: clusterUuid,
          globalpolygon: globalPolygonUuid,
          geoshape: null,
        }).unwrap();

        eventDispatch({
          type: 'updatePolygon',
          payload: {
            globalPolygonUuid,
            clusterUuid
          }
        })

        // todo update assets


      } catch {

      }
    }
  };

  const onChangePolygonOrder = (globalPolygonUuid: string, type: 'front' | 'back') => {
    eventDispatch({
      type: 'changePolygonOrder',
      payload: {
        position: type === 'back' ? 'start' : 'end',
        globalPolygonUuid
      }
    })
  }

  const refetchEvent = useCallback(async () => {
    await refetch().unwrap();
  }, [refetch]);


  const setPolygonsAndAssetsForTheMap = useCallback(async (changes: CHANGES.WITH | CHANGES.WITHOUT) => {
    try {
      if (eventPhase && eventUuid) {
        const [allTheAssetsResponse, clusterAssetsResponse, eventClustersPolygonsResponse] = await Promise.all([
          getAllTheAssets({filters: DEFAULT_FILTERS}).unwrap(),
          getClusterAssets({eventUuid: eventUuid, changes}).unwrap(),
          getPolygons({eventUuid: eventUuid, changes}).unwrap()
        ])

        const globalPolygonsRequests: (Promise<GlobalPolygon>)[] = [];
        const entitiesRequests: (Promise<Entity>)[] = [];
        const polygons: EventPolygon[] = [];

        const markers: ClusterAsset[] = clusterAssetsResponse?.map((currVal) => {
          const cluster = getClusterFromUuid(currVal.cluster_template);
          return currVal.cluster_assets.map(asset => ({
            ...asset,
            cluster_template: cluster?.uuid || null,
            color: cluster?.color_code || notAssignedAssetColor,
          }));
        }).flat() || [];

        allTheAssetsResponse?.forEach(asset => {
          if (asset.position && !markers.some(m => m.uuid === asset.uuid)) {
            markers.push({...asset, color: notAssignedAssetColor, cluster_template: null});
          }
        });

        eventClustersPolygonsResponse?.forEach(cluster => {
          cluster.cluster_polygons.forEach(polygon => {
            if (polygon.entity_uuid) {
              entitiesRequests.push(getEntity({uuid: polygon.entity_uuid}).unwrap());
            }
            globalPolygonsRequests.push(getGeoshapes({uuid: polygon.globalpolygon}).unwrap());
            polygons.push({
              alert_polygon_uuid: polygon.alert_polygon_uuid,
              cluster: cluster.cluster_template,
              globalPolygon: polygon.globalpolygon,
              polygon_name: polygon.polygon_name,
              entity_uuid: polygon.entity_uuid,
            });
          });
        });
        const [globalPolygonsResponses, entitiesResponses] = await Promise.all([Promise.all(globalPolygonsRequests), Promise.all(entitiesRequests)])

        eventDispatch({
          type: 'initialize',
          payload: {
            assets: markers,
            entities: entitiesResponses,
            eventPolygons: polygons,
            globalPolygons: globalPolygonsResponses,
          }
        })
      }
    } catch {

    }

  }, [eventPhase, eventUuid, getAllTheAssets, getClusterAssets, getClusterFromUuid, getEntity, getGeoshapes, getPolygons])

  useEffect(() => {
    if (eventPhase === PHASE.EDIT) {
      setIsEditing(true);
    }
  }, [eventPhase]);

  return <Event.Provider
    value={{
      assets,
      event,
      eventPolygons,
      clusters,
      getClusterFromUuid,
      getEntityFromUuid,
      getEventPolygonsFromCluster,
      getEventPolygonFromGlobalPolygonUuid,
      getGlobalPolygonFromUuid,
      getGlobalPolygonsFromCluster,
      globalPolygons,
      entities,
      onChangePolygonCluster,
      onChangePolygonOrder,
      onCutPolygons,
      onEditPolygon,
      onInsertPolygon,
      onRemovePolygon,
      onRemoveCluster,
      isEditing,
      refetchEvent,
      setIsEditing,
      setPolygonsAndAssetsForTheMap,
      updateClusterAsset: ({cluster, asset, note}) => onUpdateAssets([{cluster, assets: [asset]}], note),
    }}
  >
    {contextHolder}
    {children}
  </Event.Provider>;
}

