import React, {createContext, PropsWithChildren, useContext, useState} from 'react';
import {AssetLocation, AssetLocationContextType} from '../../types/internal/AddAssetForm';

const LocationContext = createContext<AssetLocationContextType>(
  [
    {
      country: null,
      admin_level_1: null,
      admin_level_2: null,
      admin_level_3: null,
      locality: null,
      address: null,
      postal_code: null,
      position: null,
      geocoding_position: null,
    },
    () => null,
  ]);
export const useLocationContext = () => useContext(LocationContext);

export const AssetLocationContext = (props: PropsWithChildren) => {
  
  const [location, setLocation] = useState<AssetLocation>({
    country: null,
    admin_level_1: null,
    admin_level_2: null,
    admin_level_3: null,
    locality: null,
    address: null,
    postal_code: null,
    position: null,
    geocoding_position: null,
  });
  
  return (
    <LocationContext.Provider value={[location, setLocation]}>
      {props.children}
    </LocationContext.Provider>
  );
};