import {Space, Typography} from 'antd';
import dayjs from 'dayjs';
import {useGetUserProfileQuery} from '../../redux/api/locales';
import {VALUE_NOT_PRESENT} from "../../utils/translation";

export const enum VALUE_TYPES {
  TEXT,
  NUMBER,
  DATE,
  CURRENCY,
  PHONE_NUMBER,
  AREA
}

const {Text, Paragraph} = Typography;
export const LabeledValue = (props: {
  label: string,
  value?: string | number | null,
  type?: VALUE_TYPES,
}) => {

  const {data} = useGetUserProfileQuery();
  const dateFormat = data?.language === 'it' ? 'DD/MM/YYYY' : 'YYYY/MM/DD';

  return (
    <Space size={'small'} direction={'vertical'} style={{display: 'flex'}}>
      <Text strong={true} ellipsis={{tooltip: true}}>
        {props.label}:
      </Text>
      <Paragraph ellipsis={{tooltip: true}}>
        {props.type === VALUE_TYPES.CURRENCY && `${props.value ? `${props.value} €` : VALUE_NOT_PRESENT}`}
        {props.type === VALUE_TYPES.AREA && `${props.value ? `${props.value} m²` : VALUE_NOT_PRESENT}`}
        {props.type === VALUE_TYPES.DATE && `${props.value ? dayjs(props.value).format(dateFormat) : VALUE_NOT_PRESENT}`}
        {(props.type === VALUE_TYPES.TEXT || !props.type) && `${props.value && `${props.value}`.trim() !== '' ? props.value : VALUE_NOT_PRESENT}`}
      </Paragraph>
    </Space>
  );
};