import {FileAddOutlined, UploadOutlined} from '@ant-design/icons';
import {Button, Col, Form, Input, message, Modal, Row, Upload} from 'antd';
import {useCallback, useState} from 'react';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import {LocalizedDatePicker} from '../../LocalizedDatePicker';
import dayjs from 'dayjs';
import {
  useFileUploadToS3Mutation,
  usePatchDocumentUploadStatusMutation,
  useRequestFileUploadToS3Mutation,
} from '../../../../redux/api/documents';
import {
  ACCEPTED_FILE_CONTENT_TYPE,
  ACCEPTED_FILE_EXTENSIONS,
  MAX_FILE_SIZE,
  sanitize_document_name,
  SPECIAL_CHARS_TO_ESCAPE,
} from '../../../../utils/documents';

const {TextArea} = Input;
const {Dragger} = Upload;
export const UploadDocumentModal = (
  {onAfterUpload}: { onAfterUpload?: (uploadedDocumentUuid: string) => void }) => {

  const {t} = useTranslation();

  const [addDocumentForm] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const [
    isModalOpen,
    setIsModalOpen,
  ] = useState(false);
  const [
    fileIsOk,
    setFileIsOk,
  ] = useState(true);
  const [
    isUploadingFile,
    setIsUploadingFile,
  ] = useState(false);

  const [
    requestAWSUrl,
  ] = useRequestFileUploadToS3Mutation();

  const [
    fileUpload,
  ] = useFileUploadToS3Mutation();

  const [
    patchDocumentUploadStatus,
  ] = usePatchDocumentUploadStatusMutation();

  const handleFinish = useCallback(async (values: any) => {
    setIsUploadingFile(true);
    try {
      const awsUrl = await requestAWSUrl({
        name: sanitize_document_name(values.document_name),
        description: values.description || '',
        start_date: values.start_date?.format('YYYY-MM-DD') || null,
        end_date: values.end_date?.format('YYYY-MM-DD') || null,
        content_type: values.document.file.type,
        type: 'T',
        file_extension: values.document.file.name.slice((values.document.file.name.lastIndexOf('.') - 1 >>> 0) + 2) || 'txt',
      }).unwrap();
      const postData = new FormData();
      for (const [key, value] of Object.entries(awsUrl.fields)) {
        postData.append(key, value);
      }
      postData.append('file', addDocumentForm.getFieldValue('document').file);
      await fileUpload({url: awsUrl.url, postData: postData}).unwrap();
      await patchDocumentUploadStatus({documentUuid: awsUrl.uuid, is_valid: true});
      if (onAfterUpload) {
        await onAfterUpload(awsUrl.uuid);
      }
      messageApi.success(capitalize(t('form.creationSuccessMessage')));
    } catch (e) {
      messageApi.error(capitalize(t('form.creationFailMessage')));
    } finally {
      setIsUploadingFile(false);
      setIsModalOpen(false);
      addDocumentForm.resetFields();
    }
  }, [addDocumentForm, fileUpload, messageApi, onAfterUpload, patchDocumentUploadStatus, requestAWSUrl, t]);

  return (
    <>
      {contextHolder}
      <Modal
        open={isModalOpen}
        title={capitalize(t('actions.uploadItem', {item: t('documents.document', {count: 1})}))}
        onCancel={() => {
          setIsModalOpen(false);
          addDocumentForm.resetFields();
        }}
        centered={true}
        destroyOnClose={true}
        footer={null}
      >
        <Form
          form={addDocumentForm}
          layout={'vertical'}
          onFinish={handleFinish}
          onFinishFailed={errorInfo => console.log('failed: ', errorInfo)}
          initialValues={{start_date: dayjs()}}
          autoComplete={'off'}
        >
          <Form.Item
            name={'document_name'}
            label={capitalize(t('documents.documentName'))}
            dependencies={['document']}
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  if (!value || value.trim() === '') {
                    return Promise.reject(new Error(capitalize(t('documents.documentNameRequired'))));
                  }
                  return Promise.resolve();
                },
              },
              ({getFieldValue}) => ({
                validator(_, value) {
                  if (value && value.trim() && SPECIAL_CHARS_TO_ESCAPE.some(char => value.includes(char))) {
                    return Promise.reject(new Error(capitalize(t('documents.noSpecialCharsAllowed'))));
                  }
                  return Promise.resolve();
                },
              }),
              {
                warningOnly: true,
                validator: (_, value) => {
                  if (value && value.trim() && ACCEPTED_FILE_EXTENSIONS.some(ext => value.includes(ext))) {
                    return Promise.reject(new Error(capitalize(t('documents.fileExtensionIsOptional'))));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input placeholder={capitalize(t('documents.documentName'))}/>
          </Form.Item>
          <Form.Item
            name={'start_date'}
            //hidden={true}
            label={capitalize(t('documents.startDate'))}
          >
            <LocalizedDatePicker
              placeholder={capitalize(t('documents.startDate'))}
              style={{display: 'flex'}}
            />
          </Form.Item>
          <Form.Item
            name={'end_date'}
            label={capitalize(t('documents.endDate'))}
            rules={[
              ({getFieldValue}) => ({
                validator(_, value) {
                  if (value && addDocumentForm.getFieldValue('start_date') && value < addDocumentForm.getFieldValue('start_date')) {
                    return Promise.reject(new Error(capitalize(t('documents.endDateLessThenStartDate'))));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <LocalizedDatePicker
              placeholder={capitalize(t('documents.endDate'))}
              style={{display: 'flex'}}
            />
          </Form.Item>
          <Form.Item
            name={'description'}
            label={capitalize(t('documents.description'))}
          >
            <TextArea
              rows={2}
              placeholder={capitalize(t('documents.description'))}
            />
          </Form.Item>
          <Form.Item
            name={'document'}
            rules={[{required: true, message: capitalize(t('documents.documentRequired'))}]}
            label={capitalize(t('documents.fileUpload'))}
          >
            <Dragger
              name={'file'}
              multiple={false}
              maxCount={1}
              accept={ACCEPTED_FILE_CONTENT_TYPE.join(',')}
              beforeUpload={(file) => {
                if (file.size <= MAX_FILE_SIZE) {
                  setFileIsOk(true);
                } else {
                  setFileIsOk(false);
                }
                if (!addDocumentForm.getFieldValue('document_name')) {
                  addDocumentForm.setFieldValue('document_name', file.name);
                }
                return false;
              }}
            >
              <p className="ant-upload-drag-icon">
                <UploadOutlined/>
              </p>
              <p className="ant-upload-text">
                {capitalize(t('documents.dragOrSelectFile'))}
              </p>
            </Dragger>
          </Form.Item>
          <Row
            justify={'end'}
            gutter={[24, 24]}
          >
            <Col>
              <Form.Item>
                <Button
                  type={'default'}
                  onClick={() => {
                    setIsModalOpen(false);
                    addDocumentForm.resetFields();
                  }}
                >
                  {capitalize(t('form.cancel'))}
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button
                  type={'primary'}
                  loading={isUploadingFile}
                  disabled={!fileIsOk}
                  htmlType={'submit'}
                >
                  {capitalize(t('actions.uploadItem', {item: t('documents.document', {count: 1})}))}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Button
        type={'primary'}
        icon={<FileAddOutlined/>}
        onClick={() => setIsModalOpen(true)}
      >
        {capitalize(t('actions.uploadItem', {item: t('documents.document', {count: 1})}))}
      </Button>
    </>
  );
};