import ResponsiveContent from '../layout/ResponsiveContent';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
import DocumentsTable, {DocumentTableViews} from '../components/documents/DocumentsTable';
import {UploadDocumentModal} from '../components/common/documents/modals/UploadDocumentModal';
import PermissionRoute from '../components/Authentication/PermissionRoute';
import {isGenericDocumentCreation} from '../components/Authentication/permissions';
import {useState} from "react";

export default function Documents() {

  const {t} = useTranslation();
  const [
    selectedView,
    setSelectedView,
  ] = useState<DocumentTableViews>('event_types');

  const showUpload = selectedView === 'none'

  return <ResponsiveContent
    title={capitalize(t('documents.document', {count: 2}))}
    extra={showUpload ?
      <PermissionRoute isAuthorized={isGenericDocumentCreation}>
        <UploadDocumentModal/>
      </PermissionRoute>
      : undefined
    }
  >
    <DocumentsTable
      selectedView={selectedView}
      setSelectedView={setSelectedView}/>
  </ResponsiveContent>;
}