import {Select, SelectProps, Spin} from "antd";
import {useGetEventsForSelectInfiniteScrollingQuery} from "../../redux/api/events";
import {useState} from "react";
import _ from "lodash";

export default function SelectEvent(selectProps?: SelectProps) {

  const [params, setParams] = useState({
    page: 1,
    q: "",
  })

  const {data, isFetching} = useGetEventsForSelectInfiniteScrollingQuery(
    {
      page: params.page,
      ...!!params.q && {q: params.q}
    }
  )

  const options = data?.results.map(el => (
    {
      label: `#${el.code} - ${el.name}`,
      value: el.uuid
    }))

  const debouncedSearch = _.debounce((value: string) => setParams(prevParams => ({page: 1, q: value})), 400);

  return <Select
    dropdownRender={(menu) => (
      <>
        {menu}
        {(isFetching && <Spin size="small" style={{width: '100%', padding: '0 12px', textAlign: 'center'}}/>)}
      </>
    )}
    loading={isFetching}
    options={options}
    showSearch
    filterOption={false}
    onSearch={(value) => debouncedSearch(value)}
    onPopupScroll={(e) => {
      const {target} = e;
      if (
        (target as any).scrollTop + (target as any).offsetHeight ===
        (target as any).scrollHeight
        &&
        data &&
        data?.results.length < data?.count
      ) {
        setParams(prevParams => ({...prevParams, page: prevParams.page + 1}))
      }
    }}
    {...selectProps}
  />
}