import React from 'react';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw-src.css';
import './App.css';
import AntTheme from './layout/AntTheme';
import Router from './routing/Router';
import {Provider as RTKProvider} from 'react-redux';
import {store} from './redux/store';
import {useConnectionCheck} from './customHooks/useConntectionCheck';
import {ConnectionStatusNotifier} from './components/common/ConnectionStatusNotifier';
import 'react-quill/dist/quill.core.css';
import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.snow.css';

function App() {

  useConnectionCheck();

  return (
    <RTKProvider store={store}>
      <AntTheme>
        <ConnectionStatusNotifier/>
        <Router/>
      </AntTheme>
    </RTKProvider>
  );
}

export default App;
