import {Button, ButtonProps, Card, Col, Row, Typography} from 'antd';
import React from 'react';
import {TitleProps} from 'antd/lib/typography/Title';

export default function SettingsSection({title, button}: { title: TitleProps, button: ButtonProps }) {
  return <Card>
    <Row
      justify={'space-between'}
      align={'middle'}
      gutter={[24, 24]}
    >
      <Col xs={24} md={17}>
        <Typography.Title level={4} style={{margin: 0}} {...title}/>
      </Col>
      <Col xs={24} md={7}>
        <Button
          block
          type={'primary'}
          {...button}
        />
      </Col>
    </Row>
  </Card>;
}