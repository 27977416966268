import {capitalize} from 'lodash';
import CustomNumericFormat from '../../../../customNumeric/CustomNumericFormat';
import {Form} from 'antd';
import React from 'react';
import {useTranslation} from 'react-i18next';

const DrawLineOffsetFormItems = () => {
  
  const {t} = useTranslation();
  
  return (
    <>
      <Form.Item
        name={'offset'}
        label={`${capitalize('offset')} (m)`}
        rules={[
          {required: true, message: capitalize(t('map.offsetRequired'))},
        ]}
        trigger={'onValueChange'}
        getValueFromEvent={e => e.floatValue}
      >
        <CustomNumericFormat allowNegative={false}/>
      </Form.Item>
    </>
  );
};

export default DrawLineOffsetFormItems;