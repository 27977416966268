import {Button, ButtonProps, Typography} from "antd";
import {FilterOutlined, FilterTwoTone} from "@ant-design/icons";
import {capitalize, isEqual} from "lodash";
import {useTranslation} from "react-i18next";
import {NOT_SET_DEFAUL_FILTERS_FE, useEventsContext} from "../../../context/emergenciesMap/EventsContext";

export default function EventsMapFilterButton(buttonProps: ButtonProps) {

  const {t} = useTranslation()
  const {filtersFE} = useEventsContext()

  const isFiltersEmpty = isEqual(filtersFE, NOT_SET_DEFAUL_FILTERS_FE)

  return <Button
    type={'text'}
    icon={isFiltersEmpty ? <FilterOutlined/> : <FilterTwoTone/>}
    style={{paddingLeft: 0}}
    children={
      isFiltersEmpty ?
        capitalize(t('emergency.filterEmergencies'))
        : <Typography.Text strong>{capitalize(t('table.downloadFilters'))}</Typography.Text>
    }
    {...buttonProps}
  />
}