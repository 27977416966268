import React, {createContext, PropsWithChildren, useContext, useEffect, useState} from 'react';
import {AssetRealEstate, RealEstateContextType} from '../../types/internal/AddAssetForm';
import {useAssetDetails} from '../assetDetails/AssetDetails';
import {useAdditionalAssetData} from '../assetDetails/AdditionalAssetDataContext';

const EditRealEstateContext = createContext<RealEstateContextType>(
  [
    {
      realestate_hub: null,
      contact_people: [],
    },
    () => null,
  ],
);

export const useEditAssetRealEstateContext = () => useContext(EditRealEstateContext);

export const EditAssetRealEstateContext = (props: PropsWithChildren) => {

  const [, {asset, loading: isAssetLoading}] = useAssetDetails();
  const [additionalData, isAdditionalDataLoading] = useAdditionalAssetData();

  const [
    realestateDetails,
    setRealestateDetails,
  ] = useState<AssetRealEstate>({
    realestate_hub: null,
    contact_people: [],
  });

  useEffect(() => {
    if (!isAssetLoading && !isAdditionalDataLoading && asset) {
      setRealestateDetails(realestateDetails => ({
        realestate_hub: asset.realestate_hub ? {
          label: additionalData.realestateHub.name,
          value: additionalData.realestateHub.uuid,
        } : null,
        contact_people: additionalData.contact_people.map(person => ({
          label: person.full_name,
          value: person.uuid,
        })),
      }));
    }
  }, [additionalData, asset, isAdditionalDataLoading, isAssetLoading]);

  return (
    <EditRealEstateContext.Provider value={[realestateDetails, setRealestateDetails]}>
      {props.children}
    </EditRealEstateContext.Provider>
  );
};