import {apiSlice} from './baseApi';
import {GenericPaginatedApi, GenericPaginatedParamsApi} from '../../types/api/paginatedApi';
import L from 'leaflet';
import {Feature, MultiPolygon as TurfMultiPolygon, Position} from '@turf/helpers';

/*
* A Polygon is a planar Surface defined by 1 exterior boundary and 0 or more interior boundaries.
* Each interior boundary defines a hole in the Polygon.*/

export type Point = [number, number];
export type Boundary = Point[];
export type Polygon = Boundary[];
export type MultiPolygon = Polygon[];

export type MultiPolygonCoordinates = [number, number][][][];

export type BoundingBox = [number, number, number, number];

export type MultiPolygonGeometry = {
  type: 'MultiPolygon',
  coordinates: L.LatLngExpression[][][],
}

export type NewMultiPolygonGeometry = {
  type: 'MultiPolygon',
  coordinates: Position[][][],
}

export type CustomGeoShape = {
  geoshape: MultiPolygonGeometry,
  globalpolygon: string,
  uuid: string,
}

export type NewCustomGeoShape = {
  geoshape: NewMultiPolygonGeometry,
  globalpolygon: string,
  uuid: string,
}

export type GlobalPolygon = {
  id: number,
  type: string,
  geometry:
    {
      type: string,
      coordinates: MultiPolygon,
    },
  bbox: BoundingBox,
  properties:
    {
      uuid: string,
    }
}

export type NewGlobalPolygon = {
  id: number,
  type: string,
  geometry: NewMultiPolygonGeometry,
  bbox: BoundingBox,
  properties:
    {
      uuid: string,
    }
}

export type Entity = {
  country: string,
  name: string,
  level: 0 | 1 | 2 | 3,
  globalpolygon: string,
  uuid: string,
}

export enum Level {
  ADMO,
  ADM1,
  ADM2,
  ADM3,
  ADM4,
  ADM5,
}

interface FilterEntity extends GenericPaginatedParamsApi {
  country?: string,
  level?: Level,
  name?: string,
}

const geoShapesApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getCustomGeoshapes: build.query<CustomGeoShape[], void>({
      query: (args) => (
        {
          url: '/geoshapes/custom/',
          params: {
            paginate: false,
          },
        }
      ),
    }),
    newGetGlobalGeoshapes: build.query<NewGlobalPolygon, string>({
      query: (uuid) => (
        {
          url: `/geoshapes/global/${uuid}/`,
        }
      ),
    }),
    getGlobalGeoshapes: build.query<GlobalPolygon, { uuid: string }>({
      query: ({uuid}) => (
        {
          url: `/geoshapes/global/${uuid}/`,
        }
      ),
    }),
    newCustomPolygonCreation: build.mutation<NewCustomGeoShape, Feature<TurfMultiPolygon, {}>>({
      query: (polygon) => {
        return {
          url: '/geoshapes/custom/',
          method: 'POST',
          body: {
            geoshape: polygon.geometry,
          },
        };
      },
    }),
    createCustomPolygon: build.mutation<CustomGeoShape, { coordinates: MultiPolygonCoordinates }>({
      query: ({coordinates}) => {
        return {
          url: '/geoshapes/custom/',
          method: 'post',
          body: {geoshape: {type: 'MultiPolygon', coordinates: coordinates}},
        };
      },
    }),
    newDeleteCustomPolygon: build.mutation<void, string>({
      query: (uuid) => {
        return {
          url: `/geoshapes/custom/${uuid}/`,
          method: 'DELETE',
        };
      },
    }),
    deleteCustomPolygon: build.mutation<void, { uuid: string }>({
      query: ({uuid}) => {
        return {
          url: `/geoshapes/custom/${uuid}/`,
          method: 'delete',
        };
      },
    }),
    getGeoshapesEntitiesPaginated: build.query<GenericPaginatedApi<Entity>, FilterEntity>({
      query: (params) => (
        {
          url: '/geoshapes/entities/',
          params: {
            ...params,
            paginate: true,
          },
        }
      ),
    }),
    getGeoshapesEntity: build.query<Entity, { uuid: string }>({
      query: ({uuid}) => (
        {
          url: `/geoshapes/entities/${uuid}`,
        }
      ),
    }),
  }),
});

export const {
  useGetCustomGeoshapesQuery,
  useCreateCustomPolygonMutation,
  useNewCustomPolygonCreationMutation,
  useNewDeleteCustomPolygonMutation,
  useLazyNewGetGlobalGeoshapesQuery,
  useDeleteCustomPolygonMutation,
  useLazyGetGlobalGeoshapesQuery,
  useLazyGetGeoshapesEntitiesPaginatedQuery,
  useLazyGetGeoshapesEntityQuery,
} = geoShapesApi;