import {PropsWithChildren} from 'react';
import {BaseMapContext} from '../baseMap/BaseMapContext';
import {AlertMarkersContext} from './AlertsMarkersContext';

export default function AlertMapContext(props: PropsWithChildren) {
  return (
    <AlertMarkersContext>
      <BaseMapContext>
        {props.children}
      </BaseMapContext>
    </AlertMarkersContext>
  );
}