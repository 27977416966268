import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
import {useLazyGetAssetsForInfiniteScrollingQuery} from '../../../../../redux/api/assets';
import {OptionLabelValue} from '../../../../../types/internal/OptionLabelValue';
import {getTranslationAssetTableColumnKey} from '../../../../../utils/translation';
import NEWListWithSearchFilter from '../../../../../components/common/filters/NEWListWithSearchFilter';

export const ImportReportsAssetFilters = (props: {
  activeFilters: OptionLabelValue[] | null,
  handleFilterChange: (values: ([] | OptionLabelValue[])) => void
}) => {

  const {t} = useTranslation();

  const [
    getAssets,
    {
      data,
      isLoading,
      isFetching,
      isUninitialized,
      originalArgs,
    },
  ] = useLazyGetAssetsForInfiniteScrollingQuery();

  const [querySearch, setQuerySearch] = useState<string>('');
  const options: OptionLabelValue[] = useMemo(() => data?.results.map(el => ({
    label: el.sigi_code,
    value: el.uuid,
  })) || [], [data?.results]);

  const placeholder = capitalize(t('table.filterPlaceholder', {column: t(getTranslationAssetTableColumnKey('sigi_code'))}));

  const handleGetNext = useCallback(() => {
    getAssets({
      ...!!querySearch && {search_by_sigi: querySearch},
      sorters: 'sigi_code',
      page: (originalArgs?.page || 0) + 1,
      page_size: 15,
    });
  }, [getAssets, originalArgs?.page, querySearch]);

  useEffect(() => {
    getAssets({
      sorters: 'sigi_code',
      ...!!querySearch && {search_by_sigi: querySearch},
      page: 1,
      page_size: 15,
    });
  }, [getAssets, querySearch]);

  return <NEWListWithSearchFilter
    getNext={handleGetNext}
    options={options}
    selectedOptions={props.activeFilters || []}
    setSelectedOptions={props.handleFilterChange}
    isLoadingOptions={isLoading || isFetching || isUninitialized}
    total={data?.count || 0}
    querySearch={querySearch}
    setQuerySearch={setQuerySearch}
    search={{placeholder}}
  />;
};