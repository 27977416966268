import {Card, Col, Row, Space, Typography} from 'antd';
import {capitalize} from 'lodash';
import AlertsToAssociateToEvent from './AlertsToAssociateToEvent';
import AlertsAssociatedToEventTable from './AlertsAssociatedToEventTable';
import {useTranslation} from 'react-i18next';
import React, {useState} from "react";
import MoveAlertsToBeManagedButton from "../../../alerts/MoveAlertsToBeManagedButton";
import {useEventContext} from "../../../../context/event/EventContext";
import {REDUCED_STATES} from "../../../../types/api/getEventsResult";

export default function EventCreationAlertsSection() {
  const {t} = useTranslation();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const {event} = useEventContext()

  return <Card>
    <Row
      justify={'space-between'}
      align={'middle'}
      style={{
        marginBottom: '1rem',
      }}
    >
      <Col>
        <Typography.Title level={3}>{capitalize(t('events.alertsConnected'))}</Typography.Title>
      </Col>
      {event?.reduced_state !== REDUCED_STATES.archived && <Col>
        <Space>
          <AlertsToAssociateToEvent/>
          <MoveAlertsToBeManagedButton selectedAlerts={selectedRowKeys} afterRemove={() => setSelectedRowKeys([])}/>
        </Space>
      </Col>}
    </Row>
    <AlertsAssociatedToEventTable
      {...event?.reduced_state !== REDUCED_STATES.archived
      && {
        selectedAlerts: selectedRowKeys,
        setSelectedAlerts: setSelectedRowKeys
      }
      }
    />
  </Card>;
}