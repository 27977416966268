import React, {useState} from 'react';
import {Button, Checkbox, CheckboxProps, Col, Modal, ModalProps, Row, SelectProps, Typography} from 'antd';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
import AntFormItem from '../../common/AntFormItem';
import {usePatchEditAlertMutation} from '../../../redux/api/alerts';
import {useMessageContext} from '../../../context/message/MessageContext';
import SelectEventTypes from '../creation/SelectEventTypes';
import EventTypeForm from '../../settings/events/formEventType/EventTypeForm';
import {EventType} from '../../../types/api/eventTypes';

function AssignEventType({checkbox, select}: { checkbox?: CheckboxProps, select?: SelectProps }) {

  const {t} = useTranslation();

  return <div>
    <AntFormItem
      label={capitalize(t('events.eventType', {count: 1}))}
      labelCol={{span: 24}}
      wrapperCol={{span: 24}}
    >
      <SelectEventTypes
        showSearch
        placeholder={capitalize(t('actions.selectItem', {item: t('events.eventType', {count: 1})}))}
        {...select}
      />
    </AntFormItem>

    <Checkbox {...checkbox}>
      {capitalize(t('actions.insertItem', {item: t('events.eventType', {count: 1})}))}
    </Checkbox>
  </div>;
}

export default function AssignEventTypeToAlert({selectedAlerts, modal, afterAssignment}: {
  selectedAlerts: React.Key[],
  afterAssignment?: () => void,
  modal?: ModalProps
}) {

  const {t} = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [patchAlert] = usePatchEditAlertMutation();
  const setMessage = useMessageContext();
  const [eventType, setEventType] = useState<string>('');
  const [isNewEventType, setIsNewEventType] = useState(false);

  const assignEventType = async (eventUuid: string) => {
    if (eventUuid) {
      try {
        const promises = selectedAlerts.map(uuid => patchAlert({
          uuid: uuid as string,
          event_type: eventUuid,
        }).unwrap());
        await Promise.all(promises);
        setMessage({success: capitalize(t('form.assignSuccessMessage'))});
      } catch {
        setMessage({error: capitalize(t('form.assignFailMessage'))});
      } finally {
        if (afterAssignment) {
          afterAssignment();
        }
      }
    }
  };

  const createAndAssign = async (values: EventType) => {
    await assignEventType(values.uuid);
  };

  return <>
    <Modal
      styles={{
        content: {padding: 0},
        header: {padding: '1rem 1.5rem', margin: 0, borderBottom: '1px solid #F0F0F0'},
        body: {
          display: 'flex',
          flexDirection: 'column',
          gap: '2rem',
          padding: isNewEventType ? '2rem 2.5rem 1rem 2.5rem ' : '2rem 2.5rem',
        },
        footer: {padding: isNewEventType ? 0 : '1rem 1.5rem', margin: 0, borderTop: '1px solid #F0F0F0'},
      }}
      closeIcon={false}
      children={<>
        <AssignEventType
          select={{
            disabled: isNewEventType,
            onSelect: v => setEventType(v),
          }}
          checkbox={{
            onChange: (v) => setIsNewEventType(v.target.checked),
          }}
        />
        {isNewEventType && <>
          <Typography.Title
            level={5}
            style={{margin: 0}}
          >{capitalize(t('actions.createItem', {item: t('events.eventType', {count: 1})}))}</Typography.Title>
          <EventTypeForm onFinish={createAndAssign}/>
        </>}
      </>}
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      footer={!isNewEventType ? <Row justify={'end'}>
        <Col xs={24} md={11}>
          {!isNewEventType && <Button
            type={'primary'}
            disabled={!eventType}
            block
            onClick={() => assignEventType(eventType)}
          >
            {capitalize(t('alerts.assign'))}
          </Button>}
        </Col>
      </Row> : null}
      {...modal}
    />
    <Button
      type={'primary'}
      block
      onClick={() => setIsModalOpen(true)}
    >
      {capitalize(t('alerts.assignType'))}
    </Button>
  </>;
}