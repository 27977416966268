import {Card, Skeleton} from 'antd';
import {useAdditionalAssetData} from '../../../context/assetDetails/AdditionalAssetDataContext';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
import {RealEstateData} from '../RealEstateData';

export const RealEstateDataCard = () => {

  const {t} = useTranslation();
  const [details, loadingAdditionalData] = useAdditionalAssetData();

  return (
    <Card title={capitalize(t('misc.realEstateData'))}>
      {(loadingAdditionalData) && <Skeleton active={true}/>}
      {!(loadingAdditionalData) && (
        <RealEstateData details={details}/>
      )}
    </Card>
  );
};
