import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {Pagination, Space, Table} from 'antd';
import {ColumnsType} from 'antd/lib/table';
import {capitalize} from 'lodash';
import {PaginationConfig} from 'antd/lib/pagination';
import ResponsiveContent from '../../../layout/ResponsiveContent';
import {AlertSource, useGetAlertSourcePaginatedQuery,} from '../../../redux/api/alertSource';
import {sanitize} from 'dompurify';
import DataSourceThresholdEdit from "../../../components/settings/dataSources/DataSourceThresholdEdit";
import {VALUE_NOT_PRESENT} from "../../../utils/translation";
import CustomNumericFormat from "../../../components/common/customNumeric/CustomNumericFormat";
import AlertSourceUnit from "../../../components/settings/dataSources/AlertSourceUnit";
import DateTimeFormat from "../../../locales/DateTimeFormat";
import RefreshAllAlertSources from "./RefreshAllAlertSources";

export default function DataSourcesSettings() {

  const {t} = useTranslation();
  const navigate = useNavigate();

  const [
    pagination,
    setPagination,
  ] = useState<PaginationConfig>({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    showTotal: (total, range) => t('table.pageSizeOfTotal', {
      rangeStart: range[0],
      rangeEnd: range[1],
      total,
    }),
    onChange: (page, pageSize) => setPagination(prevPag => ({...prevPag, current: page, pageSize})),
    style: {textAlign: 'center'},
  });

  const
    {
      data,
      isLoading,
      isFetching,
      isUninitialized,
      refetch,
    } = useGetAlertSourcePaginatedQuery({
      page: pagination.current,
      page_size: pagination.pageSize,
    }, {refetchOnMountOrArgChange: true});

  const isLoadingData = isLoading || isFetching || isUninitialized;

  const columns: ColumnsType<AlertSource> = [
    {
      key: 'name',
      dataIndex: 'name',
      title: capitalize(t('alerts.alertSourceName')),
      width: 200,
    },
    {
      key: 'description',
      dataIndex: 'description',
      width: 800,
      title: capitalize(t('misc.description')),
      render: text => <div
        dangerouslySetInnerHTML={{__html: sanitize(text)}}
        onClick={(e) => {
          if ((e.target as HTMLElement).getAttributeNames().includes('href')) {
            e.stopPropagation();
          }
        }}
      >
      </div>,
    },
    {
      key: 'threshold',
      dataIndex: 'threshold',
      title: capitalize(t('alerts.alertSourceThreshold')),
      width: 200,
      render: (text, record) => {

        return <Space
          onClick={(e) => e.stopPropagation()}
        >
          {typeof text === 'number' ? <div>
          <CustomNumericFormat displayType={'text'} value={text}/> <AlertSourceUnit alertSourceUuid={record.uuid}/>
          </div> : VALUE_NOT_PRESENT}
          {record.is_threshold_editable && <DataSourceThresholdEdit
            alertSourceUuid={record.uuid}
            initialValue={text}
          />}
        </Space>
      }
    },
    {
      key: 'schedule_info',
      dataIndex: 'schedule_info',
      width: 200,
      title: capitalize(t('alerts.alertSourceFrequency')),
      render: (v) => v || VALUE_NOT_PRESENT
    },
    {
      key: 'last_refresh',
      dataIndex: 'last_refresh',
      width: 100,
      title: capitalize(t('misc.lastUpdated')),
      render: (v) => {
        if (v) {
          return <DateTimeFormat>{v}</DateTimeFormat>
        }
      }
    }
  ];

  return <ResponsiveContent
    title={t('settings.dataSource', {count: 2})}
    extra={<RefreshAllAlertSources onFinish={() => refetch()}  />}
    goBack={() => navigate('/settings')}
    goBackText={t('settings.settings')}
  >
    <Table
      <AlertSource>
      columns={columns}
      pagination={false}
      dataSource={data?.results || []}
      rowKey={'uuid'}
      loading={isLoadingData}
      scroll={{x: true}}
      onRow={(record) => (
        {
          onClick: (e) => {
            navigate(`${record.uuid}`);
          },
          style: {
            cursor: 'pointer',
          },
        })
      }
    />
    {!!data?.count && <Pagination
      {...pagination}
      total={data?.count}
    />}
  </ResponsiveContent>;
}