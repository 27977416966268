import {Button, ButtonProps} from 'antd';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
import {useArchiveAlertMutation} from '../../redux/api/alerts';
import React, {useState} from 'react';
import {useMessageContext} from '../../context/message/MessageContext';
import DeleteModalMessage from '../common/documents/modals/DeleteModalMessage';
import {isAlertArchive} from '../Authentication/permissions';
import PermissionRoute from '../Authentication/PermissionRoute';

export default function ArchiveAlertButton({selectedAlerts, afterArchive, button}: {
  afterArchive?: () => void,
  selectedAlerts: React.Key[],
  button?: ButtonProps
}) {

  const {t} = useTranslation();
  const [archiveAlert] = useArchiveAlertMutation();
  const setMessage = useMessageContext();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleArchiveAlerts = async () => {
    try {
      const promises = selectedAlerts.map(uuid => archiveAlert({alertUuid: uuid as string}).unwrap());
      await Promise.all(promises);
      setMessage({success: capitalize(t('form.archiveSuccessMessage'))});
    } catch {
      setMessage({error: capitalize(t('form.archiveFailMessage'))});
    } finally {
      if (afterArchive)
        afterArchive();
    }
  };

  return <PermissionRoute
    isAuthorized={isAlertArchive}
  >
    <DeleteModalMessage
      title={{
        children: capitalize(t('alerts.archiveAlert')),
      }}
      paragraph={{
        children: t('alerts.archive'),
      }}
      modal={{
        open: isModalOpen,
        onCancel: () => setIsModalOpen(false),
      }}
      confirmButton={{
        children: capitalize(t('alerts.archiveAlert')),
        onClick: handleArchiveAlerts,
        danger: false,
      }}
    />
    <Button
      disabled={!selectedAlerts.length}
      onClick={() => setIsModalOpen(true)}
      {...button}
    >
      {capitalize(t('alerts.archiveAlert'))}
    </Button>
  </PermissionRoute>;
}