import {Tag} from "antd";
import {capitalize} from "lodash";
import React from "react";
import {WarningOutlined} from "@ant-design/icons";
import {GRECIM} from "../../../utils/const";

export default function GrecimTag() {
  return <Tag
    icon={<WarningOutlined/>}
    color={'error'}
  >
    {capitalize(GRECIM)}
  </Tag>
}