import {Button, Card, Col, ConfigProvider, Descriptions, DescriptionsProps, Row, Space, Spin, Typography} from 'antd';
import {useEventContext} from '../../../context/event/EventContext';
import React from 'react';
import EventTypeFromUuid from '../../common/icons/EventTypeFromUuid';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import CustomLatitudeNumericFormat from "../../common/customNumeric/CustomLatitudeNumericFormat";
import CustomLongitudeNumericFormat from "../../common/customNumeric/CustomLongitudeNumericFormat";
import {VALUE_NOT_PRESENT} from "../../../utils/translation";
import {AlertSourcesLinks} from "../../events/creation/EventChangesSummary";
import {Link} from "react-router-dom";
import {EVENT_STATES, GetEvent} from "../../../types/api/getEventsResult";
import {useGetEventQuery} from "../../../redux/api/events";
import {skipToken} from "@reduxjs/toolkit/query";
import DateTimeFormat from "../../../locales/DateTimeFormat";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import EditEventModal from "../../events/edit/EditEventModal";
import {EditOutlined} from "@ant-design/icons";

const {Title, Text} = Typography;

export function AncestorEvent({uuid}: { uuid?: string }) {
  const {data: ancestorEventData, isFetching} = useGetEventQuery(uuid ? {uuid} : skipToken);

  return <>
    {isFetching && <Spin/>}
    {uuid && !isFetching && ancestorEventData &&
      <Link to={`/events/${ancestorEventData.uuid}`}>
        <Button
          type={'link'}
          style={{padding: 0}}
        >
          #{ancestorEventData.code} - {ancestorEventData.name}
        </Button>
      </Link>
    }
    {!uuid && VALUE_NOT_PRESENT}
  </>
}

type EditField = 'description'

function EventDescriptionData({event}: { event: GetEvent }) {

  const {t} = useTranslation();

  const items: DescriptionsProps['items'] = [
    {
      label: capitalize(t('events.lastUpdated')),
      children: <DateTimeFormat>{event.last_update}</DateTimeFormat>,
    },
    {
      label: capitalize(t('events.latLonEpicenter')),
      children: event?.epicenter ? <Space>
        <div>
          <CustomLatitudeNumericFormat
            value={event.epicenter.lat} displayType={"text"}/>,
        </div>
        <CustomLongitudeNumericFormat
          value={event.epicenter.lng} displayType={"text"}/>
      </Space> : VALUE_NOT_PRESENT
    },
    {
      label: capitalize(t('events.dataSources')),
      span: {xs: 1, sm: 2, md: 2, lg: 2, xl: 2, xxl: 2},
      children: <AlertSourcesLinks dataSources={event?.alert_source}/>,
    },
    {
      label: capitalize(t('events.description')),
      span: {xs: 1, sm: 2, md: 2, lg: 2, xl: 2, xxl: 2},
      children: <div style={{whiteSpace: 'pre-line'}}>{event?.description || VALUE_NOT_PRESENT}</div>
    },
    {
      label: capitalize(t('events.ancestorEvent')),
      children: <AncestorEvent uuid={event?.ancestor_event || ""}/>,
    },
  ];

  return <ConfigProvider
    theme={{
      components: {
        Descriptions: {
          itemPaddingBottom: 0
        },
      },
    }}
  >
    <Descriptions
      style={{marginTop: '2rem'}}
      layout={'vertical'}
      bordered={false}
      colon={false}
      items={items}
      column={{xs: 1, sm: 2, md: 2, lg: 2, xl: 2, xxl: 2}}
      labelStyle={{
        fontWeight: 'bold',
        color: 'rgba(0, 0, 0, 0.88)',
      }}
      contentStyle={{marginBottom: '2rem'}}
    />
  </ConfigProvider>
}


export const EventDetailsCard = () => {

  const {event} = useEventContext();
  const {md, lg, xl, xxl} = useBreakpoint()
  const isSmallScreen = !(md || lg || xl || xxl)
  return (
    <Card style={{flexGrow: 1}}>
      <Row align={'top'} justify={'space-between'} wrap={false}>
        <Col xs={12}>
          <Row>
            <Col>
              <Title level={4} style={{marginTop: 0}}>
                {event?.name}
                {(event?.state === EVENT_STATES.Forecast || event?.state === EVENT_STATES.Happened) && <EditEventModal
                  editButtonProps={{
                    type: 'text',
                    icon: <EditOutlined/>,
                    children: null
                  }}
                />}
              </Title>
            </Col>
          </Row>
          <Row>
            <Col>
              <Text style={{fontSize: 14}}>
                {!!event?.code && `#${event.code}`}
              </Text>
            </Col>
          </Row>
        </Col>

        <Col xs={12} style={{textAlign: 'right'}}>

          {event?.event_type && !(isSmallScreen) && <EventTypeFromUuid
            uuid={event.event_type}
            tag
            tagProps={{
              style: {backgroundColor: event.color_code}
            }}
            iconStyle={{
              backgroundColor: event.color_code,
            }}
          />}
          {event?.event_type && isSmallScreen && <EventTypeFromUuid
            uuid={event.event_type}
            withTitle={false}
            iconStyle={{
              height: '100%',
              width: '24px',
              padding: '10px',
              backgroundColor: event.color_code,
            }}
          />}
        </Col>
      </Row>

      {event && <EventDescriptionData event={event}/>}
    </Card>
  );
};