import {Card, Col, Row, Space, Typography} from 'antd';
import {capitalize} from 'lodash';
import CreateTemplateMail from './CreateTemplateMail';
import React from 'react';
import {t} from 'i18next';
import TemplateMailTable from './TemplateMailTable';
import PermissionRoute from '../../Authentication/PermissionRoute';
import {isTemplateMailCreation} from '../../Authentication/permissions';
import ResetTemplateMailFiltersSorters from './filters/ResetTemplateMailFiltersSorters';

const {Title} = Typography;

export const TemplateMailCard = () => {
  return (
    <>
      <Card
        title={(
          <Row
            justify={'space-between'}
            align={'middle'}
          >
            <Col>
              <Title
                level={3}
                style={{margin: 0}}
              >
                {capitalize(t('mailTemplate.mailTemplate'))}
              </Title>
            </Col>
            <Space>
              <Col>
                <ResetTemplateMailFiltersSorters/>
              </Col>
              <PermissionRoute isAuthorized={isTemplateMailCreation}>
                <Col>
                  <CreateTemplateMail/>
                </Col>
              </PermissionRoute>
            </Space>
          </Row>
        )}
      >
        <TemplateMailTable/>
      </Card>
    </>
  );
};