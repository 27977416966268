import React from "react";
import EpicenterMarker from "../EpicenterMarker";
import EventAssetMarkers from "../EventAssetMarkers";
import MapLoading from "../../../common/map/MapLoading";
import EventEditMapRecapDrawControlsWrapper from "./EventEditMapRecapDrawControlsWrapper";
import {useEventCenterMap} from "../../../../customHooks/useCenterMap";
import {useMapLoadingContext} from "../../../../context/baseMap/MapLoadingContext";

export default function EventEditMapRecap() {

  const [loading] = useMapLoadingContext()
  useEventCenterMap(loading)

  return <>
    <MapLoading/>
    <EventAssetMarkers isAssignCluster/>
    <EventEditMapRecapDrawControlsWrapper />
    <EpicenterMarker isEdit/>
  </>;
}