import React, {useState} from "react";
import {Button, ButtonProps} from "antd";
import {useTranslation} from "react-i18next";
import {useMessageContext} from "../../context/message/MessageContext";
import {capitalize} from "lodash";
import PermissionRoute from "../Authentication/PermissionRoute";
import {isEventDelete} from "../Authentication/permissions";
import DeleteModalMessage from "../common/documents/modals/DeleteModalMessage";
import {useDeleteEventMutation} from "../../redux/api/events";
import {Link} from "react-router-dom";

export default function DeleteEventButton({selectedEvents, afterDelete, button}: {
  afterDelete?: () => void,
  selectedEvents: string[]
  button?: ButtonProps
}) {

  const {t} = useTranslation();
  const [deleteEvent, {isLoading: isDeleting}] = useDeleteEventMutation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const setMessage = useMessageContext();

  const handleDeleteEvents = async () => {
    try {
      const promises = selectedEvents.map(uuid => deleteEvent({uuid: uuid as string}).unwrap());
      await Promise.all(promises);
      setMessage({success: capitalize(t('form.deleteSuccessMessage'))});
    } catch (res) {
      console.log(res)
      const descendants = (!!res &&
        typeof res === 'object' &&
        'status' in res && res.status === 400 &&
        'data' in res &&
        (res.data as {
          descendants: string[],
        }).descendants) || [];
      if (descendants.length) {
        setMessage({
          custom: {
            content: <>
              {capitalize(t('events.deleteEventFail'))}
              <ul>
                {descendants.map((desc, idx) => (
                  <li
                    key={desc}
                    style={{textAlign: 'left'}}
                  >
                    <Link
                      to={`/events/${desc}`}>
                      {t('events.event', {count: 1})} #{idx + 1}
                    </Link>
                  </li>)
                )}
              </ul>
            </>,
            type: 'error',
          },
        });
      } else {
        setMessage({error: capitalize(t('form.deleteFailMessage'))});
      }
    } finally {
      setIsModalOpen(false)
      if (afterDelete)
        afterDelete();
    }
  };

  return <PermissionRoute
    isAuthorized={isEventDelete}
  >
    <DeleteModalMessage
      title={{
        children: capitalize(t('actions.deleteItem', {item: t('events.event', {count: 1})})),
      }}
      paragraph={{
        children: t('events.deleteEvent', {count: selectedEvents.length})
      }}
      modal={{
        open: isModalOpen,
        onCancel: () => setIsModalOpen(false),
      }}
      confirmButton={{
        children: capitalize(t('actions.delete')),
        onClick: handleDeleteEvents,
        disabled: isDeleting,
        loading: isDeleting
      }}
    />
    <Button
      danger
      onClick={() => setIsModalOpen(true)}
      {...button}>
      {capitalize(t('actions.deleteItem', {item: t('events.event')}))}
    </Button>
  </PermissionRoute>;
}