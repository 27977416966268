import {Checkbox, Col, Row} from 'antd';
import {OptionLabelValue} from '../../../types/internal/OptionLabelValue';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';

type TableFilterProps =
  {
    filters: OptionLabelValue[],
    onChange: (values: OptionLabelValue[] | []) => void,
    selectedFilters?: OptionLabelValue[],
    loadingOptions?: boolean,
    hasNullOption?: boolean
  }

export default function StandardFilter(props: TableFilterProps) {

  const {t} = useTranslation();
  const checkBoxOptions = props.hasNullOption ? [{
    label: capitalize(t('misc.nullValue')),
    value: 'null',
  }, ...props.filters] : props.filters;

  const content = <Checkbox.Group
    onChange={(checkedValue) => {
      const options = checkBoxOptions.filter(el => checkedValue.includes(el.value));
      props.onChange(options);
    }}
    value={props.selectedFilters?.map(el => el.value)}
  >
    <Row>
      {checkBoxOptions.map(filter => <Col span={24} key={filter.value.toString()}>
        <Checkbox key={filter.value.toString()} value={filter.value}>
          {filter.label}
        </Checkbox>
      </Col>)}
    </Row>
  </Checkbox.Group>;

  return (
    <div
      style={{width: '15rem'}}
    >
      {props.loadingOptions ? 'loading options...' : content}
    </div>);
}