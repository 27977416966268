import {useEventContext} from '../../../context/event/EventContext';
import {Card, ConfigProvider, Descriptions, DescriptionsProps, Flex, Space, Typography} from 'antd';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
import DateTimeFormat from '../../../locales/DateTimeFormat';
import {EVENT_STATES} from '../../../types/api/getEventsResult';
import CustomLatitudeNumericFormat from "../../common/customNumeric/CustomLatitudeNumericFormat";
import CustomLongitudeNumericFormat from "../../common/customNumeric/CustomLongitudeNumericFormat";
import React, {ReactElement, useEffect} from "react";
import {VALUE_NOT_PRESENT} from "../../../utils/translation";


export function AlertSourcesLinks({dataSources}: {
  dataSources?: string[] | null
}) {
  return <Flex vertical>
    {dataSources?.reduce((previousValue, currentValue, currentIndex) => (
          [...previousValue,
            <Typography.Link
              key={currentIndex}
              href={currentValue}
              target="_blank"
            >
              {currentValue}
            </Typography.Link>
          ])
        , [] as ReactElement[]) ||
      VALUE_NOT_PRESENT}
  </Flex>
}

export default function EventChangesSummary() {

  const {event, refetchEvent} = useEventContext();
  const {t} = useTranslation();

  const forecastOrHappened = event?.state === EVENT_STATES.Forecast ? capitalize(t('events.forecast')) :
    event?.state === EVENT_STATES.Happened ? capitalize(t('events.happened'))
      : event?.state === EVENT_STATES.Closed ? capitalize(t('events.closed')) : '';

  const items: DescriptionsProps['items'] = [
    {
      label: capitalize(t('events.involvedAssets')),
      children: event?.involved_assets || VALUE_NOT_PRESENT,
    },
    {
      label: capitalize(t('events.forecastOrHappened')),
      children: forecastOrHappened,
    },
    {
      label: capitalize(t('events.dateStartEvent')),
      children: event?.start_datetime ? <DateTimeFormat>{event?.start_datetime}</DateTimeFormat> : VALUE_NOT_PRESENT,
    },
    {
      label: capitalize(t('events.dateStartForecast')),
      children: event?.forecast_start_datetime ?
        <DateTimeFormat>{event?.forecast_start_datetime}</DateTimeFormat> : VALUE_NOT_PRESENT,
    },
    {
      label: capitalize(t('events.latLonEpicenter')),
      children: event?.epicenter ? <Space>
        <div><CustomLatitudeNumericFormat value={event?.epicenter?.lat} displayType={"text"}/>,</div>
        <CustomLongitudeNumericFormat value={event?.epicenter?.lng} displayType={"text"}/>
      </Space> : VALUE_NOT_PRESENT,
    },
    {
      label: capitalize(t('events.dataSources')),
      children: <AlertSourcesLinks dataSources={event?.alert_source}/>,
    },
    {
      label: capitalize(t('events.description')),
      children: <div style={{whiteSpace: 'pre-line'}}>{event?.description || VALUE_NOT_PRESENT}</div>,
    },
  ];

  useEffect(() => {
    // assets involved might have been changed
    refetchEvent()
  }, [refetchEvent]);

  return <>
    <Card>
      <ConfigProvider
        theme={{
          components: {
            Descriptions: {
              itemPaddingBottom: 0
            },
          },
        }}
      >
        <Descriptions
          title={capitalize(t('events.stepCreationSummary'))}
          layout={'vertical'}
          bordered={false}
          colon={false}
          items={items}
          column={{xs: 1, md: 2, lg: 3, xxl: 4}}
          labelStyle={{
            fontWeight: 'bold',
            color: 'rgba(0, 0, 0, 0.88)',
          }}
          contentStyle={{marginBottom: '1rem'}}
        />
      </ConfigProvider>
    </Card>
  </>;
}