import {EventsCard} from "./EventsCard";
import {Divider, List, Pagination} from "antd";
import {useEventsContext} from "../../context/emergenciesMap/EventsContext";
import {useGetEventsPaginatedQuery} from "../../redux/api/events";
import {useState} from "react";
import {PaginationConfig} from "antd/lib/pagination";
import {useTranslation} from "react-i18next";
import {fromFeToBeEventsMap} from "../../utils/parser";
import {PHASE} from "../../types/api/getEventsResult";

export default function EventsMapList() {

  const {t} = useTranslation()
  const {filtersFE, selectedEvent, selectEvent, unselectEvent} = useEventsContext()
  const [pagination, setPagination] = useState<PaginationConfig>({
    current: 1,
    pageSize: 10,
    showTotal: (total, range) => t('table.pageSizeOfTotal', {
      rangeStart: range[0],
      rangeEnd: range[1],
      total,
    }),
    onChange: (page, pageSize) => setPagination(prevPag => ({...prevPag, current: page, pageSize})),
    style: {textAlign: 'center', marginTop: '40px'},
  });

  const {data, isFetching, isUninitialized} = useGetEventsPaginatedQuery({
    page: pagination.current, page_size:
    pagination.pageSize,
    ...fromFeToBeEventsMap(filtersFE),
    phase: `${PHASE.LIVE},${PHASE.EDIT}`
  })

  return <>
    <Divider style={{margin: 0}}/>
    <List
      loading={isFetching || isUninitialized}
      style={{height: 'calc(100% - 14rem)', overflow: 'scroll'}}
      header={null}
      footer={null}
      dataSource={data?.results}
      itemLayout={'vertical'}
      pagination={false}
      rowKey={'uuid'}
      renderItem={(item) => {
        const isEventSelected = item.uuid === selectedEvent
        return <EventsCard
          selected={isEventSelected}
          onSelect={() => {
            if (isEventSelected) {
              unselectEvent()
            } else {
              selectEvent(item.uuid)
            }
          }}
          event={item}
        />
      }}
    />
    <Divider style={{margin: 0}}/>
    <Pagination
      {...pagination}
      total={data?.count}
    />
  </>
}