import {apiSlice} from './baseApi';
import {Company} from '../../types/internal/Companies';
import {PaginatedApi} from '../../types/api/paginatedApi';

interface GetCompanies extends PaginatedApi {
  results: Company[];
}

const companiesApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getCompaniesNotPaginated: build.query<Company[], {
      uuids?: string[],
      name?: string,
      page?: number,
      page_size?: number,
      q?: string
    }>({
      query: ({uuids, ...payload}) => {
        
        return {
          url: '/company/',
          params: {
            ...payload,
            paginate: false,
            ...{...!!uuids?.length && {uuids: uuids.join(',')}},
          },
        };
      },
    }),
    getCompaniesPaginated: build.query<GetCompanies, {
      uuids?: string[],
      name?: string,
      page?: number,
      page_size?: number,
      q?: string
    }>({
      query: ({uuids, ...payload}) => {
        
        return {
          url: '/company/',
          params: {
            ...payload,
            ...{...!!uuids?.length && {uuids: uuids.join(',')}},
          },
        };
      },
    }),
    getCompaniesForInfiniteScrolling: build.query<GetCompanies, {
      uuids?: string[],
      name?: string,
      page?: number,
      page_size?: number,
      q?: string
    }>({
      query: ({uuids, ...payload}) => {
        
        return {
          url: '/company/',
          params: {
            ...payload,
            ...{...!!uuids?.length && {uuids: uuids.join(',')}},
          },
        };
      },
      serializeQueryArgs: ({queryArgs}) => {
        const newQueryArgs = {...queryArgs};
        delete newQueryArgs.page;
        return newQueryArgs;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems, query) => {
        if ((query.arg.page || 0) > 1) {
          return {
            ...currentCache,
            ...newItems,
            results: [...currentCache.results, ...newItems.results],
          };
        }
        return newItems;
      },
    }),
  }),
});

export const {
  useLazyGetCompaniesNotPaginatedQuery,
  useLazyGetCompaniesForInfiniteScrollingQuery,
  useLazyGetCompaniesPaginatedQuery,
} = companiesApi;