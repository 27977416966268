import {useResizingMapContext} from '../../../../context/baseMap/ResizingMapContext';
import {useMap} from 'react-leaflet';
import {useEffect} from 'react';

export const HandleResize = () => {

  const [
    isResizing,
    setIsResizing,
  ] = useResizingMapContext();

  const map = useMap();

  useEffect(() => {
    setIsResizing((prevState) => {
      if (prevState && !isResizing) {
        map.invalidateSize();
      }
      return isResizing;
    });
  }, [isResizing, map, setIsResizing]);

  return (
    <>
    </>
  );
};