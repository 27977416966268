import {capitalize} from 'lodash';
import {Button} from 'antd';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

export default function AlertHistoryButton() {
  const {t} = useTranslation();
  return <Link
    to={'/alerts-history'}
  >
    <Button type={'link'} style={{'padding': 0}}>
      {capitalize(t('actions.viewItem', {item: t('alerts.history')}))}
    </Button>
  </Link>;
}