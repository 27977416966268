import {Select} from 'antd';
import {useGetAssetsOptionsQuery} from '../../../redux/api/assets';
import {capitalize} from 'lodash';
import Label from '../Label';
import {useTranslation} from 'react-i18next';

export const CountriesSearchSelect = (props: {
  value: string | null | undefined,
  onClear: () => void,
  onSelect: (value: string, option: { label: string, value: string, }) => void,
  disabled?: boolean,
}) => {

  const {t} = useTranslation();
  const {data: assetsOptions, isLoading} = useGetAssetsOptionsQuery();

  return (<>
    <Label
      label={capitalize(t('address.country'))}
      required={true}
      htmlFor={'country_select'}
    />
    <Select
      options={assetsOptions?.country}
      placeholder={capitalize(t('address.country'))}
      loading={isLoading}
      id={'country_select'}
      style={{width: '100%', marginBottom: 20}}
      filterOption={true}
      optionFilterProp={'label'}
      showSearch={true}
      allowClear={true}
      value={props.value}
      onClear={props.onClear}
      onSelect={props.onSelect}
      disabled={props.disabled === true}
    />
  </>);
};