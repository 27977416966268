export type EventsReducerState = {
  selectedEvent: string | null,
  eventsInMap: string[]
}

export const INITIAL_EVENTS_REDUCER_STATE = {
  selectedEvent: null,
  eventsInMap: []
}

type EventReducerAction =
  {
    type: 'selectEvent',
    payload: { eventUuid: string }
  }
  |
  {
    type: 'unselectEvent',
  }
  |
  {
    type: 'showEventInMap',
    payload: { eventUuid: string }
  }
  |
  {
    type: 'hideEventFromMap',
    payload: { eventUuid: string }
  }
  |
  {
    type: 'reset',
  }

export function EventsReducer(state: EventsReducerState, action: EventReducerAction): EventsReducerState {
  switch (action.type) {
    case "reset":
    return {
      selectedEvent: null,
      eventsInMap: []
    };
    case "unselectEvent":
      return {
        ...state,
        selectedEvent: null
      }
    case 'selectEvent':
      return {
        ...state,
        selectedEvent: action.payload.eventUuid
      }
    case 'showEventInMap':
      return {
        ...state,
        eventsInMap: [...state.eventsInMap, action.payload.eventUuid]
      }
    case 'hideEventFromMap':
      return {
        ...state,
        eventsInMap: state.eventsInMap.filter(el => action.payload.eventUuid !== el)
      }
    default:
      return state;
  }
  
}