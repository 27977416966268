import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '../store';

// Define a type for the slice state
interface AuthState {
  isRefreshTokenVerified: boolean,
  accessToken: string | null
}

// Define the initial state using that type
const initialState = {
  isRefreshTokenVerified: false,
  accessToken: null,
} as AuthState;

export const authSlice = createSlice({
  name: 'auth',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setAccessToken: (state, {payload: {token}}: PayloadAction<{ token: string }>) => {
      state.accessToken = token;
    },
    resetAccessToken: (state) => {
      state.accessToken = null;
    },
  },
});

export const {setAccessToken, resetAccessToken} = authSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAuthSlice = (state: RootState) => state.auth;
export default authSlice.reducer;
