import {lineString, LineString, MultiLineString, Position} from '@turf/helpers';
import {Marker, Polyline} from 'react-leaflet';
import L from 'leaflet';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import length from '@turf/length';
import {useBaseTileLayerContext} from '../../../context/baseMap/BaseTileLayerContext';

const RulerMeasurement = ({line, eventHandlers, id, isHighlighted = false}: {
  line: L.Polyline<LineString | MultiLineString, any>,
  eventHandlers?: L.LeafletEventHandlerFnMap,
  id: number,
  isHighlighted?: boolean,
}) => {
  
  const [baseLayer] = useBaseTileLayerContext();
  
  const lengthInMeters = length(lineString(line.toGeoJSON(6).geometry.coordinates as Position[]), {units: 'meters'});
  
  const lengthLabelComponent = ReactDOMServer.renderToString(
    <div className="length-label">
      {lengthInMeters > 1000 ?
        `${(lengthInMeters / 1000).toFixed(0)}km` :
        `${lengthInMeters.toFixed(0)}m`}
    </div>);
  
  return (
    <>
      <Marker
        icon={L.divIcon({
          className: 'length-label-marker',
          iconAnchor: [0, 0],
          html: lengthLabelComponent,
        })}
        position={line.getBounds().getCenter()}
      />
      
      <Polyline
        key={`${id}_${baseLayer}`}
        pathOptions={{
          weight: isHighlighted ? 3 : 2,
          color: (baseLayer === 'hybrid' || baseLayer === 'satellite') ? '#ffffff' : '#595959',
          dashArray: '3, 4',
          dashOffset: '0',
          bubblingMouseEvents: false,
        }}
        eventHandlers={eventHandlers}
        positions={line.getLatLngs() as L.LatLngExpression[]}
      />
    </>
  );
};

export default RulerMeasurement;