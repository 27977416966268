import {useLazyGetAlertsPaginatedQuery} from '../../../../redux/api/alerts';
import AlertsEventTable from './AlertsEventTable';
import {GetAlertsParams} from '../../../../types/api/alerts';
import {useCallback} from 'react';
import {GetEvent} from '../../../../types/api/getEventsResult';
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function AlertsToAssociateToEventTable({event}: { event: GetEvent }) {

  const navigate = useNavigate()
  const {t} = useTranslation()

  const [getData, {data, isFetching, isUninitialized}] = useLazyGetAlertsPaginatedQuery();
  const handleGetData = useCallback((params: GetAlertsParams) => getData({
    ...params,
    event_type: event.event_type,
    is_managed: false,
  }), [event.event_type, getData]);

  return <AlertsEventTable
    tableProps={{
      dataSource: data?.results,
      loading: isFetching || isUninitialized,
      onRow: (record) => ({
        onClick: (e) => {
          navigate(`/alerts/${record.uuid}`, {
            state: {
              path: `/events/${event?.uuid}`,
              label: `${t('events.event', {count: 1})}`
            }
          });
        },
        style: {
          cursor: 'pointer',
        },
      })
    }}
    paginationProps={{
      total: data?.count,
    }}
    getData={handleGetData}
    event={event}
  />;
}