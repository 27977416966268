import {Button, Card, Col, Row, Space, Typography} from 'antd';
import React from 'react';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useGetImportReportsStatisticsQuery} from '../../../redux/api/importReport';

export default function ImportReportsSection() {

  const {t} = useTranslation();
  const navigate = useNavigate();
  const {data} = useGetImportReportsStatisticsQuery();

  return <Card>
    <Row
      justify={'space-between'}
      align={'middle'}
      gutter={[24, 24]}
    >
      <Col xs={24} md={17}>
        <Space wrap>
          <Typography.Title level={4} style={{margin: 0}}>
            {capitalize(t('settings.dataImport'))}
          </Typography.Title>
          {!!data?.errors &&
            <Typography.Text
              type={'danger'}
            >{t('dataImport.reportError', {number: data?.errors})}</Typography.Text>}
          {!!data?.warnings && <Typography.Text
            type={'warning'}
          >{t('dataImport.reportWarning', {number: data?.warnings})}</Typography.Text>}
        </Space>
      </Col>
      <Col xs={24} md={7}>
        <Button
          block
          type={'primary'}
          onClick={() => navigate('import-report')}
        >
          {capitalize(t('actions.view'))}
        </Button>
      </Col>
    </Row>
  </Card>;
}