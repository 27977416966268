import {PropsWithChildren} from 'react';
import {MarkersContext} from './MarkersContext';
import {MapDrawerContext} from './MapDrawerContext';
import {BaseMapContext} from '../baseMap/BaseMapContext';
import {ResizingMapContext} from '../baseMap/ResizingMapContext';

export const AssetsMapContext = (props: PropsWithChildren) => {

  return (
    <MapDrawerContext>
      <ResizingMapContext>
        <BaseMapContext>
          <MarkersContext>
            {props.children}
          </MarkersContext>
        </BaseMapContext>
      </ResizingMapContext>
    </MapDrawerContext>
  );
};