import {useMap} from 'react-leaflet';
import {useCallback, useEffect, useRef} from 'react';
import {Marker as LeafletMarker} from 'leaflet';
import {ASSET_MAP_ACTIONS, MAX_ZOOM} from '../../common/map/constants/map';
import {useControlsAction} from '../../../context/baseMap/ControlContext';
import {CustomMarkerProps, MemoizedMarker} from '../../common/map/CustomMarker';

export const AssetsMapMarker = (props: CustomMarkerProps) => {

  const markerRef = useRef<LeafletMarker | null>(null);

  const intervalRef = useRef<NodeJS.Timeout>();

  const iterationRef = useRef(0);

  const map = useMap();
  const {
    action: {
      currentAction,
      actionEvent,
    },
  } = useControlsAction();

  const {
    markerUuid,
    ...markerProps
  } = props;

  const openPopup = useCallback(() => {
    iterationRef.current += 1;
    if (markerRef.current) {
      const popup = markerRef.current?.getPopup();
      if (popup !== undefined && popup.getLatLng() !== undefined) {
        map.openPopup(popup);
        iterationRef.current = 0;
        clearInterval(intervalRef.current);
      }
      if (popup !== undefined && popup.getLatLng() === undefined) {
        popup.setLatLng(markerRef.current?.getLatLng());
        map.openPopup(popup);
        iterationRef.current = 0;
        clearInterval(intervalRef.current);
      }
      if (iterationRef.current === 20) {
        clearInterval(intervalRef.current);
      }
    }
  }, [map]);

  const handleOpenMarker = useCallback(() => {
    map.closePopup();
    if (markerUuid === actionEvent && markerRef.current) {
      const targetPoint = map.project(markerRef.current.getLatLng(), MAX_ZOOM).add([0, -(map.getSize().y / 3)]);
      const targetLatLng = map.unproject(targetPoint, MAX_ZOOM);
      map.flyTo(targetLatLng, MAX_ZOOM, {animate: true, duration: 0.5});
      intervalRef.current = setInterval(openPopup, 200);
    }
  }, [markerUuid, actionEvent, map, openPopup]);

  const handleCloseMap = useCallback(() => {
    if (markerRef.current) {
      map.closePopup();
      clearInterval(intervalRef.current);
    }
  }, [map]);

  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    switch (currentAction) {
      case ASSET_MAP_ACTIONS.SELECT_MARKER:
        handleOpenMarker();
        break;
      case ASSET_MAP_ACTIONS.CLOSE_MAP_DRAWER:
        handleCloseMap();
        break;
      default:
        break;
    }
  }, [currentAction, handleCloseMap, handleOpenMarker]);

  return (
    <MemoizedMarker {...markerProps}
                    ref={markerRef}
    />
  );
};