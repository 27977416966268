import {useGetAssetClimateChangeQuery} from "../../../../redux/api/asset";
import {ColumnsType} from "antd/lib/table";
import {Empty, Flex, Skeleton, Table, Typography} from "antd";
import CustomNumericFormat from "../../../common/customNumeric/CustomNumericFormat";
import HelpTooltip from "../../../common/HelpTooltip";
import CoordinatesAssetMissing from "../../../common/CoordinatesAssetMissing";
import {skipToken} from "@reduxjs/toolkit/query";
import {isEmpty} from "lodash";

const iconStyle = {fontSize: '', marginLeft: '0.2rem'}
export default function ClimateChangeContent({assetUuid, missingCoordinates = false}: {
  assetUuid: string,
  missingCoordinates?: boolean
}) {

  const {
    data: climateChange,
    isFetching,
    isLoading
  } = useGetAssetClimateChangeQuery(!missingCoordinates ? {assetUuid} : skipToken)

  const columns: ColumnsType<any> = climateChange?.columns.map(col => ({
    key: col.name,
    dataIndex: col.name,
    width: 200,
    title: <>
      <span>
        {col.label} {col.um ? `(${col.um})` : ""}
      </span>
      {!!col.description &&
        <HelpTooltip tooltip={{title: col.description}} iconStyle={iconStyle}/>}
    </>,
    render: (v) => {
      if (col.legend) {
        const currentLegendItem = col.legend.find(legendItem => legendItem.name === v)
        if (currentLegendItem) {
          return <>
            {currentLegendItem.label}
            <HelpTooltip tooltip={{title: currentLegendItem.description}} iconStyle={iconStyle}/>
          </>
        }
      } else {
        return <CustomNumericFormat
          value={v}
          displayType={'text'}
        />
      }
    }
  })) || []
  const tables = climateChange?.tables.map(el => <Table
    key={el.name}
    scroll={{x: true}}
    className={'climate-change__table'}
    columns={columns}
    dataSource={climateChange?.data?.[el.name].map((el, idx) => ({...el, key: idx}))}
    loading={isFetching}
    title={() => <>
      <Typography.Title level={5} style={{margin: 0}}>
        {el.label}
      </Typography.Title>
      <Typography.Paragraph
        style={{margin: 0}}
        italic
        >
        {el.description}. Dataset: {el.dataset}
      </Typography.Paragraph>
    </>
    }
    pagination={false}
  />)
  return <Flex vertical gap={'middle'}>
    {!missingCoordinates ?
      <>
        {isLoading && <Skeleton active/>}
        {!isFetching && isEmpty(climateChange?.data) && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
        {tables}
      </>
      :
      <CoordinatesAssetMissing/>
    }
  </Flex>
}