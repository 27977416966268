import React from 'react';
import {useTranslation} from 'react-i18next';
import SettingsSection from '../../components/settings/SettingsSection';
import {useNavigate} from 'react-router-dom';
import ResponsiveContent from '../../layout/ResponsiveContent';
import ImportReportsSection from '../../components/settings/importReport/ImportReportsSection';
import {capitalize} from 'lodash';
import PermissionRoute from '../../components/Authentication/PermissionRoute';
import {
  isDataSourcesView,
  isEventTypesView,
  isImportDataView,
  isLayerMapView,
} from '../../components/Authentication/permissions';

export default function Settings() {
  
  const {t} = useTranslation();
  const navigate = useNavigate();
  
  return <ResponsiveContent title={t('settings.settings')}>
    <div style={{display: 'flex', flexDirection: 'column', gap: '2rem'}}>
      <PermissionRoute isAuthorized={isEventTypesView}>
        <SettingsSection
          button={{
            children: capitalize(t('actions.manageItem', {item: t('events.eventType', {count: 2})})),
            onClick: () => navigate('events'),
          }}
          title={{
            children: capitalize(t('events.eventType', {count: 2})),
          }}
        />
      </PermissionRoute>
      
      <PermissionRoute
        isAuthorized={isLayerMapView}
      >
        <SettingsSection
          button={{
            children: capitalize(t('actions.manageItem', {item: t('layers.layer', {count: 2})})),
            onClick: () => navigate('layers'),
          }}
          title={{
            children: capitalize(t('layers.layer', {count: 2})),
          }}
        />
      </PermissionRoute>
      
      <PermissionRoute
        isAuthorized={isDataSourcesView}
      >
        <SettingsSection
          button={{
            children: capitalize(t('actions.viewItem', {item: t('settings.dataSource', {count: 2})})),
            onClick: () => navigate('data-sources'),
          }}
          title={{
            children: capitalize(t('settings.dataSource', {count: 2})),
          }}
        />
      </PermissionRoute>
      
      <PermissionRoute
        isAuthorized={isImportDataView}
      >
        <ImportReportsSection/>
      </PermissionRoute>
    </div>
  </ResponsiveContent>;
}