import {Button, ButtonProps} from 'antd';
import {DownloadOutlined} from '@ant-design/icons';
import {capitalize} from 'lodash';
import React from 'react';
import {useTranslation} from 'react-i18next';

export default function DownloadButton(button?: ButtonProps) {

  const {t} = useTranslation();

  return <Button
    icon={<DownloadOutlined/>}
    type={'primary'}
    children={capitalize(t('actions.download'))}
    {...button}
  />;
}