import React, {useEffect, useState} from 'react';
import {useDeleteAssetDocumentMutation, useLazyGetAssetDocumentsQuery} from '../../../redux/api/documents';
import {useParams} from 'react-router-dom';
import DocumentsList from '../../common/documents/DocumentsList';
import {useGetUserProfileQuery} from '../../../redux/api/locales';
import {isAssetDocumentDelete, isAssetDocumentEdit, isAssetDocumentView} from '../../Authentication/permissions';

export const AssetDocumentsList = () => {

  const {assetId} = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [deleteDocument] = useDeleteAssetDocumentMutation();

  const handleRemove = (documentUuid: string) => {
    if (assetId) {
      return deleteDocument({assetUuid: assetId, documentUuid: documentUuid}).unwrap();
    } else {
      return Promise.reject();
    }
  };

  const [
    getDocuments,
    {
      isLoading: isAssetDocumentsLoading,
      isFetching: isAssetDocumentsFetching,
      data: assetsDocuments,
    },
  ] = useLazyGetAssetDocumentsQuery();

  const {
    data: userData,
  } = useGetUserProfileQuery();

  const isEditing = userData?.type ? isAssetDocumentEdit(userData?.type) : false;
  const isRemoving = userData?.type ? isAssetDocumentDelete(userData?.type) : false;
  const isDownload = userData?.type ? isAssetDocumentView(userData?.type) : false;

  useEffect(() => {
    if (assetId) {
      getDocuments({uuid: assetId, page: currentPage, page_size: currentPageSize});
    }
  }, [assetId, currentPage, getDocuments, currentPageSize]);

  return <DocumentsList
    documents={assetsDocuments?.results}
    total={assetsDocuments?.count || 0}
    page={currentPage}
    pageSize={currentPageSize}
    setPage={setCurrentPage}
    setPageSize={setCurrentPageSize}
    loading={isAssetDocumentsLoading || isAssetDocumentsFetching}
    {...isRemoving && {onRemove: handleRemove}}
    withEdit={isEditing}
    withDownload={isDownload}
  />;
};