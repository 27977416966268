import {Button, Col, Row} from 'antd';
import {AssetDetail} from './AssetDetail';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
import {Link} from 'react-router-dom';
import {MapDetailsContext} from '../../../../context/assetsMap/AssetMapDetailsContext';
import {Popup, useMap} from 'react-leaflet';

export const AssetDetailsPopup = ({markerUuid}: { markerUuid: string}) => {

  const {t} = useTranslation();

  const map = useMap();

  return (
    <Popup
      className={'asset-position-marker-popup'}
      autoPan={true}
      autoPanPadding={[(map.getSize().x - 300) / 2, (map.getSize().y - 226) / 2]}
    >
      <MapDetailsContext>
        <AssetDetail
          markerUuid={markerUuid}/>
        <Row justify={'center'} style={{marginTop: '1rem'}}>
          <Col>
            <Link to={`/assets/${markerUuid}/`}>
              <Button type={'primary'}>
                {capitalize(t('misc.goToFullDetails'))}
              </Button>
            </Link>
          </Col>
        </Row>
      </MapDetailsContext>
    </Popup>
  );
};