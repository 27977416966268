import {Dayjs} from 'dayjs';
import {OptionLabelValue} from './OptionLabelValue';
import {Sorter} from './tables/sorters';

export const alertTableColumns = [
  'assets_count',
  'end_datetime',
  'event',
  'event_type',
  'geographic_area_list',
  'is_forecast',
  'is_user_created',
  'source_url',
  'start_datetime',
  'title'] as const;

export type AlertTableColumn = (typeof alertTableColumns)[number]

export type AlertsTableStandardWithSearchFilters = Pick<Record<AlertTableColumn, OptionLabelValue[] | null>,
  'event' |
  'event_type' |
  'is_forecast' |
  'is_user_created'
>

export type AlertsTableSearchFilters = Pick<Record<AlertTableColumn, string | null>,
  'source_url' |
  'title'
>

export type AlertsTableDateRangeFilters = Pick<Record<AlertTableColumn, [string, string] | null>,
  'start_datetime' |
  'end_datetime'
>

export type AlertsTableNumberRangeFilters = Pick<Record<AlertTableColumn, [number, number] | null>,
  'assets_count'
>

export type AlertTableFilters =
  AlertsTableStandardWithSearchFilters
  & AlertsTableDateRangeFilters
  & AlertsTableNumberRangeFilters
  & AlertsTableSearchFilters

export type AlertTableSorters = { [K in AlertTableColumn]: Sorter }

export const DEFAULT_ALERT_TABLE_FILTERS: AlertTableFilters = {
  'assets_count': null,
  'source_url': null,
  'end_datetime': null,
  'event': null,
  'event_type': null,
  // 'geographic_area_list': null,
  'is_forecast': null,
  'is_user_created': null,
  'start_datetime': null,
  'title': null,
};

export const DEFAULT_ALERT_TABLE_SORTERS: AlertTableSorters = {
  'assets_count': null,
  'source_url': null,
  'end_datetime': null,
  'event': null,
  'event_type': null,
  'geographic_area_list': null,
  'is_forecast': null,
  'is_user_created': null,
  'start_datetime': null,
  'title': null,
};

export type AlertTableMode = 'managed' | 'toManage'

export type AlertCreationForm = {
  'description'?: string,
  'end_datetime'?: Dayjs
  'event_type': string,
  'is_forecast': boolean,
  'source_url'?: string,
  'start_datetime': Dayjs,
  'title': string,
}

