import {DrawableGeometry} from './NewDrawControls';
import {FeatureGroup, useMap, useMapEvent} from 'react-leaflet';
import {EditControl} from 'react-leaflet-draw';
import React, {ForwardedRef, forwardRef, useCallback, useEffect, useRef, useState} from 'react';
import L from 'leaflet';
import {
  flipTurfFeatureCoordinates,
  fromMultiPolygonToPolygons,
  fromPolygonsToMultiPolygon,
  toMultiPolygon,
} from '../../../../../utils/polygons';
import {Feature, MultiPolygon, multiPolygon, Point, point, points} from '@turf/helpers';
import {Polygon as PolygonHelper} from '@turf/helpers/dist/js/lib/geojson';
import {useMapLoadingContext} from '../../../../../context/baseMap/MapLoadingContext';
import {useControlsAction} from '../../../../../context/baseMap/ControlContext';
import center from '@turf/center';
import distance from '@turf/distance';
import CustomLeafletCircleLayer from '../../../../../utils/CustomLeafletCircleLayer';
import CustomLeafletPolygonLayer from '../../../../../utils/CustomLeafletPolygonLayer';
import {ASSET_MAP_ACTIONS} from '../../constants/map';
import circle from '@turf/circle';

const EditableFeature = forwardRef((
  {
    geometry,
    isCircle,
    disableEditMode,
    onEditSave,
  }: {
    geometry?: DrawableGeometry,
    isCircle?: boolean,
    disableEditMode: () => void,
    onEditSave: (editedGeometry: Feature<MultiPolygon, {}>, geometryUuid: string, map: L.Map) => Promise<void>
  }, ref: ForwardedRef<L.Control> | undefined) => {
  
  const featureGroupRef = useRef<L.FeatureGroup<any>>(null);
  const leafletDrawInstanceRef = useRef<L.Control>();
  
  const map = useMap();
  const [, setIsMapLoading] = useMapLoadingContext();
  const {changeAction} = useControlsAction();
  
  const [
    toActivate,
    setToActivate,
  ] = useState(false);
  
  const handleShapeEdit = useCallback((e: L.DrawEvents.Edited) => {
    const editedLayers: {
      uuid: string,
      feature_index: number,
      geometry: Feature<PolygonHelper | MultiPolygon, any>
    }[] = [];
    
    //@ts-ignore
    e.layers.eachLayer((l) => editedLayers.push(l.getProperties()));
    
    if (isCircle) {
      e.layers.eachLayer(l => {
        const radius = (l as L.Circle).getRadius();
        const center = point((l as L.Circle).toGeoJSON(6).geometry.coordinates);
        const newCircle = circle(center, radius, {units: 'meters'});
        if (geometry?.uuid) {
          setIsMapLoading(true);
          onEditSave(toMultiPolygon(newCircle), geometry.uuid, map)
            .catch(e => {
              console.log('errore: ', e);
            })
            .finally(() => {
              changeAction(ASSET_MAP_ACTIONS.SELECT_GLOBAL_POLYGON, {uuid: undefined});
              setIsMapLoading(false);
            });
        }
      });
    } else {
      if (geometry && editedLayers.length) {
        const drawnGeometriesFeatures = fromMultiPolygonToPolygons(multiPolygon(geometry.coordinates));
        editedLayers.forEach(editedLayer => drawnGeometriesFeatures.splice(editedLayer.feature_index, 1, editedLayer.geometry as Feature<PolygonHelper, any>));
        const editedMultipolygon = fromPolygonsToMultiPolygon(drawnGeometriesFeatures);
        setIsMapLoading(true);
        onEditSave(editedMultipolygon, geometry.uuid, map)
          .catch(e => {
            console.log('errore: ', e);
          })
          .finally(() => {
            changeAction(ASSET_MAP_ACTIONS.SELECT_GLOBAL_POLYGON, {uuid: undefined});
            setIsMapLoading(false);
          });
      }
    }
  }, [changeAction, geometry, isCircle, map, onEditSave, setIsMapLoading]);
  
  useEffect(() => {
    if (leafletDrawInstanceRef.current && toActivate) {
      //@ts-ignore
      leafletDrawInstanceRef.current._toolbars.edit._modes.edit?.handler?.enable();
      setToActivate(false);
    }
  }, [toActivate]);
  useEffect(() => {
    featureGroupRef.current?.eachLayer(layer => layer.remove());
    if (geometry) {
      const polygons = fromMultiPolygonToPolygons(multiPolygon(geometry.coordinates));
      polygons.forEach((polygon, idx) => {
        
        let featureToAdd;
        
        if (isCircle) {
          const features = points(polygon.geometry.coordinates.flat());
          
          const centerOfPolygon = center(features);
          
          // @ts-ignore
          const justAPoint = point(polygon.geometry.coordinates.flat().at(0));
          
          const radius = distance(centerOfPolygon, justAPoint, {units: 'meters'});
          
          featureToAdd = new CustomLeafletCircleLayer(
            flipTurfFeatureCoordinates(centerOfPolygon as Feature<Point, {}>).geometry.coordinates as L.LatLngExpression,
            {radius: radius},
            geometry.uuid,
            idx,
          );
        } else {
          featureToAdd = new CustomLeafletPolygonLayer(
            flipTurfFeatureCoordinates(polygon)
              .geometry.coordinates as L.LatLngExpression[][],
            {
              ...geometry.pathOptions,
              opacity: 1,
              dashArray: '12, 12',
              dashOffset: '0',
            },
            geometry.uuid,
            idx,
          );
        }
        featureGroupRef.current?.addLayer(featureToAdd);
      });
    }
  }, [geometry, isCircle]);
  
  useEffect(() => {
    return () => {
      if (leafletDrawInstanceRef.current) {
        map.removeEventListener('draw:editstart');
        map.removeEventListener('draw:editstop');
        map.removeEventListener('draw:edited');
        map.removeControl(leafletDrawInstanceRef.current);
      }
    };
  }, []);
  
  // @ts-ignore
  useMapEvent('draw:editresize', (l) => {
    
    if (isCircle) {
      //@ts-ignore
      leafletDrawInstanceRef.current?._map?._editTooltip?.updateContent({
        text: L.drawLocal.draw.handlers.circle.radius + ': ' +
          L.GeometryUtil.readableDistance(l.layer.getRadius(), true),
      });
    }
    
  });
  
  return (
    <>
      <FeatureGroup
        ref={featureGroupRef}
      >
        <EditControl
          position={'topright'}
          draw={{
            polyline: false,
            polygon: false,
            rectangle: false,
            circle: false,
            marker: false,
            circlemarker: false,
          }}
          edit={{edit: true, remove: false}}
          onEditStop={() => {
            featureGroupRef.current?.eachLayer(layer => layer.remove());
            disableEditMode();
            if (typeof ref === 'function') {
              ref(null);
            } else if (ref) {
              ref.current = null;
            }
          }}
          onEdited={handleShapeEdit}
          onMounted={(e: L.Control) => {
            if (typeof ref === 'function') {
              ref(e);
            } else if (ref) {
              ref.current = e;
              leafletDrawInstanceRef.current = e;
            }
            setToActivate(true);
          }}
        />
      </FeatureGroup>
    </>
  );
});

export default EditableFeature;