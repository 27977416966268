import {AlertPolygonGet} from '../../../../redux/api/alerts';
import {Descriptions, Typography} from 'antd';
import {DescriptionsItemType} from 'antd/lib/descriptions';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import {ExpandableCollapsableParagraph} from '../../../common/ExpandableCollapsableParagraph';

export default function AlertPolygonInfo({alertPolygon}: { alertPolygon: AlertPolygonGet }) {
  const {t} = useTranslation();
  
  let alertInfo = alertPolygon.data?.additional_info || {};
  if (alertPolygon.data?.intensity?.text && alertPolygon.data?.intensity?.value) {
    alertInfo = {
      ...alertInfo,
      [alertPolygon.data?.intensity.text]: alertPolygon.data?.intensity.value,
    };
  }
  
  const items: DescriptionsItemType[] = [];
  for (const [key, value] of Object.entries(alertInfo)) {
    items.push({
      key,
      label: key,
      children: <ExpandableCollapsableParagraph
        style={{margin: 0, padding: 0}}
        text={value}
        withTooltip={false}
        onClick={e => e.stopPropagation()}
      />,
    });
  }
  
  return <>
    <Typography.Title level={5}>
      {capitalize(t('misc.description'))}
    </Typography.Title>
    <div style={{marginTop: '0.5rem', maxHeight: '400px', overflowY: 'scroll'}}>
      {!!items.length && <Descriptions
        size={'small'}
        column={1}
        items={items}
      />}
      {!items.length && <Typography.Paragraph style={{marginBottom: 0}}>{capitalize(t('alerts.descriptionNotPresent'))}</Typography.Paragraph>}
    </div>
  </>;
}