import {useState} from 'react';
import {Button, Modal} from 'antd';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import {useEventContext} from '../../../context/event/EventContext';
import {EVENT_STATES} from "../../../types/api/getEventsResult";
import {usePatchEventMutation} from "../../../redux/api/events";
import PermissionRoute from "../../Authentication/PermissionRoute";
import {isEventArchive} from "../../Authentication/permissions";

export default function UnarchiveEvent() {

  const {t} = useTranslation();

  const {event} = useEventContext();
  const [updateEvent] = usePatchEventMutation()

  const [
    isModalOpen,
    setIsModalOpen,
  ] = useState(false);

  return (<PermissionRoute isAuthorized={isEventArchive}>
    <Modal
      title={capitalize(t('events.unarchiveEvent'))}
      centered={true}
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      okButtonProps={{
        type: 'primary',
        onClick: () => {
          if (event?.uuid) {
            let previousState: EVENT_STATES.Happened | EVENT_STATES.Forecast | null = null
            switch (event?.state) {
              case EVENT_STATES.Closed:
                previousState = EVENT_STATES.Happened
                break;
              case EVENT_STATES['Not Happened']:
                previousState = EVENT_STATES.Forecast
                break
            }
            if (previousState) {
              updateEvent({uuid: event?.uuid, state: previousState})
            }
          }
        }
      }}
      onOk={() => {
        setIsModalOpen(false);
      }}
      okText={capitalize(t('actions.confirm'))}
    >
      {t('events.unarchiveEventDescription')}
    </Modal>
      <Button block type={'primary'} onClick={() => setIsModalOpen(true)}>
      {capitalize(t('events.unarchiveEvent'))}
    </Button>
  </PermissionRoute>);
}