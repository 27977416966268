import {DatePicker, DatePickerProps} from 'antd';
import {useGetUserProfileQuery} from '../../redux/api/locales';

export const LocalizedDatePicker = (props: DatePickerProps) => {

  const {data} = useGetUserProfileQuery();

  let formatDate = data?.language === 'it' ? 'DD/MM/YYYY' : 'YYYY/MM/DD';

  if ('showTime' in props) {
    formatDate = formatDate.concat(' HH:mm');
  }

  return (
    <DatePicker
      {...props}
      format={formatDate}
    />
  );
};