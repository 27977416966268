import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import ResponsiveContent from '../../../layout/ResponsiveContent';
import {capitalize} from 'lodash';
import LayerForm from '../../../components/settings/layers/LayerForm';
import {useLazyGetLayerQuery, useUpdateLayerMutation} from '../../../redux/api/layers';
import {Alert, Typography} from 'antd';
import {useMessageContext} from '../../../context/message/MessageContext';

export default function EditLayer() {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {uuid} = useParams();
  const [
    updateLayer,
    {
      isSuccess: updateSuccess,
      isError: updateError,
      isLoading: updateLoading,
      error: updateErrorDescription,
    }] = useUpdateLayerMutation();
  const [
    getLayer,
    {
      data,
    }] = useLazyGetLayerQuery();

  const alertText = (updateErrorDescription && 'status' in updateErrorDescription && updateErrorDescription.status === 400 && (updateErrorDescription.data as {
    status: string,
    text: string
  }).text) || '';

  useEffect(() => {
    if (uuid) {
      getLayer({uuid});
    }
  }, [getLayer, uuid]);

  const setAntdMessage = useMessageContext();

  useEffect(() => {
    if (updateSuccess) {
      navigate('/settings/layers/');
      setAntdMessage({
        success: capitalize(t('form.editSuccessMessage')),
      });
    }
  }, [navigate, setAntdMessage, t, updateSuccess]);

  useEffect(() => {
    if (updateError) {
      setAntdMessage({
        error: capitalize(t('form.editFailMessage')),
      });
    }
  }, [setAntdMessage, t, updateError]);

  return <>
    {!!alertText && <Alert
      message={alertText}
      type="error"
      showIcon
    />}
    <ResponsiveContent
      goBack={() => navigate('/settings/layers')}
      goBackText={capitalize(t('layers.layersManagement'))}
    >
      {!!uuid && !!data && (
        <LayerForm
          isEditing={true}
          card={{
            title: <Typography.Title
              level={3}
              style={{margin: '1rem 0'}}
            >
              {capitalize(t('actions.editItem', {item: t('layers.layer', {count: 1})}))}
            </Typography.Title>,
          }}
          button={{
            children: capitalize(t('actions.editItem', {item: t('layers.layer', {count: 1})})),
          }}
          form={{
            disabled: updateLoading || updateSuccess,
            initialValues: {...data, upload_mode: data?.is_internal ? 'rei' : 'ext'},
            onFinish: (values) => {
              const {upload_mode, ...params} = values;
              if (upload_mode === 'ext' || !params.layer_file) {
                updateLayer({...params, uuid});
              } else {
                navigate('/settings/layers/upload-file', {
                  state: {
                    ...values,
                    uuid,
                    type: 'edit',
                  },
                });
              }
            },
          }}
          uploadFormItem={{
            rules: [],
          }}
        />
      )}

    </ResponsiveContent>
  </>;
}