import {Col, List, Row, Typography} from 'antd';
import {LabeledValue} from '../common/LabeledValue';
import _, {capitalize} from 'lodash';
import {titleCase} from '../../utils/parser';
import {useTranslation} from 'react-i18next';
import {RealEstateHub} from '../../types/internal/RealEstateHub';
import {ContactPerson} from '../../types/api/contactPerson';
import {Company} from '../../types/internal/Companies';
import {AssetModel} from '../../types/internal/AssetModel';

const {Text} = Typography;
export const RealEstateData = ({details}: {
  details: {
    realestateHub: RealEstateHub,
    contact_people: ContactPerson[],
    companies: Company[],
    master_asset: AssetModel | null,
  }
}) => {

  const {t} = useTranslation();

  return (
    <>
      <Row>
        <Col span={12}>
          <LabeledValue
            label={capitalize(t('assets.realestateHubName'))}
            value={details.realestateHub.name}
          />
        </Col>
      </Row>
      {!_.isEmpty(details.contact_people) && <List
        dataSource={details.contact_people}
        renderItem={(contactPerson) => {
          return (
            <>
              <Row style={{marginTop: '1rem'}}>
                <Col span={8}>
                  <LabeledValue
                    label={capitalize(t('assets.emergencyCoordinatorName'))}
                    value={titleCase(contactPerson.full_name.toLowerCase())}
                  />
                </Col>
                <Col span={8}>
                  <LabeledValue
                    label={capitalize(t('user.contactMail'))}
                    value={contactPerson.email}
                  />
                </Col>
                <Col span={8}>
                  <LabeledValue
                    label={capitalize(t('user.contactPhone'))}
                    value={contactPerson.phone_number}
                  />
                </Col>
              </Row>
            </>
          );
        }}
      />}
      {_.isEmpty(details.contact_people) && <Text type={'secondary'}>
        {capitalize(t('contacts.noContactPersonAvailable'))}
      </Text>}
    </>
  );
};