import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import ResponsiveContent from '../../layout/ResponsiveContent';
import AlertDocumentsForm from '../../components/alerts/form/AlertDocumentsForm';

export default function AlertDocuments() {

  const {t} = useTranslation();
  const {uuid} = useParams();
  const navigate = useNavigate();

  return <ResponsiveContent
    goBack={() => navigate(`/alerts/${uuid}`)}
    goBackText={t('alerts.alert', {count: 1})}
  >
    <AlertDocumentsForm/>
  </ResponsiveContent>;
}