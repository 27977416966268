import {createContext, PropsWithChildren, useCallback, useContext, useEffect, useState} from 'react';
import {useLocationContext} from './AssetLocationContext';
import {useAssetDetailsContext} from './AssetDetailsContext';
import {useAssetRealEstateContext} from './AssetRealEstateContext';
import {useCreateAssetMutation, useLazyGetAssetBySigiCodeQuery} from '../../redux/api/asset';
import {router} from '../../routing/Router';
import {AddAssetForm, SigiStatus} from '../../types/internal/AddAssetForm';
import _ from 'lodash';

const FormContext = createContext<AddAssetForm>({
  isFirstStepComplete: () => false,
  isSecondStepComplete: () => false,
  isThirdStepComplete: () => false,
  submit: () => null,
  isSigiValid: SigiStatus.to_be_checked,
  checkSigi: () => null,
});

export const useAddAssetFormContext = () => useContext(FormContext);

export const AddAssetFormContext = (props: PropsWithChildren) => {
  const [location] = useLocationContext();
  const [details] = useAssetDetailsContext();
  const [realestateDetails] = useAssetRealEstateContext();
  
  const [
    createAsset,
    {
      isSuccess: createAssetSuccess,
      data: createdAsset,
    },
  ] = useCreateAssetMutation();
  const [
    validateSigi,
    {
      data: assetsWithGivenSigiCode,
      isSuccess: isSigiCodeLookupSuccessful,
      isError: hasSigiCodeLookupFailed,
      isLoading: isSigiCodeLookupLoading,
      isFetching: isSigiCodeLookupFetching,
    },
  ] = useLazyGetAssetBySigiCodeQuery();
  
  const [
    sigiStatus,
    setSigiStatus,
  ] = useState<SigiStatus>(SigiStatus.to_be_checked);
  
  const isFirstStepComplete = useCallback(() => {
    return (
      location.position !== null &&
      location.country !== null &&
      location.country.trim() !== '' &&
      location.address !== null &&
      location.address.trim() !== '' &&
      location.admin_level_1 !== null &&
      location.admin_level_1.trim() !== '' &&
      location.admin_level_2 !== null &&
      location.admin_level_2.trim() !== '' &&
      location.admin_level_3 !== null &&
      location.admin_level_3.trim() !== '' &&
      location.postal_code !== null &&
      location.postal_code.trim() !== ''
    );
  }, [location.address, location.admin_level_1, location.admin_level_2, location.admin_level_3, location.country, location.position, location.postal_code]);
  
  const isSecondStepComplete = useCallback(() => {
    return details.sigi_code !== null && details.sigi_code.trim() !== '' && sigiStatus === SigiStatus.valid && details.opening_date !== null;
  }, [details.opening_date, details.sigi_code, sigiStatus]);
  
  const isThirdStepComplete = useCallback(() => {
    return true;
  }, []);
  
  useEffect(() => {
    if (createAssetSuccess && createdAsset) {
      router.navigate(`/assets/${createdAsset.uuid}`);
    }
  }, [createAssetSuccess, createdAsset]);
  
  const sigiValidation = (sigi_code: string) => sigi_code ? validateSigi({sigi_code: sigi_code}) : setSigiStatus(SigiStatus.to_be_checked);
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSigiValidation = useCallback(_.debounce(sigiValidation, 500), []);
  
  useEffect(() => {
    if (hasSigiCodeLookupFailed || (isSigiCodeLookupSuccessful && assetsWithGivenSigiCode && assetsWithGivenSigiCode.length >= 1)) {
      setSigiStatus(SigiStatus.not_valid);
      return;
    }
    if (isSigiCodeLookupFetching || isSigiCodeLookupLoading) {
      setSigiStatus(SigiStatus.loading);
      return;
    }
    if (isSigiCodeLookupSuccessful && assetsWithGivenSigiCode && assetsWithGivenSigiCode.length === 0) {
      setSigiStatus(SigiStatus.valid);
      return;
    }
  }, [assetsWithGivenSigiCode, hasSigiCodeLookupFailed, isSigiCodeLookupFetching, isSigiCodeLookupLoading, isSigiCodeLookupSuccessful]);
  
  const submit = () => {
    if (location.position && details.sigi_code !== null && sigiStatus === SigiStatus.valid) {
      createAsset({
        ...location,
        ...details,
        ...realestateDetails,
        sigi_code: details.sigi_code,
        is_user_created: true,
        realestate_hub: realestateDetails.realestate_hub ? realestateDetails.realestate_hub.value : null,
        contact_people: realestateDetails.contact_people.map(person => person.value),
        opening_date: details?.opening_date?.format('YYYY-MM-DD') || null,
        closing_date: details?.closing_date?.format('YYYY-MM-DD') || null,
        position: {lat: location.position[0], lng: location.position[1]},
        companies: details.companies.map(company => company.value),
        master_asset: details.master_asset?.value,
      });
    }
  };
  return (
    <FormContext.Provider
      value={{
        isFirstStepComplete,
        isSecondStepComplete,
        isThirdStepComplete,
        submit,
        isSigiValid: sigiStatus,
        checkSigi: debouncedSigiValidation,
      }}
    >
      {props.children}
    </FormContext.Provider>
  );
};