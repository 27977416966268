export const downloadFileResponseHandler = async (response: Response, filename?: string) => {
  if (response.status === 200) {
    await response.blob()
      .then((payload) => {
        const url = URL.createObjectURL(payload);
        let updatedFilename = filename || '';
        const disposition = response.headers.get('content-disposition');
        if (disposition) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            updatedFilename = matches[1].replace(/['"]/g, '');
          }
        }
        const a = document.createElement('a');
        a.href = url;
        a.download = updatedFilename;
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error('Blob Error', error);
      });
  } else {
    console.error('Download error - status: ', response.status);
  }
};