import {DocumentType, usePatchImageMutation} from '../../../../redux/api/documents';
import {capitalize} from 'lodash';
import {Button, Col, Form, Input, Modal, Row} from 'antd';
import {Dispatch, SetStateAction, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useMessageContext} from '../../../../context/message/MessageContext';
import {ACCEPTED_FILE_EXTENSIONS, sanitize_document_name, SPECIAL_CHARS_TO_ESCAPE} from '../../../../utils/documents';

const {TextArea} = Input;
export const EditImageModal = ({image, isOpen, setIsOpen}: {
  image: DocumentType,
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>
}) => {

  const {t} = useTranslation();

  const [editImageForm] = Form.useForm();
  const setMessage = useMessageContext();

  const [
    patchImage,
    {
      isLoading: isPatchingImage,
      isSuccess: isImagePatchSuccessful,
      isError: isImagePatchFailed,
      isUninitialized: isImagePatchUninitialized,
    },
  ] = usePatchImageMutation();

  const handleFinish = (values: any) => {
    patchImage({
      documentUuid: image.uuid,
      name: sanitize_document_name(values.document_name),
      description: values.description,
    });
  };

  useEffect(() => {
    if (isImagePatchSuccessful && !isImagePatchUninitialized) {
      setMessage({success: capitalize(t('form.editSuccessMessage'))});
      setIsOpen(false);
    }
  }, [isImagePatchSuccessful, isImagePatchUninitialized, setIsOpen, setMessage, t]);

  useEffect(() => {
    if (isImagePatchFailed && !isImagePatchUninitialized) {
      setMessage({error: capitalize(t('form.editFailMessage'))});
      setIsOpen(false);
    }
  }, [isImagePatchFailed, isImagePatchUninitialized, setIsOpen, setMessage, t]);

  return (
    <>
      <Modal
        open={isOpen}
        title={capitalize(t('actions.editItem', {item: t('documents.image', {count: 1})}))}
        onCancel={() => {
          setIsOpen(false);
          editImageForm.resetFields();
        }}
        centered={true}
        destroyOnClose={true}
        footer={null}
      >
        <Form
          form={editImageForm}
          layout={'vertical'}
          onFinish={handleFinish}
          onFinishFailed={errorInfo => console.log('failed: ', errorInfo)}
          initialValues={{
            document_name: image.name,
            description: image.description,
          }}
          autoComplete={'off'}
        >
          <Form.Item
            name={'document_name'}
            label={capitalize(t('documents.imageName', {count: 1}))}
            rules={[
              {
                required: true,
                message: capitalize(t('documents.imageNameRequired')),
                validator: (_, value) => {
                  if (!value || value.trim() === '') {
                    return Promise.reject();
                  }
                  return Promise.resolve();
                },
              },
              ({getFieldValue}) => ({
                validator(_, value) {
                  if (value && value.trim() && SPECIAL_CHARS_TO_ESCAPE.some(char => value.includes(char))) {
                    return Promise.reject(new Error(capitalize(t('documents.noSpecialCharsAllowed'))));
                  }
                  return Promise.resolve();
                },
              }),
              {
                warningOnly: true,
                validator: (_, value) => {
                  if (value && value.trim() && ACCEPTED_FILE_EXTENSIONS.some(ext => value.includes(ext))) {
                    return Promise.reject(new Error(capitalize(t('documents.fileExtensionIsOptional'))));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input placeholder={capitalize(t('documents.imageName', {count: 1}))}/>
          </Form.Item>
          <Form.Item
            name={'description'}
            label={capitalize(t('documents.imageDescription'))}
          >
            <TextArea
              rows={2}
              placeholder={capitalize(t('documents.imageDescription'))}
            />
          </Form.Item>
          {/*<Form.Item name={"document"}
                     rules={[{required: true, message: capitalize(t("documents.imageRequired"))}]}
                     label={capitalize(t("documents.fileUpload"))}>
            <Dragger name={"file"}
                     multiple={false}
                     maxCount={1}
                     accept={"image/*"}
                     beforeUpload={(file) => {
                       if (file.size <= MAX_FILE_SIZE) {
                         setFileIsOk(true);
                       } else {
                         setFileIsOk(false);
                       }
                       if (!addDocumentForm.getFieldValue("document_name")) {
                         addDocumentForm.setFieldValue("document_name", file.name)
                       }
                       return false;
                     }}>
              <p className="ant-upload-drag-icon">
                <UploadOutlined/>
              </p>
              <p className="ant-upload-text">
                {capitalize(t("documents.dragOrSelectImage"))}
              </p>
            </Dragger>
          </Form.Item>*/}
          <Row
            justify={'end'}
            gutter={[24, 24]}
          >
            <Col>
              <Form.Item>
                <Button
                  type={'default'}
                  onClick={() => {
                    setIsOpen(false);
                    editImageForm.resetFields();
                  }}
                >
                  {capitalize(t('form.cancel'))}
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button
                  type={'primary'}
                  loading={isPatchingImage}
                  htmlType={'submit'}
                >
                  {capitalize(t('actions.editItem', {item: t('documents.image', {count: 1})}))}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};