import React, {useEffect} from 'react';
import {useControlsAction} from '../../../context/baseMap/ControlContext';
import {ASSET_MAP_ACTIONS} from '../../common/map/constants/map';
import {useMapDrawerContext} from '../../../context/assetsMap/MapDrawerContext';
import {BaseMapContainer} from '../../common/map/BaseMapContainer';
import {MarkersCluster} from './MarkersCluster';
import {GoogleLayers} from '../../../types/internal/Map';
import {useBaseWMSLayerContext} from '../../../context/baseMap/BaseWMSLayerContext';
import {BaseWMSLayer} from '../../common/map/BaseWMSLayer';
import ResizableDrawer from '../../common/ResizableDrawer';
import {HandleResize} from '../../common/map/handlers/HandleResize';
import AssetsMapDrawControlWrapper from './AssetsMapDrawControlWrapper';
import MapLoading from '../../common/map/MapLoading';

export const MapDrawer = () => {
  const {action: {currentAction, actionEvent}, changeAction} = useControlsAction();
  
  const [isOpen, setIsOpen] = useMapDrawerContext();
  const [layers, currentWMSLayer] = useBaseWMSLayerContext();
  
  const WMSLayer = layers?.find(layer => layer.uuid === currentWMSLayer);
  
  useEffect(() => {
    switch (currentAction) {
      case ASSET_MAP_ACTIONS.OPEN_MAP_DRAWER:
        setIsOpen(true);
        break;
      case ASSET_MAP_ACTIONS.CLOSE_MAP_DRAWER:
        setIsOpen(false);
        break;
      case ASSET_MAP_ACTIONS.SELECT_MARKER:
        setIsOpen(true);
        break;
      default:
        break;
    }
  }, [changeAction, currentAction, actionEvent, setIsOpen]);
  
  return (
    <React.Fragment>
      <ResizableDrawer
        placement={'right'}
        closable={false}
        open={isOpen}
        getContainer={false}
        maskClosable={false}
        destroyOnClose={true}
        mask={false}
        rootStyle={{position: 'fixed', height: 'calc(100vh - 50px)', marginTop: 50}}
        styles={{body: {padding: 0, height: '100%'}}}
      >
        <BaseMapContainer
          withZoom={true}
          withGeoSearch={true}
          withStyle={{height: '100%', borderRadius: 0}}
          withLayerPicker={true}
          withSoftLoading={true}
          withBaseLayers={[GoogleLayers.roadmap, GoogleLayers.hybrid]}
          withWMSLayers={layers || []}
          withLegend={true}
          withPopoverConfiguration={{overlayInnerStyle: {width: 320}}}
          withQuerableLayers={true}
          withAssetFilterFromLayer={true}
          withStreetView={true}
          isStandAlone={true}
        >
          <AssetsMapDrawControlWrapper/>
          <MapLoading/>
          <MarkersCluster/>
          {!!WMSLayer && (
            <BaseWMSLayer
              url={WMSLayer.wms_baseurl}
              layers={WMSLayer.name}
              isInternal={WMSLayer.is_internal || undefined}
              key={WMSLayer.uuid}
            />
          )}
          <HandleResize/>
        </BaseMapContainer>
      </ResizableDrawer>
    </React.Fragment>
  );
};