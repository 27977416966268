import {apiSlice} from './baseApi';
import {GenericPaginatedApi, GenericPaginatedParamsApi} from '../../types/api/paginatedApi';
import {
  Alert,
  AlertGroup,
  AlertGroupPaginated,
  AlertModel,
  CreateAlertParams,
  GetAlertsParams,
  PatchAlertParams,
} from '../../types/api/alerts';
import {DocumentType} from './documents';
import {MarkerOrNull} from '../../types/internal/Map';
import {GetAssets} from '../../types/api/assets';
import {Entity} from './geoshapes';

export interface AlertPolygonGet {
  'uuid': string,
  'data': {
    additional_info?: {
      [key: string]: string,
    },
    color?: string,
    intensity?: {
      text?: string,
      value?: string,
    }
  } | null,
  'entity_uuid': string | null,
  'globalpolygon': string,
  'geographic_area_list': Entity[]
}

const alertsApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAlertsPaginated: build.query<GenericPaginatedApi<Alert>, GetAlertsParams>({
      query: (params) => {
        return {
          url: '/alerts/',
          params,
          paginate: true,
        };
      },
      providesTags: (res, error, arg) =>
        res
          ? [
            ...res?.results.map(({uuid}) => ({type: 'Alerts' as const, id: uuid})),
            {type: 'Alerts', id: 'LIST'},
            'Locale',
          ]
          : [{type: 'Alerts', id: 'LIST'},
            'Locale'],
    }),
    getAlertsForInfiniteScrolling: build.query<GenericPaginatedApi<Alert>, GetAlertsParams>({
      query: (params) => {
        return {
          url: '/alerts/',
          params,
          paginate: true,
        };
      },
      serializeQueryArgs: ({queryArgs}) => {
        const newQueryArgs = {...queryArgs};
        delete newQueryArgs.page;
        return newQueryArgs;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems, query) => {
        if ((query.arg.page || 0) > 1) {
          return {
            ...currentCache,
            ...newItems,
            results: [...currentCache.results, ...newItems.results],
          };
        }
        return newItems;
      },
    }),
    getAlert: build.query<AlertModel, { uuid: string }>({
      query: ({uuid}) => ({
        url: `/alerts/${uuid}/`,
      }),
      providesTags: (result, error, params) => [{type: 'Alerts', id: params.uuid}, 'Locale'],
    }),
    createAlert: build.mutation<Alert, CreateAlertParams>({
      query: (args) => ({
        url: `/alerts/`,
        method: 'POST',
        body: args,
      }),
      invalidatesTags: [{type: 'Alerts', id: 'LIST'}],
    }),
    deleteAlert: build.mutation<Alert, { uuid: string }>({
      query: ({uuid}) => ({
        url: `/alerts/${uuid}/`,
        method: 'DELETE',
      }),
      invalidatesTags: [{type: 'Alerts', id: 'LIST'}],
    }),
    patchEditAlert: build.mutation<Alert, PatchAlertParams>({
      query: ({
                uuid, ...args
              }) => ({
        url: `/alerts/${uuid}/`,
        method: 'PATCH',
        body: args,
      }),
      invalidatesTags: (res, error, arg) => [{type: 'Alerts', id: 'LIST'}, {type: 'Alerts', id: arg.uuid}],
    }),
    
    archiveAlert: build.mutation<Alert, { alertUuid: string }>({
      query: ({alertUuid}) => ({
        url: `/alerts/${alertUuid}/`,
        method: 'PATCH',
        body: {
          is_rejected: true,
          event: null,
        },
      }),
      invalidatesTags: (res, error, arg) => [{type: 'Alerts', id: 'LIST'}, {type: 'Alerts', id: arg.alertUuid}],
    }),
    connectAlertToEvent: build.mutation<Alert, { alertUuid: string, eventUuid: string }>({
      query: ({alertUuid, eventUuid}) => ({
        url: `/alerts/${alertUuid}/`,
        method: 'PATCH',
        body: {
          is_rejected: false,
          event: eventUuid,
        },
      }),
      invalidatesTags: (res, error, arg) => [{type: 'Alerts', id: 'LIST'}, {type: 'Alerts', id: arg.alertUuid}],
    }),
    manageAlert: build.mutation<Alert, { alertUuid: string }>({
      query: ({alertUuid}) => ({
        url: `/alerts/${alertUuid}/`,
        method: 'PATCH',
        body: {
          is_rejected: false,
          event: null,
        },
      }),
      invalidatesTags: (res, error, arg) => [{type: 'Alerts', id: 'LIST'}, {type: 'Alerts', id: arg.alertUuid}],
    }),
    
    getAlertDocuments: build.query<DocumentType[], { uuid: string }>({
      query: ({uuid}) => ({
        url: `/alerts/${uuid}/documents/`,
        params: {
          paginate: false,
        },
      }),
      providesTags: (res, error, arg) =>
        res
          ? [...res.map(({uuid}) => ({type: 'Documents' as const, id: uuid})), 'Documents']
          : ['Documents'],
    }),
    addDocumentToAlert: build.mutation<void, { alertUuid: string, documentUuid: string }>({
      query: ({alertUuid, documentUuid}) => ({
        url: `/alerts/${alertUuid}/add_document/`,
        method: 'PATCH',
        body: {
          document_uuid: documentUuid,
        },
      }),
      invalidatesTags: ['Documents'],
    }),
    removeDocumentToAlert: build.mutation<void, { alertUuid: string, documentUuid: string }>({
      query: ({alertUuid, documentUuid}) => ({
        url: `/alerts/${alertUuid}/remove_document/`,
        method: 'PATCH',
        body: {
          document_uuid: documentUuid,
        },
      }),
      invalidatesTags: ['Documents'],
    }),
    getAlertAssets: build.query<MarkerOrNull[], { uuid: string }>({
      query: ({uuid}) => {
        return {
          url: `/alerts/${uuid}/assets-map/`,
          params: {
            paginate: false,
          },
        };
      },
    }),
    getAlertPolygonsNotPaginated: build.query<AlertPolygonGet[], { uuid: string }>({
      query: ({uuid}) => {
        return {
          url: `/alerts/${uuid}/alert-polygons/`,
          params: {
            paginate: false,
          },
        };
      },
    }),
    getAlertAssetsListPaginated: build.query<GetAssets, GenericPaginatedParamsApi & { uuid: string }>({
      query: ({uuid, ...args}) => {
        return {
          url: `/alerts/${uuid}/assets/`,
          params: args,
        };
      },
    }),
    createEventFromAlert: build.mutation<{ event_uuid: string }, { alertUuid: string }>({
      query: ({alertUuid}) => ({
        url: `/alerts/${alertUuid}/create_event/`,
        method: 'POST',
      }),
      invalidatesTags: [{type: 'Events', id: 'LIST'}],
    }),
    getAlertGroupsPaginated: build.query<AlertGroupPaginated, GenericPaginatedParamsApi>({
      query: (params) => {
        return {
          url: `/alert-groups/`,
          params,
        };
      },
      providesTags: (res, error, arg) =>
        res
          ? [...res.results.map(({uuid}) => ({type: 'AlertGroups' as const, id: uuid})), {
            type: 'AlertGroups',
            id: 'LIST',
          }]
          : [{type: 'AlertGroups', id: 'LIST'}],
    }),
    getAlertGroup: build.query<AlertGroup, { alertGroupUuid: string }>({
      query: ({alertGroupUuid}) => {
        return {
          url: `/alert-groups/${alertGroupUuid}`,
        };
      },
      providesTags: (res, error, arg) => [{type: 'AlertGroups', id: arg.alertGroupUuid}],
    }),
    archiveAlertGroup: build.mutation<AlertGroup, { alertGroupUuid: string }>({
      query: ({alertGroupUuid}) => ({
        url: `/alert-groups/${alertGroupUuid}/archive/`,
        method: 'POST',
      }),
      invalidatesTags: (res, error, arg) => [{type: 'AlertGroups', id: 'LIST'}, {
        type: 'AlertGroups',
        id: arg.alertGroupUuid,
      }],
    }),
    ungroupAlertGroup: build.mutation<AlertGroup, {
      alertGroupUuid: string,
      alertUuid: string,
      paramsOfListToUpdate?: GenericPaginatedParamsApi
    }>({
      query: ({alertGroupUuid, alertUuid}) => ({
        url: `/alert-groups/${alertGroupUuid}/ungroup/`,
        method: 'POST',
        body: {alert: alertUuid},
      }),
      async onQueryStarted(params, {dispatch, queryFulfilled}) {
        try {
          const {data} = await queryFulfilled;
          dispatch(
            alertsApi.util.updateQueryData('getAlertGroupsPaginated', params.paramsOfListToUpdate || {}, (alertGroups) => {
              Object.assign(alertGroups, {
                ...alertGroups,
                results: alertGroups.results.map(el => {
                  if (el.uuid === data.uuid) {
                    return data;
                  }
                  return el;
                }),
              });
            }),
          );
          
        } catch {
          console.error('error ungroup alert');
        }
      },
    }),
    createEventFromAlertGroup: build.mutation<AlertGroup, { alertGroupUuid: string }>({
      query: ({alertGroupUuid}) => ({
        url: `/alert-groups/${alertGroupUuid}/create_event/`,
        method: 'POST',
      }),
    }),
    addAlertGroupToEvent: build.mutation<AlertGroup, { alertGroupUuid: string, eventUuid: string }>({
      query: ({alertGroupUuid, eventUuid}) => ({
        url: `/alert-groups/${alertGroupUuid}/add_to_event/`,
        method: 'POST',
        body: {event: eventUuid},
      }),
    }),
    updateShakemap: build.mutation<{ msg: string }, { alert_uuid: string, send_communication?: boolean }>({
      query: ({alert_uuid, send_communication = false}) => ({
        url: `/alerts/${alert_uuid}/update_shakemap/`,
        body: {send_communication: send_communication},
        method: 'POST',
      }),
      invalidatesTags: (res, error, arg) => [{type: 'Alerts', id: 'LIST'}, {type: 'Alerts', id: arg.alert_uuid}],
    }),
  }),
});

export const {
  useGetAlertsPaginatedQuery,
  useLazyGetAlertsPaginatedQuery,
  useLazyGetAlertsForInfiniteScrollingQuery,
  useCreateAlertMutation,
  useLazyGetAlertDocumentsQuery,
  usePatchEditAlertMutation,
  useArchiveAlertMutation,
  useManageAlertMutation,
  useConnectAlertToEventMutation,
  useDeleteAlertMutation,
  useAddDocumentToAlertMutation,
  useRemoveDocumentToAlertMutation,
  useLazyGetAlertQuery,
  useGetAlertQuery,
  useLazyGetAlertAssetsQuery,
  useLazyGetAlertAssetsListPaginatedQuery,
  useLazyGetAlertPolygonsNotPaginatedQuery,
  useGetAlertPolygonsNotPaginatedQuery,
  useCreateEventFromAlertMutation,
  useGetAlertGroupsPaginatedQuery,
  useLazyGetAlertGroupQuery,
  useArchiveAlertGroupMutation,
  useUngroupAlertGroupMutation,
  useCreateEventFromAlertGroupMutation,
  useAddAlertGroupToEventMutation,
  useUpdateShakemapMutation,
} = alertsApi;