import {Checkbox, Input, Tag, Typography} from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useEffect, useState} from 'react';
import _, {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import {useGetAssetsOptionsQuery} from '../../../../../redux/api/assets';
import {OptionLabelValue} from '../../../../../types/internal/OptionLabelValue';
import {getTranslationAssetTableColumnKey} from '../../../../../utils/translation';
import {CustomLoading} from '../../../../../components/common/CustomLoading';

export const ImportReportsCountyFilter = (props: {
  activeFilters: OptionLabelValue[] | null,
  removeCountry: (countryToRemove: string) => void,
  addCountry: (countryToAdd: OptionLabelValue) => void,
}) => {

  const {t} = useTranslation();

  const {
    data: options,
    isLoading,
    isUninitialized,
    isFetching,
  } = useGetAssetsOptionsQuery();

  const isLoadingOptions = isLoading || isUninitialized || isFetching;
  const [allTheOptions, setAllTheOptions] = useState<OptionLabelValue[]>([]);
  const [currentOptions, setCurrentOptions] = useState<OptionLabelValue[]>([]);

  useEffect(() => {
    const countries = options?.country || [];
    setAllTheOptions(countries);
    setCurrentOptions(countries.slice(0, 30));
  }, [options?.country]);

  const onSearch = (value: string) => {
    const countries = options?.country || [];
    const filteredCountries = countries.filter(el => el.label.toLowerCase().includes(value.toLowerCase()));
    setAllTheOptions(filteredCountries);
    setCurrentOptions(filteredCountries.slice(0, 30));
  };

  const debouncedSearch = _.debounce(onSearch, 400);

  const placeholder = capitalize(t('table.filterPlaceholder', {column: t(getTranslationAssetTableColumnKey('country'))}));

  return <>

    <Input.Search
      placeholder={placeholder}
      onSearch={onSearch}
      onChange={(e) => debouncedSearch(e.target.value)}
    />
    <div style={{display: 'flex', flexWrap: 'wrap', width: '15rem', gap: '0.5rem', padding: '0.5rem 0'}}>
      {props.activeFilters?.map((country) => (
        <Tag
          style={{margin: 0}}
          color={'processing'}
          key={country.value}
          closable
          onClose={() =>
            props.removeCountry(country.value)
          }
        >
          {country.label}
        </Tag>
      ))}
    </div>
    <InfiniteScroll
      dataLength={currentOptions.length} //This is important field to render the next data
      next={() => {
        setCurrentOptions(prevState => [...prevState, ...allTheOptions.slice(currentOptions.length, currentOptions.length + 50)]);
      }}
      hasMore={currentOptions.length < allTheOptions?.length}
      loader={null}
      scrollableTarget={'scrollableDiv'}
      height={'10rem'}
    >

      {currentOptions.map((opt, idx) => (
        <div key={opt.value}>
          <Checkbox
            checked={props.activeFilters?.some((el => el.value === opt.value))}
            onChange={event => {
              if (event.target.checked) {
                props.addCountry(opt);
              } else {
                props.removeCountry(opt.value);
              }
            }}
          >
            {opt.label}
          </Checkbox>
        </div>))
      }
      <div style={{display: 'flex', justifyContent: 'center', margin: '0.5rem 0'}}>
        {isLoadingOptions && <CustomLoading/>}
        {!isLoadingOptions && !currentOptions.length &&
          <Typography.Text>{capitalize(t('form.noResults'))}</Typography.Text>}
      </div>
    </InfiniteScroll>
  </>;

};