import {useTranslation} from 'react-i18next';
import React, {useEffect, useState} from 'react';
import {useLazyGetAssetImagesQuery} from '../../redux/api/documents';
import {List} from 'antd';
import {CustomLoading} from '../common/CustomLoading';
import {ImageListItem} from './images/ImageListItem';
import {AssetModel} from '../../types/internal/AssetModel';
import {useGetUserProfileQuery} from '../../redux/api/locales';
import {isAssetImageDelete, isAssetImageEdit, isAssetImageView} from '../Authentication/permissions';

export const AssetsImagesListInAlert = ({asset}: {
  asset: AssetModel,
}) => {
  const {t} = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(10);

  const [
    getImages,
    {
      isLoading: isAssetImagesLoading,
      isFetching: isAssetImagesFetching,
      data: assetsImages,
    },
  ] = useLazyGetAssetImagesQuery();

  const {
    data: userData,
  } = useGetUserProfileQuery();

  const isEditing = userData?.type ? isAssetImageEdit(userData?.type) : false;
  const isDelete = userData?.type ? isAssetImageDelete(userData?.type) : false;
  const isDownload = userData?.type ? isAssetImageView(userData?.type) : false;

  useEffect(() => {
    if (asset.uuid) {
      getImages({uuid: asset.uuid, page: currentPage, page_size: currentPageSize});
    }
  }, [currentPage, getImages, currentPageSize, asset.uuid]);

  return <>
    <List
      dataSource={assetsImages?.results}
      header={null}
      footer={null}
      loading={
        {
          spinning: isAssetImagesLoading || isAssetImagesFetching,
          indicator: <CustomLoading/>,
        }
      }
      pagination={{
        total: assetsImages?.count || 0,
        showSizeChanger: true,
        current: currentPage,
        onChange: page => setCurrentPage(page),
        onShowSizeChange: (current, pagesize) => {
          setCurrentPage(current);
          setCurrentPageSize(pagesize);
        },
        showTotal: (total, range) => t('table.pageSizeOfTotal', {
          rangeStart: range[0],
          rangeEnd: range[1],
          total,
        }),
        position: 'bottom',
        align: 'center',
      }}
      grid={{
        gutter: 16, xs: 1,
        sm: 2,
        md: 3,
        lg: 4,
        xl: 4,
        xxl: 4,
      }}
      renderItem={(item) => (
        <List.Item>
          <ImageListItem
            imageItem={item}
            withDownload={isDownload}
            withEdit={isEditing}
            withDelete={isDelete}
            assetId={asset.uuid}
          />
        </List.Item>
      )}
    />
  </>;
};