import {useTranslation} from 'react-i18next';
import React, {useEffect, useState} from 'react';
import {Button} from 'antd';
import {capitalize} from 'lodash';
import {EditOutlined} from '@ant-design/icons';
import EventTypeDrawer from './EventTypeDrawer';
import {useEditEventTypeMutation, useLazyGetEventTypeTranslationQuery} from '../../../redux/api/eventTypes';
import {useParams} from 'react-router-dom';
import {useMessageContext} from '../../../context/message/MessageContext';
import {Icon} from '../../../types/api/icons';

export default function EditEventType({color, icon}: { color: string, icon: Icon | null }) {
  const {t} = useTranslation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [getTranslations, {
    data: translations,
    isError: isFetchingDataError,
  }] = useLazyGetEventTypeTranslationQuery();
  const {uuid} = useParams();
  const setMessage = useMessageContext();
  const [editEvent, {
    isSuccess: isSuccessEdit,
    isError: isErrorEdit,
  }] = useEditEventTypeMutation();

  const [values, setValues] = useState<{
    'color_code': string,
    'icon': Icon | null,
    'name_en': string,
    'name_it': string,
  } | null>(null);

  useEffect(() => {
    if (uuid) {
      getTranslations({uuid});
    }
  }, [getTranslations, uuid]);

  useEffect(() => {
    if (translations) {
      setValues({color_code: color, icon, name_en: translations.name_en, name_it: translations.name_it});
    }
  }, [color, icon, translations]);

  useEffect(() => {
    if (isFetchingDataError) {
      setMessage({error: capitalize(t('form.fetchingFailMessage'))});
    }
  }, [isFetchingDataError, setMessage, t]);

  useEffect(() => {
    if (isSuccessEdit) {
      setMessage({success: capitalize(t('form.editSuccessMessage'))});
      setIsDrawerOpen(false);
    }
  }, [isSuccessEdit, setMessage, t]);

  useEffect(() => {
    if (isErrorEdit) {
      setMessage({error: capitalize(t('form.editFailMessage'))});
    }
  }, [isErrorEdit, setMessage, t]);

  return <>
    <Button
      icon={<EditOutlined/>}
      type={'text'}
      size={'large'}
      onClick={() => setIsDrawerOpen(true)}
    />

    {values && <EventTypeDrawer
      isDrawerOpen={isDrawerOpen}
      setIsDrawerOpen={setIsDrawerOpen}
      headerTitle={t('actions.editItem', {item: t('events.eventType', {count: 1})})}
      confirmButton={{
        children: capitalize(t('actions.saveChanges')),
      }}
      onFinish={(values) => {
        if (uuid) {
          editEvent({...values, uuid});
        }
      }}
      values={values}
    />}
  </>;
}