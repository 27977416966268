import {Col, Divider, Row, Skeleton, Space, Typography} from 'antd';
import {useAssetMapDetailsContext} from '../../../../context/assetsMap/AssetMapDetailsContext';
import {useEffect} from 'react';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import {HomeOutlined, MailOutlined} from '@ant-design/icons';
import {useGetAssetsOptionsQuery} from '../../../../redux/api/assets';
import {VALUE_NOT_PRESENT} from "../../../../utils/translation";

const {Text, Title} = Typography;
export const AssetDetail = (props: { markerUuid: string }) => {

  const {t} = useTranslation();
  const {details, getDetails, loading: loadingAssetDetails} = useAssetMapDetailsContext();

  const {data: assetOptions, isLoading: loadingAssetOptions} = useGetAssetsOptionsQuery();

  useEffect(() => {
    if (props.markerUuid && !details.uuid) {
      getDetails({uuid: props.markerUuid});
    }
  }, [details.uuid, getDetails, props.markerUuid]);

  return (
    <>
      {(loadingAssetDetails || loadingAssetOptions) && <Skeleton active={true}/>}
      {!(loadingAssetDetails || loadingAssetOptions) && (
        <>
          <Row>
            <Col>
              <Title level={4} style={{marginTop: 0}}>
                {(details.sigi_code || '').toUpperCase()}
              </Title>
            </Col>
          </Row>
          <Row>
            <Col>
              <Text>
                {[
                  details.address ? capitalize(details.address.toLowerCase()) : null,
                  details.admin_level_3 ? capitalize(details.admin_level_3.toLowerCase()) : null,
                  details.admin_level_1 ? capitalize(details.admin_level_1.toLowerCase()) : null,
                ].filter(string => string !== null)
                  .join(', ')}
              </Text>
            </Col>
          </Row>
          <Divider/>
          <Row align={'middle'}>
            <Col>
              <Space
                size={'small'}
                wrap={false}
                direction={'horizontal'}
              >
                <HomeOutlined/>
                <Title level={5} style={{marginTop: 0}}>
                  {capitalize(t('misc.buildingDetails'))}
                </Title>
              </Space>
            </Col>
          </Row>
          {details.master_asset && <Row style={{margin: '8px 0'}}>
            <Col span={18}>
              <Text strong={true}>
                {`${t('assets.masterAssetCode')}:`}
              </Text>
            </Col>
            <Col span={6}>
              <Text>
                {details.master_asset}
              </Text>
            </Col>
          </Row>}
          <Row style={{margin: '8px 0'}}>
            <Col span={18}>
              <Text strong={true}>
                {`${t('assets.totalResources')}:`}
              </Text>
            </Col>
            <Col span={6}>
              <Text>
                {`${details.total_resources ? `${details.total_resources}` : VALUE_NOT_PRESENT}`}
              </Text>
            </Col>
          </Row>
          <Row style={{margin: '8px 0'}}>
            <Col span={18}>
              <Text strong={true}>
                {`${t('assets.surfaceTotal')}:`}
              </Text>
            </Col>
            <Col span={6}>
              <Text>
                {`${details.surface_total ? `${details.surface_total}` : VALUE_NOT_PRESENT}`}
              </Text>
            </Col>
          </Row>
          <Row style={{margin: '8px 0'}}>
            <Col span={18}>
              <Text strong={true}>
                {`${t('assets.surfaceBasement')}:`}
              </Text>
            </Col>
            <Col span={6}>
              <Text>
                {`${details.surface_basement ? `${details.surface_basement}` : VALUE_NOT_PRESENT}`}
              </Text>
            </Col>
          </Row>
          <Row style={{margin: '8px 0'}}>
            <Col span={18}>
              <Text strong={true}>
                {`${capitalize(t('assets.ownership'))}: `}
              </Text>
            </Col>
            <Col span={6}>
              <Text>
                {`${capitalize(assetOptions?.ownership.find(option => option.value === details.ownership)?.label)}`}
              </Text>
            </Col>
          </Row>
          {details.contact_people.length > 0 && (
            <>
              <Divider/>
              <Row>
                <Col>
                  <Space
                    size={'small'}
                    wrap={false}
                    direction={'horizontal'}
                  >
                    <MailOutlined/>
                    <Title level={5} style={{marginTop: 0}}>
                      {capitalize(t('contacts.contact', {count: 1}))}
                    </Title>
                  </Space>
                </Col>
              </Row>
              <Row style={{margin: '8px 0'}}>
                <Col>
                  <Text>
                    {details.contact_people.map(person => person.email).join(',')}
                  </Text>
                </Col>
              </Row>
            </>
          )}
        </>
      )}
    </>
  );
};