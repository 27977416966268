import {createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState} from 'react';

export type MapSoftLoadingContextType = [
  isLoading: boolean,
  setIsLoading: Dispatch<SetStateAction<boolean>>
]

const SoftLoadingContext = createContext<MapSoftLoadingContextType>([
  false,
  () => null,
]);

export const useMapSoftLoadingContext = () => useContext(SoftLoadingContext);

export const MapSoftLoadingContext = (props: PropsWithChildren) => {
  const [isLoading, setIsLoading] = useState(false);
  
  return (
    <SoftLoadingContext.Provider value={[isLoading, setIsLoading]}>
      {props.children}
    </SoftLoadingContext.Provider>
  );
};