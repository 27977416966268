import {useDispatch, useSelector} from 'react-redux';
import ResetFiltersSortersButton from '../../../common/filters/ResetFiltersSortersButton';
import {resetTemplateEmailFiltersAndSorters, selectTemplateMail} from '../../../../redux/slices/templateMails';

export default function ResetTemplateMailFiltersSorters() {
  const dispatch = useDispatch();
  const filters = useSelector(selectTemplateMail).filters;
  const sorters = useSelector(selectTemplateMail).sorters;
  const handleConfirm = () => dispatch(resetTemplateEmailFiltersAndSorters());
  const isDisabled = Object.values(filters).every(el => el === null || !el.length) && Object.values(sorters).every(el => el === null || !el.length);

  return <ResetFiltersSortersButton
    isDisabled={isDisabled}
    onConfirm={handleConfirm}
  />;
}