import {OptionLabelValue} from '../../../../types/internal/OptionLabelValue';
import {useMemo} from 'react';
import StandardFilter from '../../../../components/common/filters/StandardFilter';
import {useGetOutcomeChoicesQuery} from '../../../../redux/api/alertLogs';

export const OutcomeFilter = ({activeFilters, handleFilterChange}: {
  activeFilters: OptionLabelValue[],
  handleFilterChange: (values: OptionLabelValue[]) => void,
}) => {
  
  const {data: choices} = useGetOutcomeChoicesQuery();
  
  const options = useMemo(() => choices?.map(el => ({
    label: el.text,
    value: el.label,
  })), [choices]);
  
  return (
    <>
      {options && (
        <StandardFilter
          filters={options}
          onChange={handleFilterChange}
          selectedFilters={activeFilters}
        />
      )}
    </>
  
  );
};