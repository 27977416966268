import {Card, Col, Row, Skeleton} from 'antd';
import {RealEstateHubSearchSelect} from '../common/searchSelect/RealEstateHubSearchSelect';
import {ContactPeopleSearchSelect} from '../common/searchSelect/ContactPeopleSearchSelect';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
import {useEditAssetRealEstateContext} from '../../context/editAsset/EditAssetRealEstateFormContext';
import {useEditFormContext} from '../../context/editAsset/EditAssetFormContext';
import {OptionLabelValue} from '../../types/internal/OptionLabelValue';
import {useParams} from 'react-router-dom';
import {useAssetDetails} from '../../context/assetDetails/AssetDetails';
import {useEffect} from 'react';

export const EditAssetRealEstateFormCard = () => {
  const {t} = useTranslation();
  const {assetId} = useParams();
  
  const [setAssetUuid] = useAssetDetails();
  const [realestateDetails, setRealestateDetails] = useEditAssetRealEstateContext();
  
  const {isUserCreated, isLoading} = useEditFormContext();
  
  useEffect(() => {
    if (assetId) {
      setAssetUuid(assetId);
    }
  }, [assetId, setAssetUuid]);
  
  return (
    <Card title={capitalize(t('assets.realEstateData'))}>
      {isLoading && <Skeleton active={true}/>}
      {!isLoading && (
        <>
          <Row gutter={[40, 0]}>
            <Col xs={24} lg={12}>
              <RealEstateHubSearchSelect
                value={realestateDetails.realestate_hub}
                disabled={!isUserCreated}
                onClear={() => {
                  setRealestateDetails(details => ({
                    ...details,
                    realestate_hub: null,
                  }));
                }}
                onSelect={(value, option) => {
                  setRealestateDetails(details => ({
                    ...details,
                    realestate_hub: option,
                  }));
                }}
              />
            </Col>
          </Row>
          <Row gutter={[40, 0]}>
            <Col xs={24} lg={12}>
              <ContactPeopleSearchSelect
                values={realestateDetails.contact_people}
                disabled={!isUserCreated}
                onClear={() => {
                  setRealestateDetails(details => ({
                    ...details,
                    contact_people: [],
                  }));
                }
                }
                onChange={(options, option) => {
                  setRealestateDetails(details => ({
                    ...details,
                    contact_people: options as OptionLabelValue[],
                  }));
                }}
                afterCreation={(contactToAdd) => {
                  setRealestateDetails(details => ({
                    ...details,
                    contact_people: [
                      ...realestateDetails.contact_people,
                      {
                        label: contactToAdd.full_name,
                        value: contactToAdd.uuid,
                      },
                    ],
                  }));
                }}
              />
            </Col>
          </Row>
        </>
      )}
    </Card>
  );
};