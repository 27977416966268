import AlertsToAssociateToEventButton from './AlertsToAssociateToEventButton';
import {useState} from 'react';
import {Drawer} from 'antd';
import AlertsToAssociateToEventTable from './AlertsToAssociateToEventTable';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import {useEventContext} from '../../../../context/event/EventContext';

export default function AlertsToAssociateToEvent() {

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const {t} = useTranslation();
  const {event} = useEventContext();

  return <>
    {!!event && <Drawer
      open={isDrawerOpen}
      onClose={() => setIsDrawerOpen(false)}
      footer={false}
      title={capitalize(t('events.alertsToConnect'))}
      width={'100%'}
    >
      <AlertsToAssociateToEventTable event={event}/>
    </Drawer>}
    <AlertsToAssociateToEventButton
      onClick={() => setIsDrawerOpen(true)}
    />
  </>;
}