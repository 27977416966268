import PermissionRoute from "../../Authentication/PermissionRoute";
import {isAssignAlertToEvent} from "../../Authentication/permissions";
import {Button, Modal, ModalProps} from "antd";
import React, {useState} from "react";
import {capitalize} from "lodash";
import ConnectAlertsToEvent from "../ConnectAlertsToEvent";
import {useTranslation} from "react-i18next";
import {AlertGroup} from "../../../types/api/alerts";

export default function AddAlertGroupToEvent({alertGroup, onFinish}: { alertGroup: AlertGroup, onFinish?: () => void }) {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const {t} = useTranslation()

  const aggregateModalProps: ModalProps = {
    styles: {
      content: {padding: '0 1.5rem'},
      header: {padding: '1rem 0', margin: 0, borderBottom: '1px solid #F0F0F0'},
      body: {display: 'flex', flexDirection: 'column', gap: '2rem', padding: '2rem 0'},
    },
    closeIcon: false,
    width: '70%',
    title: capitalize(t('alerts.connectToEvent')),
    children: <ConnectAlertsToEvent
      evenTypeUuid={alertGroup.event_type}
      alertGroup={alertGroup.uuid}
      onFinish={() => {
        setIsModalOpen(false);
        onFinish && onFinish()
      }}
    />,
  };

  return <PermissionRoute
    isAuthorized={isAssignAlertToEvent}
  >
    <Modal
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      centered
      closeIcon={false}
      footer={false}
      destroyOnClose
      {...aggregateModalProps}
    />
    <Button
      danger
      onClick={() => setIsModalOpen(true)}
    >
      {capitalize(t('alerts.connectToEvent'))}
    </Button>

  </PermissionRoute>;
}