import {NumericFormatProps} from 'react-number-format';
import CustomNumericFormat from './CustomNumericFormat';

export default function CustomLongitudeNumericFormat(numericProps: NumericFormatProps) {
  return <CustomNumericFormat
    decimalScale={6}
    fixedDecimalScale={true}
    isAllowed={(values) => {
      const {floatValue} = values;
      return floatValue === undefined || (floatValue >= -180 && floatValue <= 180);
    }}
    {...numericProps}
  />;
}
