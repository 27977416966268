import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import {useForm} from 'antd/lib/form/Form';
import EpicenterModal from './EpicenterModal';
import {ButtonProps, FormProps, ModalProps} from 'antd';
import {useEventContext} from "../../../context/event/EventContext";
import {usePatchEventMutation} from "../../../redux/api/events";
import {useState} from "react";
import {useMessageContext} from "../../../context/message/MessageContext";

export default function InsertEpicenter({modalProps, formProps, modalButton, submitButton}: {
  modalProps?: ModalProps,
  formProps?: FormProps,
  modalButton?: ButtonProps,
  submitButton?: ButtonProps
}) {
  const {t} = useTranslation();
  const [form] = useForm();
  const {event} = useEventContext()
  const [patchEvent] = usePatchEventMutation()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const setMessage = useMessageContext()

  const handleInsertEpicenter = ({lat, lng}:{lat: number, lng: number}) => {
    if (event?.uuid) {
      patchEvent({epicenter: {lat, lng}, uuid: event?.uuid})
        .then(() => {
          setMessage({
            success: capitalize(t('form.creationSuccessMessage')),
          })
        })
        .catch(() => {
          setMessage({
            error: capitalize(t('form.creationFailMessage')),
          })
        })
    }
  }

  return <EpicenterModal
    modalProps={{
      title: capitalize(t('actions.insertItem', {item: t('events.epicenter')})),
      open: isModalOpen,
      onCancel: () => setIsModalOpen(false),
      ...modalProps,
    }}
    formProps={{
      form: form,
      onFinish: values => handleInsertEpicenter(values),
      ...formProps,
    }}
    modalButton={{
      children: capitalize(t('actions.insertItem', {item: t('events.epicenter')})),
      onClick: () => setIsModalOpen(true),
      ...modalButton,
    }}
    submitButton={{
      children: capitalize(t('actions.insertItem', {item: t('events.epicenter')})),
      ...submitButton,
    }}
  />;
}