import React, {Dispatch, SetStateAction, useCallback, useEffect, useRef} from "react";
import {useMapLoadingContext} from "../../../context/baseMap/MapLoadingContext";
import {useControlsAction} from "../../../context/baseMap/ControlContext";
import {Feature, multiPolygon, MultiPolygon} from "@turf/helpers";
import L from "leaflet";
import NewDrawControls, {CutGeometries} from "../../common/map/controls/newDrawControls/NewDrawControls";
import {ASSET_MAP_ACTIONS} from "../../common/map/constants/map";
import {DrawableGeometryExtend, useAlertStepsContext} from "../../../context/alertSteps/AlertStepsContext";
import {useMap} from "react-leaflet";
import {useAlertCenterMap} from "../../../customHooks/useCenterMap";
import {capitalize} from "lodash";
import {useTranslation} from "react-i18next";

export default function AlertStepDrawControlWrapper() {

  const [, setLoading] = useMapLoadingContext()
  const {drawCount, setDrawCount, drawnGeometries, setDrawnGeometries} = useAlertStepsContext()

  const drawCountRef = useRef<number>(0)
  const setDrawCountRef = useRef<Dispatch<SetStateAction<number>>>(() => 0)
  const drawnGeometriesRef = useRef<DrawableGeometryExtend[]>([])
  const setDrawnGeometriesRef = useRef<Dispatch<SetStateAction<DrawableGeometryExtend[]>>>(() => [])
  useEffect(() => {
    drawCountRef.current = drawCount
  }, [drawCount]);
  useEffect(() => {
    setDrawCountRef.current = setDrawCount
  }, [setDrawCount]);
  useEffect(() => {
    drawnGeometriesRef.current = drawnGeometries
  }, [drawnGeometries]);
  useEffect(() => {
    setDrawnGeometriesRef.current = setDrawnGeometries
  }, [setDrawnGeometries]);

  const [isLoading] = useMapLoadingContext()
  const map = useMap();
  useAlertCenterMap({map, drawnGeometries, isLoading})

  const {action: {currentAction, actionEvent}, changeAction} = useControlsAction();
  const {t} = useTranslation()

  const shapeCreationHandler = useCallback((createdFeature: Feature<MultiPolygon, {}>, map: L.Map) => {
    return new Promise<void>((onResolve, onReject) => {
      setDrawnGeometriesRef.current(prevState => {
          const updatedCounter = drawCountRef.current + 1
          drawCountRef.current = updatedCounter

          return [
            ...prevState,
            {
              uuid: (updatedCounter).toString(),
              coordinates: createdFeature.geometry.coordinates,
              drawCount: updatedCounter,
              name: capitalize(t('map.manualPolygon'))
            }
          ]
        }
      )
      onResolve()
    });
  }, []);

  const shapeDeletionHandler = useCallback((uuid: string, map: L.Map) => {
    return new Promise<void>((onResolve, onReject) => {
      setDrawnGeometriesRef.current(prevState => prevState.filter(el => el.uuid !== uuid))
      onResolve()
    });
  }, []);

  const shapeCutHandler = useCallback((cutterGeometry: Feature<MultiPolygon, {}>, affectedGeometries: CutGeometries[], map: L.Map) => {
    return new Promise<void>((onResolve, onReject) => {
      setDrawnGeometriesRef.current(prevState => {
        let updatedState = prevState.filter(current => !affectedGeometries.some(affected => current.uuid === affected.uuid && !affected.coordinates))
        updatedState = updatedState.map(current => {
          const foundAffected = affectedGeometries.find(el => el.uuid === current.uuid)
          if (foundAffected) {
            const updatedCounter = drawCountRef.current + 1
            drawCountRef.current = updatedCounter
            return {
              uuid: (updatedCounter).toString(),
              coordinates: foundAffected.coordinates,
              drawCount: updatedCounter,
              name: capitalize(t('map.manualPolygon'))
            } as DrawableGeometryExtend
          } else {
            return current
          }
        })
        return updatedState
      })
      onResolve()
    });
  }, []);

  const shapeEditHandler = useCallback((editedFeature: Feature<MultiPolygon, {}>, uuid: string, map: L.Map) => {
    return new Promise<void>((onResolve, onReject) => {
      setDrawnGeometriesRef.current(prevState => {

          const updatedState = prevState.filter(el => el.uuid !== uuid)
          const updatedCounter = drawCountRef.current + 1
          drawCountRef.current = updatedCounter

          return [
            {
              uuid: updatedCounter.toString(),
              coordinates: editedFeature.geometry.coordinates,
              drawCount: updatedCounter,
              name: capitalize(t('map.manualPolygon'))
            },
            ...updatedState
          ]
        }
      )
      onResolve()
    });
  }, []);


  useEffect(() => {
    if (currentAction === ASSET_MAP_ACTIONS.ADD_GEOMETRY_FROM_LAYER_QUERY) {
      changeAction(ASSET_MAP_ACTIONS.PAN);
      shapeCreationHandler(multiPolygon(actionEvent.geometry), actionEvent.map)
        .finally(() => setLoading(false));
    }
  }, [actionEvent, changeAction, currentAction, setLoading, shapeCreationHandler]);

  return (
    <NewDrawControls
      drawnGeometries={drawnGeometries}
      onAfterShapeCreation={shapeCreationHandler}
      onPolygonsDelete={shapeDeletionHandler}
      onAfterShapeCut={shapeCutHandler}
      onSendToBack={(polygonUuid) => console.log(polygonUuid, 'back')}
      onBringToFront={(polygonUuid) => console.log(polygonUuid, 'front')}
      canEditShapes
      onAfterShapeEdit={shapeEditHandler}
    />
  );
}