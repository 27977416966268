import {Button, ButtonProps} from 'antd';
import {DeleteOutlined} from '@ant-design/icons';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import DeleteModalMessage from '../../common/documents/modals/DeleteModalMessage';
import {capitalize} from 'lodash';
import {useDeleteLayerMutation} from '../../../redux/api/layers';

export default function DeleteLayer({uuid, button}: { uuid: string, button?: ButtonProps }) {

  const [isModalVisible, setIsModalVisible] = useState(false);
  const {t} = useTranslation();
  const [deleteLayer] = useDeleteLayerMutation();

  return <>
    <DeleteModalMessage
      title={{
        children: capitalize(t('actions.deleteItem', {item: t('layers.layer', {count: 1})})),
      }}
      paragraph={{
        children: t('layers.deleteLayer'),
      }}
      modal={{
        open: isModalVisible,
        onCancel: () => setIsModalVisible(false),
      }}
      confirmButton={{
        onClick: () => deleteLayer({uuid}),
      }}
    />
    <Button
      size={'large'}
      type={'text'}
      icon={<DeleteOutlined/>}
      onClick={() => setIsModalVisible(true)}
      {...button} />
  </>;
}