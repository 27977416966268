import {MailOutlined} from "@ant-design/icons";
import {capitalize} from "lodash";
import CreateButton from "../../common/buttons/CreateButton";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import CommunicationCreateDrawer from "./CommunicationCreateDrawer";
import {useEventContext} from "../../../context/event/EventContext";
import {
  useCreateCommunicationWithTextEditorMutation,
  useCreateCommunicationWithUploadMutation
} from "../../../redux/api/events";
import {useMessageContext} from "../../../context/message/MessageContext";
import {CommunicationStatus, CreateCommunicationWithTextEditor} from "../../../types/api/communications";
import dayjs from "dayjs";
import {RcFile} from "antd/lib/upload";
import {isEventCommunicationCreation} from "../../Authentication/permissions";
import PermissionRoute from "../../Authentication/PermissionRoute";

export default function ClusterCommunicationCreate({cluster}: { cluster: string }) {

  const {t} = useTranslation()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const {event} = useEventContext()
  const [createCommunicationWithTextEditor, {isLoading: isLoadingCreateCommunicationWithTextEditor}] = useCreateCommunicationWithTextEditorMutation()
  const [createCommunicationWithUpload, {isLoading: isLoadingCreateCommunicationWithUpload}] = useCreateCommunicationWithUploadMutation()
  const setMessage = useMessageContext()
  const handleCreationWithTextEditor = async (values: Pick<CreateCommunicationWithTextEditor, 'body' | 'subject' | 'tags' | 'status'>) => {
    const {body, subject, tags, status} = values
    if (event?.uuid) {
      try {
        await createCommunicationWithTextEditor({
          cluster_templates: [cluster],
          body,
          datetime: dayjs().toISOString(),
          event: event.uuid,
          subject,
          status,
          tags
        }).unwrap()
        setMessage({success: capitalize(t('form.creationSuccessMessage'))})
        setIsDrawerOpen(false)
      } catch {
        setMessage({error: capitalize(t('form.creationFailMessage'))})
      }
    }
  }

  const handleCreationWithUpload = async (values: {
    // subject: string,
    tags?: string[],
    // datetime: string,
    email_file: RcFile,
    status: CommunicationStatus,
  }) => {
    if (event?.uuid) {
      try {
        const request = {
          cluster_templates: [cluster],
          // datetime: values.datetime,
          email_file: values.email_file,
          event: event.uuid,
          // subject: values.subject,
          status: values.status,
          tags: values.tags,
        }
        await createCommunicationWithUpload(request).unwrap()
        setMessage({success: capitalize(t('form.creationSuccessMessage'))})
        setIsDrawerOpen(false)
      } catch {
        setMessage({error: capitalize(t('form.creationFailMessage'))})
      }
    }
  }

  return <PermissionRoute isAuthorized={isEventCommunicationCreation}>
    <CommunicationCreateDrawer
      type={'cluster'}
      disabled={isLoadingCreateCommunicationWithTextEditor || isLoadingCreateCommunicationWithUpload}
      onCreationWithUpload={handleCreationWithUpload}
      onCreationWithTextEditor={handleCreationWithTextEditor}
      drawer={{
        open: isDrawerOpen,
        onClose: () => setIsDrawerOpen(false)
      }}
    />
    <CreateButton
      onClick={() => setIsDrawerOpen(true)}
      style={{width: '7rem'}}
      icon={<MailOutlined/>}>{capitalize(t('actions.create'))}</CreateButton>
  </PermissionRoute>
}