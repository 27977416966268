import {Card, Col, Row} from 'antd';
import {useEventContext} from '../../../context/event/EventContext';
import EditEventModal from "../edit/EditEventModal";
import EventRecapValues from "../edit/EventRecapValues";

export default function EventCreationEditCard() {

  const {event} = useEventContext();

  return <Card>
    {event && <EventRecapValues event={event}/>}
    <Row justify={'end'}>
      <Col>
        <EditEventModal />
      </Col>
    </Row>
  </Card>;
}