import {Button, ButtonProps, Col, DatePickerProps, Form, Modal, ModalProps, Row, TimePicker, Typography} from "antd";
import {EditOutlined} from "@ant-design/icons";
import  {Dayjs, isDayjs} from "dayjs";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {capitalize} from "lodash";
import AntFormItem from "../../common/AntFormItem";
import {LocalizedDatePicker} from "../../common/LocalizedDatePicker";

const {Text} = Typography;
export default function EditDateModal({dateDatePicker, timeDatePicker, onFinish, description, modal, modalButton}: {
  onFinish: (date: string) => void,
  modal?: ModalProps,
  modalButton?: ButtonProps,
  description?: string,
  dateDatePicker?: DatePickerProps,
  timeDatePicker?: DatePickerProps,
}) {

  const {t} = useTranslation();

  const [
    isModalOpen,
    setIsModalOpen,
  ] = useState(false);

  const [form] = Form.useForm()

  const handleFinish = ({date, time}: { date: Dayjs, time?: Dayjs }) => {
    let value = date.startOf('day')
    if (time) {
      value = value.set('hour', time.get('hour')).set('minute', time.get('minute')).set('second', time.get('second'))
    }
    setIsModalOpen(false)
    onFinish(value.toISOString())
  }


  return <>
    <Modal
      centered={true}
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      footer={<Row justify={'end'}>
        <Form form={form} onFinish={handleFinish}>
          <Form.Item required>
            <Button type={'primary'} htmlType={'submit'}>
              {capitalize(t('actions.save'))}
            </Button>
          </Form.Item>
        </Form>
      </Row>}
      {...modal}
    >
      {!!description && <Row style={{marginTop: '1rem'}}>
        <Col>
          <Text strong={true}>
            {description}
          </Text>
        </Col>
      </Row>}
      <Row style={{margin: '1rem 0'}} gutter={[16, 16]}>
        <Col span={12}>
          <Form form={form} layout={'vertical'}>
            <AntFormItem label={capitalize(t('misc.date'))} name={'date'} rules={[
              {
                required: true,
                message: capitalize(t('form.requiredField', {field: t('events.eventOccurredDay')})),
                validator: (_, value) => {
                  if (!value || !isDayjs(value)) {
                    return Promise.reject();
                  }
                  return Promise.resolve();
                },
              }]}>
              <LocalizedDatePicker style={{width: '100%'}} {...dateDatePicker}/>
            </AntFormItem>
          </Form>
        </Col>
        <Col span={12}>
          <Form form={form} layout={'vertical'}>
            <AntFormItem label={capitalize(t('misc.time'))} name={'time'}>
              <TimePicker style={{width: '100%'}} {...timeDatePicker}/>
            </AntFormItem>
          </Form>
        </Col>
      </Row>
    </Modal>
    <Button
      type={'text'}
      icon={<EditOutlined/>}
      onClick={(e) => {
        modalButton?.onClick && modalButton.onClick(e)
        setIsModalOpen(true)
      }}
      {...modalButton}
    />
  </>
}