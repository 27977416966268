import React, {useEffect, useState} from 'react';
import {Button, ButtonProps, Space} from 'antd';
import {capitalize} from 'lodash';
import {ArrowLeftOutlined} from '@ant-design/icons';
import SettingsDrawer from '../SettingsDrawer';
import {EventTypeForm} from '../../../types/internal/eventTypes';
import {useGetIconsNotPaginatedQuery} from '../../../redux/api/icons';
import {Icon} from '../../../types/api/icons';
import EventTypeIcons from './formEventType/EventTypeIcons';
import EventTypeNamesForm from './formEventType/EventTypeNamesForm';

export const EVENT_TYPE_DEFAULT_VALUES = {
  name_en: '',
  name_it: '',
  icon: null,
};

export default function EventTypeDrawer({isDrawerOpen, setIsDrawerOpen, headerTitle, confirmButton, onFinish, values}: {
  isDrawerOpen: boolean,
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>,
  headerTitle?: string,
  confirmButton?: ButtonProps,
  onFinish: (values: EventTypeForm) => void,
  values?: {
    'icon': Icon | null,
    'name_en': string,
    'name_it': string,
  }
}) {

  const [eventValue, setEventValue] = useState<EventTypeForm>(values ? {
    ...values,
    icon: values.icon?.uuid || null,
  } : EVENT_TYPE_DEFAULT_VALUES);
  const disabled = !eventValue.name_it || !eventValue.name_en || !eventValue.icon;
  const {data: icons} = useGetIconsNotPaginatedQuery();

  useEffect(() => {
    if (!values) {
      setEventValue({...EVENT_TYPE_DEFAULT_VALUES, icon: (icons?.length && icons[0].uuid) || null});
    }
  }, [icons, values]);

  useEffect(() => {
    if (values) {
      setEventValue({...values, icon: values.icon?.uuid || null});
    }
  }, [values]);

  const header = <div style={{display: 'flex', justifyContent: 'space-between', alignContent: 'center'}}>
    <Space>
      <Button
        icon={<ArrowLeftOutlined/>}
        type={'text'}
        onClick={() => setIsDrawerOpen(false)}
      />
      {capitalize(headerTitle)}
    </Space>
    <Button
      type={'primary'}
      disabled={disabled}
      onClick={() => onFinish(eventValue)}
      {...confirmButton}
    />
  </div>;

  return <>
    <SettingsDrawer
      header={header}
      detail={<EventTypeNamesForm
        eventValue={eventValue}
        setEventValue={setEventValue}
      />}
      open={isDrawerOpen}
      onClose={() => setIsDrawerOpen(false)}
    >
      <EventTypeIcons
        eventValue={eventValue}
        setEventValue={setEventValue}
      />
    </SettingsDrawer>
  </>;
}