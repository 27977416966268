import {Card, Col, Input, Row, Select, Space, Typography} from 'antd';
import {useAssetDetailsContext} from '../../context/addAsset/AssetDetailsContext';
import {CustomNumericInput} from '../common/CustomNumericInput';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import {useGetAssetsOptionsQuery} from '../../redux/api/assets';
import {useAddAssetFormContext} from '../../context/addAsset/AddAssetFormContext';
import {SigiStatus} from '../../types/internal/AddAssetForm';
import {CustomLoading} from '../common/CustomLoading';
import {CheckCircleTwoTone, WarningTwoTone} from '@ant-design/icons';
import {yellow} from '@ant-design/colors';
import {LocalizedDatePicker} from '../common/LocalizedDatePicker';
import Label from '../common/Label';
import {CompaniesSearchSelect} from '../common/searchSelect/CompaniesSearchSelect';
import {OptionLabelValue} from '../../types/internal/OptionLabelValue';
import {MasterAssetSearchSelect} from '../common/searchSelect/MasterAssetSearchSelect';

const {Text} = Typography;
export const AssetDetailFormCard = () => {
  
  const {t} = useTranslation();
  const [details, setDetails] = useAssetDetailsContext();
  const {isSigiValid, checkSigi} = useAddAssetFormContext();
  
  const {data: assetsOptions} = useGetAssetsOptionsQuery();
  
  return (
    <Card title={capitalize(t('assets.buildingDetails'))}>
      <Row gutter={[40, 0]} align={'middle'}>
        <Col xs={24} lg={12}>
          <Label label={capitalize(t('assets.sigiCode'))} required={true} htmlFor={'sigi_code'}/>
          <Input
            placeholder={capitalize(t('assets.sigiCode'))}
            id={'sigi_code'}
            value={details.sigi_code || ''}
            onChange={e => {
              setDetails(details => ({...details, sigi_code: e.target.value || null}));
              checkSigi(e.target.value);
            }}
            style={{marginBottom: 20}}
          />
        </Col>
        <Col xs={24} lg={12}>
          <Space direction={'horizontal'} wrap={false}>
            {isSigiValid === SigiStatus.loading && (
              <>
                <CustomLoading style={{fontSize: 18}}/>
                <Text type={'secondary'}>{capitalize(t('assets.validatingSigiCode'))}</Text>
              </>
            )}
            {isSigiValid === SigiStatus.valid && (
              <>
                <CheckCircleTwoTone twoToneColor={'#52c41a'} style={{fontSize: 18}}/>
                <Text type={'secondary'}>{capitalize(t('assets.sigiCodeIsValid'))}</Text>
              </>
            )}
            {isSigiValid === SigiStatus.not_valid && (
              <>
                <WarningTwoTone twoToneColor={yellow[5]} style={{fontSize: 18}}/>
                <Text type={'secondary'}>{capitalize(t('assets.sigiCodeIsNotValid'))}</Text>
              </>
            )}
          </Space>
        </Col>
      </Row>
      <Row gutter={[40, 0]}>
        <Col xs={24} lg={12}>
          <MasterAssetSearchSelect
            value={details.master_asset}
            onClear={() => {
              setDetails(details => ({
                ...details,
                master_asset: null,
              }));
            }}
            onSelect={(value, options) => {
              setDetails(details => ({
                ...details,
                master_asset: options as OptionLabelValue,
              }));
            }}
          />
        </Col>
      </Row>
      <Row gutter={[40, 0]}>
        <Col xs={24} lg={12}>
          <Label label={capitalize(t('assets.buildingType'))} htmlFor={'building_type'}/>
          <Select
            value={assetsOptions?.building_type.find(option => option.value === details.building_type)}
            onChange={e => {
              if (e.value === 'SSR') {
                setDetails(details => ({...details, building_type: e.value}));
              } else {
                setDetails(details => ({
                  ...details,
                  building_type: e.value,
                  building_type_detail: null,
                }));
              }
            }}
            placeholder={capitalize(t('assets.buildingType'))}
            id={'building_type'}
            labelInValue={true}
            options={assetsOptions?.building_type}
            style={{display: 'flex', marginBottom: 20}}
          />
        </Col>
        {details.building_type === 'SSR' && <Col xs={24} lg={12}>
          <Label label={capitalize(t('assets.buildingTypeDetail'))} htmlFor={'building_type_detail'}/>
          <Select
            options={assetsOptions?.building_type_detail}
            value={assetsOptions?.building_type_detail.find(option => option.value === details.building_type_detail)}
            onChange={e => setDetails(details => ({...details, building_type_detail: e.value}))}
            id={'building_type_detail'}
            labelInValue={true}
            style={{display: 'flex', marginBottom: 20}}
          />
        </Col>}
      </Row>
      <Row gutter={[40, 0]}>
        <Col xs={24} lg={12}>
          <Label label={capitalize(t('assets.surfaceTotal'))} htmlFor={'total_surface'}/>
          <CustomNumericInput
            placeholder={capitalize(t('assets.surfaceTotal'))}
            value={details.surface_total}
            id={'total_surface'}
            suffix={' m²'}
            onValueChange={value =>
              setDetails(details => ({
                ...details,
                surface_total: value.floatValue || null,
              }))
            }
            style={{marginBottom: 20, display: 'flex'}}
          />
        </Col>
        <Col xs={24} lg={12}>
          <Label label={capitalize(t('assets.surfaceBasement'))} htmlFor={'basement_surface'}/>
          <CustomNumericInput
            placeholder={capitalize(t('assets.surfaceBasement'))}
            suffix={' m²'}
            id={'basement_surface'}
            value={details.surface_basement}
            onValueChange={value =>
              setDetails(details => ({
                ...details,
                surface_basement: value.floatValue || null,
              }))}
            style={{marginBottom: 20}}
          />
        </Col>
      </Row>
      <Row gutter={[40, 0]}>
        <Col xs={24} lg={12}>
          <Label
            label={capitalize(t('assets.openingDate'))}
            htmlFor={'opening_date'}
            required={true}
          />
          <LocalizedDatePicker
            value={details.opening_date}
            placeholder={capitalize(t('assets.openingDate'))}
            id={'opening_date'}
            onChange={e => setDetails(details => ({...details, opening_date: e}))}
            style={{marginBottom: 20, display: 'flex'}}
          />
        </Col>
        <Col xs={24} lg={12}>
          <Label label={capitalize(t('assets.closingDate'))} htmlFor={'closing_date'}/>
          <LocalizedDatePicker
            placeholder={capitalize(t('assets.closingDate'))}
            value={details.closing_date}
            id={'closing_date'}
            onChange={e => setDetails(details => ({...details, closing_date: e}))}
            style={{marginBottom: 20, display: 'flex'}}
          />
        </Col>
      </Row>
      <Row gutter={[40, 0]}>
        <Col xs={24} lg={12}>
          <CompaniesSearchSelect
            values={details.companies}
            onClear={() => {
              setDetails(details => ({
                ...details,
                companies: [],
              }));
            }}
            onChange={(value, options) => {
              setDetails(details => ({
                ...details,
                companies: options as OptionLabelValue[],
              }));
            }}
          />
        </Col>
        <Col xs={24} lg={12}>
          <Label label={capitalize(t('assets.totalResources'))} htmlFor={'totalResources'}/>
          <CustomNumericInput
            placeholder={capitalize(t('assets.totalResources'))}
            suffix={''}
            decimalScale={0}
            id={'totalResources'}
            value={details.total_resources}
            onValueChange={value =>
              setDetails(details => ({
                ...details,
                total_resources: value.floatValue || null,
              }))}
            style={{marginBottom: 20}}
          />
        </Col>
      </Row>
      <Row gutter={[40, 0]}>
        <Col xs={24} lg={12}>
          <Label label={capitalize(t('assets.isOwned'))} htmlFor={'ownership'}/>
          <Select
            options={assetsOptions?.ownership}
            value={assetsOptions?.ownership.find(option => option.value === details.ownership)}
            onChange={e => setDetails(details => ({...details, ownership: e.value}))}
            placeholder={capitalize(t('assets.isOwned'))}
            id={'ownership'}
            labelInValue={true}
            style={{display: 'flex', marginBottom: 20}}
          />
        </Col>
      </Row>
    </Card>
  );
};