import {Flex, Radio, Typography} from "antd";
import {BIODIVERSITY_SOURCE} from "../../../../types/api/assets";
import {useDispatch, useSelector} from "react-redux";
import {addFilters, removeFilters, selectAssetsTableFilters} from "../../../../redux/slices/assets";
import {capitalize} from "lodash";
import {useTranslation} from "react-i18next";

export const ALL_BIODIVERSITY_SOURCE = 'ALL'

export default function BiodiversitySourceFilter() {

  const dispatch = useDispatch()
  const activeFilters = useSelector(selectAssetsTableFilters);
  const value = activeFilters.biodiversity_source?.length ? activeFilters.biodiversity_source[0].value : ALL_BIODIVERSITY_SOURCE
  const {t} = useTranslation()
  const BIODIVERSITY_SOURCE_OPTIONS = [
  {
    label: 'Natura 2000',
    value: BIODIVERSITY_SOURCE.NT2000
  },
  {
    label: 'WDPA',
    value: BIODIVERSITY_SOURCE.WDPA
  },
  {
    label: capitalize(t('assets.biodiversitySourcesAll')),
    value: ALL_BIODIVERSITY_SOURCE
  },
]

  return <Flex vertical gap={'0.5rem'}>
    <Typography.Text strong>
      {capitalize(t('assets.biodiversitySources'))}
    </Typography.Text>
    <Radio.Group
      options={BIODIVERSITY_SOURCE_OPTIONS}
      value={value}
      onChange={v => {
        const value = v.target.value
        if (value === ALL_BIODIVERSITY_SOURCE) {
          dispatch(removeFilters('biodiversity_source'))
        } else {
          const option = BIODIVERSITY_SOURCE_OPTIONS.find(el => el.value === value)
          if (option) {
            dispatch(addFilters({biodiversity_source: [option]}))
          }
        }
      }}
    />
  </Flex>
}