import {apiSlice} from './baseApi';
import {RealEstateHub} from '../../types/internal/RealEstateHub';

const realEstateHubApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getRealEstateHubs: build.query<{ uuid: string, name: string }[], void>({
      query: () => ({
        url: '/realestatehub/',
        params: {
          paginate: false,
        },
      }),
    }),
    getRealEstateHubsByUuid: build.query<RealEstateHub, { uuid: string }>({
      query: ({uuid}) => `/realestatehub/${uuid}/`,
    }),
  }),
});

export const {
  useGetRealEstateHubsQuery,
  useLazyGetRealEstateHubsByUuidQuery,
} = realEstateHubApi;