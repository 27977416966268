import {GenericPaginatedApi, GenericPaginatedParamsApi} from './paginatedApi';
import {RcFile} from 'antd/lib/upload';

export enum CommunicationStatus {
  draft = 'D',
  sent = 'S',
  received = 'R',
}

export type EventCommunication = {
  'assets': string[],
  'cluster_templates': string[],
  'event': string,
  'tags': string[],
  'uuid': string,
  'date_creation': string,
  'date_edit': string,
  'user_creation': string,
  'user_edit': string,
  'datetime': string,
  'subject': string,
  'body': string,
  'status': CommunicationStatus,
  'attachments': string[]
}

export type EventCommunications = {
  'assets_count': number,
  'clusters_count': number,
  'datetime': string,
  'status': CommunicationStatus,
  'subject': string | null,
  'tags': string[],
  'user_edit': string,
  'uuid': string,
}

export type GetEventCommunicationResponse = GenericPaginatedApi<EventCommunications>

export interface GetEventClusterCommunicationsParams extends GenericPaginatedParamsApi {
  datetime_after?: string,
  datetime_before?: string,
  eventUuid: string,
  clusterUuid: string,
  status?:
    [CommunicationStatus.draft, CommunicationStatus.sent] |
    [CommunicationStatus.draft] |
    [CommunicationStatus.sent],
}

export interface GetEventAssetCommunicationsParams extends GenericPaginatedParamsApi {
  datetime_after?: string,
  datetime_before?: string,
  eventUuid: string,
  assetUuid: string,
  status?:
    [CommunicationStatus.draft] |
    [CommunicationStatus.received] |
    [CommunicationStatus.sent] |
    [CommunicationStatus.draft, CommunicationStatus.received] |
    [CommunicationStatus.draft, CommunicationStatus.sent] |
    [CommunicationStatus.received, CommunicationStatus.sent] |
    [CommunicationStatus.draft, CommunicationStatus.received, CommunicationStatus.sent]
}

export type GetEventTemplateMail = {
  'subject': string,
  'body': string,
  'tags': string[],
  'attachments': string[],
}

export type CreateCommunicationWithTextEditor = {
  'assets'?: string[],
  'attachments'?: string[],
  'body': string,
  'cluster_templates'?: string[],
  'datetime': string,
  'event': string,
  'subject': string,
  'status': CommunicationStatus,
  'tags'?: string[],
}

export type CreateCommunicationWithUpload = {
  'assets'?: string[],
  'attachments'?: string[],
  'cluster_templates'?: string[],
  // 'datetime': string,
  'email_file': RcFile,
  'event': string,
  // 'subject': string,
  'status': CommunicationStatus,
  'tags'?: string[],
}

export type PatchCommunication = {
  eventUuid: string,
  communicationUuid: string,
  tags: [],
  subject: string,
  body: string,
}
