import {createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState} from 'react';

export type ResizingMapContextType = [
  isResizing: boolean,
  setIsResizing: Dispatch<SetStateAction<boolean>>
]

const ResizingContext = createContext<ResizingMapContextType>([
  false,
  () => null,
]);

export const useResizingMapContext = () => useContext(ResizingContext);

export const ResizingMapContext = (props: PropsWithChildren) => {
  const [
    isResizing,
    setIsResizing,
  ] = useState<boolean>(false);

  return (
    <ResizingContext.Provider value={[isResizing, setIsResizing]}>
      {props.children}
    </ResizingContext.Provider>
  );
};