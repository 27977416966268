import {Button, ConfigProvider, Form, Modal, Space} from 'antd';
import React, {createRef, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  RollbackOutlined,
  SaveOutlined,
  ScissorOutlined,
} from '@ant-design/icons';
import DrawControlsButton from './DrawControlsButton';
import {useTranslation} from 'react-i18next';
import {PolygonIcon} from '../../../customIcon/PolygonIcon';
import _, {capitalize} from 'lodash';
import {EditControl, EditControlProps} from 'react-leaflet-draw';
import L, {latLng} from 'leaflet';
import {FeatureGroup, Pane, useMap, useMapEvents} from 'react-leaflet';
import {Feature, lineString, MultiPolygon, multiPolygon, point, Polygon, Position} from '@turf/helpers';
import circle from '@turf/circle';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import {flipTurfFeatureCoordinates, toMultiPolygon} from '../../../../../utils/polygons';
import {useMapLoadingContext} from '../../../../../context/baseMap/MapLoadingContext';
import buffer from '@turf/buffer';
import DrawCircleWithCompassFormItems from './Form/DrawCircleWithCompassFormItems';
import DrawLineOffsetFormItems from './Form/DrawLineOffsetFormItems';
import TooltippedDrawControlsButton from './TooltippedDrawControlsButton';
import {CircleIcon} from '../../../customIcon/CircleIcon';
import {MathCompassIcon} from '../../../customIcon/MathCompassIcon';
import {OffsetLineIcon} from '../../../customIcon/OffsetLineIcon';
import {BringToFrontIcon} from '../../../customIcon/BringToFrontIcon';
import {SendToBackIcon} from '../../../customIcon/SendToBackIcon';
import {RulerIcon} from '../../../customIcon/RulerIcon';
import RulerMeasurement from '../../RulerMeasurement';
import useEscapeKey from '../../../../../customHooks/useEscapeKey';
import useKeyboardShortcut from '../../../../../customHooks/useKeyboardShortcut';
import pointToLineDistance from '@turf/point-to-line-distance';
import EditableFeature from './EditableFeature';
import {useControlsAction} from '../../../../../context/baseMap/ControlContext';
import {ASSET_MAP_ACTIONS} from '../../constants/map';
import {cutPolygons} from '../../../../../utils/map';
import {CustomPolygon} from '../../CustomPolygon';

export type DrawableGeometry = {
  coordinates: Position[][][],
  uuid: string,
  pathOptions?: L.PathOptions,
}

export type CutGeometries = {
  coordinates: Position[][][] | null,
  uuid: string,
}

export type NewDrawControlsType = {
  drawsPolygons?: boolean,
  drawsCircles?: boolean,
  drawsCirclesWithCompass?: boolean,
  drawsOffsettableLines?: boolean,
  canDeleteShapes?: boolean,
  canCutShapes?: boolean,
  canSelectShapes?: boolean,
  canMeasureDistances?: boolean,
  canEditShapes?: boolean,
  bringsToFront?: boolean,
  sendsToBack?: boolean,
  drawnGeometries?: DrawableGeometry[],
  onComponentMount?: () => void,
  onAfterPolygonButtonClick?: (map: L.Map) => void,
  onAfterCircleButtonClick?: (map: L.Map) => void,
  onAfterDeleteButtonClick?: (map: L.Map) => void,
  onAfterCutButtonClick?: (map: L.Map) => void,
  onAfterShapeCreation?: (createdFeature: Feature<MultiPolygon, {}>, map: L.Map) => Promise<void>,
  onAfterShapeCut?: (cutterGeometry: Feature<MultiPolygon, {}>, affectedGeometries: CutGeometries[], map: L.Map) => Promise<void>,
  onAfterShapeEdit?: (editedGeometry: Feature<MultiPolygon, {}>, geometryUuid: string, map: L.Map) => Promise<void>,
  onPolygonsDelete?: (polygonUuid: string, map: L.Map) => Promise<void>,
  onBringToFront?: (polygonUuid: string) => void,
  onSendToBack?: (polygonUuid: string) => void,
}

type MapModes = 'delete' | 'cut' | 'edit' | undefined;
type MapTools = 'polygon' | 'circle' | 'circleWithCompass' | 'offsetLine' | 'measure' | undefined;

/**
 * Componente per gestire i controlli di disegno e modifica sulla mappa.
 * @param drawsPolygons Opzione per abilitare/disabilitare il disegno di poligoni (default: true).
 * @param drawsCircles Opzione per abilitare/disabilitare il disegno di cerchi (default: true).
 * @param withEnhancedCircles Opzione per abilitare/disabilitare il disegno di cerchi in modalità controllata (default: true).
 * @param canDeleteShapes Opzione per abilitare/disabilitare l'eliminazione di forme (default: true).
 * @param canCutShapes Opzione per abilitare/disabilitare il taglio di forme (default: true).
 * @param onComponentMount Funzione chiamata quando il componente viene montato.
 * @param onAfterPolygonButtonClick Funzione chiamata dopo aver cliccato il pulsante di disegno del poligono.
 * @param onAfterCircleButtonClick Funzione chiamata dopo aver cliccato il pulsante di disegno del cerchio.
 * @param onAfterDeleteButtonClick Funzione chiamata dopo aver cliccato il pulsante di eliminazione del poligono.
 * @param onAfterCutButtonClick Funzione chiamata dopo aver cliccato il pulsante di taglio del poligono.
 * @param drawnGeometries Array di multipolygon precedentemente disegnati sulla mappa (default = []).
 * @param onAfterShapeCreation Funzione chiamata dopo aver creato un poligono sulla mappa.
 * @param onAfterShapeCut Funzione chiamata dopo aver tagliato un poligono sulla mappa.
 * @param onPolygonsDelete Funzione chiamata dopo aver cliccato su un poligono in modalità elimina.
 * @param editControlHandlers Funzioni handler di leaflet draw. Consulta la documentazione di React-Leaflet-Draw
 * @link https://github.com/alex3165/react-leaflet-draw?tab=readme-ov-file
 */
const NewDrawControls = ({
                           drawsPolygons = true,
                           drawsCircles = true,
                           drawsCirclesWithCompass = true,
                           drawsOffsettableLines = true,
                           canDeleteShapes = true,
                           canCutShapes = true,
                           canSelectShapes = true,
                           canMeasureDistances = true,
                           canEditShapes = false,
                           bringsToFront = true,
                           sendsToBack = true,
                           onComponentMount,
                           onAfterPolygonButtonClick,
                           onAfterCircleButtonClick,
                           onAfterDeleteButtonClick,
                           onAfterCutButtonClick,
                           drawnGeometries = [],
                           onAfterShapeCreation,
                           onAfterShapeCut,
                           onAfterShapeEdit,
                           onPolygonsDelete,
                           onBringToFront,
                           onSendToBack,
                           ...editControlHandlers
                         }: NewDrawControlsType & Omit<EditControlProps, 'position' | 'draw' | 'onCreated'>) => {
  
  const drawControlsBoxRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null);
  const componentWillMountRef = useRef(true);
  const leafletDrawControlsRef = useRef<L.Control>();
  const drawnGeometriesRef = useRef<DrawableGeometry[]>([]);
  const editableLeafletFeatureDrawControlsRef = createRef<L.Control>();
  const currentDrawModeRef = useRef<MapModes>(undefined);
  const currentDrawToolRef = useRef<MapTools>(undefined);
  
  const {t} = useTranslation();
  const [modal, contextHolder] = Modal.useModal();
  const [form] = Form.useForm();
  const [, setIsMapLoading] = useMapLoadingContext();
  const map = useMap();
  const {
    action: {
      currentAction,
      actionEvent,
    },
    changeAction,
  } = useControlsAction();
  
  const [
    currentDrawTool,
    setCurrentDrawTool,
  ] = useState<MapTools>();
  const [
    currentDrawMode,
    setCurrentDrawMode,
  ] = useState<MapModes>(undefined);
  const [
    selectedMeasurementId,
    setSelectedMeasurementId,
  ] = useState<string | undefined>(undefined);
  const [
    drawnVertices,
    setDrawnVertices,
  ] = useState(0);
  const [
    measurementLines,
    setMeasurementLines,
  ] = useState<{ coordinates: Position[], id: string }[]>([]);
  
  const enablePolygon = useCallback(() => {
    // @ts-ignore
    leafletDrawControlsRef.current?._toolbars.draw._modes.polygon.handler.enable();
    setDrawnVertices(0);
  }, []);
  const disablePolygon = useCallback(() => {
    // @ts-ignore
    leafletDrawControlsRef.current?._toolbars.draw._modes.polygon.handler.disable();
    setDrawnVertices(0);
  }, []);
  const enableCircle = useCallback(() => {
    // @ts-ignore
    leafletDrawControlsRef.current?._toolbars.draw._modes.circle.handler.enable();
  }, []);
  const disableCircle = useCallback(() => {
    // @ts-ignore
    leafletDrawControlsRef.current?._toolbars.draw._modes.circle.handler.disable();
  }, []);
  const enablePolyline = useCallback(() => {
    // @ts-ignore
    leafletDrawControlsRef.current?._toolbars.draw._modes.polyline.handler.enable();
    setDrawnVertices(0);
  }, []);
  const disablePolyline = useCallback(() => {
    // @ts-ignore
    leafletDrawControlsRef.current?._toolbars.draw._modes.polyline.handler.disable();
    setDrawnVertices(0);
  }, []);
  const enableDeleteMode = useCallback(() => {
    // @ts-ignore
    leafletDrawControlsRef.current?._toolbars.draw._modes.polygon.handler.disable();
    // @ts-ignore
    leafletDrawControlsRef.current?._toolbars.draw._modes.polyline.handler.disable();
    // @ts-ignore
    leafletDrawControlsRef.current?._toolbars.draw._modes.circle.handler.disable();
    setDrawnVertices(0);
    changeAction(ASSET_MAP_ACTIONS.SELECT_GLOBAL_POLYGON, {uuid: undefined});
    setCurrentDrawTool(undefined);
  }, [changeAction]);
  const enableRuler = useCallback(() => {
    L.drawLocal.draw.handlers.polyline.tooltip.start = '';//capitalize('stabilisci il primo punto');
    L.drawLocal.draw.handlers.polyline.tooltip.cont = '';
    L.drawLocal.draw.handlers.polyline.tooltip.end = '';
    L.drawLocal.draw.handlers.polyline.error = '';
    // @ts-ignore
    leafletDrawControlsRef.current?._toolbars.draw._modes.polyline.handler.enable();
    setDrawnVertices(0);
  }, []);
  const disableRuler = useCallback(() => {
    L.drawLocal.draw.handlers.polyline.tooltip.start = capitalize(t('map.clickToStartDrawingPolyline'));
    L.drawLocal.draw.handlers.polyline.tooltip.cont = capitalize(t('map.clickToContinueDrawingPolyline'));
    L.drawLocal.draw.handlers.polyline.tooltip.end = capitalize(t('map.clickToEndDrawingPolyline'));
    L.drawLocal.draw.handlers.polyline.error = capitalize(t('map.errorDrawingPolyline'));
    // @ts-ignore
    leafletDrawControlsRef.current?._toolbars.draw._modes.polyline.handler.disable();
    setDrawnVertices(0);
  }, [t]);
  const discardAndDisableEditMode = useCallback(() => {
    try {
      //@ts-ignore
      editableLeafletFeatureDrawControlsRef.current?._toolbars.edit._modes.edit.handler.revertLayers();
      //@ts-ignore
      editableLeafletFeatureDrawControlsRef.current?._toolbars.edit._modes.edit.handler.disable();
      
    } catch (e) {
      console.error('errore nel disabilitare la modalità di modifica: ', e);
    }
    
  }, [editableLeafletFeatureDrawControlsRef]);
  const saveAndDisableEditMode = useCallback(() => {
    try {
      //@ts-ignore
      editableLeafletFeatureDrawControlsRef.current?._toolbars.edit._modes.edit.handler.save();
      //@ts-ignore
      editableLeafletFeatureDrawControlsRef.current?._toolbars.edit._modes.edit.handler.disable();
      
    } catch (e) {
      console.error('errore nel disabilitare la modalità di modifica: ', e);
    }
    
  }, [editableLeafletFeatureDrawControlsRef]);
  
  const cutGeometries = useCallback((cutterPolygon: Feature<MultiPolygon, any>) => {
    return cutPolygons(drawnGeometriesRef.current, cutterPolygon);
  }, []);
  
  const handlePolygonButtonClick = useCallback(() => {
    setCurrentDrawTool(currentOption => {
      if (currentOption !== 'polygon') {
        enablePolygon();
        return 'polygon';
      } else {
        disablePolygon();
        return undefined;
      }
    });
    onAfterPolygonButtonClick?.(map);
  }, [disablePolygon, enablePolygon, map, onAfterPolygonButtonClick]);
  const handleCircleButtonClick = useCallback(() => {
    setCurrentDrawTool(currentOption => {
      if (currentOption !== 'circle') {
        enableCircle();
        return 'circle';
      } else {
        disableCircle();
        return undefined;
      }
    });
    onAfterCircleButtonClick?.(map);
  }, [disableCircle, enableCircle, map, onAfterCircleButtonClick]);
  const handleCircleWithCompassButtonClick = useCallback(() => {
    setCurrentDrawTool(currentOption => {
      if (currentOption !== 'circleWithCompass') {
        modal.confirm({
          icon: null,
          closable: true,
          title: capitalize(t('map.enhancedCircle')),
          okText: capitalize(t('actions.create')),
          cancelText: capitalize(t('actions.close')),
          onCancel: (close) => {
            form.resetFields();
            close();
            setCurrentDrawTool(undefined);
          },
          onOk: (close) => {
            form
              .validateFields()
              .then((values) => {
                form.resetFields();
                const turfCircle: Feature<Polygon, {}> = circle(
                  point([values.longitude, values.latitude]),
                  values.radius,
                  {units: 'meters'},
                );
                const leafletPolygon = L.polygon([
                  flipTurfFeatureCoordinates(turfCircle).geometry.coordinates,
                ] as L.LatLngExpression[][][]);
                
                onAfterShapeCreation?.(
                  leafletPolygon.toGeoJSON() as Feature<MultiPolygon, {}>,
                  map,
                );
                close();
              })
              .catch((info) => {
                console.error('Validate Failed:', info);
              })
              .finally(() => setCurrentDrawTool(undefined));
          },
          content: (
            <Form
              form={form}
              layout={'vertical'}
              name={'drawCircleWithCompassForm'}
            >
              <DrawCircleWithCompassFormItems/>
            </Form>
          ),
        });
        return 'circleWithCompass';
      } else {
        return undefined;
      }
    });
  }, [form, map, modal, onAfterShapeCreation, t]);
  const handleOffsetLineButtonClick = useCallback(() => {
    setCurrentDrawTool(currentOption => {
      if (currentOption !== 'offsetLine') {
        enablePolyline();
        return 'offsetLine';
      } else {
        disablePolyline();
        return undefined;
      }
    });
  }, [disablePolyline, enablePolyline]);
  const handleMeasureButtonClick = useCallback(() => {
    setCurrentDrawTool(currentOption => {
      if (currentOption !== 'measure') {
        enableRuler();
        return 'measure';
      } else {
        disableRuler();
        return undefined;
      }
    });
  }, [disableRuler, enableRuler]);
  const handleDeleteShapeButtonClick = useCallback(() => {
    setCurrentDrawMode(currentMode => {
      if (currentMode !== 'delete') {
        if (selectedMeasurementId) {
          setMeasurementLines(lines => lines.filter(line => line.id !== selectedMeasurementId));
        } else if (currentAction === ASSET_MAP_ACTIONS.SELECT_GLOBAL_POLYGON && actionEvent?.uuid) {
          setIsMapLoading(true);
          onPolygonsDelete?.(actionEvent.uuid, map)
            .then(() => {
              changeAction(ASSET_MAP_ACTIONS.SELECT_GLOBAL_POLYGON, {uuid: undefined});
            })
            .catch(e => console.error(e))
            .finally(() => setIsMapLoading(false));
        } else {
          enableDeleteMode();
          return 'delete';
        }
      } else {
        return undefined;
      }
    });
    onAfterDeleteButtonClick?.(map);
  }, [actionEvent, changeAction, currentAction, enableDeleteMode, map, onAfterDeleteButtonClick, onPolygonsDelete, selectedMeasurementId, setIsMapLoading]);
  const handleCutShapeButtonClick = useCallback(() => {
    if (currentDrawMode !== 'cut') {
      if (drawnGeometries && currentAction === ASSET_MAP_ACTIONS.SELECT_GLOBAL_POLYGON && actionEvent?.uuid) {
        const selectedGeometry = drawnGeometries?.find(geometry => geometry.uuid === actionEvent?.uuid);
        if (selectedGeometry) {
          const cutter = multiPolygon(selectedGeometry.coordinates);
          setIsMapLoading(true);
          const affectedGeometries = cutGeometries(cutter);
          onAfterShapeCut
            ?.(cutter as Feature<MultiPolygon, {}>, affectedGeometries, map)
            .catch(e => console.error(e))
            .finally(() => {
              setIsMapLoading(false);
              changeAction(ASSET_MAP_ACTIONS.SELECT_GLOBAL_POLYGON, {uuid: undefined});
            });
        }
        setCurrentDrawMode(undefined);
      } else {
        if (!currentDrawTool) {
          setCurrentDrawTool('polygon');
          enablePolygon();
        }
        setCurrentDrawMode('cut');
      }
    } else {
      setCurrentDrawTool(undefined);
      disablePolygon();
      disableCircle();
      disablePolyline();
      setCurrentDrawMode(undefined);
    }
    onAfterCutButtonClick?.(map);
  }, [actionEvent?.uuid, changeAction, currentAction, currentDrawMode, currentDrawTool, cutGeometries, disableCircle, disablePolygon, disablePolyline, drawnGeometries, enablePolygon, map, onAfterCutButtonClick, onAfterShapeCut, setIsMapLoading]);
  
  const handleEditShapeButtonClick = useCallback(() => {
    setCurrentDrawMode(currentMode => {
      if (currentMode !== 'edit') {
        return 'edit';
      } else {
        try {
          discardAndDisableEditMode();
        } catch (e) {
          console.error(e);
        }
        return undefined;
      }
    });
  }, [discardAndDisableEditMode]);
  
  const toolOptions = useMemo(() => (
    [
      ...(drawsPolygons ? [{
        component: (
          <TooltippedDrawControlsButton
            drawControlsButtonProps={
              {
                isActive: currentDrawTool === 'polygon',
                icon: <PolygonIcon/>,
                disabled: (currentDrawTool !== undefined && currentDrawTool !== 'polygon') || currentDrawMode === 'delete',
                onClick: handlePolygonButtonClick,
                title: capitalize(t('map.polygon')),
              }
            }
            popoverProps={
              {
                open: currentDrawTool === 'polygon',
                placement: 'right',
                content: <Button
                  icon={<RollbackOutlined/>}
                  size={'small'}
                  type={'text'}
                  title={capitalize(t('form.previous'))}
                  disabled={drawnVertices <= 0}
                  onClick={() => {
                    // @ts-ignore
                    leafletDrawControlsRef.current?._toolbars.draw._modes.polygon.handler.deleteLastVertex();
                    setDrawnVertices(vertex => vertex - 1);
                  }}
                >
                  {capitalize(t('form.previous'))}
                </Button>,
              }
            }
          />
        ),
      }] : []),
      ...(drawsCircles ? [{
        component: (
          <DrawControlsButton
            isActive={currentDrawTool === 'circle'}
            icon={<CircleIcon/>}
            disabled={(currentDrawTool !== undefined && currentDrawTool !== 'circle') || currentDrawMode === 'delete'}
            onClick={handleCircleButtonClick}
            title={capitalize(t('map.circle'))}
          />
        ),
      }] : []),
      ...(drawsCirclesWithCompass ? [{
        component: (
          <DrawControlsButton
            isActive={currentDrawTool === 'circleWithCompass'}
            icon={<MathCompassIcon/>}
            disabled={(currentDrawTool !== undefined && currentDrawTool !== 'circleWithCompass') || currentDrawMode === 'delete'}
            onClick={handleCircleWithCompassButtonClick}
            title={capitalize(t('map.enhancedCircle'))}
          />
        ),
      }] : []),
      ...(drawsOffsettableLines ? [{
        component: (
          <>
            <TooltippedDrawControlsButton
              drawControlsButtonProps={
                {
                  isActive: currentDrawTool === 'offsetLine',
                  icon: <OffsetLineIcon/>,
                  disabled: (currentDrawTool !== undefined && currentDrawTool !== 'offsetLine') || currentDrawMode === 'delete',
                  onClick: handleOffsetLineButtonClick,
                  title: capitalize('offset'),
                }
              }
              popoverProps={
                {
                  open: currentDrawTool === 'offsetLine',
                  placement: 'right',
                  content:
                    <Button
                      icon={<RollbackOutlined/>}
                      size={'small'}
                      type={'text'}
                      title={capitalize(t('form.previous'))}
                      disabled={drawnVertices <= 0}
                      onClick={() => {
                        // @ts-ignore
                        leafletDrawControlsRef.current?._toolbars.draw._modes.polyline.handler.deleteLastVertex();
                        setDrawnVertices(vertex => vertex - 1);
                      }}
                    >
                      {capitalize(t('form.previous'))}
                    </Button>,
                }
              }
            />
          </>
        ),
      }] : []),
      ...(canMeasureDistances ? [{
        component: (
          <DrawControlsButton
            isActive={currentDrawTool === 'measure'}
            icon={<RulerIcon/>}
            disabled={
              (currentDrawTool !== undefined && currentDrawTool !== 'measure') ||
              (currentDrawMode === 'delete' || currentDrawMode === 'cut')
            }
            onClick={handleMeasureButtonClick}
            title={capitalize(t('map.cut'))}
          />
        ),
      }] : []),
      ...(bringsToFront ? [{
        component: (
          <DrawControlsButton
            icon={<BringToFrontIcon/>}
            disabled={currentDrawTool !== undefined || currentDrawMode === 'delete' || (currentAction === ASSET_MAP_ACTIONS.SELECT_GLOBAL_POLYGON && actionEvent?.uuid === undefined) || drawnGeometries.length <= 1}
            onClick={() => {
              if (currentAction === ASSET_MAP_ACTIONS.SELECT_GLOBAL_POLYGON && actionEvent?.uuid) {
                onBringToFront?.(actionEvent.uuid);
                changeAction(ASSET_MAP_ACTIONS.SELECT_GLOBAL_POLYGON, {uuid: undefined});
                setCurrentDrawMode(undefined);
              }
            }}
            title={capitalize(t('map.bringToFront'))}
          />
        ),
      }] : []),
      ...(sendsToBack ? [{
        component: (
          <DrawControlsButton
            icon={<SendToBackIcon/>}
            disabled={currentDrawTool !== undefined || currentDrawMode === 'delete' || (currentAction === ASSET_MAP_ACTIONS.SELECT_GLOBAL_POLYGON && actionEvent?.uuid === undefined) || drawnGeometries?.length <= 1}
            onClick={() => {
              if (currentAction === ASSET_MAP_ACTIONS.SELECT_GLOBAL_POLYGON && actionEvent?.uuid) {
                onSendToBack?.(actionEvent.uuid);
                changeAction(ASSET_MAP_ACTIONS.SELECT_GLOBAL_POLYGON, {uuid: undefined});
                setCurrentDrawMode(undefined);
              }
            }}
            title={capitalize(t('map.sendToBack'))}
          />
        ),
      }] : []),
    ]
  ), [drawsPolygons, currentDrawTool, currentDrawMode, handlePolygonButtonClick, t, drawnVertices, drawsCircles, handleCircleButtonClick, drawsCirclesWithCompass, handleCircleWithCompassButtonClick, drawsOffsettableLines, handleOffsetLineButtonClick, canMeasureDistances, handleMeasureButtonClick, bringsToFront, currentAction, actionEvent, drawnGeometries.length, sendsToBack, onBringToFront, changeAction, onSendToBack]);
  const modeOptions = useMemo(() => (
    [
      ...(canCutShapes ? [{
        component: (
          <DrawControlsButton
            isActive={currentDrawMode === 'cut'}
            icon={<ScissorOutlined/>}
            disabled={
              (currentDrawMode !== undefined && currentDrawMode !== 'cut') ||
              //(currentAction === ASSET_MAP_ACTIONS.SELECT_GLOBAL_POLYGON && actionEvent?.uuid === undefined) ||
              _.isEmpty(drawnGeometries)}
            onClick={handleCutShapeButtonClick}
            title={capitalize(t('map.cut'))}
          />
        ),
      }] : []),
      ...(canDeleteShapes ? [{
        component: (
          <DrawControlsButton
            isActive={currentDrawMode === 'delete'}
            icon={<DeleteOutlined/>}
            disabled={
              (currentDrawMode !== undefined && currentDrawMode !== 'delete') ||
              (_.isEmpty(drawnGeometries) && _.isEmpty(measurementLines))
            }
            onClick={handleDeleteShapeButtonClick}
            title={capitalize(t('map.deleteSelectedArea'))}
          />
        ),
      }] : []),
      ...(canEditShapes ? [{
        component: (
          <TooltippedDrawControlsButton
            drawControlsButtonProps={
              {
                isActive: currentDrawMode === 'edit',
                icon: <EditOutlined/>,
                disabled:
                  (currentDrawMode !== undefined && currentDrawMode !== 'edit') ||
                  _.isEmpty(drawnGeometries) ||
                  (
                    currentAction === ASSET_MAP_ACTIONS.SELECT_GLOBAL_POLYGON &&
                    actionEvent?.uuid === undefined
                  ),
                onClick: handleEditShapeButtonClick,
                title: capitalize(t('actions.edit')),
              }
            }
            popoverProps={
              {
                open: currentDrawMode === 'edit',
                placement: 'right',
                content: (
                  <>
                    <Button
                      icon={<CloseOutlined/>}
                      size={'small'}
                      type={'text'}
                      title={capitalize(t('form.cancel'))}
                      onClick={() => {
                        discardAndDisableEditMode();
                        setCurrentDrawMode(undefined);
                      }}
                    >
                      {capitalize(t('form.cancel'))}
                    </Button>
                    <Button
                      icon={<SaveOutlined/>}
                      size={'small'}
                      type={'text'}
                      title={capitalize(t('actions.save'))}
                      onClick={() => {
                        saveAndDisableEditMode();
                        setCurrentDrawMode(undefined);
                      }}
                    >
                      {capitalize(t('actions.save'))}
                    </Button>
                  </>
                ),
                
              }
            }
          />
        ),
      }] : []),
    ]
  ), [canCutShapes, currentDrawMode, currentAction, actionEvent?.uuid, drawnGeometries, handleCutShapeButtonClick, t, canDeleteShapes, measurementLines, handleDeleteShapeButtonClick, canEditShapes, handleEditShapeButtonClick, discardAndDisableEditMode, saveAndDisableEditMode]);
  
  const handleShapeCreation = useCallback(async (createdEvent: L.DrawEvents.Created) => {
    setIsMapLoading(true);
    setCurrentDrawTool(undefined);
    setDrawnVertices(0);
    let createdPolygon: L.Polygon | undefined;
    switch (createdEvent.layerType) {
      case 'polygon':
        const drawnPolygon = createdEvent.layer as L.Polygon;
        createdPolygon = L.polygon([drawnPolygon.getLatLngs()] as L.LatLngExpression[][][]);
        drawnPolygon.remove();
        break;
      case 'circle':
        const drawnCircle = createdEvent.layer as L.Circle;
        const turfCircle: Feature<Polygon, {}> = circle(
          point([drawnCircle.getLatLng().lng, drawnCircle.getLatLng().lat]),
          Math.max(10, drawnCircle.getRadius()),
          {units: 'meters'},
        );
        createdPolygon = L.polygon([
          flipTurfFeatureCoordinates(turfCircle).geometry.coordinates,
        ] as L.LatLngExpression[][][]);
        drawnCircle.remove();
        break;
      case 'polyline':
        const drawnLine = createdEvent.layer as L.Polyline;
        const line = lineString(drawnLine.toGeoJSON(6).geometry.coordinates as Position[]);
        if (currentDrawToolRef.current === 'measure') {
          setIsMapLoading(false);
          setMeasurementLines(lines =>
            [
              ...lines,
              {
                coordinates: drawnLine.toGeoJSON(6).geometry.coordinates as Position[],
                id: `${(lines.at(-1)?.id || 0)}`,
              },
            ]);
          createdEvent.layer.remove();
        } else {
          let offset = 0;
          createdEvent.layer.remove();
          const isConfirmed = await modal.confirm({
            icon: null,
            closable: true,
            title: capitalize(t('map.lineOffset')),
            okText: capitalize(t('actions.confirm')),
            cancelText: capitalize(t('form.cancel')),
            onCancel: (close) => {
              form.resetFields();
              close();
              setCurrentDrawTool(undefined);
            },
            onOk: (close) => {
              form
                .validateFields()
                .then((values) => {
                  form.resetFields();
                  offset = values.offset;
                  close();
                })
                .catch((info) => {
                  console.error('Validate Failed:', info);
                })
                .finally(() => setCurrentDrawTool(undefined));
            },
            content: (
              <>
                <Form
                  form={form}
                  layout={'vertical'}
                  name={'createExtrudedLine'}
                >
                  <DrawLineOffsetFormItems/>
                </Form>
              </>
            ),
          });
          if (isConfirmed) {
            createdPolygon = L.polygon(flipTurfFeatureCoordinates(multiPolygon([buffer(line, offset, {units: 'meters'}).geometry.coordinates]) as Feature<MultiPolygon, {}>).geometry.coordinates as L.LatLngExpression[][][]);
          } else {
            setIsMapLoading(false);
          }
        }
        break;
      default:
        break;
    }
    
    if (createdPolygon) {
      if (currentDrawModeRef.current === 'cut') {
        const cutter = toMultiPolygon(createdPolygon.toGeoJSON(6));
        const affectedGeometries = cutGeometries(cutter);
        onAfterShapeCut?.(cutter as Feature<MultiPolygon, {}>, affectedGeometries, map)
          .catch(e => console.error(e))
          .finally(() => {
            changeAction(ASSET_MAP_ACTIONS.SELECT_GLOBAL_POLYGON, {uuid: undefined});
            setIsMapLoading(false);
            setCurrentDrawMode(undefined);
          });
      } else if (onAfterShapeCreation) {
        onAfterShapeCreation(toMultiPolygon(createdPolygon.toGeoJSON(6)), map)
          .catch(e => console.error(e))
          .finally((() => {
            setIsMapLoading(false);
            setCurrentDrawMode(undefined);
          }));
      }
    }
  }, [changeAction, cutGeometries, form, map, modal, onAfterShapeCreation, onAfterShapeCut, setIsMapLoading, t]);
  
  useMapEvents({
    click: e => {
      switch (currentDrawTool) {
        case 'polygon':
        case 'offsetLine':
          setDrawnVertices(vertex => vertex + 1);
          break;
        default:
          break;
      }
      
      if (currentDrawMode === 'edit') {
        discardAndDisableEditMode();
        setCurrentDrawMode(undefined);
      }
      
      const clickedPoint = point([e.latlng.lng, e.latlng.lat]);
      
      if ((!_.isEmpty(drawnGeometries) || !_.isEmpty(measurementLines)) && currentDrawTool === undefined) {
        const clickedPolygonUuid =
          _.findLast(drawnGeometries, geometry =>
            booleanPointInPolygon(
              clickedPoint,
              multiPolygon(geometry.coordinates)))?.uuid;
        
        const clickedMeasurementId =
          _.findLast(measurementLines, line =>
            pointToLineDistance(
              clickedPoint,
              lineString(line.coordinates),
              {units: 'radians'},
            ) <= 0.000025,
          )?.id;
        
        switch (currentDrawMode) {
          case 'delete':
            if (clickedMeasurementId !== undefined) {
              setMeasurementLines(lines =>
                lines.filter(line => line.id !== clickedMeasurementId),
              );
            } else if (clickedPolygonUuid) {
              setIsMapLoading(true);
              onPolygonsDelete?.(clickedPolygonUuid, map)
                .then(() => {
                  // @ts-ignore
                  leafletDrawControlsRef.current?._toolbars.edit._modes.remove.handler.disable();
                })
                .catch(e => {
                  console.error(e);
                })
                .finally(() => setIsMapLoading(false));
            }
            setCurrentDrawMode(undefined);
            break;
          default:
            if (clickedMeasurementId) {
              setSelectedMeasurementId(currentlySelectedMeasurement => clickedMeasurementId !== currentlySelectedMeasurement ? clickedMeasurementId : undefined);
              changeAction(ASSET_MAP_ACTIONS.SELECT_GLOBAL_POLYGON, {uuid: undefined});
            } else {
              changeAction(ASSET_MAP_ACTIONS.SELECT_GLOBAL_POLYGON, {uuid: (currentAction === ASSET_MAP_ACTIONS.SELECT_GLOBAL_POLYGON && actionEvent.uuid === clickedPolygonUuid) ? undefined : clickedPolygonUuid});
              setSelectedMeasurementId(undefined);
            }
            break;
        }
      }
    },
  });
  useEscapeKey(() => {
    setCurrentDrawMode(undefined);
    setCurrentDrawTool(undefined);
    changeAction(ASSET_MAP_ACTIONS.SELECT_GLOBAL_POLYGON, {uuid: undefined});
    setSelectedMeasurementId(undefined);
  });
  useKeyboardShortcut([
      {keyboardKey: 'Backspace'},
      {keyboardKey: 'Canc'},
    ],
    () => {
      if (selectedMeasurementId) {
        setMeasurementLines(lines => lines.filter(line => line.id !== selectedMeasurementId));
        setSelectedMeasurementId(undefined);
        setCurrentDrawMode(undefined);
      } else if (currentAction === ASSET_MAP_ACTIONS.SELECT_GLOBAL_POLYGON && actionEvent?.uuid && onPolygonsDelete) {
        setIsMapLoading(true);
        onPolygonsDelete(actionEvent.uuid, map)
          .then(() => {
            changeAction(ASSET_MAP_ACTIONS.SELECT_GLOBAL_POLYGON, {uuid: undefined});
            setCurrentDrawMode(undefined);
          })
          .catch(e => console.error(e))
          .finally(() => setIsMapLoading(false));
      }
    });
  useKeyboardShortcut([
      {keyboardKey: 'x'},
    ],
    () => {
      if (currentAction === ASSET_MAP_ACTIONS.SELECT_GLOBAL_POLYGON && actionEvent?.uuid && onAfterShapeCut) {
        const coordinates = drawnGeometries?.find(geometry => geometry.uuid === actionEvent.uuid)?.coordinates;
        if (coordinates) {
          const cutter = multiPolygon(coordinates);
          const affetctedGeometries = cutGeometries(cutter);
          setIsMapLoading(true);
          onAfterShapeCut(cutter as Feature<MultiPolygon, {}>, affetctedGeometries, map)
            .catch(e => console.error(e))
            .finally(() => {
              changeAction(ASSET_MAP_ACTIONS.SELECT_GLOBAL_POLYGON, {uuid: undefined});
              setIsMapLoading(false);
              setCurrentDrawMode(undefined);
            });
        }
      }
    });
  
  useEffect(() => {
    if (componentWillMountRef.current) {
      if (drawControlsBoxRef.current) {
        L.DomEvent.disableClickPropagation(drawControlsBoxRef.current);
        L.DomEvent.disableScrollPropagation(drawControlsBoxRef.current);
      }
      onComponentMount?.();
      componentWillMountRef.current = false;
    }
    return () => {
      componentWillMountRef.current = true;
    };
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    drawnGeometriesRef.current = drawnGeometries;
  }, [drawnGeometries]);
  useEffect(() => {
    currentDrawModeRef.current = currentDrawMode;
  }, [currentDrawMode]);
  useEffect(() => {
    currentDrawToolRef.current = currentDrawTool;
  }, [currentDrawTool]);
  
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgContainerDisabled: '#F5F5F5',
        },
      }}
    >
      <>
        {contextHolder}
        {
          drawnGeometries
            ?.filter(geometry =>
              currentDrawMode === 'edit'
              && currentAction === ASSET_MAP_ACTIONS.SELECT_GLOBAL_POLYGON
              && actionEvent.uuid
                ? geometry.uuid !== actionEvent.uuid
                : true)
            .map((geometry, index) => (
              <CustomPolygon
                positions={flipTurfFeatureCoordinates(multiPolygon(geometry.coordinates)).geometry.coordinates as L.LatLngExpression[][][]}
                pathOptions={currentAction === ASSET_MAP_ACTIONS.SELECT_GLOBAL_POLYGON && actionEvent?.uuid === geometry.uuid ? ({
                  ...geometry.pathOptions,
                  opacity: 1,
                  dashArray: '12, 12',
                  dashOffset: '0',
                  weight: 3,
                  fillOpacity: 0.5,
                }) : ({
                  weight: 2,
                  ...geometry.pathOptions,
                })}
                key={`${geometry.uuid}_${index}_${actionEvent?.uuid === geometry.uuid ? 'selected' : ''}`}
              />
            ))}
        <Pane name={'measurements'}>
          {measurementLines.flatMap(polyline =>
            polyline.coordinates.slice(0, -1).map((coord, index) =>
              (
                {
                  shape: L.polyline([
                    latLng(coord[1], coord[0]),
                    latLng(polyline.coordinates[index + 1][1], polyline.coordinates[index + 1][0]),
                  ]), id: polyline.id,
                }
              ),
            ),
          ).map((line, index) =>
            <RulerMeasurement
              key={index}
              line={line.shape}
              id={index}
              isHighlighted={selectedMeasurementId === line.id}
            />,
          )}
        </Pane>
        <FeatureGroup>
          
          <EditControl
            position={'topright'}
            draw={{
              polyline: true,
              polygon: {allowIntersection: false},
              rectangle: false,
              circle: true,
              marker: false,
              circlemarker: false,
            }}
            edit={{edit: false}}
            {...editControlHandlers}
            onCreated={handleShapeCreation}
            onMounted={(e: L.Control) => {
              leafletDrawControlsRef.current = e;
              editControlHandlers?.onMounted?.(e);
            }}
          />
        </FeatureGroup>
        {
          currentDrawMode === 'edit'
          && currentAction === ASSET_MAP_ACTIONS.SELECT_GLOBAL_POLYGON
          && drawnGeometries?.find(geometry => geometry.uuid === actionEvent?.uuid)
          && onAfterShapeEdit
          && actionEvent?.uuid && (
            <EditableFeature
              onEditSave={onAfterShapeEdit}
              disableEditMode={() => {
                discardAndDisableEditMode();
                setCurrentDrawMode(undefined);
              }}
              geometry={drawnGeometries?.find(geometry => geometry.uuid === actionEvent?.uuid)}
              isCircle={drawnGeometries?.find(geometry => geometry.uuid === actionEvent?.uuid)?.coordinates.flat(2).length === 65}
              ref={editableLeafletFeatureDrawControlsRef}
            />
          )}
        <div
          ref={drawControlsBoxRef}
          style={{
            position: 'relative',
            top: '1rem',
            left: '1.5rem',
            zIndex: 1000,
            paddingBottom: '0.5rem',
            display: 'block',
            width: 'fit-content',
          }}
        >
          <Space
            direction={'vertical'}
            size={'small'}
          >
            {toolOptions && (
              <Space.Compact direction={'vertical'}>
                {toolOptions.map((option, key) => (
                  <div key={key}>
                    {option.component}
                  </div>
                ))}
              </Space.Compact>
            )}
            {modeOptions && (
              <Space.Compact direction={'vertical'}>
                {modeOptions.map((option, key) => (
                  <div key={key}>
                    {option.component}
                  </div>
                ))}
              </Space.Compact>
            )}
          </Space>
        </div>
      </>
    </ConfigProvider>
  );
};

export default NewDrawControls;