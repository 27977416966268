import React, {createContext, PropsWithChildren, useContext, useEffect, useState} from 'react';
import {AssetLocation, AssetLocationContextType} from '../../types/internal/AddAssetForm';
import {useAssetDetails} from '../assetDetails/AssetDetails';

const EditLocationContext = createContext<AssetLocationContextType>(
  [
    {
      country: null,
      admin_level_1: null,
      admin_level_2: null,
      admin_level_3: null,
      locality: null,
      address: null,
      postal_code: null,
      position: null,
      geocoding_position: null,
    },
    () => null,
  ]);
export const useEditLocationContext = () => useContext(EditLocationContext);

export const EditAssetLocationContext = (props: PropsWithChildren) => {
  
  const [, {asset, loading: isAssetLoading}] = useAssetDetails();
  
  const [location, setLocation] = useState<AssetLocation>({
    country: null,
    admin_level_1: null,
    admin_level_2: null,
    admin_level_3: null,
    locality: null,
    address: null,
    postal_code: null,
    position: null,
    geocoding_position: null,
  });
  
  useEffect(() => {
    if (!isAssetLoading && asset) {
      setLocation(location => ({
        country: asset.country || location.country,
        admin_level_1: asset.admin_level_1 || location.admin_level_1,
        admin_level_2: asset.admin_level_2 || location.admin_level_2,
        admin_level_3: asset.admin_level_3 || location.admin_level_3,
        locality: asset.locality || location.locality,
        address: asset.address || location.address,
        postal_code: asset.postal_code || location.postal_code,
        position: asset.position ? [asset.position.lat, asset.position?.lng] : null,
        geocoding_position: null,
      }));
    }
  }, [asset, isAssetLoading]);
  
  return (
    <EditLocationContext.Provider value={[location, setLocation]}>
      {props.children}
    </EditLocationContext.Provider>
  );
};