import {Button, ButtonProps} from 'antd';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';

export default function ManageAlertButton(button: ButtonProps) {

  const {t} = useTranslation();

  return <Button {...button}>
    {capitalize(t('alerts.manageAlert'))}
  </Button>;
}