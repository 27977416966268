import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import ResponsiveContent from '../../../layout/ResponsiveContent';
import {Col, Row, Tabs} from 'antd';
import {capitalize} from 'lodash';
import {ImportReportErrorTableView} from './tableViews/ImportReportErrorTableView';
import {ImportReportWarningTableView} from './tableViews/ImportReportWarningTableView';
import {ImportReportOkTableView} from './tableViews/ImportReportOkTableView';
import ImportReportDownload from "../../../components/settings/importReport/ImportReportDownload";

export type ReportDetailStatus = 'ERROR' | 'WARNING' | 'OK'

export default function ImportReport() {

  const {t} = useTranslation();
  const navigate = useNavigate();

  const [
    selectedView,
    setSelectedView,
  ] = useState<ReportDetailStatus>('ERROR');

  return (
    <ResponsiveContent
      title={t('dataImport.detailIssues')}
      goBack={() => navigate('/settings/import-report')}
      goBackText={t('settings.dataImport')}
      extra={<ImportReportDownload />}
    >
      <Row>
        <Col>
          <Tabs
            type={'card'}
            items={[
              {key: 'ERROR', label: capitalize(t('dataImport.error', {count: 2}))},
              {key: 'WARNING', label: capitalize(t('dataImport.warning', {count: 2}))},
              {key: 'OK', label: capitalize(t('dataImport.success'))},
            ]}
            onChange={(key) => setSelectedView(key as ReportDetailStatus)}
            defaultValue={'ERROR'}
          />
        </Col>
      </Row>
      <Row>
        <Col flex={1}>
          {selectedView === 'ERROR' && <ImportReportErrorTableView/>}
          {selectedView === 'WARNING' && <ImportReportWarningTableView/>}
          {selectedView === 'OK' && <ImportReportOkTableView/>}
        </Col>
      </Row>
    </ResponsiveContent>
  );
}