import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {
  useGetAlertQuery,
} from '../../../redux/api/alerts';
import { useEffect} from 'react';
import ResponsiveContent from '../../../layout/ResponsiveContent';
import AlertStepsEdit from "../alertSteps/AlertStepsEdit";
import {AlertStepsContext} from "../../../context/alertSteps/AlertStepsContext";
import {skipToken} from "@reduxjs/toolkit/query";

export default function AlertEdit() {

  const {t} = useTranslation();
  const {uuid} = useParams();
  const navigate = useNavigate();

  const {data: alert,} = useGetAlertQuery(uuid ? {uuid} : skipToken);

  useEffect(() => {
    if (alert && !alert.is_user_created) {
      navigate(`/alerts/${alert.uuid}`)
    }
  }, [alert, navigate]);


  return (
    <ResponsiveContent
      title={t('actions.editItem', {item: t('alerts.alert')})}
      goBack={() => navigate(`/alerts/${uuid}`)}
      goBackText={t('alerts.alert', {count: 1})}
      containerStyle={{gap: '0.75rem'}}
    >
      <AlertStepsContext>
        <AlertStepsEdit/>
      </AlertStepsContext>
    </ResponsiveContent>
  );
}