import {Button, Col, Modal, Row, StepProps, Steps, Typography} from "antd";
import React, {ReactElement, useEffect, useState} from "react";
import {capitalize} from "lodash";
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";
import AlertDetailForm from "../form/AlertDetailForm";
import {useForm} from "antd/lib/form/Form";
import AlertStepMap from "./AlertStepMap";
import AlertGeoshapesSelectAndList from "./AlertGeoshapesSelectAndList";
import {useAlertStepsContext} from "../../../context/alertSteps/AlertStepsContext";
import {useCreateAlertMutation} from "../../../redux/api/alerts";
import {useNavigate} from "react-router-dom";
import {useMessageContext} from "../../../context/message/MessageContext";
import {CreateAlertParams} from "../../../types/api/alerts";
import {AlertCreationForm} from "../../../types/internal/Alerts";
import {parseAlertFormForBe} from "../../../utils/parser";

const {Paragraph} = Typography;

export default function AlertStepsCreation() {

  const {t} = useTranslation();
  const navigate = useNavigate();
  const setMessage = useMessageContext();
  const [form] = useForm();
  const [formValues, setFormValues] = useState<AlertCreationForm>()
  const {drawnGeometries} = useAlertStepsContext()

  const [step, setStep] = useState(0);
  const [isNavigationModalOpen, setIsNavigationModalOpen,] = useState(false);
  const [
    createAlert,
    {
      data,
      isLoading,
      isSuccess,
      isError,
    },
  ] = useCreateAlertMutation();
  const next = () => {
    setStep(curr => curr + 1);
  };
  const prev = () => {
    setStep(curr => curr - 1);
  };

  const isButtonDisabled = isLoading || !drawnGeometries.length || !formValues;

  const handleFinish = async () => {
    if (formValues && drawnGeometries.length) {
      createAlert({
        ...parseAlertFormForBe(formValues),
        alert_polygons: drawnGeometries.map(el => {
          if (el.drawCount) {
            return {
              'data': null,
              'geoshape': {type: 'MultiPolygon', coordinates: el.coordinates},
              'globalpolygon': null,
            }
          } else {
            return {
              'data': null,
              'geoshape': null,
              'globalpolygon': el.uuid,
            }
          }
        }),
      });
    }
  }

  const steps: (StepProps & { content: ReactElement })[] = [
    {
      title: capitalize(t('alerts.detail')),
      content: <AlertDetailForm
        form={{
          form: form,
          initialValues: {
            start_datetime: dayjs().startOf('day'),
          },
        }}
      />
    },
    {
      title: capitalize(t('alerts.geoArea')),
      content: <>
        <AlertStepMap/>
        <AlertGeoshapesSelectAndList/>
      </>
    },
  ]

  const handleFormValidation = () => {
    form.validateFields()
      .then((values) => {
        setFormValues(values)
        next()
      })
      .catch(() => console.log('form validation error'))
  }


  useEffect(() => {
    if (isSuccess && data?.uuid) {
      setIsNavigationModalOpen(true);
    }
    return () => setIsNavigationModalOpen(false);
  }, [data?.uuid, isSuccess]);

  useEffect(() => {
    if (isError) {
      setMessage({error: capitalize(t('form.creationFailMessage'))});
    }
  }, [isError, setMessage, t]);

  return <>
    <Steps
      current={step}
      items={steps}
      onChange={v => {
        if (v === 1) {
          handleFormValidation()
        } else {
          setStep(v)
        }
      }}
    />
    {steps[step].content}
    <Row
      justify={'end'}
    >
      {step > 0 && (
        <Col>
          <Button
            style={{margin: '0 8px'}} onClick={() => prev()}>
            {capitalize(t('form.previous'))}
          </Button>
        </Col>
      )}
      {step < steps.length - 1 && (
        <Col>
          <Button
            type="primary"
            onClick={() => {
              if (step === 0) {
                handleFormValidation()
              } else {
                next()
              }
            }}>
            {capitalize(t('form.next'))}
          </Button>
        </Col>
      )}
      {step === steps.length - 1 && (
        <Col>
          <Button
            loading={isLoading}
            disabled={isButtonDisabled}
            type={'primary'}
            onClick={handleFinish}
          >
            {capitalize(t('actions.createItem', {item: t('alerts.alert')}))}
          </Button>
        </Col>
      )}
    </Row>
    <Modal
      closable={false}
      open={isNavigationModalOpen}
      onCancel={() => {
        if (data)
          navigate(`/alerts/${data.uuid}`);
      }}
      footer={(
        <Row justify={'space-between'} style={{marginTop: '1rem'}}>
          <Col>
            <Button
              onClick={() => {
                if (data)
                  navigate(`/alerts/${data.uuid}/`);
              }}
            >
              {capitalize(t('actions.goToDestination', {destination: t('alerts.alert', {count: 1})}))}
            </Button>
          </Col>
          <Col>
            <Button
              type={'primary'}
              onClick={() => {
                if (data)
                  navigate(`/alerts/${data.uuid}/documents`);
              }}
            >
              {capitalize(t('actions.insertItem', {item: t('documents.document', {count: 2})}))}
            </Button>
          </Col>
        </Row>
      )}
    >
      <Paragraph>
        {capitalize(t('form.creationSuccessMessage'))}
      </Paragraph>
      <Paragraph>
        {capitalize(t('alerts.manageAlertDescription'))}
      </Paragraph>
    </Modal>
  </>
}