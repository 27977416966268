import EventTypeIcons from './EventTypeIcons';
import EventTypeNamesForm from './EventTypeNamesForm';
import React, {useEffect, useState} from 'react';
import {EventTypeForm as Type} from '../../../../types/internal/eventTypes';
import {Button, Divider} from 'antd';
import {EVENT_TYPE_DEFAULT_VALUES} from '../EventTypeDrawer';
import {useGetIconsNotPaginatedQuery} from '../../../../redux/api/icons';
import {capitalize} from 'lodash';
import {useCreateEventTypeMutation} from '../../../../redux/api/eventTypes';
import {useTranslation} from 'react-i18next';
import {EventType} from '../../../../types/api/eventTypes';
import {useMessageContext} from '../../../../context/message/MessageContext';

export default function EventTypeForm({onFinish}: { onFinish: (values: EventType) => void }) {

  const [eventValue, setEventValue] = useState<Type>(EVENT_TYPE_DEFAULT_VALUES);
  const [create] = useCreateEventTypeMutation();
  const {t} = useTranslation();
  const setMessage = useMessageContext();
  const {data: icons} = useGetIconsNotPaginatedQuery();
  const disabled = !eventValue.name_it || !eventValue.name_en || !eventValue.icon;

  useEffect(() => {
    setEventValue({...EVENT_TYPE_DEFAULT_VALUES, icon: (icons?.length && icons[0].uuid) || null});
  }, [icons]);

  const handleCreate = async () => {
    if (!disabled) {
      try {
        const values = await create(eventValue).unwrap();
        onFinish(values);
      } catch {
        setMessage({error: t('form.creationFailMessage')});
      }
    }
  };

  return <div style={{display: 'flex', flexDirection: 'column', gap: '2rem'}}>
    <EventTypeNamesForm
      eventValue={eventValue}
      setEventValue={setEventValue}
    />
    <EventTypeIcons
      eventValue={eventValue}
      setEventValue={setEventValue}
    />
    <div style={{textAlign: 'right'}}>
      <Divider/>
      <Button
        disabled={disabled}
        type={'primary'}
        onClick={handleCreate}
      >
        {capitalize(t('actions.create'))}
      </Button>
    </div>
  </div>;
}