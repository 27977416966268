import {Button, ButtonProps} from 'antd';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
import {useManageAlertMutation} from '../../redux/api/alerts';
import React, {useState} from 'react';
import {useMessageContext} from '../../context/message/MessageContext';
import DeleteModalMessage from '../common/documents/modals/DeleteModalMessage';
import PermissionRoute from '../Authentication/PermissionRoute';
import {isDeassignAlertToEvent} from '../Authentication/permissions';

export default function MoveAlertsToBeManagedButton({selectedAlerts, afterRemove, button}: {
  afterRemove?: () => void,
  selectedAlerts: React.Key[],
  button?: ButtonProps
}) {

  const {t} = useTranslation();
  const [setAlertToBeManaged] = useManageAlertMutation();
  const setMessage = useMessageContext();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleRemoveAlerts = async () => {
    try {
      const promises = selectedAlerts.map(uuid => setAlertToBeManaged({alertUuid: uuid as string}).unwrap());
      await Promise.all(promises);
      setMessage({success: capitalize(t('form.removeSuccessMessage'))});
    } catch {
      setMessage({error: capitalize(t('form.removeFailMessage'))});
    } finally {
      setIsModalOpen(false)
      if (afterRemove)
        afterRemove();
    }
  };

  return <PermissionRoute
    isAuthorized={isDeassignAlertToEvent}
  >
    <DeleteModalMessage
      title={{
        children: capitalize(t('alerts.setAlertToBeManaged')),
      }}
      paragraph={{
        children: t('alerts.setAlertToBeManagedDescription'),
      }}
      modal={{
        open: isModalOpen,
        onCancel: () => setIsModalOpen(false),
      }}
      confirmButton={{
        children: capitalize(t('actions.confirm')),
        onClick: handleRemoveAlerts,
      }}
    />
    <Button
      danger
      disabled={!selectedAlerts.length}
      onClick={() => setIsModalOpen(true)}
      {...button}
    >
      {capitalize(t('alerts.setAlertToBeManaged'))}
    </Button>
  </PermissionRoute>;
}