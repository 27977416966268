// FILTERS
import {Sorter} from './sorters';
import {GetAssetBiodiversityParams} from '../../api/assets';

const BiodiversityNumberFilters = [
  'distance',
  'name'
] as const;
export type BiodiversityNumberFilters = (typeof BiodiversityNumberFilters)[number];
const isBiodiversityNumberFilters = (x: any): x is BiodiversityNumberFilters => BiodiversityNumberFilters.includes(x);

export type BiodiversityFiltersFE = Partial<
  Record<BiodiversityNumberFilters, number>
>

export type BiodiversityFilters = (
  typeof BiodiversityNumberFilters)[number];
export const isBiodiversityFilters = (x: any): x is BiodiversityFilters => [
  ...BiodiversityNumberFilters].includes(x);

export function fromFeToBeBiodiversityTableFilters(tableFilters: BiodiversityFiltersFE) {
  let parsedFilters: Partial<GetAssetBiodiversityParams> = {};
  for (const [key, value] of Object.entries(tableFilters)) {
    if (
      isBiodiversityNumberFilters(key)) {
      parsedFilters = {...parsedFilters, [key as keyof GetAssetBiodiversityParams]: value};
    }
  }
  return parsedFilters;
}

export function getFiltersOfTheBiodiversityTableColumn(column: BiodiversityColumns) {
  const filters: BiodiversityFilters[] = [];
  switch (column) {
    default:
      filters.push(column)
      break;
  }
  return filters;
}

// COLUMNS
export const biodiversityColumns = [
  'distance',
  'name'
] as const;

export type BiodiversityColumns = (typeof biodiversityColumns)[number]
export const isBiodiversityColumns = (x: any): x is BiodiversityColumns => biodiversityColumns.includes(x);

// SORTERS
export type BiodiversitySortersFE = Partial<{
  [K in BiodiversityColumns]: Sorter
}>
