import CustomNumericFormat from './CustomNumericFormat';
import {NumericFormatProps} from 'react-number-format';

export default function CustomLatitudeNumericFormat(numericProps: NumericFormatProps) {
  return <CustomNumericFormat
    decimalScale={6}
    fixedDecimalScale={true}
    isAllowed={(values) => {
      const {floatValue} = values;
      return floatValue === undefined || (floatValue >= -90 && floatValue <= 90);
    }}
    {...numericProps}
  />;
}