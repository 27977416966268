import {PropsWithChildren} from 'react';
import {AlertDetails} from './AlertDetails';
import {AssetContext} from '../assetDetails/AssetContext';
import {AssetDrawerContext} from './AssetDrawerContext';
import {useParams} from "react-router-dom";

export const AlertContext = (props: PropsWithChildren) => {

  const {uuid: alertUuid} = useParams();

  return (
    <AlertDetails alertUuid={alertUuid}>
      <AssetDrawerContext>
        <AssetContext>
          {props.children}
        </AssetContext>
      </AssetDrawerContext>
    </AlertDetails>
  );
};