import {INITIAL_CENTER} from './constants/map';
import {MapContainer} from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import {GoogleLayers} from '../../../types/internal/Map';
import * as React from 'react';
import {CSSProperties, memo, PropsWithChildren} from 'react';

const MemoizedGoogleLayer = memo((props: {
  type?: 'roadmap' | 'satellite' | 'terrain' | 'hybrid',
  key: string,
  apiKey?: string
}) => <ReactLeafletGoogleLayer {...props}/>);

export const DummyMapContainer = memo(
  (props: {
    isHighlighted?: boolean,
    tileLayer: GoogleLayers
    withStyle?: CSSProperties,
  } & PropsWithChildren) => {
    return (
      <MapContainer
        center={INITIAL_CENTER}
        zoomControl={false}
        scrollWheelZoom={false}
        doubleClickZoom={false}
        dragging={false}
        zoom={7}
        className={'pointer-cursor-enabled'}
        attributionControl={false}
        style={
          {
            height: '100%',
            width: '100%',
            overflow: 'hidden',
            borderRadius: '6px',
            border: '2px solid',
            borderColor: props.isHighlighted === true ? '#1677ff' : '#FFF',
            ...props.withStyle,
          }
        }
      >
        <MemoizedGoogleLayer
          type={props.tileLayer}
          key={props.tileLayer}
          apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        />
        {props.children}
      </MapContainer>
    );
  });