import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '../store';

interface mapFiltersSlice {
  polygons: { uuid: string, globalpolygon: string }[],
}

const initialState = {
  polygons: [],
} as mapFiltersSlice;

export const mapFilters = createSlice({
  name: 'mapFilters',
  initialState,
  reducers: {
    addAssetsMapGeographicalFilter: (state, {payload}) => {
      state.polygons = [...state.polygons, payload];
    },
    addAssetsMapGeographicalFilters: (state, {payload}) => {
      state.polygons = [...state.polygons, ...payload];
    },
    removeAssetsMapGeographicalFilter: (state, {payload}) => {
      state.polygons = state.polygons.filter(polygon => polygon.uuid !== payload);
    },
    removeAssetsMapGeographicalFilters: (state, {payload}) => {
      state.polygons = state.polygons.filter(polygon => !payload.includes(polygon.uuid));
    },
    resetAssetsMapGeographicalFilter: (state) => {
      state.polygons = [];
    },
    setAssetsMapGeographicalFilters: (state, {payload}) => {
      state.polygons = payload;
    },
  },
});

export const {
  addAssetsMapGeographicalFilter,
  removeAssetsMapGeographicalFilter,
  resetAssetsMapGeographicalFilter,
  removeAssetsMapGeographicalFilters,
  addAssetsMapGeographicalFilters,
  setAssetsMapGeographicalFilters,
} = mapFilters.actions;

export const selectMapFilters = (state: RootState) => state.mapFilters;

export default mapFilters.reducer;