import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {OptionLabelValue} from '../../../../types/internal/OptionLabelValue';
import {useLazyGetAlertsForInfiniteScrollingQuery} from '../../../../redux/api/alerts';
import NEWListWithSearchFilter from '../../../common/filters/NEWListWithSearchFilter';

export const DocumentsAlertFilter = ({activeFilters, handleFilterChange}: {
  activeFilters: OptionLabelValue[] | null,
  handleFilterChange: (values: OptionLabelValue[]) => void,
}) => {

  const [
    getAlerts,
    {
      data,
      isLoading,
      isFetching,
      isUninitialized,
      originalArgs,
    },
  ] = useLazyGetAlertsForInfiniteScrollingQuery();

  const [querySearch, setQuerySearch] = useState<string>('');
  const handleGetNext = useCallback(() => {
    getAlerts({
      ordering: 'title',
      ...!!querySearch && {search_by_title: querySearch},
      page: (originalArgs?.page || 0) + 1,
      page_size: 15,
    });
  }, [getAlerts, originalArgs?.page, querySearch]);

  const options: OptionLabelValue[] = useMemo(() => data?.results.map(el => ({
    label: el.title,
    value: el.uuid,
  })) || [], [data?.results]);

  useEffect(() => {
    getAlerts({
      ordering: 'title',
      ...!!querySearch && {search_by_title: querySearch},
      page: 1,
      page_size: 15,
    });
  }, [getAlerts, querySearch]);

  return (
    <NEWListWithSearchFilter
      getNext={handleGetNext}
      options={options}
      selectedOptions={activeFilters || []}
      setSelectedOptions={handleFilterChange}
      total={data?.count || 0}
      isLoadingOptions={isLoading || isFetching || isUninitialized}
      querySearch={querySearch}
      setQuerySearch={setQuerySearch}
    />
  );
};