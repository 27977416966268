import {Select, Typography} from 'antd';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
import {CustomLoading} from '../CustomLoading';
import {OptionLabelValue} from '../../../types/internal/OptionLabelValue';
import Label from '../Label';
import {useGetRealEstateHubsQuery} from '../../../redux/api/realEstateHub';

const {Text} = Typography;
export const RealEstateHubSearchSelect = (props: {
  value: OptionLabelValue | null,
  onClear: () => void,
  onSelect: (value: { label: string, value: string }, option: { label: string, value: string }) => void,
  disabled?: boolean
}) => {

  const {t} = useTranslation();

  const {isLoading, isFetching, isError, data} = useGetRealEstateHubsQuery();

  const [
    options,
    setOptions,
  ] = useState<OptionLabelValue[]>([]);

  const [
    searchedHub,
    setSearchedHub,
  ] = useState<string>('');

  useEffect(() => {
    const filteredOptions: OptionLabelValue[] = data?.filter((el => el.name.toLowerCase().includes(searchedHub.toLowerCase())) || []).map(option => ({
      label: option.name,
      value: option.uuid,
    })) || [];
    setOptions(filteredOptions);
  }, [data, searchedHub]);

  return (
    <>
      <Label label={capitalize(t('assets.realestateHubName'))} htmlFor={'realestate_hub'}/>
      <Select
        placeholder={capitalize(t('assets.realestateHubName'))}
        id={'realestate_hub'}
        style={{marginBottom: 20, display: 'flex'}}
        allowClear={true}
        onClear={() => {
          props.onClear();
          setOptions([]);
        }}
        value={props.value}
        disabled={props.disabled === true}
        filterOption={false}
        onSearch={setSearchedHub}
        labelInValue={true}
        onSelect={props.onSelect}
        showSearch={true}
        notFoundContent={<div style={{padding: 5}}>
          {(isFetching || isLoading) && (
            <CustomLoading/>
          )}
          {!(isFetching || isLoading) && isError && (
            <Text type={'secondary'}>
              {capitalize(t('misc.somethingWentWrongDuringFetch'))}...
            </Text>
          )}
          {!(isFetching || isLoading) && !isError && searchedHub !== '' && (
            <Text type={'secondary'}>
              {capitalize(t('misc.noRealEstateHubAvailable'))}
            </Text>
          )}
          {!(isFetching || isLoading) && !isError && !searchedHub && (
            <Text type={'secondary'}>
              {capitalize(t('misc.startTypingToSearchRealEstateHub'))}...
            </Text>
          )}
        </div>}
        options={options}
      />
    </>
  );
};