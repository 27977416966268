import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import TemplateMailForm from './TemplateMailForm';
import {Form} from 'antd';
import {useMessageContext} from '../../../context/message/MessageContext';
import {useCreateTemplateEmailMutation} from '../../../redux/api/templateEmails';
import {useEffect, useState} from 'react';

export default function CreateTemplateMail() {

  const {t} = useTranslation();

  const [templateMailForm] = Form.useForm();
  const setMessage = useMessageContext();
  const [
    isDrawerOpen,
    setIsDrawerOpen,
  ] = useState(false);

  const [
    createTemplateEmail,
    {
      isSuccess: isCreationSuccess,
      isError: isCreationFailed,
      //isLoading: isCreationLoading,
    },
  ] = useCreateTemplateEmailMutation();

  useEffect(() => {
    if (isCreationSuccess) {
      setIsDrawerOpen(false);
    }
    if (isCreationFailed) {
      setMessage({error: capitalize(t('form.creationFailMessage'))});
    }
  }, [isCreationFailed, isCreationSuccess, setMessage, t]);

  return <TemplateMailForm
    title={capitalize(t('actions.createItem', {item: t('mailTemplate.mailTemplate')}))}
    drawer={{
      open: isDrawerOpen,
      onClose: () => {
        setIsDrawerOpen(false);
        templateMailForm.resetFields();
      },
    }}
    drawerButton={{
      children: capitalize(t('actions.insertItem', {item: t('mailTemplate.template')})),
      //disabled: true
      onClick: () => setIsDrawerOpen(true),
    }}
    form={{form: templateMailForm}}
    confirmButton={{
      children: capitalize(t('actions.createItem', {item: t('mailTemplate.template')})),
      onClick: () => {
        templateMailForm
          .validateFields()
          .then((values) => {
            console.log('values: ', values);
            createTemplateEmail(values);
            templateMailForm.resetFields();
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      },
    }}
  />;
}