import {CaretDownFilled, CaretUpFilled} from '@ant-design/icons';
import React from 'react';
import {Sorter} from '../../types/internal/tables/sorters';

export default function TableSorter(
  {value, onChange}: { value: Sorter, onChange: (type: Sorter) => void },
) {

  const handleClick = () => {
    switch (value) {
      case null:
        onChange('ascend');
        break;
      case 'ascend':
        onChange('descend');
        break;
      case 'descend':
        onChange(null);
        break;
    }
  };

  return <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      cursor: 'pointer',
    }}
    onClick={handleClick}
  >
    <CaretUpFilled
      style={{
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        opacity: '25%',
        ...{...(value === 'ascend' && {color: '#1677ff', opacity: ''})},
      }}
    />
    <CaretDownFilled
      style={{
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        opacity: '25%',
        ...{...(value === 'descend' && {color: '#1677ff', opacity: ''})},
      }}
    />
  </div>;
}