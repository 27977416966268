import {configureStore} from '@reduxjs/toolkit';
import auth from './slices/auth';
import {apiSlice} from './api/baseApi';
import layout from './slices/layout';
import alerts from './slices/alerts';
import assets from './slices/assets';
import events from './slices/events';
import mapFilters from './slices/mapFilters';
import messages from './slices/messages';
import documents from './slices/documents';
import importReport from './slices/importReport';
import templateMail from './slices/templateMails';

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [apiSlice.reducerPath]: apiSlice.reducer,
    alerts,
    assets,
    events,
    auth,
    layout,
    mapFilters,
    messages,
    documents,
    importReport,
    templateMail,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  // hide devTools for production
  devTools: process.env.NODE_ENV !== 'production',
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {counter: CounterState}
export type AppDispatch = typeof store.dispatch