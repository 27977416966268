import {Button, Card, Col, Form, FormProps, Input, message, Row} from 'antd';
import {useLoginMutation} from '../redux/api/authApiSlice';
import {AUTH_FROM_LOGIN, redirect, setRefreshToken} from '../components/Authentication/authUtils';
import {setAccessToken} from '../redux/slices/auth';
import {useDispatch} from 'react-redux';
import SimpleBackgroundLayout from '../layout/SimpleBackgroundLayout';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
import riskAppLogo from '../assets/images/riskapp_logo_no_bg.png';

type LoginFormProps = {
  username: string,
  password: string
}
export default function Login() {

  const [login] = useLoginMutation();
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const {t} = useTranslation();

  const messagePopup = (type: 'error' | 'success') => {
    messageApi.open({
      type: type,
      content: capitalize(t(`authentication.auth${capitalize(type)}`)),
    });
  };
  const onFinish: FormProps['onFinish'] = async (values) => {
    try {
      const response = await login(values).unwrap();
      setRefreshToken(response.refresh);
      dispatch(setAccessToken({token: response.access}));
      sessionStorage.setItem(AUTH_FROM_LOGIN, 'true');
      redirect();
      messagePopup('success');
    } catch {
      messagePopup('error');
      console.error('login error');
    }
  };

  const authenticationUrl = process.env.REACT_APP_AUTHENTICATION_REDIRECT_URL;

  return (
    <>
      {contextHolder}
      <SimpleBackgroundLayout>
        <Card title={null}>
          <Row justify={'center'} style={{marginBottom: '1.5rem'}}>
            <Col>
              <img src={riskAppLogo} alt={'riskapp logo'} width={150}/>
            </Col>
          </Row>
          <Form
            <LoginFormProps>
            title={'login'}
            layout={'vertical'}
            onFinishFailed={() => console.log('test')}
            onFinish={onFinish}
          >
            <Form.Item
              <string>
              name={'username'}
              label={capitalize(t('user.username'))}
              rules={[{required: true}]}
            >
              <Input/>
            </Form.Item>
            <Form.Item
              <string>
              name={'password'}
              label={capitalize(t('form.password'))}
              rules={[{required: true}]}
            >
              <Input.Password/>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" block style={{marginTop: '1rem'}}>
                {capitalize(t('authentication.login'))}
              </Button>
            </Form.Item>
          </Form>
          <Button
            block
            disabled={!authenticationUrl}
            onClick={() => {
              if (authenticationUrl)
                window.location.replace(authenticationUrl);
            }}
          >
            {t('authentication.sso').toUpperCase()}
          </Button>
        </Card>
      </SimpleBackgroundLayout>
    </>);
}