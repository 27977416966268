import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '../store';
import {PaginationConfig} from 'antd/lib/pagination';
import {
  ArchivedEventsSortersFE,
  EventsFilters,
  EventsFiltersFE,
} from '../../types/internal/tables/archivedEventsTable';

// Define a type for the slice state
type EventsSlice = {
  archivedEventsTable: {
    filters: EventsFiltersFE,
    sorters: ArchivedEventsSortersFE,
    pagination: PaginationConfig,
  },
}

// Define the initial state using that type
const initialState: EventsSlice = {
  archivedEventsTable: {
    filters: {},
    sorters: {},
    pagination: {
      current: 1,
      pageSize: +(window.sessionStorage.getItem('archivedEvents_page_size') || 10),
    },
  },
};

export const eventsSlice = createSlice({
  name: 'events',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setArchivedEventsTableFilters: (state, {payload}: PayloadAction<EventsFiltersFE>) => {
      state.archivedEventsTable.filters = payload;
    },
    removeArchivedEventsTableFilters: (state, {payload}: PayloadAction<EventsFilters[] | EventsFilters>) => {
      const updatedFilters = {...state.archivedEventsTable.filters};
      if (Array.isArray(payload)) {
        payload.forEach(el => {
          delete updatedFilters[el];
        });
      } else {
        delete updatedFilters[payload];
      }
      state.archivedEventsTable = {
        ...state.archivedEventsTable,
        filters: updatedFilters,
        pagination: {
          ...state.archivedEventsTable.pagination,
          current: 1,
        },
      };
    },
    
    addArchivedEventsTableFilters: (state, {payload}: PayloadAction<EventsFiltersFE>) => {
      state.archivedEventsTable = {
        ...state.archivedEventsTable,
        filters: {
          ...state.archivedEventsTable.filters,
          ...payload,
        },
        pagination: {
          ...state.archivedEventsTable.pagination,
          current: 1,
        },
      };
    },
    
    setArchivedEventsTableSorters: (state, {payload}: PayloadAction<ArchivedEventsSortersFE>) => {
      state.archivedEventsTable.sorters = payload;
    },
    removeArchivedEventsTableSorters: (state, {payload}: PayloadAction<keyof ArchivedEventsSortersFE | (keyof ArchivedEventsSortersFE)[]>) => {
      if (Array.isArray(payload)) {
        payload.forEach(el => delete state.archivedEventsTable.sorters[el]);
      } else {
        delete state.archivedEventsTable.sorters[payload];
      }
    },
    addArchivedEventsTableSorters: (state, {payload}: PayloadAction<ArchivedEventsSortersFE>) => {
      state.archivedEventsTable.sorters = {...state.archivedEventsTable.sorters, ...payload};
    },
    resetArchivedEventsTableFiltersSorters: (state) => {
      state.archivedEventsTable = {
        ...state.archivedEventsTable,
        filters: {},
        sorters: {},
        pagination: {...state.archivedEventsTable.pagination, current: 1},
      };
    },
    setArchivedEventsTablePagination: (state, {payload}: PayloadAction<Partial<PaginationConfig>>) => {
      state.archivedEventsTable.pagination = payload;
      if (payload.pageSize) {
        window.sessionStorage.setItem('archivedEvents_page_size', `${payload.pageSize}`);
      }
    },
  },
});

export const {
  addArchivedEventsTableFilters,
  removeArchivedEventsTableFilters,
  addArchivedEventsTableSorters,
  setArchivedEventsTableSorters,
  removeArchivedEventsTableSorters,
  resetArchivedEventsTableFiltersSorters,
  setArchivedEventsTablePagination,
} = eventsSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectEvents = (state: RootState) => state.events;
export const selectArchivedEventsTableFilters = (state: RootState) => state.events.archivedEventsTable.filters;
export const selectArchivedEventsTableSorters = (state: RootState) => state.events.archivedEventsTable.sorters;
export const selectArchivedEventsTablePagination = (state: RootState) => state.events.archivedEventsTable.pagination;

export default eventsSlice.reducer;
