import _, {capitalize} from 'lodash';
import {AutoComplete, Typography} from 'antd';
import {SearchOutlined} from '@ant-design/icons';
import {CustomLoading} from '../CustomLoading';
import {useLazyGetReverseGeocodeQuery} from '../../../redux/api/geocoding';
import {useTranslation} from 'react-i18next';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {DefaultOptionType} from 'antd/es/select';
import Label from '../Label';

const {Text} = Typography;
export const ReverseGeocodingSearchSelect = (props: {
  onSelect: (value: string, option: DefaultOptionType) => void,
  disabled?: boolean,
}) => {

  const {t} = useTranslation();

  const [
    getReverseGeocoding,
    {
      isError: hasReverseGeocodeFailed,
      isLoading: isReverseGeocodeLoading,
      isFetching: isReverseGeocodeFetching,
      data: geocodeData,
    },
  ] = useLazyGetReverseGeocodeQuery();

  const [searchedCoordinates, setSearchedCoordinates] = useState<string>('');
  const [suggestedLocations, setSuggestedLocations] = useState<google.maps.GeocoderResult[]>([]);

  const [isValidCoordinate, setIsValidCoordinate] = useState(true);

  const validateCoordinates = useCallback((string: string) => {
    const coordinatePattern = /([-+]?\d+(\.\d+)?)[\s,]+([-+]?\d+(\.\d+)?)/;
    const match = string.match(coordinatePattern);
    if (
      match &&
      !isNaN(parseFloat(match[1])) &&
      !isNaN(parseFloat(match[3])) &&
      !(parseFloat(match[1]) < -90) &&
      !(parseFloat(match[1]) > 90) &&
      !(parseFloat(match[3]) < -180) &&
      !(parseFloat(match[3]) > 180)
    ) {
      const latitude = parseFloat(match[1]);
      const longitude = parseFloat(match[3]);
      setIsValidCoordinate(true);
      return [latitude, longitude];
    } else {
      setIsValidCoordinate(false);
    }
  }, []);

  const debouncedSearch = useMemo(() =>
    _.debounce(
      async (value: string) => {
        if (value) {
          const coords = validateCoordinates(value);
          if (coords) {
            const [lat, lon] = coords;
            getReverseGeocoding({lat, lon});
          }

        }
      }, 400), [getReverseGeocoding, validateCoordinates]);

  useEffect(() => {
    if (geocodeData)
      setSuggestedLocations(geocodeData.results);
  }, [geocodeData]);

  return (
    <>
      <Label label={capitalize(t('address.searchByCoordinates'))} htmlFor={'searchCoordinates'}/>
      <AutoComplete
        style={{display: 'flex'}}
        disabled={props.disabled === true}
        allowClear={true}
        options={suggestedLocations?.map((item, index) => (
          {
            key: index,
            label: item.formatted_address,
            value: item.formatted_address,
            ...item,
          }
        ))}
        placeholder={`${capitalize(t('address.latitude'))}, ${t('address.longitude')}`}
        status={isValidCoordinate ? '' : 'error'}
        value={searchedCoordinates}
        onChange={setSearchedCoordinates}
        id={'searchCoordinates'}
        suffixIcon={<SearchOutlined/>}
        // @ts-ignore wrong type declaration from antd (autocomplete is a base inputComponent)
        autoComplete={'off'}
        getPopupContainer={triggerNode => triggerNode.parentElement}
        filterOption={false}
        notFoundContent={(
          <div style={{padding: 5}}>
            {
              suggestedLocations.length === 0 &&
              !hasReverseGeocodeFailed &&
              !isReverseGeocodeFetching &&
              !isReverseGeocodeLoading && (
                searchedCoordinates ? (
                  <Text type={'secondary'}>
                    {`${capitalize(t('form.noResults'))}...`}
                  </Text>
                ) : (
                  <Text type={'secondary'}>
                    {`${capitalize(t('form.typeToSearch'))}...`}
                  </Text>
                ))}
            {
              (isReverseGeocodeLoading || isReverseGeocodeFetching) && (
                <CustomLoading/>
              )}
            {
              hasReverseGeocodeFailed && (
                <Text type={'secondary'}>
                  {`${capitalize(t('misc.somethingWentWrongDuringFetch'))}...`}
                </Text>
              )}
          </div>
        )}
        onSearch={debouncedSearch}
        onSelect={props.onSelect}
      />
    </>
  );
};