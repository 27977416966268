import {Avatar, Card, Pagination, Space, Table, Typography} from 'antd';
import {useNavigate} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {useLazyGetEventTypesPaginatedQuery} from '../../../redux/api/eventTypes';
import {PaginationConfig} from 'antd/lib/pagination';
import {useTranslation} from 'react-i18next';
import {EventType} from '../../../types/api/eventTypes';
import {ColumnsType} from 'antd/lib/table';

export default function EventsTypesTable() {
  const navigate = useNavigate();
  const [getData, {data, isFetching, isUninitialized}] = useLazyGetEventTypesPaginatedQuery();
  const {t} = useTranslation();

  const [pagination, setPagination] = useState<PaginationConfig>({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    showTotal: (total, range) => t('table.pageSizeOfTotal', {
      rangeStart: range[0],
      rangeEnd: range[1],
      total,
    }),
    onChange: (page, pageSize) => setPagination(prevPag => ({...prevPag, current: page, pageSize})),
    style: {textAlign: 'center', marginTop: '40px'},
  });

  useEffect(() => {
    getData({page: pagination.current, page_size: pagination.pageSize});
  }, [getData, pagination]);

  const columns: ColumnsType<EventType> = [
    {
      key: 'colorName',
      render: (value, record) => {
        const src = !!process.env.REACT_APP_MEDIA && !!record.icon?.svg ? `${process.env.REACT_APP_MEDIA}${record.icon.svg}` : undefined;
        return <Space>
          <Avatar
            src={<img src={src} alt={`icon_${record.name}`} style={{padding: '5px'}}/>}
          />
          <Typography.Title level={4} style={{margin: 0}}>{record.name}</Typography.Title>
        </Space>;
      },
    },
    {
      key: 'cluster_templates',
      dataIndex: 'cluster_templates',
      render: (v) => `${v} ${t('clusters.cluster', {count: v})}`,
    },
    {
      key: 'documents',
      dataIndex: 'documents',
      render: (v) => `${v} ${t('documents.document', {count: v})}`,
    },
  ];

  return <Card title={null}>
    <Table
      <EventType>
      columns={columns}
      dataSource={data?.results}
      rowKey={'uuid'}
      showHeader={false}
      pagination={false}
      loading={isFetching || isUninitialized}
      onRow={(record) => (
        {
          onClick: (e) => {
            navigate(`${record.uuid}`);
          },
          style: {
            cursor: 'pointer',
          },
        })
      }
    />
    {!!data?.count && (
      <Pagination
        {...pagination}
        total={data?.count}
      />)}
  </Card>;
}