import {NumericFormat, NumericFormatProps} from 'react-number-format';
import {useGetUserProfileQuery} from '../../../redux/api/locales';
import {Input} from 'antd';

export default function CustomNumericFormat(numericProps: NumericFormatProps) {
  const {data} = useGetUserProfileQuery();
  let props: NumericFormatProps<any> = {
    customInput: Input,
    ...numericProps,
  };

  if (data?.language === 'it') {
    props = {
      thousandSeparator: '.',
      decimalSeparator: ',',
      ...props,
    };
  }

  return <NumericFormat
    {...props}
  />;
}