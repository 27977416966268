import {apiSlice} from './baseApi';
import {
  GetPaginatedImportReports,
  ImportReport,
  ImportReportDetail,
  ImportReportDetailAsset,
} from '../../utils/dataImport';
import {GenericPaginatedApi, GenericPaginatedParamsApi} from '../../types/api/paginatedApi';
import {downloadFileResponseHandler} from '../../utils/download';

export type ImportReportParamApi = {
  address?: string,
  adm_level_1?: string,
  adm_level_2?: string,
  adm_level_3?: string,
  country?: string,
  is_resolved?: string,
  operation?: string,
  ordering?: string,
  sigi_code?: string,
  status?: string,
  report_uuid?: string
}

const importReport = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getImportReports: build.query<GetPaginatedImportReports, {
      page?: number,
      page_size?: number,
      status?: 'E' | 'OK' | 'P' | 'W'
    } | void>({
      query: (args) => ({
        url: '/import_report/',
        params: {
          ...args,
        },
      }),
    }),
    getImportReportsOptions: build.query<GenericPaginatedApi<ImportReport>, { uuid: string }>({
      query: (args) => ({
        url: `/import_report/${args.uuid}/detail/`,
        method: 'OPTIONS',
        params: {
          ...args,
        },
      }),
    }),
    getImportReport: build.query<GenericPaginatedApi<ImportReportDetail>, GenericPaginatedParamsApi & ImportReportParamApi>({
      query: (args) => {
        const {report_uuid, ...params} = args;
        return {
          url: `/import_report/${report_uuid}/detail/`,
          params: params,
        };
      },
    }),
    getImportReportDetail: build.query<ImportReportDetailAsset, {
      reportUuid: string,
      detailUuid: string,
      page?: number,
      pageSize?: number
    }>({
      query: (args) => {
        const {reportUuid, detailUuid, ...params} = args;
        return {
          url: `/import_report/${reportUuid}/detail/${detailUuid}/`,
          params: params,
        };
      },
    }),
    getImportReportsStatistics: build.query<{ errors: number, warnings: number }, void>({
      query: (args) => ({
        url: '/import_report/statistics/',
      }),
    }),
    downloadImportReport: build.query<void, void>({
      query: () => {
        return {
          url: '/import_report/download/',
          responseHandler: downloadFileResponseHandler,
          cache: 'no-cache',
        };
      },
    }),
  }),
});

export const {
  useLazyGetImportReportsQuery,
  useGetImportReportsQuery,
  useLazyGetImportReportQuery,
  useGetImportReportDetailQuery,
  useGetImportReportsStatisticsQuery,
  useLazyGetImportReportsOptionsQuery,
  useLazyDownloadImportReportQuery,
} = importReport;