import React from "react";
import {Button, ButtonProps} from "antd";
import {useTranslation} from "react-i18next";
import {
  useAddAlertGroupToEventMutation,
} from "../../../redux/api/alerts";
import {useMessageContext} from "../../../context/message/MessageContext";
import {capitalize} from "lodash";

export default function ConnectAlertGroupToEvent({alertGroupUuid, eventUuid, button, afterConnect}: {
  alertGroupUuid: string,
  eventUuid: string,
  button?: ButtonProps,
  afterConnect?: () => void
}) {

  const {t} = useTranslation();
  const setMessage = useMessageContext();
  const [addAlertGroupToEvent, {isLoading}] = useAddAlertGroupToEventMutation()

  const handleConnect = async () => {
    try {
      await addAlertGroupToEvent({alertGroupUuid, eventUuid}).unwrap()
      setMessage({success: capitalize(t('alerts.connectToEventSuccessMessage'))});
    } catch {
      setMessage({error: capitalize(t('alerts.connectToEventFailMessage'))});
    } finally {
      if (afterConnect)
        afterConnect();
    }
  };

  return <Button
    type={'primary'}
    loading={isLoading}
    disabled={isLoading}
    onClick={handleConnect}
    {...button}
  >
    {capitalize(t('alerts.connectToEvent'))}
  </Button>;
}