import {Cluster, ClusterCreationParams, ClusterEditParams, ClusterEditTranslation} from '../../types/api/clusters';
import {apiSlice} from './baseApi';

const clustersApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getClusterTemplatesNotPaginated: build.query<Cluster[], { uuid: string }>({
      query: ({uuid}) => ({
        url: `/event-types/${uuid}/cluster-templates/`,
        params: {
          paginate: false,
        },
      }),
      providesTags: (res, error, arg) =>
        res
          ? [...res?.map(({uuid}) => ({type: 'ClusterTemplates' as const, id: uuid})), {type: 'EventTypes' as const, id: arg.uuid}, 'ClusterTemplates', 'Locale']
          : ['ClusterTemplates', 'Locale'],
    }),
    getClusterTranslation: build.query<ClusterEditTranslation, { uuid: string, eventUuid: string }>({
      query: ({uuid, eventUuid}) => ({
        url: `/event-types/${eventUuid}/cluster-templates/${uuid}/translations/`,
      }),
      providesTags: (res, error, arg) => [{type: 'ClusterTemplatesTranslations' as const, id: arg.uuid}],
    }),
    createClusterTemplate: build.mutation<Cluster, ClusterCreationParams>({
      query: ({eventUuid, ...args}) => ({
        url: `/event-types/${eventUuid}/cluster-templates/`,
        method: 'POST',
        body: {
          ...args,
        },
      }),
      invalidatesTags: (result, error, arg) => ['ClusterTemplates'],
    }),
    editClusterTemplate: build.mutation<Cluster, ClusterEditParams>({
      query: ({eventUuid, uuid, ...args}) => ({
        url: `/event-types/${eventUuid}/cluster-templates/${uuid}/`,
        method: 'PATCH',
        body: {
          ...args,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        {
          type: 'ClusterTemplates',
          id: arg.uuid,
        },
        {
          type: 'ClusterTemplatesTranslations',
          id: arg.uuid,
        },
      ],
    }),
    editClustersOrder: build.mutation<Cluster[], { eventTypeUuid: string, cluster_templates: string[] }>({
      query: ({eventTypeUuid, ...body}) => ({
        url: `/event-types/${eventTypeUuid}/cluster-templates/set_cluster_ordering/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => [{type: 'EventTypes', id: arg.eventTypeUuid}],
    }),
    deleteClusterTemplate: build.mutation<void, { eventUuid: string, clusterTemplateUuid: string }>({
      query: ({eventUuid, clusterTemplateUuid}) => {
        return {
          url: `/event-types/${eventUuid}/cluster-templates/${clusterTemplateUuid}/`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, arg) => ['ClusterTemplates'],
    }),
  }),
});

export const {
  useLazyGetClusterTranslationQuery,
  useGetClusterTemplatesNotPaginatedQuery,
  useCreateClusterTemplateMutation,
  useEditClusterTemplateMutation,
  useDeleteClusterTemplateMutation,
  useEditClustersOrderMutation,
} = clustersApi;