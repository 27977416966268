import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '../store';
import {PaginationConfig} from 'antd/lib/pagination';
import {
  DEFAULT_TEMPLATE_EMAIL_TABLE_FILTERS,
  DEFAULT_TEMPLATE_EMAIL_TABLE_SORTERS,
  TemplateEmailTableFilters,
  TemplateEmailTableSorters,
} from '../../types/internal/templateEmail';

// Define a type for the slice state
export interface TemplateMail {
  filters: TemplateEmailTableFilters,
  sorters: TemplateEmailTableSorters,
  pagination: PaginationConfig,
}

// Define the initial state using that type
const initialState = {
  filters: DEFAULT_TEMPLATE_EMAIL_TABLE_FILTERS,
  sorters: DEFAULT_TEMPLATE_EMAIL_TABLE_SORTERS,
  pagination: {
    current: 1,
    pageSize: +(window.sessionStorage.getItem('template_mail_page_size') || 10),
  },
} as TemplateMail;

export const templateMailSlice = createSlice({
  name: 'templateMail',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    /*Assets documents*/
    setTemplateEmailFilters: (state, {payload}: PayloadAction<Partial<TemplateEmailTableFilters>>) => {
      state.filters = {...state.filters, ...payload};
      state.pagination = {...state.pagination, current: 1};
    },
    setTemplateEmailSorters: (state, {payload}: PayloadAction<Partial<TemplateEmailTableSorters>>) => {
      state.sorters = {...state.sorters, ...payload};
    },
    setTemplateEmailPagination: (state, {payload}: PayloadAction<Partial<PaginationConfig>>) => {
      state.pagination = payload;
      if (payload.pageSize) {
        window.sessionStorage.setItem('template_mail_page_size', `${payload.pageSize}`);
      }
    },
    resetTemplateEmailFiltersAndSorters: (state) => {
      state.filters = DEFAULT_TEMPLATE_EMAIL_TABLE_FILTERS;
      state.sorters = DEFAULT_TEMPLATE_EMAIL_TABLE_SORTERS;
      state.pagination = {...state.pagination, current: 1};
    },
  },
});

export const {
  setTemplateEmailFilters,
  setTemplateEmailSorters,
  setTemplateEmailPagination,
  resetTemplateEmailFiltersAndSorters,
} = templateMailSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export const selectTemplateMail = (state: RootState) => state.templateMail;

export default templateMailSlice.reducer;
