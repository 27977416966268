import {apiSlice} from './baseApi';

const geocoding = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getGeoCode: build.query<{ results: google.maps.GeocoderResult[], status: string }, { address: string }>({
      query: (args) => {
        const {address} = args;
        return {
          url: 'https://maps.googleapis.com/maps/api/geocode/json',
          params: {
            address,
            key: process.env.REACT_APP_GOOGLE_API_KEY,
          },
          headers: undefined,
        };
      },
    }),
    getReverseGeocode: build.query<{ results: google.maps.GeocoderResult[], status: string }, {
      lat: number,
      lon: number
    }>({
      query: (args) => {
        const {lat, lon} = args;
        return {
          url: 'https://maps.googleapis.com/maps/api/geocode/json',
          params: {
            latlng: `${lat},${lon}`,
            key: process.env.REACT_APP_GOOGLE_API_KEY,
          },
          headers: undefined,
        };
      },
    }),
  }),
});

export const {
  useLazyGetGeoCodeQuery,
  useLazyGetReverseGeocodeQuery,
} = geocoding;