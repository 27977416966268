import {Card, Col, Radio, Row, Space, Typography} from "antd";
import {CardTitle} from "../creation/AlertCreation";
import AlertGeoshapesSelect from "./AlertGeoshapesSelect";
import {capitalize} from "lodash";
import AlertGeoshapesList from "./AlertGeoshapesList";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {PlusOutlined, ScissorOutlined} from "@ant-design/icons";

export default function AlertGeoshapesSelectAndList() {
  const {t} = useTranslation();
  const [type, setType] = useState<'cut' | 'insert'>('insert')

  return <Card>
    <CardTitle text={t('alerts.geoArea')}/>
    <Radio.Group
      value={type}
      onChange={e => setType(e.target.value)}
      style={{marginBottom: '0.5rem'}}
      buttonStyle="solid"
    >
      <Radio.Button
        value={'insert'}
      >
        <Space align={'center'}>
          <PlusOutlined/>
          {capitalize(t('events.insertEntity'))}
        </Space>
      </Radio.Button>
      <Radio.Button
        type={'primary'}
        value={'cut'}
      >
        <Space align={'center'}>
          <ScissorOutlined/>
          {capitalize(t('events.cutEntity'))}
        </Space>
      </Radio.Button>
    </Radio.Group>
    <Row gutter={[56, 24]}>
      <Col xs={24} md={8}>
        <AlertGeoshapesSelect type={type}/>
      </Col>
      <Col xs={24} md={16}>
        <Typography.Title
          level={5}
          style={{margin: 0}}
        >
          {capitalize(t('alerts.addedLocations'))}
        </Typography.Title>
        <AlertGeoshapesList/>
      </Col>
    </Row>
  </Card>
}