import {useLazyGetAlertAssetsListPaginatedQuery} from '../../../../redux/api/alerts';
import React, {useEffect, useState} from 'react';
import {CustomLoading} from '../../../common/CustomLoading';
import {Button, List} from 'antd';
import {RightOutlined} from '@ant-design/icons';
import {PaginationConfig} from 'antd/lib/pagination';
import {useAssetDrawerContext} from '../../../../context/alertDetails/AssetDrawerContext';
import {useAssetDetails} from '../../../../context/assetDetails/AssetDetails';

export default function AlertAssetList({uuid}: { uuid: string }) {

  const [, setIsDrawerOpen] = useAssetDrawerContext();
  const [setAssetUuid] = useAssetDetails();

  const [getData, {data, isFetching, isUninitialized}] = useLazyGetAlertAssetsListPaginatedQuery();
  const [pagination, setPagination] = useState<PaginationConfig>({
    current: 1,
    pageSize: 10,
    simple: true,
    onChange: (page, pageSize) => setPagination(prevPag => ({...prevPag, current: page, pageSize})),
    style: {textAlign: 'right'},
  });

  useEffect(() => {
    if (uuid) {
      getData({uuid, page_size: pagination.pageSize, page: pagination.current});
    }
  }, [getData, pagination, uuid]);

  return <List
    dataSource={data?.results}
    header={null}
    footer={null}
    loading={{
      spinning: isFetching || isUninitialized,
      indicator: <CustomLoading/>,
    }}
    pagination={{
      ...pagination,
      total: data?.count,
    }}
    renderItem={(item) => (
      <List.Item
        actions={[<div style={{display: 'flex', justifyContent: 'center'}}>
          <Button
            type={'primary'}
            size={'small'}
            shape={'circle'}
            icon={<RightOutlined/>}
            onClick={(event) => {
              event.stopPropagation();
              setIsDrawerOpen(true);
              setAssetUuid(item.uuid);
            }}
          />
        </div>]}
      >
            <span style={{textTransform: 'capitalize'}}>
            {[item.address && item.address.toLowerCase(),
              item.admin_level_3 && item.admin_level_3.toLowerCase(),
              item.admin_level_2 && item.admin_level_2.toUpperCase()].join(' - ')}
            </span>
      </List.Item>
    )}
  />;

}