import {useCallback, useEffect, useMemo} from 'react';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import {parseOptions, useLazyAddressSearchQuery} from '../../../../../redux/api/assets';
import {OptionLabelValue} from '../../../../../types/internal/OptionLabelValue';
import {getTranslationAssetTableColumnKey} from '../../../../../utils/translation';
import DEPRECATEDListWithSearchFilter from '../../../../../components/common/filters/DEPRECATEDListWithSearchFilter';

export const ImportReportAddressSearchFilter = ({field, activeFilters, handleFilterChange}: {
  field: 'address' | 'admin_level_1' | 'admin_level_2' | 'admin_level_3' | 'postal_code',
  activeFilters: OptionLabelValue[] | null,
  handleFilterChange: (values: ([] | OptionLabelValue[])) => void
}) => {

  const {t} = useTranslation();

  const [
    getAddresses,
    {
      data,
      isLoading,
      isFetching,
      isUninitialized,
    },
  ] = useLazyAddressSearchQuery();

  const options: OptionLabelValue[] = useMemo(() =>
      parseOptions(data?.results || []).map(el =>
        ['address', 'admin_level_1', 'admin_level_3'].includes(field) ?
          {...el, label: el.label ? el.label.toLowerCase() : ''}
          : el),
    [data?.results, field]);

  const placeholder = capitalize(t('table.filterPlaceholder', {column: t(getTranslationAssetTableColumnKey(field))}));

  const handleGetOptions = useCallback((page: number, q?: string) => {
    if (q) {
      getAddresses({field, page, page_size: 30, value: q});
    } else {
      getAddresses({field, page, page_size: 30});
    }
  }, [field, getAddresses]);

  useEffect(() => {
    handleGetOptions(1);
  }, [handleGetOptions]);

  return (
    <DEPRECATEDListWithSearchFilter
      getOptions={handleGetOptions}
      fetchedOptions={options}
      selectedOptions={activeFilters || []}
      setSelectedOptions={handleFilterChange}
      isLoadingOptions={isLoading || isFetching || isUninitialized}
      total={data?.count || 0}
      search={{placeholder}}
    />
  );
};