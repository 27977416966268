import {capitalize} from "lodash";
import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {Divider, Input, Modal, Space, Typography} from "antd";
import EditButton from "../../common/buttons/EditButton";
import RemoveButton from "../../common/buttons/RemoveButton";
import DeleteModalMessage from "../../common/documents/modals/DeleteModalMessage";

export default function InsertNote({text, onTextChange}: {
  text: string | null,
  onTextChange: (value: string) => void
}) {

  const {t} = useTranslation()
  const [isModalTextOpen, setIsModalTextOpen] = useState(false)
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false)
  const value = useRef(text || "")

  return <>
    <DeleteModalMessage
      title={{
        children: capitalize(t('actions.deleteItem', {item: t('misc.note')})),
      }}
      paragraph={{
        children: t('events.deleteAssetNote'),
      }}
      modal={{
        open: isModalDeleteOpen,
        onCancel: () => setIsModalDeleteOpen(false),
      }}
      confirmButton={{
        children: capitalize(t('actions.delete')),
        onClick: () => {
          setIsModalDeleteOpen(false)
          onTextChange('')
        },
      }}
    />
    <Modal
      destroyOnClose
      open={isModalTextOpen}
      title={capitalize(t('misc.note'))}
      onCancel={() => {
        value.current = text || ""
        setIsModalTextOpen(false)
      }}
      okText={capitalize(t('actions.save'))}
      okButtonProps={{
        onClick: () => {
          onTextChange(value.current)
          setIsModalTextOpen(false)
        }
      }}
    >
      <Input.TextArea
        autoSize={{minRows: 4}}
        defaultValue={text || ""}
        onChange={(e) => {
          value.current = e.target.value
        }}
      />
    </Modal>
    {text && <div style={{display: 'flex', flexDirection: 'column'}}>
      <Typography.Text style={{padding: '0 0 8px'}} strong>
        {capitalize(t('misc.note'))}
      </Typography.Text>
      <Typography.Text>
        {text}
      </Typography.Text>
      <Divider/>
      <Space style={{alignSelf: 'flex-end'}}>
        <EditButton type={'text'} children={null} onClick={() => setIsModalTextOpen(true)}/>
        <RemoveButton type={'text'} children={null} onClick={() => setIsModalDeleteOpen(true)}/>
      </Space>
    </div>}
    {!text && <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'end', height: '100%'}}>
      <EditButton onClick={() => setIsModalTextOpen(true)} style={{marginBottom: '8px'}}>
        {capitalize(t('actions.insertItem', {item: t('misc.note')}))}
      </EditButton>
    </div>}
  </>
}