import {Button, List, ListProps, Modal, Skeleton, Space, Tag, Typography} from "antd";
import DownloadButton from "../../common/buttons/DownloadButton";
import RemoveButton from "../../common/buttons/RemoveButton";
import React, {useState} from "react";
import {ArrowLeftOutlined, ArrowRightOutlined, EyeOutlined, FormOutlined, MailTwoTone} from "@ant-design/icons";
import {CommunicationStatus, EventCommunications} from "../../../types/api/communications";
import {capitalize, upperCase} from "lodash";
import {Trans, useTranslation} from "react-i18next";
import DateTimeFormat from "../../../locales/DateTimeFormat";
import {
  useDeleteEventCommunicationMutation,
  useGetEventCommunicationQuery,
  useLazyDownloadEventCommunicationQuery
} from "../../../redux/api/events";
import {useEventContext} from "../../../context/event/EventContext";
import {useMessageContext} from "../../../context/message/MessageContext";
import {skipToken} from "@reduxjs/toolkit/query";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import DeleteModalMessage from "../../common/documents/modals/DeleteModalMessage";
import {CustomTag} from "../../common/ExpandableTagList";
import {useGetAllTagsQuery} from "../../../redux/api/tags";
import PermissionRoute from "../../Authentication/PermissionRoute";
import {isEventCommunicationDelete} from "../../Authentication/permissions";
import EditCommunication from "./form/EditCommunication";
import {AssetTabs, ClusterTabs, CommunicationStatusTabs} from "./CommunicationTabs";
import {REDUCED_STATES} from "../../../types/api/getEventsResult";
import SanitizedHtml from "../../common/SanitizedHtml";

function ViewCommunicationBody({communicationUuid, eventUuid}: { communicationUuid: string, eventUuid: string }) {
  const [open, setOpen] = useState(false);
  const {data} = useGetEventCommunicationQuery(open ? {
    communicationUuid,
    eventUuid
  } : skipToken)

  const {xs} = useBreakpoint()

  return <>
    <Modal
      title={data ? data.subject : <Skeleton title paragraph={false}/>}
      open={open}
      onCancel={() => setOpen(false)}
      footer={false}
      width={xs ? '100vw' : '80vw'}
    >
      {
        data?.body ?
          <SanitizedHtml htmlString={data.body}/>
          :
          <Skeleton title={false} paragraph={{rows: 5}}/>
      }
    </Modal>
    <Button onClick={() => setOpen(true)} type="text" icon={<EyeOutlined/>}/>
  </>


}

function DownloadCommunication({eventUuid, communicationUuid}: { eventUuid: string, communicationUuid: string }) {

  const {t} = useTranslation()
  const [download, {isFetching}] = useLazyDownloadEventCommunicationQuery()
  const setMessage = useMessageContext()

  return <DownloadButton
    onClick={async () => {
      try {
        await download({eventUuid, communicationUuid}).unwrap()
        setMessage({
          success: capitalize(t('documents.downloadSuccess'))
        })
      } catch {
        setMessage({
          error: capitalize(t('documents.downloadError'))
        })
      }
    }}
    disabled={isFetching}
    loading={isFetching}
    size={'large'}
    children={null}
    type={'text'}
  />
}

function DeleteCommunication({eventUuid, communicationUuid}: { eventUuid: string, communicationUuid: string }) {

  const [isModalOpen, setIsModalOpen] = useState(false)
  const {t} = useTranslation()
  const [deleteCommunication, {isLoading}] = useDeleteEventCommunicationMutation()
  const setMessage = useMessageContext()
  const handleDelete = async () => {
    try {
      await deleteCommunication({eventUuid, communicationUuid}).unwrap()
      setMessage({success: capitalize(t('form.deleteSuccessMessage'))});
    } catch {
      setMessage({success: capitalize(t('form.deleteFailMessage'))});
    } finally {
      setIsModalOpen(false)
    }
  }

  return <PermissionRoute isAuthorized={isEventCommunicationDelete}>
    <DeleteModalMessage
      title={{
        children: capitalize(t('alerts.removeConfirm')),
      }}
      paragraph={{
        children: <Trans
          i18nKey={'events.communicationDeleteDescription'} components={{p: <Typography.Paragraph/>}}
        />,
      }}
      modal={{
        open: isModalOpen,
        onCancel: () => setIsModalOpen(false),
      }}
      confirmButton={{
        children: capitalize(t('alerts.removeConfirm')),
        onClick: handleDelete,
        disabled: isLoading,
        loading: isLoading
      }}
    />
    <RemoveButton
      size={'large'}
      children={null}
      type={'text'}
      onClick={() => setIsModalOpen(true)}
    />
  </PermissionRoute>
}

export default function CommunicationList({list, communicationTab}: {
  list?: ListProps<EventCommunications>,
  communicationTab: AssetTabs | ClusterTabs
}) {

  const {t} = useTranslation()
  const {event} = useEventContext()
  const {data: tags} = useGetAllTagsQuery()

  return <List
    renderItem={item => {
      const isClusterCommunication = item.clusters_count >= 1
      const date = (
        <Typography.Text>
          <DateTimeFormat>
            {item.datetime}
          </DateTimeFormat>
        </Typography.Text>
      )
      const user = `${capitalize(t('user.user', {count: 1}))}: ${item.user_edit}`
      const assets = isClusterCommunication ?
        <div>{capitalize(t('events.involvedAssets'))}: <Typography.Text>{item.assets_count}</Typography.Text></div>
        : null

      const title = <Space direction={'vertical'}>
        <Space>
          {item.subject}
          {isClusterCommunication && <Tag color={'red'}>{upperCase(t('clusters.cluster', {count: 1}))}</Tag>}
        </Space>
        <Space wrap>
          {item.tags.map((tag) => {
              const foundTag = tags?.find(el => el.uuid === tag)
              return foundTag ?
                (<CustomTag
                  content={foundTag.frontend_name}
                  color={foundTag.color}
                  key={foundTag.uuid}
                />)
                :
                null
            }
          )}
        </Space>
      </Space>


      return <>
        <List.Item
          actions={[
            <>
              {!!event?.uuid && <ViewCommunicationBody communicationUuid={item.uuid} eventUuid={event?.uuid}/>}
              {communicationTab === CommunicationStatusTabs.draft &&
                !!event?.uuid &&
                event?.reduced_state !== REDUCED_STATES.archived &&
                <EditCommunication communicationUuid={item.uuid} eventUuid={event?.uuid}/>}
              {!!event?.uuid && <DownloadCommunication eventUuid={event?.uuid} communicationUuid={item.uuid}/>}
              {!!event?.uuid && event?.reduced_state !== REDUCED_STATES.archived &&
                <DeleteCommunication eventUuid={event?.uuid} communicationUuid={item.uuid}/>}
            </>
          ]}
        >
          <List.Item.Meta
            title={title}
            avatar={<>
              {item.status === CommunicationStatus.sent && <Space><ArrowRightOutlined/><MailTwoTone/></Space>}
              {item.status === CommunicationStatus.received && <Space><ArrowLeftOutlined/><MailTwoTone/></Space>}
              {item.status === CommunicationStatus.draft && <FormOutlined/>}
            </>
            }
            description={<Space direction={'vertical'}>
              {date}
              {user}
              {assets}
            </Space>}
          />
        </List.Item>
      </>
    }}
    {...list}
  />
}