import {Typography} from "antd";
import {useTranslation} from "react-i18next";
import {capitalize} from "lodash";

export default function CoordinatesAssetMissing() {

  const {t} = useTranslation()

  return <Typography.Paragraph
    style={{margin: '0.5rem', textAlign: 'center'}}
    italic
  >
    {capitalize(t('assets.missingCoordinates'))}
  </Typography.Paragraph>
}