import {useMap} from 'react-leaflet';
import {Button, Space} from 'antd';
import {MinusOutlined, PlusOutlined} from '@ant-design/icons';
import {CSSProperties} from 'react';
import {QueryModeButtons} from './QueryModeButtons';
import {useBaseWMSLayerContext} from '../../../../context/baseMap/BaseWMSLayerContext';
import {StreetViewButton} from './StreetViewButton';
import {AssetFilterFromLayer} from './AssetFilterFromLayer';
import {ScreenshotButton} from './ScreenshotButton';

export const MapFloatButtons = (props: {
  withZoom?: boolean,
  withQuerableLayers?: boolean,
  withStreetView?: boolean,
  withAssetFilterFromLayer?: boolean,
  withScreenshot?: boolean,
  style?: CSSProperties
}) => {
  const map = useMap();
  
  const [wmsLayers, currentWMSLayer] = useBaseWMSLayerContext();
  
  return (
    <Space
      direction={'vertical'}
      style={{
        position: 'absolute',
        zIndex: 1000,
        bottom: '1.5rem',
        right: '1rem',
        ...props.style,
      }}
    >
      {props.withStreetView === true && (
        <Space.Compact direction={'vertical'}>
          <StreetViewButton/>
        </Space.Compact>
      )}
      <Space.Compact direction={'vertical'}>
        {currentWMSLayer !== null && wmsLayers.find(layer => layer.uuid === currentWMSLayer)?.has_polygons && props.withAssetFilterFromLayer === true && (
          <AssetFilterFromLayer/>
        )}
        {currentWMSLayer !== null && props.withQuerableLayers === true && (
          <QueryModeButtons/>
        )}
      </Space.Compact>
      {props.withScreenshot === true && (
        <Space.Compact direction={'vertical'}>
          <ScreenshotButton/>
        </Space.Compact>
      )}
      {props.withZoom && (
        <Space.Compact direction={'vertical'}>
          <Button
            icon={<PlusOutlined/>}
            size={'large'}
            title={'Zoom in'}
            onClick={() => map.zoomIn(1)}
          />
          <Button
            icon={<MinusOutlined/>}
            size={'large'}
            title={'Zoom out'}
            onClick={() => map.zoomOut(1)}
          />
        </Space.Compact>
      )}
    </Space>
  );
};