import React, {useEffect, useRef} from 'react';
import L from 'leaflet';
import {MapFloatButtons} from './controls/MapFloatButtons';
import {BaseLayerPicker} from './controls/BaseLayerPicker';
import {GeoSearch} from './controls/GeoSearch';
import {MapControlsProps} from '../../../types/internal/Map';
import {Legend} from './controls/Legend';

export const Controls = (props: MapControlsProps) => {
  
  const floatButtonsBoxRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null);
  const baseLayerPickerBoxRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null);
  const geosSearchBoxRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null);
  const legendControlsBoxRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null);
  
  useEffect(() => {
    if (floatButtonsBoxRef.current) {
      L.DomEvent.disableClickPropagation(floatButtonsBoxRef.current);
      L.DomEvent.disableScrollPropagation(floatButtonsBoxRef.current);
    }
    if (baseLayerPickerBoxRef.current) {
      L.DomEvent.disableClickPropagation(baseLayerPickerBoxRef.current);
      L.DomEvent.disableScrollPropagation(baseLayerPickerBoxRef.current);
    }
    if (geosSearchBoxRef.current) {
      L.DomEvent.disableClickPropagation(geosSearchBoxRef.current);
      L.DomEvent.disableScrollPropagation(geosSearchBoxRef.current);
    }
    if (legendControlsBoxRef.current) {
      L.DomEvent.disableClickPropagation(legendControlsBoxRef.current);
      L.DomEvent.disableScrollPropagation(legendControlsBoxRef.current);
    }
  }, []);
  
  return (
    <React.Fragment>
      <div ref={floatButtonsBoxRef}>
        <MapFloatButtons
          withZoom={props.withZoom}
          withQuerableLayers={props.withQuerableLayers}
          withAssetFilterFromLayer={props.withAssetFilterFromLayer}
          withStreetView={props.withStreetView}
          withScreenshot={props.withScreenshot}
        />
      </div>
      {props.withLayerPicker === true && props.withBaseLayers && (
        <div ref={baseLayerPickerBoxRef}>
          <BaseLayerPicker
            availableBaseTileLayers={props.withBaseLayers}
            availableWMSLayers={props.withWMSLayers}
            withLayerLoading={props.withSoftLoading}
            withPopoverConfiguration={{
              ...props.withPopoverConfiguration,
              getPopupContainer: () => (baseLayerPickerBoxRef.current) || document.body,
            }}
          />
        </div>
      )}
      {props.withGeoSearch === true && (
        <div
          ref={geosSearchBoxRef}
          style={{
            position: 'relative',
            top: '1rem',
            left: '1.5rem',
            zIndex: 1000,
            paddingBottom: '0.5rem',
            display: 'block',
            width: 'fit-content',
          }}
        >
          <GeoSearch id={'map-geosearch'}/>
        </div>
      )}
      {props.withLegend === true && props.withWMSLayers && (
        <div ref={legendControlsBoxRef}>
          <Legend availableWMSLayers={props.withWMSLayers}/>
        </div>
      )}
    </React.Fragment>
  );
};