import {Button, List, theme} from "antd";
import {ASSET_MAP_ACTIONS} from "../../common/map/constants/map";
import {DeleteOutlined} from "@ant-design/icons";
import {capitalize} from "lodash";
import {useEventContext} from "../../../context/event/EventContext";
import {Cluster} from "../../../types/api/clusters";
import {useControlsAction} from "../../../context/baseMap/ControlContext";
import {useTranslation} from "react-i18next";
import {useMapLoadingContext} from "../../../context/baseMap/MapLoadingContext";
import {translateLevelToText} from "../../../utils/assets";

const {useToken} = theme;

export default function GlobalPolygonsList({cluster}: { cluster: Cluster }) {

  const [isLoadingMap, setIsLoadingMap] = useMapLoadingContext();
  const {
    action: {
      currentAction,
      actionEvent,
    },
    changeAction,
  } = useControlsAction();
  const {t} = useTranslation();
  const {token} = useToken();
  const {
    getEntityFromUuid,
    onRemovePolygon,
    getEventPolygonsFromCluster
  } = useEventContext();

  const handleRemovePolygon = (globalPolygonUuid: string) => {
    setIsLoadingMap(true);
    onRemovePolygon(globalPolygonUuid)
      .finally(() => setIsLoadingMap(false));
  };

  return <List
    size={'small'}
    dataSource={getEventPolygonsFromCluster(cluster.uuid)}
    renderItem={(item, index) => {

      const currentEntity = (item.entity_uuid && getEntityFromUuid(item.entity_uuid)) || null
      const label = currentEntity ? `${currentEntity.name} (${capitalize(t(translateLevelToText(currentEntity.level)))})`: item.polygon_name

      return (
        <List.Item
          style={{backgroundColor: currentAction === ASSET_MAP_ACTIONS.SELECT_GLOBAL_POLYGON && actionEvent.uuid === item.globalPolygon ? token.colorInfoBg : token.colorBgContainer}}
          key={item.globalPolygon}
          onClick={e => {
            changeAction(ASSET_MAP_ACTIONS.SELECT_GLOBAL_POLYGON, {uuid: currentAction === ASSET_MAP_ACTIONS.SELECT_GLOBAL_POLYGON && actionEvent.uuid === item.globalPolygon ? undefined : item.globalPolygon});
          }}
          actions={[<Button
            disabled={isLoadingMap}
            danger
            type={'text'}
            icon={<DeleteOutlined/>}
            onClick={(e) => {
              e.stopPropagation();
              handleRemovePolygon(item.globalPolygon);
            }}
          >
            {capitalize(t('actions.delete'))}
          </Button>,
          ]}
        >
          {label}
        </List.Item>)
    }}
  />
}