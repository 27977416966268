import {Button, Flex, Pagination, Popover, Space, Table, Typography} from "antd";
import {ColumnsType} from "antd/lib/table";
import React, {useCallback, useMemo, useState} from "react";
import {PaginationConfig} from "antd/lib/pagination";
import {useGetEventAssetStatusPaginatedQuery} from "../../../redux/api/events";
import {skipToken} from "@reduxjs/toolkit/query";
import {useTranslation} from "react-i18next";
import {useEventContext} from "../../../context/event/EventContext";
import {
  fromFeToBeInvolvedAssetsTableFilters,
  involvedAssetsColumnsTable,
  InvolvedAssetsFiltersFE,
  InvolvedAssetsTableColumn
} from "../../../types/internal/tables/involvedAssetsTable";
import {ASSET_STATUS, GetAssetStatus, REDUCED_STATES} from "../../../types/api/getEventsResult";
import {DocumentsTableColumnHeader} from "../../documents/TablesViews/DocumentsTableColumnHeader";
import {getTranslationInvolvedAssetTableColumnKey} from "../../../utils/translation";
import {capitalize, cloneDeep} from "lodash";
import InvolvedAssetRelatedOptionsFilter from "./InvolvedAssetRelatedOptionsFilter";
import {FileTextOutlined} from "@ant-design/icons";
import AssetStatusEdit from "../eventStatus/AssetStatusEdit";

export default function InvolvedAssetsTable() {

  const {t} = useTranslation()
  const {event} = useEventContext()
  const [pagination, setPagination] = useState<PaginationConfig>({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    showTotal: (total, range) => t('table.pageSizeOfTotal', {
      rangeStart: range[0],
      rangeEnd: range[1],
      total,
    }),
    onChange: (page, pageSize) => setPagination(prevPag => ({...prevPag, current: page, pageSize})),
    style: {textAlign: 'center'},
  });
  const [filters, setFilters] = useState<InvolvedAssetsFiltersFE>({
    excludedFilters: []
  })


  const resetFilter = useCallback((filter: keyof InvolvedAssetsFiltersFE) => {
    setFilters(prevState => {
      const copyState = cloneDeep(prevState)
      delete copyState[filter]
      return copyState
    })
  }, [])

  const {data, isFetching} = useGetEventAssetStatusPaginatedQuery(event?.uuid ? {
    eventUuid: event.uuid,
    page: pagination.current,
    page_size: pagination.pageSize,
    ...fromFeToBeInvolvedAssetsTableFilters(filters)
  } : skipToken)

  const getAddressColumnProps = useCallback((column: InvolvedAssetsTableColumn) => {
    return {
      key: column,
      dataIndex: ['asset', column],
      title: <DocumentsTableColumnHeader
        title={capitalize(t(getTranslationInvolvedAssetTableColumnKey(column)))}
        filter={event?.uuid && <InvolvedAssetRelatedOptionsFilter
          filters={filters}
          setFilters={setFilters}
          eventUuid={event?.uuid}
          field={column}
        />}
        isFilterDisabled={filters[column] === undefined}
        onFilterReset={() => resetFilter(column)}
      />,
      ...["address", "admin_level_1", "admin_level_3", "country"]
        .includes(column) && {
        render: (value: string | null) => <div
          style={{textTransform: 'capitalize'}}>
          {value ? value.toLowerCase() : ''}
        </div>
      },
    }
  }, [event?.uuid, filters, resetFilter, t])

  const columns: ColumnsType<GetAssetStatus> = useMemo(() => involvedAssetsColumnsTable.map(column => {
    if (column !== 'state') {
      return getAddressColumnProps(column)
    } else {
      return {
        key: column,
        dataIndex: column,
        title: <DocumentsTableColumnHeader
          title={capitalize(t('events.changeAssetsState', {count: 1}))}
          filter={event?.uuid && <InvolvedAssetRelatedOptionsFilter
            filters={filters}
            setFilters={setFilters}
            eventUuid={event?.uuid}
            field={column}
          />}
          isFilterDisabled={filters[column] === undefined}
          onFilterReset={() => resetFilter(column)}
        />,
        render: (value: string, record: GetAssetStatus) => {

          let label = ''

          switch (record.state) {
            case ASSET_STATUS.OPEN:
              label = capitalize(t('assets.statusOpen', {count: 1}))
              break;
            case ASSET_STATUS.REMOTE_WORK:
              label = capitalize(t('assets.statusRemote'))
              break;
            case ASSET_STATUS.CLOSED:
              label = capitalize(t('assets.statusClosed', {count: 1}))
          }

          return <Space align={'center'} size={'small'}>
            {label}
            {!!record.notes && <Popover
              content={record.notes}
              title={capitalize(t('misc.note'))}
            >
              <Button
                icon={<FileTextOutlined/>}
                type={'text'}
              />
            </Popover>}
            {event?.reduced_state !== REDUCED_STATES.archived && <AssetStatusEdit
              asset={record.asset}
              state={record.state}
              state_from={record.state_from}
              notes={record.notes}
            />}
          </Space>
        }
      }
    }
  }), [event?.reduced_state, event?.uuid, filters, getAddressColumnProps, resetFilter, t])


  return <Flex vertical gap={'1rem'}>
    <Typography.Title level={4}>
      {capitalize(t('events.changeAssetsState'))}
    </Typography.Title>
    <Table
      <GetAssetStatus>
      columns={columns}
      loading={isFetching}
      dataSource={data?.results}
      rowKey={item => item.asset.uuid}
      pagination={false}
      scroll={{x: true}}
    />
    {!!data?.count && <Pagination
      total={data?.count}
      {...pagination}
    />}
  </Flex>
}