import {useEffect} from 'react';

type EscapeEventHandler = (event: KeyboardEvent) => void;

const UseEscapeKey = (callback: EscapeEventHandler) => {
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        callback(event);
      }
    };
    
    document.addEventListener('keydown', handleKeyPress);
    
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [callback]);
};

export default UseEscapeKey;