import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '../store';
import {PaginationConfig} from 'antd/lib/pagination';
import {DEFAULT_COLUMNS} from '../../utils/assets';
import {
  AssetsFiltersFE,
  AssetsOptionLabelValueFilters,
  AssetsSortersFE,
  AssetsTableColumn,
  isStaticAssetsFilters,
  StaticAssetsFilters,
} from '../../types/internal/tables/assetsTable';
import _ from 'lodash';

// Define a type for the slice state
type AssetsSlice = {
  assetsTable: {
    filters: AssetsFiltersFE,
    filtersInExcludeMode: AssetsOptionLabelValueFilters[],
    sorters: AssetsSortersFE,
    pagination: PaginationConfig,
    columns: AssetsTableColumn[],
  },
}

// Define the initial state using that type
const initialState: AssetsSlice = {
  assetsTable: {
    filters: {
      building_type: [{label: 'Strumentali Sì Risorse', value: 'SSR'}, {label: 'Strumentali No Risorse', value: 'SNR'}],
      is_closing_date_null: true,
    },
    filtersInExcludeMode: [],
    sorters: {},
    columns: DEFAULT_COLUMNS,
    pagination: {
      current: 1,
      pageSize: +(window.sessionStorage.getItem('assets_page_size') || 10),
    },
  },
};

export const assetsSlice = createSlice({
  name: 'assets',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setFilters: (state, {payload}: PayloadAction<AssetsFiltersFE>) => {
      state.assetsTable.filters = payload;
    },
    insertFilterInExcludeMode: (state, {payload}: PayloadAction<AssetsOptionLabelValueFilters>) => {
      state.assetsTable.filtersInExcludeMode = [...state.assetsTable.filtersInExcludeMode, payload];
    },
    removeFilterInExcludeMode: (state, {payload}: PayloadAction<AssetsOptionLabelValueFilters>) => {
      state.assetsTable.filtersInExcludeMode = (state.assetsTable.filtersInExcludeMode).filter(el => el !== payload);
    },
    removeFilters: (state, {payload}: PayloadAction<((StaticAssetsFilters | 'hazards') | string)[] | ((StaticAssetsFilters | 'hazards') | string)>) => {
      const updatedFilters = {...state.assetsTable.filters};
      if (Array.isArray(payload)) {
        payload.forEach(el => {
          if (isStaticAssetsFilters(el) || el === 'hazards') {
            delete updatedFilters[el];
          } else {
            delete updatedFilters?.hazards?.[el];
          }
        });
      } else {
        if (isStaticAssetsFilters(payload) || payload === 'hazards') {
          delete updatedFilters[payload];
        } else {
          delete updatedFilters?.hazards?.[payload];
        }
      }
      
      state.assetsTable = {
        ...state.assetsTable,
        filters: updatedFilters,
        pagination: {
          ...state.assetsTable.pagination,
          current: 1,
        },
      };
    },
    
    removeHazardFilter: (state, {payload}: PayloadAction<string>) => {
      delete state.assetsTable.filters?.hazards?.[payload];
    },
    
    addFilters: (state, {payload}: PayloadAction<AssetsFiltersFE>) => {
      const {hazards, ...otherFilters} = payload;
      let updatedHazards = {...state.assetsTable.filters.hazards};
      if (hazards) {
        for (const [key, value] of Object.entries(hazards)) {
          updatedHazards = {
            ...updatedHazards,
            [key]: value,
          };
        }
      }
      
      state.assetsTable = {
        ...state.assetsTable,
        filters: {
          ...state.assetsTable.filters,
          hazards: updatedHazards,
          ...otherFilters,
        },
        pagination: {
          ...state.assetsTable.pagination,
          current: 1,
        },
      };
    },
    
    setSorters: (state, {payload}: PayloadAction<AssetsSortersFE>) => {
      state.assetsTable.sorters = payload;
    },
    removeSorters: (state, {payload}: PayloadAction<keyof AssetsSortersFE | (keyof AssetsSortersFE)[]>) => {
      if (Array.isArray(payload)) {
        payload.forEach(el => delete state.assetsTable.sorters[el]);
      } else {
        delete state.assetsTable.sorters[payload];
      }
    },
    addSorters: (state, {payload}: PayloadAction<AssetsSortersFE>) => {
      state.assetsTable.sorters = {...state.assetsTable.sorters, ...payload};
    },
    resetFiltersSorters: (state) => {
      state.assetsTable = {
        ...state.assetsTable,
        filters: {},
        sorters: {},
        pagination: {...state.assetsTable.pagination, current: 1},
      };
    },
    
    setPagination: (state, {payload}: PayloadAction<Partial<PaginationConfig>>) => {
      state.assetsTable.pagination = payload;
      if (payload.pageSize) {
        window.sessionStorage.setItem('assets_page_size', `${payload.pageSize}`);
      }
    },
    setColumns: (state, {payload}: PayloadAction<AssetsTableColumn[]>) => {
      state.assetsTable.columns = payload;
    },
    insertColumnsAtTheEnd: (state, {payload}: PayloadAction<AssetsTableColumn[]>) => {
      state.assetsTable.columns = [..._.difference(state.assetsTable.columns, payload), ...payload];
    },
    removeColumns: (state, {payload}: PayloadAction<AssetsTableColumn[]>) => {
      state.assetsTable.columns = _.difference(state.assetsTable.columns, payload);
    },
  },
});

export const {
  setPagination,
  setColumns,
  insertColumnsAtTheEnd,
  removeColumns,
  resetFiltersSorters,
  addFilters,
  removeFilters,
  setFilters,
  addSorters,
  setSorters,
  removeSorters,
  insertFilterInExcludeMode,
  removeFilterInExcludeMode,
} = assetsSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAssets = (state: RootState) => state.assets;
export const selectAssetsTableFilters = (state: RootState) => state.assets.assetsTable.filters;
export const selectAssetsTableSorters = (state: RootState) => state.assets.assetsTable.sorters;
export const selectAssetsTablePagination = (state: RootState) => state.assets.assetsTable.pagination;
export const selectAssetsTableColumns = (state: RootState) => state.assets.assetsTable.columns;

export default assetsSlice.reducer;
