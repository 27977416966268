import {Card, Col, Divider, Input, Radio, Row, Typography} from 'antd';
import {useLocationContext} from '../../context/addAsset/AssetLocationContext';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import {GeocodingSearchSelect} from '../common/searchSelect/GeocodingSearchSelect';
import {ReverseGeocodingSearchSelect} from '../common/searchSelect/ReverseGeocodingSearchSelect';
import {useState} from 'react';
import {AssetPositionMarker} from './previewMap/AssetPositionMarker';
import {BaseMapContainer} from '../common/map/BaseMapContainer';
import {GoogleLayers} from '../../types/internal/Map';
import Label from '../common/Label';
import {CountriesSearchSelect} from '../common/searchSelect/CountriesSearchSelect';

const {Title} = Typography;

enum SearchAddressType {
  Address,
  Coordinates,
}

export const AssetPositionFormCard = () => {
  
  const {t} = useTranslation();
  
  const [location, setLocation] = useLocationContext();
  const [selectedGeocodingType, setSelectedGeocodingType] = useState(SearchAddressType.Address);
  
  return (
    <Card title={capitalize(t('assets.buildingLocation'))}>
      <Row>
        <Col>
          <Radio.Group
            onChange={e => setSelectedGeocodingType(e.target.value)}
            value={selectedGeocodingType}
            style={{marginBottom: 8}}
          >
            <Radio.Button
              value={SearchAddressType.Address}
              id={'geocoding'}
            >
              {capitalize(t('address.address'))}
            </Radio.Button>
            <Radio.Button
              value={SearchAddressType.Coordinates}
              id={'reverseGeocoding'}
            >
              {capitalize(t('address.coordinates'))}
            </Radio.Button>
          </Radio.Group>
        </Col>
      </Row>
      <Row>
        <Col flex={1}>
          {selectedGeocodingType === SearchAddressType.Address && (
            <GeocodingSearchSelect
              onSelect={(value, option) => {
                const localityName = (option.address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('locality'))?.long_name)?.toUpperCase() || null;
                const municipalityName = (option.address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('administrative_area_level_3'))?.long_name)?.toUpperCase() || null;
                setLocation(location => (
                  {
                    ...location,
                    country: option.address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('country'))?.short_name || null,
                    admin_level_1: option.address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('administrative_area_level_1'))?.long_name || null,
                    admin_level_2: option.address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('administrative_area_level_2'))?.short_name || null,
                    admin_level_3: option.address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('administrative_area_level_3'))?.long_name || null,
                    locality: localityName !== municipalityName ? option.address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('locality'))?.long_name : null,
                    address: [
                      option.address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('route'))?.long_name || null,
                      option.address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('street_number'))?.long_name || null,
                    ].filter(item => item !== null).join(', ') || null,
                    postal_code: option.address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('postal_code'))?.long_name || null,
                    // @ts-ignore wrong type delared from @types/google.maps
                    position: option.geometry?.location ? [option.geometry.location.lat as number, option.geometry.location.lng as number] : null,
                    geocoding_position: option.geometry?.location ? [option.geometry.location.lat as number, option.geometry.location.lng as number] : null,
                  }
                ));
              }}
            />
          )}
          {selectedGeocodingType === SearchAddressType.Coordinates && (
            <ReverseGeocodingSearchSelect
              onSelect={(value, option) => {
                const localityName = (option.address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('locality'))?.long_name)?.toUpperCase() || '';
                const municipalityName = (option.address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('administrative_area_level_3'))?.long_name)?.toUpperCase() || '';
                setLocation(location => (
                  {
                    ...location,
                    country: option.address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('country'))?.short_name || null,
                    admin_level_1: option.address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('administrative_area_level_1'))?.long_name || null,
                    admin_level_2: option.address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('administrative_area_level_2'))?.short_name || null,
                    admin_level_3: option.address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('administrative_area_level_3'))?.long_name || null,
                    locality: localityName !== municipalityName ? option.address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('locality'))?.long_name : null,
                    address: [
                      option.address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('route'))?.long_name || null,
                      option.address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('street_number'))?.long_name || null,
                    ].filter(item => item !== null).join(', ') || null,
                    postal_code: option.address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('postal_code'))?.long_name || null,
                    // @ts-ignore wrong type delared from @types/google.maps
                    position: option.geometry?.location ? [option.geometry.location.lat as number, option.geometry.location.lng as number] : null,
                    geocoding_position: option.geometry?.location ? [option.geometry.location.lat as number, option.geometry.location.lng as number] : null,
                  }
                ));
              }}
            />
          )}
        </Col>
      </Row>
      <Divider/>
      <Row gutter={[40, 0]}>
        <Col xs={24} lg={12}>
          <CountriesSearchSelect
            value={location.country}
            onClear={() => setLocation(location => (
              {...location, country: null}
            ))}
            onSelect={(value, option) =>
              setLocation(location => ({
                ...location,
                country: value || null,
              }))}
          />
        </Col>
        <Col xs={24} lg={12}>
          <Label label={capitalize(t('address.region'))} required={true} htmlFor={'admin_level_1'}/>
          <Input
            placeholder={capitalize(t('address.region'))}
            style={{marginBottom: 20}}
            id={'admin_level_1'}
            value={location.admin_level_1 || ''}
            onChange={e => setLocation(location => ({
              ...location,
              admin_level_1: e.target.value || null,
            }))}
          />
        </Col>
      </Row>
      <Row gutter={[40, 0]}>
        <Col xs={24} lg={12}>
          <Label label={capitalize(t('address.municipality'))} required={true} htmlFor={'admin_level_3'}/>
          <Input
            placeholder={capitalize(t('address.municipality'))}
            style={{marginBottom: 20}}
            id={'admin_level_3'}
            value={location.admin_level_3 || ''}
            onChange={e => setLocation(location => ({
              ...location,
              admin_level_3: e.target.value || null,
            }))}
          />
        </Col>
        <Col xs={24} lg={12}>
          <Label label={capitalize(t('address.province'))} required={true} htmlFor={'admin_level_2'}/>
          <Input
            placeholder={capitalize(t('address.province'))}
            style={{marginBottom: 20}}
            id={'admin_level_2'}
            value={location.admin_level_2 || ''}
            onChange={e => setLocation(location => ({
              ...location,
              admin_level_2: e.target.value || null,
            }))}
          />
        </Col>
      </Row>
      <Row gutter={[40, 0]}>
        {location.locality !== null && <Col xs={24} lg={12}>
          <Label label={capitalize(t('address.locality'))} htmlFor={'locality'}/>
          <Input
            placeholder={capitalize(t('address.locality'))}
            style={{marginBottom: 20}}
            id={'locality'}
            autoComplete={'off'}
            value={location.locality || ''}
            onChange={e => setLocation(location => ({
              ...location,
              locality: e.target.value, /*|| null*/
            }))}
          />
        </Col>}
        <Col xs={24} lg={12}>
          <Label label={capitalize(t('address.address'))} required={true} htmlFor={'address'}/>
          <Input
            placeholder={capitalize(t('address.address'))}
            style={{marginBottom: 20}}
            id={'address'}
            autoComplete={'off'}
            value={location.address || ''}
            onChange={e => setLocation(location => ({
              ...location,
              address: e.target.value || null,
            }))}
          />
        </Col>
        <Col xs={24} lg={12}>
          <Label label={capitalize(t('address.postalCode'))} required={true} htmlFor={'postal_code'}/>
          <Input
            placeholder={capitalize(t('address.postalCode'))}
            autoComplete={'off'}
            style={{marginBottom: 20}}
            id={'postal_code'}
            value={location.postal_code || ''}
            onChange={e => setLocation(location => ({
              ...location,
              postal_code: e.target.value || null,
            }))}
          />
        </Col>
      </Row>
      <Row>
        <Col flex={1}>
          <Title
            level={5}
            style={{fontWeight: 'lighter', padding: '0 0.5rem'}}
          >{t('misc.positionPreview')?.toUpperCase()}
          </Title>
          <BaseMapContainer
            withZoom={true}
            withGeoSearch={false}
            withLayerPicker={true}
            withBaseLayers={[GoogleLayers.roadmap, GoogleLayers.hybrid]}
            withStyle={{width: '100%', height: '360px'}}
          >
            <AssetPositionMarker/>
          </BaseMapContainer>
        </Col>
      </Row>
    </Card>
  );
};