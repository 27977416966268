import {Button} from "antd";
import {
  useLazyGetAlertSourceTaskStatusQuery,
  useLazyRefreshAllAlertSourcesDataQuery
} from "../../../redux/api/alertSource";
import {useEffect, useState} from "react";
import {SyncOutlined} from "@ant-design/icons";
import {useMessageContext} from "../../../context/message/MessageContext";
import {capitalize} from "lodash";
import {useTranslation} from "react-i18next";

export default function RefreshAllAlertSources({onFinish}: { onFinish: () => void }) {

  const [refresh, {isLoading}] = useLazyRefreshAllAlertSourcesDataQuery()
  const [getTaskStatus] = useLazyGetAlertSourceTaskStatusQuery()
  const [pendingTasksUuids, setPendingTasksUuids] = useState<string[]>([])
  const isPending = pendingTasksUuids.length > 0 || isLoading
  const setMessage = useMessageContext()
  const {t} = useTranslation()

  useEffect(() => {
    const timeout = setTimeout(async () => {
      if (isPending) {
        try {
          const tasksStatusResponses = await Promise.all(pendingTasksUuids.map(uuid => getTaskStatus({task_id: uuid}).unwrap()))
          const updatedPending = tasksStatusResponses.reduce((result: string[], res) => {
            if (res.status !== 200) {
              return [...result, res.task_id]
            } else {
              return result
            }
          }, [])
          if (updatedPending.length === 0) {
            onFinish()
          }
          setPendingTasksUuids(updatedPending)

        } catch {
          onFinish()
          setPendingTasksUuids([])
          setMessage({error: capitalize(t('form.fetchingFailMessage'))})
        }
      }
    }, 5000)

    return () => {
      clearTimeout(timeout)
    }
  }, [getTaskStatus, isPending, onFinish, pendingTasksUuids, setMessage, t]);
  const handleRefresh = async () => {
    try {
      const tasks = await refresh().unwrap()
      const pendingTasks = tasks.reduce((result: string[], task) => {
        if (task.status === 200) {
          return [...result, task.data.task_id]
        } else return result
      }, [])
      setPendingTasksUuids(pendingTasks)
    } catch {
      setMessage({error: capitalize(t('form.fetchingFailMessage'))})
    }
  }

  return <>
    <Button
      icon={<SyncOutlined/>}
      type={'primary'}
      disabled={isPending}
      loading={isPending}
      onClick={handleRefresh}
    >
      {capitalize(t('actions.updateItem', {item: t('settings.dataSource', {count: 2})}))}
    </Button>
  </>
}