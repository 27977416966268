import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import TemplateMailForm from './TemplateMailForm';
import {EditOutlined} from '@ant-design/icons';
import {useEffect, useState} from 'react';
import {FormInstance} from 'antd';
import {EmailTemplate} from '../../../types/api/templateEmails';
import {useEditTemplateEmailMutation} from '../../../redux/api/templateEmails';
import {useMessageContext} from '../../../context/message/MessageContext';

export default function EditTemplateMail({template, formInstance}: {
  template: EmailTemplate,
  formInstance: FormInstance<any>
}) {

  const {t} = useTranslation();
  const setMessage = useMessageContext();

  const [
    isDrawerOpen,
    setIsDrawerOpen,
  ] = useState(false);

  const [
    editTemplate,
    {
      isSuccess: isEditSuccess,
      isError: hasEditFailed,
    },
  ] = useEditTemplateEmailMutation();
  useEffect(() => {
    if (isEditSuccess) {
      setIsDrawerOpen(false);
    }
    if (hasEditFailed) {
      setMessage({error: capitalize(t('form.creationFailMessage'))});
    }
  }, [hasEditFailed, isEditSuccess, setMessage, t]);

  return <TemplateMailForm
    title={capitalize(t('actions.editItem', {item: t('mailTemplate.mailTemplate')}))}
    drawer={{
      open: isDrawerOpen,
      onClose: () => {
        setIsDrawerOpen(false);
        formInstance.resetFields();
      },
    }}
    form={{
      form: formInstance,
      initialValues: {
        title: template.title,
        tags: template.tags.map(tag => tag.uuid),
        language: template.language,
        text: template.text,
      },
    }}
    drawerButton={{
      icon: <EditOutlined/>,
      type: 'text',
      onClick: () => setIsDrawerOpen(true),
    }}
    confirmButton={{
      children: capitalize(t('actions.editItem', {item: t('mailTemplate.template')})),
      onClick: () => {
        formInstance
          .validateFields()
          .then((values) => {
            editTemplate({
              templateUuid: template.uuid,
              ...values,
            });
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      },
    }}
  />;
}