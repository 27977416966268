import {Button} from 'antd';
import {CameraOutlined} from '@ant-design/icons';
import {useMap} from 'react-leaflet';
import html2canvas from 'html2canvas';
import {useState} from 'react';
import {useEventContext} from '../../../../context/event/EventContext';
import dayjs from 'dayjs';
import {dateToLocaleString} from '../../../../utils/dateParsers';
import {useGetUserProfileQuery} from '../../../../redux/api/locales';
import {useTranslation} from 'react-i18next';

export const ScreenshotButton = () => {
  
  const {t} = useTranslation();
  
  const map = useMap();
  const event = useEventContext();
  
  const {
    data: userData,
  } = useGetUserProfileQuery();
  
  const [
    isLoading,
    setIsLoading,
  ] = useState(false);
  
  const handleScreenshot = () => {
    setIsLoading(true);
    
    html2canvas(map.getContainer() /*document.getElementsByTagName('body')[0]*/, {
      allowTaint: true,
      useCORS: true,
      ignoreElements: (node: Element) => {
        return node.id === 'map-controls';
      },
    }).then(canvas => {
        if (userData) {
          const dataUrl = canvas.toDataURL();
          const link = document.createElement('a');
          link.href = dataUrl;
          link.download = [
            'event',
            event.event?.code,
            dateToLocaleString(dayjs().toISOString(), userData.language, 'HH:mm:ss'),
          ]
            .filter(components => components !== undefined)
            .join('_');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .finally(() => setIsLoading(false));
  };
  
  return (
    <>
      <Button
        loading={isLoading}
        icon={<CameraOutlined/>}
        size={'large'}
        title={t('actions.take_map_screenshot')}
        onClick={handleScreenshot}
      />
    </>
  );
};