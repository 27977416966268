import MapLoading from "../../common/map/MapLoading";
import React from "react";
import AlertStepDrawControlWrapper from "./AlertStepDrawControlWrapper";
import {GoogleLayers} from "../../../types/internal/Map";
import {BaseWMSLayer} from "../../common/map/BaseWMSLayer";
import {useBaseWMSLayerContext} from "../../../context/baseMap/BaseWMSLayerContext";
import {BaseMapContainer} from "../../common/map/BaseMapContainer";

export default function AlertStepMap() {

  const [layers, currentWMSLayer] = useBaseWMSLayerContext();
  const WMSLayer = layers?.find(layer => layer.uuid === currentWMSLayer);

  return <BaseMapContainer
    withZoom={true}
    withGeoSearch={true}
    withLayerPicker={true}
    withBaseLayers={[GoogleLayers.roadmap, GoogleLayers.hybrid]}
    withWMSLayers={layers || []}
    withSoftLoading={true}
    withQuerableLayers={true}
    withLegend={true}
    withPopoverConfiguration={{overlayInnerStyle: {width: 320}}}
    isStandAlone={true}
    withStyle={{width: '100%', height: '47vh'}}
    withAssetFilterFromLayer={true}
  >
    {!!WMSLayer && (
      <BaseWMSLayer
        url={WMSLayer.wms_baseurl}
        layers={WMSLayer.name}
        isInternal={WMSLayer.is_internal || undefined}
        key={WMSLayer.uuid}
      />
    )}
    <AlertStepDrawControlWrapper/>
    <MapLoading/>
  </BaseMapContainer>
}