import {capitalize} from 'lodash';
import {Button} from 'antd';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

export default function AlertSuggestionButton() {
  const {t} = useTranslation();

  return <Link
    to={'/alerts-suggestion'}
  >
    <Button>
      {capitalize(t('alerts.suggestion'))}
    </Button>
  </Link>;
}