import {useDispatch, useSelector} from 'react-redux';
import ResetFiltersSortersButton from '../../../common/filters/ResetFiltersSortersButton';
import {
  resetArchivedEventsTableFiltersSorters,
  selectArchivedEventsTableFilters,
  selectArchivedEventsTableSorters,
} from '../../../../redux/slices/events';

export default function ResetArchivedEventsTableFiltersAndSorters() {
  const dispatch = useDispatch();

  const filters = useSelector(selectArchivedEventsTableFilters);
  const sorters = useSelector(selectArchivedEventsTableSorters);
  const handleConfirm = () => dispatch(resetArchivedEventsTableFiltersSorters());
  const isDisabled = Object.values(filters).every(el => el === null) && Object.values(sorters).every(el => el === null);

  return <ResetFiltersSortersButton
    isDisabled={isDisabled}
    onConfirm={handleConfirm}
  />;
}