import {Button, ButtonProps} from 'antd';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import {useConnectAlertToEventMutation} from '../../redux/api/alerts';
import {useMessageContext} from '../../context/message/MessageContext';
import React from 'react';

export default function ConnectAlertToEventButton({selectedAlerts, eventUuid, button, afterConnect}: {
  selectedAlerts: React.Key[],
  eventUuid: string,
  button?: ButtonProps,
  afterConnect?: () => void
}) {

  const {t} = useTranslation();
  const [connect, {isLoading}] = useConnectAlertToEventMutation();
  const setMessage = useMessageContext();

  const handleConnect = async () => {
    try {
      const promises = selectedAlerts.map(uuid => connect({alertUuid: uuid as string, eventUuid}).unwrap());
      await Promise.all(promises);
      setMessage({success: capitalize(t('alerts.connectToEventSuccessMessage'))});
    } catch {
      setMessage({error: capitalize(t('alerts.connectToEventFailMessage'))});
    } finally {
      if (afterConnect)
        afterConnect();
    }
  };

  return <Button
    type={'primary'}
    loading={isLoading}
    disabled={isLoading}
    onClick={handleConnect}
    {...button}
  >
    {capitalize(t('alerts.connectToEvent'))}
  </Button>;
}