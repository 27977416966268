import {createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState} from 'react';

export type AssetDrawerContextType = [
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
];

const AssetDrawer = createContext<AssetDrawerContextType>([
  false,
  () => null,
]);

export const useAssetDrawerContext = () => useContext(AssetDrawer);

export const AssetDrawerContext = (props: PropsWithChildren) => {

  const [
    isOpen,
    setIsOpen,
  ] = useState(false);

  return (<AssetDrawer.Provider value={[isOpen, setIsOpen]}>
    {props.children}
  </AssetDrawer.Provider>);
};