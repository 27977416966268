import {useParams} from 'react-router-dom';
import EventContext from "../context/event/EventContext";
import EventLive from "../components/event/EventLive";
import EventEdit from "../components/event/edit/EventEdit";
import EventCreate from "../components/event/EventCreate";
import PermissionRoute from "../components/Authentication/PermissionRoute";
import {isEventCreation, isEventEdit, isEventView} from "../components/Authentication/permissions";

export const EventRedirect = () => {

  const {uuid} = useParams();

  return <EventContext eventUuid={uuid}>
    <PermissionRoute isAuthorized={isEventCreation}>
      <EventCreate/>
    </PermissionRoute>
    <PermissionRoute isAuthorized={isEventView}>
      <EventLive/>
    </PermissionRoute>
    <PermissionRoute isAuthorized={isEventEdit}>
      <EventEdit/>
    </PermissionRoute>
  </EventContext>
};