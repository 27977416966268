import {Button, ButtonProps, Col, ConfigProvider, Modal, ModalProps, Row, Space, Table, Typography} from 'antd';
import {useGetImportReportDetailQuery} from '../../../redux/api/importReport';
import {ColumnsType} from 'antd/lib/table';
import {capitalize} from 'lodash';
import {getTranslationAssetTableColumnKey} from '../../../utils/translation';
import {useTranslation} from 'react-i18next';
import {ArrowLeftOutlined} from '@ant-design/icons';
import React from 'react';
import {TitleProps} from 'antd/lib/typography/Title';
import {Comparison} from '../../../utils/dataImport';
import PermissionRoute from '../../../components/Authentication/PermissionRoute';
import {isImportDataEdit} from '../../../components/Authentication/permissions';

export default function ImportReportDetailModal({reportUuid, detailUuid, modal, assetButton, closeModalButton, title}: {
  reportUuid: string,
  detailUuid: string,
  modal?: ModalProps,
  assetButton?: ButtonProps,
  closeModalButton?: ButtonProps,
  title?: TitleProps
}) {

  const {data, isLoading, isUninitialized} = useGetImportReportDetailQuery({reportUuid, detailUuid});
  const rows: Comparison[] = data?.extra_data.comparison || [];
  const {t} = useTranslation();
  const loading = isLoading || isUninitialized;

  const columns: ColumnsType<Comparison> = [
    {
      key: 'field',
      dataIndex: 'field',
      title: capitalize(t('dataImport.comparison.field')),
      render: value => capitalize(t(getTranslationAssetTableColumnKey(value))),
    },
    {
      key: 'csv',
      dataIndex: 'csv',
      title: capitalize(t('dataImport.comparison.csv')),
    },
    {
      key: 'geocoder',
      dataIndex: 'geocoder',
      title: capitalize(t('dataImport.comparison.geocoder')),
    },
  ];

  return <>
    <Modal
      footer={null}
      centered
      closable={false}
      {...modal}
    >
      <Row justify={'space-between'} style={{marginBottom: '2rem'}} align={'middle'}>
        <Col>
          <Space style={{padding: '1rem 0'}}>
            <Button type={'link'} style={{padding: 0}} icon={<ArrowLeftOutlined/>} {...closeModalButton} />
            <Typography.Title level={3} style={{margin: 0}} {...title}/>
          </Space>
        </Col>
        <PermissionRoute isAuthorized={isImportDataEdit}>
          <Col>
            <Button
              type={'link'}
              style={{padding: 0}}
              {...assetButton}
            >
              {capitalize(t('actions.editItem', {item: t('assets.asset', {count: 1})}))}
            </Button>
          </Col>
        </PermissionRoute>
        <Col xs={24}>
          <Typography.Text type={'secondary'}>{data?.extra_data.address_string}</Typography.Text>
        </Col>
      </Row>

      <ConfigProvider
        theme={{
          components: {
            Table: {
              rowHoverBg: '',
            },
          },
        }}
      >
        <Table
          <Comparison>
          dataSource={rows}
          columns={columns}
          pagination={false}
          loading={loading}
          rowKey={'field'}
          rowClassName={(record, index) => {
            switch (record.status) {
              case 'WARNING':
                return 'warning-row';
              case 'ERROR':
                return 'error-row';
              default:
                return '';
            }
          }}
        />
      </ConfigProvider>
    </Modal>
  </>;
}