import {capitalize} from "lodash";
import {Button, Col, Row, StepProps, Steps, Typography} from "antd";
import {useTranslation} from "react-i18next";
import {useEventContext} from "../../../context/event/EventContext";
import React, {ReactElement} from "react";
import EventCreationEditCard from "../creation/EventCreationEditForm";
import EventCreationAlertsSection from "../creation/eventAlerts/EventCreationAlertsSection";
import EpicenterCard from "../epicenter/EpicenterCard";
import EventClusters from "../clusters/EventClusters";
import InsertEpicenter from "../epicenter/InsertEpicenter";
import ImportAlerts from "../importAlertsAreas/ImportAlerts";
import {BaseMapContainer} from "../../common/map/BaseMapContainer";
import {GoogleLayers} from "../../../types/internal/Map";
import EventChangesSummary from "../creation/EventChangesSummary";
import EventEditMapRecap from "../../event/map/eventEditMapRecap/EventEditMapRecap";
import AssetsToVerify from "./AssetsToVerify";
import {AssetDrawerContext} from "../../../context/alertDetails/AssetDrawerContext";
import {AssetContext} from "../../../context/assetDetails/AssetContext";
import {useEditStepsContext} from "../../../context/event/EditSteps";
import {PHASE} from "../../../types/api/getEventsResult";
import ConfirmChanges from "./ConfirmChanges";
import {BaseMapContext} from "../../../context/baseMap/BaseMapContext";
import DiscardAllChangesButton from "./DiscardAllChangesButton";

export default function EventEditSteps() {

  const {t} = useTranslation();
  const {isEditing, assets, eventPolygons, event} = useEventContext()
  const {step, setStep} = useEditStepsContext()

  const disabledInCreation = event?.phase === PHASE.CREATE &&
    !assets?.filter(asset => asset.cluster_template).length &&
    !eventPolygons?.length
  const nextIsDisabled =
    !(
      (step !== 1) ||
      !(step === 1 && disabledInCreation)
    )
  const next = () => {
    setStep(curr => curr + 1);
  };
  const prev = () => {
    setStep(curr => curr - 1);
  };

  const steps: (StepProps & { content: ReactElement })[] = [
      // step 0
      {
        title: capitalize(t('events.stepCreationData')),
        content: <>
          <EventCreationEditCard/>
          <EventCreationAlertsSection/>
        </>,
      },
      // step 1
      {
        title: capitalize(t('events.stepCreationAreas')),
        content: <BaseMapContext>
          <Row gutter={16}>
            <Col xs={24} md={10} style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem'
            }}>
              {!!event?.epicenter && <>
                <Typography.Title
                  level={4}
                  style={{margin: 0}}>
                  {capitalize(t('events.epicenter'))}
                </Typography.Title>
                <EpicenterCard/>
              </>}
              <EventClusters/>
            </Col>
            <Col xs={24} md={14}>
              <Row justify={'end'} gutter={[8, 8]} style={{marginBottom: '1rem'}}>
                <Col>
                  <DiscardAllChangesButton />
                </Col>
                {!event?.epicenter && <Col>
                  <InsertEpicenter/>
                </Col>}
                <Col>
                  <ImportAlerts/>
                </Col>
              </Row>
              <BaseMapContainer
                isStandAlone={true}
                withZoom={true}
                withGeoSearch={true}
                withLayerPicker={true}
                withBaseLayers={[GoogleLayers.roadmap, GoogleLayers.hybrid]}
                withStyle={{width: '100%', height: '70vh'}}
              >
                <EventEditMapRecap/>
              </BaseMapContainer>
            </Col>
          </Row>
        </BaseMapContext>,
      },
      // step 2 => if update index update refetch in assetsToVerify
      ...isEditing ? [{
        title: capitalize(t('events.assetsToVerify')),
        content: <AssetDrawerContext>
          <AssetContext>
            <AssetsToVerify/>
          </AssetContext>
        </AssetDrawerContext>,
        disabled: disabledInCreation
      }] : [],
      // step 3
      {
        title: capitalize(t('events.stepCreationSummary')),
        content: <EventChangesSummary/>,
        disabled: disabledInCreation
      },
    ]
  ;

  return <>
    <Steps
      current={step}
      items={steps}
      onChange={v => setStep(v)}
    />
      {steps[step].content}
      <Row
        justify={'end'}
      >
        {step > 0 && (
          <Col>
            <Button style={{margin: '0 8px'}} onClick={() => prev()}>
              {capitalize(t('form.previous'))}
            </Button>
          </Col>
        )}
        {step < steps.length - 1 && (
          <Col>
            <Button disabled={nextIsDisabled} type="primary" onClick={() => next()}>
              {capitalize(t('form.next'))}
            </Button>
          </Col>
        )}
        {step === steps.length - 1 && (
          <Col>
            <ConfirmChanges/>
          </Col>
        )}
      </Row>
  </>
}