import {Badge, Button, ConfigProvider, FloatButtonProps} from 'antd';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
import {useStreetViewContext} from '../../../../context/baseMap/StreetViewContext';
import React from 'react';
import {ManIcon} from '../../customIcon/ManIcon';
import {orange} from '@ant-design/colors';

export const StreetViewButton = (props: FloatButtonProps) => {

  const {t} = useTranslation();

  const [
    isInStreetViewMode,
    setIsInStreetViewMode,
  ] = useStreetViewContext();

  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgContainerDisabled: '#F5F5F5',
        },
      }}
    >
      <Badge
        dot={true}
        count={+isInStreetViewMode}
      >
        <Button
          icon={<ManIcon style={{fill: orange[4]}}/>}
          style={{border: +isInStreetViewMode ? '1.5px solid #4096ff' : '1px solid #d9d9d9'}}
          size={'large'}
          title={capitalize(t('map.streetViewTooltip'))}
          onClick={() => {
            setIsInStreetViewMode(streetViewMode => !streetViewMode);
            //document.body.style.cursor = require("../../../common/icons/man.svg");
          }}
        />
      </Badge>
    </ConfigProvider>
  );
};