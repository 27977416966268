import {PlusOutlined} from '@ant-design/icons';
import {capitalize} from 'lodash';
import {Button} from 'antd';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {isAlertCreation} from '../../Authentication/permissions';
import PermissionRoute from '../../Authentication/PermissionRoute';

export default function CreateAlertButton() {
  const {t} = useTranslation();
  return <PermissionRoute
    isAuthorized={isAlertCreation}
  >
    <Link
      to={'/alerts/create'}
    >
      <Button
        type={'primary'}
        icon={<PlusOutlined/>}
      >
        {capitalize(t('actions.createItem', {item: t('alerts.alert')}))}
      </Button>
    </Link>
  </PermissionRoute>;
}