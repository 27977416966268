import {useTranslation} from 'react-i18next';
import React, {useEffect, useState} from 'react';
import {Button} from 'antd';
import {capitalize} from 'lodash';
import EventTypeDrawer from './EventTypeDrawer';
import {useCreateEventTypeMutation} from '../../../redux/api/eventTypes';
import {useMessageContext} from '../../../context/message/MessageContext';

export default function CreateEventType() {

  const {t} = useTranslation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [createEvent, {isSuccess, isError, isLoading}] = useCreateEventTypeMutation();
  const setMessage = useMessageContext();

  useEffect(() => {
    if (isSuccess) {
      setMessage({success: capitalize(t('form.creationSuccessMessage'))});
      setIsDrawerOpen(false);
    }
  }, [isSuccess, setMessage, t]);

  useEffect(() => {
    if (isError) {
      setMessage({error: capitalize(t('form.creationFailMessage'))});
    }
  }, [isError, setMessage, t]);

  return <>
    <Button
      type={'primary'}
      onClick={() => setIsDrawerOpen(true)}
    >
      {capitalize(t('actions.insertItem', {item: t('events.eventType', {count: 1})}))}
    </Button>
    <EventTypeDrawer
      isDrawerOpen={isDrawerOpen}
      setIsDrawerOpen={setIsDrawerOpen}
      headerTitle={capitalize(t('actions.insertItem', {item: t('events.eventType', {count: 1})}))}
      confirmButton={{
        loading: isLoading,
        children: capitalize(t('actions.createItem', {item: t('misc.typology')})),
      }}
      onFinish={values => {
        createEvent(values);
      }}
    />
  </>;
}