import React, {ReactElement, useRef} from "react";
import {useEventContext} from "../../../context/event/EventContext";
import {CustomMarkerClusterGroup} from "../../assets/assetsMap/CustomMarkerClusterGroup";
import {Marker as LeafletMarker} from "leaflet";
import {CustomMarker} from "../../common/map/CustomMarker";
import {EventAssetDetailsPopup} from "./EventAssetDetailsPopup";
import {notAssignedAssetColor} from "../../../utils/colors";
import {ClusterAsset} from "../../../context/event/EventContextTypes";
import {AssetContext} from "../../../context/assetDetails/AssetContext";
import {AssetDrawerContext} from "../../../context/alertDetails/AssetDrawerContext";
import {AssetDetailsDrawer} from "../../asset/assetDrawer/AssetDetailsDrawer";
import {useTranslation} from "react-i18next";

function EventAssetMarker({asset, isAssignCluster = false}: { asset: ClusterAsset, isAssignCluster?: boolean }) {

  const {uuid, color, position} = asset
  const markerRef = useRef<LeafletMarker | null>(null);

  return <CustomMarker
    key={uuid}
    ref={markerRef}
    position={position}
    outerColor={color}
  >
    <EventAssetDetailsPopup markerUuid={uuid} isAssignCluster={isAssignCluster}/>
  </CustomMarker>
}

export default function EventAssetMarkers({type = 'all', isAssignCluster = false}: {
  type?: 'all' | 'onlyEvent',
  isAssignCluster?: boolean
}) {

  const {assets, clusters, event} = useEventContext()
  const eventUuid = event?.uuid
  const {t} = useTranslation()

  const assignedToClusterAssets: ClusterAsset[] = [];
  const notAssignedToClusterAssets: ClusterAsset[] = [];

  assets.forEach(asset => {
    asset.cluster_template ? assignedToClusterAssets.push(asset) : notAssignedToClusterAssets.push(asset)
  })

  const clusterGroups = (clusters || []).reduce((result, cluster) => {
    const clusterAssets = assignedToClusterAssets.filter(asset => asset.cluster_template === cluster.uuid)
    return clusterAssets.length ?
      [...result,
        <CustomMarkerClusterGroup key={cluster.uuid} color={cluster.color_code}>
          {clusterAssets.map(clusterAsset => <EventAssetMarker
            key={clusterAsset.uuid}
            asset={clusterAsset}
            isAssignCluster={isAssignCluster}/>)}
        </CustomMarkerClusterGroup>] :
      result
  }, [] as ReactElement[]) || []

  return <>
    <AssetDrawerContext>
      <AssetContext>
        <AssetDetailsDrawer
          goBack={{
            path: `/events/${eventUuid}`,
            label: t('events.event', {count: 1}),
          }}
        />
        {clusterGroups}
        {type === 'all' && <CustomMarkerClusterGroup color={notAssignedAssetColor}>
          {notAssignedToClusterAssets.map(clusterAsset => <EventAssetMarker
            key={clusterAsset.uuid}
            asset={clusterAsset}
            isAssignCluster={isAssignCluster}/>)}
        </CustomMarkerClusterGroup>}
      </AssetContext>
    </AssetDrawerContext>
  </>
}