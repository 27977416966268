import {Button, Col, ColorPicker, Form, Input, Modal, Row} from 'antd';
import {capitalize} from 'lodash';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import AntFormItem from '../../common/AntFormItem';
import {ClusterCreationParams} from '../../../types/api/clusters';
import {useForm} from 'antd/lib/form/Form';
import {useCreateClusterTemplateMutation} from '../../../redux/api/clusters';
import {useMessageContext} from '../../../context/message/MessageContext';
import {useParams} from 'react-router-dom';
import {ClusterForm} from '../../../types/internal/clusters';
import {colorPickerDefault, hexString} from '../../../utils/colors';

export default function CreateCluster() {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const {t} = useTranslation();
  const [form] = useForm();
  const [createCluster, {isSuccess, isLoading, isError}] = useCreateClusterTemplateMutation();
  const setMessage = useMessageContext();
  const {uuid: eventUuid} = useParams();
  const {resetFields} = form;

  const handleFinish = (values: ClusterForm) => {
    if (eventUuid) {
      const parsedValues: ClusterCreationParams = {
        ...values,
        color_code: hexString(values.color_code),
        eventUuid,
      };
      createCluster(parsedValues);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setMessage({success: capitalize(t('form.creationSuccessMessage'))});
      setIsModalOpen(false);
    }
    return () => {
      resetFields();
    };
  }, [isSuccess, resetFields, setMessage, t]);

  useEffect(() => {
    if (isError) {
      setMessage({error: capitalize(t('form.creationFailMessage'))});
    }
  }, [isError, setMessage, t]);

  return <>
    <Button
      type={'primary'}
      onClick={() => setIsModalOpen(true)}
    >
      {capitalize(t('actions.insertItem', {item: 'cluster'}))}
    </Button>
    <Modal
      open={isModalOpen}
      footer={<Row justify={'end'}>
        <Col>
          <Form
            <ClusterForm>
            form={form}
            onFinish={handleFinish}
            initialValues={{color_code: colorPickerDefault}}
          >
            <Form.Item>
              <Button
                htmlType={'submit'}
                type={'primary'}
                disabled={isLoading}
                loading={isLoading}
              >
                {capitalize(t('actions.create'))}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>}
      onCancel={() => setIsModalOpen(false)}
      title={<>
        {capitalize(t('actions.createItem', {item: t('clusters.cluster', {count: 1})}))}
      </>}
      styles={{
        header: {
          padding: '0.875rem 0',
          margin: 0,
          borderBottom: '1px solid #D9D9D9',
        },
        body: {
          padding: '1rem 0',
        },
      }}
      closeIcon={false}
    >
      <Form
        <ClusterForm>
        form={form}
        layout={'vertical'}
      >
        <AntFormItem
          name={'name_it'}
          label={capitalize(t('clusters.name_it'))}
          rules={[
            {
              required: true,
              message: capitalize(t('clusters.clusterNameRequired')),
              validator: (_, value) => {
                if (!value || value.trim() === '') {
                  return Promise.reject();
                }
                return Promise.resolve();
              },
            }]}
        >
          <Input/>
        </AntFormItem>
        <AntFormItem
          name={'name_en'}
          label={capitalize(t('clusters.name_en'))}
          rules={[
            {
              required: true,
              message: capitalize(t('clusters.clusterNameRequired')),
              validator: (_, value) => {
                if (!value || value.trim() === '') {
                  return Promise.reject();
                }
                return Promise.resolve();
              },
            }]}
        >
          <Input/>

        </AntFormItem>
        <AntFormItem
          name={'description_it'}
          label={capitalize(t('clusters.description_it'))}
        >
          <Input.TextArea/>
        </AntFormItem>
        <AntFormItem
          name={'description_en'}
          label={capitalize(t('clusters.description_en'))}
        >
          <Input.TextArea/>
        </AntFormItem>
        <AntFormItem
          name={'color_code'}
          label={capitalize(t('clusters.color'))}
          rules={[{required: true}]}
        >
          <ColorPicker
            size={'large'}
            showText
            disabledAlpha
            format={'hex'}
          />
        </AntFormItem>
      </Form>
    </Modal>
  </>;
}