import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '../store';

// Define a type for the slice state
interface LayoutState {
  isProfileVisible: boolean;
}

// Define the initial state using that type
const initialState = {
  isProfileVisible: false,
} as LayoutState;

export const layoutSlice = createSlice({
  name: 'layout',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    invertProfileVisibility: (state) => {
      state.isProfileVisible = !state.isProfileVisible;
    },
    setProfileVisibility: (state, {payload: {isVisible}}: PayloadAction<{ isVisible: boolean }>) => {
      state.isProfileVisible = isVisible;
    },
  },
});

export const {invertProfileVisibility, setProfileVisibility} = layoutSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectLayoutSlice = (state: RootState) => state.layout;
export default layoutSlice.reducer;
