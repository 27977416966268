import {createContext, PropsWithChildren, useContext, useState} from 'react';
import {ASSET_MAP_ACTIONS} from '../../components/common/map/constants/map';

export type AssetMapActionType = {
  currentAction: ASSET_MAP_ACTIONS,
  actionEvent?: any
}

export type AssetMapContextType = {
  action: AssetMapActionType,
  changeAction: (newAction: ASSET_MAP_ACTIONS, event?: any) => void
}

const ControlsActionContext = createContext<AssetMapContextType>({
  action: {currentAction: ASSET_MAP_ACTIONS.PAN},
  changeAction: () => null,
});

export const useControlsAction = () => useContext(ControlsActionContext);

export const ControlContext = (props: PropsWithChildren) => {
  const [action, setAction] = useState<AssetMapActionType>({currentAction: ASSET_MAP_ACTIONS.PAN, actionEvent: null});

  const changeAction = (newAction: ASSET_MAP_ACTIONS, event?: any) => {
    setAction({currentAction: newAction, actionEvent: event});
  };

  return (
    <ControlsActionContext.Provider value={{action, changeAction}}>
      {props.children}
    </ControlsActionContext.Provider>
  );
};