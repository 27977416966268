import {Button, Col, Divider, Row, Select, Space, Typography} from 'antd';
import {useGetUserProfileQuery, useUpdateLocaleMutation} from '../../redux/api/locales';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
import {useNavigate} from 'react-router-dom';
import {CSSProperties} from 'react';
import {CaretDownOutlined, QuestionCircleOutlined} from '@ant-design/icons';
import {useMessageContext} from "../../context/message/MessageContext";

const {Text, Link} = Typography;
const GUIDE_URL = 'https://drive.google.com/file/d/1WzdfTUXupOOHJjpF0H6GxoaG8m-0EQ-0/view';
export default function Profile({style}: { style?: CSSProperties }) {

  const {t} = useTranslation();
  const setMessage = useMessageContext()
  const navigate = useNavigate();

  const {data} = useGetUserProfileQuery();
  const currentLanguage = data?.language;
  const [updateLocale] = useUpdateLocaleMutation();

  return (
    <div
      id={'profile'}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      style={{
        position: 'fixed',
        top: 60,
        right: 6,
        borderRadius: 8,
        zIndex: 1000000,
        width: 290,
        backgroundColor: '#FFFFFF',
        padding: '1rem',
        boxShadow: '0px 2px 8px 0px #00000026',
        ...style,
      }}
    >
      <Row justify={'center'}>
        <Col>
          <Text strong>
            {data?.full_name}
          </Text>
        </Col>
      </Row>
      <Row justify={'center'}>
        <Col>
          <Text>
            {data?.email}
          </Text>
        </Col>
      </Row>
      {!!data?.type_name && <Row justify={'center'} style={{margin: '0.5rem 0'}}>
        <Col>
          <Text type={'secondary'}>
            {data?.type_name}
          </Text>
        </Col>
      </Row>}
      <Row style={{marginTop: '0.5rem'}}>
        <Col flex={1}>
          <Select
            getPopupContainer={triggerNode => triggerNode.parentElement}
            style={{width: '100%'}}
            value={currentLanguage}
            options={[
              {label: 'English', value: 'en'},
              {label: 'Italiano', value: 'it'},
            ]}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            onSelect={lng => {
              updateLocale({language: lng});
            }}
            suffixIcon={<CaretDownOutlined/>}
          />
        </Col>
      </Row>
      <Row
        style={{marginTop: '0.5rem'}}
        justify={'center'}
      >
        <Col>
          <Link
            href={GUIDE_URL}
            target={'_blank'}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Space direction={'horizontal'} size={'small'}>
              {capitalize(t('misc.guide'))}
              <QuestionCircleOutlined/>
            </Space>
          </Link>
        </Col>
      </Row>
      <Divider/>
      <Row justify={'center'}>
        <Col>
          <Button
            type={'link'}
            danger
            block
            onClick={() => {
              setMessage({success: capitalize(t('authentication.logoutSuccess'))})
              navigate('logout');
            }}
          >
            {capitalize(t('authentication.logout'))}
          </Button>
        </Col>
      </Row>
    </div>
  );
}