import {Avatar, Button, Card, Col, List, Row, Space, Typography} from "antd";
import {Cluster} from "../../../types/api/clusters";
import EditButton from "../../common/buttons/EditButton";
import React from "react";
import {capitalize} from "lodash";
import {useTranslation} from "react-i18next";
import DeleteEventCluster from "./DeleteEventCluster";

function ClusterItem({cluster, type, onCreate, onEdit, onRemove, selected = false}: {
  cluster: Cluster,
  type: 'edit' | 'create',
  onCreate?: (cluster: string) => void
  onEdit?: (cluster: string) => void,
  onRemove?: (cluster: string) => void,
  selected?: boolean
}) {

  const {t} = useTranslation()

  return (
    <List.Item>
      {type === 'edit' && <Card>
        <Row justify={'space-between'} align={'middle'} gutter={[24,24]}>
          <Col>
            <Space align={'center'}>
              <Avatar style={{backgroundColor: cluster.color_code}}/>
              <Typography.Text strong>{cluster.name}</Typography.Text>
            </Space>
          </Col>
          <Col>
            <Space align={'center'} wrap>
              <DeleteEventCluster
                deleteCluster={() => onRemove && onRemove(cluster.uuid)}
                clusterName={cluster.name}
              />
              <EditButton
                disabled={selected}
                loading={selected}
                onClick={() => onEdit && onEdit(cluster.uuid)}
              />
            </Space>
          </Col>
        </Row>
      </Card>}
      {type === 'create' && <Card>
        <Row justify={'space-between'} align={'middle'} gutter={[24,24]}>
          <Col>
            <Space align={'center'}>
              <Avatar style={{backgroundColor: cluster.color_code}}/>
              <Typography.Text strong>{cluster.name}</Typography.Text>
            </Space>
          </Col>
          <Col>
            <Button onClick={() => onCreate && onCreate(cluster.uuid)}>
              {capitalize(t('events.insertCluster'))}
            </Button>
          </Col>
        </Row>
      </Card>}
    </List.Item>
  )
}

export default function ClustersList({clusters, type, onCreate, onEdit, onRemove, selectedCluster}: {
  clusters: Cluster[],
  type: 'edit' | 'create',
  onCreate?: (cluster: string) => void
  onEdit?: (cluster: string) => void,
  onRemove?: (cluster: string) => void,
  selectedCluster: string | undefined
}) {
  return <List
    rowKey={'uuid'}
    dataSource={clusters}
    grid={{gutter: 16, column: 1}}
    renderItem={(cluster) => (
      <ClusterItem
        selected={selectedCluster === cluster.uuid}
        type={type}
        cluster={cluster}
        onCreate={onCreate}
        onEdit={onEdit}
        onRemove={onRemove}
      />
    )}
  />
}