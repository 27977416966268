import {useBaseTileLayerContext} from '../../../context/baseMap/BaseTileLayerContext';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import {useMap, useMapEvents} from 'react-leaflet';
import {useLayoutEffect, useState} from 'react';
import {useStreetViewContext} from '../../../context/baseMap/StreetViewContext';
import {Modal} from 'antd';

export const BaseLayer = () => {
  const [baseLayer] = useBaseTileLayerContext();
  const [
    isInStreetViewMode,
    setIsInStreetViewMode,
  ] = useStreetViewContext();

  const map = useMap();

  const [
    isStreetViewModalOpen,
    setIsStreetViewModalOpen,
  ] = useState(false);
  const [
    coordinates,
    setCoordinates,
  ] = useState<{ lat: number, lng: number } | null>(null);

  useLayoutEffect(() => {
    map.invalidateSize();
  });

  useMapEvents({
    click(e) {
      if (isInStreetViewMode) {
        setIsStreetViewModalOpen(true);
        setCoordinates(e.latlng);
        setIsInStreetViewMode(false);
      }
    },
  });

  return (
    <>
      <Modal
        open={isStreetViewModalOpen}
        closable={true}
        centered={true}
        width={'75%'}
        footer={null}
        afterOpenChange={isOpen => {
          if (isOpen) {
            new window.google.maps.StreetViewPanorama(
              document.getElementById('street-view') as HTMLElement,
              {
                position: coordinates,
                pov: {heading: 165, pitch: 0},
                zoom: 1,
              },
            );
          }
        }}
        onCancel={() => setIsStreetViewModalOpen(false)}
      >
        <div
          id={'street-view'}
          style={{width: '100%', maxHeight: '100%', height: '600px', marginTop: '1.5rem'}}
        />
      </Modal>
      <ReactLeafletGoogleLayer
        type={baseLayer}
        key={baseLayer}
        apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      />
    </>
  );
};