import {Button, Col, Divider, Popover, Row} from 'antd';
import {FilterFilled} from '@ant-design/icons';
import _, {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import {addFilters, removeFilters, selectAssetsTableFilters} from '../../../redux/slices/assets';
import {useDispatch, useSelector} from 'react-redux';
import {
  AssetsTableColumn,
  getFiltersOfTheAssetsTableStaticColumn,
  isAssetsTableStaticColumn,
} from '../../../types/internal/tables/assetsTable';
import DatesFilter from '../../common/filters/DatesFilter';
import NumbersFilter from '../../common/filters/NumbersFilter';
import StandardFilter from '../../common/filters/StandardFilter';
import {BIODIVERSITY_WITHIN} from "../../../types/api/assets";
import {VALUE_NOT_PRESENT} from "../../../utils/translation";
import BiodiversityDistance from "./filters/BiodiversityDistance";
import BiodiversitySourceFilter from "./filters/BiodiversitySourceFilter";
import React from "react";
import AssetRelatedOptionsFilter from "./filters/AssetRelatedOptionsFilter";

export default function AssetsTableFilter({column}: {
  column: Exclude<AssetsTableColumn, 'map_view'>,
}) {

  const {t} = useTranslation();
  const dispatch = useDispatch();
  const activeFilters = useSelector(selectAssetsTableFilters);

  let filter = <></>;
  if (isAssetsTableStaticColumn(column)) {
    switch (column) {
      case 'biodiversity_within':
        filter = <>
          <BiodiversityDistance/>
          <BiodiversitySourceFilter/>
          <Divider/>
          <StandardFilter
            selectedFilters={activeFilters.biodiversity_within}
            filters={[
              {label: capitalize(t('misc.yes')), value: BIODIVERSITY_WITHIN.IN},
              {label: capitalize(t('misc.no')), value: BIODIVERSITY_WITHIN.OUT},
              {label: VALUE_NOT_PRESENT, value: BIODIVERSITY_WITHIN.NA},
            ]}
            onChange={(values) => {
              if (values.length) {
                dispatch(addFilters({biodiversity_within: values}));
              } else {
                dispatch(removeFilters('biodiversity_within'));
              }
            }}
          />
        </>
        break;
      case 'surface_basement':
      case 'surface_total':
      case 'total_resources':
        filter = <NumbersFilter
          numericProps={{
            suffix: column !== 'total_resources' ? ' mq' : '',
          }}
          onNullChange={(v) => {
            if (v) {
              dispatch(addFilters({[`is_${column}_null`]: v}));
              dispatch(removeFilters([`${column}_min`, `${column}_max`]));
            } else {
              dispatch(removeFilters(`is_${column}_null`));
            }
          }}
          onMinChange={(value) => {
            if (typeof value === 'number') {
              dispatch(addFilters({[`${column}_min`]: value}));
            } else {
              dispatch(removeFilters(`${column}_min`));
            }
          }}
          onMaxChange={(value) => {
            if (typeof value === 'number') {
              dispatch(addFilters({[`${column}_max`]: value}));
            } else {
              dispatch(removeFilters(`${column}_max`));
            }
          }}
          min={activeFilters[`${column}_min`]}
          max={activeFilters[`${column}_max`]}
          isNull={activeFilters[`is_${column}_null`]}
        />;
        break;

      case 'opening_date':
      case 'closing_date':
        filter = <DatesFilter
          isNull={activeFilters[`is_${column}_null`]}
          start={activeFilters[`${column}_after`]}
          end={activeFilters[`${column}_before`]}
          onNullChange={(value) => {
            if (value) {
              dispatch(addFilters({[`is_${column}_null`]: value}));
              dispatch(removeFilters([`${column}_after`, `${column}_before`]));
            } else {
              dispatch(removeFilters(`is_${column}_null`));
            }
          }}
          onStartChange={(value) => {
            if (value) {
              dispatch(addFilters({[`${column}_after`]: value}));
            } else {
              dispatch(removeFilters(`${column}_after`));
            }
          }}
          onEndChange={(value) => {
            if (value) {
              dispatch(addFilters({[`${column}_before`]: value}));
            } else {
              dispatch(removeFilters(`${column}_before`));
            }
          }}
        />;
        break;
      case 'address':
      case 'admin_level_1':
      case 'admin_level_2':
      case 'admin_level_3':
      case 'building_type':
      case 'building_type_detail':
      case 'companies':
      case 'contact_people':
      case 'country':
      case 'is_user_created':
      case 'master_asset':
      case 'ownership':
      case 'postal_code':
      case 'realestate_hub':
      case 'realestate_garrison':
      case 'sigi_code':
        filter = <AssetRelatedOptionsFilter field={column}/>;
        break;
      default:
        filter = <>
          WORK IN PROGRESS...
        </>;
    }
  } else {
    // hazard filter
    filter = <StandardFilter
      selectedFilters={activeFilters?.hazards?.[column]}
      filters={[
        {label: 'N.A.', value: '-2'},
        {label: 'N.D.', value: '-1'},
        {label: '0', value: '0'},
        {label: '1', value: '1'},
        {label: '2', value: '2'},
        {label: '3', value: '3'},
        {label: '4', value: '4'},
      ]}
      onChange={(values) => {
        if (values.length) {
          dispatch(addFilters({hazards: {[column]: values}}));
        } else {
          dispatch(removeFilters(column));
        }
      }}
    />;
  }

  const isTotalFilterActive = (column: AssetsTableColumn) => {
    if (isAssetsTableStaticColumn(column)) {
      return getFiltersOfTheAssetsTableStaticColumn(column).some(filter => !_.isUndefined(activeFilters[filter]));
    } else {
      return !_.isUndefined(activeFilters.hazards?.[column]);
    }
  };

  return <Popover
    arrow={false}
    trigger={'click'}
    placement={'bottom'}
    destroyTooltipOnHide
    content={(
      <>
        {filter}
        <Divider/>
        <Row justify={'end'}>
          <Col>
            <Button
              disabled={!isTotalFilterActive(column)}
              type={'link'}
              onClick={() => {
                if (isAssetsTableStaticColumn(column)) {
                  dispatch(removeFilters(getFiltersOfTheAssetsTableStaticColumn(column)));
                } else {
                  dispatch(removeFilters(column));
                }
              }}
            >
              {capitalize(t('table.removeFilter'))}
            </Button>
          </Col>
        </Row>
      </>
    )}
  >
    <FilterFilled
      style={{
        opacity: '25%',
        ...{
          ...isTotalFilterActive(column) && {color: '#1677ff', opacity: ''},
        },
      }}
    />
  </Popover>;
}