import React, {useMemo, useState} from "react";
import {Button, Collapse, Divider, Drawer, List, Skeleton, Space, Typography} from "antd";
import {ButtonProps} from "antd/es/button/button";
import {ArrowLeftOutlined, RightOutlined} from "@ant-design/icons";
import {useGetEventDetailHistoryQuery} from "../../../redux/api/events";
import {skipToken} from "@reduxjs/toolkit/query";
import {capitalize} from "lodash";
import {useTranslation} from "react-i18next";
import DateTimeFormat from "../../../locales/DateTimeFormat";
import {VALUE_NOT_PRESENT} from "../../../utils/translation";
import {useEventContext} from "../../../context/event/EventContext";
import {getAssetAddress} from "../../../utils/assets";
import {useAssetDrawerContext} from "../../../context/alertDetails/AssetDrawerContext";
import {useAssetDetails} from "../../../context/assetDetails/AssetDetails";
import AssetDrawerContent from "../../asset/assetDrawer/AssetDrawerContent";

const {Title, Text, Paragraph} = Typography

export const RoundActionButton = (props: ButtonProps) => {
  return (
    <Button
      type={'primary'}
      size={'small'}
      shape={'circle'}
      icon={<RightOutlined/>}
      {...props}
    />
  );
};

const AssetDrawerButton = ({assetUuid}: { assetUuid: string }) => {

  const [, setIsOpen] = useAssetDrawerContext()
  const [setAssetUuid] = useAssetDetails();

  return <RoundActionButton
    onClick={() => {
      setAssetUuid(assetUuid)
      setIsOpen(true)
    }}/>
}
export default function MovingHistoryItemDrawer({historyUuid}: { historyUuid: string }) {
  const [
    open,
    setOpen,
  ] = useState(false);
  const {t} = useTranslation()
  const {event, getClusterFromUuid} = useEventContext()
  const {data, isFetching: isFetchingEventDetail} = useGetEventDetailHistoryQuery(open && event ? {
    eventUuid: event.uuid,
    historyUuid
  } : skipToken)


  const [isAssetVisible, setIsAssetVisible] = useAssetDrawerContext()
  const [setAssetUuid, {loading: isFetchingAssetDetail}] = useAssetDetails();

  const closeAssetView = () => {
    setAssetUuid(null)
    setIsAssetVisible(false)
  }

  const title = !isAssetVisible ? <>
      {!isFetchingEventDetail && <>
        <Title level={4}><DateTimeFormat>{data?.operation_date}</DateTimeFormat></Title>
        <Text type={'secondary'}>{capitalize(t('user.user', {count: 1}))}: {data?.user_edit}</Text>
      </>}
      {isFetchingEventDetail && <Skeleton paragraph={false}/>}
    </> :
    null

  const collapseItems = useMemo(() => data?.configuration.map(el => {
    const currentCluster = getClusterFromUuid(el.cluster_template)

    return {
      key: el.cluster_template,
      label: <div key={currentCluster?.uuid} style={{display: 'flex', gap: '0.5rem'}}>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <div
            style={{
              backgroundColor: currentCluster?.color_code,
              width: 22,
              height: 22,
              borderRadius: '50%',
              margin: '0 4px',
            }}
          />
          <span>
            {currentCluster?.name}
          </span>
        </div>
        <Typography.Text
          type={'secondary'}>{el.assets.length} {t('assets.asset', {count: el.assets.length})}</Typography.Text>
      </div>,
      children: <List
        size={'small'}
        dataSource={el.assets}
        rowKey={item => item.uuid}
        renderItem={(item, index) => (
          <List.Item>
            <Typography.Text>{getAssetAddress(item)}</Typography.Text>
            <AssetDrawerButton assetUuid={item.uuid}/>
          </List.Item>
        )}
      />
    }
  }), [data?.configuration, getClusterFromUuid, t])

  return (
    <>
      <Drawer
        title={title}
        open={open}
        placement={'right'}
        onClose={() => setOpen(false)}
        mask={true}
        maskClosable={true}
        size={'large'}
        styles={
          !isAssetVisible ?
            {content: {borderRadius: '16px 0 0 16px'}}
            :
            {header: {display: "none"}, content: {borderRadius: "16px 0 0 16px", backgroundColor: "#F5F5F5"}}
        }
      >
        {(isFetchingAssetDetail || isFetchingEventDetail) && <Skeleton title={false} paragraph={{rows: 10}}/>}

        {/*CLUSTER ASSETS VIEW*/}
        {!isAssetVisible && !isFetchingEventDetail && <>
          <Space direction={'vertical'}>
            <Text strong={true}>
              {capitalize(t('misc.note'))}
            </Text>
            <Paragraph>
              {data?.text_note || VALUE_NOT_PRESENT}
            </Paragraph>
          </Space>
          <Divider type={'horizontal'}/>
          <Collapse
            ghost
            bordered={true}
            items={collapseItems}
            accordion
          />
        </>}

        {/*ASSET VIEW*/}
        {isAssetVisible && !isFetchingAssetDetail && <>
          <Button type={"text"}
                  size={"small"}
                  icon={<ArrowLeftOutlined/>}
                  onClick={closeAssetView}>
            {capitalize(t("actions.closeItem", {item: t("assets.buildingDetails")}))}
          </Button>
          <AssetDrawerContent/>
        </>}
      </Drawer>
      <RoundActionButton
        onClick={() => setOpen(true)}/>
    </>
  );
}