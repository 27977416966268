import ResponsiveContent from '../../layout/ResponsiveContent';
import {capitalize} from 'lodash';
import AlertsTable from '../../components/alerts/table/AlertsTable';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

export default function AlertsHistory() {

  const navigate = useNavigate();
  const {t} = useTranslation();

  return <ResponsiveContent
    title={capitalize(t('alerts.history'))}
    goBack={() => navigate('/alerts')}
    goBackText={capitalize(t('alerts.list'))}
  >
    <AlertsTable mode={'managed'}/>
  </ResponsiveContent>;
}