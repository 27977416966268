import {ConfigProvider, Descriptions, DescriptionsProps, Space} from "antd";
import {capitalize} from "lodash";
import React from "react";
import {useTranslation} from "react-i18next";
import EventTypeFromUuid from "../../common/icons/EventTypeFromUuid";
import {EVENT_STATES, GetEvent} from "../../../types/api/getEventsResult";
import DateTimeFormat from "../../../locales/DateTimeFormat";
import GrecimTag from "../activeEventsList/GrecimTag";
import EventStateTag from "../activeEventsList/EventStateTag";
import {AncestorEvent} from "../../event/eventDetails/EventDetailsCard";
import {VALUE_NOT_PRESENT} from "../../../utils/translation";

export default function EventRecapValues({event}: { event: GetEvent }) {

  const {t} = useTranslation()

  const items: DescriptionsProps['items'] = [
    {
      label: capitalize(t('events.name', {count: 1})),
      children: event.name
    },
    {
      label: capitalize(t('misc.description')),
      children: <div style={{whiteSpace: 'pre-line'}}>{event.description || VALUE_NOT_PRESENT}</div>
    },
    {
      label: capitalize(t('events.ancestorEvent')),
      children: <AncestorEvent uuid={event?.ancestor_event || ""}/>,
    },
    ...event.state === EVENT_STATES.Forecast ? [{
      label: capitalize(t('events.dateStartForecast')),
      children: <DateTimeFormat>{event.forecast_start_datetime}</DateTimeFormat>
    }] : [],
    ...(event.state === EVENT_STATES.Happened || event.state === EVENT_STATES.Closed) ? [{
      label: capitalize(t('events.dateStartEvent')),
      children: <DateTimeFormat>{event.start_datetime}</DateTimeFormat>
    }] : [],
  ]

  return <>
    <Space>
      <EventTypeFromUuid
        uuid={event.event_type}
        iconStyle={{
          height: '1.5rem',
          width: '1.5rem',
          backgroundColor: event.color_code
        }}
      />
      {event.is_special_management && <GrecimTag/>}
      <EventStateTag eventState={event.state}/>
    </Space>
    <ConfigProvider
      theme={{
        components: {
          Descriptions: {
            itemPaddingBottom: 0
          },
        },
      }}>
      <Descriptions
        style={{marginTop: '1rem'}}
        layout={'vertical'}
        bordered={false}
        colon={false}
        items={items}
        column={1}
        labelStyle={{
          fontWeight: 'bold',
          color: 'rgba(0, 0, 0, 0.88)',
        }}
        contentStyle={{marginBottom: '1rem'}}
      />
    </ConfigProvider>
  </>
}