import ResponsiveContent from '../../../layout/ResponsiveContent';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {Col, Row, Space, Typography} from 'antd';
import {capitalize} from 'lodash';
import React from 'react';
import DeleteAlertButton from '../DeleteAlertButton';
import ArchiveAlertButton from '../ArchiveAlertButton';
import AssignEventTypeToAlert from '../table/AssignEventTypeToAlert';
import AlertView from './alertView/AlertView';
import ChooseConnectAlertsToEventMode from '../table/ChooseConnectAlertsToEventMode';
import {useAlertContext} from '../../../context/alertDetails/AlertDetails';
import usePreviousPath from "../../../customHooks/usePreviousPath";

export default function NotManagedAlert() {

  const {t} = useTranslation();
  const navigate = useNavigate();

  const [alert] = useAlertContext();

  const {path, label} = usePreviousPath()
  const goBack = () => navigate(path || '/alerts')
  const goBackText = label || t('alerts.list')

  const isManagePossible = !!alert?.event_type;

  return (
    <ResponsiveContent
      goBack={goBack}
      goBackText={goBackText}
      titleHeader={
        <Row justify={'space-between'}>
          <Col>
            <Typography.Title level={3} style={{marginTop: 0}}>
              {capitalize(t('alerts.manageAlerts'))}
            </Typography.Title>
          </Col>
          {alert && (
            <Col>
              <Space wrap>
                <DeleteAlertButton
                  afterDelete={() => navigate('/alerts')}
                  selectedAlerts={[alert.uuid]}
                />
                <ArchiveAlertButton
                  selectedAlerts={[alert.uuid]}
                  afterArchive={() => navigate('/alerts-history')}
                />
                {!isManagePossible &&
                  <AssignEventTypeToAlert selectedAlerts={[alert.uuid]}/>}
                {isManagePossible &&
                  <ChooseConnectAlertsToEventMode
                    evenTypeUuid={alert.event_type || undefined}
                    selectedAlerts={[alert.uuid]}/>}
              </Space>
            </Col>
          )}
        </Row>}
    >
      <AlertView/>

    </ResponsiveContent>
  );
}