import {capitalize} from "lodash";
import {Button} from "antd";
import React from "react";
import {useTranslation} from "react-i18next";
import {useMessageContext} from "../../../context/message/MessageContext";
import {useCreateEventFromAlertGroupMutation} from "../../../redux/api/alerts";

export default function CreateEventFromAlertGroup({alertGroupUuid, onFinish}: { alertGroupUuid: string, onFinish?: () => void }) {

  const {t} = useTranslation()
  const setMessage = useMessageContext();
  const [createEvent, {isLoading}] = useCreateEventFromAlertGroupMutation()

  const handleCreate = async () => {
    try {
      await createEvent({alertGroupUuid}).unwrap()
      setMessage({success: capitalize(t('alerts.connectToEventSuccessMessage'))});
      onFinish && onFinish()
    } catch {
      setMessage({error: capitalize(t('alerts.connectToEventFailMessage'))});
    }
  };


  return <Button
    type={'primary'}
    danger
    loading={isLoading}
    onClick={handleCreate}
  >
    {capitalize(t('actions.createItem', {item: t('events.event', {count: 1})}))}
  </Button>
}