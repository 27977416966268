import {apiSlice} from './baseApi';

export type GetLegendGraphicsRequest = {
  //request: string,
  LAYER: string,
  FORMAT?: string,
  STYLE?: string,
  FEATURETYPE?: string,
  RULE?: string,
  SCALE?: string,
  SLD?: string,
  SLD_BODY?: string,
  WIDTH?: string | number,
  HEIGHT?: string | number,
  EXCEPTIONS?: string,
  LANGUAGE?: string,
};

export type GetCapabilitiesRequest = {
  SERVICE?: string,
  VERSION?: string,
  //REQUEST?: string,
  FORMAT?: string,
  TRANSPARENT?: string,
  QUERY_LAYERS?: string,
  STYLE?: string,
  LAYERS?: string,
  exceptions?: string,
  INFO_FORMAT?: string,
  FEATURE_COUNT?: number,
  X?: number,
  Y?: number,
  SRS?: string,
  WIDTH?: number,
  HEIGHT?: number,
  BBOX?: string,
}

export type GetFeatureInfoResponse = {
  features: {
    bbox: number[],
    id: string,
    properties: {
      [key: string]: string,
    }
  }[],
  totalFeatures: string,
  numberReturned: number,
}

const MapApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getLegendGraphic: build.query<void, {
      baseUrl: string,
      authkey?: string
    } & GetLegendGraphicsRequest>({
      query: ({baseUrl, ...config}) => {
        return {
          url: baseUrl,
          params: {
            REQUEST: 'GetLegendGraphic',
            FORMAT: 'image/png',
            VERSION: '1.1.1',
            ...config,
          },
        };
      },
    }),
    getFeatureInfo: build.query<GetFeatureInfoResponse, {
      baseUrl: string,
      layers: string[],
      bbox: string,
      authkey?: string,
      X?: number,
      Y?: number,
      WIDTH?: number,
      HEIGHT?: number,
    } & GetCapabilitiesRequest>({
      query: ({baseUrl, layers, bbox, ...config}) => {
        return {
          url: baseUrl,
          //responseHandler: (response: { text: () => any }) => response.text(),
          params: {
            SERVICE: 'WMS',
            VERSION: '1.1.1',
            REQUEST: 'GetFeatureInfo',
            //FORMAT: "image/png",
            //TRANSPARENT: "true",
            QUERY_LAYERS: layers.join(','),
            STYLES: '',
            LAYERS: layers.join(','),
            exceptions: 'application/vnd.ogc.se_xml',
            INFO_FORMAT: 'application/json',
            FEATURE_COUNT: 50,
            X: 50,
            Y: 50,
            SRS: 'EPSG:4326',
            WIDTH: 101,
            HEIGHT: 101,
            BBOX: bbox,
            ...config,
          },
        };
      },
    }),
  }),
});

export const {
  useLazyGetLegendGraphicQuery,
  useLazyGetFeatureInfoQuery,
  useGetFeatureInfoQuery,
} = MapApi;