import React, {useEffect, useRef, useState} from 'react';
import {Marker, Popup} from 'react-leaflet';
import L, {Marker as LeafletMarker} from 'leaflet';
import {skipToken} from "@reduxjs/toolkit/query";
import {useAlertContext} from "../../../../context/alertDetails/AlertDetails";
import {useGetEventTypeQuery} from "../../../../redux/api/eventTypes";
import EpicenterInfo from "../../../common/map/EpicenterInfo";

const src = process.env.REACT_APP_MEDIA;
const defaultIcon = {
  iconUrl: src,
  iconSize: new L.Point(30, 30),
};
export default function AlertEpicenterMarker({isEdit = false}: { isEdit?: boolean }) {

  const markerRef = useRef<LeafletMarker | null>(null);
  const [alert] = useAlertContext();
  const {data} = useGetEventTypeQuery(alert?.event_type ? {uuid: alert?.event_type} : skipToken);
  const [icon, setIcon] = useState(defaultIcon)

  useEffect(() => {
    if (data?.icon) {
      setIcon({
        ...defaultIcon,
        iconUrl: src + data.icon.svg,
      })
    }
  }, [data?.icon]);

  return (
    <>
      {!!alert?.epicenter && <Marker
        icon={new L.Icon(icon)}
        position={alert.epicenter}
        ref={markerRef}
      >
        <Popup className={'alert-epicenter-marker-popup'}>
          <EpicenterInfo lat={alert.epicenter.lat} lng={alert.epicenter.lng}/>
        </Popup>
      </Marker>}
    </>
  )
}