import {forwardRef, memo, Ref, useMemo} from 'react';
import {Marker, MarkerProps} from 'react-leaflet';
import L from 'leaflet';
import {MarkerIcon, MarkerSvg} from '../customIcon/MarkerIcon';
import {renderToString} from 'react-dom/server';

export type CustomMarkerProps = MarkerProps & {
  markerUuid?: string,
} & MarkerIcon

export const CustomMarker = forwardRef((props: CustomMarkerProps, ref: Ref<any> | undefined) => {

  const {
    markerUuid,
    eventHandlers,
    outerColor,
    innerColor,
    size,
    ...markerProps
  } = props;

  //console.log(props.position);

  const marker = useMemo(() => (
    renderToString(<MarkerSvg
      outerColor={outerColor}
      innerColor={innerColor}
      size={size}
    />)
  ), [innerColor, outerColor, size]);

  const handlers = useMemo(() => {
    return {
      /*mouseover(e: L.LeafletMouseEvent) {
        console.log("mouseover! ");
        console.log(e.target)
      },
      mouseout(e: L.LeafletMouseEvent) {
        console.log("mouseout!")
      },*/
      ...eventHandlers,
    };
  }, [eventHandlers]);

  return (
    <Marker {...markerProps}
            ref={ref}
            eventHandlers={handlers}
            icon={L.divIcon({
              html: marker,
              iconAnchor: [18, 36],
              popupAnchor: [0, -18],
              className: '',
            })}
    />
  );
});

export const MemoizedMarker = memo(CustomMarker, (prevProps, nextProps) => {
    // @ts-ignore
    return (prevProps.position?.lat === nextProps.position?.lat && prevProps.position?.lng === nextProps.position?.lng && prevProps.children !== nextProps.children);
  },
);