import {OptionLabelValue} from './OptionLabelValue';
import {Sorter} from './tables/sorters';

export type DocumentsTableColumns =
  'name' |
  'end_date' |
  'event_types' |
  'assets' |
  'events' |
  'alerts';

export type DocumentsTableSearchFilters = Pick<Record<DocumentsTableColumns, OptionLabelValue[] | null>,
  'name' |
  'event_types' |
  'assets' |
  'events' |
  'alerts'
>
export type DocumentsTableDateRangeFilters = Pick<Record<DocumentsTableColumns, [string, string] | null>,
  'end_date'
>

export type EventTypesDocumentsTableColumns = Extract<DocumentsTableColumns, 'name' | 'end_date' | 'event_types'>;
export type AssetsDocumentsTableColumns = Extract<DocumentsTableColumns, 'name' | 'end_date' | 'assets'>;
export type EventsDocumentsTableColumns = Extract<DocumentsTableColumns, 'name' | 'end_date' | 'events'>;
export type AlertsDocumentsTableColumns = Extract<DocumentsTableColumns, 'name' | 'end_date' | 'alerts'>;
export type NotAssociatedDocumentsTableColumns = Extract<DocumentsTableColumns, 'name' | 'end_date'>;

export type EventTypesDocumentsTableFilters =
  Pick<DocumentsTableSearchFilters, 'name' | 'event_types'>
  & Pick<DocumentsTableDateRangeFilters, 'end_date'>
export type AssetsDocumentsTableFilters =
  Pick<DocumentsTableSearchFilters, 'name' | 'assets'>
  & Pick<DocumentsTableDateRangeFilters, 'end_date'>
export type EventsDocumentsTableFilters =
  Pick<DocumentsTableSearchFilters, 'name' | 'events'>
  & Pick<DocumentsTableDateRangeFilters, 'end_date'>
export type AlertsDocumentsTableFilters =
  Pick<DocumentsTableSearchFilters, 'name' | 'alerts'>
  & Pick<DocumentsTableDateRangeFilters, 'end_date'>
export type NotAssociatedDocumentsTableFilters =
  Pick<DocumentsTableSearchFilters, 'name'>
  & Pick<DocumentsTableDateRangeFilters, 'end_date'>

export type AssetsDocumentsTableSorters = { [K in AssetsDocumentsTableColumns]: Sorter }
export type EventTypesDocumentsTableSorters = { [K in EventTypesDocumentsTableColumns]: Sorter }
export type EventsDocumentsTableSorters = { [K in EventsDocumentsTableColumns]: Sorter }
export type AlertsDocumentsTableSorters = { [K in AlertsDocumentsTableColumns]: Sorter }
export type NotAssociatedDocumentsTableSorters = { [K in NotAssociatedDocumentsTableColumns]: Sorter }

export const DEFAULT_ASSETS_DOCUMENTS_TABLE_FILTERS: AssetsDocumentsTableFilters = {
  name: null,
  end_date: null,
  assets: null,
};
export const DEFAULT_EVENT_TYPES_DOCUMENTS_TABLE_FILTERS: EventTypesDocumentsTableFilters = {
  name: null,
  end_date: null,
  event_types: null,
};
export const DEFAULT_EVENTS_DOCUMENTS_TABLE_FILTERS: EventsDocumentsTableFilters = {
  name: null,
  end_date: null,
  events: null,
};
export const DEFAULT_ALERTS_DOCUMENTS_TABLE_FILTERS: AlertsDocumentsTableFilters = {
  name: null,
  end_date: null,
  alerts: null,
};
export const DEFAULT_NOT_ASSOCIATED_DOCUMENTS_TABLE_FILTERS: NotAssociatedDocumentsTableFilters = {
  name: null,
  end_date: null,
};

export const DEFAULT_ASSETS_DOCUMENTS_TABLE_SORTERS: AssetsDocumentsTableSorters = {
  name: null,
  end_date: null,
  assets: null,
};
export const DEFAULT_EVENT_TYPES_DOCUMENTS_TABLE_SORTERS: EventTypesDocumentsTableSorters = {
  name: null,
  end_date: null,
  event_types: null,
};
export const DEFAULT_EVENTS_DOCUMENTS_TABLE_SORTERS: EventsDocumentsTableSorters = {
  name: null,
  end_date: null,
  events: null,
};
export const DEFAULT_ALERTS_DOCUMENTS_TABLE_SORTERS: AlertsDocumentsTableSorters = {
  name: null,
  end_date: null,
  alerts: null,
};
export const DEFAULT_NOT_ASSOCIATED_DOCUMENTS_TABLE_SORTERS: NotAssociatedDocumentsTableSorters = {
  name: null,
  end_date: null,
};