import {createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState} from 'react';

import {GoogleLayers} from '../../types/internal/Map';

export type BaseTileLayerContextType = [
  currentTileLayer: GoogleLayers,
  setCurrentTileLayer: Dispatch<SetStateAction<GoogleLayers>>
]

const LayerContext = createContext<BaseTileLayerContextType>([
    GoogleLayers.roadmap,
    () => null,
  ],
);

export const useBaseTileLayerContext = () => useContext(LayerContext);

export const BaseTileLayerContext = (props: PropsWithChildren) => {
  const [
    currentTileLayer,
    setCurrentTileLayer,
  ] = useState<GoogleLayers>(GoogleLayers.roadmap);

  return (
    <LayerContext.Provider value={[currentTileLayer, setCurrentTileLayer]}>
      {props.children}
    </LayerContext.Provider>
  );
};