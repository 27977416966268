import {Col, Row, Typography} from 'antd';
import {useGetUserProfileQuery} from '../../../redux/api/locales';
import {FileOutlined} from '@ant-design/icons';
import {capitalize} from 'lodash';
import {dateToLocaleString} from '../../../utils/dateParsers';
import {useTranslation} from 'react-i18next';
import {DocumentType} from '../../../redux/api/documents';
import {DocumentActions} from '../../common/documents/documentActions/DocumentActions';
import {VALUE_NOT_PRESENT} from "../../../utils/translation";

const {Text, Paragraph} = Typography;
export const DocumentListItem = ({documentItem, onRemove, onDelete, withEdit, withDownload}: {
  documentItem: DocumentType,
  onRemove?: () => Promise<any>,
  onDelete?: () => Promise<any>,
  withEdit?: boolean,
  withDownload?: boolean
}) => {

  const {t} = useTranslation();

  const {
    data: userData,
    isFetching: isFetchingUserData,
    isLoading: isLoadingUserData,
  } = useGetUserProfileQuery();

  return (
    <>
      {!isFetchingUserData && !isLoadingUserData && userData && (
        <Row
          style={{width: '100%'}}
          align={'middle'}
          wrap={false}
        >
          <Col flex={'auto'}>
            <Row
              align={'middle'}
              gutter={[16, 16]}
            >
              <Col xs={24} sm={12} lg={6}>
                <Row
                  wrap={false}
                  gutter={[16, 16]}
                >
                  <Col flex={'none'}>
                    <FileOutlined/>
                  </Col>
                  <Col flex={'auto'}>
                    <Text ellipsis={{tooltip: documentItem.name}}>
                      {documentItem.name}
                    </Text>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={12} lg={6}>
                <Row gutter={[16, 16]}>
                  <Col flex={'100px'}>
                    <Text
                      type={'secondary'}
                      style={{fontSize: '11px'}}
                    >
                      {t('documents.validityDate').toLocaleUpperCase()}:
                    </Text>
                  </Col>
                  <Col flex={'auto'}>
                    <Text
                      ellipsis={{
                        tooltip: documentItem.end_date ?
                          dateToLocaleString(documentItem.end_date, userData.language) :
                          VALUE_NOT_PRESENT,
                      }}
                    >
                      {documentItem.end_date ?
                        dateToLocaleString(documentItem.end_date, userData.language) :
                        VALUE_NOT_PRESENT
                      }
                    </Text>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={12} lg={6}>
                <Text ellipsis={{tooltip: documentItem.user_creation}}>
                  {documentItem.user_creation}
                </Text>
              </Col>
              <Col xs={24} sm={12} lg={6}>
                <Row
                  gutter={[16, 16]}
                  wrap={false}
                >
                  <Col flex={'none'}>
                    <Text
                      type={'secondary'}
                      style={{fontSize: '11px'}}
                    >
                      {t('documents.notes').toUpperCase()}:
                    </Text>
                  </Col>
                  <Col flex={'auto'}>
                    <Paragraph
                      style={{marginBottom: 0}}
                      ellipsis={{
                        tooltip: documentItem.description,
                        expandable: true,
                        symbol: capitalize(t('misc.readMore')),
                      }}
                    >
                      {documentItem.description ? documentItem.description : VALUE_NOT_PRESENT}
                    </Paragraph>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col flex={'none'}>
            <DocumentActions
              documentItem={documentItem}
              withDetails={true}
              withEdit={withEdit}
              withDownload={withDownload}
              {...!!onRemove && {
                withRemove: true,
                onRemove,
              }}
              {...!!onDelete && {
                withDelete: true,
                onDelete,
              }}
            />
          </Col>
        </Row>
      )}
    </>
  );
};