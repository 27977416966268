import {useDispatch, useSelector} from 'react-redux';
import ResetFiltersSortersButton from '../../../common/filters/ResetFiltersSortersButton';
import {DocumentTableViews} from '../../DocumentsTable';
import {
  DocumentsSlice,
  resetAlertsDocumentsFiltersAndSorters,
  resetAssetsDocumentsFiltersAndSorters,
  resetEventsDocumentsFiltersAndSorters,
  resetEventTypesDocumentsFiltersAndSorters,
  resetNotAssociatedDocumentsFiltersAndSorters,
  selectDocuments,
} from '../../../../redux/slices/documents';

export default function ResetDocumentsFiltersSorters({view}: { view: DocumentTableViews }) {
  const dispatch = useDispatch();

  let sliceKey: keyof DocumentsSlice;
  let handleConfirm: () => void;

  switch (view) {
    case 'alerts':
      sliceKey = 'alertsDocuments';
      handleConfirm = () => dispatch(resetAlertsDocumentsFiltersAndSorters());
      break;
    case 'event_types':
      sliceKey = 'eventTypesDocuments';
      handleConfirm = () => dispatch(resetEventTypesDocumentsFiltersAndSorters());
      break;
    case 'assets':
      sliceKey = 'assetsDocuments';
      handleConfirm = () => dispatch(resetAssetsDocumentsFiltersAndSorters());
      break;
    case 'events':
      sliceKey = 'eventsDocuments';
      handleConfirm = () => dispatch(resetEventsDocumentsFiltersAndSorters());
      break;
    case 'none':
      sliceKey = 'notAssociatedDocuments';
      handleConfirm = () => dispatch(resetNotAssociatedDocumentsFiltersAndSorters());
      break;
  }

  const filters = useSelector(selectDocuments)[sliceKey].filters;
  const sorters = useSelector(selectDocuments)[sliceKey].sorters;
  const isDisabled = Object.values(filters).every(el => el === null || !el.length) && Object.values(sorters).every(el => el === null || !el.length);

  return <ResetFiltersSortersButton
    isDisabled={isDisabled}
    onConfirm={handleConfirm}
  />;
}