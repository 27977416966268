import {CHANGES, PHASE} from "../../../types/api/getEventsResult";
import {useEventContext} from "../../../context/event/EventContext";
import ResponsiveContent from "../../../layout/ResponsiveContent";
import {capitalize} from "lodash";
import EventEditSteps from "../../events/edit/EventEditSteps";
import RootLayout from "../../../layout/RootLayout";
import {useTranslation} from "react-i18next";
import {EditStepsContext} from "../../../context/event/EditSteps";
import {useEffect} from "react";

export default function EventEdit() {

  const {event, isEditing, setPolygonsAndAssetsForTheMap} = useEventContext()
  const {t} = useTranslation()

  useEffect(() => {
    if (event?.phase === PHASE.EDIT || isEditing) {
      setPolygonsAndAssetsForTheMap(CHANGES.WITH)
    }
  }, [event?.phase, isEditing, setPolygonsAndAssetsForTheMap]);

  return (event?.phase === PHASE.EDIT || isEditing) ?
    <RootLayout>
      <ResponsiveContent
        title={capitalize(t('actions.editItem', {item: t('events.event', {count: 1})}))}
      >
        <EditStepsContext>
          <EventEditSteps/>
        </EditStepsContext>
      </ResponsiveContent>
    </RootLayout>
    :
    null
}