import {OptionLabelValue} from './OptionLabelValue';
import {Sorter} from './tables/sorters';

export type TemplateEmailTableColumns =
  'title' |
  'tags' |
  'language';

export type TemplateEmailTableSearchFilters = Pick<Record<TemplateEmailTableColumns, OptionLabelValue[] | null>,
  'title' |
  'tags' |
  'language'
>

//export type TemplateEmailTableDateRangeFilters = Pick<Record<TemplateEmailTableColumns, [string, string] | null>, >

export type TemplateEmailTableFilters =
  Pick<TemplateEmailTableSearchFilters, 'title' | 'tags' | 'language'>

export type TemplateEmailTableSorters = { [K in TemplateEmailTableColumns]: Sorter }

export const DEFAULT_TEMPLATE_EMAIL_TABLE_FILTERS: TemplateEmailTableFilters = {
  title: null,
  tags: null,
  language: null,
};

export const DEFAULT_TEMPLATE_EMAIL_TABLE_SORTERS: TemplateEmailTableSorters = {
  title: null,
  tags: null,
  language: null,
};