import {Button, ButtonProps, Col, Modal, ModalProps, Row, Space, Typography} from 'antd';
import ManageAlertButton from './ManageAlertButton';
import React, {useCallback, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
import {ArrowLeftOutlined} from '@ant-design/icons';
import ConnectAlertsToEvent from '../ConnectAlertsToEvent';
import {isAssignAlertToEvent} from '../../Authentication/permissions';
import PermissionRoute from '../../Authentication/PermissionRoute';
import {useConnectAlertToEventMutation, useCreateEventFromAlertMutation} from "../../../redux/api/alerts";
import {useMessageContext} from "../../../context/message/MessageContext";
import {useNavigate} from "react-router-dom";

export default function ChooseConnectAlertsToEventMode({selectedAlerts, button, evenTypeUuid}: {
  selectedAlerts: React.Key[],
  button?: ButtonProps,
  evenTypeUuid?: string,
}) {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState<'choose' | 'aggregate'>('choose');
  const {t} = useTranslation();
  const [createEvent] = useCreateEventFromAlertMutation()
  const [connectEvent] = useConnectAlertToEventMutation();
  const [isLoading, setIsLoading] = useState(false)
  const setMessage = useMessageContext()
  const navigate = useNavigate()

  const handleEventCreation = useCallback(async () => {
    try {
      if (selectedAlerts.length) {
        setIsLoading(true)
        const res = await createEvent({alertUuid: selectedAlerts[0].toString()}).unwrap()
        const eventUuid = res.event_uuid
        setMessage({
          success: capitalize(t('form.creationSuccessMessage')),
        });
        if (selectedAlerts.length > 1) {
          const promises: Promise<any>[] = []
          selectedAlerts.forEach((alert, idx) => {
            if (idx !== 0) {
              promises.push(connectEvent({alertUuid: alert as string, eventUuid}).unwrap())
            }
          })
          await Promise.all(promises)
        }
        navigate(`/events/${eventUuid}/`)
      }
    } catch {
      setMessage({
        error: capitalize(t('form.creationFailMessage')),
      });
    } finally {
      setIsLoading(false)
    }
  }, [connectEvent, createEvent, navigate, selectedAlerts, setMessage, t])

  const chooseModalProps: ModalProps = {
    styles: {
      content: {padding: 0},
      header: {padding: '1rem 1.5rem', margin: 0, borderBottom: '1px solid #F0F0F0'},
      body: {display: 'flex', flexDirection: 'column', gap: '2rem', padding: '2rem 2.5rem'},
      footer: {padding: '0.625rem 1.5rem', margin: 0, borderTop: '1px solid #F0F0F0'},
    },
    closeIcon: false,
    title: capitalize(t('actions.manageItem', {item: t('alerts.alert', {count: 2})})),
    children: selectedAlerts.length > 1 ?
      <Trans
        i18nKey={'alerts.manageAlertsDescription'}
        values={{
          number: selectedAlerts.length,
          item: t('alerts.alert', {count: selectedAlerts.length}),
        }}
        components={{p: <Typography.Text/>}}
      />
      : capitalize(t('alerts.manageAlertDescription'))
    ,
    footer: <Row justify={'space-between'}>
      <Col xs={24} md={11}>
        <Button
          block
          onClick={() => setModalMode('aggregate')}
        >
          {capitalize(t('alerts.addAlert'))}
        </Button>
      </Col>
      <Col xs={24} md={11}>
        <Button
          type={'primary'}
          block
          loading={isLoading}
          disabled={isLoading}
          onClick={handleEventCreation}
        >
          {capitalize(t('actions.createItem', {item: t('events.event', {count: 1})}))}
        </Button>
      </Col>
    </Row>,
  };

  const aggregateModalProps: ModalProps = {
    styles: {
      content: {padding: '0 1.5rem'},
      header: {padding: '1rem 0', margin: 0, borderBottom: '1px solid #F0F0F0'},
      body: {display: 'flex', flexDirection: 'column', gap: '2rem', padding: '2rem 0'},
    },
    closeIcon: false,
    width: '70%',
    title: <Space>
      <Button
        type={'link'}
        style={{padding: 0}}
        icon={<ArrowLeftOutlined/>}
        onClick={() => setModalMode('choose')}
      />
      {capitalize(t('alerts.addAlert'))}
    </Space>,
    children: <ConnectAlertsToEvent
      evenTypeUuid={evenTypeUuid}
      alerts={selectedAlerts}
      onFinish={() => {
        setIsModalOpen(false);
      }}
    />,
    onCancel: () => {
      setIsModalOpen(false);
    },
  };

  const modalProps = modalMode === 'choose' ? chooseModalProps : aggregateModalProps;

  return <PermissionRoute
    isAuthorized={isAssignAlertToEvent}
  >
    <Modal
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      centered
      closeIcon={false}
      footer={false}
      destroyOnClose
      {...modalProps}
    />
    <ManageAlertButton
      danger
      onClick={() => setIsModalOpen(true)}
      {...button}
    />
  </PermissionRoute>;
}