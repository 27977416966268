import SelectGeoshapeEntities from "../../alerts/creation/SelectGeoshapeEntities";
import {useMapLoadingContext} from "../../../context/baseMap/MapLoadingContext";
import {useCallback} from "react";
import {OptionPolygon} from "../../alerts/creation/AlertCreation";
import {Entity} from "../../../redux/api/geoshapes";
import {useEventContext} from "../../../context/event/EventContext";
import {Cluster} from "../../../types/api/clusters";

export default function InsertEntitySelect({cluster}: { cluster: Cluster}) {

  const {
    eventPolygons,
    entities,
    onInsertPolygon,
    onRemovePolygon,
    getEventPolygonsFromCluster,
  } = useEventContext();
  const [
    isLoadingMap,
    setIsLoadingMap,
  ] = useMapLoadingContext();

  const clusterEntities: string[] =
    getEventPolygonsFromCluster(cluster.uuid)
      .reduce((result, currentValue) =>
          currentValue.entity_uuid
            ? [...result, currentValue.entity_uuid] :
            result,
        [] as string[],
      );
  const otherEntities =
    entities
      .reduce((result: string[], ent) =>
          !clusterEntities.includes(ent.uuid)
            ? [...result, ent.uuid]
            : result,
        [],
      );

  const handleAddPolygonFromEntity = useCallback(async (opt: OptionPolygon, entity?: Entity) => {
    if (cluster && entity) {
      setIsLoadingMap(true);
      onInsertPolygon({type: 'entity', payload: entity, cluster: cluster.uuid})
        .finally(() => setIsLoadingMap(false));
    }
  }, [cluster, onInsertPolygon, setIsLoadingMap]);

  const handleRemoveGlobalPolygonOfAnEntity = (entityUuid: string) => {
    const globalPolygonToRemove = eventPolygons?.find(eventPolygon => eventPolygon.entity_uuid === entityUuid)?.globalPolygon;
    if (globalPolygonToRemove) {
      setIsLoadingMap(true);
      onRemovePolygon(globalPolygonToRemove)
        .finally(() => setIsLoadingMap(false));
    }
  };

  return <SelectGeoshapeEntities
    disabledSelect={isLoadingMap}
    selectGeoShape={handleAddPolygonFromEntity}
    unselectGeoShapes={(entityUuid, globalPolygonUuid) => handleRemoveGlobalPolygonOfAnEntity(entityUuid)}
    selectedGeoShapes={clusterEntities}
    disabledOptions={otherEntities}
  />
}