import {Checkbox, Divider, Input, Tag, Typography} from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import React from 'react';
import {OptionLabelValue} from '../../../types/internal/OptionLabelValue';
import {CustomLoading} from '../CustomLoading';
import {SearchProps} from 'antd/lib/input';
import {useTranslation} from 'react-i18next';
import _, {capitalize} from 'lodash';

export default function NEWListWithSearchFilter({
                                                  getNext,
                                                  options,
                                                  selectedOptions,
                                                  setSelectedOptions,
                                                  total,
                                                  isLoadingOptions,
                                                  search,
                                                  hasNullOption,
                                                  querySearch,
                                                  setQuerySearch,
                                                  isExclude = false,
                                                  setExcludeMode,
                                                  setDeclarativeMode,
                                                  // todo after approve feature remove prop
                                                  isExcludeFeature = false
                                                }:
                                                  {
                                                    getNext: () => void,
                                                    querySearch: string,
                                                    setQuerySearch: React.Dispatch<React.SetStateAction<string>>,
                                                    options: OptionLabelValue[],
                                                    selectedOptions: OptionLabelValue[],
                                                    setSelectedOptions: (so: OptionLabelValue[]) => void,
                                                    total: number,
                                                    isLoadingOptions: boolean,
                                                    search?: SearchProps,
                                                    hasNullOption?: boolean,
                                                    isExclude?: boolean,
                                                    setExcludeMode?: () => void;
                                                    setDeclarativeMode?: () => void;
                                                    // todo tmp for enable exclude feature
                                                    isExcludeFeature?: boolean
                                                  }) {

  const {t} = useTranslation();

  const currentOptions = hasNullOption ? [{
    label: capitalize(t('misc.nullValue')),
    value: 'null',
  }, ...options] : [...options];

  const onSearch = (value: string) => {
    if (value !== querySearch) {
      setQuerySearch(value);
    }
  };

  const debouncedSearch = _.debounce(onSearch, 400);

  const removeItem = (value: string | number | boolean) => {
    setSelectedOptions(selectedOptions.filter(opt => opt.value !== value));
  };

  const insertItem = (opt: OptionLabelValue) => {
    setSelectedOptions([opt, ...selectedOptions]);
  };

  const hasSelectedOptions = selectedOptions.length > 0

  return <>

    <Input.Search onSearch={onSearch} onChange={(e) => debouncedSearch(e.target.value)} {...search}/>
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        width: '15rem',
        gap: '0.5rem',
        padding: '0.5rem 0',
        height: '4rem',
        overflowY: 'scroll'
      }}
    >
      {!hasSelectedOptions &&
        <Typography.Paragraph style={{alignSelf: 'center', margin: 0}} italic>
          {capitalize(t("table.notSetFilter"))}
        </Typography.Paragraph>}
      {hasSelectedOptions &&
        <Typography.Text style={{alignSelf: 'center', margin: 0}} italic>
          {capitalize(t(isExclude ? "misc.excluded" : 'misc.selected'))}:
        </Typography.Text>}
      {selectedOptions.map((so, idx) => (
        <div style={{alignSelf: 'center'}} key={so.value.toString()}>
          <Tag
            style={{margin: 0}}
            color={'processing'}
            closable
            onClose={() => removeItem(so.value)}
          >
            <Typography.Text ellipsis style={{maxWidth: '10rem'}}>{so.label}</Typography.Text>
          </Tag>
        </div>
      ))}
    </div>
    <Divider/>
    <InfiniteScroll
      dataLength={currentOptions.length} //This is important field to render the next data
      next={() => {
        if (currentOptions.length) {
          getNext();
        }
      }}
      hasMore={currentOptions.length < total}
      scrollableTarget={'scrollableDiv'}
      loader={null}
      height={'10rem'}
    >
      {isExcludeFeature && options.length > 0 && <Checkbox
        indeterminate={hasSelectedOptions}
        onChange={() => {
          if (isExclude) {
            setDeclarativeMode && setDeclarativeMode()
        } else {
            setExcludeMode && setExcludeMode()
          }
        }}
        checked={isExclude}>
        ({capitalize(t('actions.selectAll'))})
      </Checkbox>}
      {currentOptions.map((opt, idx) => (
        <div key={opt.value}>
          <Checkbox
            checked={isExclude ? !selectedOptions.some(el => el.value === opt.value) : selectedOptions.some(el => el.value === opt.value)}
            onChange={event => {
              const isChecked = event.target.checked;
              if (isExclude) {
                isChecked ? removeItem(opt.value) : insertItem(opt);
              } else {
                isChecked ? insertItem(opt) : removeItem(opt.value);
              }
            }}
          >
            <Typography.Text
              ellipsis={{
                tooltip: true,
              }}
              style={{maxWidth: '10rem'}}
            >
              {opt.label}
            </Typography.Text>
          </Checkbox>
        </div>))
      }
      <div style={{display: 'flex', justifyContent: 'center', margin: '0.5rem 0'}}>
        {isLoadingOptions && <CustomLoading/>}
        {!isLoadingOptions && !currentOptions.length &&
          <Typography.Text>{capitalize(t('form.noResults'))}</Typography.Text>}
      </div>
    </InfiniteScroll>
  </>;

}