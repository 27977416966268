import {Button, ModalProps} from 'antd';
import {Trans, useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
import DeleteModalMessage from '../../common/documents/modals/DeleteModalMessage';
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useDeleteEventTypeMutation} from '../../../redux/api/eventTypes';
import {useMessageContext} from '../../../context/message/MessageContext';

export default function DeleteEventType({name, modal}: { name?: string, modal?: ModalProps }) {

  const {t} = useTranslation();
  const deleteMessage = capitalize(t('actions.deleteItem', {item: t('events.eventType', {count: 1})}));
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();
  const {uuid} = useParams();
  const [deleteEvent, {isLoading, isSuccess, isError}] = useDeleteEventTypeMutation();
  const setMessage = useMessageContext();
  const handleConfirm = () => {
    if (uuid) {
      setIsModalVisible(false);
      deleteEvent({uuid});
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setMessage({success: capitalize(t('form.deleteSuccessMessage'))});
      navigate('/settings/events');
    }
  }, [isSuccess, navigate, setMessage, t]);

  useEffect(() => {
    if (isError) {
      setMessage({error: capitalize(t('form.deleteFailMessage'))});
    }
  }, [isError, navigate, setMessage, t]);

  return <>
    <DeleteModalMessage
      title={{
        children: deleteMessage,
      }}
      paragraph={{
        children: <Trans i18nKey={'documents.delete'} values={{item: 'test'}}/>,
      }}
      modal={modal}
    />
    <div>
      <Button
        danger
        onClick={() => setIsModalVisible(true)}
      >
        {deleteMessage}
      </Button>
      <DeleteModalMessage
        title={{
          children: deleteMessage,
        }}
        paragraph={{
          children: <Trans i18nKey={'events.delete'} values={{item: name || 'record'}}/>,
        }}
        modal={{
          open: isModalVisible,
          onCancel: () => setIsModalVisible(false),
        }}
        confirmButton={{
          disabled: !uuid,
          loading: isLoading,
          onClick: handleConfirm,
        }}
      />
    </div>
  </>;
}