import {Card, Skeleton} from 'antd';
import {useAssetDetails} from '../../../context/assetDetails/AssetDetails';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';

import {useAdditionalAssetData} from '../../../context/assetDetails/AdditionalAssetDataContext';
import {AssetDetail} from '../AssetDetail';
import {useParams} from 'react-router-dom';
import {useEffect} from 'react';

export const AssetDetailCard = () => {

  const {t} = useTranslation();
  const {assetId} = useParams();

  const [setAssetUuid, {asset, loading}] = useAssetDetails();
  const [details, loadingAdditionalData] = useAdditionalAssetData();

  useEffect(() => {
    if (assetId) {
      setAssetUuid(assetId);
    }
  }, [assetId, setAssetUuid]);

  return (
    <Card title={capitalize(t('assets.assetsDetail'))}>
      {(loading || loadingAdditionalData) && <Skeleton active={true}/>}
      {(!loading || !loadingAdditionalData) && (<AssetDetail asset={asset} details={details}/>)}
    </Card>
  );
};