import {Button, ButtonProps} from 'antd';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';

export default function AlertsToAssociateToEventButton(button?: ButtonProps) {

  const {t} = useTranslation();

  return <Button
    type={'primary'}
    {...button}>
    {capitalize(t('actions.insertItem', {item: t('alerts.alert', {count: 1})}))}
  </Button>;
}