import {Button, Col, Layout, Row, Space, Steps} from 'antd';
import {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
import {EditAssetPositionFormCard} from '../components/editAsset/EditAssetPositionFormCard';
import {EditAssetDetailFormCard} from '../components/editAsset/EditAssetDetailFormCard';
import {EditAssetRealEstateFormCard} from '../components/editAsset/EditAssetRealEstateFormCard';
import {useEditFormContext} from '../context/editAsset/EditAssetFormContext';
import {DocumentsListCard} from '../components/asset/cards/DocumentsListCard';
import {ImagesListCard} from '../components/asset/cards/ImagesListCard';
import PermissionRoute from '../components/Authentication/PermissionRoute';
import {isGeolocationAutomaticAssetEdit, isUserCreatedAssetEdit} from '../components/Authentication/permissions';
import {useAssetDetails} from '../context/assetDetails/AssetDetails';
import {CustomSider} from '../components/common/CustomSider';

const {Content} = Layout;
export const EditAsset = () => {

  const {t} = useTranslation();
  const {isFirstStepComplete, isSecondStepComplete, isThirdStepComplete, submit} = useEditFormContext();
  const [, {asset}] = useAssetDetails();

  const [
    currentStep,
    setCurrentStep,
  ] = useState(0);

  const steps = useMemo(() => ([
    {
      title: `${capitalize(t('form.edit'))} ${t('assets.buildingLocation')}`,
    },
    {
      title: `${capitalize(t('form.edit'))} ${t('assets.buildingDetails')}`,
    },
    {
      title: `${capitalize(t('form.edit'))} ${t('assets.realEstateData')}`,
    },
    {
      title: capitalize(t('documents.documentsAndImages')),
    },
  ]), [t]);

  const items = useMemo(() =>
    steps.map((item) =>
      ({key: item.title, title: item.title}),
    ), [steps]);

  return (
    <PermissionRoute
      isAuthorized={asset.is_user_created ? isUserCreatedAssetEdit : isGeolocationAutomaticAssetEdit}
      redirect
    >
      <Layout style={{position: 'relative'}}>
        <CustomSider
          backButtonLabel={capitalize(t('form.cancel'))}
          content={
            <Steps
              current={currentStep}
              items={items}
              size={'small'}
              labelPlacement={'horizontal'}
              direction={'vertical'}
              style={{padding: '0 1.5rem'}}
              onChange={step => {
                if (
                  (step < currentStep) ||
                  (step === 1 && isFirstStepComplete()) ||
                  (step === 2 && isSecondStepComplete()) ||
                  (step === 3)
                ) {
                  setCurrentStep(step);
                }
              }}
            />
          }
        />
        <Content style={{overflowY: 'scroll'}}>
          <div style={{margin: '1rem 1.5rem'}}>
            <Row gutter={[28, 28]}>
              <Col span={24}>
                <Space
                  direction={'vertical'}
                  style={{display: 'flex'}}
                  size={'large'}
                >
                  {currentStep === 0 && <EditAssetPositionFormCard/>}
                  {currentStep === 1 && <EditAssetDetailFormCard/>}
                  {currentStep === 2 && <EditAssetRealEstateFormCard/>}
                  {currentStep === 3 && (
                    <>
                      <DocumentsListCard/>
                      <ImagesListCard/>
                    </>
                  )}
                </Space>

              </Col>
            </Row>

            <Row justify={'end'} style={{marginTop: '1rem'}}>
              <Col>
                {currentStep > 0 && (
                  <Button
                    style={{margin: '0 8px'}}
                    disabled={currentStep === 0}
                    onClick={() => setCurrentStep(current => current - 1)}
                  >
                    {capitalize(t('form.previous'))}
                  </Button>
                )}
                {currentStep < steps.length - 1 && (
                  <Button
                    type="primary"
                    disabled={currentStep === 0 ? !isFirstStepComplete() : !isSecondStepComplete()}
                    onClick={() => setCurrentStep(current => current + 1)}
                  >
                    {capitalize(t('form.next'))}
                  </Button>
                )}
                {currentStep === steps.length - 1 && (
                  <Button
                    type="primary"
                    disabled={!isThirdStepComplete()}
                    onClick={submit}
                  >
                    {capitalize(t('form.done'))}
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        </Content>
      </Layout>
    </PermissionRoute>
  );
};