import {Button, ButtonProps, Col, ColorPicker, Form, FormProps, Input, Radio, Row} from "antd";
import AntFormItem from "../../common/AntFormItem";
import {capitalize} from "lodash";
import {useTranslation} from "react-i18next";
import SelectEvent from "../SelectEvent";
import {useEventContext} from "../../../context/event/EventContext";

export default function EditEventForm({formProps, buttonProps}: { formProps?: FormProps, buttonProps?: ButtonProps }) {

  const {t} = useTranslation();
  const {event} = useEventContext()

  return <Form
    layout={'vertical'}
    {...formProps}
  >
    <AntFormItem
      name={'name'}
      label={capitalize(t('events.name', {count: 1}))}
      rules={[
        {
          required: true,
          message: capitalize(t('form.requiredField', {field: t('events.name')})),
        },
      ]}
    >
      <Input/>
    </AntFormItem>
    <AntFormItem
      name={'description'}
      label={capitalize(t('misc.description'))}
    >
      <Input.TextArea/>
    </AntFormItem>
    <AntFormItem
      name={'has_ancestor_event'}
      label={capitalize(t('events.hasAncestorEvent'))}
      rules={[
        {
          required: true,
          message: capitalize(t('form.requiredField', {field: t('events.hasAncestorEvent')})),
        },
      ]}
    >
      <Radio.Group>
        <Radio value={true}>{capitalize(t('misc.yes'))}</Radio>
        <Radio value={false}>{capitalize(t('misc.no'))}</Radio>
      </Radio.Group>
    </AntFormItem>
    <AntFormItem
      noStyle
      shouldUpdate={(prevValues, currentValues) => prevValues.has_ancestor_event !== currentValues.has_ancestor_event}
    >
      {({getFieldValue}) =>
        getFieldValue('has_ancestor_event') ? (
          <AntFormItem
            name={'ancestor_event'}
            label={capitalize(t('events.ancestorEvent'))}
            rules={[
              {
                required: true,
                message: capitalize(t('form.requiredField', {field: t('events.ancestorEvent')})),
              },
              {
                message: capitalize(t('events.notSameEvent')),
                validator: (_, value) => {
                  if (value === event?.uuid) {
                    return Promise.reject();
                  }
                  return Promise.resolve();
                },
              }
            ]}
          >
            <SelectEvent />
          </AntFormItem>
        ) : null
      }
    </AntFormItem>
    <AntFormItem
      name={'color_code'}
      label={capitalize(t('events.color'))}
      rules={[{required: true}]}
    >
      <ColorPicker
        size={'large'}
        showText
        disabledAlpha
        format={'hex'}
      />
    </AntFormItem>
    <Row
      justify={'end'}
    >
      <Col>
        <Button
          htmlType={'submit'}
          type={'primary'}
          children={capitalize(t('actions.confirm'))}
          {...buttonProps}
        />
      </Col>
    </Row>
  </Form>
}