import {CustomLoading} from '../CustomLoading';
import React from 'react';
import {useMapLoadingContext} from '../../../context/baseMap/MapLoadingContext';

export default function MapLoading() {
  
  const [isLoading] = useMapLoadingContext();
  
  return <>
    {(isLoading) && (
      <div
        style={
          {
            backgroundColor: 'rgba(255, 255, 255, 0.75)',
            height: '100%',
            width: '100%',
            zIndex: 10000,
            display: 'flex',
            justifyContent: 'center',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
      >
        <div style={{position: 'absolute', top: '50%'}}>
          <CustomLoading/>
        </div>
      </div>)}
  </>;
}