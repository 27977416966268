import {Input} from 'antd';
import {debounce} from 'lodash';
import {useEffect, useMemo, useState} from 'react';

type TableFilterProps =
  {
    placeholder: string
    selectedFilter?: string,
    onChange: (value: string | null) => void,
  }

export default function SearchFilter({placeholder, selectedFilter, onChange}: TableFilterProps) {

  const debounceOnChange = useMemo(() => debounce(onChange, 500), [onChange]);
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(selectedFilter || '');
  }, [selectedFilter]);

  return (
    <div
      style={{width: '15rem'}}
    >
      <Input
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          setValue(e.target.value || '');
          debounceOnChange(e.target.value || null);
        }}
      />
    </div>);
}