import ResponsiveContent from '../../layout/ResponsiveContent';
import {Col, Row, Space, Typography} from 'antd';
import ToAlertsStandardButton from '../../components/alerts/standard/ToAlertsStandardButton';
import HelpTooltip from '../../components/common/HelpTooltip';
import AlertsSuggestionList from '../../components/alerts/suggestion/AlertsSuggestionList';
import {capitalize} from 'lodash';
import AlertHistoryButton from '../../components/alerts/history/AlertHistoryButton';
import CreateAlertButton from '../../components/alerts/creation/CreateAlertButton';
import React from 'react';
import {useTranslation} from 'react-i18next';

export default function AlertsSuggestion() {

  const {t} = useTranslation();

  return <ResponsiveContent
    containerStyle={{gap: '1rem'}}
    titleHeader={<Row justify={'space-between'} align={'middle'}>
      <Col>
        <Typography.Title level={3} style={{marginTop: 0}}>
          {capitalize(t('alerts.manageAlerts'))}
        </Typography.Title>
        <AlertHistoryButton/>
      </Col>
      <Col>
        <CreateAlertButton/>
      </Col>
    </Row>}
  >
    <Space>
      <ToAlertsStandardButton/>
    </Space>
    <AlertsSuggestionList/>
  </ResponsiveContent>;
}