import {useState} from 'react';
import {Button, Modal} from 'antd';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import {useEventContext} from '../../../context/event/EventContext';
import {EVENT_STATES} from "../../../types/api/getEventsResult";
import {usePatchEventMutation} from "../../../redux/api/events";

export default function CloseEvent() {

  const {t} = useTranslation();
  const {event} = useEventContext();
  const [updateEvent] = usePatchEventMutation()

  const [
    isModalOpen,
    setIsModalOpen,
  ] = useState(false);

  return (<>
    <Modal
      title={capitalize(t('events.closeAndArchive'))}
      centered={true}
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      okButtonProps={{
        type: 'primary',
        danger: true,
        onClick: () => {
          if (event?.uuid) {
            updateEvent({uuid: event?.uuid, state: EVENT_STATES.Closed})
          }
        }
      }}
      onOk={() => {
        setIsModalOpen(false);
      }}
      okText={capitalize(t('events.closeAndArchive'))}
    >
      {t('events.archiveEventDescription')}
    </Modal>
    <Button
      danger
      block
      type={'primary'}
      onClick={() => setIsModalOpen(true)}>
      {capitalize(t('events.closeAndArchive'))}
    </Button>
  </>);
}