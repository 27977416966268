import {Color} from 'antd/lib/color-picker';
import chroma from 'chroma-js';

export const hexToRGB = (hex: string, alpha?: number) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  
  return alpha !== undefined ? `rgba(${[r, g, b, alpha].join(', ')})` : `rgb(${[r, g, b]})`;
};

export const colorPickerDefault = '#1677ff';
export const notAssignedAssetColor = 'grey'
export const hexString = (colorHex: string | Color) => typeof colorHex === 'string' ? colorHex : colorHex.toHexString();

export function getContrastColor(backgroundColor: string) {
    const brightness = chroma(backgroundColor).luminance();
    return brightness > 0.5 ? 'black' : 'white';
  }