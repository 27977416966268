import {Card} from 'antd';
import {AssetDocumentsList} from '../documents/AssetDocumentsList';
import {UploadDocumentModal} from '../../common/documents/modals/UploadDocumentModal';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useAddAssetDocumentMutation} from '../../../redux/api/documents';
import PermissionRoute from '../../Authentication/PermissionRoute';
import {isAssetDocumentCreation} from '../../Authentication/permissions';

export const DocumentsListCard = () => {

  const {t} = useTranslation();
  const {assetId} = useParams();
  const [
    addAssetDocument,
  ] = useAddAssetDocumentMutation();

  return (
    <Card
      title={capitalize(t('documents.document', {count: 1}))}
      extra={<PermissionRoute
        isAuthorized={isAssetDocumentCreation}
      >
        <UploadDocumentModal
          onAfterUpload={(uploadedDocumentUuid) => {
            if (assetId) {
              addAssetDocument({
                assetUuid: assetId,
                documentUuid: uploadedDocumentUuid,
              });
            }
          }}
        />
      </PermissionRoute>
      }
    >
      <AssetDocumentsList/>
    </Card>
  );
};