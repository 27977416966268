import {Select, SelectProps, Space, Typography} from "antd";
import {BIODIVERSITY_SOURCE} from "../../types/api/assets";
import {capitalize} from "lodash";
import {ALL_BIODIVERSITY_SOURCE} from "../assets/AssetsTable/filters/BiodiversitySourceFilter";
import {useTranslation} from "react-i18next";
import {TextProps} from "antd/lib/typography/Text";

export default function SelectBioDiversitySource({selectProps, text}: { selectProps?: SelectProps, text?: TextProps }) {

  const {t} = useTranslation()
  const BIODIVERSITY_SOURCE_OPTIONS = [
    {
      label: 'Natura 2000',
      value: BIODIVERSITY_SOURCE.NT2000
    },
    {
      label: 'WDPA',
      value: BIODIVERSITY_SOURCE.WDPA
    },
    {
      label: capitalize(t('assets.biodiversitySourcesAll')),
      value: ALL_BIODIVERSITY_SOURCE
    },
  ]

  return <Space wrap>
    <Typography.Text strong children={`${capitalize(t('assets.biodiversitySources'))}:`} {...text} />
    <Select
      options={BIODIVERSITY_SOURCE_OPTIONS}
      style={{width: '10rem'}}
      {...selectProps}
    />
  </Space>
}