import {useNavigate, useSearchParams} from 'react-router-dom';
import {useEffect} from 'react';
import {redirect, setRefreshToken} from '../components/Authentication/authUtils';
import Loading from './Loading';

export default function SSOInterceptor() {
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get('token');

  useEffect(() => {
    if (token) {
      setRefreshToken(token);
      redirect();
    } else {
      navigate('/login');
    }
  }, [navigate, token]);

  return <Loading/>;
}