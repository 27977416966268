import {Card, Col, Pagination, Row, Table, Tabs, Typography} from 'antd';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
import React, {useEffect, useMemo, useState} from 'react';
import {
  useAddDocumentToEventTypeMutation,
  useLazyGetEventTypeDocumentsQuery,
  useRemoveDocumentToEventTypeMutation,
} from '../../../redux/api/eventTypes';
import {useParams} from 'react-router-dom';
import {ColumnsType} from 'antd/lib/table';
import {DocumentType} from '../../../redux/api/documents';
import {PaginationConfig} from 'antd/lib/pagination';
import DateFormat from '../../../locales/DateFormat';
import {UploadDocumentModal} from '../../common/documents/modals/UploadDocumentModal';
import {DocumentActions} from '../../common/documents/documentActions/DocumentActions';
import PermissionRoute from '../../Authentication/PermissionRoute';
import {
  isEventTypeDocumentCreation,
  isEventTypeDocumentDelete,
  isEventTypeDocumentEdit,
  isEventTypeDocumentView,
} from '../../Authentication/permissions';
import {useGetUserProfileQuery} from '../../../redux/api/locales';

const {Title} = Typography;
export default function EventTypeDocuments() {

  const {t} = useTranslation();
  const {uuid} = useParams();

  const [
    selectedTab,
    setSelectedTab,
  ] = useState<'valid' | 'expired'>('valid');

  const [
    pagination,
    setPagination,
  ] = useState<PaginationConfig>({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    showTotal: (total, range) => t('table.pageSizeOfTotal', {
      rangeStart: range[0],
      rangeEnd: range[1],
      total,
    }),
    onChange: (page, pageSize) => setPagination(prevPag => ({...prevPag, current: page, pageSize})),
    style: {textAlign: 'center', marginTop: '40px'},
  });

  const [
    selectedRowKeys,
    setSelectedRowKeys,
  ] = useState<React.Key[]>([]);

  const [
    getData,
    {
      data,
    },
  ] = useLazyGetEventTypeDocumentsQuery();

  const [addDocument] = useAddDocumentToEventTypeMutation();
  const [removeDocument] = useRemoveDocumentToEventTypeMutation();
  const {
    data: userData,
  } = useGetUserProfileQuery();

  const isEditing = userData?.type ? isEventTypeDocumentEdit(userData?.type) : false;
  const isRemove = userData?.type ? isEventTypeDocumentDelete(userData?.type) : false;
  const isDownload = userData?.type ? isEventTypeDocumentView(userData?.type) : false;

  const columns: ColumnsType<DocumentType> = useMemo(() => [
    {
      key: 'name',
      dataIndex: 'name',
      title: capitalize(t('documents.documentName')),
    },
    {
      key: 'end_date',
      dataIndex: 'end_date',
      title: capitalize(t('documents.endDate')),
      render: value => {
        if (value) {
          return <>
            <DateFormat>{value}</DateFormat>
          </>;
        }
      },
    },
    {
      key: 'user_creation',
      dataIndex: 'user_creation',
      title: capitalize(t('documents.uploadedBy', {name: ''})),
    },
    {
      key: 'description',
      dataIndex: 'description',
      title: capitalize(t('documents.notes')),
    },
    {
      key: 'action',
      align: 'right',
      render: (value, record, index) => {
        return <DocumentActions
          documentItem={record}
          withDetails={true}
          withDownload={isDownload}
          withEdit={isEditing}
          withRemove={isRemove}
          onRemove={() => {
            if (uuid) {
              return removeDocument({
                eventTypeUuid: uuid,
                documentUuid: record.uuid,
              }).unwrap();
            } else {
              return Promise.reject();
            }
          }}
        />;
      },
    },
  ], [isDownload, isEditing, isRemove, removeDocument, t, uuid]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_NONE,
    ],
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const hasSelected = selectedRowKeys.length > 0;

  useEffect(() => {
    // todo onChange pagination reset selection
  }, []);

  useEffect(() => {
    if (uuid) {
      getData({
        uuid,
        page: pagination.current,
        page_size: pagination.pageSize,
        is_expired: selectedTab === 'expired',
      });
    }
  }, [getData, pagination, selectedTab, uuid]);

  return (
    <Card>
      <Row
        justify={'space-between'}
        style={{marginBottom: '2.5rem'}}
      >
        <Col>
          <Title
            level={4}
            style={{margin: 0}}
          >
            {capitalize(t('events.documents'))}
          </Title>
        </Col>
        <PermissionRoute isAuthorized={isEventTypeDocumentCreation}>
          <Col>
            <UploadDocumentModal
              onAfterUpload={(uploadedDocumentUuid) => {
                if (uuid) {
                  addDocument({
                    eventTypeUuid: uuid,
                    documentUuid: uploadedDocumentUuid,
                  });
                }
              }}
            />
          </Col>
        </PermissionRoute>
      </Row>

      <Tabs
        type={'card'}
        items={[
          {key: 'valid', label: capitalize(t('documents.valid'))},
          {key: 'expired', label: capitalize(t('documents.expired'))},
        ]}
        activeKey={selectedTab}
        onChange={key => setSelectedTab(key as 'valid' | 'expired')}
      />
      {/*todo permit multiple actions rowSelection is now commented*/}
      <Table
        <DocumentType>
        columns={columns}
        pagination={false}
        // rowSelection={rowSelection}
        rowKey={'uuid'}
        dataSource={data?.results}
        scroll={{x: true}}
      />
      {!!data?.count && (
        <Pagination
          {...pagination}
          total={data?.count}
        />)}
    </Card>
  );
}

