import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
import {OptionLabelValue} from '../../../../../types/internal/OptionLabelValue';
import StandardFilter from '../../../../../components/common/filters/StandardFilter';

export const ImportReportsOperationFilter = (props: {
  activeFilters: OptionLabelValue[] | null,
  handleFilterChange: (values: ([] | OptionLabelValue[])) => void
}) => {

  const {t} = useTranslation();

  return <StandardFilter
    selectedFilters={props.activeFilters || undefined}
    filters={[
      {label: capitalize(t('dataImport.creation', {count: 1})), value: 'CREATE'},
      {label: capitalize(t('dataImport.update', {count: 1})), value: 'UPDATE'},
    ]}
    onChange={props.handleFilterChange}
  />;
};