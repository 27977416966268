import {createContext, PropsWithChildren, useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useGetAssetQuery} from '../../redux/api/asset';
import {AssetContextType, AssetModel} from '../../types/internal/AssetModel';
import {skipToken} from '@reduxjs/toolkit/query';

const DEFAULT_ASSET = {
  realestate_hub: null,
  master_asset: null,
  contact_people: [],
  position: null,
  uuid: '',
  sigi_code: '',
  is_mobile: false,
  country: null,
  admin_level_1: null,
  admin_level_2: null,
  admin_level_3: null,
  locality: null,
  address: null,
  postal_code: null,
  is_user_created: false,
  building_type: null,
  building_type_detail: null,
  surface_total: null,
  surface_basement: null,
  opening_date: null,
  closing_date: null,
  ownership: null,
  companies: [],
  total_resources: null,
  hazards: [],
  date_edit: null,
};

const AssetDetailsContext = createContext<AssetContextType>([
  () => null,
  {
    asset: DEFAULT_ASSET,
    loading: false,
  },
]);

export const useAssetDetails = () => useContext(AssetDetailsContext);

export const AssetDetails = (props: PropsWithChildren) => {
  const navigate = useNavigate();

  const [
    asset,
    setAssets,
  ] = useState<AssetModel>(DEFAULT_ASSET);
  const [
    assetUuid,
    setAssetUuid,
  ] = useState<string | null>(null);

  const {
    isFetching,
    isLoading,
    isSuccess,
    isError,
    data,
  } = useGetAssetQuery(assetUuid ? {uuid: assetUuid} : skipToken);

  useEffect(() => {
    if (isSuccess && data) {
      setAssets(data);
    } else {
      if (isError) {
        navigate('/assets');
      }
    }
  }, [data, isError, isSuccess, navigate]);

  return (
    <AssetDetailsContext.Provider value={[setAssetUuid, {asset: asset, loading: isFetching || isLoading}]}>
      {props.children}
    </AssetDetailsContext.Provider>
  );
};