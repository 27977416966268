import {apiSlice} from './baseApi';
import {GenericPaginatedApi} from '../../types/api/paginatedApi';

export type Outcomes = 'KO' | 'NNA' | 'NUT' | 'OK'

/*
se alert è string => alert_not_created è null
se alert è null => alert_not_created è string*/
export type AlertLogs = {
  alert: string | null,
  alertsource: string,
  uuid: string,
  date_creation: string,
  alert_not_created: string | null,
  outcome: string,
}

const alertLogsApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAlertLogsPaginated: build.query<GenericPaginatedApi<AlertLogs>, {
      page?: number,
      page_size?: number,
      //alert: string[],
      alert_not_created?: string,
      alertsource: string,
      date_creation_after?: string,
      date_creation_before?: string,
      ordering?: string,
      outcome?: string
    }>({
      query: (params) => {
        return {
          url: '/alert-logs/',
          params,
          paginate: true,
        };
      },
      providesTags: (res, error, arg) =>
        res ?
          [
            ...res?.results.map(({uuid}) => (
              {type: 'AlertLogs' as const, id: uuid}
            )),
            {type: 'AlertLogs', id: 'LIST'},
            'Locale',
          ]
          : [
            {type: 'AlertLogs', id: 'LIST'},
            'Locale',
          ],
    }),
    getOutcomeChoices: build.query<{ label: string, text: string }[], void>({
      query: () => {
        return {
          url: '/alert-logs/get_outcome_choices/',
        };
      },
      providesTags: (res, error, arg) =>
        ['Locale'],
    }),
  }),
});

export const {
  useGetAlertLogsPaginatedQuery,
  useGetOutcomeChoicesQuery,
} = alertLogsApi;