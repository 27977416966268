import {apiSlice} from './baseApi';
import {CreateExtLayer, CreateReiLayer, GetWMSLayer, PatchLayer, WMSLayer} from '../../types/internal/Map';
import {MultiPolygonGeometry} from './geoshapes';

const layersApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    deleteLayer: build.mutation<WMSLayer, { uuid: string }>({
      query: ({uuid}) => {
        return {
          url: `/layers/${uuid}/`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, arg) => [{type: 'Layers', uuid: arg.uuid}],
    }),
    getLayersPaginated: build.query<GetWMSLayer, {
      page?: number,
      page_size?: number,
      is_internal?: boolean,
      is_active?: boolean,
      q?: string
    }>({
      query: (args) => {
        return {
          url: '/layers/',
          params: args,
        };
      },
      providesTags: (res, error, arg) =>
        res
          ? [...res?.results.map(({uuid}) => ({type: 'Layers' as const, uuid})), 'Layers']
          : ['Layers'],
    }),
    createExtLayer: build.mutation<WMSLayer, CreateExtLayer>({
      query: (args) => {
        return {
          url: `/layers/ext_layer/`,
          method: 'POST',
          body: {
            ...args,
            additional_info: {},
          },
        };
      },
      invalidatesTags: (result, error, arg) => ['Layers'],
    }),
    createReiLayer: build.mutation<WMSLayer, CreateReiLayer>({
      query: (args) => {
        const formData = new FormData();
        for (const [key, value] of Object.entries(args)) {
          if (key !== 'additional_info' && value) {
            formData.append(key, value as string);
          }
        }
        formData.append('additional_info', JSON.stringify({}));
        return {
          url: `/layers/rei_layer/`,
          method: 'POST',
          body: formData,
          formData: true,
          
        };
      },
      invalidatesTags: (result, error, arg) => ['Layers'],
    }),
    updateLayer: build.mutation<WMSLayer, PatchLayer>({
      query: (args) => {
        const {uuid, ...params} = args;
        return {
          url: `/layers/${uuid}/`,
          method: 'PATCH',
          body: params,
        };
      },
      invalidatesTags: (result, error, arg) => [{type: 'Layers', uuid: arg.uuid}],
    }),
    getLayer: build.query<WMSLayer, { uuid: string }>({
      query: (args) => {
        const {uuid} = args;
        return ({
          url: `/layers/${uuid}/`,
        });
      },
      providesTags: (result, error, params) => [{type: 'Layers', uuid: params.uuid}],
    }),
    getAllLayers: build.query<WMSLayer[], void>({
      query: (args) => {
        return ({
          url: `/layers/`,
          params: {
            paginate: false,
            is_active: true,
          },
        });
      },
      providesTags: ['Layers'],
    }),
    getPolygonFromLayer: build.query<{ geoshape: MultiPolygonGeometry }, {
      lat: string,
      lon: string,
      layerUuid: string
    }>({
      query: (args) => {
        return ({
          url: `/layers/${args.layerUuid}/get_polygon/`,
          params: {
            lat: args.lat,
            lon: args.lon,
          },
        });
      },
      //providesTags: (result, error, params) => [{ type: 'Layers', uuid: params.uuid }],
    }),
  }),
});

export const {
  useGetAllLayersQuery,
  useLazyGetLayerQuery,
  useLazyGetLayersPaginatedQuery,
  useUpdateLayerMutation,
  useDeleteLayerMutation,
  useCreateExtLayerMutation,
  useCreateReiLayerMutation,
  useLazyGetPolygonFromLayerQuery,
} = layersApi;