import {useLazyGetLayersPaginatedQuery} from '../../../redux/api/layers';
import {Col, Input, Pagination, Row, Skeleton, Tabs} from 'antd';
import {capitalize} from 'lodash';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import LayerListItem from './LayerListItem';
import {PaginationConfig} from 'antd/lib/pagination';
import {CustomLoading} from '../../common/CustomLoading';

export default function LayersList() {

  const {t} = useTranslation();

  const [
    getLayers,
    {
      data,
      isLoading,
      isFetching,
      isUninitialized,
    }] = useLazyGetLayersPaginatedQuery();

  const [
    isActiveLayers,
    setIsActiveLayers,
  ] = useState<boolean>(true);
  const [
    searchTerm,
    setSearchTerm,
  ] = useState<string>('');
  const [
    pagination,
    setPagination,
  ] = useState<PaginationConfig>({current: 1, pageSize: 10});

  const loading = isLoading || isFetching || isUninitialized;

  useEffect(() => {
    getLayers({
      is_active: isActiveLayers,
      ...!!searchTerm && {q: searchTerm},
      page: pagination.current,
      page_size: pagination.pageSize,
    });
  }, [getLayers, isActiveLayers, pagination, searchTerm]);

  return (
    <>
      <Row justify={'space-between'}>
        <Col>
          <Input.Search
            onSearch={setSearchTerm}
            placeholder={capitalize(t('actions.searchItem', {item: t('layers.layer', {count: 1})}))}
          />
        </Col>
        <Col>
          <Tabs
            type={'card'}
            items={[
              {key: 'active', label: capitalize(t('layers.mapActive'))},
              {key: 'notActive', label: capitalize(t('layers.mapNotActive'))},
            ]}
            activeKey={isActiveLayers ? 'active' : 'notActive'}
            onChange={key => setIsActiveLayers(key === 'active')}
          />
        </Col>
      </Row>
      {loading && <CustomLoading/>}
      {!data?.count && !loading && (
        <p>{capitalize(t('form.noResults'))}</p>
      )}
      {data?.results.map(el => (
        <Skeleton
          key={el.uuid}
          loading={loading}
          active
          avatar
        >
          <LayerListItem
            frontendName={el.frontend_name}
            name={el.name}
            notes={el.description}
            source={el.layer_source}
            wmsSource={el.wms_baseurl}
            isActive={el.is_active}
            isInternal={el.is_internal}
            uuid={el.uuid}
          />
        </Skeleton>))}
      {!!data?.count && (
        <Pagination {...pagination}
                    total={data?.count}
                    simple
                    onChange={(page, pageSize) => setPagination(prevPag => ({
                      ...prevPag,
                      current: page,
                    }))}
                    style={{textAlign: 'center'}}
        />)}
    </>);

}