import ClustersList from "./ClustersList";
import {useEventContext} from "../../../context/event/EventContext";
import EventClusterDrawer from "./EventClusterDrawer";
import {useCallback, useMemo, useState} from "react";
import {Typography} from "antd";
import {capitalize} from "lodash";
import {useTranslation} from "react-i18next";
import {Cluster} from "../../../types/api/clusters";
import {useMapLoadingContext} from "../../../context/baseMap/MapLoadingContext";
import PermissionRoute from "../../Authentication/PermissionRoute";
import {Link} from "react-router-dom";
import {isEventTypesEdit} from "../../Authentication/permissions";

export default function EventClusters() {

  const {event, clusters, assets, eventPolygons, getClusterFromUuid, onRemoveCluster} = useEventContext()
  const {t} = useTranslation()
  const [selectedCluster, setSelectedCluster] = useState<string | undefined>()
  const [, setIsLoadingMap] = useMapLoadingContext()

  const insertedClusters: Cluster[] = []
  const notInsertedClusters: Cluster[] = []

  clusters?.forEach(cl => {
    if (eventPolygons?.some(ep => ep.cluster === cl.uuid) || assets?.some(asset => asset.cluster_template === cl.uuid)) {
      insertedClusters.push(cl)
    } else {
      notInsertedClusters.push(cl)
    }
  })

  const handleRemoveCluster = useCallback((cluster: string) => {
    setIsLoadingMap(true)
    onRemoveCluster(cluster)
      .finally(() => setIsLoadingMap(false))
  }, [onRemoveCluster, setIsLoadingMap])

  const selectedClusterData = useMemo(() => {
    return selectedCluster ? getClusterFromUuid(selectedCluster) : undefined
  }, [getClusterFromUuid, selectedCluster])

  return <>
    <EventClusterDrawer
      cluster={selectedClusterData}
      drawer={{
        open: !!selectedCluster,
        onClose: () => setSelectedCluster(undefined),
      }}
    />
    {!!(insertedClusters.length) && <>

      <Typography.Title
        level={4}
        style={{margin: 0}}>
        {capitalize(t('events.clustersEvent'))}
      </Typography.Title>
      <ClustersList
        selectedCluster={selectedCluster}
        type={'edit'}
        onEdit={(cluster) => setSelectedCluster(cluster)}
        onRemove={handleRemoveCluster}
        clusters={insertedClusters}
      />

      {!!notInsertedClusters.length && <>
        <Typography.Title
          level={4}
          style={{margin: 0}}>
          {capitalize(t('events.clustersNotEvent'))}
        </Typography.Title>
        <ClustersList
          selectedCluster={selectedCluster}
          onCreate={(cluster) => setSelectedCluster(cluster)}
          type={'create'}
          clusters={notInsertedClusters}
        />
      </>
      }
    </>}

    {!(insertedClusters.length) && <>
      <Typography.Title
        level={3}
        style={{margin: 0}}>
        {capitalize(t('events.chooseClusters'))}
      </Typography.Title>
      {!clusters?.length && <>
        <Typography.Paragraph italic>
          {capitalize(t('events.noClusterAssignedToEventType'))}
          <PermissionRoute isAuthorized={isEventTypesEdit}>
            <Link to={`/settings/events/${event?.event_type}`}> {capitalize(t('events.insertClusterSettings'))}</Link>
          </PermissionRoute>
        </Typography.Paragraph>

      </>
      }
      {!!clusters?.length && <ClustersList
        selectedCluster={selectedCluster}
        onCreate={(cluster) => setSelectedCluster(cluster)}
        type={'create'}
        clusters={clusters || []}
      />}
    </>}
  </>
}