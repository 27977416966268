import {useEventContext} from '../../../context/event/EventContext';
import React from 'react';
import EventPolygons from '../../event/map/EventPolygons';
import EventAssetMarkers from '../../event/map/EventAssetMarkers';
import EpicenterMarker from '../../event/map/EpicenterMarker';
import MapLoading from '../../common/map/MapLoading';
import ClusterMapDrawControlWrapper from './ClusterMapDrawControlWrapper';
import {useEventClusterCenterMap} from "../../../customHooks/useCenterMap";
import {useMapLoadingContext} from "../../../context/baseMap/MapLoadingContext";

export default function ClusterMap({clusterUuid}: {
  clusterUuid: string,
}) {

  const {clusters} = useEventContext();
  const [loading] = useMapLoadingContext()
  useEventClusterCenterMap(clusterUuid, loading)

  return <>
    <MapLoading/>
    <EventAssetMarkers isAssignCluster={true}/>
    <EventPolygons
      selectedClustersUuids={clusters?.filter(el => el.uuid !== clusterUuid).map(el => el.uuid) || []}
    />
    <ClusterMapDrawControlWrapper clusterUuid={clusterUuid}/>
    <EpicenterMarker/>
  </>;
}