import {Button} from 'antd';
import {Trans, useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
import DeleteModalMessage from '../../common/documents/modals/DeleteModalMessage';
import {useEffect, useState} from 'react';
import {useDeleteClusterTemplateMutation} from '../../../redux/api/clusters';
import {useMessageContext} from '../../../context/message/MessageContext';
import {useNavigate} from 'react-router-dom';
import {DeleteOutlined} from '@ant-design/icons';

export default function DeleteCluster({clusterUuid, eventUuid, name}: {
  clusterUuid: string,
  eventUuid: string,
  name: string
}) {

  const {t} = useTranslation();
  const navigate = useNavigate();
  const setMessage = useMessageContext();

  const [
    isModalVisible,
    setIsModalVisible,
  ] = useState(false);

  const [
    deleteCluster,
    {
      isSuccess,
      isError,
      isLoading,
    },
  ] = useDeleteClusterTemplateMutation();

  useEffect(() => {
    if (isSuccess) {
      setMessage({success: capitalize(t('form.deleteSuccessMessage'))});
      setIsModalVisible(false);
      navigate(`/settings/events/${eventUuid}`);
    }
  }, [eventUuid, isSuccess, navigate, setMessage, t]);

  useEffect(() => {
    if (isError) {
      setMessage({error: capitalize(t('form.deleteFailMessage'))});
    }
  }, [isError, setMessage, t]);

  return (
    <>
      <Button
        danger
        onClick={() => setIsModalVisible(true)}
        icon={<DeleteOutlined/>}
        type={'text'}
      />
      <DeleteModalMessage
        title={{
          children: capitalize(t('actions.deleteItem', {item: t('clusters.cluster', {count: 2})})),
        }}
        paragraph={{
          children: <Trans i18nKey={'clusters.delete'} values={{item: name}}/>,
        }}
        modal={{
          open: isModalVisible,
          onCancel: () => setIsModalVisible(false),
        }}
        confirmButton={{
          loading: isLoading,
          disabled: isLoading,
          onClick: () => deleteCluster({eventUuid: eventUuid, clusterTemplateUuid: clusterUuid}),
        }}
      />
    </>);
}