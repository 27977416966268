import {Link, useRouteError} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Button, Flex, Result} from 'antd';
import {capitalize} from "lodash";

export default function Error() {
  const error = useRouteError();
  console.error(error);

  const {t} = useTranslation();

  return (
    <Flex vertical justify={'center'} style={{height: '100vh', width: '100vw'}}>
      <Result
      status="500"
      subTitle={capitalize(t('misc.genericError'))}
      extra={<Link to={'/'}>
        <Button
          type={'primary'}
        >
          {capitalize(t('actions.goToDestination', {destination: t('misc.homepage')}))}
        </Button>
      </Link>}
    />
    </Flex>
  );
}