import {Trans, useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
import DeleteModalMessage from '../../common/documents/modals/DeleteModalMessage';
import {useState} from 'react';
import RemoveButton from "../../common/buttons/RemoveButton";
import {useMapLoadingContext} from "../../../context/baseMap/MapLoadingContext";

export default function DeleteEventCluster({deleteCluster, clusterName}: {
  deleteCluster: () => void,
  clusterName: string
}) {

  const {t} = useTranslation();
  const [isLoadingMap] = useMapLoadingContext()

  const [
    isModalVisible,
    setIsModalVisible,
  ] = useState(false);

  return (
    <>
      <RemoveButton
        onClick={() => setIsModalVisible(true)}
        disabled={isLoadingMap}
      />

      <DeleteModalMessage
        title={{
          children: capitalize(t('actions.deleteItem', {item: t('clusters.cluster', {count: 2})})),
        }}
        paragraph={{
          children: <Trans i18nKey={'clusters.delete'} values={{item: clusterName}}/>,
        }}
        modal={{
          open: isModalVisible,
          onCancel: () => setIsModalVisible(false),
        }}
        confirmButton={{
          disabled: isLoadingMap,
          onClick: () => {
            setIsModalVisible(false)
            deleteCluster()
          },
        }}
      />
    </>);
}