export type HeaderItem =
  'riskapp'
  | 'emergency'
  | 'assets'
  | 'events'
  | 'map'
  | 'mail'
  | 'documents'
  | 'user'
  | 'settings'
  | 'alerts';
export const menuItems: HeaderItem[] = ['riskapp', 'emergency', 'assets', 'events', 'map', 'mail', 'user', 'documents', 'settings', 'alerts'];