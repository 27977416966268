import ProtectedRoute from './ProtectedRoute';
import {useGetUserProfileQuery} from '../../redux/api/locales';
import {User} from './permissions';
import {PropsWithChildren} from 'react';

export default function PermissionRoute({isAuthorized, redirect, children}: PropsWithChildren<{
  isAuthorized: (user: User) => boolean,
  redirect?: boolean
}>) {

  const {
    data: userData,
    isFetching: isFetchingUserData,
  } = useGetUserProfileQuery();

  return !isFetchingUserData && userData ?
    <ProtectedRoute
      verifyConditionVisibility={() => isAuthorized(userData.type)}
      {...redirect && {redirectUrl: '/not-authorized'}}
    >
      {children}
    </ProtectedRoute>
    : null;
}