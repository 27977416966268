import {Card, Col, Pagination, Row, Table} from 'antd';
import {capitalize} from 'lodash';
import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import DeleteMultipleDocuments from '../DeleteMultipleDocuments';
import {DownloadMultipleDocuments} from '../DownloadMultipleDocuments';
import {
  DocumentType,
  useAddEventDocumentMutation,
  useDeleteDocumentMutation,
  useGetEventDocumentsQuery
} from '../../../redux/api/documents';
import {useGetUserProfileQuery} from '../../../redux/api/locales';
import {CustomLoading} from '../../common/CustomLoading';
import {
  isEventDocumentCreation,
  isEventDocumentDelete,
  isEventDocumentEdit,
  isEventDocumentView
} from '../../Authentication/permissions';
import {PaginationConfig} from "antd/lib/pagination";
import PermissionRoute from "../../Authentication/PermissionRoute";
import {UploadDocumentModal} from "../../common/documents/modals/UploadDocumentModal";
import {DocumentActions} from "../../common/documents/documentActions/DocumentActions";
import {useEventContext} from "../../../context/event/EventContext";
import {skipToken} from "@reduxjs/toolkit/query";
import {REDUCED_STATES} from "../../../types/api/getEventsResult";

export default function EventDocuments() {

  const {t} = useTranslation();
  const {event} = useEventContext()

  const [
    selectedRowKeys,
    setSelectedRowKeys,
  ] = useState<React.Key[]>([]);

  const [pagination, setPagination] = useState<PaginationConfig>({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    showTotal: (total, range) => t('table.pageSizeOfTotal', {
      rangeStart: range[0],
      rangeEnd: range[1],
      total,
    }),
    onChange: (page, pageSize) => {
      setPagination(prevPag => ({...prevPag, current: page, pageSize}))
      setSelectedRowKeys([])
    },
    style: {textAlign: 'center'},
  });
  const [filters, setFilters] = useState([])
  const [sorters, setSorters] = useState([])

  const
    {
      data: documents,
      isLoading: isLoadingDocuments,
      isFetching: isFetchingDocuments,
    } = useGetEventDocumentsQuery(event?.uuid ? {
        page: pagination.current,
        page_size: pagination.pageSize,
        uuid: event?.uuid
      } :
      skipToken)

  const {
    data: userData,
    isFetching: isFetchingUserData,
    isLoading: isLoadingUserData,
  } = useGetUserProfileQuery();

  const isEditing = userData?.type ? isEventDocumentEdit(userData?.type) : false;
  const isRemove = userData?.type ? isEventDocumentDelete(userData?.type) : false;
  const isDownload = userData?.type ? isEventDocumentView(userData?.type) : false;

  const [
    deleteDocument,
  ] = useDeleteDocumentMutation();

  const [
    addEvent,
  ] = useAddEventDocumentMutation();

  const columns = useMemo(() => [
    {
      dataIndex: 'name',
      key: 'name',
      title: capitalize(t('documents.fileName'))
    },
    {
      dataIndex: 'end_date',
      key: 'end_date',
      title: capitalize(t('documents.validityDate'))
    },
    {
      key: 'action',
      render: (text: string, record: DocumentType, index: number) => {
        return <DocumentActions
          documentItem={record}
          withDetails={true}
          withDownload={isDownload}
          withEdit={event?.reduced_state !== REDUCED_STATES.archived && isEditing}
          withRemove={event?.reduced_state !== REDUCED_STATES.archived && isRemove}
          onRemove={() => deleteDocument({documentUuid: record.uuid})}
        />;
      },
    }
  ], [deleteDocument, event?.reduced_state, isDownload, isEditing, isRemove, t]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_NONE,
    ],
  };

  return <Card
    title={capitalize(t('events.documents'))}
    extra={event?.reduced_state !== REDUCED_STATES.archived ? <PermissionRoute
      isAuthorized={isEventDocumentCreation}
    >
      <UploadDocumentModal
        onAfterUpload={(uploadedDocumentUuid) => {
          if (event?.uuid) {
            addEvent({
              eventUuid: event?.uuid,
              documentUuid: uploadedDocumentUuid,
            });
          }
        }}
      />
    </PermissionRoute> : null
    }
  >
    <Row gutter={[16, 16]} justify={'end'} style={{marginBottom: '1rem'}}>
      {event?.reduced_state !== REDUCED_STATES.archived && isRemove && <Col><DeleteMultipleDocuments uuids={selectedRowKeys as string[]}/></Col>}
      {isDownload && <Col><DownloadMultipleDocuments uuids={selectedRowKeys as string[]}/></Col>}
    </Row>

    <Table
      columns={columns}
      pagination={false}
      rowSelection={rowSelection}
      loading={
        {
          spinning: isLoadingDocuments || isFetchingDocuments || isLoadingUserData || isFetchingUserData,
          indicator: <CustomLoading/>,
        }
      }
      dataSource={documents?.results.map(document => ({key: document.uuid, ...document}))}
      scroll={{x: true}}
    />
    <Row
      justify={'center'}
      style={{marginTop: '1rem'}}
    >
      <Col>
        <Pagination
          total={documents?.count}
          {...pagination}
        />
      </Col>
    </Row>
  </Card>;
}