import {AssetsTableRecordFilter} from '../../types/api/assets';
import {apiSlice} from './baseApi';
import {AssetDetails, Marker} from '../../types/internal/Map';

const assetsMapApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAssetMap: build.query<Marker[], { filters: AssetsTableRecordFilter }>({
      query: ({filters}) => {
        return {
          url: '/assets-map/',
          params: {
            ...filters,
          },
        };
      },
    }),
    getAssetMapDetails: build.query<AssetDetails, { uuid: string }>({
      query: ({uuid}) => `/assets-map/${uuid}/`,
    }),
  }),
});

export const {
  useGetAssetMapQuery,
  useLazyGetAssetMapQuery,
  useLazyGetAssetMapDetailsQuery,
} = assetsMapApi;