import {useState} from 'react';
import {Typography} from 'antd';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import {ParagraphProps} from 'antd/lib/typography/Paragraph';

const {Paragraph, Link} = Typography;
export const ExpandableCollapsableParagraph = ({text, rows = 4, withTooltip = true, ...paragraphProps}: {
  text: string,
  rows?: number,
  withTooltip?: boolean,
} & ParagraphProps) => {
  
  const {t} = useTranslation();
  
  const [
    expanded,
    setExpanded,
  ] = useState<boolean | null>(null);
  const [
    counter,
    setCounter,
  ] = useState<number>(0);
  
  return (
    <Paragraph
      key={counter}
      ellipsis={{
        rows: rows,
        expandable: true,
        symbol: capitalize(t('misc.showMore')),
        tooltip: withTooltip ? text : '',
        onEllipsis: (ellipsis) => setExpanded(ellipsis),
      }}
      {...paragraphProps}
    >
      {text}
      {expanded &&
        <Link onClick={() => setCounter(counter => counter + 1)}>{` ${capitalize(t('misc.showLess'))}`}</Link>}
    </Paragraph>
  );
};