import {useEventContext} from "../../../context/event/EventContext";
import {Avatar, Col, List, Row, Space, Typography} from "antd";
import React from "react";
import {Cluster} from "../../../types/api/clusters";
import ClusterCommunicationView from "./ClusterCommunicationView";
import {useGetAllTheClustersOfTheHistoryOfTheEventNotPaginatedQuery} from "../../../redux/api/events";
import {skipToken} from "@reduxjs/toolkit/query";
import ClusterCommunicationCreate from "./ClusterCommunicationCreate";
import {capitalize} from "lodash";
import {useTranslation} from "react-i18next";
import {REDUCED_STATES} from "../../../types/api/getEventsResult";

const ClusterCommunicationItem = ({uuid, position_order, name, color_code, description, isActive, draft, sent}: Cluster & {
  isActive: boolean,
  draft: number,
  sent: number
}) => {

  const {t} = useTranslation()
  const {event} = useEventContext()

  return <List.Item>
    <Row align={'middle'} justify={'space-between'} style={{margin: '2rem 0', width: '100%'}}
         gutter={[16, 16]} wrap>
      <Col xs={24} lg={8}>
        <Space>
          <Avatar style={{backgroundColor: color_code}}/>
          {name}
        </Space>
      </Col>
      <Col xs={12} lg={4}>{capitalize(t('events.communicationDraft', {count: 2}))}: <Typography.Text
        strong>{draft}</Typography.Text></Col>
      <Col xs={12} lg={4}>{capitalize(t('events.communicationSent', {count: 2}))}: <Typography.Text
        strong>{sent}</Typography.Text></Col>
      <Col xs={24} lg={8} style={{textAlign: 'right'}}>
        <Space wrap>
          {isActive && event?.reduced_state !== REDUCED_STATES.archived && <ClusterCommunicationCreate cluster={uuid}/>}
          <ClusterCommunicationView cluster={{uuid, name, color_code, description, position_order}}/>
        </Space>
      </Col>
    </Row>
  </List.Item>
}

export default function ClustersCommunications() {

  const {event} = useEventContext()
  const {getClusterFromUuid} = useEventContext()

  const {
    data,
    isFetching,
    isUninitialized
  } = useGetAllTheClustersOfTheHistoryOfTheEventNotPaginatedQuery(event?.uuid ? {
    eventUuid: event.uuid,
  } : skipToken)

  const historyClusters = (data || [])?.reduce((prevVal, currVal) => {
    const foundCluster = getClusterFromUuid(currVal.cluster_template)
    return foundCluster ? [
        ...prevVal,
        {
          ...foundCluster,
          isActive: currVal.assets_count > 0,
          draft: currVal.draft_count,
          sent: currVal.sent_count
        }
      ] :
      prevVal
  }, [] as (Cluster & { isActive: boolean, draft: number, sent: number })[])

  const isLoading = isFetching || isUninitialized

  return <List
    loading={isLoading}
    dataSource={historyClusters}
    rowKey={"uuid"}
    renderItem={item => <ClusterCommunicationItem {...item} />
    }
  />
}