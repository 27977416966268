import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import ResponsiveContent from '../../../layout/ResponsiveContent';
import {Button} from 'antd';
import {capitalize} from 'lodash';
import LayersList from '../../../components/settings/layers/LayersList';
import PermissionRoute from '../../../components/Authentication/PermissionRoute';
import {isLayerMapCreation} from '../../../components/Authentication/permissions';

function LayerCreationButton() {
  const {t} = useTranslation();
  const navigate = useNavigate();

  return <Button
    type={'primary'}
    onClick={() => navigate('create')}
  >
    {capitalize(t('actions.createItem', {item: `${t('misc.new', {context: 'male'})} ${t('layers.layer', {count: 1})}`}))}
  </Button>;
}

export default function LayersSettings() {

  const {t} = useTranslation();
  const navigate = useNavigate();

  return <ResponsiveContent
    title={t('layers.layer', {count: 2})}
    goBack={() => navigate('/settings')}
    goBackText={capitalize(t('settings.settings'))}
    extra={<PermissionRoute
      isAuthorized={isLayerMapCreation}
    >
      <LayerCreationButton/>
    </PermissionRoute>}
  >
    <LayersList/>
  </ResponsiveContent>;
}