import { Form, FormItemProps, Input, InputProps} from 'antd';
import React from 'react';

export default function EventTypeNames({
                                       itLabel,
                                       enLabel,
                                     }:
                                       {
                                         itLabel: { formItem?: FormItemProps, input?: InputProps },
                                         enLabel: { formItem?: FormItemProps, input?: InputProps }
                                       }) {
  return <div style={{display: 'flex', columnGap: '3rem', rowGap: '1rem', alignItems: 'center', flexWrap: 'wrap'}}>
    <Form.Item wrapperCol={{xs: 24}} colon={false} {...itLabel.formItem}>
      <Input {...itLabel.input}/>
    </Form.Item>
    <Form.Item wrapperCol={{xs: 24}} colon={false} {...enLabel.formItem}>
      <Input {...enLabel.input}/>
    </Form.Item>
  </div>;
}