import {createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState} from 'react';

export type MapLoadingContextType = [
  isLoading: boolean,
  setIsLoading: Dispatch<SetStateAction<boolean>>
]

const LoadingContext = createContext<MapLoadingContextType>([
  false,
  () => null,
]);

export const useMapLoadingContext = () => useContext(LoadingContext);

export const MapLoadingContext = (props: PropsWithChildren) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <LoadingContext.Provider value={[isLoading, setIsLoading]}>
      {props.children}
    </LoadingContext.Provider>
  );
};