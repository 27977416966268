import {WMSTileLayer, WMSTileLayerProps} from 'react-leaflet';
import {useGetUserProfileQuery} from '../../../redux/api/locales';
import {useWMSLayerOpacityContext} from '../../../context/baseMap/WMSLayerOpacityContext';
import {memo} from 'react';

export const DummyWMSLayer = memo((props: { isInternal?: boolean } & WMSTileLayerProps) => {

  const [opacity] = useWMSLayerOpacityContext();

  const {data: userData} = useGetUserProfileQuery();
  const {isInternal, ...otherProps} = props;

  return (
    <WMSTileLayer
      transparent={true}
      crossOrigin={'anonymous'}
      referrerPolicy={'no-referrer'}
      opacity={opacity}
      format={'image/png'}
      zIndex={2}
      {...(props.isInternal && userData ? {authkey: userData.geoserver_key} : {})}
      {...otherProps}
    />
  );
});