import {apiSlice} from './baseApi';
import {GenericPaginatedApi, GenericPaginatedParamsApi} from '../../types/api/paginatedApi';
import {Tag} from '../../types/api/tags';
import {sanitize} from 'dompurify';

type getTagsParams = {
  search_by_name?: string
}

const tagsApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getTags: build.query<GenericPaginatedApi<Tag>, GenericPaginatedParamsApi & getTagsParams>({
      query: (args) => ({
        url: '/tags/',
        params: {
          ...args,
        },
      }),
    }),
    getTagsForInfiniteScrolling: build.query<GenericPaginatedApi<Tag>, GenericPaginatedParamsApi & getTagsParams>({
      query: ({page = 1, ...args}) => {
        return ({
          url: '/tags/',
          params: {
            page,
            ...args,
          },
        });
      },
      serializeQueryArgs: ({queryArgs}) => {
        const newQueryArgs = {...queryArgs};
        delete newQueryArgs.page;
        return newQueryArgs;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems, query) => {
        if ((query.arg.page || 0) > 1) {
          return {
            ...currentCache,
            ...newItems,
            results: [...currentCache.results, ...newItems.results],
          };
        }
        return newItems;
      },
    }),
    getAllTags: build.query<Tag[], void>({
      query: (args) => ({
        url: '/tags/',
        params: {
          paginate: false,
        },
      }),
    }),
  }),
});

export const {
  useLazyGetTagsQuery,
  useLazyGetTagsForInfiniteScrollingQuery,
  useGetAllTagsQuery,
} = tagsApi;