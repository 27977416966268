import {useLocation} from "react-router-dom";

export default function usePreviousPath() {

  const state = useLocation().state

  return {
    path: state?.path as string | undefined,
    label: state?.label as string | undefined,
  }
}