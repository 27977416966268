import {Card} from "antd";
import {capitalize} from "lodash";
import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import BiodiversityTable from "./BiodiversityTable";
import SelectBioDiversitySource from "../SelectBioDiversitySource";
import {ALL_BIODIVERSITY_SOURCE} from "../../assets/AssetsTable/filters/BiodiversitySourceFilter";
import {BIODIVERSITY_SOURCE} from "../../../types/api/assets";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

export default function BiodiversityCard() {

  const {t} = useTranslation()
  const [currentSource, setCurrentSource] = useState<BIODIVERSITY_SOURCE | (typeof ALL_BIODIVERSITY_SOURCE)>(ALL_BIODIVERSITY_SOURCE)
  const {xs} = useBreakpoint()

  return <Card
    title={capitalize(t('assets.biodiversity'))}
    bodyStyle={{
      ...xs && {paddingLeft: 0, paddingRight: 0}
    }}
    extra={
      <SelectBioDiversitySource
        selectProps={{
          value: currentSource,
          onSelect: (v) => setCurrentSource(v)
        }}
        text={{
          ...xs && {children: null}
        }}
      />}
  >
    <BiodiversityTable currentSource={currentSource}/>
  </Card>
}