import {apiSlice} from './baseApi';
import {
  AssetsRelatedOptionsFilterParams,
  AssetsTableRecord,
  AssetsTableRecordFilter,
  GetAssets,
  GetAssetsNotes,
  GetAssetsNotesParams,
} from '../../types/api/assets';
import {OptionLabelValue} from '../../types/internal/OptionLabelValue';
import {AssetModel, CreateAssetRequestType} from '../../types/internal/AssetModel';
import {PaginatedApi} from '../../types/api/paginatedApi';
import {downloadFileResponseHandler} from '../../utils/download';

export type RelatedOption = { value: string, display_name: string }

export type AssetField = {
  choices?: RelatedOption[],
}

export interface SearchAddress extends PaginatedApi {
  results: RelatedOption[];
}

export interface AssetsRelatedOptionGet extends PaginatedApi {
  results: RelatedOption[];
}

export type AssetOptions = {
  actions: {
    POST: {
      building_type_detail: AssetField,
      building_type: AssetField,
      building_status: AssetField,
      country: AssetField,
      ownership: AssetField,
    },
  }
}

export const parseOptions = (values: { value: string, display_name: string }[]) => values.map(el => ({
  value: el.value,
  label: el.display_name,
}));
const sortOptions = (values: OptionLabelValue[]) => values.sort((a, b) => a.label.localeCompare(b.label));

const parseAndSortOptions = (values: { value: string, display_name: string }[]) => sortOptions(parseOptions(values));

const assetsApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAssets: build.query<GetAssets, {
      page?: number,
      page_size?: number,
      filters?: AssetsTableRecordFilter,
      sorters?: string
    }>({
      query: (args) => {
        const {page, page_size, filters, sorters} = args;
        return {
          url: '/assets/',
          params: {
            page,
            page_size,
            ...filters,
            ...{...sorters && {ordering: sorters}},
          },
        };
      },
      providesTags: () => ['Locale'],
    }),
    getAssetsNotPaginated: build.query<AssetsTableRecord[], {
      filters?: AssetsTableRecordFilter,
      sorters?: string
    }>({
      query: (args) => {
        const {filters, sorters} = args;
        return {
          url: '/assets/',
          params: {
            paginate: false,
            ...filters,
            ...{...sorters && {ordering: sorters}},
          },
        };
      },
      providesTags: () => ['Locale'],
    }),
    getAssetsForInfiniteScrolling: build.query<GetAssets, {
      page?: number,
      page_size?: number,
      filters?: AssetsTableRecordFilter,
      sorters?: string
    }>({
      query: (args) => {
        const {page, page_size, filters, sorters} = args;
        return {
          url: '/assets/',
          params: {
            page,
            page_size,
            ...filters,
            ...{...sorters && {ordering: sorters}},
          },
        };
      },
      serializeQueryArgs: ({queryArgs}) => {
        const newQueryArgs = {...queryArgs};
        delete newQueryArgs.page;
        return newQueryArgs;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems, query) => {
        if ((query.arg.page || 0) > 1) {
          return {
            ...currentCache,
            ...newItems,
            results: [...currentCache.results, ...newItems.results],
          };
        }
        return newItems;
      },
    }),
    getAssetsOptions: build.query<{
      building_type_detail: OptionLabelValue[],
      building_type: OptionLabelValue[],
      building_status: OptionLabelValue[],
      country: OptionLabelValue[],
      ownership: OptionLabelValue[],
    }, void>({
      query: () => {
        return {
          url: '/assets/',
          method: 'options',
        };
      },
      providesTags: () => ['Locale'],
      transformResponse: (response: AssetOptions, meta, arg) => {
        
        return {
          building_type_detail: parseAndSortOptions(response.actions.POST.building_type_detail.choices || []),
          building_type: parseAndSortOptions(response.actions.POST.building_type.choices || []),
          country: parseAndSortOptions(response.actions.POST.country.choices || []),
          building_status: parseAndSortOptions(response.actions.POST.building_status.choices || []),
          ownership: parseAndSortOptions(response.actions.POST.ownership.choices || []),
        };
      },
    }),
    deleteAssets: build.mutation<null, { uuid: string }>({
      query: (args) => ({
        url: `/assets/${args.uuid}/`,
        method: 'delete',
      }),
    }),
    patchAsset: build.mutation<AssetModel, { uuid: string, patch: CreateAssetRequestType }>({
      query: (args) => ({
        url: `/assets/${args.uuid}/`,
        method: 'PATCH',
        body: args.patch,
      }),
      invalidatesTags: ['Asset'],
    }),
    addressSearch: build.query<SearchAddress, {
      field: 'address' | 'admin_level_1' | 'admin_level_2' | 'admin_level_3' | 'postal_code',
      value?: string,
      page_size?: number,
      page?: number
    }>({
      query: (args) => ({
        url: '/address_search/',
        params: args,
      }),
    }),
    getAssetsRelatedOptionsForInfiniteScrolling: build.query<AssetsRelatedOptionGet, AssetsRelatedOptionsFilterParams>({
      query: (args) => ({
        url: '/assets/related_options/',
        params: args,
      }),
      serializeQueryArgs: ({queryArgs}) => {
        const newQueryArgs = {...queryArgs};
        delete newQueryArgs.page;
        return newQueryArgs;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems, query) => {
        if ((query.arg.page || 0) > 1) {
          return {
            ...currentCache,
            ...newItems,
            results: [...currentCache.results, ...newItems.results],
          };
        }
        return newItems;
      },
    }),
    downloadAssetsReport: build.query<GetAssets, {
      filters?: AssetsTableRecordFilter,
      sorters?: string,
      columns?: string
    }>({
      query: (args) => {
        const {filters, sorters, columns} = args;
        return {
          url: '/assets/download/',
          params: {
            ...filters,
            ...{...sorters && {ordering: sorters}},
            columns,
          },
          //headers: {'Content-Type': 'application/csv'},
          responseHandler: downloadFileResponseHandler,
          cache: 'no-cache',
        };
      },
    }),
    downloadBiodiversityReport: build.query<GetAssets, {
      filters?: AssetsTableRecordFilter,
      sorters?: string,
      columns?: string
    }>({
      query: (args) => {
        const {filters, sorters, columns} = args;
        return {
          url: '/assets/download_biodiversity/',
          params: {
            ...filters,
            ...{...sorters && {ordering: sorters}},
            columns,
          },
          //headers: {'Content-Type': 'application/csv'},
          responseHandler: downloadFileResponseHandler,
          cache: 'no-cache',
        };
      },
    }),
    downloadHazardsReport: build.query<GetAssets, {
      filters?: AssetsTableRecordFilter,
      sorters?: string,
      columns?: string
    }>({
      query: (args) => {
        const {filters, sorters, columns} = args;
        return {
          url: '/assets/hazards_report/',
          params: {
            ...filters,
            ...{...sorters && {ordering: sorters}},
            columns,
          },
          //headers: {'Content-Type': 'application/csv'},
          responseHandler: downloadFileResponseHandler,
          cache: 'no-cache',
        };
      },
    }),
    searchAssetsBySigi: build.query<AssetModel[], {
      sigi: string
    }>({
      query: (args) => {
        return {
          url: '/assets/',
          params: {
            search_by_sigi: args.sigi,
            paginate: false,
          },
        };
      },
      providesTags: () => ['Locale'],
    }),
    getEventNotesOfTheAssetPaginated: build.query<GetAssetsNotes, GetAssetsNotesParams>({
      query: ({
                eventUuid, assetUuid, ...params
              }) => {
        return {
          url: `/assets/${assetUuid}/events/${eventUuid}/notes/`,
          params,
        };
      },
    }),
  }),
});

export const {
  useLazyGetAssetsQuery,
  useLazyGetAssetsNotPaginatedQuery,
  useLazyGetAssetsForInfiniteScrollingQuery,
  useGetAssetsOptionsQuery,
  useDeleteAssetsMutation,
  usePatchAssetMutation,
  useLazyAddressSearchQuery,
  useLazyDownloadAssetsReportQuery,
  useLazyDownloadBiodiversityReportQuery,
  useLazyDownloadHazardsReportQuery,
  useLazySearchAssetsBySigiQuery,
  useGetEventNotesOfTheAssetPaginatedQuery,
  useLazyGetAssetsRelatedOptionsForInfiniteScrollingQuery,
} = assetsApi;