import {CloudDownloadOutlined} from '@ant-design/icons';
import {Badge, Button, ConfigProvider, FloatButtonProps} from 'antd';
import {useQuerableLayerContext} from '../../../../context/baseMap/QuerableLayerContext';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
import React from 'react';
import {useBaseWMSLayerContext} from '../../../../context/baseMap/BaseWMSLayerContext';

export const AssetFilterFromLayer = (props: FloatButtonProps) => {

  const {t} = useTranslation();

  const [layers, currentWMSLayer] = useBaseWMSLayerContext();

  const [
    queryType,
    setQueryType,
  ] = useQuerableLayerContext();

  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgContainerDisabled: '#F5F5F5',
        },
      }}
    >
      <Badge
        dot={true}
        count={+(queryType === 'GET_POLYGON_FROM_LAYER')}
      >
        <Button
          icon={<CloudDownloadOutlined
            style={{color: queryType === 'GET_POLYGON_FROM_LAYER' ? '#4096ff' : '#000'}}
          />}
          size={'large'}
          style={{border: queryType === 'GET_POLYGON_FROM_LAYER' ? '1.5px solid #4096ff' : '1px solid #d9d9d9'}}
          disabled={currentWMSLayer === null || queryType === 'GET_FEATURE_INFO' || layers.find(layer => layer.uuid === currentWMSLayer)?.has_polygons === false}
          title={capitalize(t('map.layerQuery'))}
          onClick={() => setQueryType(mode => mode === 'GET_POLYGON_FROM_LAYER' ? null : 'GET_POLYGON_FROM_LAYER')}
        />
      </Badge>
    </ConfigProvider>
  );
};