import {PaginationConfig} from 'antd/lib/pagination';
import {
  ImportReportErrorTableFilters,
  ImportReportErrorTableSorters,
  ImportReportOkTableFilters,
  ImportReportOkTableSorters,
  ImportReportWarningTableFilters,
  ImportReportWarningTableSorters,
} from '../../types/internal/ImportReportTable';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../store';

interface ImportReportSlice {
  errors: {
    filters: ImportReportErrorTableFilters,
    sorters: ImportReportErrorTableSorters,
    pagination: PaginationConfig,
  },
  warnings: {
    filters: ImportReportWarningTableFilters,
    sorters: ImportReportWarningTableSorters,
    pagination: PaginationConfig,
  },
  ok: {
    filters: ImportReportOkTableFilters,
    sorters: ImportReportOkTableSorters,
    pagination: PaginationConfig,
  }
}

/*Filters*/
export const DEFAULT_IMPORT_REPORT_ERRORS_TABLE_FILTERS: ImportReportErrorTableFilters = {
  sigi_code: null,
  country: null,
  admin_level_1: null,
  admin_level_2: null,
  admin_level_3: null,
  address: null,
  operation: null,
  is_resolved: null,
};
export const DEFAULT_IMPORT_REPORT_WARNINGS_TABLE_FILTERS: ImportReportWarningTableFilters = {
  sigi_code: null,
  country: null,
  admin_level_1: null,
  admin_level_2: null,
  admin_level_3: null,
  address: null,
  operation: null,
  is_resolved: null,
};

export const DEFAULT_IMPORT_REPORT_OK_TABLE_FILTERS: ImportReportOkTableFilters = {
  sigi_code: null,
  country: null,
  admin_level_1: null,
  admin_level_2: null,
  admin_level_3: null,
  address: null,
  operation: null,
};

/*Sorters*/
export const DEFAULT_IMPORT_REPORT_ERRORS_TABLE_SORTERS: ImportReportErrorTableSorters = {
  sigi_code: null,
  country: null,
  admin_level_1: null,
  admin_level_2: null,
  admin_level_3: null,
  address: null,
  operation: null,
  is_resolved: null,
};
export const DEFAULT_IMPORT_REPORT_WARNINGS_TABLE_SORTERS: ImportReportWarningTableSorters = {
  sigi_code: null,
  country: null,
  admin_level_1: null,
  admin_level_2: null,
  admin_level_3: null,
  address: null,
  operation: null,
  is_resolved: null,
};
export const DEFAULT_IMPORT_REPORT_OK_SORTERS: ImportReportOkTableSorters = {
  sigi_code: null,
  country: null,
  admin_level_1: null,
  admin_level_2: null,
  admin_level_3: null,
  address: null,
  operation: null,
};

const initialState = {
  errors: {
    filters: DEFAULT_IMPORT_REPORT_ERRORS_TABLE_FILTERS,
    sorters: DEFAULT_IMPORT_REPORT_ERRORS_TABLE_SORTERS,
    pagination: {
      current: 1,
      pageSize: +(window.sessionStorage.getItem('import_report_errors_page_size') || 10),
    },
  },
  warnings: {
    filters: DEFAULT_IMPORT_REPORT_WARNINGS_TABLE_FILTERS,
    sorters: DEFAULT_IMPORT_REPORT_WARNINGS_TABLE_SORTERS,
    pagination: {
      current: 1,
      pageSize: +(window.sessionStorage.getItem('import_report_warnings_page_size') || 10),
    },
  },
  ok: {
    filters: DEFAULT_IMPORT_REPORT_OK_TABLE_FILTERS,
    sorters: DEFAULT_IMPORT_REPORT_OK_SORTERS,
    pagination: {
      current: 1,
      pageSize: +(window.sessionStorage.getItem('import_report_ok_page_size') || 10),
    },
  },
  
} as ImportReportSlice;

export const importReportSlice = createSlice({
  name: 'importReport',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    /*ERRORS*/
    setImportReportErrorsTableFilters: (state, {payload}: PayloadAction<Partial<ImportReportErrorTableFilters>>) => {
      state.errors.filters = {...state.errors.filters, ...payload};
      state.errors.pagination = {...state.errors.pagination, current: 1};
    },
    setImportReportErrorsTableSorters: (state, {payload}: PayloadAction<Partial<ImportReportErrorTableSorters>>) => {
      state.errors.sorters = {...state.errors.sorters, ...payload};
    },
    setImportReportErrorsTablePagination: (state, {payload}: PayloadAction<Partial<PaginationConfig>>) => {
      state.errors.pagination = payload;
      if (payload.pageSize) {
        window.sessionStorage.setItem('import_report_errors_page_size', `${payload.pageSize}`);
      }
    },
    /*WARNINGS*/
    setImportReportWarningsTableFilters: (state, {payload}: PayloadAction<Partial<ImportReportWarningTableFilters>>) => {
      state.warnings.filters = {...state.warnings.filters, ...payload};
      state.warnings.pagination = {...state.warnings.pagination, current: 1};
    },
    setImportReportWarningsTableSorters: (state, {payload}: PayloadAction<Partial<ImportReportWarningTableSorters>>) => {
      state.warnings.sorters = {...state.warnings.sorters, ...payload};
    },
    setImportReportWarningsTablePagination: (state, {payload}: PayloadAction<Partial<PaginationConfig>>) => {
      state.warnings.pagination = payload;
      if (payload.pageSize) {
        window.sessionStorage.setItem('import_report_warnings_page_size', `${payload.pageSize}`);
      }
    },
    /*OK*/
    setImportReportOkTableFilters: (state, {payload}: PayloadAction<Partial<ImportReportOkTableFilters>>) => {
      state.ok.filters = {...state.ok.filters, ...payload};
      state.ok.pagination = {...state.ok.pagination, current: 1};
    },
    setImportReportOkTableSorters: (state, {payload}: PayloadAction<Partial<ImportReportOkTableSorters>>) => {
      state.ok.sorters = {...state.ok.sorters, ...payload};
    },
    setImportReportOkTablePagination: (state, {payload}: PayloadAction<Partial<PaginationConfig>>) => {
      state.ok.pagination = payload;
      if (payload.pageSize) {
        window.sessionStorage.setItem('import_report_ok_page_size', `${payload.pageSize}`);
      }
    },
  },
});

export const {
  /*ERRORS*/
  setImportReportErrorsTableFilters,
  setImportReportErrorsTableSorters,
  setImportReportErrorsTablePagination,
  /*WARNINGS*/
  setImportReportWarningsTableFilters,
  setImportReportWarningsTableSorters,
  setImportReportWarningsTablePagination,
  /*OK*/
  setImportReportOkTableFilters,
  setImportReportOkTableSorters,
  setImportReportOkTablePagination,
} = importReportSlice.actions;

export const selectImportReportErrors = (state: RootState) => state.importReport.errors;
export const selectImportReportWarnings = (state: RootState) => state.importReport.warnings;
export const selectImportReportOk = (state: RootState) => state.importReport.ok;

export default importReportSlice.reducer;
