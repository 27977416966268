import {Col, Layout, Row, Typography} from 'antd';
import {EventsContext} from '../context/emergenciesMap/EventsContext';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
import {EventsFilterModal} from '../components/eventsMap/filters/EventsFilterModal';
import {EventsBaseMap} from '../components/eventsMap/EventsBaseMap';
import {BaseMapContext} from "../context/baseMap/BaseMapContext";
import EventsMapList from "../components/eventsMap/EventsMapList";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";
import {useState} from "react";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

const {Sider, Content} = Layout;
const {Title} = Typography;
export const EventsMap = () => {
  const {t} = useTranslation();
  const [
    isSidebarCollapsed,
    setIsSidebarCollapsed,
  ] = useState(false);
  const {lg, xl, xxl} = useBreakpoint()

  return (
    <EventsContext>
      <BaseMapContext>
        <Layout>
          <Content>
              <EventsBaseMap/>
          </Content>
          <Sider
            collapsible={!(lg || xl || xxl)}
            theme={'light'}
            width={283}
            breakpoint={'lg'}
            collapsed={isSidebarCollapsed}
            onCollapse={collapsed => setIsSidebarCollapsed(collapsed)}
            collapsedWidth={0}
            zeroWidthTriggerStyle={{backgroundColor: '#1890FF'}}
            trigger={isSidebarCollapsed ?
              <LeftOutlined style={{fontSize: 18, color: '#fff'}}/> :
              <RightOutlined style={{fontSize: 18, color: '#fff'}}/>}

            style={{zIndex: 99999}}
            reverseArrow
          >
            <Row style={{margin: '1rem 1rem'}}>
              <Col>
                <Title
                  level={3}
                  style={{marginTop: 0}}
                >
                  {capitalize(t('emergency.emergencyList'))}
                </Title>
              </Col>
            </Row>
            <Row style={{margin: '1rem 1rem'}}>
              <Col>
                <EventsFilterModal/>
              </Col>
            </Row>
            <EventsMapList/>
          </Sider>
        </Layout>
      </BaseMapContext>
    </EventsContext>
  );
};