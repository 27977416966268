import Icon from '@ant-design/icons';

const CircleSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20px" height="20px" viewBox="0 0 20 20" version="1.1"
  >
    <g id="surface1">
      <path
        d="M 9.101562 0.0390625 C 7.25 0.207031 5.496094 0.894531 3.90625 2.085938 C 3.429688 2.441406 2.441406 3.429688 2.085938 3.90625 C 1.058594 5.277344 0.429688 6.707031 0.15625 8.28125 C -0.328125 11.058594 0.339844 13.757812 2.085938 16.09375 C 2.441406 16.570312 3.429688 17.558594 3.90625 17.914062 C 6.242188 19.660156 8.941406 20.328125 11.71875 19.84375 C 13.292969 19.570312 14.722656 18.941406 16.09375 17.914062 C 16.570312 17.558594 17.558594 16.570312 17.914062 16.09375 C 19.660156 13.757812 20.328125 11.058594 19.84375 8.28125 C 19.570312 6.707031 18.941406 5.277344 17.914062 3.90625 C 17.558594 3.429688 16.570312 2.441406 16.09375 2.085938 C 14.003906 0.519531 11.597656 -0.183594 9.101562 0.0390625 Z M 10.800781 1.210938 C 13.078125 1.421875 15.277344 2.566406 16.726562 4.296875 C 19.519531 7.628906 19.519531 12.371094 16.726562 15.703125 C 15.277344 17.433594 13.078125 18.578125 10.800781 18.789062 C 8.464844 19.003906 6.128906 18.261719 4.296875 16.726562 C 2.351562 15.097656 1.171875 12.558594 1.171875 10 C 1.171875 7.359375 2.421875 4.761719 4.480469 3.121094 C 6.273438 1.691406 8.546875 1.003906 10.800781 1.210938 Z M 10.800781 1.210938 "
      />
    </g>
  </svg>
);

export const CircleIcon = () => (
  <Icon
    component={CircleSvg}
    className={'custom-icon'}
  />
);