import {Avatar, Button, Card, Col, List, Row, Space, Typography} from 'antd';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
import CreateCluster from './CreateCluster';
import EditCluster from './EditCluster';
import {useParams} from 'react-router-dom';
import {useEditClustersOrderMutation, useGetClusterTemplatesNotPaginatedQuery,} from '../../../redux/api/clusters';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import DeleteCluster from './DeleteCluster';
import PermissionRoute from '../../Authentication/PermissionRoute';
import {isEventTypesDelete, isEventTypesEdit} from '../../Authentication/permissions';
import {DragOutlined} from "@ant-design/icons";
import {Cluster} from "../../../types/api/clusters";
import {skipToken} from "@reduxjs/toolkit/query";
import {useMessageContext} from "../../../context/message/MessageContext";

const {Title} = Typography;
const END = 'END'
export default function ClusterList() {

  const {t} = useTranslation();
  const {uuid} = useParams();
  const [isDragMode, setIsDragMode] = useState(false)

  const dragItem = useRef('')
  const dragOverItem = useRef('')

  const {data, isFetching, isUninitialized} = useGetClusterTemplatesNotPaginatedQuery(uuid ? {uuid} : skipToken);
  const [updateOrder, {isLoading}] = useEditClustersOrderMutation()
  const [clusters, setClusters] = useState<Cluster[]>([])
  const isDragModeAvailable = clusters.length > 1
  const setMessage = useMessageContext()

  const cancelButton = useMemo(() => <Button
    onClick={() => {
      setIsDragMode(false)
      if (data) {
        setClusters(data)
      }
    }}
  >
    {capitalize(t('form.cancel'))}
  </Button>, [data, t])

  const enableDragButton = useMemo(() => <Button
    disabled={!isDragModeAvailable || isLoading}
    loading={isLoading}
    onClick={() => setIsDragMode(true)}
    icon={<DragOutlined/>}>
    {capitalize(t('clusters.order'))}
  </Button>, [isDragModeAvailable, isLoading, t])

  const confirmButton = useMemo(() => <Button
    type={'dashed'}
    danger
    onClick={async () => {
      if (uuid) {
        try {
          await updateOrder({eventTypeUuid: uuid, cluster_templates: clusters.map(el => el.uuid)}).unwrap()
          setMessage({success: capitalize(t('form.editSuccessMessage'))});
        } catch {
          setMessage({error: capitalize(t('form.editFailMessage'))});
        } finally {
          setIsDragMode(false)
        }
      }
    }}
  >
    {capitalize(t('actions.confirm'))}
  </Button>, [clusters, setMessage, t, updateOrder, uuid])

  const dragStart = (clusterUuid: string) => {
    dragItem.current = clusterUuid
  }

  const drop = () => {
    if (isDragModeAvailable) {
      setClusters(prevState => {
        const clusterToMove = prevState.find(el => el.uuid === dragItem.current)
        let reorderedClusters: Cluster[] = prevState.filter(cluster => cluster.uuid !== dragItem.current)
        const indexToInsert = dragOverItem.current === END ? prevState.length - 1 : prevState.findIndex(el => el.uuid === dragOverItem.current)

        if (indexToInsert > -1 && clusterToMove) {
          const firstPart = reorderedClusters.slice(0, indexToInsert)
          const secondPart = reorderedClusters.slice(indexToInsert)
          reorderedClusters = [...firstPart, clusterToMove, ...secondPart]
        }
        return reorderedClusters
      })
    }

    dragItem.current = ""
    dragOverItem.current = ""
  }

  const dragEnter = (id: string) => {
    dragOverItem.current = id
  }

  useEffect(() => {
    if (data) {
      setClusters(data)
    }
  }, [data]);

  return (
    <Card>
      <Row
        justify={'space-between'}
        align={'middle'}
        style={{marginBottom: '2rem'}}
        gutter={[16, 16]}
      >
        <Col>
          <Title
            level={4}
            style={{margin: 0}}
          >
            {capitalize(t('clusters.eventCluster', {count: 2}))}
          </Title>
        </Col>
        <PermissionRoute
          isAuthorized={isEventTypesEdit}
        >
          <Col>
            <Space wrap>
              {isDragMode && cancelButton}
              <Space>
                {isDragMode && confirmButton}
                {!isDragMode && enableDragButton}
              </Space>
              <CreateCluster/>
            </Space>
          </Col>
        </PermissionRoute>
      </Row>
      {isDragMode && <div style={{backgroundColor: 'gainsboro', height: '0.5rem'}}/>}
      <List
        loading={isFetching || isUninitialized}
        itemLayout="horizontal"
        dataSource={clusters}
        rowKey={'uuid'}
        renderItem={(cluster, index) => {
          if (uuid) {
            return <List.Item
              style={
                isDragMode ? {
                    border: '1px solid gainsboro',
                    margin: '0.5rem 0',
                    padding: '0.2rem'
                  }
                  : {}
              }
              onDragStart={() => dragStart(cluster.uuid)}
              onDragEnter={() => {
                dragEnter(cluster.uuid)
              }}
              onDragEnd={drop}
              draggable={isDragMode}
              actions={[
                <PermissionRoute isAuthorized={isEventTypesEdit}>
                  <EditCluster clusterUuid={cluster.uuid}/>
                </PermissionRoute>,
                <PermissionRoute isAuthorized={isEventTypesDelete}>
                  <DeleteCluster
                    clusterUuid={cluster.uuid}
                    eventUuid={uuid}
                    name={cluster.name}
                  />
                </PermissionRoute>,
              ]}
            >
              <List.Item.Meta
                avatar={<Space style={{marginLeft: '0.5rem'}}>
                  {isDragMode && <DragOutlined/>}
                  <Avatar style={{backgroundColor: cluster.color_code}}/>
                </Space>
                }
                title={cluster.name}
              />
            </List.Item>;
          }
        }}
      />
      {isDragMode && <div style={{backgroundColor: 'gainsboro', height: '0.5rem'}} onDragEnter={() => {
        dragEnter(END)
      }}/>}
    </Card>
  );
}