import {Button, Col, Row, Tag, Typography} from 'antd';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
import {RightOutlined} from '@ant-design/icons';
import React, {useEffect} from 'react';
import {useLazyGetEventQuery} from '../../../../redux/api/events';
import {Link} from "react-router-dom";

const {Text} = Typography;
export default function EventDetail({event}: { event: string | null }) {

  const {t} = useTranslation();
  const [getEvent, {data}] = useLazyGetEventQuery();

  useEffect(() => {
    if (event) {
      getEvent({uuid: event});
    }
  }, [event, getEvent]);

  return (
    <>
      {data && (
        <>
          <Text strong>
            {capitalize(t('alerts.connectedEvent'))}
          </Text>
          <Row justify={'space-between'} wrap={false}>
            <Col>
              {`${data.name} | ${data.code}`}
            </Col>
            {!!event && <Col>
              <Link
                to={`/events/${event}`}
              >
                <Button
                  type={'primary'}
                  size={'small'}
                  shape={'circle'}
                  icon={<RightOutlined/>}
                />
              </Link>
            </Col>}
          </Row>
        </>
      )}
      {!data && (
        <Tag color={'error'}>
          {capitalize(t('alerts.eventNotRelated'))}</Tag>
      )}
    </>
  );
}