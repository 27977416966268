import FilterColumnsButton from './FilterColumnsButton';
import React, {useState} from 'react';
import {Button, Checkbox, Col, Divider, Modal, Row} from 'antd';
import {capitalize, sortBy} from 'lodash';
import {useTranslation} from 'react-i18next';
import {getTranslationAlertToManageTableColumnKey} from '../../../utils/translation';
import {useDispatch, useSelector} from 'react-redux';
import {
  insertAlertsTableColumnsAtTheEnd,
  removeAlertsTableColumn,
  selectAlerts,
  setAlertsTableColumns,
} from '../../../redux/slices/alerts';
import {
  alertsColumns,
  AlertsColumns,
  AlertTableMode,
  DEFAULT_ALERT_TO_MANAGE_COLUMNS,
} from '../../../types/internal/tables/alertsTable';

export default function AlertsTableColumnsSelection({mode}: { mode: AlertTableMode }) {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const sliceKey = mode === 'managed' ? 'alertsManagedTable' : 'alertsToManageTable';

  const columns: AlertsColumns[] = useSelector(selectAlerts)[sliceKey].columns;
  const sortedColumns = sortBy(alertsColumns.map(
    el => ({
      value: el,
      label: capitalize(t(getTranslationAlertToManageTableColumnKey(el))),
    })), (col) => col.label);
  const handleChange = (key: AlertsColumns, isChecked: boolean) => {
    if (mode === 'toManage') {
      if (isChecked) {
        dispatch(insertAlertsTableColumnsAtTheEnd({columns: [key], mode: 'toManage'}));
      } else {
        dispatch(removeAlertsTableColumn({columns: [key], mode: 'toManage'}));
      }
    } else {
      if (isChecked) {
        dispatch(insertAlertsTableColumnsAtTheEnd({columns: [key], mode: 'managed'}));
      } else {
        dispatch(removeAlertsTableColumn({columns: [key], mode: 'managed'}));
      }
    }
  };

  return <>
    <Modal
      title={capitalize(t('table.selectColumns'))}
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      footer={<Row justify={'end'} style={{marginTop: '2rem'}}>
        <Col>
          <Button
            danger
            onClick={() => {
              if (mode === 'toManage') {
                dispatch(setAlertsTableColumns({columns: DEFAULT_ALERT_TO_MANAGE_COLUMNS, mode: 'toManage'}));
              } else {
                dispatch(setAlertsTableColumns({columns: DEFAULT_ALERT_TO_MANAGE_COLUMNS, mode: 'managed'}));
              }
            }}
          >
            {capitalize(t('form.reset'))}
          </Button>
        </Col>
      </Row>}
      closeIcon={false}
    >
      <Divider style={{margin: 0, marginBottom: '2rem'}}/>
      <Row style={{marginLeft: '1rem'}} gutter={[12, 12]}>
        {sortedColumns.map(el => (
          <Col
            key={el.value}
            span={24}
          >
            <Checkbox
              checked={columns.includes(el.value)}
              onChange={e => handleChange(el.value, e.target.checked)}
            >
              {el.label}
            </Checkbox>

          </Col>
        ))}
      </Row>
    </Modal>
    <FilterColumnsButton onClick={() => setIsModalOpen(true)}/>
  </>;
}