import {DocumentType, usePatchDocumentMutation} from '../../../../redux/api/documents';
import {capitalize} from 'lodash';
import {Button, Col, Form, Input, Modal, Row} from 'antd';
import {Dispatch, SetStateAction, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {LocalizedDatePicker} from '../../LocalizedDatePicker';
import dayjs, {Dayjs} from 'dayjs';
import {useMessageContext} from '../../../../context/message/MessageContext';
import {ACCEPTED_FILE_EXTENSIONS, sanitize_document_name, SPECIAL_CHARS_TO_ESCAPE} from '../../../../utils/documents';

const {TextArea} = Input;
export const EditDocumentModal = ({document, isOpen, setIsOpen}: {
  document: DocumentType,
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>
}) => {

  const {t} = useTranslation();

  const [editDocumentForm] = Form.useForm();
  const setMessage = useMessageContext();

  const [
    patchDocument,
    {
      isLoading: isPatchingDocument,
      isSuccess: isDocumentPatchSuccessful,
      isError: isDocumentPatchFailed,
      isUninitialized: isDocumentPatchUninitialized,
    },
  ] = usePatchDocumentMutation();

  const handleFinish = (values: {
    description: string,
    name: string,
    start_date: Dayjs | undefined,
    end_date: Dayjs | undefined
  }) => {
    patchDocument({
      documentUuid: document.uuid,
      name: sanitize_document_name(values.name),
      description: values.description,
      start_date: values.start_date?.format('YYYY-MM-DD') || null,
      end_date: values.end_date?.format('YYYY-MM-DD') || null,
    });
  };

  useEffect(() => {
    if (isDocumentPatchSuccessful && !isDocumentPatchUninitialized) {
      setMessage({success: capitalize(t('form.editSuccessMessage'))});
      setIsOpen(false);
    }
  }, [isDocumentPatchSuccessful, isDocumentPatchUninitialized, setIsOpen, setMessage, t]);

  useEffect(() => {
    if (isDocumentPatchFailed && !isDocumentPatchUninitialized) {
      setMessage({error: capitalize(t('form.editFailMessage'))});
      setIsOpen(false);
    }
  }, [isDocumentPatchFailed, isDocumentPatchUninitialized, setIsOpen, setMessage, t]);

  return (
    <>
      <Modal
        open={isOpen}
        title={capitalize(t('actions.editItem', {item: t('documents.document', {count: 1})}))}
        onCancel={() => {
          setIsOpen(false);
          editDocumentForm.resetFields();
        }}
        centered={true}
        destroyOnClose={true}
        footer={null}
      >
        <Form
          form={editDocumentForm}
          layout={'vertical'}
          onFinish={handleFinish}
          onFinishFailed={errorInfo => console.log('failed: ', errorInfo)}
          autoComplete={'off'}
          initialValues={{
            name: document.name,
            description: document.description,
            start_date: document.start_date ? dayjs(document.start_date) : undefined,
            end_date: document.end_date ? dayjs(document.end_date) : undefined,
          }}
        >
          <Form.Item
            name={'name'}
            label={capitalize(t('documents.documentName', {count: 1}))}
            rules={[
              {
                required: true,
                message: capitalize(t('documents.documentRequired')),
                validator: (_, value) => {
                  if (!value || value.trim() === '') {
                    return Promise.reject();
                  }
                  return Promise.resolve();
                },
              },
              ({getFieldValue}) => ({
                validator(_, value) {
                  if (value && value.trim() && SPECIAL_CHARS_TO_ESCAPE.some(char => value.includes(char))) {
                    return Promise.reject(new Error(capitalize(t('documents.noSpecialCharsAllowed'))));
                  }
                  return Promise.resolve();
                },
              }),
              {
                warningOnly: true,
                validator: (_, value) => {
                  if (value && value.trim() && ACCEPTED_FILE_EXTENSIONS.some(ext => value.includes(ext))) {
                    return Promise.reject(new Error(capitalize(t('documents.fileExtensionIsOptional'))));
                  }
                  return Promise.resolve();
                },
              }]}
          >
            <Input placeholder={capitalize(t('documents.documentName', {count: 1}))}/>
          </Form.Item>
          <Form.Item
            name={'description'}
            label={capitalize(t('documents.description'))}
          >
            <TextArea
              rows={2}
              placeholder={capitalize(t('documents.description'))}
            />
          </Form.Item>
          <Form.Item
            name={'start_date'}
            label={capitalize(t('documents.startDate'))}
          >
            <LocalizedDatePicker
              placeholder={capitalize(t('documents.startDate'))}
              style={{display: 'flex'}}
            />
          </Form.Item>
          <Form.Item
            name={'end_date'}
            label={capitalize(t('documents.endDate'))}
            rules={[
              ({getFieldValue}) => ({
                validator(_, value) {
                  if (value && editDocumentForm.getFieldValue('start_date') && value < editDocumentForm.getFieldValue('start_date')) {
                    return Promise.reject(new Error(capitalize(t('documents.endDateLessThenStartDate'))));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <LocalizedDatePicker
              placeholder={capitalize(t('documents.endDate'))}
              style={{display: 'flex'}}
            />
          </Form.Item>
          {/*<Form.Item name={"document"}
                     rules={[{required: true, message: capitalize(t("documents.imageRequired"))}]}
                     label={capitalize(t("documents.fileUpload"))}>
            <Dragger name={"file"}
                     multiple={false}
                     maxCount={1}
                     accept={"image/*"}
                     beforeUpload={(file) => {
                       if (file.size <= MAX_FILE_SIZE) {
                         setFileIsOk(true);
                       } else {
                         setFileIsOk(false);
                       }
                       if (!addDocumentForm.getFieldValue("document_name")) {
                         addDocumentForm.setFieldValue("document_name", file.name)
                       }
                       return false;
                     }}>
              <p className="ant-upload-drag-icon">
                <UploadOutlined/>
              </p>
              <p className="ant-upload-text">
                {capitalize(t("documents.dragOrSelectImage"))}
              </p>
            </Dragger>
          </Form.Item>*/}
          <Row
            justify={'end'}
            gutter={[24, 24]}
          >
            <Col>
              <Form.Item>
                <Button
                  type={'default'}
                  onClick={() => {
                    setIsOpen(false);
                    editDocumentForm.resetFields();
                  }}
                >
                  {capitalize(t('form.cancel'))}
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button
                  type={'primary'}
                  loading={isPatchingDocument}
                  htmlType={'submit'}
                >
                  {capitalize(t('actions.editItem', {item: t('documents.document', {count: 1})}))}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};