import {AssetModel} from '../../types/internal/AssetModel';
import {Col, Row, Typography} from 'antd';
import {capitalize} from 'lodash';
import {LabeledValue} from '../common/LabeledValue';
import {titleCase} from '../../utils/parser';
import {BaseMapContainer} from '../common/map/BaseMapContainer';
import {GoogleLayers} from '../../types/internal/Map';
import L from 'leaflet';
import {AssetsMapMarker} from '../assets/assetsMap/AssetsMapMarker';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useGetAssetsOptionsQuery} from '../../redux/api/assets';
import {VALUE_NOT_PRESENT} from "../../utils/translation";

const {Title} = Typography;
export const AssetLocation = ({asset}: { asset: AssetModel }) => {

  const {t} = useTranslation();
  const {data: assetsOptions} = useGetAssetsOptionsQuery();

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={8}>
          <LabeledValue
            label={capitalize(t('address.country'))}
            value={capitalize(assetsOptions?.country.find(country => country.value === asset.country)?.label || 'n/a')}
          />
        </Col>
        <Col span={8}>
          <LabeledValue
            label={capitalize(t('address.region'))}
            value={capitalize(asset.admin_level_1 || VALUE_NOT_PRESENT)}
          />
        </Col>
        <Col span={8}>
          <LabeledValue
            label={capitalize(t('address.province'))}
            value={asset.admin_level_2}
          />
        </Col>
      </Row>
      <Row style={{marginTop: '1rem'}} gutter={[24, 24]}>
        <Col span={8}>
          <LabeledValue
            label={capitalize(t('address.address'))}
            value={titleCase(asset.address?.toLowerCase() || '')}
          />
        </Col>
        <Col span={8}>
          <LabeledValue
            label={capitalize(t('address.municipality'))}
            value={capitalize(asset.admin_level_3 || VALUE_NOT_PRESENT)}
          />
        </Col>
        {asset.locality && <Col span={8}>
          <LabeledValue
            label={capitalize(t('address.locality'))}
            value={asset.locality}
          />
        </Col>}
        <Col span={8}>
          <LabeledValue
            label={capitalize(t('address.postalCode'))}
            value={asset.postal_code}
          />
        </Col>
      </Row>
      <Row style={{marginTop: '1rem'}} gutter={[24, 24]}>
        <Col span={8}>
          <LabeledValue
            label={`${capitalize(t('address.coordinates'))} ${t('address.latitude')}`}
            value={asset.position?.lat.toFixed(6)}
          />
        </Col>
        <Col span={8}>
          <LabeledValue
            label={`${capitalize(t('address.coordinates'))} ${t('address.longitude')}`}
            value={asset.position?.lng.toFixed(6)}
          />
        </Col>
        <Col span={8}>
          <LabeledValue
            label={`${capitalize(t('assets.asset_one'))}/${t('assets.movingAsset')}`}
            value={asset.is_mobile ? capitalize(t('assets.movingAsset')) : capitalize(t('assets.asset_one'))}
          />
        </Col>
      </Row>
      {asset.position && (
        <Row style={{marginTop: '1.5rem'}}>
          <Col flex={1}>
            <Title
              level={5}
              style={{fontWeight: 'lighter', padding: '0 0.5rem'}}
            >{t('misc.positionPreview').toUpperCase()}
            </Title>
            <BaseMapContainer
              withGeoSearch={false}
              withZoom={true}
              withInitialZoom={16}
              withLayerPicker={true}
              withBaseLayers={[GoogleLayers.roadmap, GoogleLayers.hybrid]}
              withCenter={new L.LatLng(asset.position.lat, asset.position.lng)}
              withStreetView={true}
              withStyle={{width: '100%', height: '360px'}}
            >
              <AssetsMapMarker
                position={new L.LatLng(asset.position.lat, asset.position.lng)}
                markerUuid={asset.uuid}
              />
            </BaseMapContainer>
          </Col>
        </Row>)}
    </>
  );
};