import {PropsWithChildren} from 'react';
import {BaseTileLayerContext} from './BaseTileLayerContext';
import {ControlContext} from './ControlContext';
import {MapLoadingContext} from './MapLoadingContext';
import {StreetViewContext} from './StreetViewContext';
import {BaseWMSLayerContext} from './BaseWMSLayerContext';
import {QuerableLayerContext} from './QuerableLayerContext';
import {MapSoftLoadingContext} from './MapSoftLoadingContext';

export const BaseMapContext = (props: PropsWithChildren) => {
  return (
    <ControlContext>
      <MapSoftLoadingContext>
        <MapLoadingContext>
          <BaseTileLayerContext>
            <BaseWMSLayerContext>
              <QuerableLayerContext>
                <StreetViewContext>
                  {props.children}
                </StreetViewContext>
              </QuerableLayerContext>
            </BaseWMSLayerContext>
          </BaseTileLayerContext>
        </MapLoadingContext>
      </MapSoftLoadingContext>
    </ControlContext>
  );
};