import React, {useCallback, useLayoutEffect, useState} from 'react';
import {Drawer, DrawerProps} from 'antd';
import {useResizingMapContext} from '../../context/baseMap/ResizingMapContext';
import {EllipsisOutlined} from '@ant-design/icons';

const ResizableDrawer = (props: DrawerProps) => {

  const [
    isResizing,
    setIsResizing,
  ] = useResizingMapContext();

  const [
    drawerWidth,
    setDrawerWidth,
  ] = useState<string | number | undefined>(650);

  const handleMousemove = useCallback((e: any) => {
    const offsetRight = document.body.offsetWidth - (e.clientX - document.body.offsetLeft);
    const minWidth = 650;
    const maxWidth = window.innerWidth - 120;
    if (offsetRight > minWidth && offsetRight < maxWidth) {
      setDrawerWidth(offsetRight);
    }
  }, []);

  const handleMouseup = useCallback((e: any) => {
    if (isResizing) {
      setIsResizing(false);
      document.removeEventListener('mousemove', handleMousemove);
      document.removeEventListener('mouseup', handleMouseup);
    }
  }, [handleMousemove, isResizing, setIsResizing]);

  const handleMousedown = useCallback((e: any) => {
    e.stopPropagation();
    e.preventDefault();
    document.addEventListener('mousemove', handleMousemove, {passive: true});
    document.addEventListener('mouseup', handleMouseup, {passive: true});
  }, [handleMousemove, handleMouseup]);

  useLayoutEffect(() => {
    setIsResizing(true);
  });

  return (
    <Drawer {...props}
            width={drawerWidth}
    >
      <div
        onMouseDown={handleMousedown}
        style={{
          cursor: 'ew-resize',
          margin: 0,
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          zIndex: 100000,
          backgroundColor: '#fafafa',
        }}
      >
        <EllipsisOutlined rotate={90} style={{height: '100%'}}/>
      </div>
      {props.children}
    </Drawer>
  );
};

export default ResizableDrawer;
