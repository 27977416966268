import {Button, ButtonProps, Modal} from "antd";
import EditEventForm from "./EditEventForm";
import {useState} from "react";
import {capitalize} from "lodash";
import {useTranslation} from "react-i18next";
import {useEventContext} from "../../../context/event/EventContext";
import {useGetEventQuery, usePatchEventMutation} from "../../../redux/api/events";
import {useMessageContext} from "../../../context/message/MessageContext";
import {hexString} from "../../../utils/colors";
import {skipToken} from "@reduxjs/toolkit/query";
import PermissionRoute from "../../Authentication/PermissionRoute";
import {isEventEdit} from "../../Authentication/permissions";

export default function EditEventModal({editButtonProps}: { editButtonProps?: ButtonProps }) {

  const [isModalOpen, setIsModalOpen] = useState(false)
  const {t} = useTranslation()
  const {event} = useEventContext()
  const [patchEvent, {isLoading}] = usePatchEventMutation()
  const setMessage = useMessageContext()

  const {
    data: ancestorEvent,
    isLoading: isLoadingAncestorEvent
  } = useGetEventQuery(event?.ancestor_event ? {uuid: event?.ancestor_event} : skipToken)

  const handleSubmit = (values: any) => {
    if (event?.uuid) {
      patchEvent({
        uuid: event.uuid,
        color_code: hexString(values.color_code),
        description: values.description,
        name: values.name,
        ...values.has_ancestor_event && {ancestor_event: values.ancestor_event},
        ...!values.has_ancestor_event && {ancestor_event: null}
      })
        .then(() => {
          setMessage({success: capitalize(t('form.editSuccessMessage'))});
          setIsModalOpen(false)
        })
        .catch(() => {
          setMessage({error: capitalize(t('form.editFailMessage'))});
        })
    }
  }

  return <PermissionRoute isAuthorized={isEventEdit}>
    <Modal
      destroyOnClose
      title={capitalize(t('actions.editItem', {item: t('events.event', {count: 1})}))}
      styles={{
        body: {
          margin: '1rem 0'
        }
      }}
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      footer={false}
    >
      {!!event && !(event.ancestor_event && isLoadingAncestorEvent) && <EditEventForm
        buttonProps={{
          loading: isLoading,
          disabled: isLoading
        }}
        formProps={{
          initialValues: {
            color_code: event.color_code,
            has_ancestor_event: !!event.ancestor_event,
            name: event.name,
            description: event.description,
            ancestor_event: ancestorEvent ? {
              label: `#${ancestorEvent.code} - ${ancestorEvent.name}`,
              value: ancestorEvent.uuid
            } : undefined,
          },
          onFinish: handleSubmit
        }}
      />}
    </Modal>
    <Button
      type={'primary'}
      children={capitalize(t('actions.edit'))}
      onClick={() => setIsModalOpen(true)}
      {...editButtonProps}
    />
  </PermissionRoute>
}