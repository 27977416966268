import React, {useState} from "react";
import {PaginationConfig} from "antd/lib/pagination";
import {useTranslation} from "react-i18next";
import {Button, Col, Pagination, Row, Table, Typography} from "antd";
import {useGetTemplateEmailsQuery} from "../../../redux/api/templateEmails";
import {ColumnsType} from "antd/lib/table";
import {DocumentsTableColumnHeader} from "../../documents/TablesViews/DocumentsTableColumnHeader";
import _, {capitalize} from "lodash";
import TableSorter from "../../common/TableSorter";
import {EmailTemplate} from "../../../types/api/templateEmails";
import {ExpandableTagList} from "../../common/ExpandableTagList";
import {TemplateEmailLanguageFilter} from "../../settings/templateMail/filters/TemplateEmailLanguageFilter";
import {CustomLoading} from "../../common/CustomLoading";
import {
  DEFAULT_TEMPLATE_EMAIL_TABLE_FILTERS,
  DEFAULT_TEMPLATE_EMAIL_TABLE_SORTERS
} from "../../../types/internal/templateEmail";
import {TemplateEmailTitleFilter} from "../../settings/templateMail/filters/TemplateEmailTitleFilter";
import {fromFeToBeSorter} from "../../../utils/parser";

export default function TemplateTable({onSelectTemplate}: { onSelectTemplate: (template: string | null) => void }) {

  const {t} = useTranslation()
  const [pagination, setPagination] = useState<PaginationConfig>({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    showTotal: (total, range) => t('table.pageSizeOfTotal', {
      rangeStart: range[0],
      rangeEnd: range[1],
      total,
    }),
    onChange: (page, pageSize) => setPagination(prevPag => ({...prevPag, current: page, pageSize})),
    style: {textAlign: 'center', marginTop: '2rem'},
  });
  const [sorters, setSorters] = useState(DEFAULT_TEMPLATE_EMAIL_TABLE_SORTERS)
  const [filters, setFilters] = useState(DEFAULT_TEMPLATE_EMAIL_TABLE_FILTERS)


  const {
    data: mailTemplates,
    isUninitialized,
    isFetching,
  } = useGetTemplateEmailsQuery({
    page: pagination.current,
    page_size: pagination.pageSize,
    ...(filters.title ? {
      title: filters.title?.map(title => title.value).join(','),
    } : {}),
    ...(filters.tags ? {
      tags: filters.tags?.map(tag => tag.value).join(','),
    } : {}),
    ...(filters.language ? {
      language: filters.language.map(language => language.value).join(','),
    } : {}),
    ordering: fromFeToBeSorter(sorters),
  });


  const columns: ColumnsType<any> = [
    {
      title: (
        <DocumentsTableColumnHeader
          title={capitalize(t('mailTemplate.title'))}
          sorter={<TableSorter
            value={sorters.title}
            onChange={type => setSorters(prevState => ({...prevState, title: type}))}
          />}
          filter={<TemplateEmailTitleFilter
            activeFilters={filters.title}
            handleFilterChange={(values) => {
              setFilters(prevState => ({...prevState, title: values}))
              setPagination(pag => ({...pag, current: 1}))
            }}
          />}
          onFilterReset={() => setFilters(prevState => ({...prevState, title: null}))}
          isFilterDisabled={_.isEmpty(filters.title)}
        />
      ),
      key: 'title',
      dataIndex: 'title',
    },
    {
      title: (
        <DocumentsTableColumnHeader
          title={capitalize(t('mailTemplate.tag'))}
          sorter={<TableSorter
            value={sorters.tags}
            onChange={type => setSorters(prevState => ({...prevState, tags: type}))}
          />}
          filter={<TemplateEmailLanguageFilter
            activeFilters={filters.title}
            handleFilterChange={(values) => {
              setFilters(prevState => ({...prevState, tags: values}))
              setPagination(pag => ({...pag, current: 1}))
            }}
          />}
          onFilterReset={() => setFilters(prevState => ({...prevState, tags: null}))}
          isFilterDisabled={_.isEmpty(filters.tags)}
        />
      ),
      key: 'tags',
      dataIndex: 'tags',
      render: (text: string, record: EmailTemplate, index: number) => {
        return (<ExpandableTagList
          tags={record.tags.map(tag => ({
            uuid: tag.frontend_name,
            content: tag.frontend_name,
            color: tag.color,
          }))}
        />);
      },
    },
    {
      title: (
        <DocumentsTableColumnHeader
          title={capitalize(t('mailTemplate.language'))}
          sorter={<TableSorter
            value={sorters.language}
            onChange={type => setSorters(prevState => ({...prevState, language: type}))}
          />}
          filter={<TemplateEmailLanguageFilter
            activeFilters={filters.language}
            handleFilterChange={(values) => {
              setFilters(prevState => ({...prevState, language: values}))
              setPagination(pag => ({...pag, current: 1}))
            }}
          />}
          onFilterReset={() => setFilters(prevState => ({...prevState, language: null}))}
          isFilterDisabled={_.isEmpty(filters.language)}
        />
      ),
      key: 'language',
      dataIndex: 'language',
      render: (text: string, record: EmailTemplate, index: number) => {
        return <ExpandableTagList
          tags={[{
            uuid: record.language,
            content: record.language === 'it' ? capitalize(t('misc.italian')) : capitalize(t('misc.english')),
            color: '#c9c9c9',
          }]}
        />;
      },
    },
    {
      key: 'actions',
      render: (text: string, record: EmailTemplate, index: number) => {
        return <Button
          type={'primary'}
          onClick={() => onSelectTemplate(record.uuid)}
        >
          {capitalize(t('mailTemplate.use'))}
        </Button>;
      },
    },
  ];

  return <>
    <Typography.Title
      level={4}
      style={{margin: '1rem 0'}}
    >
      {capitalize(t('actions.selectItem', {item: t('mailTemplate.mailTemplate')}))}
    </Typography.Title>
    <Table
      dataSource={mailTemplates?.results}
      columns={columns}
      pagination={false}
      rowKey={'uuid'}
      scroll={{x: true}}
      loading={
        {
          spinning: isFetching || isUninitialized,
          indicator: <CustomLoading/>,
        }
      }
    />
    <Row
      justify={'center'}
      style={{marginTop: '1rem'}}
    >
      <Col>
        <Pagination
          total={mailTemplates?.count}
          {...pagination}
        />
      </Col>
    </Row>
  </>
}