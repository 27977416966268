import {Typography} from 'antd';
import {DetailedHTMLProps, LabelHTMLAttributes} from 'react';

const {Text} = Typography;
export default function Label(props: {
  label: string,
  required?: boolean
} & DetailedHTMLProps<LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>) {
  const {label, required, ...labelProps} = props;
  return (
    <label {...labelProps}>
      <Text strong={true}>{props.label}</Text>
      {props.required === true && <Text type={'danger'}>*</Text>}
    </label>
  );
}