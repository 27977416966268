import React, {CSSProperties, ReactNode} from 'react';
import {Button, Col, Row, Space, Typography} from 'antd';
import {capitalize} from 'lodash';
import {ArrowLeftOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';

export default function ResponsiveContent(
  {
    children,
    title,
    titleHeader,
    goBack,
    goBackText,
    extra,
    containerStyle,
  }:
    {
      children: ReactNode,
      title?: string,
      titleHeader?: ReactNode,
      goBack?: () => void,
      goBackText?: string,
      extra?: ReactNode,
      containerStyle?: CSSProperties
    },
) {

  const {t} = useTranslation();

  return <div
    className={'content'}
    style={{display: 'flex', flexDirection: 'column', gap: '1.5rem', ...containerStyle}}
  >
    <div>{!!goBack && <Button type={'link'} onClick={goBack} style={{padding: 0}}>
      <Space size={16}>
        <ArrowLeftOutlined/>
        {!!goBackText &&
          <Typography.Text>{capitalize(t('actions.goBackDestination', {destination: goBackText}))}</Typography.Text>}
        {!goBackText && <Typography.Text>{capitalize(t('actions.goBack'))}</Typography.Text>}
      </Space>
    </Button>}
      {!titleHeader && <Row justify={'space-between'} align={'middle'} gutter={[24, 24]}>
        {!!title && <Col>
          <Typography.Title level={3} style={{marginTop: 0}}>
            {capitalize(title)}
          </Typography.Title>
        </Col>}
        {!!extra && <Col>{extra}</Col>}
      </Row>}
      {!!titleHeader && titleHeader}
    </div>
    {children}
  </div>;
}