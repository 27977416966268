import {Card, Col, Row} from 'antd';
import {Cluster} from '../../../types/api/clusters';
import ClusterMap from './ClusterMap';
import {GoogleLayers} from '../../../types/internal/Map';
import {BaseMapContainer} from '../../common/map/BaseMapContainer';
import ClusterEntities from './ClusterEntities';
import GlobalPolygonsList from './GlobalPolygonsList';
import {useBaseWMSLayerContext} from '../../../context/baseMap/BaseWMSLayerContext';
import {BaseWMSLayer} from '../../common/map/BaseWMSLayer';
import React from 'react';

export default function EventClusterEdit({cluster}: { cluster: Cluster }) {
  
  const [layers, currentWMSLayer] = useBaseWMSLayerContext();
  const WMSLayer = layers?.find(layer => layer.uuid === currentWMSLayer);
  
  return (
    <div style={{marginTop: '1rem'}}>
      <BaseMapContainer
        withZoom={true}
        withGeoSearch={true}
        withLayerPicker={true}
        withBaseLayers={[GoogleLayers.roadmap, GoogleLayers.hybrid]}
        withWMSLayers={layers || []}
        withSoftLoading={true}
        withQuerableLayers={true}
        withLegend={true}
        withPopoverConfiguration={{overlayInnerStyle: {width: 320}}}
        isStandAlone={true}
        withStyle={{width: '100%', height: '60vh'}}
        withAssetFilterFromLayer={true}
      >
        <ClusterMap clusterUuid={cluster.uuid}/>
        {!!WMSLayer && (
          <BaseWMSLayer
            url={WMSLayer.wms_baseurl}
            layers={WMSLayer.name}
            isInternal={WMSLayer.is_internal || undefined}
            key={WMSLayer.uuid}
          />
        )}
      </BaseMapContainer>
      <Card>
        <Row gutter={[16, 16]}>
          <Col xs={24} md={12}>
            <ClusterEntities cluster={cluster}/>
          </Col>
          <Col xs={24} md={12}>
            <GlobalPolygonsList cluster={cluster}/>
          </Col>
        </Row>
      </Card>
    </div>
  );
}