import {apiSlice} from './baseApi';

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    login: builder.mutation<{ access: string, refresh: string }, { username: string, password: string }>({
      query: (credentials) => ({
        url: 'auth/get-token/',
        method: 'POST',
        body: credentials,
      }),
      invalidatesTags: ['User'],
    }),
    refreshToken: builder.mutation<{ access: string }, { refresh: string }>({
      query: (body) => ({
        url: 'auth/refresh-token/',
        method: 'POST',
        body: body,
      }),
    }),
  }),
});

export const {useLoginMutation, useRefreshTokenMutation} = authApiSlice;