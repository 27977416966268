import {useMemo, useState} from 'react';
import {EditDocumentModal} from '../modals/EditDocumentModal';
import {DocumentDetailsModal} from '../modals/DocumentDetailsModal';
import {DocumentType, useLazyGetUrlQuery} from '../../../../redux/api/documents';
import {capitalize} from 'lodash';
import {Trans, useTranslation} from 'react-i18next';
import DeleteModalMessage from '../modals/DeleteModalMessage';
import {Button, Dropdown, MenuProps, Space} from 'antd';
import {
  CopyOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  InfoCircleOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import {useMessageContext} from '../../../../context/message/MessageContext';
import {EditImageModal} from '../modals/EditImageModal';
import {ImageDetailsModal} from '../modals/ImageDetailsModal';

export const DocumentActions = (
  {
    documentItem,
    withDetails = false,
    isDetailsOptionInDropdownMenu = false,
    withDownload = false,
    withEdit = false,
    withCopy = false,
    onCopyClick,
    withRemove = false,
    onRemove,
    withDelete = false,
    onDelete,
  }: {
    documentItem: DocumentType,
    withDetails?: boolean,
    isDetailsOptionInDropdownMenu?: boolean,
    withDownload?: boolean,
    withEdit?: boolean,
    withCopy?: boolean,
    onCopyClick?: () => Promise<any>,
    withRemove?: boolean,
    onRemove?: () => Promise<any>,
    withDelete?: boolean,
    onDelete?: () => Promise<any>
  }) => {

  const {t} = useTranslation();
  const setMessage = useMessageContext();

  const [
    isEditDocumentModalOpen,
    setIsEditDocumentModalOpen,
  ] = useState(false);

  const [
    isDocumentDetailsModalOpen,
    setIsDocumentDetailsModalOpen,
  ] = useState(false);

  const [
    isDeleteModalOpen,
    setIsDeleteModalOpen,
  ] = useState(false);

  const [
    isRemoveModalOpen,
    setIsRemoveModalOpen,
  ] = useState(false);

  const [
    getUrl,
  ] = useLazyGetUrlQuery();

  const items: MenuProps['items'] = useMemo(() => [
    ...(withDownload ? [{
      label: capitalize(t('actions.download')),
      key: 'download',
      icon: <DownloadOutlined/>,
    }] : []),
    ...(withDetails && isDetailsOptionInDropdownMenu ? [{
      label: capitalize(t('actions.details')),
      key: 'details',
      icon: <InfoCircleOutlined/>,
    }] : []),
    ...(withEdit ? [
      {
        label: capitalize(t('actions.edit')),
        key: 'edit',
        icon: <EditOutlined/>,
      },
    ] : []),
    ...(withCopy ? [
      {
        label: capitalize(t('actions.copy')),
        key: 'copy',
        icon: <CopyOutlined/>,
      },
    ] : []),
    ...(withRemove ? [
      {
        label: capitalize(t('actions.remove')),
        key: 'remove',
        icon: <DeleteOutlined/>,
        danger: true,
      },
    ] : []),
    ...(withDelete ? [
      {
        label: capitalize(t('actions.delete')),
        key: 'delete',
        icon: <DeleteOutlined/>,
        danger: true,
      },
    ] : []),
  ], [withDownload, t, withDetails, isDetailsOptionInDropdownMenu, withEdit, withCopy, withRemove, withDelete]);

  const handleDownload = async () => {
    const awsUrl = await getUrl({documentUuid: documentItem.uuid}).unwrap();
    fetch(awsUrl.url, {
      method: 'GET',
      headers: {},
    })
      .then(response => {
        response.arrayBuffer().then(function (buffer) {
          const url = URL.createObjectURL(new Blob([buffer]));
          const responseContentDisposition = ((new URL(awsUrl.url)).searchParams.get('response-content-disposition')) || '';
          const filename = responseContentDisposition.substring(responseContentDisposition.indexOf('"') + 1, responseContentDisposition.lastIndexOf('"'));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${filename}`); //or any other extension
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(url);
        });
      })
      .catch(err => {
        console.log(err);
        setMessage({error: capitalize(t('form.fetchingFailMessage'))});
      });
  };

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    switch (e.key) {
      case 'details':
        setIsDocumentDetailsModalOpen(true);
        break;
      case 'download':
        handleDownload();
        break;
      case 'edit':
        setIsEditDocumentModalOpen(true);
        break;
      case 'copy':
        if (onCopyClick)
          onCopyClick();
        break;
      case 'remove':
        setIsRemoveModalOpen(true);
        break;
      case 'delete':
        setIsDeleteModalOpen(true);
        break;
    }
  };

  return (
    <div style={{display: 'flex', justifyContent: 'end'}}>
      {documentItem.type === 'T' && <EditDocumentModal
        document={documentItem}
        isOpen={isEditDocumentModalOpen}
        setIsOpen={setIsEditDocumentModalOpen}
      />}
      {documentItem.type === 'P' && <EditImageModal
        image={documentItem}
        isOpen={isEditDocumentModalOpen}
        setIsOpen={setIsEditDocumentModalOpen}
      />}
      {documentItem.type === 'T' && <DocumentDetailsModal
        documentItem={documentItem}
        open={isDocumentDetailsModalOpen}
        setOpen={setIsDocumentDetailsModalOpen}
      />}
      {documentItem.type === 'P' && <ImageDetailsModal
        image={documentItem}
        open={isDocumentDetailsModalOpen}
        setOpen={setIsDocumentDetailsModalOpen}
        onDelete={() => setIsDeleteModalOpen(true)}
        onEdit={() => setIsEditDocumentModalOpen(true)}
        onDownload={handleDownload}
        withDelete={withDelete}
        withDownload={withDownload}
        withEdit={withEdit}
      />
      }
      {/*remove modal message*/}
      <DeleteModalMessage
        title={{children: capitalize(t('actions.remove'))}}
        paragraph={{
          children: <Trans
            i18nKey={'documents.remove'}
            values={{document_name: documentItem.name}}
          />,
        }}
        modal={{
          open: isRemoveModalOpen,
          onCancel: () => setIsRemoveModalOpen(false),
        }}
        confirmButton={{
          onClick: () => {
            if (onRemove) {
              onRemove().then(
                () => {
                  setMessage({success: documentItem.type === 'T' ? capitalize(t('form.removeSuccessMessage')) : capitalize(t('documents.imageDeleteSuccessful'))});
                },
                (err) => {
                  setMessage({error: documentItem.type === 'T' ? capitalize(t('form.removeFailMessage')) : capitalize(t('documents.imageDeleteFailed'))});
                });
              setIsRemoveModalOpen(false);
            }
          },
        }
        }
      />
      {/*delete modal message*/}
      <DeleteModalMessage
        title={{children: capitalize(t('actions.delete'))}}
        paragraph={{
          children: <Trans
            i18nKey={'documents.delete'}
            values={{document_name: documentItem.name}}
          />,
        }}
        modal={{
          open: isDeleteModalOpen,
          onCancel: () => setIsDeleteModalOpen(false),
        }}
        confirmButton={{
          onClick: () => {
            if (onDelete) {
              onDelete().then(
                () => {
                  setMessage({success: documentItem.type === 'T' ? capitalize(t('form.deleteSuccessMessage')) : capitalize(t('documents.imageDeleteSuccessful'))});
                },
                (err) => {
                  setMessage({error: documentItem.type === 'T' ? capitalize(t('form.deleteFailMessage')) : capitalize(t('documents.imageDeleteFailed'))});
                });
              setIsDeleteModalOpen(false);
            }
          },
        }
        }
      />
      <Space
        wrap={false}
        direction={'horizontal'}
        style={{textAlign: 'right'}}
      >
        {withDetails && !isDetailsOptionInDropdownMenu && (
          <Button
            type={'link'}
            onClick={() => setIsDocumentDetailsModalOpen(true)}
          >
            {capitalize(t('documents.viewDetails'))}
          </Button>
        )}
      </Space>
      <Dropdown menu={{items, onClick: handleMenuClick}}>
        <Button
          type={'text'}
          icon={<MoreOutlined/>}
        />
      </Dropdown>
    </div>
  );

};