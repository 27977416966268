import {CustomMarkerClusterGroup} from './CustomMarkerClusterGroup';
import {AssetsMapMarker} from './AssetsMapMarker';
import L from 'leaflet';
import {useMarkersContext} from '../../../context/assetsMap/MarkersContext';
import {AssetDetailsPopup} from './customPopup/AssetDetailsPopup';
import React from 'react';

export const MarkersCluster = () => {

  const {markers} = useMarkersContext();

  return (
    <CustomMarkerClusterGroup>
      {
        markers &&
        markers
          .filter(marker => marker.position !== null)
          .map(marker => (
              <AssetsMapMarker
                position={L.latLng([marker.position?.lat || 0, marker.position?.lng || 0])}
                markerUuid={marker.uuid}
                key={marker.uuid}
              >
                <AssetDetailsPopup markerUuid={marker.uuid}/>
              </AssetsMapMarker>
            ),
          )}
    </CustomMarkerClusterGroup>
  );
};