import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import ResponsiveContent from '../../../layout/ResponsiveContent';
import {capitalize} from 'lodash';
import ClusterList from '../../../components/settings/clusters/ClusterList';
import EventTypeDocuments from '../../../components/settings/documents/EventTypeDocuments';
import DeleteEventType from '../../../components/settings/events/DeleteEventType';
import {Typography} from 'antd';
import EditEventType from '../../../components/settings/events/EditEventType';
import {useGetEventTypeQuery} from '../../../redux/api/eventTypes';
import React, {useEffect} from 'react';
import {useMessageContext} from '../../../context/message/MessageContext';
import EventTypeIcon from '../../../components/common/icons/EventTypeIcon';
import {
  isEventTypeDocumentView,
  isEventTypesDelete,
  isEventTypesEdit,
} from '../../../components/Authentication/permissions';
import PermissionRoute from '../../../components/Authentication/PermissionRoute';
import {skipToken} from "@reduxjs/toolkit/query";

export default function EventTypeSettings() {

  const {t} = useTranslation();
  const navigate = useNavigate();
  const {uuid} = useParams();

  const {data, error, isError} = useGetEventTypeQuery(uuid ? {uuid} : skipToken)
  const setMessage = useMessageContext();

  useEffect(() => {
    if (error && 'status' in error && error.status === 404) {
      setMessage({
        error: capitalize(t('form.notFoundMessage')),
      });
      navigate('/settings/events');
    }
  }, [error, isError, navigate, setMessage, t]);

  const src = process.env.REACT_APP_MEDIA && data?.icon?.svg ? `${process.env.REACT_APP_MEDIA}${data.icon.svg}` : undefined;

  return <ResponsiveContent
    goBack={() => navigate('/settings/events')}
    goBackText={capitalize(t('events.eventType', {count: 1}))}
    titleHeader={<div style={{display: 'flex', gap: '1rem', alignItems: 'center'}}>
      <EventTypeIcon src={src} style={{height: '32px', width: '32px', backgroundColor: '#FFF'}}/>
      <Typography.Title level={3} style={{marginTop: 0}}>
        {!!data?.name && data.name}
      </Typography.Title>
      {data &&
        <PermissionRoute
          isAuthorized={isEventTypesEdit}
        >
          <EditEventType icon={data?.icon} color={data?.color_code}/>
        </PermissionRoute>}
    </div>}
  >
    <div style={{display: 'flex', flexDirection: 'column', gap: '2rem'}}>
      <ClusterList/>
      <PermissionRoute
        isAuthorized={isEventTypeDocumentView}
      >
        <EventTypeDocuments/>
      </PermissionRoute>
      <PermissionRoute
        isAuthorized={isEventTypesDelete}
      >
        <DeleteEventType name={data?.name}/>
      </PermissionRoute>
    </div>

  </ResponsiveContent>;
}