import {useGetEventQuery} from "../../redux/api/events";
import {Popup} from "react-leaflet";
import {Skeleton} from "antd";
import {skipToken} from "@reduxjs/toolkit/query";
import React from "react";
import {EventMapDetail} from "./EventsCard";

export default function EventPopup({eventUuid, open = false}: { eventUuid: string, open: boolean }) {

  const {data: event, isFetching, isUninitialized} = useGetEventQuery(open ? {uuid: eventUuid} : skipToken)

  return <Popup>
    <Skeleton loading={isFetching || isUninitialized}>
      {event && <EventMapDetail event={event} />}
    </Skeleton>
  </Popup>
}