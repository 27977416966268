import {apiSlice} from './baseApi';
import {Icon} from '../../types/api/icons';

const iconsApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getIconsNotPaginated: build.query<Icon[], void>({
      query: () => ({
        url: `/icons/`,
        params: {
          paginate: false,
        },
      }),
    }),
  }),
});

export const {useGetIconsNotPaginatedQuery} = iconsApi;