import {Checkbox, Form} from 'antd';
import dayjs from 'dayjs';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
import {LocalizedDatePicker} from '../LocalizedDatePicker';

export default function DatesFilter({start, end, isNull, onEndChange, onStartChange, onNullChange}: {
  onNullChange: (value: boolean) => void,
  onStartChange: (value: string | undefined) => void,
  onEndChange: (value: string | undefined) => void,
  start?: string,
  end?: string,
  isNull?: boolean,
}) {

  const {t} = useTranslation();

  return <Form
    layout={'vertical'}
  >
    <Form.Item
      label={capitalize(t('misc.start'))}
    >
      <LocalizedDatePicker
        placeholder={isNull ? '' : ''}
        disabled={isNull}
        value={start ? dayjs(start) : undefined}
        onChange={(v) => onStartChange(v ? v.format('YYYY-MM-DD') : undefined)}
      />

    </Form.Item>
    <Form.Item
      label={capitalize(t('misc.end'))}
    >
      <LocalizedDatePicker
        placeholder={isNull ? '' : ''}
        disabled={isNull}
        value={end ? dayjs(end) : undefined}
        onChange={(v) => onEndChange(v ? v.format('YYYY-MM-DD') : undefined)}
      />
    </Form.Item>

    <Checkbox
      checked={isNull}
      onChange={e => onNullChange(e.target.checked)}
      style={{marginTop: '1rem'}}
    >
      {capitalize(t('misc.nullDate'))}
    </Checkbox>
  </Form>;
}