import {
  Col,
  DatePickerProps, Divider,
  Drawer,
  DrawerProps,
  ListProps,
  Row,
  Space,
  TabsProps,
  Typography
} from "antd";
import CommunicationTabs, {AssetTabs, ClusterTabs} from "./CommunicationTabs";
import CommunicationList from "./CommunicationList";
import {EventCommunications} from "../../../types/api/communications";
import {capitalize} from "lodash";
import {useTranslation} from "react-i18next";
import {LocalizedDatePicker} from "../../common/LocalizedDatePicker";

export default function CommunicationViewDrawer({communicationTab, list, withReceived, drawer, tabs, datePickerAfter, datePickerBefore}: {
  drawer?: DrawerProps,
  tabs?: TabsProps,
  datePickerAfter?: DatePickerProps,
  datePickerBefore?: DatePickerProps,
  withReceived?: boolean,
  list?: ListProps<EventCommunications>,
  communicationTab: AssetTabs | ClusterTabs
}) {

  const {t} = useTranslation()

  return <>
    <Drawer
      size={'large'}
      extra={<CommunicationTabs tabs={tabs} withReceived={withReceived}/>}
      styles={{
        header: {
          marginTop: '1rem'
        },
      }}
      {...drawer}
    >
      <Row justify={'start'} gutter={[16, 16]}>
        <Col>
          <Space direction={'vertical'}>
            <Typography.Text strong>{capitalize(t('misc.start'))}</Typography.Text>
            <LocalizedDatePicker
              style={{width: '12rem'}}
              {...datePickerAfter}/>
          </Space>
        </Col>
        <Col>
          <Space direction={'vertical'}>
            <Typography.Text strong>{capitalize(t('misc.end'))}</Typography.Text>
            <LocalizedDatePicker
              style={{width: '12rem'}}
              {...datePickerBefore}/>
          </Space>
        </Col>
      </Row>
      <Divider style={{margin: '1rem 0'}}/>
      <CommunicationList list={list} communicationTab={communicationTab}/>
    </Drawer>
  </>
}