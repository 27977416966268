import {useEffect} from 'react';

type ModifierKey = 'Control' | 'Alt';
type KeyboardKey = string;

interface Shortcut {
  keyboardKey: KeyboardKey;
  modifierKey?: ModifierKey;
}

type KeyboardEventHandler = (event: KeyboardEvent) => void;

const useKeyboardShortcut = (
  shortcuts: Shortcut[],
  callback: KeyboardEventHandler,
) => {
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      for (const shortcut of shortcuts) {
        const isModifierKeyPressed = shortcut.modifierKey
          ? event.getModifierState(shortcut.modifierKey)
          : true;
        
        if (isModifierKeyPressed && event.key === shortcut.keyboardKey) {
          callback(event);
          return; // Termina il loop dopo il primo match
        }
      }
    };
    
    document.addEventListener('keydown', handleKeyPress);
    
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [shortcuts, callback]);
};

export default useKeyboardShortcut;