import {PropsWithChildren} from 'react';
import {AssetLocationContext} from './AssetLocationContext';
import {AssetDetailsContext} from './AssetDetailsContext';
import {AssetRealEstateContext} from './AssetRealEstateContext';
import {AddAssetFormContext} from './AddAssetFormContext';

export const AddAssetContext = (props: PropsWithChildren) => {
  return (
    <AssetLocationContext>
      <AssetDetailsContext>
        <AssetRealEstateContext>
          <AddAssetFormContext>
            {props.children}
          </AddAssetFormContext>
        </AssetRealEstateContext>
      </AssetDetailsContext>
    </AssetLocationContext>
  );
};