import {capitalize} from "lodash";
import {Button, Input, Modal, Typography} from "antd";
import React, {useCallback, useRef, useState} from "react";
import {useSaveConfigurationMutation} from "../../../redux/api/events";
import {useEventContext} from "../../../context/event/EventContext";
import {useMessageContext} from "../../../context/message/MessageContext";
import {useTranslation} from "react-i18next";

export default function ConfirmChanges() {

  const {refetchEvent, setIsEditing, event} = useEventContext()
  const [save] = useSaveConfigurationMutation()
  const setMessage = useMessageContext();
  const {t} = useTranslation();
  const [isModalTextOpen, setIsModalTextOpen] = useState(false)
  const [isConfirm, setIsConfirm] = useState(false)
  const value = useRef("")
  const handleConfirm = useCallback(async () => {
    if (event?.uuid) {
      setIsConfirm(true)
      try {
        await save({eventUuid: event.uuid, text_note: value.current || null}).unwrap()
        await refetchEvent()
        setMessage({success: capitalize(t('form.editSuccessMessage'))});
      } catch(err) {
        if (!(err && typeof err === 'object' && 'status' in err && err.status === 400)) {
          setMessage({error: capitalize(t('form.editFailMessage'))});
        }
      } finally {
        setIsConfirm(false)
        setIsModalTextOpen(false)
        setIsEditing(false)
      }
    }
  }, [event?.uuid, refetchEvent, save, setIsEditing, setMessage, t])

  return <>
    <Modal
      centered
      open={isModalTextOpen}
      title={capitalize(t('events.confirmChanges'))}
      onCancel={() => {
        value.current = ""
        setIsModalTextOpen(false)
      }}
      okText={capitalize(t('actions.confirm'))}
      okButtonProps={{
        onClick: handleConfirm,
        disabled: isConfirm,
        loading: isConfirm
      }}
    >
      <Typography.Text italic>{capitalize(t('events.confirmChangesDescription'))}:</Typography.Text>
      <Input.TextArea
        autoSize={{minRows: 4}}
        onChange={(e) => {
          value.current = e.target.value
        }}
      />
    </Modal>
    <Button
      disabled={isConfirm}
      loading={isConfirm}
      type="primary"
      onClick={() => setIsModalTextOpen(true)}
    >
      {capitalize(t('actions.confirm'))}
    </Button>
  </>
}