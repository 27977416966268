import {useGetEventTypesNotPaginatedQuery} from "../../../redux/api/eventTypes";
import {useEventsContext} from "../../../context/emergenciesMap/EventsContext";
import {useGetEventsMapNotPaginatedQuery, useLazyGetEventQuery} from "../../../redux/api/events";
import {fromFeToBeEventsMap} from "../../../utils/parser";
import {Marker} from "react-leaflet";
import L from "leaflet";
import {ReactElement, useEffect, useState} from "react";
import ReactDOMServer from "react-dom/server";
import {PHASE} from "../../../types/api/getEventsResult";
import EventPopup from "../EventPopup";
import {useMapLoadingContext} from "../../../context/baseMap/MapLoadingContext";

const baseSrc = process.env.REACT_APP_MEDIA;

export const EventIcon = ({color, src, selected = false}: { color: string, src: string, selected?: boolean }) => {
  return <img
    src={src}
    alt={''}
    style={{
      boxSizing: 'content-box',
      width: '18px',
      height: '18x',
      padding: '5px',
      borderRadius: '50%',
      overflow: 'visible',
      backgroundColor: color,
      border: selected ? '3px solid var(--primary-6, #1890FF)' : '1px solid var(--neutral-5, #D9D9D9)',
    }}
    className={selected ? 'pulse' : ''}
  />;
}

export default function EventMarkers() {

  const {filtersFE, selectedEvent, selectEvent} = useEventsContext()
  const {
    data: events,
    isUninitialized,
    isFetching
  } = useGetEventsMapNotPaginatedQuery({
    ...fromFeToBeEventsMap(filtersFE),
    phase: `${PHASE.LIVE},${PHASE.EDIT}`
  })

  const [getEvent, {data}] = useLazyGetEventQuery()

  const [, setIsLoadingMap] = useMapLoadingContext();
  const {data: eventTypes} = useGetEventTypesNotPaginatedQuery()
  const [popup, setPopup] = useState('')

  useEffect(() => {
    setIsLoadingMap(isUninitialized || isFetching)
  }, [isFetching, isUninitialized, setIsLoadingMap]);

  const markers = events?.reduce((result, event) => [...result,
    ...event.position
      .reduce((allTheMarkers, currentElement, currentElementIdx) => {
        const index = event.position.findIndex(position => (position.lat === currentElement.lat) && (position.lng === currentElement.lng))

        if (index !== currentElementIdx) {
          // a marker with these lat & lng is already present
          return allTheMarkers
        } else {
          const currentEventType = eventTypes?.find(eventType => eventType.uuid === event.event_type)
          const popupKey = `${event.uuid}-popup-${currentElement.lat}-${currentElement.lng}`
          const markerKey = `${event.uuid}-marker-${currentElement.lat}-${currentElement.lng}`
          return [...allTheMarkers,
            <Marker
              eventHandlers={{
                click: async () => {
                  setPopup(popupKey)
                  selectEvent(event.uuid)
                },
                popupclose: () => setPopup('')
              }}
              key={markerKey}
              icon={L.divIcon({
                className: '', // Specify something to get rid of the default class.
                html: ReactDOMServer.renderToString(<EventIcon
                  selected={selectedEvent === event.uuid}
                  color={event.color_code}
                  src={baseSrc + (currentEventType?.icon?.svg || "")}/>)
              })}
              position={currentElement}
            >
              <EventPopup eventUuid={event.uuid} open={popupKey === popup}/>
            </Marker>,
          ]
        }
      }, [] as ReactElement[])

  ], [] as ReactElement[])

  return <>
    {markers}
    {}
  </>
}