import {useEventContext} from "../../context/event/EventContext";
import {CHANGES, PHASE} from "../../types/api/getEventsResult";
import RootLayout from "../../layout/RootLayout";
import {capitalize} from "lodash";
import EventEditSteps from "../events/edit/EventEditSteps";
import ResponsiveContent from "../../layout/ResponsiveContent";
import {useTranslation} from "react-i18next";
import {EditStepsContext} from "../../context/event/EditSteps";
import {useEffect} from "react";

export default function EventCreate() {

  const {event} = useEventContext()
  const {t} = useTranslation()
  const {setPolygonsAndAssetsForTheMap} = useEventContext()
  useEffect(() => {
    if (event?.phase === PHASE.CREATE){
      setPolygonsAndAssetsForTheMap(CHANGES.WITH)
    }
  }, [event?.phase, setPolygonsAndAssetsForTheMap]);

  return (event?.phase === PHASE.CREATE) ?
    <RootLayout>
      <ResponsiveContent
        title={capitalize(t('actions.createItem', {item: t('events.event', {count: 1})}))}
      >
        <EditStepsContext>
          <EventEditSteps/>
        </EditStepsContext>
      </ResponsiveContent>
    </RootLayout>
    :
    null
}