import {Button, List, theme} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import {capitalize} from "lodash";
import {useTranslation} from "react-i18next";
import {useAlertStepsContext} from "../../../context/alertSteps/AlertStepsContext";
import {ASSET_MAP_ACTIONS} from "../../common/map/constants/map";
import {useControlsAction} from "../../../context/baseMap/ControlContext";
import {useMapLoadingContext} from "../../../context/baseMap/MapLoadingContext";
const {useToken} = theme;

export default function AlertGeoshapesList() {

  const {t} = useTranslation()
  const {drawnGeometries, setDrawnGeometries} = useAlertStepsContext()

  const [isLoadingMap, setIsLoadingMap] = useMapLoadingContext();
  const {token} = useToken();

  const {
    action: {
      currentAction,
      actionEvent,
    },
    changeAction,
  } = useControlsAction();


  return <List
    size={'small'}
    dataSource={drawnGeometries}
    renderItem={(item, index) => {

      return (
        <List.Item
          style={{backgroundColor: currentAction === ASSET_MAP_ACTIONS.SELECT_GLOBAL_POLYGON && actionEvent.uuid === item.uuid ? token.colorInfoBg : token.colorBgContainer}}
          key={item.uuid}
          onClick={e => {
            changeAction(ASSET_MAP_ACTIONS.SELECT_GLOBAL_POLYGON, {uuid: currentAction === ASSET_MAP_ACTIONS.SELECT_GLOBAL_POLYGON && actionEvent.uuid === item.uuid ? undefined : item.uuid});
          }}
          actions={[<Button
            disabled={isLoadingMap}
            danger
            type={'text'}
            icon={<DeleteOutlined/>}
            onClick={(e) => {
              e.stopPropagation();
              setDrawnGeometries(prevState => prevState.filter(el => el.uuid !== item.uuid))
            }}
          >
            {capitalize(t('actions.delete'))}
          </Button>,
          ]}
        >
          {item.name}
        </List.Item>)
    }}
  />
}