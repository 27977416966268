import {createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState} from 'react';
import {useGetAllLayersQuery} from '../../redux/api/layers';
import {WMSLayer} from '../../types/internal/Map';
import {WMSLayerOpacityContext} from './WMSLayerOpacityContext';

export type BaseWMSLayerContextType = [
  layers: WMSLayer[],
  currentWMSLayer: string | null,
  setCurrentWMSLayer: Dispatch<SetStateAction<string | null>>,
]

const WMSLayerContext = createContext<BaseWMSLayerContextType>([
    [],
    null,
    () => null,
  ],
);

export const useBaseWMSLayerContext = () => useContext(WMSLayerContext);

export const BaseWMSLayerContext = (props: PropsWithChildren) => {

  const {
    data: layers,
  } = useGetAllLayersQuery();

  const [
    currentWMSLayer,
    setCurrentWMSLayer,
  ] = useState<string | null>(null);

  return (
    <WMSLayerContext.Provider value={[layers || [], currentWMSLayer, setCurrentWMSLayer]}>
      <WMSLayerOpacityContext>
        {props.children}
      </WMSLayerOpacityContext>
    </WMSLayerContext.Provider>
  );
};