import {PropsWithChildren} from 'react';
import {AssetContext} from '../assetDetails/AssetContext';
import {EditAssetFormContext} from './EditAssetFormContext';
import {EditAssetLocationContext} from './EditAssetLocationContext';
import {EditAssetDetailsContext} from './EditAssetDetailsContext';
import {EditAssetRealEstateContext} from './EditAssetRealEstateFormContext';

export const EditAssetContext = (props: PropsWithChildren) => {
  return (
    <AssetContext>
      <EditAssetLocationContext>
        <EditAssetDetailsContext>
          <EditAssetRealEstateContext>
            <EditAssetFormContext>
              {props.children}
            </EditAssetFormContext>
          </EditAssetRealEstateContext>
        </EditAssetDetailsContext>
      </EditAssetLocationContext>
    </AssetContext>
  );
};