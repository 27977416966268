import Icon from '@ant-design/icons';

const RulerSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20px" height="20px"
    viewBox="0 0 20 20" version="1.1"
  >
    <g id="surface1">
      <path
        d="M 0.148438 5.34375 L 5.34375 0.148438 C 5.441406 0.0507812 5.574219 0 5.703125 0 C 5.835938 0 5.964844 0.0507812 6.066406 0.148438 L 19.851562 13.933594 C 19.949219 14.03125 20 14.164062 20 14.296875 C 20 14.425781 19.949219 14.558594 19.851562 14.65625 L 19.847656 14.65625 L 19.851562 14.660156 L 14.65625 19.851562 C 14.558594 19.953125 14.425781 20 14.296875 20 C 14.164062 20 14.03125 19.953125 13.933594 19.851562 L 0.148438 6.066406 C 0.0507812 5.964844 0 5.832031 0 5.703125 C 0 5.574219 0.0507812 5.441406 0.148438 5.34375 Z M 17.140625 12.558594 L 14.785156 14.914062 C 14.601562 15.097656 14.324219 15.117188 14.164062 14.960938 C 14.007812 14.804688 14.027344 14.523438 14.210938 14.339844 L 16.570312 11.984375 L 14.667969 10.082031 L 13.550781 11.195312 C 13.367188 11.378906 13.089844 11.402344 12.929688 11.242188 C 12.773438 11.085938 12.792969 10.808594 12.976562 10.625 L 14.09375 9.507812 L 12.351562 7.765625 L 9.992188 10.121094 C 9.808594 10.304688 9.53125 10.328125 9.375 10.167969 C 9.214844 10.011719 9.238281 9.734375 9.421875 9.550781 L 11.777344 7.191406 L 9.941406 5.355469 L 8.828125 6.472656 C 8.640625 6.65625 8.363281 6.675781 8.207031 6.519531 C 8.046875 6.359375 8.070312 6.082031 8.253906 5.898438 L 9.371094 4.78125 L 7.558594 2.972656 L 5.199219 5.328125 C 5.015625 5.511719 4.738281 5.53125 4.582031 5.375 C 4.421875 5.21875 4.445312 4.941406 4.628906 4.757812 L 6.984375 2.402344 L 5.703125 1.121094 L 1.117188 5.703125 L 14.296875 18.882812 L 18.878906 14.296875 C 16.730469 12.144531 19.292969 14.707031 17.140625 12.558594 Z M 17.140625 12.558594 "
      />
    </g>
  </svg>
);

export const RulerIcon = () => (
  <Icon
    component={RulerSvg}
    className={'custom-icon'}
  />
);