import {EmailTemplate} from '../../../types/api/templateEmails';
import {Col, Form, Row, Space} from 'antd';
import ViewTemplateMail from './ViewTemplateMail';
import PermissionRoute from '../../Authentication/PermissionRoute';
import {isTemplateMailDelete, isTemplateMailEdit} from '../../Authentication/permissions';
import {DeleteTemplate} from './DeleteTemplate';
import EditTemplateMail from './EditTemplateMail';
import React from 'react';

export const TemplateMailActions = ({template}: { template: EmailTemplate }) => {

  const [EditTemplateMailForm] = Form.useForm();

  return (
    <>
      <Row>
        <Col>
          <ViewTemplateMail
            template={template}
            formInstance={EditTemplateMailForm}
          />
        </Col>
        <Col>
          <Space>
            <PermissionRoute isAuthorized={isTemplateMailDelete}>
              <DeleteTemplate template={template}/>
            </PermissionRoute>
            {/*<Button icon={<DownloadOutlined/>}*/}
            {/*        type={'text'}/>*/}
            <PermissionRoute isAuthorized={isTemplateMailEdit}>
              <EditTemplateMail
                template={template}
                formInstance={EditTemplateMailForm}
              />
            </PermissionRoute>
          </Space>
        </Col>
      </Row>
    </>
  );
};