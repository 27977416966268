import {Avatar, Drawer, DrawerProps, Space, Typography} from "antd";
import {Cluster} from "../../../types/api/clusters";
import React from "react";
import EventClusterEdit from "./EventClusterEdit";
import {InfoCircleOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {BaseMapContext} from "../../../context/baseMap/BaseMapContext";

export default function EventClusterDrawer({cluster, drawer}: {
  cluster?: Cluster,
  drawer?: DrawerProps,
  onConfirm?: () => void
}) {

  const {t} = useTranslation()

  return !!cluster ? <Drawer
    width={'100%'}
    title={<Space align={'center'}>
      <Avatar style={{backgroundColor: cluster?.color_code}}/>
      <Typography.Text strong>{cluster?.name}</Typography.Text>
    </Space>}
    destroyOnClose
    {...drawer}
  >
    <Space>
      <InfoCircleOutlined/>
      <Typography.Text>
        {(t('events.editInfo'))}
      </Typography.Text>
    </Space>
    <BaseMapContext>
      <EventClusterEdit cluster={cluster}/>
    </BaseMapContext>
  </Drawer> : null
}