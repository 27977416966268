import {
  Button,
  Col,
  ConfigProvider,
  Descriptions,
  DescriptionsProps,
  Divider,
  Drawer,
  DrawerProps,
  Row,
  Space,
  Tag,
  Typography,
} from 'antd';
import {EVENT_STATES, GetEventsResult} from '../../../types/api/getEventsResult';
import React from 'react';
import EventTypeFromUuid from '../../common/icons/EventTypeFromUuid';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import DateTimeFormat from '../../../locales/DateTimeFormat';
import {Link} from 'react-router-dom';
import {useGetEventQuery} from '../../../redux/api/events';
import {skipToken} from '@reduxjs/toolkit/query';
import {WarningOutlined} from '@ant-design/icons';
import CustomLatitudeNumericFormat from "../../common/customNumeric/CustomLatitudeNumericFormat";
import CustomLongitudeNumericFormat from "../../common/customNumeric/CustomLongitudeNumericFormat";
import {VALUE_NOT_PRESENT} from "../../../utils/translation";
import {GRECIM} from "../../../utils/const";
import {AlertSourcesLinks} from "../creation/EventChangesSummary";
import {AncestorEvent} from "../../event/eventDetails/EventDetailsCard";

export default function EventDetailDrawer({drawer, event}: { drawer?: DrawerProps, event: GetEventsResult }) {

  const {t} = useTranslation();

  const forecastOrHappened = event?.state === EVENT_STATES.Forecast ? capitalize(t('events.forecast')) :
    event?.state === EVENT_STATES.Happened ? capitalize(t('events.happened'))
      : '';

  const {data: ancestorEventData} = useGetEventQuery(event.ancestor_event ? {uuid: event.ancestor_event} : skipToken);

  const itemsFirstSection: DescriptionsProps['items'] = [
    {
      label: capitalize(t('events.involvedAssets')),
      children: event.involved_assets,
    },
    {
      label: capitalize(t('events.forecastOrHappened')),
      children: forecastOrHappened,
    },
    {
      label: capitalize(t('events.lastUpdated')),
      children: <DateTimeFormat>{event.last_update}</DateTimeFormat>,
    },
    {
      label: capitalize(t('events.latLonEpicenter')),
      children: event?.epicenter ? <Space>
        <div>
          <CustomLatitudeNumericFormat
          value={event.epicenter.lat} displayType={"text"}/>,
        </div>
        <CustomLongitudeNumericFormat
          value={event.epicenter.lng} displayType={"text"}/>
      </Space> : VALUE_NOT_PRESENT,
    },
    {
      label: capitalize(t('events.dataSources')),
      span: 2,
      children: <AlertSourcesLinks dataSources={event?.alert_source}/>,
    },
    {
      label: capitalize(t('events.description')),
      children: <div style={{whiteSpace: 'pre-line'}}>{event?.description || VALUE_NOT_PRESENT}</div>,
      span: 2
    },
    ...ancestorEventData ? [{
      label: capitalize(t('events.ancestorEvent')),
      children: <AncestorEvent uuid={event.ancestor_event || ""}/>,
    }] : [],
  ];

  const itemsSecondSection: DescriptionsProps['items'] = [
    {
      label: capitalize(t('events.dateStartForecast')),
      children: event?.forecast_start_datetime ?
        <DateTimeFormat>{event?.forecast_start_datetime}</DateTimeFormat> : VALUE_NOT_PRESENT,
    },
    {
      label: capitalize(t('events.dateStartEvent')),
      children: event?.start_datetime ? <DateTimeFormat>{event?.start_datetime}</DateTimeFormat> : VALUE_NOT_PRESENT,
    },
    {
      label: capitalize(t('assets.assetsClosed')),
      children: VALUE_NOT_PRESENT,
    },
  ];

  return <Drawer
    size={'large'}
    title={<Row justify={'space-between'} wrap={false}>
      <Col>
        <Space direction={'vertical'}>
          <Typography.Title level={3} style={{margin: 0}}>
            {event.name}
          </Typography.Title>
          {!!event?.is_special_management && <Tag
            icon={<WarningOutlined/>}
            color={'error'}
          >
            {capitalize(GRECIM)}
          </Tag>
          }
          <div>
            <Typography.Text type={'secondary'}>
              #{event.code}
            </Typography.Text>
          </div>
        </Space>
      </Col>
      <Col>
        <EventTypeFromUuid
          uuid={event.event_type}
          tag
          tagProps={{
            style: {backgroundColor: event.color_code}
          }}
          iconStyle={{
            backgroundColor: event.color_code
          }}
        />
      </Col>
    </Row>}
    footer={<Row justify={'end'}>
      <Col>
        <Link to={`/events/${event.uuid}`}>
          <Button type={'primary'}>
            {capitalize(t('misc.goToFullDetails'))}
          </Button>
        </Link>
      </Col>
    </Row>}
    {...drawer}
  >
    <ConfigProvider
      theme={{
        components: {
          Descriptions: {
            itemPaddingBottom: 0
          },
        },
      }}
    >
      <Descriptions
        layout={'vertical'}
        bordered={false}
        colon={false}
        items={itemsFirstSection}
        column={2}
        labelStyle={{
          fontWeight: 'bold',
          color: 'rgba(0, 0, 0, 0.88)',
        }}
        contentStyle={{marginBottom: '2rem'}}
      />
      <Divider style={{margin: '1.5rem 0'}}/>
      <Descriptions
        layout={'vertical'}
        bordered={false}
        colon={false}
        items={itemsSecondSection}
        column={2}
        contentStyle={{marginBottom: '2rem'}}
        labelStyle={{
          fontWeight: 'bold',
          color: 'rgba(0, 0, 0, 0.88)',
        }}
      />
    </ConfigProvider>

  </Drawer>;

}