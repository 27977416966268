import {useMap, useMapEvents, WMSTileLayer, WMSTileLayerProps} from 'react-leaflet';
import {useGetUserProfileQuery} from '../../../redux/api/locales';
import {useQuerableLayerContext} from '../../../context/baseMap/QuerableLayerContext';
import {useLazyGetFeatureInfoQuery} from '../../../redux/api/map';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {createRoot} from 'react-dom/client';
import {flushSync} from 'react-dom';
import {GetFeatureInfoProperties} from './GetFeatureInfoProperties';
import {useLazyGetPolygonFromLayerQuery} from '../../../redux/api/layers';
import {useBaseWMSLayerContext} from '../../../context/baseMap/BaseWMSLayerContext';
import {useMapLoadingContext} from '../../../context/baseMap/MapLoadingContext';
import {useMessageContext} from '../../../context/message/MessageContext';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import {useWMSLayerOpacityContext} from '../../../context/baseMap/WMSLayerOpacityContext';
import {useControlsAction} from '../../../context/baseMap/ControlContext';
import {ASSET_MAP_ACTIONS, MAX_ZOOM} from './constants/map';
import {useMapSoftLoadingContext} from '../../../context/baseMap/MapSoftLoadingContext';

export const BaseWMSLayer = (props: { isInternal?: boolean } & WMSTileLayerProps) => {
  
  const {t} = useTranslation();
  
  const map = useMap();
  const [
    queryMode,
    setQueryMode,
  ] = useQuerableLayerContext();
  const [, currentWMSLayer] = useBaseWMSLayerContext();
  const [, setIsMapLoading] = useMapLoadingContext();
  const setMessage = useMessageContext();
  const [opacity] = useWMSLayerOpacityContext();
  const {changeAction} = useControlsAction();
  const [, setSoftMapLoading] = useMapSoftLoadingContext();
  
  const {data: userData} = useGetUserProfileQuery();
  const [getFeatureInfo] = useLazyGetFeatureInfoQuery();
  const [getPolygonFromLayer] = useLazyGetPolygonFromLayerQuery();
  
  const [
    isPopupOpen,
    setIsPopupOpen,
  ] = useState(false);
  
  useEffect(() => {
    if (queryMode === null && isPopupOpen) {
      map.closePopup();
    }
  }, [queryMode, map, isPopupOpen]);
  
  const handleGetFeatureInfo = useCallback(async (e: L.LeafletMouseEvent) => {
    if (props.url && props.layers) {
      const point = map.latLngToContainerPoint(e.latlng)
      const size = map.getSize()

      const res = await getFeatureInfo({
        baseUrl: props.url,
        layers: [props.layers],
        bbox: map.getBounds().toBBoxString(),
        X: Math.round(point.x),
        Y: Math.round(point.y),
        HEIGHT: size.y,
        WIDTH: size.x,
        ...(props.isInternal && userData && userData?.geoserver_key) ? {authkey: userData.geoserver_key} : {},
      });
      const getFeatureInfoProperties = document.createElement('get-feature-info-properties');
      const root = createRoot(getFeatureInfoProperties);
      flushSync(() => {
        root.render(<GetFeatureInfoProperties
          data={res.data}
          success={res.isSuccess}
          error={res.isError}
          loading={res.isLoading}
        />);
      });
      
      const targetPoint = map.project(e.latlng, map.getZoom()).add([0, -(map.getSize().y / 3)]);
      const targetLatLng = map.unproject(targetPoint, map.getZoom());
      map.flyTo(targetLatLng, map.getZoom(), {animate: true, duration: 0.5});
      
      setIsPopupOpen(true);
      map.openPopup(getFeatureInfoProperties, e.latlng, {className: 'get-feature-info-popup'});
    }
  }, [getFeatureInfo, map, props.isInternal, props.layers, props.url, userData]);
  
  const handleGetPolygonFromLayer = useCallback(async (e: L.LeafletMouseEvent) => {
    setIsMapLoading(true);
    if (currentWMSLayer) {
      const res = await getPolygonFromLayer({
        layerUuid: currentWMSLayer,
        lat: `${e.latlng.lat}`,
        lon: `${e.latlng.lng}`,
      });
      if (res.isSuccess) {
        changeAction(ASSET_MAP_ACTIONS.ADD_GEOMETRY_FROM_LAYER_QUERY, {
          geometry: res.data.geoshape.coordinates,
          map: map,
        });
      } else {
        setIsMapLoading(false);
        setMessage({error: capitalize(t('layers.thisActionIsNotSupported'))});
      }
    }
    setQueryMode(null);
  }, [changeAction, currentWMSLayer, getPolygonFromLayer, map, setIsMapLoading, setMessage, setQueryMode, t]);
  
  useMapEvents({
    click(e) {
      if (queryMode === 'GET_FEATURE_INFO' && !isPopupOpen) {
        handleGetFeatureInfo(e);
      }
      if (queryMode === 'GET_POLYGON_FROM_LAYER' && currentWMSLayer) {
        handleGetPolygonFromLayer(e);
      }
    },
    popupclose() {
      setIsPopupOpen(false);
    },
  });
  
  useEffect(() => {
    if (queryMode !== 'GET_FEATURE_INFO')
      setIsPopupOpen(false);
  }, [queryMode]);
  
  const {isInternal, ...otherProps} = props;
  
  const handlers = useMemo(() => (
    {
      loading(e: any) {
        setSoftMapLoading(true);
      },
      load(e: any) {
        setTimeout(() => setSoftMapLoading(false), 600);
      },
    }
  ), [setSoftMapLoading]);
  
  return (
    <WMSTileLayer
      transparent={true}
      crossOrigin={'anonymous'}
      referrerPolicy={'no-referrer'}
      opacity={opacity}
      format={'image/png'}
      zIndex={2}
      maxZoom={MAX_ZOOM}
      updateWhenIdle={true}
      updateWhenZooming={false}
      updateInterval={2400}
      eventHandlers={handlers}
      {...(props.isInternal && userData ? {authkey: userData.geoserver_key} : {})}
      {...otherProps}
    />
  );
};