import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import ResponsiveContent from '../../../layout/ResponsiveContent';
import {capitalize} from 'lodash';
import CreateEventType from '../../../components/settings/events/CreateEventType';
import EventsTypesTable from '../../../components/settings/events/EventsTypesTable';
import PermissionRoute from '../../../components/Authentication/PermissionRoute';
import {isEventTypesCreation, isTemplateMailView} from '../../../components/Authentication/permissions';
import {TemplateMailCard} from '../../../components/settings/templateMail/TemplateMailCard';
import React from 'react';

export default function EventTypesSettings() {

  const {t} = useTranslation();
  const navigate = useNavigate();

  return <ResponsiveContent
    title={t('events.eventType', {count: 2})}
    goBack={() => navigate('/settings')}
    goBackText={capitalize(t('settings.settings'))}
    extra={<PermissionRoute isAuthorized={isEventTypesCreation}>
      <CreateEventType/>
    </PermissionRoute>}
  >
    <div style={{display: 'flex', flexDirection: 'column', gap: '2rem'}}>
      <EventsTypesTable/>
      <PermissionRoute isAuthorized={isTemplateMailView}>
        <TemplateMailCard/>
      </PermissionRoute>
    </div>

  </ResponsiveContent>;
}