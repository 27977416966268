import {Button, Col, ConfigProvider, Drawer, FormInstance, Row, Skeleton, Space, Tag, Typography} from 'antd';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import React, {useState} from 'react';
import {EmailTemplate} from '../../../types/api/templateEmails';
import {CustomTag} from '../../common/ExpandableTagList';
import {useGetAllTagsQuery} from '../../../redux/api/tags';
import SanitizedHtml from "../../common/SanitizedHtml";

export default function ViewTemplateMail({template, formInstance}: {
  template: EmailTemplate,
  formInstance: FormInstance<any>
}) {

  const {t} = useTranslation();

  const [
    isDrawerOpen,
    setIsDrawerOpen,
  ] = useState(false);

  const {
    data: tags,
    isLoading: areTagsLoading,
    isFetching: areTagsFetching,
  } = useGetAllTagsQuery();

  const isLoading = areTagsLoading || areTagsFetching;

  return (
    <>
      <Button
        type={'link'}
        onClick={() => setIsDrawerOpen(true)}
      >
        {capitalize(t('actions.viewItem', {item: t('mailTemplate.template')}))}
      </Button>
      <ConfigProvider
        theme={{
          token: {
            zIndexPopupBase: 100000,
          },
        }}
      >
        <Drawer
          title={null}
          forceRender={true}
          destroyOnClose={true}
          closeIcon={false}
          open={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          size={'large'}
          styles={{footer: {padding: '0 24px 24px 24px'}}}
        >
          {isLoading && <Skeleton active={true}/>}
          {!isLoading && (
            <>
              <Row>
                <Col>
                  <Typography.Title
                    level={3}
                    style={{margin: 0}}
                  >
                    {template.title}
                  </Typography.Title>
                </Col>
              </Row>
              <Row style={{marginTop: '1rem'}}>
                <Col>
                  <Space wrap={true}>
                    <Tag>{template.language === 'it' ? capitalize(t('misc.italian')) : capitalize(t('misc.english'))}</Tag>
                    {tags?.filter(tag => template.tags.map(tag => tag.uuid).includes(tag.uuid))?.map(tag =>
                      <CustomTag
                        content={tag.frontend_name}
                        color={tag.color}
                        key={tag.uuid}
                      />)}
                  </Space>
                </Col>
              </Row>
              <Row style={{marginTop: '2.5rem'}}>
                <Col flex={1}>
                  <SanitizedHtml htmlString={template.text}/>
                </Col>
              </Row>
            </>
          )}
        </Drawer>
      </ConfigProvider>
    </>
  );
}