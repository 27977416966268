import React, {forwardRef} from 'react';
import {Button, ButtonProps, theme} from 'antd';

export type DrawControlsButtonProps = { isActive?: boolean } & ButtonProps

const {useToken} = theme;
const DrawControlsButton = forwardRef(({
                                         isActive = false,
                                         ...buttonProps
                                       }: DrawControlsButtonProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
  
  const {token} = useToken();
  
  return (
    <Button
      ref={ref}
      size={'large'}
      style={isActive ? {
        border: `1px solid ${token.blue}`,
        background: token.blue,
        color: '#fff',
        fill: '#fff',
      } : buttonProps.disabled ? {fill: token.colorTextDisabled} : {}}
      {...buttonProps}
    />
  );
});

export default DrawControlsButton;