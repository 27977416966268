import {Button, ButtonProps, Col, DatePickerProps, Form, FormItemProps, Modal, ModalProps, Row, Typography} from "antd";
import {EditOutlined} from "@ant-design/icons";
import {Dayjs} from "dayjs";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {capitalize} from "lodash";
import AntFormItem from "../../common/AntFormItem";
import {LocalizedDatePicker} from "../../common/LocalizedDatePicker";

const {Text} = Typography;
export default function DateTimeModal({formItemProps, datePickerProps, onFinish, description, modal, modalButton}: {
  onFinish: (date: string) => void,
  modal?: ModalProps,
  modalButton?: ButtonProps,
  description?: string,
  formItemProps?: FormItemProps,
  datePickerProps?: DatePickerProps,
}) {

  const {t} = useTranslation();

  const [
    isModalOpen,
    setIsModalOpen,
  ] = useState(false);

  const [form] = Form.useForm()

  const handleFinish = ({date}: { date?: Dayjs }) => {
    setIsModalOpen(false)
    date && onFinish(date.toISOString())
  }


  return <>
    <Modal
      centered={true}
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      footer={<Row justify={'end'}>
        <Form form={form} onFinish={handleFinish}>
          <Form.Item required>
            <Button type={'primary'} htmlType={'submit'}>
              {capitalize(t('actions.save'))}
            </Button>
          </Form.Item>
        </Form>
      </Row>}
      {...modal}
    >
      {!!description && <Row style={{marginTop: '1rem'}}>
        <Col>
          <Text strong={true}>
            {description}
          </Text>
        </Col>
      </Row>}

      <Form form={form} layout={'vertical'} style={{margin: '1rem 0'}}>
        <AntFormItem
          label={capitalize(t('misc.date'))}
          name={'date'}
          {...formItemProps}
        >
          <LocalizedDatePicker
            style={{minWidth: '10rem'}}
            {...datePickerProps}
          />
        </AntFormItem>
      </Form>
    </Modal>
    <Button
      type={'text'}
      icon={<EditOutlined/>}
      onClick={(e) => {
        modalButton?.onClick && modalButton.onClick(e)
        setIsModalOpen(true)
      }}
      {...modalButton}
    />
  </>
}