import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
import StandardFilter from '../../../../../components/common/filters/StandardFilter';
import {OptionLabelValue} from '../../../../../types/internal/OptionLabelValue';

export const ImportReportsIsResolvedFilter = (props: {
  activeFilters: OptionLabelValue[] | null,
  handleFilterChange: (values: ([] | OptionLabelValue[])) => void
}) => {

  const {t} = useTranslation();

  return <StandardFilter
    selectedFilters={props.activeFilters || undefined}
    filters={[
      {label: capitalize(t('dataImport.isResolved')), value: 'True'},
      {label: capitalize(t('dataImport.isNotResolved')), value: 'False'},
    ]}
    onChange={props.handleFilterChange}
  />;
};