import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import ResponsiveContent from '../../../layout/ResponsiveContent';
import {Col, Row, Space, Typography} from 'antd';
import {capitalize} from 'lodash';
import DeleteAlertButton from '../DeleteAlertButton';
import AlertView from './alertView/AlertView';
import React from 'react';
import ChooseConnectAlertsToEventMode from '../table/ChooseConnectAlertsToEventMode';
import MoveAlertsToBeManagedButton from '../MoveAlertsToBeManagedButton';
import {useAlertContext} from '../../../context/alertDetails/AlertDetails';
import usePreviousPath from "../../../customHooks/usePreviousPath";

export default function ArchivedAlert() {

  const {t} = useTranslation();
  const navigate = useNavigate();
  const [alert] = useAlertContext();

  const {path, label} = usePreviousPath()
  const goBack = () => navigate(path || '/alerts-history')
  const goBackText = label || t('alerts.list')

  return <ResponsiveContent
    goBack={goBack}
    goBackText={goBackText}
    titleHeader={
      <Row justify={'space-between'}>
        <Col>
          <Typography.Title level={3} style={{marginTop: 0}}>
            {capitalize(t('alerts.archivedAlert'))}
          </Typography.Title>
        </Col>
        {alert && (<Col>
          <Space wrap>
            <DeleteAlertButton afterDelete={() => navigate('/alerts')} selectedAlerts={[alert.uuid]}/>
            <ChooseConnectAlertsToEventMode
              evenTypeUuid={alert.event_type || undefined}
              selectedAlerts={[alert.uuid]}
            />
            <MoveAlertsToBeManagedButton selectedAlerts={[alert.uuid]}/>
          </Space>
        </Col>)}
      </Row>}
  >
    <AlertView/>
  </ResponsiveContent>;
}