import {OptionLabelValue} from '../../../../types/internal/OptionLabelValue';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import NEWListWithSearchFilter from '../../../common/filters/NEWListWithSearchFilter';
import {useLazyGetEventsForInfiniteScrollingQuery} from '../../../../redux/api/events';
import {GetEventsParams} from '../../../../types/api/getEventsResult';

export default function EventCodeFilter({activeFilters, handleFilterChange, params}: {
  activeFilters?: OptionLabelValue[],
  handleFilterChange: (values: OptionLabelValue[]) => void,
  params?: GetEventsParams
}) {

  const [
    getEvents,
    {
      data,
      isLoading,
      isFetching,
      isUninitialized,
      originalArgs,
    },
  ] = useLazyGetEventsForInfiniteScrollingQuery();

  const [querySearch, setQuerySearch] = useState<string>('');
  const options: OptionLabelValue[] = useMemo(() => data?.results.map(el => ({
    label: el.code,
    value: el.uuid,
  })) || [], [data?.results]);

  const handleGetNext = useCallback(() => {
    getEvents({
      ...!!querySearch && {search_by_code: querySearch},
      page: (originalArgs?.page || 0) + 1,
      page_size: 15,
      ...params,
    });
  }, [getEvents, originalArgs?.page, params, querySearch]);

  useEffect(() => {
    getEvents({
      ...!!querySearch && {search_by_code: querySearch},
      page: 1,
      page_size: 15,
      ...params,
    });
  }, [getEvents, params, querySearch]);

  return (
    <NEWListWithSearchFilter
      getNext={handleGetNext}
      options={options}
      selectedOptions={activeFilters || []}
      setSelectedOptions={handleFilterChange}
      total={data?.count || 0}
      isLoadingOptions={isLoading || isFetching || isUninitialized}
      querySearch={querySearch}
      setQuerySearch={setQuerySearch}
    />
  );
}