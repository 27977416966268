import {apiSlice} from './baseApi';
import {GenericPaginatedApi, GenericPaginatedParamsApi} from '../../types/api/paginatedApi';
import {AssetModel} from '../../types/internal/AssetModel';

export type DocumentType = {
  uuid: string,
  is_valid: boolean,
  name: string,
  user_creation: string,
  date_creation: string,
  description: string | null,
  start_date: string | null,
  end_date: string | null,
  type: 'T' | 'P'
  url: string,
}

export type FileUploadRequestType = {
  uuid: string,
  url: string,
  fields: {
    'acl': string,
    'Content-Type': string,
    'key': string,
    'x-amz-algorithm': string,
    'x-amz-credential': string,
    'x-amz-date': string,
    'policy': string,
    'x-amz-signature': string
  },
};

export type DocumentsParams = {
  entity?: 'assets' | 'events' | 'event_types' | 'alerts' | 'none',
  is_expired?: boolean,
  type?: 'T' | 'P',
  search_by_name?: string,
  ordering?: string,
}

export type AssetsDocumentsType =
  DocumentType
  & {
  assets: {
    uuid: string,
    address: string,
    admin_level_1: string,
    admin_level_2: string,
    admin_level_3: string,
    sigi_code: string
  }[]
}

export type EventTypesDocumentsType =
  DocumentType
  & { event_types: { uuid: string, event_type_name: string, color_code: string }[] }

export type AlertsDocumentsType = DocumentType & { alerts: { uuid: string, title: string }[] }

const documentsApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAssetDocuments: build.query<GenericPaginatedApi<DocumentType>, {
      uuid: string,
      page: number,
      page_size: number,
    }>({
      query: (args) => ({
        url: `/assets/${args.uuid}/documents/`,
        params: {
          page: args.page,
          page_size: args.page_size,
          type: 'T',
        },
      }),
      providesTags: ['Documents'],
    }),
    getAssetImages: build.query<GenericPaginatedApi<DocumentType>, { uuid: string, page: number, page_size: number }>({
      query: (args) => ({
        url: `/assets/${args.uuid}/documents/`,
        params: {
          page: args.page,
          page_size: args.page_size,
          type: 'P',
        },
      }),
      providesTags: ['AssetsImages'],
    }),
    deleteAssetDocument: build.mutation<AssetModel, { assetUuid: string, documentUuid: string }>({
      query: (args) => ({
        url: `/assets/${args.assetUuid}/remove_document/`,
        method: 'PATCH',
        body: {
          document_uuid: args.documentUuid,
        },
      }),
      invalidatesTags: ['Documents'],
    }),
    deleteAssetImage: build.mutation<AssetModel, { assetUuid: string, documentUuid: string }>({
      query: (args) => ({
        url: `/assets/${args.assetUuid}/remove_document/`,
        method: 'PATCH',
        body: {
          document_uuid: args.documentUuid,
        },
      }),
      invalidatesTags: ['AssetsImages'],
    }),
    requestFileUploadToS3: build.mutation<FileUploadRequestType, {
      name: string,
      description: string,
      start_date?: string | null,
      end_date?: string | null,
      content_type: string,
      type: 'T' | 'P',
      file_extension: string
    }>({
      query: (args) => ({
        url: '/documents/create_upload_s3/',
        method: 'POST',
        body: {
          ...args,
        },
      }),
    }),
    fileUploadToS3: build.mutation<any, { url: string, postData: FormData }>({
      query: ({url, postData}) => ({
        url: url,
        method: 'POST',
        body: postData,
        formData: true,
      }),
    }),
    patchDocumentUploadStatus: build.mutation<DocumentType, {
      documentUuid: string,
      is_valid: boolean,
    }>({
      query: ({documentUuid, ...body}) => ({
        url: `/documents/${documentUuid}/`,
        method: 'PATCH',
        body: {...body},
      }),
    }),
    addAssetDocument: build.mutation<AssetModel, { assetUuid: string, documentUuid: string }>({
      query: (args) => ({
        url: `/assets/${args.assetUuid}/add_document/`,
        method: 'PATCH',
        body: {
          document_uuid: args.documentUuid,
        },
      }),
      invalidatesTags: ['Documents'],
    }),
    addAssetImage: build.mutation<AssetModel, { assetUuid: string, documentUuid: string }>({
      query: (args) => ({
        url: `/assets/${args.assetUuid}/add_document/`,
        method: 'PATCH',
        body: {
          document_uuid: args.documentUuid,
        },
      }),
      invalidatesTags: ['AssetsImages'],
    }),
    patchDocument: build.mutation<DocumentType, {
      documentUuid: string,
      name?: string,
      is_valid?: boolean,
      description?: string,
      start_date?: string | null,
      end_date?: string | null
    }>({
      query: ({documentUuid, ...body}) => ({
        url: `/documents/${documentUuid}/`,
        method: 'PATCH',
        body: {...body},
      }),
      invalidatesTags: ['Documents'],
    }),
    patchImage: build.mutation<DocumentType, {
      documentUuid: string,
      name?: string,
      is_valid?: boolean,
      description?: string,
    }>({
      query: ({documentUuid, ...body}) => ({
        url: `/documents/${documentUuid}/`,
        method: 'PATCH',
        body: {...body},
      }),
      invalidatesTags: (result, error, args) => args.is_valid ? [] : ['AssetsImages'],
    }),
    getUrl: build.query<{ url: string }, {
      documentUuid: string
    }>({
      query: (args) => ({
        url: `/documents/${args.documentUuid}/get_url/`,
      }),
    }),
    getDocuments: build.query<GenericPaginatedApi<DocumentType>, DocumentsParams & GenericPaginatedParamsApi>({
      query: (args) => ({
        url: `/documents/`,
        params: args,
      }),
      providesTags: ['Documents'],
    }),
    getDocumentsForInfiniteScrolling: build.query<GenericPaginatedApi<DocumentType>, DocumentsParams & GenericPaginatedParamsApi>({
      query: (args) => ({
        url: `/documents/`,
        params: args,
      }),
      serializeQueryArgs: ({queryArgs}) => {
        const newQueryArgs = {...queryArgs};
        delete newQueryArgs.page;
        return newQueryArgs;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems, query) => {
        if ((query.arg.page || 0) > 1) {
          return {
            ...currentCache,
            ...newItems,
            results: [...currentCache.results, ...newItems.results],
          };
        }
        return newItems;
      },
    }),
    deleteDocument: build.mutation<void, { documentUuid: string }>({
      query: (args) => ({
        url: `/documents/${args.documentUuid}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Documents', 'AssetsImages'],
    }),
    deleteDocuments: build.mutation<void, { documentUuids: string[] }>({
      query: (args) => ({
        url: `/documents/bulk_delete/`,
        method: 'DELETE',
        params: {
          document_list_uuid: args.documentUuids.join(','),
        },
      }),
      invalidatesTags: ['Documents'],
    }),
    getDocumentsUrl: build.query<{ url: string }[], { documentUuids: string[] }>({
      query: (args) => ({
        url: `/documents/bulk_get_url/`,
        params: {
          document_list_uuid: args.documentUuids.join(','),
        },
      }),
    }),
    getDocumentsByEventType: build.query<GenericPaginatedApi<EventTypesDocumentsType>, DocumentsParams & GenericPaginatedParamsApi & Partial<{
      file_name: string,
      end_date_after: string,
      end_date_before: string,
      is_end_date_null: boolean,
      event_types: string
    }>>({
      query: (args) => ({
        url: `/documents/`,
        params: {
          ...args,
          entity: 'event_types',
          type: 'T',
        },
      }),
      providesTags: ['Documents'],
    }),
    getDocumentsByEvents: build.query<GenericPaginatedApi<DocumentType>, DocumentsParams & GenericPaginatedParamsApi & Partial<{
      file_name: string,
      end_date_after: string,
      end_date_before: string,
      is_end_date_null: boolean
    }>>({
      query: (args) => ({
        url: `/documents/`,
        params: {
          ...args,
          entity: 'events',
          type: 'T',
        },
      }),
      providesTags: ['Documents'],
    }),
    getDocumentsByAssets: build.query<GenericPaginatedApi<AssetsDocumentsType>, DocumentsParams & GenericPaginatedParamsApi & Partial<{
      file_name: string,
      end_date_after: string,
      end_date_before: string,
      is_end_date_null: boolean
    }>>({
      query: (args) => ({
        url: `/documents/`,
        params: {
          ...args,
          entity: 'assets',
          type: 'T',
        },
      }),
      providesTags: ['Documents'],
    }),
    getNotAssociedDocuments: build.query<GenericPaginatedApi<DocumentType>, DocumentsParams & GenericPaginatedParamsApi & Partial<{
      file_name: string,
      end_date_after: string,
      end_date_before: string,
      is_end_date_null: boolean
    }>>({
      query: (args) => ({
        url: `/documents/`,
        params: {
          ...args,
          entity: 'none',
          type: 'T',
        },
      }),
      providesTags: ['Documents'],
    }),
    getAlertsDocuments: build.query<GenericPaginatedApi<AlertsDocumentsType>, DocumentsParams & GenericPaginatedParamsApi & Partial<{
      file_name: string,
      end_date_after: string,
      end_date_before: string,
      is_end_date_null: boolean
    }>>({
      query: (args) => ({
        url: `/documents/`,
        params: {
          ...args,
          entity: 'alerts',
          type: 'T',
        },
      }),
      providesTags: ['Documents'],
    }),
    getEventDocuments: build.query<GenericPaginatedApi<DocumentType>, GenericPaginatedParamsApi & {uuid: string}>({
      query: ({uuid, page, page_size}) => ({
        url: `/events/${uuid}/documents/`,
        params: {
          page,
          page_size,
          type: 'T',
        },
      }),
      providesTags: ['Documents'],
    }),
    addEventDocument: build.mutation<DocumentType, { eventUuid: string, documentUuid: string }>({
      query: (args) => ({
        url: `/events/${args.eventUuid}/add_document/`,
        method: 'PATCH',
        body: {
          document_uuid: args.documentUuid,
        },
      }),
      invalidatesTags: ['Documents'],
    }),
  }),
});

export const {
  useLazyGetAssetDocumentsQuery,
  useLazyGetAssetImagesQuery,
  useDeleteAssetDocumentMutation,
  useRequestFileUploadToS3Mutation,
  useFileUploadToS3Mutation,
  useAddAssetDocumentMutation,
  useAddAssetImageMutation,
  usePatchDocumentMutation,
  usePatchImageMutation,
  useLazyGetUrlQuery,
  useLazyGetDocumentsQuery,
  useLazyGetDocumentsForInfiniteScrollingQuery,
  useDeleteDocumentMutation,
  useDeleteDocumentsMutation,
  useLazyGetDocumentsUrlQuery,
  useLazyGetDocumentsByAssetsQuery,
  useLazyGetDocumentsByEventTypeQuery,
  useLazyGetDocumentsByEventsQuery,
  useLazyGetNotAssociedDocumentsQuery,
  usePatchDocumentUploadStatusMutation,
  useLazyGetAlertsDocumentsQuery,
  useLazyGetEventDocumentsQuery,
  useGetEventDocumentsQuery,
  useAddEventDocumentMutation
} = documentsApi;