import {Card} from 'antd';
import {capitalize} from 'lodash';
import {ImageList} from '../images/ImagesList';
import {useTranslation} from 'react-i18next';
import {UploadImageModal} from '../../common/documents/modals/UploadImageModal';
import {useAddAssetImageMutation} from '../../../redux/api/documents';
import {useParams} from 'react-router-dom';
import PermissionRoute from '../../Authentication/PermissionRoute';
import {isAssetImageCreation, isAssetImageView} from '../../Authentication/permissions';

export const ImagesListCard = () => {

  const {t} = useTranslation();
  const {assetId} = useParams();

  const [
    addAssetImage,
  ] = useAddAssetImageMutation();

  return (
    <Card
      title={capitalize(t('documents.image', {count: 1}))}
      extra={(
        <PermissionRoute isAuthorized={isAssetImageCreation}>
          <UploadImageModal
            onAfterUpload={(uploadedDocumentUuid => {
              if (assetId) {
                addAssetImage({assetUuid: assetId, documentUuid: uploadedDocumentUuid});
              }
            })}
          />
        </PermissionRoute>
      )}
    >
      <PermissionRoute isAuthorized={isAssetImageView}>
        <ImageList/>
      </PermissionRoute>
    </Card>
  );
};