export const HazardLevelBadge = (props: {
  color: string,
}) => {
  return (
    <div
      style={
        {
          width: 16,
          height: 16,
          borderRadius: '50%',
          backgroundColor: props.color,
        }
      }
    />
  );
};