import MarkerClusterGroup from 'react-leaflet-cluster';
import L from 'leaflet';
import {PropsWithChildren, useCallback, useMemo} from 'react';
import {useMapLoadingContext} from '../../../context/baseMap/MapLoadingContext';
import {getContrastColor} from "../../../utils/colors";


export const CustomMarkerClusterGroup = (props: PropsWithChildren & { color?: string }) => {

  const [, setIsMapLoading] = useMapLoadingContext();

  const POLYGON_OPTIONS = useMemo(() => ({
    fillColor: '#ffffff',
    color: props.color || '#f00800',
    weight: 3,
    opacity: 1,
    fillOpacity: 0.8,
  }), [props.color]);

  const createIcon = useCallback((cluster: { getChildCount: () => number; }) => {
    const markerHtmlStyles = `
    background-color: ${props.color || "#E84C3D"};
    color: ${props.color ? getContrastColor(props.color) : "#FFFFFF"};
    padding: 8px 15px;
    border-radius: 20px;
    font-weight: 700;
  `
    return L.divIcon({
      html: `<div style="${markerHtmlStyles}" ><div>${cluster.getChildCount()}</div></div>`,
      iconAnchor: [0, 0],
      popupAnchor: [0, 0],
      className: 'myCluster'
    })
  }, [props.color])

  return (<MarkerClusterGroup
    children={props.children}
    chunkedLoading={true}
    chunkInterval={20}
    chunkDelay={20}
    chunkProgress={(processedMarkers: number, totalMarkers: number) => {
      setIsMapLoading(isMapLoading => isMapLoading && processedMarkers === totalMarkers);
    }}
    spiderfyOnMaxZoom={true}
    maxClusterRadius={60}
    showCoverageOnHover={true}
    iconCreateFunction={createIcon}
    polygonOptions={POLYGON_OPTIONS}
  />);
};