import {DownloadOutlined} from '@ant-design/icons';
import {fromFeToBeAssetsFilters} from '../../../types/internal/tables/assetsTable';
import {fromFeToBeSorter} from '../../../utils/parser';
import {capitalize} from 'lodash';
import {Button, Checkbox, CheckboxProps, Modal, Tabs, Typography} from 'antd';
import React, {useMemo, useState} from 'react';
import {
  useLazyDownloadAssetsReportQuery,
  useLazyDownloadBiodiversityReportQuery,
  useLazyDownloadHazardsReportQuery,
} from '../../../redux/api/assets';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {
  selectAssets,
  selectAssetsTableColumns,
  selectAssetsTableFilters,
  selectAssetsTableSorters,
} from '../../../redux/slices/assets';
import {useMessageContext} from '../../../context/message/MessageContext';
import {selectMapFilters} from '../../../redux/slices/mapFilters';

export default function DownloadAssetsAndHazardsReport() {
  
  const {t} = useTranslation();
  const setAntdMessage = useMessageContext();
  const filters = useSelector(selectAssetsTableFilters);
  const sorters = useSelector(selectAssetsTableSorters);
  const columns = useSelector(selectAssetsTableColumns);
  const [assetsCheckedList, setAssetsCheckedList] = useState<CheckboxProps['value'][]>(['sorters', 'filters']);
  const [hazardsCheckedList, setHazardsCheckedList] = useState<CheckboxProps['value'][]>(['filters']);
  const [biodiversityCheckedList, setBiodiversityCheckedList] = useState<CheckboxProps['value'][]>(['sorters', 'filters']);
  
  const [downloadAssetsReport, {
    isFetching: isFetchingDownloadAssetsReport,
  }] = useLazyDownloadAssetsReportQuery();
  
  const [downloadBiodiversityReport, {
    isFetching: isFetchingDownloadBiodiversityReport,
  }] = useLazyDownloadBiodiversityReportQuery();
  
  const [downloadHazardsReport, {
    isFetching: isFetchingDownloadHazardsReport,
  }] = useLazyDownloadHazardsReportQuery();
  
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeKey, setActiveKey] = useState<'assets' | 'hazards' | 'biodiversity'>('assets');
  const polygonFilter = useSelector(selectMapFilters).polygons;
  
  const isAssetsFilters = assetsCheckedList.includes('filters');
  const isAssetsSorters = assetsCheckedList.includes('sorters');
  const isAssestsAllFields = assetsCheckedList.includes('allFields');
  
  const isHazardsFilters = hazardsCheckedList.includes('filters');
  const isHazardsAllFields = hazardsCheckedList.includes('allFields');
  
  const isBiodiversityFilters = biodiversityCheckedList.includes('filters');
  const isBiodiversitySorters = biodiversityCheckedList.includes('sorters');
  const isBiodiversityAllFields = biodiversityCheckedList.includes('allFields');
  
  const assetsOptions = [
    {
      label: <><Typography.Text
        style={{fontWeight: 'bold'}}
      >{capitalize(t('table.downloadSorters'))}</Typography.Text><br/>{capitalize(t('table.downloadSortersDescription'))}</>,
      value: 'sorters',
    },
    {
      label: <><Typography.Text
        style={{fontWeight: 'bold'}}
      >{capitalize(t('table.downloadFilters'))}</Typography.Text><br/>{capitalize(t('table.downloadFiltersDescription'))}</>,
      value: 'filters',
    },
    {
      label: <><Typography.Text
        style={{fontWeight: 'bold'}}
      >{capitalize(t('table.downloadAllFields'))}</Typography.Text><br/>{capitalize(t('table.downloadAllFieldsDescription'))}</>,
      value: 'allFields',
    },
  ];
  
  const hazardsOptions = [
    {
      label: <><Typography.Text
        style={{fontWeight: 'bold'}}
      >{capitalize(t('table.downloadFilters'))}</Typography.Text><br/>{capitalize(t('table.downloadFiltersDescription'))}</>,
      value: 'filters',
    },
    {
      label: <><Typography.Text
        style={{fontWeight: 'bold'}}
      >{capitalize(t('table.downloadAllFields'))}</Typography.Text><br/>{capitalize(t('table.downloadAllFieldsDescription'))}</>,
      value: 'allFields',
    },
  ];
  
  const okButtonProps = useMemo(() => {
    return activeKey === 'assets' ? {
        disabled: isFetchingDownloadAssetsReport,
        loading: isFetchingDownloadAssetsReport,
      } :
      activeKey === 'hazards' ?
        {
          disabled: isFetchingDownloadHazardsReport,
          loading: isFetchingDownloadHazardsReport,
        } :
        {
          disabled: isFetchingDownloadBiodiversityReport,
          loading: isFetchingDownloadBiodiversityReport,
        };
  }, [activeKey, isFetchingDownloadAssetsReport, isFetchingDownloadBiodiversityReport, isFetchingDownloadHazardsReport]);
  
  const excludeFilters = useSelector(selectAssets).assetsTable.filtersInExcludeMode;
  const handleDownloadXlsx = async () => {
    try {
      switch (activeKey) {
        case 'assets':
          await downloadAssetsReport({
            ...isAssetsFilters && {
              filters:
                {
                  ...fromFeToBeAssetsFilters(filters, excludeFilters),
                  ...!!polygonFilter?.length && {polygon_uuid: polygonFilter.map(polygon => polygon.globalpolygon).join(',')},
                },
            },
            ...isAssetsSorters && {sorters: fromFeToBeSorter(sorters)},
            ...!isAssestsAllFields && !!columns.length && {columns: columns.filter(col => col !== 'map_view').join()},
          }).unwrap();
          break;
        case 'hazards':
          await downloadHazardsReport({
            ...isHazardsFilters && {
              filters:
                {
                  ...fromFeToBeAssetsFilters(filters, excludeFilters),
                  ...!!polygonFilter?.length && {polygon_uuid: polygonFilter.map(polygon => polygon.globalpolygon).join(',')},
                },
            },
            ...!isHazardsAllFields && !!columns.length && {columns: columns.filter(col => col !== 'map_view').join()},
          }).unwrap();
          break;
        case 'biodiversity':
          await downloadBiodiversityReport({
            ...isBiodiversityFilters && {
              filters:
                {
                  ...fromFeToBeAssetsFilters(filters, excludeFilters),
                  ...!!polygonFilter?.length && {polygon_uuid: polygonFilter.map(polygon => polygon.globalpolygon).join(',')},
                },
            },
            ...isBiodiversitySorters && {sorters: fromFeToBeSorter(sorters)},
            ...!isBiodiversityAllFields && !!columns.length && {columns: columns.filter(col => col !== 'map_view').join()},
          }).unwrap();
          break;
      }
      setAntdMessage({
        success: capitalize(t('documents.downloadSuccess')),
      });
    } catch {
      setAntdMessage({
        error: capitalize(t('documents.downloadError')),
      });
    } finally {
      closeModal();
    }
    
  };
  
  const closeModal = () => setIsModalVisible(false);
  const openModal = () => setIsModalVisible(true);
  
  const items = [
    {
      label: capitalize(t('assets.asset', {count: 2})),
      key: 'assets',
      children: <>
        <Typography.Paragraph>{capitalize(t('table.downloadText'))}</Typography.Paragraph>
        <Checkbox.Group
          style={{marginTop: '1rem', display: ' flex', gap: '1rem', flexDirection: 'column'}}
          options={assetsOptions}
          value={assetsCheckedList}
          onChange={values => setAssetsCheckedList(values)}
        />
      </>,
    },
    {
      label: capitalize(t('hazards.downloadReport')),
      key: 'hazards',
      children: <>
        <Typography.Paragraph>{capitalize(t('table.downloadText'))}</Typography.Paragraph>
        <Checkbox.Group
          style={{marginTop: '1rem', display: ' flex', gap: '1rem', flexDirection: 'column'}}
          options={hazardsOptions}
          value={hazardsCheckedList}
          onChange={values => setHazardsCheckedList(values)}
        />
      </>,
    },
    {
      label: capitalize(t('assets.biodiversityDownloadReport')),
      key: 'biodiversity',
      children: <>
        <Typography.Paragraph>{capitalize(t('table.downloadText'))}</Typography.Paragraph>
        <Checkbox.Group
          style={{marginTop: '1rem', display: ' flex', gap: '1rem', flexDirection: 'column'}}
          options={assetsOptions}
          value={biodiversityCheckedList}
          onChange={values => setBiodiversityCheckedList(values)}
        />
      </>,
    },
  ];
  
  return <>
    <Modal
      title={capitalize(t('misc.downloadReport'))}
      open={isModalVisible}
      onCancel={closeModal}
      onOk={handleDownloadXlsx}
      okButtonProps={okButtonProps}
    >
      <Tabs
        items={items}
        activeKey={activeKey}
        onChange={(item) => setActiveKey(item as 'assets' | 'hazards' | 'biodiversity')}
      />
    
    </Modal>
    <Button
      type={'text'}
      icon={<DownloadOutlined/>}
      onClick={openModal}
    >
      {capitalize(t('misc.downloadReport'))}
    </Button>
  </>;
}