import {Card, Col, Divider, Input, Radio, Row, Skeleton, Typography} from 'antd';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import {GeocodingSearchSelect} from '../common/searchSelect/GeocodingSearchSelect';
import {ReverseGeocodingSearchSelect} from '../common/searchSelect/ReverseGeocodingSearchSelect';
import {useEffect, useState} from 'react';
import {useEditFormContext} from '../../context/editAsset/EditAssetFormContext';
import {useEditLocationContext} from '../../context/editAsset/EditAssetLocationContext';
import {EditAssetPositionMarker} from './editPositionMap/EditAssetPositionMarker';
import {BaseMapContainer} from '../common/map/BaseMapContainer';
import {GoogleLayers} from '../../types/internal/Map';
import L from 'leaflet';
import Label from '../common/Label';
import {CountriesSearchSelect} from '../common/searchSelect/CountriesSearchSelect';
import {useParams} from 'react-router-dom';
import {useAssetDetails} from '../../context/assetDetails/AssetDetails';

const {Title} = Typography;

enum SearchAddressType {
  Address,
  Coordinates,
}

export const EditAssetPositionFormCard = () => {

  const {t} = useTranslation();
  const {assetId} = useParams();

  const [setAssetUuid] = useAssetDetails();
  const {isUserCreated, isLoading} = useEditFormContext();
  const [location, setLocation] = useEditLocationContext();

  const [selectedGeocodingType, setSelectedGeocodingType] = useState(SearchAddressType.Address);

  useEffect(() => {
    if (assetId) {
      setAssetUuid(assetId);
    }
  }, [assetId, setAssetUuid]);

  return (
    <Card title={capitalize(t('assets.buildingLocation'))}>
      {isLoading && <Skeleton active={true}/>}
      {!isLoading && location && (<>
        <Row>
          <Col>
            <Radio.Group
              onChange={e => setSelectedGeocodingType(e.target.value)}
              disabled={!isUserCreated}
              value={selectedGeocodingType}
              style={{marginBottom: 8}}
            >
              <Radio.Button
                value={SearchAddressType.Address}
                id={'geocoding'}
              >
                {capitalize(t('address.address'))}
              </Radio.Button>
              <Radio.Button
                value={SearchAddressType.Coordinates}
                id={'reverseGeocoding'}
              >
                {capitalize(t('address.coordinates'))}
              </Radio.Button>
            </Radio.Group>
          </Col>
        </Row>
        <Row>
          <Col flex={1}>
            {selectedGeocodingType === SearchAddressType.Address && (
              <GeocodingSearchSelect
                disabled={!isUserCreated}
                onSelect={(value, option) => {
                  const localityName = (option.address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('locality'))?.long_name).toUpperCase() || '';
                  const municipalityName = (option.address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('administrative_area_level_3'))?.long_name).toUpperCase() || '';
                  setLocation(patch => (
                    {
                      ...patch,
                      country: option.address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('country'))?.short_name || null,
                      admin_level_1: option.address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('administrative_area_level_1'))?.long_name || null,
                      admin_level_2: option.address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('administrative_area_level_2'))?.short_name || null,
                      admin_level_3: option.address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('administrative_area_level_3'))?.long_name || null,
                      locality: localityName !== municipalityName ? option.address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('locality'))?.long_name : null,
                      address: [
                        option.address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('route'))?.long_name || null,
                        option.address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('street_number'))?.long_name || null,
                      ].filter(item => item !== null).join(', ') || null,
                      postal_code: option.address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('postal_code'))?.long_name || null,
                      // @ts-ignore wrong type delared from @types/google.maps
                      position: option.geometry?.location ? [option.geometry.location.lat as number, option.geometry.location.lng as number] : null,
                    }
                  ));
                }}
              />
            )}
            {selectedGeocodingType === SearchAddressType.Coordinates && (
              <ReverseGeocodingSearchSelect
                disabled={!isUserCreated}
                onSelect={(value, option) => {
                  const localityName = (option.address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('locality'))?.long_name).toUpperCase() || '';
                  const municipalityName = (option.address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('administrative_area_level_3'))?.long_name).toUpperCase() || '';
                  setLocation(patch => (
                    {
                      ...patch,
                      country: option.address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('country'))?.short_name || null,
                      admin_level_1: option.address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('administrative_area_level_1'))?.long_name || null,
                      admin_level_2: option.address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('administrative_area_level_2'))?.short_name || null,
                      admin_level_3: option.address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('administrative_area_level_3'))?.long_name || null,
                      locality: localityName !== municipalityName ? option.address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('locality'))?.long_name : null,
                      address: [
                        option.address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('route'))?.long_name || null,
                        option.address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('street_number'))?.long_name || null,
                      ].filter(item => item !== null).join(', ') || null,
                      postal_code: option.address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('postal_code'))?.long_name || null,
                      // @ts-ignore wrong type delared from @types/google.maps
                      position: option.geometry?.location ? [option.geometry.location.lat as number, option.geometry.location.lng as number] : null,
                    }
                  ));
                }}
              />
            )}
          </Col>
        </Row>
        <Divider/>
        <Row gutter={[40, 0]}>
          <Col xs={24} lg={12}>
            <CountriesSearchSelect
              value={location.country}
              onClear={() => setLocation(patch => ({...patch, country: null}))}
              onSelect={(value, option) => setLocation(patch => ({
                ...patch,
                country: option.value,
              }))}
              disabled={!isUserCreated}
            />
          </Col>
          <Col xs={24} lg={12}>
            <Label label={capitalize(t('address.region'))} required={true} htmlFor={'admin_level_1'}/>
            <Input
              placeholder={capitalize(t('address.region'))}
              disabled={!isUserCreated}
              style={{marginBottom: 20}}
              id={'admin_level_1'}
              value={location.admin_level_1 || ''}
              onChange={e => setLocation(patch => ({
                ...patch,
                admin_level_1: e.target.value || null,
              }))}
            />
          </Col>
        </Row>
        <Row gutter={[40, 0]}>
          <Col xs={24} lg={12}>
            <Label
              label={capitalize(t('address.municipality'))} required={true}
              htmlFor={'admin_level_3'}
            />
            <Input
              placeholder={capitalize(t('address.municipality'))}
              disabled={!isUserCreated}
              style={{marginBottom: 20}}
              id={'admin_level_3'}
              value={location.admin_level_3 || ''}
              onChange={e => setLocation(patch => ({
                ...patch,
                admin_level_3: e.target.value || null,
              }))}
            />
          </Col>
          <Col xs={24} lg={12}>
            <Label label={capitalize(t('address.province'))} required={true} htmlFor={'admin_level_2'}/>
            <Input
              placeholder={capitalize(t('address.province'))}
              disabled={!isUserCreated}
              style={{marginBottom: 20}}
              id={'admin_level_2'}
              value={location.admin_level_2 || ''}
              onChange={e => setLocation(patch => ({
                ...patch,
                admin_level_2: e.target.value || null,
              }))}
            />
          </Col>
        </Row>
        <Row gutter={[40, 0]}>
          {location.locality !== null && <Col xs={24} lg={12}>
            <Label label={capitalize(t('address.locality'))} htmlFor={'locality'}/>
            <Input
              placeholder={capitalize(t('address.locality'))}
              disabled={!isUserCreated}
              style={{marginBottom: 20}}
              id={'locality'}
              autoComplete={'off'}
              value={location.locality || ''}
              onChange={e => setLocation(patch => ({
                ...patch,
                locality: e.target.value, /*|| null*/
              }))}
            />
          </Col>}
          <Col xs={24} lg={12}>
            <Label label={capitalize(t('address.address'))} required={true} htmlFor={'address'}/>
            <Input
              placeholder={capitalize(t('address.address'))}
              disabled={!isUserCreated}
              style={{marginBottom: 20}}
              id={'address'}
              autoComplete={'off'}
              value={location.address || ''}
              onChange={e => setLocation(patch => ({...patch, address: e.target.value || null}))}
            />
          </Col>
        </Row>
        <Row gutter={[40, 0]}>
          <Col xs={24} lg={12}>
            <Label label={capitalize(t('address.postalCode'))} required={true} htmlFor={'postal_code'}/>
            <Input
              placeholder={capitalize(t('address.postalCode'))}
              disabled={!isUserCreated}
              autoComplete={'off'}
              style={{marginBottom: 20}}
              id={'postal_code'}
              value={location.postal_code || ''}
              onChange={e => setLocation(patch => ({
                ...patch,
                postal_code: e.target.value || null,
              }))}
            />
          </Col>
        </Row>
      </>)}
      <Row>
        <Col flex={1}>
          <Title
            level={5}
            style={{fontWeight: 'lighter', padding: '0 0.5rem'}}
          >{t('misc.positionPreview').toUpperCase()}
          </Title>
          <BaseMapContainer
            withZoom={true}
            withInitialZoom={16}
            withGeoSearch={true}
            withLayerPicker={true}
            withBaseLayers={[GoogleLayers.roadmap, GoogleLayers.hybrid]}
            withCenter={location.position ? new L.LatLng(location.position[0], location.position[1]) : undefined}
            withStyle={{width: '100%', height: '360px'}}
          >
            <EditAssetPositionMarker/>
          </BaseMapContainer>
        </Col>
      </Row>
    </Card>
  );
};