import React, {PropsWithChildren} from 'react';
import {Button, Card, Col, Row, Space, Tag, Typography} from 'antd';
import {capitalize} from 'lodash';
import {Trans, useTranslation} from 'react-i18next';
import {EditOutlined, WarningOutlined} from '@ant-design/icons';
import {useEventContext} from '../../context/event/EventContext';
import {dateToLocaleString} from '../../utils/dateParsers';
import {useGetUserProfileQuery} from '../../redux/api/locales';
import PermissionRoute from "../Authentication/PermissionRoute";
import {isEventEdit} from "../Authentication/permissions";
import EventAssetsDownloadButton from "../event/EventAssetsDownloadButton";
import {GRECIM} from "../../utils/const";
import PhaseTag from "../event/PhaseTag";
import {EVENT_STATES, PHASE} from "../../types/api/getEventsResult";
import DateFormat from "../../locales/DateFormat";
import UnarchiveEvent from "../event/eventStatus/UnarchiveEvent";

const {Text, Title} = Typography;
export const EventSummaryCard = (props: PropsWithChildren) => {

  const {t} = useTranslation();
  const {event, setIsEditing} = useEventContext();

  const {data: userData} = useGetUserProfileQuery();
  const isGrecim = !!event?.is_special_management
  const isArchived = event?.state === EVENT_STATES.Closed || event?.state === EVENT_STATES["Not Happened"]
  const isNotLive = event?.phase !== PHASE.LIVE
  const areTags = isGrecim || isArchived || isNotLive

  return <Card style={{width: '100%', backgroundColor: '#E6F4FF'}}>
    {/*false && <Skeleton active={true}/>*/}
    {(<>
        <Space direction={'vertical'} size={'large'} align={'center'} style={{width: '100%'}}>
          <div>
            <Row>
              <Col>
                <Title level={5} style={{marginTop: 0}}>
                  {event?.name}
                </Title>
              </Col>
            </Row>
            {event?.code && <Row justify={'center'}>
              <Col>
                <Text>
                  #{event?.code}
                </Text>
              </Col>
            </Row>}
          </div>
          {areTags && <div style={{display: 'flex', flexWrap: 'wrap', gap: '0.5rem', justifyContent: 'space-between'}}>
            {isGrecim && <Tag
              style={{flexGrow: 1, textAlign: 'center'}}
              icon={<WarningOutlined/>}
              color={'error'}
            >
              {capitalize(GRECIM)}
            </Tag>}
            {isNotLive && <PhaseTag phase={event?.phase}/>}
          </div>}

          {event?.state === EVENT_STATES.Forecast && !!event?.forecast_start_datetime && userData?.language && (
            <Row>
              <Col>
                <Trans
                  i18nKey={'emergency.forseenFor'}
                  style={{fontSize: 12}}
                  values={{dateTime: dateToLocaleString(event?.forecast_start_datetime, userData.language, 'HH:mm')}}
                  components={{strong: <Text strong={true} style={{fontSize: 12}}/>}}
                />
              </Col>
            </Row>
          )}
          {event?.state === EVENT_STATES.Happened && !!event?.start_datetime && userData?.language && (
            <Row>
              <Col>
                <Trans
                  i18nKey={'emergency.occurredFor'}
                  style={{fontSize: 12}}
                  values={{dateTime: dateToLocaleString(event?.start_datetime, userData.language, 'HH:mm')}}
                  components={{strong: <Text strong={true} style={{fontSize: 12}}/>}}
                />
              </Col>
            </Row>
          )}
          {isArchived && <div style={{flexGrow: 1}}>
            <Typography.Text style={{fontSize: 12}} strong>{capitalize(t('events.archived'))}: </Typography.Text>
            <DateFormat>{event?.end_datetime}</DateFormat>
          </div>}
        </Space>
        <Space
          direction={'vertical'}
          size={'small'}
          style={{display: 'flex', marginTop: '1rem'}}
        >
          {!!event && (
            <><Space direction={'vertical'} style={{width: '100%'}} size={12}>
              {isArchived && <UnarchiveEvent />}
              {!isArchived && <PermissionRoute isAuthorized={isEventEdit}>
                <Button type={"primary"}
                        style={{width: "100%"}}
                        icon={<EditOutlined/>}
                        onClick={() => setIsEditing(true)}>
                  {capitalize(t("form.edit"))}
                </Button>
              </PermissionRoute>}
              <EventAssetsDownloadButton/>
            </Space>
            </>
          )}
        </Space>
      </>
    )}
  </Card>;
};