import {capitalize} from "lodash";
import {Form, Input} from "antd";
import {useTranslation} from "react-i18next";

export default function CommunicationSubject() {

  const {t} = useTranslation()

  return <Form.Item
            label={capitalize(t('events.communicationSubject'))}
            required={true}
            rules={[{required: true}]}
            name={'subject'}
          >
            <Input/>
          </Form.Item>
}