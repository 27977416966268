import {BaseMapContext} from '../../../context/baseMap/BaseMapContext';
import {BaseMapProps} from '../../../types/internal/Map';
import {BaseMap} from './BaseMap';

export const BaseMapContainer = (props: BaseMapProps) => {
  return (
    <>
      {props.isStandAlone === true ? (
        <BaseMap {...props}/>
      ) : (
        <BaseMapContext>
          <BaseMap {...props}/>
        </BaseMapContext>
      )}
    </>
  );
};