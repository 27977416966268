import {DummyMapContainer} from '../DummyMapContainer';
import {useBaseTileLayerContext} from '../../../../context/baseMap/BaseTileLayerContext';
import {Button, Col, Divider, List, Popover, PopoverProps, Row, Slider, Space, Tooltip, Typography} from 'antd';
import {GoogleLayers, WMSLayer} from '../../../../types/internal/Map';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
import {useBaseWMSLayerContext} from '../../../../context/baseMap/BaseWMSLayerContext';
import {QuestionCircleOutlined, SettingOutlined} from '@ant-design/icons';
import {Link} from 'react-router-dom';
import {useWMSLayerOpacityContext} from '../../../../context/baseMap/WMSLayerOpacityContext';
import {useCallback, useRef} from 'react';
import {useQuerableLayerContext} from '../../../../context/baseMap/QuerableLayerContext';
import {DummyWMSLayer} from '../DummyWMSLayer';
import {CustomLoading} from '../../CustomLoading';
import {useMapSoftLoadingContext} from '../../../../context/baseMap/MapSoftLoadingContext';

const layersLabel = {
  roadmap: 'map.roadmap',
  satellite: 'map.satellite',
  terrain: 'map.terrain',
  hybrid: 'map.hybrid',
};

const {Text, Title} = Typography;
export const BaseLayerPicker = (props: {
  availableBaseTileLayers: GoogleLayers[],
  availableWMSLayers?: WMSLayer[],
  withPopoverConfiguration?: PopoverProps,
  withLayerLoading?: boolean
}) => {
  
  const {t} = useTranslation();
  
  const pickerBoxRef = useRef(null);
  
  const [
    currentTileLayer,
    setCurrentTileLayer,
  ] = useBaseTileLayerContext();
  const [,
    currentWMSLayer,
    setCurrentWMSLayer,
  ] = useBaseWMSLayerContext();
  const [
    opacity,
    setOpacity,
  ] = useWMSLayerOpacityContext();
  
  const [isLoadingLayer] = useMapSoftLoadingContext();
  
  const [, setQueryMode] = useQuerableLayerContext();
  
  const renderBaseLayer = useCallback((layer: GoogleLayers) => {
    return (
      <List.Item
        style={{cursor: 'pointer'}}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setCurrentTileLayer(layer);
        }}
      >
        <div>
          <DummyMapContainer
            isHighlighted={layer === currentTileLayer}
            key={`${layersLabel[layer]}_${layer === currentTileLayer ? 'highlighted' : 'not_highlighted'}`}
            withStyle={{width: 64, height: 64}}
            tileLayer={layer}
          />
        </div>
        <Text
          style={{maxWidth: 64}}
          ellipsis={{tooltip: `${capitalize(t(layersLabel[layer]))}`}}
        >
          {capitalize(t(layersLabel[layer]))}
        </Text>
      </List.Item>
    );
  }, [currentTileLayer, setCurrentTileLayer, t]);
  
  const renderWMSLayer = useCallback((layer: WMSLayer) => {
    return (
      <Row
        gutter={[0, 16]}
        style={{
          backgroundColor: layer.uuid === currentWMSLayer ? '#E6F4FF' : 'transparent',
          borderRadius: 8,
          padding: '0.5rem 0.5rem',
          margin: 0,
          cursor: 'pointer',
        }}
        align={'middle'}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setCurrentWMSLayer((prevLayer) => {
            if (prevLayer === layer.uuid) {
              setQueryMode(null);
              return null;
            } else return layer.uuid;
          });
        }}
      >
        <Col span={6}>
          <div>
            <DummyMapContainer
              isHighlighted={layer.uuid === currentWMSLayer}
              key={`${layer.uuid}_${layer.uuid === currentWMSLayer ? 'highlighted' : 'not_highlighted'}`}
              withStyle={{width: 64, height: 64}}
              tileLayer={GoogleLayers.roadmap}
            >
              <DummyWMSLayer
                url={layer.wms_baseurl}
                layers={layer.name}
                opacity={0.5}
                isInternal={layer.is_internal || undefined}
              />
            </DummyMapContainer>
          </div>
        </Col>
        <Col span={16}>
          <Text
            style={{width: '100%'}}
            ellipsis={{tooltip: `${capitalize(layer.frontend_name || '')}`}}
          >
            {capitalize(layer.frontend_name || '')}
          </Text>
        </Col>
        <Col span={2}>
          <Popover
            content={layer.description}
            trigger={'click'}
          >
            <Button
              type={'text'}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              icon={<QuestionCircleOutlined/>}
            />
          </Popover>
        </Col>
      </Row>
    );
  }, [currentWMSLayer, setCurrentWMSLayer, setQueryMode]);
  
  return (
    <Space
      direction={'horizontal'}
      ref={pickerBoxRef}
      align={'end'}
      style={{
        position: 'absolute',
        bottom: '1rem',
        left: '1.5rem',
        zIndex: 1000,
      }}
    >
      <Popover
        trigger={'click'}
        zIndex={1000}
        title={null}
        placement={'rightTop'}
        content={
          <div>
            <Row justify={'start'}>
              <Col>
                <Title level={5} style={{marginTop: 0}}>
                  {capitalize(t('map.baseMapType'))}
                </Title>
              </Col>
            </Row>
            <Row>
              <Col>
                <List
                  grid={{gutter: 16}}
                  dataSource={props.availableBaseTileLayers}
                  renderItem={renderBaseLayer}
                />
              </Col>
            </Row>
            {props.availableWMSLayers && (
              <>
                <Divider/>
                <Row align={'middle'} gutter={[16, 16]}>
                  <Col>
                    <Title level={5} style={{marginTop: 0}}>
                      {capitalize(t('map.layer'))}
                    </Title>
                  </Col>
                  <Col>
                    <Link to={'/settings/layers'}>
                      <Button type={'text'} icon={<SettingOutlined/>}></Button>
                    </Link>
                  </Col>
                  {props.availableWMSLayers && (
                    <Col flex={1}>
                      <Slider
                        value={opacity * 100}
                        onChange={(value) => setOpacity(value / 100)}
                        max={100}
                        min={0}
                        disabled={currentWMSLayer === null}
                        tooltip={{
                          getPopupContainer: () => document.getElementById('map-controls') || document.body,
                          formatter: (value) => `${capitalize(t('layers.opacity'))}: ${value || 0}%`,
                        }}
                      />
                    </Col>
                  )}
                </Row>
                <List
                  dataSource={props.availableWMSLayers}
                  bordered={false}
                  pagination={{
                    position: 'bottom',
                    align: 'end',
                    showTotal: (total, range) => t('table.pageSizeOfTotal', {
                      rangeStart: range[0],
                      rangeEnd: range[1],
                      total,
                    }),
                    defaultPageSize: 3,
                    hideOnSinglePage: true,
                    size: 'small',
                  }}
                  renderItem={renderWMSLayer}
                />
              </>
            )}
          </div>
        }
        {...props.withPopoverConfiguration}
      >
        <div
          style={{
            width: '80px',
            height: '80px',
            borderRadius: '8px',
            backgroundColor: '#DCDCDC',
          }}
        >
          <DummyMapContainer tileLayer={currentTileLayer}/>
        </div>
      </Popover>
      {currentWMSLayer !== null && props.availableWMSLayers && (
        <>
          <Tooltip
            title={capitalize(t('map.disableLayer'))}
            placement={'right'}
          >
            <div
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setCurrentWMSLayer(() => {
                  setQueryMode(null);
                  return null;
                });
              }}
              style={{
                width: '48px',
                height: '48px',
                borderRadius: '8px',
                backgroundColor: '#DCDCDC',
              }}
            >
              <DummyMapContainer
                tileLayer={currentTileLayer}
                isHighlighted={true}
              >
                {props.withLayerLoading && isLoadingLayer && (
                  <div
                    style={{
                      position: 'absolute',
                      width: '48px',
                      height: '48px',
                      backgroundColor: 'rgba(255,255,255,0.5)',
                      zIndex: 10000,
                    }}
                  >
                    <CustomLoading style={{position: 'absolute', verticalAlign: 'middle', width: '100%'}}/>
                  </div>
                )}
                <DummyWMSLayer
                  key={`wms_layer_thumbnail_${currentWMSLayer}`}
                  url={props.availableWMSLayers?.find(layer => layer.uuid === currentWMSLayer)?.wms_baseurl || ''}
                  layers={props.availableWMSLayers?.find(layer => layer.uuid === currentWMSLayer)?.name}
                  opacity={0.5}
                  isInternal={props.availableWMSLayers?.find(layer => layer.uuid === currentWMSLayer)?.is_internal || undefined}
                />
              </DummyMapContainer>
            </div>
          </Tooltip>
        </>
      )}
    
    </Space>
  );
};