import ResponsiveContent from '../../layout/ResponsiveContent';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
import CreateAlertButton from '../../components/alerts/creation/CreateAlertButton';
import {Col, Row, Space, Typography} from 'antd';
import AlertHistoryButton from '../../components/alerts/history/AlertHistoryButton';
import AlertsTable from '../../components/alerts/table/AlertsTable';
import AlertSuggestionButton from '../../components/alerts/suggestion/AlertSuggestionButton';
import HelpTooltip from '../../components/common/HelpTooltip';
import React from 'react';

export default function AlertsStandard() {

  const {t} = useTranslation();

  return <ResponsiveContent
    containerStyle={{gap: '1rem'}}
    titleHeader={<Row justify={'space-between'} align={'middle'}>
      <Col>
        <Typography.Title level={3} style={{marginTop: 0}}>
          {capitalize(t('alerts.manageAlerts'))}
        </Typography.Title>
        <AlertHistoryButton/>
      </Col>
      <Col>
        <CreateAlertButton/>
      </Col>
    </Row>}
  >
    <Space>
      <AlertSuggestionButton/>
      <HelpTooltip
        tooltip={{
          title: t('alerts.suggestionHelp'),
        }}
      />
    </Space>
    <AlertsTable mode={'toManage'}/>
  </ResponsiveContent>;
}