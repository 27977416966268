import {Button} from "antd";
import {useDiscardAllChangesMutation} from "../../../redux/api/events";
import {useMessageContext} from "../../../context/message/MessageContext";
import {useState} from "react";
import {capitalize} from "lodash";
import {useTranslation} from "react-i18next";
import DeleteModalMessage from "../../common/documents/modals/DeleteModalMessage";
import {useMapLoadingContext} from "../../../context/baseMap/MapLoadingContext";
import {useEventContext} from "../../../context/event/EventContext";
import {CHANGES} from "../../../types/api/getEventsResult";

export default function DiscardAllChangesButton() {

  const {t} = useTranslation()
  const [discard] = useDiscardAllChangesMutation()
  const setMessage = useMessageContext()
  const {event, setPolygonsAndAssetsForTheMap} = useEventContext()
  const eventUuid = event?.uuid

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoadingMap, setIsLoadingMap] = useMapLoadingContext()

  const handleClick = async () => {
    if (eventUuid) {
      try {
        setIsModalOpen(false)
        setIsLoadingMap(true)
        await discard({eventUuid}).unwrap()
        await setPolygonsAndAssetsForTheMap(CHANGES.WITH)
        setMessage({success: t('events.discardAllChangesSuccess')})
      } catch {
        setMessage({error: t('events.discardAllChangesFail')})
      } finally {
        setIsLoadingMap(false)
      }
    }
  }

  return <>
    <DeleteModalMessage
      title={{
        children: capitalize(t("events.discardAllChanges"))
      }}
      paragraph={{
        children: t("events.discardAllChangesDescription")
      }}
      confirmButton={{
        disabled: isLoadingMap,
        onClick: handleClick
      }}
      modal={{
        open: isModalOpen,
        onCancel: () => setIsModalOpen(false)
      }}
    />
    <Button
      danger
      loading={isLoadingMap}
      disabled={isLoadingMap}
      onClick={() => setIsModalOpen(true)}
      children={capitalize(t("events.discardAllChanges"))}
    />
  </>
}