import {useDispatch, useSelector} from 'react-redux';
import ResetFiltersSortersButton from '../../../common/filters/ResetFiltersSortersButton';
import {resetAlertsTableSortersAndFilters, selectAlerts} from '../../../../redux/slices/alerts';
import {AlertTableMode} from '../../../../types/internal/tables/alertsTable';

export default function ResetAlertsFiltersSorters({mode}: { mode: AlertTableMode }) {
  const dispatch = useDispatch();

  const sliceKey = mode === 'managed' ? 'alertsManagedTable' : 'alertsToManageTable';
  const filters = useSelector(selectAlerts)[sliceKey].filters;
  const sorters = useSelector(selectAlerts)[sliceKey].sorters;
  const handleConfirm = () => dispatch(resetAlertsTableSortersAndFilters({mode}));
  const isDisabled = Object.values(filters).every(el => el === null) && Object.values(sorters).every(el => el === null);

  return <ResetFiltersSortersButton
    isDisabled={isDisabled}
    onConfirm={handleConfirm}
  />;
}