import {Navigate} from 'react-router-dom';
import {PropsWithChildren} from 'react';

export default function ProtectedRoute({verifyConditionVisibility, redirectUrl, children}: PropsWithChildren<{
  verifyConditionVisibility: () => boolean,
  redirectUrl?: string
}>) {

  const isToShow = verifyConditionVisibility();

  return <>
    {isToShow && children}
    {!isToShow && redirectUrl && <Navigate to={redirectUrl} replace={true}/>}
    {!isToShow && !redirectUrl && null}
  </>;
}