import {capitalize, debounce} from "lodash";
import {NumericFormat, NumericFormatProps} from "react-number-format";
import {Input, Typography} from "antd";
import {Trans, useTranslation} from "react-i18next";
import {useGetUserProfileQuery} from "../../../../redux/api/locales";
import {addFilters, removeFilters, selectAssetsTableFilters} from "../../../../redux/slices/assets";
import {useDispatch, useSelector} from "react-redux";
import React from "react";
import BiodiversitySourceFilter from "./BiodiversitySourceFilter";

const MAX_RADIUS = 10000

export function BiodiversityInput(numericProps: NumericFormatProps) {

  const {t} = useTranslation()
  const {data} = useGetUserProfileQuery();
  let props: NumericFormatProps<any> = {
    customInput: Input,
    allowNegative: false,
    decimalScale: 0,
    suffix: ' m',
    isAllowed: values => {
      return values.floatValue === undefined || values.floatValue <= MAX_RADIUS
    }
  };

  if (data?.language === 'it') {
    props = {
      thousandSeparator: '.',
      decimalSeparator: ',',
      ...props,
      ...numericProps
    };
  }


  return <NumericFormat
    placeholder={capitalize(t('table.filterPlaceholder', {column: t('assets.biodiversityDistance')}))}
    {...props}
  />
}

export default function BiodiversityDistance() {

  const dispatch = useDispatch();
  const activeFilters = useSelector(selectAssetsTableFilters);
  const distance = activeFilters.biodiversity_distance === undefined ? "" : activeFilters.biodiversity_distance
  const debounceUpdate = debounce((v: number | undefined) => {
    if (typeof v === 'number' && v >= 0) {
      dispatch(addFilters({biodiversity_distance: v}))
    } else {
      dispatch(removeFilters('biodiversity_distance'))
    }
  }, 500);

  return <div
    style={{maxWidth: '20rem', marginBottom: '1rem'}}>
    <div
      style={{marginBottom: '0.5rem'}}>
      <Trans i18nKey={'assets.biodiversityDistanceInsert'}
             components={{p: <Typography.Paragraph italic style={{margin: 0}}/>}}/>
    </div>
    <BiodiversityInput
      value={distance}
      onValueChange={value => {
        debounceUpdate(value.floatValue);
      }}
    />
  </div>
}