import {Button, Col, Layout, Row, Steps} from 'antd';
import {useMemo, useState} from 'react';
import {AssetPositionFormCard} from '../components/addAsset/AssetPositionFormCard';
import {AssetDetailFormCard} from '../components/addAsset/AssetDetailFormCard';
import {AssetRealEstateFormCard} from '../components/addAsset/AssetRealEstateFormCard';
import {useAddAssetFormContext} from '../context/addAsset/AddAssetFormContext';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
import {CustomSider} from '../components/common/CustomSider';

const {Content} = Layout;
export const AddAsset = () => {

  const {t} = useTranslation();

  const {
    isFirstStepComplete,
    isSecondStepComplete,
    isThirdStepComplete,
    submit,
  } = useAddAssetFormContext();

  const [
    currentStep,
    setCurrentStep,
  ] = useState(0);

  const steps = useMemo(() => ([
    {
      title: capitalize(t('assets.assetLocation')),
    },
    {
      title: capitalize(t('assets.assetsDetail')),
    },
    {
      title: capitalize(t('assets.realEstateData')),
    },
  ]), [t]);

  const items = useMemo(() =>
    steps.map((item) =>
      ({key: item.title, title: item.title}),
    ), [steps]);

  return (
    <>
      <Layout style={{position: 'relative'}}>
        <CustomSider
          backButtonLabel={capitalize(t('form.cancel'))}
          content={
            <Steps
              current={currentStep}
              items={items}
              size={'small'}
              labelPlacement={'horizontal'}
              direction={'vertical'}
              style={{padding: '0 1.5rem'}}
              onChange={step => {
                if (
                  (step < currentStep) ||
                  (step === 1 && isFirstStepComplete()) ||
                  (step === 2 && isSecondStepComplete())
                ) {
                  setCurrentStep(step);
                }
              }}
            />
          }
        />
        <Content style={{overflowY: 'scroll'}}>
          <div style={{margin: '1rem 1.5rem'}}>
            {currentStep === 0 && <AssetPositionFormCard/>}
            {currentStep === 1 && <AssetDetailFormCard/>}
            {currentStep === 2 && <AssetRealEstateFormCard/>}
            <Row justify={'end'} style={{marginTop: '1rem'}}>
              <Col>
                {currentStep > 0 && (
                  <Button
                    style={{margin: '0 8px'}}
                    disabled={currentStep === 0}
                    onClick={() => setCurrentStep(current => current - 1)}
                  >
                    {capitalize(t('form.previous'))}
                  </Button>
                )}
                {currentStep < steps.length - 1 && (
                  <Button
                    type="primary"
                    disabled={currentStep === 0 ? !isFirstStepComplete() : !isSecondStepComplete()}
                    onClick={() => setCurrentStep(current => current + 1)}
                  >
                    {capitalize(t('form.next'))}
                  </Button>
                )}
                {currentStep === steps.length - 1 && (
                  <Button
                    type="primary"
                    disabled={!isThirdStepComplete()}
                    onClick={() => submit()}
                  >
                    {capitalize(t('form.done'))}
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        </Content>
      </Layout>
    </>
  );
};