import {capitalize} from "lodash";
import {Button, Divider, Modal} from "antd";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import AntFormItem from "../../common/AntFormItem";
import {GetEventHistoryPerAsset} from "../../../types/api/getEventsResult";
import {getAssetAddress} from "../../../utils/assets";
import DateTimeFormat from "../../../locales/DateTimeFormat";
import {VALUE_NOT_PRESENT} from "../../../utils/translation";

export default function AssetStatusDetails({details}: { details: GetEventHistoryPerAsset }) {

  const {t} = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  return <>
    <Modal
      title={capitalize(t('actions.details'))}
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      footer={<Button
        type={'primary'}
        onClick={() => setIsOpen(false)}
      >
        {capitalize(t('actions.close'))}
      </Button>}
    >
      <AntFormItem label={capitalize(t('assets.sigiCode'))} style={{margin: 0}}>
        {details.asset.sigi_code}
      </AntFormItem>
      <AntFormItem label={capitalize(t('assets.asset', {count: 1}))} style={{margin: 0}}>
        {getAssetAddress(details.asset)}
      </AntFormItem>
      <AntFormItem label={capitalize(t('misc.date'))} style={{margin: 0}}>
        <DateTimeFormat>{details.operation_date}</DateTimeFormat>
      </AntFormItem>
      <AntFormItem label={capitalize(t('user.user', {count: 1}))} style={{margin: 0}}>
        {details.user_edit}
      </AntFormItem>
      <Divider style={{margin: '1rem 0'}}/>
      <AntFormItem label={capitalize(t('misc.note'))} labelCol={{span: 24}} wrapperCol={{span: 24}} style={{margin: 0}}>
        {details.text_note || VALUE_NOT_PRESENT}
      </AntFormItem>
    </Modal>
    <Button
      onClick={() => setIsOpen(true)}
      type={'link'}>
      {capitalize(t('actions.details'))}
    </Button>
  </>
}