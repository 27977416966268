import {Col, Layout, Menu, Row, Space} from 'antd';
import {AssetLocationCard} from '../components/asset/cards/AssetLocationCard';
import {AssetDetailCard} from '../components/asset/cards/AssetDetailCard';
import {RealEstateDataCard} from '../components/asset/cards/RealEstateDataCard';
import {IdentifiedHazardsCard} from '../components/asset/cards/IdentifiedHazardsCard';
import {AssetContext} from '../context/assetDetails/AssetContext';
import {FileOutlined, GlobalOutlined, HeatMapOutlined, InfoCircleOutlined, RiseOutlined} from '@ant-design/icons';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import {useMemo, useState} from 'react';
import {AssetSummaryCard} from '../components/common/AssetSummaryCard';
import {DocumentsListCard} from '../components/asset/cards/DocumentsListCard';
import {ImagesListCard} from '../components/asset/cards/ImagesListCard';
import PermissionRoute from '../components/Authentication/PermissionRoute';
import {isAssetDocumentView, isAssetImageView} from '../components/Authentication/permissions';
import {CustomSider} from '../components/common/CustomSider';
import {AssetEventsHistoryCard} from "../components/asset/cards/AssetEventsHistoryCard";
import BiodiversityCard from "../components/asset/cards/BiodiversityCard";
import usePreviousPath from "../customHooks/usePreviousPath";
import ClimateChangeCard from "../components/asset/cards/climateChange/ClimateChangeCard";
import ClimateChangeIcon from "../components/common/icons/ClimateChangeIcon";

const {Content} = Layout;
export default function Asset() {

  const {t} = useTranslation();

  const [
    currentStep,
    setCurrentStep,
  ] = useState('buildingInformation');

  const items = useMemo(() => ([
    {
      key: 'buildingInformation',
      label: capitalize(t('assets.assetInformation')),
      icon: <InfoCircleOutlined/>,
    },
    {
      key: 'eventHistoryAndNotes',
      label: capitalize(t('events.historyEvent')),
      icon: <HeatMapOutlined/>,
    },
    {
      key: 'identifiedHazards',
      label: capitalize(t('hazards.risksAndHazards')),
      icon: <RiseOutlined/>,
    },
    {
      key: 'biodiversity',
      label: capitalize(t('assets.biodiversity')),
      icon: <GlobalOutlined/>,
    },
    ...process.env.REACT_APP_SHOW_CLIMATE_CHANGE === 'show' ? [{
      key: 'climateChange',
      label: capitalize(t('assets.climateChange')),
      icon: <ClimateChangeIcon/>,
    }] : [],
    {
      key: 'documents',
      label: capitalize(t('documents.documentsAndImages')),
      icon: <FileOutlined/>,
    },
  ]), [t]);

  const {path, label} = usePreviousPath()
  const navigationProps = path && label ?
    {backButtonLabel: capitalize(t('actions.goBackDestination', {destination: label})), destination: path} :
    {backButtonLabel: capitalize(t('misc.goBackToAssets')), destination: '/assets'}

  return (
    <>
      <AssetContext>
        <Layout style={{position: 'relative'}}>
          <CustomSider
            {...navigationProps}
            title={capitalize(t('assets.assetDetail'))}
            content={
              <Menu
                theme="dark"
                defaultSelectedKeys={['buildingInformation']}
                onSelect={(item) => setCurrentStep(item.key)}
                mode="inline"
                items={items}
              />
            }
            summaryCard={<AssetSummaryCard/>}
          />
          <Content style={{overflowY: 'scroll'}}>
            <div style={{margin: '1.5rem 1.5rem'}}>
              <Row gutter={[28, 28]}>
                <Col span={24}>
                  <Space
                    direction={'vertical'}
                    style={{display: 'flex'}}
                    size={'large'}
                  >
                    {currentStep === 'buildingInformation' && (
                      <>
                        <AssetLocationCard/>
                        <AssetDetailCard/>
                        <RealEstateDataCard/>
                      </>

                    )}
                    {currentStep === 'identifiedHazards' && (
                      <>
                        <IdentifiedHazardsCard/>
                      </>
                    )}
                    {currentStep === 'eventHistoryAndNotes' && (
                      <>
                        <AssetEventsHistoryCard/>
                      </>
                    )}
                    {currentStep === 'biodiversity' && (
                      <BiodiversityCard/>
                    )}
                    {currentStep === 'climateChange' && process.env.REACT_APP_SHOW_CLIMATE_CHANGE  === 'show' && (
                      <ClimateChangeCard/>
                    )}
                    {currentStep === 'documents' && (
                      <>
                        <PermissionRoute
                          isAuthorized={isAssetDocumentView}
                        >
                          <DocumentsListCard/>
                        </PermissionRoute>
                        <PermissionRoute
                          isAuthorized={isAssetImageView}
                        >
                          <ImagesListCard/>
                        </PermissionRoute>
                      </>
                    )}
                  </Space>
                </Col>
              </Row>
            </div>
          </Content>
        </Layout>
      </AssetContext>
    </>
  );
}