import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import {useGetUserProfileQuery} from './redux/api/locales';
import {useEffect} from 'react';
import {Outlet} from 'react-router-dom';

export default function LogRocketConfig() {

  const {data} = useGetUserProfileQuery();
  const logrocketAppId = process.env.REACT_APP_LOGROCKET_APP_ID

  useEffect(() => {
    if (logrocketAppId && data) {
      LogRocket.init(logrocketAppId,
        {
          network: {
            requestSanitizer: (request) => {
              // hide request / response pair with sensitive data
              const extractedUrl = request.url;

              const sensitiveEndpoint = [
                'get-token',
                'refresh-token',
                'rei_layer',
              ];

              const conditionsForHidingResponse =
                sensitiveEndpoint.some(el => extractedUrl.includes(el));

              if (conditionsForHidingResponse) {
                return null;
              }

              // hide JWT token
              request.headers = {};
              return request;
            },
            responseSanitizer: (response) => {
              const sensitiveEndpoint = ['get-token', 'refresh-token'];
              if (sensitiveEndpoint.includes(response.reqId)) {
                response.body = '';
              }
              return response;
            },
          },
        });
      // redux setup after calling LogRocket.init()
      setupLogRocketReact(LogRocket);
      //user setup
      LogRocket.identify(data?.uuid || "NOT_DEFINED_USER");
    }

  }, [data, logrocketAppId]);

  return <Outlet/>;
}
