import {Col, Pagination, Row, Table, Typography} from 'antd';
import React, {useMemo, useState} from 'react';
import {ArrowRightOutlined} from '@ant-design/icons';
import {ColumnsType} from "antd/lib/table";
import {useGetEventHistoryPerAssetPaginatedQuery} from "../../../redux/api/events";
import {useEventContext} from "../../../context/event/EventContext";
import {PaginationConfig} from "antd/lib/pagination";
import {skipToken} from "@reduxjs/toolkit/query";
import {useTranslation} from "react-i18next";
import {AssetAddress, GetEventHistoryPerAsset} from "../../../types/api/getEventsResult";
import DateTimeFormat from "../../../locales/DateTimeFormat";
import {capitalize} from "lodash";
import {getAssetStatusTranslationKey, VALUE_NOT_PRESENT} from "../../../utils/translation";
import {ClusterOption} from "../../events/importAlertsAreas/AssignCluster";
import AssetStatusDetails from "../eventStatus/AssetStatusDetails";
import {getAssetAddress} from "../../../utils/assets";


export const MovingHistoryByAssetView = () => {

  const {t} = useTranslation()
  const {event, getClusterFromUuid} = useEventContext()
  const [pagination, setPagination] = useState<PaginationConfig>({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    showTotal: (total, range) => t('table.pageSizeOfTotal', {
      rangeStart: range[0],
      rangeEnd: range[1],
      total,
    }),
    onChange: (page, pageSize) => setPagination(prevPag => ({...prevPag, current: page, pageSize})),
    style: {textAlign: 'center'},
  });

  const isParamsOk = !!(event?.uuid && pagination.current && pagination.pageSize)

  const {data: history, isFetching} = useGetEventHistoryPerAssetPaginatedQuery(isParamsOk ? {
    eventUuid: event.uuid,
    page_size: pagination.pageSize,
    page: pagination.current,
  } : skipToken)

  const columns: ColumnsType<GetEventHistoryPerAsset> = useMemo(() => [
    {
      title: capitalize(t('misc.date')),
      dataIndex: 'operation_date',
      key: 'operation_date',
      width: '15%',
      render: (v: string | null) => <DateTimeFormat>{v}</DateTimeFormat>
    },
    {
      title: capitalize(t('assets.sigiCode')),
      dataIndex: ['asset', 'sigi_code'],
      key: 'sigi_code',
      width: '20%',
    },
    {
      title: capitalize(t('address.address')),
      dataIndex: 'asset',
      key: 'asset',
      width: '20%',
      render: (asset: AssetAddress) => <Typography.Text ellipsis={{tooltip: true}} style={{maxWidth: '18rem'}}>
        {getAssetAddress(asset)}
      </Typography.Text>
    },
    {
      title: capitalize(t('events.change', {count: 1})),
      dataIndex: 'change',
      key: 'change',
      width: '35%',
      render: (value: GetEventHistoryPerAsset['change']) => {

        if ('state_from' in value) {
          const {state_from, state_to} = value
          return <div style={{display: 'flex', gap: '0.5rem'}}>
            <Typography.Text>{state_from ? capitalize(t(getAssetStatusTranslationKey({
              state: state_from,
              reduced: true
            }), {count: 1})) : VALUE_NOT_PRESENT}</Typography.Text>
            <ArrowRightOutlined/>
            <Typography.Text>{state_to ? capitalize(t(getAssetStatusTranslationKey({
              state: state_to,
              reduced: true
            }), {count: 1})) : VALUE_NOT_PRESENT}</Typography.Text>
          </div>
        } else {
          const {cluster_template_from, cluster_template_to} = value
          const clusterFrom = cluster_template_from ? getClusterFromUuid(cluster_template_from) || null : null
          const clusterTo = cluster_template_to ? getClusterFromUuid(cluster_template_to) || null : null

          return <div style={{display: 'flex', gap: '0.5rem', alignItems: 'center'}}>
            {cluster_template_from ? <ClusterOption cluster={clusterFrom}/> : VALUE_NOT_PRESENT}
            <ArrowRightOutlined/>
            {clusterTo ? <ClusterOption cluster={clusterTo}/> : VALUE_NOT_PRESENT}
          </div>
        }
      }
    },
    {
      dataIndex: 'action',
      key: 'action',
      width: '10%',
      render: (_, record) => (
        <AssetStatusDetails details={record}/>
      ),
    },
  ], [getClusterFromUuid, t])


  return (
    <>
      <Row style={{marginTop: '1rem'}}>
        <Col flex={1}>
          <Table
            <GetEventHistoryPerAsset>
            dataSource={history?.results}
            columns={columns}
            loading={isFetching}
            rowKey={'uuid'}
            pagination={false}
            scroll={{x: true}}
          />
        </Col>
      </Row>
      {!!history?.count && <Row justify={'center'} style={{marginTop: '1rem'}}>
        <Col>
          <Pagination
            {...pagination}
            total={history?.count}
          />
        </Col>
      </Row>}
    </>
  );
};