import {Entity, useLazyGetGeoshapesEntitiesPaginatedQuery} from '../../../redux/api/geoshapes';
import {useCallback, useRef, useState} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useTranslation} from 'react-i18next';
import {GenericPaginatedApi} from '../../../types/api/paginatedApi';
import {Button, Input} from 'antd';
import _, {capitalize} from 'lodash';
import {translateLevelToText} from '../../../utils/assets';
import {OptionPolygon} from './AlertCreation';


type SelectGeoshapesProps =
  {
    selectedGeoShapes: string[],
    selectGeoShape: (opt: OptionPolygon, entity?: Entity) => void,
    unselectGeoShapes: (entityUuid: string, globalPolygonUUid: string) => void,
    disabledOptions?: string[],
    disabledSelect?: boolean
  } |
  {
    selectGeoShape: (opt: OptionPolygon, entity?: Entity) => void,
    disabledOptions?: string[],
    disabledSelect?: boolean
  }


export default function SelectGeoshapeEntities({
                                                 selectGeoShape,
                                                 disabledOptions,
                                                 disabledSelect,
                                                 ...props
                                               }: SelectGeoshapesProps) {
  const [getData, {data, isFetching}] = useLazyGetGeoshapesEntitiesPaginatedQuery();
  const [options, setOptions] = useState<OptionPolygon[]>([]);
  const {t} = useTranslation();

  const page = useRef<number>(1);
  const name = useRef<string | null>(null);

  const reset = () => {
    page.current = 1;
    name.current = null;
    setOptions([]);
  };

  const handleGetData = useCallback(async ({currentPage, currentName}: {
    currentPage: number,
    currentName: string,
  }) => {
    try {
      let data: GenericPaginatedApi<Entity>;
      if (currentName !== name.current) {
        data = await getData({name: currentName}).unwrap();
        setOptions(data.results.map(el => ({
          value: el.uuid,
          label: `${el.name} (${capitalize(t(translateLevelToText(el.level)))})`,
          globalpolygon: el.globalpolygon,
        })));
      } else {
        if (currentPage > page.current) {
          data = await getData({page: currentPage, name: currentName}).unwrap();
          setOptions(prevOpt => {
            return [...prevOpt, ...data.results.map(el => ({
              value: el.uuid,
              label: `${el.name} (${capitalize(t(translateLevelToText(el.level)))})`,
              globalpolygon: el.globalpolygon,
            }))];
          });
        }
      }
      page.current = currentPage;
      name.current = currentName;
    } catch {

    }
  }, [getData, t]);

  const debounceHandleGetData = _.debounce(handleGetData, 400);
  const hasMore = options.length < (data?.count || options.length);
  const isSelectedGeoshapesProp = 'selectedGeoShapes' in props
  const isUnselectGeoShapesProp = 'unselectGeoShapes' in props

  return <>
    <Input.Search
      placeholder={capitalize(t('alerts.geoTypePlaceholder'))}
      onChange={(e) => e.target.value ? debounceHandleGetData({
        currentPage: 1,
        currentName: e.target.value,
      }) : reset()}
      loading={isFetching}
      allowClear
    />
    <InfiniteScroll
      style={{
        marginTop: '1rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        gap: '0.2rem',
      }}
      dataLength={options.length} //This is important field to render the next data
      hasMore={hasMore}
      loader={null}
      height={'10rem'}
      next={() => {
        if (name.current) {
          handleGetData({currentPage: page.current + 1, currentName: name.current});
        }
      }}
    >
      {!!name.current && options.map(el => {

        const isSelected = isSelectedGeoshapesProp && props.selectedGeoShapes.includes(el.value);

        return <Button
          disabled={disabledOptions?.some(entity => entity === el.value) || disabledSelect}
          block
          type={isSelected ? 'default' : 'text'}
          key={el.value}
          onClick={() => {
            if (isSelectedGeoshapesProp && isUnselectGeoShapesProp) {
              if (!isSelected) {
                selectGeoShape(el, data?.results.find(entity => entity.uuid === el.value));
              } else {
                props.unselectGeoShapes(el.value, el.globalpolygon);
              }
            } else {
              selectGeoShape(el, data?.results.find(entity => entity.uuid === el.value));
            }
          }}
        >
          {el.label}
        </Button>;
      })}
      {!isFetching && !!name.current && options.length === 0 && <p>{capitalize(t('form.noResults'))}</p>}
    </InfiniteScroll>
  </>;
}