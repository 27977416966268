import React from 'react';
import {useAlertMarkersContext} from '../../../../context/alertMap/AlertsMarkersContext';
import L from 'leaflet';
import {CustomMarkerClusterGroup} from '../../../assets/assetsMap/CustomMarkerClusterGroup';
import AlertPolygons from './AlertPolygons';
import {AlertMapMarker} from '../../../../pages/alerts/AlertMapMarker';
import AlertEpicenterMarker from "./AlertEpicenterMarker";
import {AssetDetail} from "../../../assets/assetsMap/customPopup/AssetDetail";
import {MapDetailsContext} from "../../../../context/assetsMap/AssetMapDetailsContext";
import {Button, Col, Row} from "antd";
import {capitalize} from "lodash";
import {Popup, useMap} from "react-leaflet";
import {useTranslation} from "react-i18next";
import {useAssetDrawerContext} from "../../../../context/alertDetails/AssetDrawerContext";
import {useAssetDetails} from "../../../../context/assetDetails/AssetDetails";

export default function AlertMap() {

  const {markers} = useAlertMarkersContext();
  const map = useMap()
  const {t} = useTranslation()
  const [, setIsOpen] = useAssetDrawerContext()
  const [setAssetUuid] = useAssetDetails();

  return <>
    <AlertEpicenterMarker/>
    <CustomMarkerClusterGroup>
      {!!markers && markers?.map(marker => (
        <AlertMapMarker
          key={marker.uuid}
          markerUuid={marker.uuid}
          position={L.latLng([marker.position?.lat || 0, marker.position?.lng || 0])}
        >
          <Popup
            className={'asset-position-marker-popup'}
            autoPan={true}
            autoPanPadding={[(map.getSize().x - 300) / 2, (map.getSize().y - 226) / 2]}
          >
            <MapDetailsContext>
              <AssetDetail
                markerUuid={marker.uuid}/>
              <Row justify={'center'} style={{marginTop: '1rem'}}>
                <Col>
                  <Button
                    type={'primary'}
                    onClick={() => {
                      map.closePopup()
                      setAssetUuid(marker.uuid)
                      setIsOpen(true)
                    }}
                  >
                    {capitalize(t('misc.goToFullDetails'))}
                  </Button>
                </Col>
              </Row>
            </MapDetailsContext>
          </Popup>
        </AlertMapMarker>
      ))}
    </CustomMarkerClusterGroup>
    <AlertPolygons/>
  </>;
}