import {CSSProperties} from 'react';

export default function PreviewEventTypeIconBig({src, style, selected, onClick}: {
  src?: string,
  style?: CSSProperties,
  selected?: boolean,
  onClick?: () => void
}) {
  return <img
    onClick={onClick}
    src={src}
    alt={''}
    style={{
      boxSizing: 'content-box',
      width: '135px',
      height: '135px',
      padding: '10px',
      border: selected ? '3px solid var(--primary-6, #1890FF)' : '1px solid var(--neutral-5, #D9D9D9)',
      overflow: 'visible',
      ...style,
    }}
  />;
}