import {useAssetDetails} from '../../../context/assetDetails/AssetDetails';
import React, {useEffect} from 'react';
import {AssetLocation} from '../AssetLocation';
import {capitalize} from 'lodash';
import {Card, Skeleton} from 'antd';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';

export const AssetLocationCard = () => {

  const {t} = useTranslation();
  const {assetId} = useParams();

  const [setAssetUuid, {asset, loading}] = useAssetDetails();

  useEffect(() => {
    if (assetId) {
      setAssetUuid(assetId);
    }
  }, [assetId, setAssetUuid]);

  return (
    <Card title={capitalize(t('assets.assetLocation'))}>
      {loading && <Skeleton active={true}/>}
      {!loading && <AssetLocation asset={asset}/>}
    </Card>

  );
};