import {Button, ButtonProps} from 'antd';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';

export default function ImportAlertsAreasButton(button?: ButtonProps) {

  const {t} = useTranslation();

  return <Button
    type={'primary'}
    {...button}
  >
    {capitalize(t('events.importAlertAreas'))}
  </Button>;
}