import {AssetModel} from '../../types/internal/AssetModel';
import {RealEstateHub} from '../../types/internal/RealEstateHub';
import {ContactPerson} from '../../types/api/contactPerson';
import {Company} from '../../types/internal/Companies';
import {Col, Row} from 'antd';
import {LabeledValue, VALUE_TYPES} from '../common/LabeledValue';
import {capitalize} from 'lodash';
import {useGetAssetsOptionsQuery} from '../../redux/api/assets';
import {useTranslation} from 'react-i18next';

export const AssetDetail = ({asset, details}: {
  asset: AssetModel,
  details: {
    realestateHub: RealEstateHub,
    contact_people: ContactPerson[],
    companies: Company[],
    master_asset: AssetModel | null,
  }

}) => {

  const {t} = useTranslation();
  const {data: assetsOptions} = useGetAssetsOptionsQuery();

  return (
    <>
      <Row>
        <Col span={8}>
          <LabeledValue
            label={capitalize(t('assets.sigiCode'))}
            value={asset.sigi_code}
          />
        </Col>
        <Col span={8}>
          <LabeledValue
            label={capitalize(t('assets.masterAssetCode'))}
            value={details.master_asset?.sigi_code}
          />
        </Col>
        <Col span={8}>
          <LabeledValue
            label={capitalize(t('assets.userCreated'))}
            value={asset.is_user_created ? capitalize(t('misc.yes')) : capitalize(t('misc.no'))}
          />
        </Col>
      </Row>
      <Row style={{marginTop: '1rem'}}>
        <Col span={8}>
          <LabeledValue
            label={capitalize(t('assets.buildingType'))}
            value={assetsOptions?.building_type.find(option => option.value === asset.building_type)?.label}
          />
        </Col>
        <Col span={8}>
          <LabeledValue
            label={capitalize(t('assets.buildingTypeDetail'))}
            value={assetsOptions?.building_type_detail.find(option => option.value === asset.building_type_detail)?.label}
          />
        </Col>
        <Col span={8}>
          <LabeledValue
            label={capitalize(t('assets.companiesWithin'))}
            value={details.companies?.map(company => company.name)?.join(', ')}
          />
        </Col>
      </Row>
      <Row style={{marginTop: '1rem'}}>
        <Col span={8}>
          <LabeledValue
            label={capitalize(t('assets.totalResourcesNumber'))}
            value={asset.total_resources}
          />
        </Col>
        <Col span={8}>
          <LabeledValue
            label={capitalize(t('assets.surfaceTotal'))}
            value={asset.surface_total}
            type={VALUE_TYPES.AREA}
          />
        </Col>
        <Col span={8}>
          <LabeledValue
            label={capitalize(t('assets.surfaceBasement'))}
            value={asset.surface_basement}
            type={VALUE_TYPES.AREA}
          />
        </Col>
      </Row>
      <Row style={{marginTop: '1rem'}}>
        <Col span={8}>
          <LabeledValue
            label={capitalize(t('assets.openingDate'))}
            value={asset.opening_date}
            type={VALUE_TYPES.DATE}
          />
        </Col>
        <Col span={8}>
          <LabeledValue
            label={capitalize(t('assets.closingDate'))}
            value={asset.closing_date}
            type={VALUE_TYPES.DATE}
          />
        </Col>
        <Col span={8}>
          <LabeledValue
            label={capitalize(t('assets.isOwned'))}
            value={assetsOptions?.ownership?.find(option => option.value === asset.ownership)?.label}
            type={VALUE_TYPES.TEXT}
          />
        </Col>
      </Row>
    </>
  );
};