import React, {useEffect, useState} from 'react';
import {useDeleteAssetDocumentMutation, useLazyGetAssetDocumentsQuery} from '../../redux/api/documents';
import DocumentsList from '../common/documents/DocumentsList';
import {AssetModel} from '../../types/internal/AssetModel';
import {useGetUserProfileQuery} from '../../redux/api/locales';
import {isAssetDocumentDelete, isAssetDocumentEdit, isAssetDocumentView} from '../Authentication/permissions';

export const AssetsDocumentsListInAlert = ({asset}: { asset: AssetModel }) => {

  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [deleteDocument] = useDeleteAssetDocumentMutation();

  const handleRemove = (documentUuid: string) => {
    if (asset.uuid) {
      return deleteDocument({assetUuid: asset.uuid, documentUuid: documentUuid}).unwrap();
    } else {
      return Promise.reject();
    }
  };

  const [
    getDocuments,
    {
      isLoading: isAssetDocumentsLoading,
      isFetching: isAssetDocumentsFetching,
      data: assetsDocuments,
    },
  ] = useLazyGetAssetDocumentsQuery();

  const {
    data: userData,
  } = useGetUserProfileQuery();

  const isEditing = userData?.type ? isAssetDocumentEdit(userData?.type) : false;
  const isRemove = userData?.type ? isAssetDocumentDelete(userData?.type) : false;
  const isDownload = userData?.type ? isAssetDocumentView(userData?.type) : false;

  useEffect(() => {
    if (asset.uuid) {
      getDocuments({uuid: asset.uuid, page: currentPage, page_size: currentPageSize});
    }
  }, [currentPage, getDocuments, currentPageSize, asset.uuid]);

  return <DocumentsList
    documents={assetsDocuments?.results}
    total={assetsDocuments?.count || 0}
    page={currentPage}
    pageSize={currentPageSize}
    setPage={setCurrentPage}
    setPageSize={setCurrentPageSize}
    loading={isAssetDocumentsLoading || isAssetDocumentsFetching}
    {...isRemove && {onRemove: handleRemove}}
    withEdit={isEditing}
    withDownload={isDownload}
  />;
};