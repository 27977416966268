import {Space, Typography} from "antd";
import {capitalize} from "lodash";
import CustomLatitudeNumericFormat from "../customNumeric/CustomLatitudeNumericFormat";
import CustomLongitudeNumericFormat from "../customNumeric/CustomLongitudeNumericFormat";
import React from "react";
import {useTranslation} from "react-i18next";

export default function EpicenterInfo({lat, lng}: {lat: number, lng: number}) {

  const {t} = useTranslation()

  return <>
    <Typography.Title
      level={4}
      style={{marginBottom: '1rem'}}>
      {capitalize(t('events.epicenter'))}
    </Typography.Title>
    <div>
      <Space>
        <label>{capitalize(t('address.latitude'))}:</label>
        <CustomLatitudeNumericFormat value={lat} displayType={"text"}/>
      </Space>
    </div>
    <div>
      <Space>
        <label>{capitalize(t('address.longitude'))}:</label>
        <CustomLongitudeNumericFormat value={lng} displayType={"text"}/>
      </Space>
    </div>
  </>
}