import React, {useState} from 'react';
import {Button, Col, Row, Space, Tag} from 'antd';
import chroma from 'chroma-js';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
import {TagProps} from 'antd/es/tag';

export const CustomTag = ({content, color = '#c9c9c9', ...tagProps}: {
  content: string,
  color?: string
} & TagProps) => {

  return (
    <Tag
      {...tagProps}
      color={chroma(color).alpha(0.2).hex()}
      style={{
        border: `1px solid ${chroma(color).alpha(0.5).hex()}`,
        borderRadius: '11px ',
        color: chroma(color).darken(2).hex(),
        boxShadow: `0 0 3px ${chroma(color).darken(0.75).hex()}`
      }}
      bordered={true}
    >
      {content}
    </Tag>
  );

};

export const ExpandableTagList = ({tags, customTag = true}: {
  tags: { uuid?: string, content: string, color?: string }[],
  customTag?: boolean
}) => {

  const {t} = useTranslation();

  const [
    isExpanded,
    setIsExpanded,
  ] = useState(false);

  return (<>
    {(isExpanded) && (
      <Row
        justify={'space-between'}
        align={'middle'}
        wrap={false}
      >
        <Col>
          <Space
            size={'small'}
            wrap={true}
          >
            {tags.map((tag) => (
              customTag ?
                <CustomTag
                  content={tag.content}
                  color={tag.color}
                  key={tag.uuid}
                /> :
                <Tag
                  children={tag.content}
                  color={tag.color}
                  key={tag.uuid}
                />
            ))}
          </Space>
        </Col>
        {tags.length > 1 && (
          <Col>
            <Button
              type={'link'}
              onClick={() => setIsExpanded(false)}
            >
              {capitalize(t('misc.collapse'))}
            </Button>
          </Col>
        )}
      </Row>
    )}
    {tags.length > 0 && (!isExpanded || tags.length === 1) && (
      <Space size={'small'}>
        {customTag ? <CustomTag
            content={tags.at(0)?.content || ''}
            color={tags.at(0)?.color}
            key={tags.at(0)?.uuid}
          /> :
          <Tag
            children={tags.at(0)?.content}
            color={tags.at(0)?.color}
            key={tags.at(0)?.uuid}
          />}
        {tags.length > 1 &&
          <Button
            type={'link'}
            onClick={() => setIsExpanded(true)}
          >
            {`+${tags.length - 1}`}
          </Button>}
      </Space>
    )}
  </>);
};