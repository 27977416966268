import {MultiPolygon as MP} from '../redux/api/geoshapes';
import L from 'leaflet';
import {Feature, LineString, multiPolygon, MultiPolygon, point, Point, polygon, Polygon, Position} from '@turf/helpers';
import booleanContains from '@turf/boolean-contains';
import centerOfMass from '@turf/center-of-mass';
import distance from '@turf/distance';
import area from '@turf/area';
import {polygonToLine} from '@turf/polygon-to-line';
import length from '@turf/length';

export const geoJSONToLeafletPolygon = (multipolygonGeometry: MP) => {
  return multipolygonGeometry
    .map(polygon =>
      polygon.map(boundary =>
        boundary.map(([lng, lat]) =>
          new L.LatLng(lat, lng),
        ),
      ),
    );
};

export const flipTurfFeatureCoordinates = (feature: Feature<Point | LineString | Polygon | MultiPolygon, {}>) => {
  switch (feature.geometry.type) {
    case 'Point':
      return {
        ...feature,
        geometry: {
          ...feature.geometry,
          coordinates: [feature.geometry.coordinates.at(1), feature.geometry.coordinates.at(0)],
        },
      };
    case 'LineString':
      return {
        ...feature,
        geometry: {
          ...feature.geometry,
          coordinates:
            feature.geometry.coordinates
              .map(([lng, lat]) => ([lat, lng])),
        },
      };
    case 'Polygon':
      return {
        ...feature,
        geometry: {
          ...feature.geometry,
          coordinates:
            feature.geometry.coordinates
              .map((position: Position[]) => position
                .map(([lng, lat]) => ([lat, lng]))),
        },
      };
    case 'MultiPolygon':
      return {
        ...feature,
        geometry: {
          ...feature.geometry,
          coordinates:
            feature.geometry.coordinates
              .map((polygon: Position[][]) => polygon
                .map(position => position
                  .map(([lng, lat]) => ([lat, lng])))),
        },
      };
  }
};

export function isContained(firstMultiPolygon: Feature<MultiPolygon, any>, secondMultiPolygon: Feature<MultiPolygon, any>) {
  // Controlla se il primo multipoligono è completamente contenuto nel secondo multipoligono
  
  // Estrai i poligoni interni dal primo multipoligono
  const firstPolygons = extractPolygons(firstMultiPolygon);
  
  // Estrai i poligoni interni dal secondo multipoligono
  const secondPolygons = extractPolygons(secondMultiPolygon);
  
  // Controlla se ogni poligono del primo multipoligono è contenuto almeno in uno dei poligoni del secondo multipoligono
  for (const firstPolygon of firstPolygons) {
    let isContained = false;
    for (const secondPolygon of secondPolygons) {
      if (booleanContains(secondPolygon, firstPolygon)) {
        isContained = true;
        break;
      }
    }
    if (!isContained) {
      return false; // Se anche un solo poligono del primo multipoligono non è contenuto, restituisci falso
    }
  }
  
  return true; // Se tutti i poligoni del primo multipoligono sono contenuti, restituisci vero
}

// Funzione per estrarre i poligoni interni da un multipoligono
function extractPolygons(multiPolygon: Feature<MultiPolygon, any>) {
  const polygons: Feature<Polygon, any>[] = [];
  multiPolygon.geometry.coordinates.forEach(polygonCoordinates => {
    const p = polygon(polygonCoordinates);
    polygons.push(p);
  });
  return polygons;
}

export const toMultiPolygon = (feature: Feature<Polygon | MultiPolygon, any>): Feature<MultiPolygon, {}> => {
  switch (feature.geometry.type) {
    case 'Polygon':
      return multiPolygon([feature.geometry.coordinates]);
    case 'MultiPolygon':
      return multiPolygon(feature.geometry.coordinates);
  }
};

export const fromMultiPolygonToPolygons = (feature: Feature<MultiPolygon, any>): Feature<Polygon, any>[] => {
  return feature.geometry.coordinates.map(polygonToExtract => polygon(polygonToExtract));
};

export const fromPolygonsToMultiPolygon = (features: Feature<Polygon, any>[]): Feature<MultiPolygon, any> => {
  const coordinates = features.map(polygon => polygon.geometry.coordinates);
  return multiPolygon(coordinates);
};

export const perimeter = (feature: Feature<MultiPolygon, any>) => {
  let perimeter = 0;
  feature.geometry.coordinates.forEach(polygonCoords => {
    const polygonFeature = polygon(polygonCoords);
    perimeter += length(polygonToLine(polygonFeature));
  });
  
  return perimeter;
};

export const isCircle = (feature: Feature<MultiPolygon, any>) => {
  if (feature.geometry.type !== 'MultiPolygon' || feature.geometry.coordinates.length !== 1) {
    return false;
  }
  
  const exteriorRing = feature.geometry.coordinates[0][0];
  const centroid = centerOfMass(polygon([exteriorRing]));
  const radius = distance(centroid, point(exteriorRing[0]));
  const multipolygonArea = area(feature);
  const circleArea = Math.PI * radius * 1000 * radius * 1000;
  const tolerance = 0.01; // Adjust as needed
  
  const polygonPerimeter = perimeter(feature) * 1000;
  const circlePerimeter = 2 * Math.PI * radius * 1000;
  
  return (
    ((Math.abs(multipolygonArea - circleArea) / multipolygonArea) < tolerance) &&
    ((Math.abs(polygonPerimeter - circlePerimeter) / polygonPerimeter) < tolerance));
};