import {capitalize} from 'lodash';
import {Button} from 'antd';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

export default function ToAlertsStandardButton() {
  const {t} = useTranslation();
  return <Link to={'/alerts'}>
    <Button type={'primary'}>
      {capitalize(t('actions.goBackDestination', {destination: t('alerts.listMode')}))}
    </Button>
  </Link>;
}