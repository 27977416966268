import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useNavigate} from 'react-router-dom';
import ResponsiveContent from '../../../layout/ResponsiveContent';
import {Pagination, Table, Typography} from 'antd';
import {capitalize} from 'lodash';
import {useGetImportReportsQuery, useLazyGetImportReportsQuery} from '../../../redux/api/importReport';
import {ImportReport} from '../../../utils/dataImport';
import {ColumnsType} from 'antd/lib/table';
import {PaginationConfig} from 'antd/lib/pagination';
import {dateToLocaleString} from '../../../utils/dateParsers';
import {useGetUserProfileQuery} from '../../../redux/api/locales';
import EditButton from "../../../components/common/buttons/EditButton";

export default function ImportReportsSettings() {

  const {t} = useTranslation();
  const navigate = useNavigate();
  const [getImportReport, {data, isLoading, isFetching, isUninitialized}] = useLazyGetImportReportsQuery();
  const {data: lastReport} = useGetImportReportsQuery({page: 1, page_size: 1});
  const lastReportUuid = lastReport?.results.length ? lastReport.results[0].uuid : ""

  const reports = data?.results;
  const [pagination, setPagination] = useState<PaginationConfig>({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    showTotal: (total, range) => t('table.pageSizeOfTotal', {
      rangeStart: range[0],
      rangeEnd: range[1],
      total,
    }),
    onChange: (page, pageSize) => setPagination(prevPag => ({...prevPag, current: page, pageSize})),
    style: {textAlign: 'center'},
  });
  const isLoadingData = isLoading || isFetching || isUninitialized;

  useEffect(() => {
    if (pagination && getImportReport) {
      getImportReport({
        page: pagination.current,
        page_size: pagination.pageSize,
      });
    }
  }, [getImportReport, pagination]);

  const {
    data: userData,
  } = useGetUserProfileQuery();

  const columns: ColumnsType<ImportReport> = [
    {
      key: 'date_creation',
      dataIndex: 'date_creation',
      title: capitalize(t('dataImport.date')),
      render: value => userData?.language ? dateToLocaleString(value, userData?.language) : '',
    },
    {
      key: 'updated',
      dataIndex: 'updated',
      title: capitalize(t('dataImport.update', {count: 2})),
    },
    {
      key: 'created',
      dataIndex: 'created',
      title: capitalize(t('dataImport.creationNew', {count: 2})),
    },
    {
      key: 'errors',
      dataIndex: 'errors',
      title: capitalize(t('dataImport.errorNew', {count: 2})),
      render: value => (
        <Typography.Text type={'danger'}>
          {value}
        </Typography.Text>
      ),
    },
    {
      key: 'warnings',
      dataIndex: 'warnings',
      title: capitalize(t('dataImport.warningNew', {count: 2})),
      render: value => (
        <Typography.Text type={'warning'}>
          {value}
        </Typography.Text>
      ),
    },
  ];

  return <ResponsiveContent
    title={t('settings.dataImport')}
    goBack={() => navigate('/settings')}
    goBackText={t('settings.settings')}
    extra={<Link to={`${lastReportUuid}`}>
      <EditButton/>
  </Link>}
  >
    <Table
      <ImportReport>
      columns={columns}
      pagination={false}
      dataSource={reports}
      rowKey={'uuid'}
      loading={isLoadingData}
      scroll={{x: true}}
    />
    {!!data?.count && <Pagination
      {...pagination}
      total={data?.count}
    />}
  </ResponsiveContent>;
}