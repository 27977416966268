import React from 'react';
import AssetsTable from '../components/assets/AssetsTable/AssetsTable';
import {MapDrawer} from '../components/assets/assetsMap/MapDrawer';
import {AssetsMapContext} from '../context/assetsMap/AssetsMapContext';

export default function Assets() {
  return (
    <React.Fragment>
      <AssetsMapContext>
        <AssetsTable/>
        <MapDrawer/>
      </AssetsMapContext>
    </React.Fragment>
  );
}