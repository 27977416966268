import {Button, Col, Divider, Image, Modal, Row, Skeleton, Typography} from 'antd';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
import {dateToLocaleString} from '../../../../utils/dateParsers';
import {useGetUserProfileQuery} from '../../../../redux/api/locales';
import {DeleteOutlined, DownloadOutlined, EditOutlined} from '@ant-design/icons';
import {DocumentType} from '../../../../redux/api/documents';

const {Title, Text, Paragraph} = Typography;
export const ImageDetailsModal = ({
                                    image,
                                    open,
                                    setOpen,
                                    onDelete,
                                    onEdit,
                                    onDownload,
                                    withDelete,
                                    withDownload,
                                    withEdit,
                                  }: {
  image: DocumentType,
  open: boolean,
  setOpen: (open: boolean) => void | null,
  onDelete: () => void,
  onEdit: () => void,
  onDownload: () => void,
  withDelete?: boolean
  withDownload?: boolean,
  withEdit?: boolean,
}) => {

  const {t} = useTranslation();

  const {
    data: userData,
    isFetching: isFetchingUserData,
    isLoading: isLoadingUserData,
  } = useGetUserProfileQuery();

  return (
    <Modal
      open={open}
      centered={true}
      title={<>
        <Row>
          <Col>
            <Title level={5} style={{margin: 0}}>
              {image.name}
            </Title>
          </Col>
        </Row>
        <Row>
          <Col>
            <Text type={'secondary'} style={{fontWeight: 400}}>
              {(t('documents.uploadedBy', {name: image.user_creation}))}
            </Text>
          </Col>
        </Row>
      </>}
      onCancel={() => setOpen(false)}
      footer={
        <Row justify={'space-evenly'}>
          {withDelete && <Col>
            <Button
              type={'text'}
              style={{color: 'rgba(0, 0, 0, 0.45)'}}
              onClick={() => {
                onDelete();
                setOpen(false);
              }}
              icon={<DeleteOutlined/>}
            />
          </Col>}
          <Col>
            <Divider type={'vertical'}/>
          </Col>
          {withDownload && <Col>
            <Button
              type={'text'}
              style={{color: 'rgba(0, 0, 0, 0.45)'}}
              onClick={() => {
                onDownload();
                setOpen(false);
              }}
              icon={<DownloadOutlined/>}
            />
          </Col>}
          <Col>
            <Divider type={'vertical'}/>
          </Col>
          {withEdit && <Col>
            <Button
              type={'text'}
              style={{color: 'rgba(0, 0, 0, 0.45)'}}
              onClick={() => {
                onEdit();
                setOpen(false);
              }}
              icon={<EditOutlined/>}
            />
          </Col>}
        </Row>
      }
    >
      {(isFetchingUserData || isLoadingUserData) && (
        <Skeleton active={true}/>
      )}
      {!(isFetchingUserData || isLoadingUserData) && userData && (
        <>
          <Divider/>
          <Row style={{marginTop: '1.5rem', marginBottom: '1.5rem'}}>
            <Col span={12}>
              <Text strong={true}>
                {capitalize(t('documents.dateCreation'))}
              </Text>
            </Col>
            <Col span={12}>
              <Text>
                {dateToLocaleString(image.date_creation, userData.language)}
              </Text>
            </Col>
          </Row>
          <Row style={{marginBottom: '1.5rem'}}>
            <Col span={12}>
              <Text strong={true}>
                {capitalize(t('documents.imageDescription'))}
              </Text>
            </Col>
            {image.description && (
              <Col span={24}>
                <Paragraph style={{marginBottom: 0, marginTop: '0.5rem'}}>
                  {image.description}
                </Paragraph>
              </Col>
            )}
            {!image.description && (
              <Col span={12}>
                <Text type={'secondary'}>
                  n/a
                </Text>
              </Col>
            )}
          </Row>
          <Row justify={'center'}>
            <Col>
              <Image src={image.url} alt={image.name}/>
            </Col>
          </Row>
          <Divider/>
        </>
      )}

    </Modal>
  );
};