import {capitalize} from "lodash";
import {TemplateMailEditor} from "../../../settings/templateMail/TemplateMailEditor";
import {Form} from "antd";
import {useTranslation} from "react-i18next";

export default function CommunicationBody() {

  const {t} = useTranslation()

  return <div style={{height: '26rem'}}>
    <Form.Item
      required={true}
      label={capitalize(t('events.communicationBody'))}
      rules={[
        {
          required: true,
          message: capitalize(t('form.requiredField', {field: t('events.communicationBody')})),
          validator: (_, value) => {
            if (!value || value.trim() === '') {
              return Promise.reject();
            }
            return Promise.resolve();
          },
        }]}
      name={'body'}
    >
      <TemplateMailEditor reactQuillProps={{
        style: {height: '20rem'},
        modules: {}
      }}/>
    </Form.Item>
  </div>
}