import {createContext, PropsWithChildren, useCallback, useContext, useEffect, useState} from 'react';
import {RealEstateHub} from '../../types/internal/RealEstateHub';
import {ContactPerson} from '../../types/api/contactPerson';
import {useLazyGetRealEstateHubsByUuidQuery} from '../../redux/api/realEstateHub';
import {useLazyGetContactsByUuidsQuery} from '../../redux/api/contacts';
import {useAssetDetails} from './AssetDetails';
import {Company} from '../../types/internal/Companies';
import {useLazyGetCompaniesNotPaginatedQuery} from '../../redux/api/company';
import {AssetModel} from '../../types/internal/AssetModel';
import {useLazyGetAssetQuery} from '../../redux/api/asset';

export type AdditionalAssetDataType = {
  realestateHub: RealEstateHub,
  contact_people: ContactPerson[],
  companies: Company[],
  master_asset: AssetModel | null,
}

type AdditionalAssetDataContextType = [
  additionalData: AdditionalAssetDataType,
  loading: boolean,
];

const DEFAULT_ASSET_ADDITIONAL_DATA: AdditionalAssetDataType = {
  realestateHub: {
    uuid: '',
    name: '',
    description: null,
    short_name: null,
  },
  contact_people: [],
  companies: [],
  master_asset: null,
};

const AdditionalAssetData = createContext<AdditionalAssetDataContextType>([
  DEFAULT_ASSET_ADDITIONAL_DATA,
  false,
]);

export const useAdditionalAssetData = () => useContext<AdditionalAssetDataContextType>(AdditionalAssetData);

export const AdditionalAssetDataContext = (props: PropsWithChildren) => {

  const [, {asset, loading}] = useAssetDetails();

  const [
    additionalData,
    setAdditionalData,
  ] = useState<AdditionalAssetDataType>(DEFAULT_ASSET_ADDITIONAL_DATA);

  const [
    isFetchingAdditionalData,
    setIsFetchingAdditionalData,
  ] = useState(false);

  const [getRealEstateHub] = useLazyGetRealEstateHubsByUuidQuery();
  const [getContactPerson] = useLazyGetContactsByUuidsQuery();
  const [getCompanies] = useLazyGetCompaniesNotPaginatedQuery();
  const [getMasterAsset] = useLazyGetAssetQuery();

  const handleFetchAdditionalAssetData = useCallback(async () => {
    setIsFetchingAdditionalData(true);
    if (asset.contact_people.length > 0) {
      const contact_people = await getContactPerson({uuids: asset.contact_people}).unwrap();
      setAdditionalData(additionalData => ({...additionalData, contact_people: contact_people}));
    }
    if (asset.realestate_hub) {
      const realestate_hub = await getRealEstateHub({uuid: asset.realestate_hub}).unwrap();
      setAdditionalData(additionalData => ({...additionalData, realestateHub: realestate_hub}));
    }
    if (asset.companies.length > 0) {
      const companies = await getCompanies({uuids: asset.companies}).unwrap();
      setAdditionalData(additionalData => ({...additionalData, companies: companies}));
    }
    if (asset.master_asset) {
      const master_asset = await getMasterAsset({uuid: asset.master_asset}).unwrap();
      setAdditionalData(additionalData => ({...additionalData, master_asset: master_asset}));
    }
    setIsFetchingAdditionalData(false);
  }, [asset.companies, asset.contact_people, asset.master_asset, asset.realestate_hub, getCompanies, getContactPerson, getMasterAsset, getRealEstateHub]);

  useEffect(() => {
    if (asset.uuid) {
      handleFetchAdditionalAssetData();
    }
  }, [asset.uuid, handleFetchAdditionalAssetData]);

  return (
    <AdditionalAssetData.Provider
      value={
        [
          additionalData,
          isFetchingAdditionalData ||
          loading,
        ]
      }
    >
      {props.children}
    </AdditionalAssetData.Provider>
  );
};