import {AssetReport, ImportReportDetail} from '../../../../utils/dataImport';
import {Col, Pagination, Row, Table} from 'antd';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useLazyGetImportReportQuery} from '../../../../redux/api/importReport';
import {useNavigate, useParams} from 'react-router-dom';
import ImportReportDetailModal from '../ImportReportDetailModal';
import {ColumnsType} from 'antd/lib/table';
import _, {capitalize} from 'lodash';
import {getTranslationAssetTableColumnKey} from '../../../../utils/translation';
import {
  selectImportReportOk,
  setImportReportOkTableFilters,
  setImportReportOkTablePagination,
  setImportReportOkTableSorters,
} from '../../../../redux/slices/importReport';
import {DocumentsTableColumnHeader} from '../../../../components/documents/TablesViews/DocumentsTableColumnHeader';
import TableSorter from '../../../../components/common/TableSorter';
import {ImportReportsAssetFilters} from './filters/ImportReportsAssetFilters';
import {fromFeToBeSorter} from '../../../../utils/parser';
import {ImportReportsOperationFilter} from './filters/ImportReportsOperationFilter';
import {ImportReportsCountyFilter} from './filters/ImportReportsCountyFilter';
import {ImportReportAddressSearchFilter} from './filters/ImportReportAddressSearchFilter';

export const ImportReportOkTableView = () => {

  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {uuid: reportUuid} = useParams();

  const [
    modalData,
    setModalData,
  ] = useState<null | { asset: AssetReport, detail: string }>(null);

  const pagination = useSelector(selectImportReportOk).pagination;
  const filters = useSelector(selectImportReportOk).filters;
  const sorters = useSelector(selectImportReportOk).sorters;

  const [
    getData,
    {
      data,
      isLoading,
      isFetching,
    },
  ] = useLazyGetImportReportQuery();

  useEffect(() => {
    if (reportUuid) {
      getData({
        page: pagination.current,
        page_size: pagination.pageSize,
        report_uuid: reportUuid,
        status: 'OK',
        ...(filters.sigi_code ? {
          sigi_code: filters.sigi_code.map(asset => asset.value).join(','),
        } : {}),
        ...(filters.admin_level_1 ? {
          adm_level_1: filters.admin_level_1.map(option => option.value).join(','),
        } : {}),
        ...(filters.admin_level_3 ? {
          adm_level_3: filters.admin_level_3.map(option => option.value).join(','),
        } : {}),
        ...(filters.admin_level_2 ? {
          adm_level_2: filters.admin_level_2.map(option => option.value).join(','),
        } : {}),
        ...(filters.address ? {
          address: filters.address.map(option => option.value).join(','),
        } : {}),
        ...(filters.country ? {
          country: filters.country.map(option => option.value).join(','),
        } : {}),
        ...(filters.operation ? {
          operation: filters.operation.map(asset => asset.value).join(','),
        } : {}),
        ...(!_.isEmpty(fromFeToBeSorter(sorters)) ? {
          ordering: fromFeToBeSorter(sorters),
        } : {}),
      });
    }
  }, [filters.address, filters.admin_level_1, filters.admin_level_2, filters.admin_level_3, filters.country, filters.operation, filters.sigi_code, getData, pagination, reportUuid, sorters]);

  const columns: ColumnsType<ImportReportDetail> = [
    {
      title: (
        <DocumentsTableColumnHeader
          title={capitalize(t(getTranslationAssetTableColumnKey('sigi_code')))}
          sorter={<TableSorter
            value={sorters.sigi_code}
            onChange={type => dispatch(setImportReportOkTableSorters({sigi_code: type}))}
          />}
          filter={<ImportReportsAssetFilters
            activeFilters={filters.sigi_code}
            handleFilterChange={(values) => dispatch(setImportReportOkTableFilters({sigi_code: values}))}
          />}
          onFilterReset={() => dispatch(setImportReportOkTableFilters({sigi_code: null}))}
          isFilterDisabled={_.isEmpty(filters.sigi_code)}
        />
      ),
      key: 'sigi_code',
      dataIndex: ['asset', 'sigi_code'],
    },
    {
      key: 'country',
      title: (
        <DocumentsTableColumnHeader
          title={capitalize(t(getTranslationAssetTableColumnKey('country')))}
          sorter={<TableSorter
            value={sorters.country}
            onChange={type => dispatch(setImportReportOkTableSorters({country: type}))}
          />}
          filter={<ImportReportsCountyFilter
            activeFilters={filters.country}
            removeCountry={(countryToRemove) => dispatch(setImportReportOkTableFilters({country: filters.country?.filter(country => country.value !== countryToRemove)}))}
            addCountry={(countryToAdd) => dispatch(setImportReportOkTableFilters({country: [...(filters.country ? filters.country : []), countryToAdd]}))}
          />}
          onFilterReset={() => dispatch(setImportReportOkTableFilters({country: null}))}
          isFilterDisabled={_.isEmpty(filters.country)}
        />
      ),
      dataIndex: ['asset', 'country'],
    },
    {
      key: 'admin_level_1',
      title: (
        <DocumentsTableColumnHeader
          title={capitalize(t(getTranslationAssetTableColumnKey('admin_level_1')))}
          sorter={<TableSorter
            value={sorters.admin_level_1}
            onChange={type => dispatch(setImportReportOkTableSorters({admin_level_1: type}))}
          />}
          filter={<ImportReportAddressSearchFilter
            field={'admin_level_1'}
            activeFilters={filters.admin_level_1}
            handleFilterChange={values => dispatch(setImportReportOkTableFilters({admin_level_1: values}))}
          />}
          isFilterDisabled={_.isEmpty(filters.admin_level_1)}
          onFilterReset={() => dispatch(setImportReportOkTableFilters({admin_level_1: null}))}
        />
      ),
      dataIndex: ['asset', 'admin_level_1'],
      render: (value) => <div style={{textTransform: 'capitalize'}}>{value ? value.toLowerCase() : ''}</div>,
    },
    {
      key: 'admin_level_3',
      title: (
        <DocumentsTableColumnHeader
          title={capitalize(t(getTranslationAssetTableColumnKey('admin_level_3')))}
          sorter={<TableSorter
            value={sorters.admin_level_3}
            onChange={type => dispatch(setImportReportOkTableSorters({admin_level_3: type}))}
          />}
          filter={<ImportReportAddressSearchFilter
            field={'admin_level_3'}
            activeFilters={filters.admin_level_3}
            handleFilterChange={values => dispatch(setImportReportOkTableFilters({admin_level_3: values}))}
          />}
          isFilterDisabled={_.isEmpty(filters.admin_level_3)}
          onFilterReset={() => dispatch(setImportReportOkTableFilters({admin_level_3: null}))}
        />
      ),
      dataIndex: ['asset', 'admin_level_3'],
      render: (value) => <div style={{textTransform: 'capitalize'}}>{value ? value.toLowerCase() : ''}</div>,
    },
    {
      key: 'admin_level_2',
      title: (
        <DocumentsTableColumnHeader
          title={capitalize(t(getTranslationAssetTableColumnKey('admin_level_2')))}
          sorter={<TableSorter
            value={sorters.admin_level_2}
            onChange={type => dispatch(setImportReportOkTableSorters({admin_level_2: type}))}
          />}
          filter={<ImportReportAddressSearchFilter
            field={'admin_level_2'}
            activeFilters={filters.admin_level_2}
            handleFilterChange={values => dispatch(setImportReportOkTableFilters({admin_level_2: values}))}
          />}
          isFilterDisabled={_.isEmpty(filters.admin_level_2)}
          onFilterReset={() => dispatch(setImportReportOkTableFilters({admin_level_2: null}))}
        />
      ),
      dataIndex: ['asset', 'admin_level_2'],
    },
    {
      key: 'address',
      title: (
        <DocumentsTableColumnHeader
          title={capitalize(t(getTranslationAssetTableColumnKey('address')))}
          sorter={<TableSorter
            value={sorters.address}
            onChange={type => dispatch(setImportReportOkTableSorters({address: type}))}
          />}
          filter={<ImportReportAddressSearchFilter
            field={'address'}
            activeFilters={filters.address}
            handleFilterChange={values => dispatch(setImportReportOkTableFilters({address: values}))}
          />}
          isFilterDisabled={_.isEmpty(filters.address)}
          onFilterReset={() => dispatch(setImportReportOkTableFilters({address: null}))}
        />
      ),
      dataIndex: ['asset', 'address'],
      render: (value) => <div style={{textTransform: 'capitalize'}}>{value ? value.toLowerCase() : ''}</div>,
    },
    {
      key: 'operation',
      title: (
        <DocumentsTableColumnHeader
          title={capitalize(t('dataImport.operation'))}
          sorter={<TableSorter
            value={sorters.operation}
            onChange={type => dispatch(setImportReportOkTableSorters({operation: type}))}
          />}
          filter={<ImportReportsOperationFilter
            activeFilters={filters.operation}
            handleFilterChange={values => dispatch(setImportReportOkTableFilters({operation: values}))}
          />}
          onFilterReset={() => dispatch(setImportReportOkTableFilters({operation: null}))}
          isFilterDisabled={_.isEmpty(filters.operation)}
        />
      ),
      dataIndex: 'operation',
      render: value => {
        switch (value) {
          case 'CREATE':
            return capitalize(t('dataImport.creation', {count: 1}));
          case 'UPDATE':
            return capitalize(t('dataImport.update', {count: 1}));
          default:
            return '';
        }
      },
    },
  ];

  return (<>
    {!!modalData && !!reportUuid && (
      <ImportReportDetailModal
        detailUuid={modalData?.detail}
        reportUuid={reportUuid}
        modal={{
          open: !!modalData && !!reportUuid,
          onCancel: () => setModalData(null),
        }}
        assetButton={{
          onClick: () => navigate(`/assets/${modalData?.asset.uuid}/edit`),
        }}
        closeModalButton={{
          onClick: () => setModalData(null),
        }}
        title={{
          children: modalData?.asset.sigi_code,
        }}
      />
    )}
    <Row>
      <Col flex={1}>
        <Table
          <ImportReportDetail>
          columns={columns}
          pagination={false}
          dataSource={data?.results || []}
          rowKey={'uuid'}
          loading={isLoading || isFetching}
          scroll={{x: true}}
          onRow={(record) => (
            {
              onClick: (e) => {
                setModalData({detail: record.uuid, asset: record.asset});
              },
              style: {
                cursor: 'pointer',
              },
            }
          )
          }
        />
      </Col>
    </Row>
    <Row
      style={{marginTop: '1rem'}}
      justify={'center'}
    >
      <Col>
        <Pagination
          disabled={!data?.count}
          showSizeChanger={true}
          total={data?.count}
          showTotal={(total, range) => t('table.pageSizeOfTotal', {
            rangeStart: range[0],
            rangeEnd: range[1],
            total,
          })}
          onChange={(current, pageSize) => {
            dispatch(setImportReportOkTablePagination({current, pageSize}));
          }}
          {...pagination}
        />
      </Col>
    </Row>
  </>);
};