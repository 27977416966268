import SimpleBackgroundLayout from '../layout/SimpleBackgroundLayout';
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {resetAccessToken} from '../redux/slices/auth';
import {removeRefreshToken} from '../components/Authentication/authUtils';
import {Spin} from "antd";

export default function Logout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authRedirectUrl = process.env.REACT_APP_AUTHENTICATION_REDIRECT_URL

  useEffect(() => {
    dispatch(resetAccessToken());
    removeRefreshToken();
    if (authRedirectUrl) {
      window.location.replace(authRedirectUrl);
    } else {
      navigate('/login');
    }
  }, [dispatch, navigate, authRedirectUrl]);

  return <SimpleBackgroundLayout>
    <Spin size={'large'}/>
  </SimpleBackgroundLayout>;
}