import {Button, ButtonProps} from 'antd';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import {useDeleteAlertMutation} from '../../redux/api/alerts';
import {useMessageContext} from '../../context/message/MessageContext';
import React, {useState} from 'react';
import DeleteModalMessage from '../common/documents/modals/DeleteModalMessage';
import {isAlertDelete} from '../Authentication/permissions';
import PermissionRoute from '../Authentication/PermissionRoute';

export default function DeleteAlertButton({selectedAlerts, afterDelete, button}: {
  afterDelete?: () => void,
  selectedAlerts: React.Key[],
  button?: ButtonProps
}) {

  const {t} = useTranslation();
  const [deleteAlert] = useDeleteAlertMutation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const setMessage = useMessageContext();

  const handleDeleteAlerts = async () => {
    try {
      const promises = selectedAlerts.map(uuid => deleteAlert({uuid: uuid as string}).unwrap());
      await Promise.all(promises);
      setMessage({success: capitalize(t('form.deleteSuccessMessage'))});
    } catch {
      setMessage({error: capitalize(t('form.deleteFailMessage'))});
    } finally {
      if (afterDelete)
        afterDelete();
    }
  };

  return <PermissionRoute
    isAuthorized={isAlertDelete}
  >
    <DeleteModalMessage
      title={{
        children: capitalize(t('actions.deleteItem', {item: t('alerts.alert', {count: 1})})),
      }}
      paragraph={{
        children: t('alerts.delete'),
      }}
      modal={{
        open: isModalOpen,
        onCancel: () => setIsModalOpen(false),
      }}
      confirmButton={{
        children: capitalize(t('actions.delete')),
        onClick: handleDeleteAlerts,
      }}
    />
    <Button
      danger
      type={'primary'}
      onClick={() => setIsModalOpen(true)}
      {...button}>
      {capitalize(t('actions.deleteItem', {item: t('alerts.alert')}))}
    </Button>
  </PermissionRoute>;
}