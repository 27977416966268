import {useTranslation} from "react-i18next";
import {EVENT_STATES} from "../../../types/api/getEventsResult";
import {Tag} from "antd";
import {capitalize} from "lodash";
import React from "react";

export default function EventStateTag({eventState}: { eventState: EVENT_STATES }) {
  const {t} = useTranslation()

  return <>
    {eventState === EVENT_STATES.Forecast && <Tag color={'warning'}>{capitalize(t('events.forecast'))}</Tag>}
    {eventState === EVENT_STATES.Happened && <Tag color={'red'}>{capitalize(t('events.happened'))}</Tag>}
    {(eventState === EVENT_STATES.Closed || eventState === EVENT_STATES["Not Happened"]) &&
      <Tag color={'blue'}>{capitalize(t('events.archived'))}</Tag>}
  </>
}