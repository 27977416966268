import AssetsTableFilter from './AssetsTableFilter';
import {Col, Row, Space} from 'antd';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import {getTranslationAssetTableColumnKey} from '../../../utils/translation';
import {useDispatch, useSelector} from 'react-redux';
import {addSorters, removeSorters, selectAssetsTableSorters} from '../../../redux/slices/assets';
import TableSorter from '../../common/TableSorter';
import {AssetsTableStaticColumn, isAssetsTableStaticColumn} from '../../../types/internal/tables/assetsTable';
import {useGetHazardTypeClassesQuery} from '../../../redux/api/hazardTypes';

export default function AssetsTableColumnHeader({column}: { column: AssetsTableStaticColumn | string }) {

  const {t} = useTranslation();
  const dispatch = useDispatch();
  const currentSorters = useSelector(selectAssetsTableSorters);

  let filter = <></>;
  let sorter = <></>;
  let title = '';

  const {
    data: hazardTypes,
  } = useGetHazardTypeClassesQuery();

  if (isAssetsTableStaticColumn(column)) {
    title = t(getTranslationAssetTableColumnKey(column));
  } else {
    title = hazardTypes?.find(ht => ht.code === column)?.name || '';
  }

  switch (column) {
    case 'map_view':
      break;
    default:
      sorter = <TableSorter
        onChange={(type) => {
          if (type) {
            dispatch(addSorters({[column]: type || undefined}));
          } else {
            dispatch(removeSorters(column));
          }
        }}
        value={currentSorters[column] || null}
      />;
      filter = <AssetsTableFilter column={column}/>;
      break;
  }

  return <Row align={'middle'} wrap={false}>
    <Col flex={1} style={{marginRight: '0.5rem'}}>
      {capitalize(title)}
    </Col>
    <Col>
      <Space>
        {sorter}
        {filter}
      </Space>
    </Col>
  </Row>;
}