import {useState} from 'react';
import {capitalize} from 'lodash';
import {Col, Divider, Modal, Radio, Row, Space, Typography} from 'antd';
import {useTranslation} from 'react-i18next';
import {LocalizedDatePicker} from '../../common/LocalizedDatePicker';
import {useEventsContext} from "../../../context/emergenciesMap/EventsContext";
import {REDUCED_STATES} from "../../../types/api/getEventsResult";
import SelectEventTypes from "../../alerts/creation/SelectEventTypes";
import EventsMapFilterButton from "./EventsMapFilterButton";

const {Text} = Typography;
export const EventsFilterModal = () => {

  const {t} = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {filtersFE, setFiltersFE, reset} = useEventsContext()

  return (
    <>
      <Modal
        title={capitalize(t('emergency.filterEmergencies'))}
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={false}
        centered={true}
      >
        <Divider style={{margin: '1rem 0'}}/>
        <Row>
          <Col>
            <Text strong={true}>
              {capitalize(t('emergency.events'))}
            </Text>
          </Col>
        </Row>
        <Row style={{marginLeft: '1rem'}}>
          <Col>
            <Radio.Group
              value={filtersFE.reduced_state || 'ALL'}
              onChange={(v) => {
                const value = v.target.value
                if (value === 'ALL') {
                  setFiltersFE(prevState => ({...prevState, reduced_state: null}))
                } else {
                  setFiltersFE(prevState => ({...prevState, reduced_state: value}))
                }
                reset()
              }}
            >
              <Space direction="vertical">
                <Radio value={REDUCED_STATES.active}>{capitalize(t('emergency.active'))}</Radio>
                <Radio value={REDUCED_STATES.archived}>{capitalize(t('emergency.archived'))}</Radio>
                <Radio value={'ALL'}>{capitalize(t('emergency.allEvents'))}</Radio>
              </Space>
            </Radio.Group>
          </Col>
        </Row>
        <Divider style={{margin: '1rem 0'}}/>
        <Row>
          <Col>
            <Text strong={true}>
              {capitalize(t('emergency.eventType'))}
            </Text>
          </Col>
        </Row>
        <Row>
          <Col flex={1}>
            <SelectEventTypes
              value={filtersFE.event_type}
              style={{width: '100%'}}
              mode={'multiple'}
              onSelect={(value, option) => {
                setFiltersFE(prevState => ({...prevState, event_type: [...prevState.event_type, value]}))
                reset()
              }}
              onDeselect={(value, option) => {
                setFiltersFE(prevState => ({...prevState, event_type: prevState.event_type.filter(et => et !== value)}))
                reset()
              }}
            />
          </Col>
        </Row>
        <Divider style={{margin: '1rem 0'}}/>
        <Row>
          <Col>
            <Text strong={true}>
              {capitalize(t('emergency.startDate'))}
            </Text>
          </Col>
        </Row>
        <Row>
          <Col flex={1}>
            <LocalizedDatePicker
              value={filtersFE.start_datetime_after}
              onChange={v => {
                setFiltersFE(prevState => ({...prevState, start_datetime_after: v}))
                reset()
              }}
              style={{width: '100%'}}/>
          </Col>
        </Row>
        <Row style={{marginTop: '1rem'}}>
          <Col>
            <Text strong={true}>
              {capitalize(t('emergency.endDate'))}
            </Text>
          </Col>
        </Row>
        <Row>
          <Col flex={1}>
            <LocalizedDatePicker
              value={filtersFE.start_datetime_before}
              onChange={v => {
                setFiltersFE(prevState => ({...prevState, start_datetime_before: v}))
                reset()
              }
            }
              style={{width: '100%'}}
            />
          </Col>
        </Row>
      </Modal>
      <EventsMapFilterButton
        onClick={() => setIsModalOpen(true)}
      />
    </>
  );
};