import {CSSProperties, ReactNode} from 'react';
import {Company} from './Companies';
import {ContactPerson} from '../api/contactPerson';
import {PopoverProps} from 'antd';
import {PaginatedApi} from '../api/paginatedApi';

export enum GoogleLayers {
  roadmap = 'roadmap',
  satellite = 'satellite',
  terrain = 'terrain',
  hybrid = 'hybrid',
}

export type MapControlsProps = {
  withZoom?: boolean,
  withLayerPicker?: boolean,
  withBaseLayers?: GoogleLayers[],
  withWMSLayers?: WMSLayer[],
  withGeoSearch?: boolean,
  withPopoverConfiguration?: PopoverProps,
  withLegend?: boolean,
  withQuerableLayers?: boolean,
  withStreetView?: boolean,
  withAssetFilterFromLayer?: boolean,
  withScreenshot?: boolean,
  withSoftLoading?: boolean,
}

export type BaseMapProps = {
  children?: ReactNode,
  withInitialZoom?: number,
  withCenter?: L.LatLng,
  withStyle?: CSSProperties,
  isStandAlone?: boolean,
  withAssetFilterFromLayer?: boolean,
} & MapControlsProps;

export type WMSLayer = {
  uuid: string,
  additional_info: object,
  description: string,
  frontend_name: string,
  frontend_url: string,
  is_active: boolean,
  is_internal: boolean,
  layer_source: string,
  name: string,
  wms_baseurl: string,
  has_polygons: boolean,
};

export type CreateExtLayer = {
  description: string | null,
  frontend_name: string,
  frontend_url: string,
  layer_source: string
}

export type CreateReiLayer = {
  description: string | null,
  frontend_name: string,
  layer_file: string
  layer_source: string,
}

export type PatchLayer = Partial<Pick<WMSLayer, 'uuid' | 'description' | 'frontend_name' | 'frontend_url' | 'is_active' | 'layer_source'>>

export interface GetWMSLayer extends PaginatedApi {
  results: WMSLayer[];
}

export type Marker = {
  uuid: string,
  position: {
    lat: number,
    lng: number,
  },
}

export type MarkerOrNull = {
  uuid: string,
  position: {
    lat: number,
    lng: number,
  } | null,
}
export type AssetDetails = {
  uuid: string | null,
  sigi_code: string | null,
  address: string | null,
  admin_level_1: string | null,
  admin_level_3: string | null,
  surface_total: number | null,
  surface_basement: number | null,
  ownership: string | null,
  total_resources: number | null,
  master_asset: string | null,
  companies: Company[],
  contact_people: ContactPerson[]
}

export type GetRasterLegendGraphicResponse = {
  Legend: {
    layerName: string,
    title: string,
    rules: {
      symbolizers: {
        Raster: {
          colormap: { entries: { label: string, quantity: string, color: string }[], type: string },
          opacity: string
        }
      }[]
    }[]
  }[]
}

export type GetPolygonLegendGraphicResponse = {
  Legend: {
    layerName: string,
    title: string,
    rules: {
      name: string,
      abstract: string,
      title: string,
      symbolizers: {
        Polygon: {
          stroke: string,
          'stroke-width': number,
          'stroke-opacity': string,
          'stroke-linecap': string,
          'stroke-linejoin': string,
          fill: string,
          'fill-opacity': string
        }
      }[]
    }[]
  }[]
}