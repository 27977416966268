import {Tabs, TabsProps} from "antd";
import {useMemo} from "react";
import {capitalize} from "lodash";
import {useTranslation} from "react-i18next";

export enum CommunicationStatusTabs {
  draft = 'draft',
  sent = 'sent',
  sentAndReceived = 'sentAndReceived',
}

export type AssetTabs = CommunicationStatusTabs.draft | CommunicationStatusTabs.sentAndReceived
export type ClusterTabs = CommunicationStatusTabs.draft | CommunicationStatusTabs.sent


export default function CommunicationTabs({withReceived = true, tabs}: { withReceived?: boolean, tabs?: TabsProps }) {

  const {t} = useTranslation()
  const items: TabsProps['items'] = useMemo(() => [
    {
      key: CommunicationStatusTabs.draft,
      label: capitalize(t('events.communicationDraft', {count: 2})),
    },
    ...withReceived ? [{
      key: CommunicationStatusTabs.sentAndReceived,
      label: capitalize(t('events.communicationSentReceived')),
    }] : [{
      key: CommunicationStatusTabs.sent,
      label: capitalize(t('events.communicationSent', {count: 2})),
    }],
  ], [t, withReceived]);

  return <Tabs
    type={'card'}
    items={items}
    {...tabs}
  />
}