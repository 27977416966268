import {Button, Checkbox, message, Modal, Typography} from 'antd';
import React, {useState} from 'react';
import {useAlertContext} from '../../../../context/alertDetails/AlertDetails';
import {useUpdateShakemapMutation} from '../../../../redux/api/alerts';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';

const {Paragraph} = Typography;
const UpdateShakemapButton = () => {
  const {t} = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const [alert] = useAlertContext();
  
  const [isOpen, setIsOpen] = useState(false);
  const [willSendCommunication, setWillSendCommunication] = useState(false);
  
  const [
    updateShakemap,
    {
      isLoading: isUpdatingShakemap,
    },
  ] = useUpdateShakemapMutation();
  return (
    <>
      {contextHolder}
      <Modal
        open={isOpen}
        title={capitalize(t('alerts.updatePolygons'))}
        okText={capitalize(t('actions.update'))}
        okButtonProps={{loading: isUpdatingShakemap, disabled: isUpdatingShakemap, danger: true}}
        onOk={() => {
          if (alert?.uuid) {
            updateShakemap({alert_uuid: alert.uuid, send_communication: willSendCommunication})
              .unwrap()
              .then(res => messageApi.open({type: 'success', content: res.msg}))
              .catch(e => messageApi.open({type: 'error', content: e.data.msg}))
              .finally(() => setIsOpen(false));
          }
        }}
        onCancel={() => {
          setWillSendCommunication(false);
          setIsOpen(false);
        }}
      >
        <Paragraph style={{marginBottom: '0'}}>
          {t('alerts.confirmUpdateShakemap1')}
        </Paragraph>
        <Paragraph style={{marginBottom: '0'}}>
          {t('alerts.confirmUpdateShakemap2')}
        </Paragraph>
        <Paragraph style={{marginBottom: '0'}}>
          {t('alerts.confirmUpdateShakemap3')}
        </Paragraph>
        <Checkbox
          onChange={(e) => setWillSendCommunication(e.target.checked)} checked={willSendCommunication}
          style={{marginTop: '1rem'}}
        >{capitalize(t('alerts.sendCommunications'))}</Checkbox>
      </Modal>
      <Button
        loading={isUpdatingShakemap}
        disabled={isUpdatingShakemap}
        danger={true}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {capitalize(t('alerts.updatePolygons'))}
      </Button>
    </>
  );
};

export default UpdateShakemapButton;