import React, {createContext, PropsWithChildren, useContext, useState} from 'react';
import {AssetRealEstate, RealEstateContextType} from '../../types/internal/AddAssetForm';

const RealEstateContext = createContext<RealEstateContextType>(
  [
    {
      realestate_hub: null,
      contact_people: [],
    },
    () => null,
  ],
);

export const useAssetRealEstateContext = () => useContext(RealEstateContext);

export const AssetRealEstateContext = (props: PropsWithChildren) => {
  const [realestateDetails, setRealestateDetails] = useState<AssetRealEstate>({
    realestate_hub: null,
    contact_people: [],
  });

  return (
    <RealEstateContext.Provider value={[realestateDetails, setRealestateDetails]}>
      {props.children}
    </RealEstateContext.Provider>
  );
};