import {Button, Card, Col, Empty, Pagination, Row, Skeleton, Space, Tag, Typography} from 'antd';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import React, {useCallback, useEffect, useState} from 'react';
import {useGetAlertGroupsPaginatedQuery} from "../../../redux/api/alerts";
import {PaginationConfig} from "antd/lib/pagination";
import {AlertGroup} from "../../../types/api/alerts";
import EventTypeFromUuid from "../../common/icons/EventTypeFromUuid";
import DateTimeFormat from "../../../locales/DateTimeFormat";
import {translateLevelToText} from "../../../utils/assets";
import {VALUE_NOT_PRESENT} from "../../../utils/translation";
import AlertsSuggestionTable from "./AlertsSuggestionTable";
import ArchiveAlertGroup from "./ArchiveAlertGroup";
import AddAlertGroupToEvent from "./AddAlertGroupToEvent";
import AddAlertGroupToSpecificEvent from "./AddAlertGroupToSpecificEvent";
import CreateEventFromAlertGroup from "./CreateEventFromAlertGroup";
import {GenericPaginatedParamsApi} from "../../../types/api/paginatedApi";

function AlertSuggestionItem({alertGroup, alterGroupParams, refetchList}: {
  alertGroup: AlertGroup,
  alterGroupParams: GenericPaginatedParamsApi,
  refetchList: () => void
}) {

  const {t} = useTranslation();
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  return <Card
    title={<Row justify={'space-between'} align={'middle'} wrap style={{margin: '0.75rem 0'}}>
      <Col>
        <Space align={'center'} wrap>
          <EventTypeFromUuid
            uuid={alertGroup.event_type}
            iconStyle={{width: '1.5rem', height: '1.5rem'}}
          />
          {alertGroup.event ? <Tag color={'geekblue'}>
            {capitalize(t('alerts.hasEvent'))}
          </Tag> : <Tag color={'red'}>
            {capitalize(t('alerts.hasNotEvent'))}
          </Tag>}
        </Space>
      </Col>
      <Col>
        <Space wrap align={'center'}>
          <ArchiveAlertGroup
            alertGroupUuid={alertGroup.uuid}
            afterArchive={refetchList}
          />
          {!alertGroup.event && <>
            <AddAlertGroupToEvent
              alertGroup={alertGroup}
              onFinish={refetchList}
            />
            <CreateEventFromAlertGroup
              alertGroupUuid={alertGroup.uuid}
              onFinish={refetchList}
            />
          </>}
          {alertGroup.event && <AddAlertGroupToSpecificEvent
            alertGroup={alertGroup}
            onFinish={refetchList}
          />}
        </Space>
      </Col>
    </Row>
    }
  >
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <Typography.Text>{capitalize(t('alerts.first'))}: <DateTimeFormat>{alertGroup.first_alert_datetime}</DateTimeFormat></Typography.Text>
      <Typography.Text>{capitalize(t('alerts.geoArea'))}: {alertGroup.geographic_area.length ? alertGroup.geographic_area.map(el => `${el.name} (${capitalize(t(translateLevelToText(el.level)))})`).join(' - ') : VALUE_NOT_PRESENT}</Typography.Text>
      <Typography.Text
        strong>{capitalize(t('alerts.involvedAssetsPotentially'))}: {alertGroup.involved_assets}</Typography.Text>
    </div>
    <Space
      align={'center'}
      style={{margin: '1rem 0'}}
    >
      <Typography.Text
        style={{fontSize: '1.25rem'}}>{alertGroup.alerts_count} {t('alerts.aggregateAlerts')}</Typography.Text>
      <Button
        type={'link'}
        onClick={() => setIsDetailsOpen(prevState => !prevState)}
      >
        {capitalize(t(isDetailsOpen ? 'actions.hide' : 'actions.view'))}
      </Button>
    </Space>
    {isDetailsOpen && (
      <AlertsSuggestionTable
        alterGroupParams={alterGroupParams}
        alertGroup={alertGroup.uuid}
      />
    )}
  </Card>;
}

export default function AlertsSuggestionList() {

  const {t} = useTranslation()
  const [pagination, setPagination] = useState<PaginationConfig>({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    showTotal: (total, range) => t('table.pageSizeOfTotal', {
      rangeStart: range[0],
      rangeEnd: range[1],
      total,
    }),
    onChange: (page, pageSize) => setPagination(prevPag => ({...prevPag, current: page, pageSize})),
    style: {textAlign: 'center'},
  });

  const paramsAlertGroupsGet: GenericPaginatedParamsApi = {
    page: pagination.current,
    page_size: pagination.pageSize,
  }

  const {data, isLoading, isFetching, isUninitialized, refetch} = useGetAlertGroupsPaginatedQuery(paramsAlertGroupsGet)
  const isLoadingData = isLoading || isFetching || isUninitialized;


  const handleRefetchOnRemovingRecord = useCallback(() => {
    if (data?.results && data?.results.length > 1) {
      refetch()
    } else {
      setPagination(prevPag => ({...prevPag, current: (prevPag.current || 1) - 1 || 1}))
    }
  }, [data?.results, refetch])

  useEffect(() => {
    // when I removed all the alerts from an alert group
    if (data?.results.some(el => !el.is_manageable)) {
      handleRefetchOnRemovingRecord()
    }
  }, [data?.results, handleRefetchOnRemovingRecord]);

  return <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
    }}
  >
    {!isLoadingData && data?.results.map((el, idx) => <AlertSuggestionItem
      key={idx}
      alertGroup={el}
      alterGroupParams={paramsAlertGroupsGet}
      refetchList={handleRefetchOnRemovingRecord}
    />)}
    {isLoadingData && <Skeleton avatar/>}
    {!isLoadingData && !!data?.count && <Pagination
      {...pagination}
      disabled={isLoadingData}
      total={data?.count}
    />}
    {!isLoadingData && !data?.count && <Card><Empty/></Card>}
  </div>;
}