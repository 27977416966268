import {useLazyGetAssetMapDetailsQuery} from '../../redux/api/assetsMap';
import {createContext, PropsWithChildren, useContext, useEffect, useState} from 'react';
import {AssetDetails} from '../../types/internal/Map';

export type AssetMapDetailsContextType = {
  details: AssetDetails,
  getDetails: Function,
  loading: boolean,
};

const INITIAL_STATE = {
  uuid: null,
  sigi_code: null,
  address: null,
  admin_level_1: null,
  admin_level_3: null,
  surface_total: null,
  surface_basement: null,
  ownership: null,
  total_resources: null,
  master_asset: null,
  companies: [],
  contact_people: [],
};

const AssetMapDetailsContext = createContext<AssetMapDetailsContextType>({
  details: INITIAL_STATE,
  getDetails: (uuid: string) => INITIAL_STATE,
  loading: true,
});

export const useAssetMapDetailsContext = () => useContext(AssetMapDetailsContext);

export const MapDetailsContext = (props: PropsWithChildren) => {
  const [
    assetDetails,
    setAssetDetails,
  ] = useState<AssetDetails>(INITIAL_STATE);

  const [
    getAssetMapDetails,
    {
      isSuccess,
      data,
      isLoading,
      isFetching,
      isUninitialized,
    },
  ] = useLazyGetAssetMapDetailsQuery();

  useEffect(() => {
    if (isSuccess && data) {
      setAssetDetails(data);
    }
  }, [data, isSuccess]);

  return (
    <>
      <AssetMapDetailsContext.Provider
        value={
          {
            details: assetDetails,
            getDetails: getAssetMapDetails,
            loading: isLoading || isFetching || isUninitialized,
          }}
      >
        {props.children}
      </AssetMapDetailsContext.Provider>
    </>

  );
};