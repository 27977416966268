import {router} from '../../routing/Router';

const REFRESH_TOKEN_HSE = 'REFRESH_TOKEN_HSE';
export const REDIRECT_TO = 'REDIRECT_TO';

export const AUTH_FROM_LOGIN = 'AUTH_FROM_LOGIN';

export const setRefreshToken = (token: string) => {
  localStorage.setItem(REFRESH_TOKEN_HSE, token);
};

export const getRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKEN_HSE) || '';
};

export const removeRefreshToken = () => {
  localStorage.removeItem(REFRESH_TOKEN_HSE);
};

export const redirect = () => {
  const redirectPath = sessionStorage.getItem(REDIRECT_TO);
  if (redirectPath && redirectPath !== '/login') {
    router.navigate(redirectPath);
    sessionStorage.removeItem(REDIRECT_TO);
  } else {
    router.navigate('/assets');
  }
};