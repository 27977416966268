import {Form, FormProps, Upload, UploadFile} from "antd";
import CommunicationSubject from "./form/CommunicationSubject";
import CommunicationTag from "./form/CommunicationTag";
import {LocalizedDatePicker} from "../../common/LocalizedDatePicker";
import {ACCEPTED_EMAIL_CONTENT_TYPE} from "../../../utils/documents";
import {UploadOutlined} from "@ant-design/icons";
import {capitalize} from "lodash";
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";

const {Dragger} = Upload
const normFile = (e: any) => {
  console.log('Upload event:', e);
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

export type CommunicationUploadForm = {
  // subject: string,
  tags: string[],
  // datetime: dayjs.Dayjs,
  email_file: UploadFile[],
}
export default function CommunicationCreateUpload(formProps: FormProps) {

  const {t} = useTranslation()

  return <Form
    <CommunicationUploadForm>
    layout={'vertical'}
    {...formProps}
  >
    <CommunicationTag/>
    <Form.Item
      name={'email_file'}
      label={capitalize(t('actions.uploadFile'))}
      getValueFromEvent={normFile}
      valuePropName={'fileList'}
      rules={[
        {
          required: true,
          message: capitalize(t('form.requiredField', {field: t('actions.uploadFile')})),
        },
      ]}
    >
      <Dragger
        name={'file'}
        multiple={false}
        maxCount={1}
        accept={ACCEPTED_EMAIL_CONTENT_TYPE.join(',')}
        beforeUpload={(file, files) => {
          return false
        }}
      >
        <p className="ant-upload-drag-icon">
          <UploadOutlined/>
        </p>
        <p className="ant-upload-text">
          {capitalize(t('documents.dragOrSelectEmail'))}
        </p>
      </Dragger>
    </Form.Item>

  </Form>
}