import {useEffect, useMemo, useRef, useState} from 'react';
import {Popup, useMap, useMapEvents} from 'react-leaflet';
import {Marker as LeafletMarker} from 'leaflet';
import {CheckOutlined, DeleteOutlined, DragOutlined} from '@ant-design/icons';
import {Button, Col, Divider, Row, Typography} from 'antd';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
import {useEditLocationContext} from '../../../context/editAsset/EditAssetLocationContext';
import {useAssetDetails} from '../../../context/assetDetails/AssetDetails';
import {CustomMarker} from '../../common/map/CustomMarker';

const {Paragraph, Title} = Typography;
export const EditAssetPositionMarker = () => {

  const markerRef = useRef<LeafletMarker | null>(null);

  const {t} = useTranslation();
  const map = useMap();

  const [
    location,
    setLocation,
  ] = useEditLocationContext();

  const [, {asset}] = useAssetDetails();

  const [
    isMarkerDraggable,
    setIsMarkerDraggable,
  ] = useState(false);

  const [
    coordinates,
    setCoordinates,
  ] = useState<[number, number] | null>(null);

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        if (markerRef.current) {
          setCoordinates([markerRef.current.getLatLng().lat, markerRef.current.getLatLng().lng]);
        }
      },
      contextmenu() {
        markerRef.current?.openPopup();
      },
    }),
    [],
  );

  useMapEvents({
    dblclick(e) {
      if (!location.position) {
        setLocation(location => (
          {
            ...location,
            position: [e.latlng.lat, e.latlng.lng],
          }));
      }
    },
  });

  useEffect(() => {
    if (location.position) {
      setCoordinates(location.position);
      map.setView(location.position, 18);
    }
  }, [location.position, map]);

  return (
    <>
      {coordinates && (
        <CustomMarker
          position={coordinates}
          eventHandlers={eventHandlers}
          ref={markerRef}
          draggable={isMarkerDraggable}
        >
          <Popup className={'asset-position-preview-marker-popup'}>
            <>
              <Row>
                <Col>
                  <Title level={4}>{asset.sigi_code}</Title>
                </Col>
              </Row>
              <Row>
                <Col flex={1}>
                  <Paragraph type={'secondary'}>
                    {capitalize(t('misc.buildingHasBeenPlacedHere'))}.
                  </Paragraph>
                </Col>
              </Row>
              <Divider/>
              <Row justify={'center'} gutter={[16, 16]}>
                <Col>
                  {!isMarkerDraggable && (
                    <Button
                      type={'default'}
                      disabled={isMarkerDraggable}
                      onClick={() => {
                        setIsMarkerDraggable(draggable => !draggable);
                        map.closePopup();
                      }}
                      icon={<DragOutlined/>}
                    >
                      {capitalize(t('misc.correctPosition'))}
                    </Button>)}
                  {isMarkerDraggable && (
                    <Button
                      type={'primary'}
                      disabled={!isMarkerDraggable}
                      onClick={() => {
                        setIsMarkerDraggable(draggable => !draggable);
                        map.closePopup();
                        setLocation(location => (
                          {
                            ...location,
                            position: coordinates,
                          }));
                      }}
                      icon={<CheckOutlined/>}
                    >
                      {capitalize(t('misc.confirmPosition'))}
                    </Button>)}
                </Col>
                <Col>
                  <Button
                    type={'default'}
                    danger={true}
                    onClick={() => {
                      setCoordinates(null);
                      setLocation(location => (
                        {
                          ...location,
                          position: null,
                        }));
                      map.closePopup();
                    }}
                    icon={<DeleteOutlined/>}
                  >
                    {capitalize(t('form.delete'))}
                  </Button>
                </Col>
              </Row>
            </>
          </Popup>
        </CustomMarker>
      )}
    </>
  );
};