import {useGetEventTypeQuery} from '../../../redux/api/eventTypes';
import EventTypeIcon from './EventTypeIcon';
import React from 'react';
import {Space, SpaceProps, Tag, Typography} from 'antd';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import {TitleProps} from "antd/lib/typography/Title";
import {skipToken} from "@reduxjs/toolkit/query";
import {TagProps} from "antd/es/tag";

const {Title, Text} = Typography;
export default function EventTypeFromUuid({uuid, withTitle = true, tag = false, iconStyle, space, title, tagProps}: {
  uuid: string | null,
  withTitle?: boolean,
  tag?: boolean,
  iconStyle?: React.CSSProperties,
  space?: SpaceProps,
  title?: TitleProps,
  tagProps?: TagProps
}) {

  const {data} = useGetEventTypeQuery(uuid ? {uuid} : skipToken)
  const src = process.env.REACT_APP_MEDIA && data?.icon?.svg ? `${process.env.REACT_APP_MEDIA}${data.icon.svg}` : undefined;
  const {t} = useTranslation();

  const content = (
    <Space
      align={'center'}
      direction={'horizontal'}
      size={'small'}
      {...space}
    >
      {!!data && (
        <>
          <EventTypeIcon
            src={src} style={{
            backgroundColor: data.color_code,
            height: '12px',
            width: '12px',
            ...tag && {
              border: '',
              height: '100%',
              width: '24px',
              padding: '5px',
              marginTop: '7px',
            },
            ...iconStyle
          }}
          />
          {withTitle && <Title
            level={5}
            style={{margin: 0}}
            {...title}>
            {capitalize(data.name)}
          </Title>}
        </>
      )}
      {!data && <>
        <EventTypeIcon src={''} style={{height: '12px', width: '12px', backgroundColor: 'gainsboro'}}/>
        {withTitle && <Text strong style={{margin: 0}}>
          {capitalize(t('alerts.eventTypeNotAssigned'))}
        </Text>}
      </>}
    </Space>);

  return (
    <>
      {!tag && content}
      {tag && <Tag
        color={data?.color_code} bordered={false}
        children={content}
        {...tagProps}
        style={{borderRadius: '16px', paddingRight: '1rem', paddingLeft: '1rem', ...tagProps?.style}}
      />}

    </>
  );
}