import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import ResponsiveContent from '../../../layout/ResponsiveContent';
import {capitalize} from 'lodash';
import {Button, Card, Space, Typography} from 'antd';
import {useCreateReiLayerMutation, useUpdateLayerMutation} from '../../../redux/api/layers';
import {CustomLoading} from '../../../components/common/CustomLoading';
import {CheckCircleOutlined, WarningOutlined, WarningTwoTone} from '@ant-design/icons';

function GoToLayersSettings() {

  const navigate = useNavigate();
  const {t} = useTranslation();

  return <Button
    type={'primary'}
    onClick={() => navigate('/settings/layers')}
  >
    {capitalize(t('actions.goBackDestination', {destination: t('layers.layersManagement')}))}
  </Button>;
}

function Loading() {
  const {t} = useTranslation();

  return <>
    <CustomLoading style={{fontSize: 150}}/>
    <Typography.Title level={2} style={{margin: 0}}>{capitalize(t('layers.upload.loadingTitle'))}</Typography.Title>
    <Space align={'center'}>
      <WarningTwoTone/>
      <Typography.Paragraph
        style={{margin: 0}}
      >{capitalize(t('layers.upload.loadingDescription'))}</Typography.Paragraph>
    </Space>
  </>;
}

function Success() {

  const {t} = useTranslation();

  return <>
    <CheckCircleOutlined style={{fontSize: 150, color: '#52C41A'}}/>
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: '1rem',
        alignItems: 'center',
      }}
    >
      <Typography.Title
        level={2} style={{margin: 0}}
      >{capitalize(t('layers.upload.successTitle'))}</Typography.Title>
      <Typography.Paragraph
        style={{margin: 0}}
      >{capitalize(t('layers.upload.successDescription'))}</Typography.Paragraph>
    </div>
    <div>
      <GoToLayersSettings/>
    </div>
  </>;
}

function Error() {

  const {t} = useTranslation();

  return <>
    <WarningOutlined style={{fontSize: 150, color: '#f20404'}}/>
    <Typography.Title level={2} style={{margin: 0}}>{capitalize(t('layers.upload.errorTitle'))}</Typography.Title>
    <div>
      <GoToLayersSettings/>
    </div>
  </>;
}

export default function UploadLayer() {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const body = location.state;

  const [createReiLayer, {
    isSuccess: reiSuccess,
    isError: reiError,
    isLoading: reiLoading,
  }] = useCreateReiLayerMutation();
  const [updateLayer, {
    isSuccess: updateSuccess,
    isError: updateError,
    isLoading: updateLoading,
  }] = useUpdateLayerMutation();

  useEffect(() => {
    if (body?.type === 'edit' && updateLayer) {
      const {type, ...params} = body;
      updateLayer(params);
    }
  }, [body, updateLayer]);

  useEffect(() => {
    if (body?.type === 'creation' && createReiLayer) {
      const {type, layer_file, ...params} = body;
      createReiLayer({...params, layer_file: layer_file.file});
    }
  }, [body, createReiLayer]);

  useEffect(() => {
    if (!body?.type && navigate) {
      navigate('/');
    }
  }, [body?.type, navigate]);

  return <ResponsiveContent
    goBack={() => navigate('/settings/layers')}
    goBackText={capitalize(t('layers.layersManagement'))}
  >
    <Card
      bodyStyle={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: '2.5rem',
        alignItems: 'center',
      }}
    >

      {updateLoading && <Loading/>}
      {updateSuccess && <Success/>}
      {updateError && <Error/>}

      {reiLoading && <Loading/>}
      {reiSuccess && <Success/>}
      {reiError && <Error/>}

    </Card>
  </ResponsiveContent>;
}