import {
  Button,
  ButtonProps,
  Col,
  ConfigProvider,
  Drawer,
  DrawerProps,
  Form,
  FormProps,
  Input,
  Row,
  Select,
  Skeleton,
  Typography,
} from 'antd';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import {useGetAllTagsQuery} from '../../../redux/api/tags';
import {TemplateMailEditor} from './TemplateMailEditor';

export default function TemplateMailForm({title, drawer, form, drawerButton, confirmButton}: {
  title: string,
  drawer?: DrawerProps,
  form?: FormProps,
  drawerButton?: ButtonProps,
  confirmButton?: ButtonProps,
}) {

  const {t} = useTranslation();

  const {
    data: tags,
    isLoading: areTagsLoading,
    isFetching: areTagsFetching,
  } = useGetAllTagsQuery();

  const isLoading = areTagsLoading || areTagsFetching;

  return <>
    <Button
      type={'primary'}
      {...drawerButton}/>
    <ConfigProvider
      theme={{
        token: {
          zIndexPopupBase: 100000,
        },
      }}
    >
      <Drawer
        title={null}
        id={'template-mail-drawer'}
        forceRender={true}
        destroyOnClose={true}
        closeIcon={false}
        size={'large'}
        styles={{
          footer: {padding: '0 24px 24px 24px'},
        }}
        footer={(
          <Row
            justify={'end'}
            style={{padding: '10px 0'}}
          >
            <Col>
              <Button
                type={'primary'}
                {...confirmButton}/>
            </Col>
          </Row>
        )}
        {...drawer}>
        {isLoading && <Skeleton active={true}/>}
        {!isLoading && (
          <div style={{display: 'flex', flexDirection: 'column', gap: '2.25rem'}}>
            <Typography.Title level={3} style={{margin: 0}}>
              {title}
            </Typography.Title>
            <Form
              layout={'vertical'}
              {...form}
            >
              <Row gutter={12}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label={capitalize(t('mailTemplate.mailTemplateTitle'))}
                    required={true}
                    rules={[{required: true}]}
                    name={'title'}
                  >
                    <Input placeholder={capitalize(t('mailTemplate.mailTemplateTitle'))}/>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label={capitalize((t('mailTemplate.language')))}
                    required={true}
                    rules={[{required: true}]}
                    name={'language'}
                  >
                    <Select
                      placeholder={capitalize((t('actions.selectItem', {item: t('mailTemplate.language')})))}
                      options={[
                        {
                          label: capitalize(t('misc.italian')),
                          value: 'it',
                        },
                        {
                          label: capitalize(t('misc.english')),
                          value: 'en',
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    label={capitalize((t('mailTemplate.tag')))}
                    name={'tags'}
                  >
                    <Select
                      placeholder={capitalize((t('actions.selectItem', {item: t('mailTemplate.tag')})))}
                      mode={'multiple'}
                      options={tags?.map(tag => ({
                        label: tag.frontend_name,
                        value: tag.uuid,
                      }))}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                style={{marginTop: '2.25rem'}}
                required={true}
                label={capitalize(t('mailTemplate.mailTemplate'))}
                rules={[
                  {
                    required: true,
                    message: capitalize(t('form.requiredField', {field: t('mailTemplate.mailTemplate')})),
                    validator: (_, value) => {
                      if (!value || value.trim() === '') {
                        return Promise.reject();
                      }
                      return Promise.resolve();
                    },
                  }]}
                name={'text'}
              >
                <TemplateMailEditor
                  reactQuillProps={{style: {height: '20rem'}}}
                  placeholder={capitalize(t('mailTemplate.placeholder'))}/>
              </Form.Item>
            </Form>
          </div>
        )}
      </Drawer>
    </ConfigProvider>
  </>;
}