import {useGetAlertSourceQuery} from "../../../redux/api/alertSource";
import DateTimeFormat from "../../../locales/DateTimeFormat";
import {capitalize} from "lodash";
import {useTranslation} from "react-i18next";
import {Typography} from "antd";

export default function DataSourceLastUpdate({alertSourceUuid}: { alertSourceUuid: string }) {

  const {data} = useGetAlertSourceQuery({alertSourceUuid}, {refetchOnMountOrArgChange: true})
  const {t} = useTranslation()

  return <div>
    <Typography.Text strong>{capitalize(t('misc.lastUpdated'))}: </Typography.Text>
    <DateTimeFormat>{data?.last_refresh}</DateTimeFormat>
  </div>
}