import {Alert, Button, Form, Input, Modal} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import {useState} from 'react';
import {capitalize} from 'lodash';
import {useCreateContactMutation} from '../../redux/api/contacts';
import {ContactPerson} from '../../types/api/contactPerson';
import {useTranslation} from 'react-i18next';

const AddContactPersonButton = ({afterCreation}: { afterCreation?: (contactToAdd: ContactPerson) => void }) => {
  
  const [form] = Form.useForm();
  const {t} = useTranslation();
  
  const [
    isEditModalOpen,
    setIsEditModalOpen,
  ] = useState(false);
  
  const [
    createContact,
    {
      isLoading,
      isError,
    },
  ] = useCreateContactMutation();
  
  const [
    statusMessage,
    setStatusMessage,
  ] = useState<string | null>(null);
  
  return (
    <>
      <Modal
        open={isEditModalOpen}
        destroyOnClose={true}
        title={capitalize(t('actions.insertItem', {item: t('contacts.contact_one')}))}
        closable={true}
        okText={capitalize(t('actions.create'))}
        okButtonProps={{loading: isLoading}}
        onCancel={() => {
          form.resetFields();
          setIsEditModalOpen(false);
        }}
        onOk={() => {
          
          form
            .validateFields()
            .then(values => {
              form.resetFields();
              createContact(values)
                .unwrap()
                .then((res) => {
                  if (afterCreation) {
                    afterCreation(res);
                  }
                  setIsEditModalOpen(false);
                })
                .catch(() => setStatusMessage(t('form.creationFailMessage')));
            });
        }}
      >
        {isError && statusMessage && (
          <Alert
            message={statusMessage}
            type={'error'}
            closable={true}
            onClick={() => setStatusMessage(null)}
          />
        )}
        <Form
          form={form}
          name={'addContactPerson'}
          layout={'vertical'}
        >
          <Form.Item
            name={'full_name'}
            label={capitalize(t('assets.emergencyCoordinatorName'))}
            rules={[{required: true, message: capitalize(t('form.requiredField', {field: t('contacts.email')}))}]}
          >
            <Input placeholder={`${capitalize(t('assets.emergencyCoordinatorName'))}...`}/>
          </Form.Item>
          <Form.Item
            name={'phone_number'}
            label={capitalize(t('contacts.phone'))}
            rules={[
              {
                required: true, message: capitalize(t('form.requiredField', {field: t('contacts.email')})),
              },
            ]}
          >
            <Input placeholder={`${capitalize(t('contacts.phone'))}...`}/>
          </Form.Item>
          <Form.Item
            name={'email'}
            label={capitalize(t('contacts.email'))}
            rules={[
              {
                type: 'email',
                message: capitalize(t('contacts.mailInvalid')),
              }, {
                required: true, message: capitalize(t('form.requiredField', {field: t('contacts.email')})),
              }]}
          >
            <Input placeholder={`${capitalize(t('contacts.email'))}...`}/>
          </Form.Item>
        </Form>
      
      </Modal>
      <Button
        onClick={() => setIsEditModalOpen(true)}
        icon={<PlusOutlined/>}
      >
        {capitalize(t('actions.insertItem', {item: t('contacts.contact_one')}))}
      </Button>
    </>
  );
};
export default AddContactPersonButton;