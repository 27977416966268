import {ConfigProvider} from 'antd';
import enUS from 'antd/locale/en_US';
import it from 'antd/locale/it_IT';
import 'dayjs/locale/it';
import {useGetUserProfileQuery} from '../redux/api/locales';
import {useEffect} from 'react';
import i18next from '../i18next';
import {Outlet} from 'react-router-dom';

export default function LocaleProvider() {

  const {data} = useGetUserProfileQuery();
  const antdLocale = data?.language === 'en' ? enUS : it;

  useEffect(() => {
    if (data) {
      i18next.changeLanguage(data.language);
      //   todo manage errors
    }
  }, [data]);

  return (
    <ConfigProvider locale={antdLocale}>
      <Outlet/>
    </ConfigProvider>
  );
}