import {apiSlice} from './baseApi';
import {User} from '../../components/Authentication/permissions';

export const localesApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getUserProfile: build.query<{
      email: string,
      full_name: string,
      language: 'it' | 'en',
      user: string,
      type: User,
      type_name: string,
      uuid: string,
      geoserver_key: string,
    }, void>({
      query: () => {
        return {
          url: '/userprofile/',
        };
      },
      providesTags: ['Locale', 'User'],
    }),
    updateLocale: build.mutation<{ language: 'it' | 'en' }, { language: 'it' | 'en' }>({
      query: (args) => {
        return {
          method: 'PATCH',
          url: '/userprofile/',
          body: args,
        };
      },
      invalidatesTags: ['Locale'],
    }),
  }),
});

export const {useGetUserProfileQuery, useUpdateLocaleMutation} = localesApi;