import {Col, Pagination, Row, Space, Table, Tag, Typography} from 'antd';
import _, {capitalize, isEmpty} from 'lodash';
import {useTranslation} from 'react-i18next';
import React, {useEffect, useState} from 'react';
import ArchiveAlertButton from '../ArchiveAlertButton';
import AlertsTableColumnsSelection from './AlertsTableColumnsSelection';
import {ColumnsType} from 'antd/lib/table';
import {useDispatch, useSelector} from 'react-redux';
import {
  addAlertsTableFilters,
  addAlertsTableSorters,
  removeAlertsTableFilters,
  removeAlertsTableSorters,
  selectAlerts,
  setAlertsTablePagination,
} from '../../../redux/slices/alerts';
import {getTranslationAlertToManageTableColumnKey} from '../../../utils/translation';
import ChooseConnectAlertsToEventMode from './ChooseConnectAlertsToEventMode';
import AssignEventTypeToAlert from './AssignEventTypeToAlert';
import DeleteAlertButton from '../DeleteAlertButton';
import {useLazyGetAlertsPaginatedQuery} from '../../../redux/api/alerts';
import {Alert} from '../../../types/api/alerts';
import {EventType} from '../../../types/api/eventTypes';
import DateTimeFormat from '../../../locales/DateTimeFormat';
import HelpTooltip from '../../common/HelpTooltip';
import {useNavigate} from 'react-router-dom';
import {DocumentsTableColumnHeader} from '../../documents/TablesViews/DocumentsTableColumnHeader';
import TableSorter from '../../common/TableSorter';
import {ExpandableTagList} from '../../common/ExpandableTagList';
import EventTypeFilter from './filters/EventTypeFilter';
import EventFilter from './filters/EventFilter';
import IsUserCreatedFilter from '../../assets/AssetsTable/filters/IsUserCreatedFilter';
import IsForecastFilter from './filters/IsForecastFilter';
import {fromFeToBeSorter} from '../../../utils/parser';
import SearchFilter from '../../common/filters/SearchFilter';
import ResetAlertsFiltersSorters from './filters/ResetAlertsFiltersSorters';
import {translateLevelToText} from '../../../utils/assets';
import {Entity} from '../../../redux/api/geoshapes';
import {
  AlertsFilters,
  AlertTableMode,
  fromFeToBeAlertsTableFilters,
  getFiltersOfTheAlertsTableColumn,
} from '../../../types/internal/tables/alertsTable';
import DatesFilter from '../../common/filters/DatesFilter';
import NumbersFilter from '../../common/filters/NumbersFilter';
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

export default function AlertsTable({mode}: { mode: AlertTableMode }) {

  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [getAlerts, {data, isFetching, isUninitialized}] = useLazyGetAlertsPaginatedQuery();

  const selectedAlerts: Alert[] = data?.results.filter(alert => selectedRowKeys.includes(alert.uuid)) || [];
  const isAssignPossible = selectedAlerts.every(alert => !alert.event_type);
  const eventTypes = selectedAlerts.map(el => el.event_type);
  const isAggregatePossible = eventTypes.every(el => el?.uuid) && selectedAlerts.every(el => el.state !== 'CONNECTED');
  const eventTypeAggregate = (isAggregatePossible && eventTypes.length && eventTypes[0]?.uuid) || undefined
  const isAggregateDisabled = !eventTypes.every(el => el?.uuid && eventTypes[0]?.uuid === el.uuid);
  const isArchivePossible = selectedAlerts.every(el => el.state !== 'ARCHIVED');

  const resetAlertSelection = () => {
    setSelectedRowKeys([]);
  };
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_NONE,
    ],
  };
  const hasSelected = selectedRowKeys.length > 0;
  const sliceKey = mode === 'managed' ? 'alertsManagedTable' : 'alertsToManageTable';
  const reduxMode = mode === 'managed' ? 'managed' : 'toManage';
  const columns = useSelector(selectAlerts)[sliceKey].columns;
  const pagination = useSelector(selectAlerts)[sliceKey].pagination;
  const filters = useSelector(selectAlerts)[sliceKey].filters;
  const sorters = useSelector(selectAlerts)[sliceKey].sorters;
  const {xs, sm, md, lg, xl, xxl} = useBreakpoint()

  const antdColumns: ColumnsType<Alert> = columns.map(column => {

    const defaultProps = {
      title: capitalize(t(getTranslationAlertToManageTableColumnKey(column))),
      key: column,
      dataIndex: column,
      width: 200,
    };

    switch (column) {
      case 'event_type': {
        return {
          ...defaultProps,
          title: <DocumentsTableColumnHeader
            title={capitalize(t(getTranslationAlertToManageTableColumnKey(column)))}
            sorter={<TableSorter
              value={sorters[column] || null}
              onChange={type => {
                if (type) {
                  dispatch(addAlertsTableSorters({sorters: {[column]: type}, mode: reduxMode}));
                } else {
                  dispatch(removeAlertsTableSorters({sorters: column, mode: reduxMode}));
                }
              }}
            />}
            filter={<EventTypeFilter
              activeFilters={filters[column]}
              handleFilterChange={(values) => {
                if (values.length) {
                  dispatch(addAlertsTableFilters({
                    filters: {[column]: values},
                    mode: reduxMode,
                  }));
                } else {
                  dispatch(removeAlertsTableFilters({filters: column, mode: reduxMode}));
                }
              }}
            />}
            onFilterReset={() => dispatch(removeAlertsTableFilters({filters: column, mode: reduxMode}))}
            isFilterDisabled={!filters[column]}
          />,
          render: (value: EventType | null, record) => {
            if (value) {
              return <Tag
                style={{
                  ...(xs || sm || md) && {width: '7rem'},
                  ...(lg || xl || xxl) && {width: ''},
                  textAlign: 'center'
                }}>
                <Typography.Text ellipsis={{tooltip: true}}>
                  {capitalize(value.name)}
                </Typography.Text>
              </Tag>;
            } else {
              return <ExpandableTagList
                tags={[{
                  uuid: `${record.uuid}_not_available_event`,
                  content: capitalize(t('alerts.eventNotRelated')),
                  color: '#c9c9c9',
                }]}
              />;
            }
          },
        };
      }
      case 'is_forecast': {
        return {
          ...defaultProps,
          title: <DocumentsTableColumnHeader
            title={capitalize(t(getTranslationAlertToManageTableColumnKey(column)))}
            sorter={<TableSorter
              value={sorters[column] || null}
              onChange={type => {
                if (type) {
                  dispatch(addAlertsTableSorters({sorters: {[column]: type}, mode: reduxMode}));
                } else {
                  dispatch(removeAlertsTableSorters({sorters: column, mode: reduxMode}));
                }
              }}
            />}
            filter={<IsForecastFilter
              activeFilters={filters[column]}
              handleFilterChange={(values) => {
                if (values.length) {
                  dispatch(addAlertsTableFilters({
                    filters: {[column]: values},
                    mode: reduxMode,
                  }));
                } else {
                  dispatch(removeAlertsTableFilters({filters: column, mode: reduxMode}));
                }
              }}
            />}
            onFilterReset={() => dispatch(removeAlertsTableFilters({filters: column, mode: reduxMode}))}
            isFilterDisabled={!filters[column]}
          />,
          render: (v: boolean) => {
            return capitalize(t(v ? 'alerts.isForecast' : 'alerts.isNotForecast'));
          },
        };
      }
      case 'is_user_created': {
        return {
          ...defaultProps,
          title: <DocumentsTableColumnHeader
            title={capitalize(t(getTranslationAlertToManageTableColumnKey(column)))}
            sorter={<TableSorter
              value={sorters[column] || null}
              onChange={type => {
                if (type) {
                  dispatch(addAlertsTableSorters({sorters: {[column]: type}, mode: reduxMode}));
                } else {
                  dispatch(removeAlertsTableSorters({sorters: column, mode: reduxMode}));
                }
              }}
            />}
            filter={<IsUserCreatedFilter
              activeFilters={filters[column]}
              handleFilterChange={(values) => {
                if (values.length) {
                  dispatch(addAlertsTableFilters({
                    filters: {[column]: values},
                    mode: reduxMode,
                  }));
                } else {
                  dispatch(removeAlertsTableFilters({filters: column, mode: reduxMode}));
                }
              }}
            />}
            onFilterReset={() => dispatch(removeAlertsTableFilters({filters: column, mode: reduxMode}))}
            isFilterDisabled={!filters[column]}
          />,
          render: (v: boolean) => {
            return capitalize(t(v ? 'alerts.isUserCreated' : 'alerts.isNotUserCreated'));
          },
        };
      }
      case 'geographic_area_list': {
        return {
          ...defaultProps,
          render: (v: Entity[]) => {
            if (v?.length) {
              if (v.length === 1) {
                return `${v[0].name} (${capitalize(t(translateLevelToText(v[0].level)))})`;
              } else {
                return <Typography.Text
                  style={{width: '10rem'}}
                  ellipsis={{tooltip: true}}
                >
                  {v.map(el => `${el.name} (${capitalize(t(translateLevelToText(el.level)))})`).join(' - ')}
                </Typography.Text>;
              }
            }
          },
        };
      }
      case 'event': {
        return {
          ...defaultProps,
          title: <DocumentsTableColumnHeader
            title={capitalize(t(getTranslationAlertToManageTableColumnKey(column)))}
            sorter={<TableSorter
              value={sorters[column] || null}
              onChange={type => {
                if (type) {
                  dispatch(addAlertsTableSorters({sorters: {[column]: type}, mode: reduxMode}));
                } else {
                  dispatch(removeAlertsTableSorters({sorters: column, mode: reduxMode}));
                }
              }}
            />}
            filter={<EventFilter
              activeFilters={filters[column]}
              handleFilterChange={(values) => {
                if (values.length) {
                  dispatch(addAlertsTableFilters({
                    filters: {[column]: values},
                    mode: reduxMode,
                  }));
                } else {
                  dispatch(removeAlertsTableFilters({filters: column, mode: reduxMode}));
                }
              }}
            />}
            onFilterReset={() => dispatch(removeAlertsTableFilters({filters: column, mode: reduxMode}))}
            isFilterDisabled={!filters[column]}
          />,
          render: (v: string | null) => {
            if (v) {
              return <Tag color={'geekblue'}>
                #{v}
              </Tag>;
            } else {
              return <Tag color={'error'}>
                {capitalize(t('alerts.eventNotRelated'))}
              </Tag>;
            }
          },
        };
      }
      case 'start_datetime':
      case 'end_datetime':
        return {
          ...defaultProps,
          title: <DocumentsTableColumnHeader
            title={capitalize(t(getTranslationAlertToManageTableColumnKey(column)))}
            sorter={<TableSorter
              value={sorters[column] || null}
              onChange={type => {
                if (type) {
                  dispatch(addAlertsTableSorters({sorters: {[column]: type}, mode: reduxMode}));
                } else {
                  dispatch(removeAlertsTableSorters({sorters: column, mode: reduxMode}));
                }
              }}
            />}

            filter={<DatesFilter
              isNull={filters[`is_${column}_null`]}
              start={filters[`${column}_after`]}
              end={filters[`${column}_before`]}
              onNullChange={(value) => {
                if (value) {
                  dispatch(addAlertsTableFilters({filters: {[`is_${column}_null`]: value}, mode: reduxMode}));
                  dispatch(removeAlertsTableFilters({
                    filters: [`${column}_after`, `${column}_before`],
                    mode: reduxMode,
                  }));
                } else {
                  dispatch(removeAlertsTableFilters({filters: `is_${column}_null`, mode: reduxMode}));
                }
              }}
              onStartChange={(value) => {
                if (value) {
                  dispatch(addAlertsTableFilters({filters: {[`${column}_after`]: value}, mode: reduxMode}));
                } else {
                  dispatch(removeAlertsTableFilters({filters: `${column}_after`, mode: reduxMode}));
                }
              }}
              onEndChange={(value) => {
                if (value) {
                  dispatch(addAlertsTableFilters({filters: {[`${column}_before`]: value}, mode: reduxMode}));
                } else {
                  dispatch(removeAlertsTableFilters({filters: `${column}_before`, mode: reduxMode}));
                }
              }}
            />}
            onFilterReset={() => dispatch(removeAlertsTableFilters({
              filters: [`is_${column}_null`, `${column}_after`, `${column}_before`],
              mode: reduxMode,
            }))}
            isFilterDisabled={!(filters[`is_${column}_null`] || filters[`${column}_after`] || filters[`${column}_before`])}
          />,
          render: (v: string | null) => {
            if (v) {
              return <DateTimeFormat>{v}</DateTimeFormat>;
            }
          },
        };
      case 'assets_count':
        return {
          ...defaultProps,
          title: <DocumentsTableColumnHeader
            title={capitalize(t(getTranslationAlertToManageTableColumnKey(column)))}
            sorter={<TableSorter
              value={sorters[column] || null}
              onChange={type => {
                if (type) {
                  dispatch(addAlertsTableSorters({sorters: {[column]: type}, mode: reduxMode}));
                } else {
                  dispatch(removeAlertsTableSorters({sorters: column, mode: reduxMode}));
                }
              }}
            />}
            filter={<NumbersFilter
              onNullChange={(value) => {
                if (value) {
                  dispatch(addAlertsTableFilters({filters: {[`is_${column}_null`]: value}, mode: reduxMode}));
                  dispatch(removeAlertsTableFilters({
                    filters: [`${column}_min`, `${column}_max`],
                    mode: reduxMode,
                  }));
                } else {
                  dispatch(removeAlertsTableFilters({filters: `is_${column}_null`, mode: reduxMode}));
                }
              }}

              onMinChange={(value) => {
                if (typeof value === 'number') {
                  dispatch(addAlertsTableFilters({filters: {[`${column}_min`]: value}, mode: reduxMode}));
                } else {
                  dispatch(removeAlertsTableFilters({filters: `${column}_min`, mode: reduxMode}));
                }
              }}
              onMaxChange={(value) => {
                if (typeof value === 'number') {
                  dispatch(addAlertsTableFilters({filters: {[`${column}_max`]: value}, mode: reduxMode}));
                } else {
                  dispatch(removeAlertsTableFilters({filters: `${column}_max`, mode: reduxMode}));
                }
              }}
              min={filters[`${column}_min`]}
              max={filters[`${column}_max`]}
              isNull={filters[`is_${column}_null`]}
            />}
            onFilterReset={() => dispatch(removeAlertsTableFilters({
              filters: [`${column}_max`, `${column}_min`, `is_${column}_null`],
              mode: reduxMode,
            }))}
            isFilterDisabled={!(filters[`${column}_max`] || filters[`${column}_min`] || filters[`is_${column}_null`])}
          />,
        };
      case 'title':
        return {
          ...defaultProps,
          title: <DocumentsTableColumnHeader
            title={capitalize(t(getTranslationAlertToManageTableColumnKey(column)))}
            sorter={<TableSorter
              value={sorters[column] || null}
              onChange={type => {
                if (type) {
                  dispatch(addAlertsTableSorters({sorters: {[column]: type}, mode: reduxMode}));
                } else {
                  dispatch(removeAlertsTableSorters({sorters: column, mode: reduxMode}));
                }
              }}
            />}
            filter={
              <SearchFilter
                onChange={value => {
                  if (value) {
                    dispatch(addAlertsTableFilters({
                      filters: {[`search_by_${column}`]: value},
                      mode: reduxMode,
                    }));
                  } else {
                    dispatch(removeAlertsTableFilters({filters: `search_by_${column}`, mode: reduxMode}));
                  }
                }}
                placeholder={capitalize(t('table.filterPlaceholder', {column: t(getTranslationAlertToManageTableColumnKey(column))}))}
                selectedFilter={filters[`search_by_${column}`]}
              />
            }
            onFilterReset={() => dispatch(removeAlertsTableFilters({filters: `search_by_${column}`, mode: reduxMode}))}
            isFilterDisabled={!filters[`search_by_${column}`]}
          />,
        };
      case 'source_url':
        return {
          ...defaultProps,
          title: <DocumentsTableColumnHeader
            title={capitalize(t(getTranslationAlertToManageTableColumnKey(column)))}
            sorter={<TableSorter
              value={sorters[column] || null}
              onChange={type => {
                if (type) {
                  dispatch(addAlertsTableSorters({sorters: {[column]: type}, mode: reduxMode}));
                } else {
                  dispatch(removeAlertsTableSorters({sorters: column, mode: reduxMode}));
                }
              }}
            />}
            filter={
              <SearchFilter
                onChange={value => {
                  if (value) {
                    dispatch(addAlertsTableFilters({
                      filters: {[`search_by_${column}`]: value},
                      mode: reduxMode,
                    }));
                  } else {
                    dispatch(removeAlertsTableFilters({filters: `search_by_${column}`, mode: reduxMode}));
                  }
                }}
                placeholder={capitalize(t('table.filterPlaceholder', {column: t(getTranslationAlertToManageTableColumnKey(column))}))}
                selectedFilter={filters[`search_by_${column}`]}
              />
            }
            onFilterReset={() => dispatch(removeAlertsTableFilters({filters: `search_by_${column}`, mode: reduxMode}))}
            isFilterDisabled={!filters[`search_by_${column}`]}
          />,
        };
      default:
        return {...defaultProps};
    }

  });

  useEffect(() => {
    getAlerts({
      ...fromFeToBeAlertsTableFilters(filters),
      page: pagination.current,
      page_size: pagination.pageSize,
      is_managed: mode === 'managed',
      ...!isEmpty(sorters) && {ordering: fromFeToBeSorter(sorters)},
    });
  }, [filters, getAlerts, mode, pagination, sorters]);

  useEffect(() => {
    // remove filters if the column is not present
    let filtersToRemove: AlertsFilters[] = [];
    for (const [key,] of Object.entries(filters)) {
      filtersToRemove.push(key as AlertsFilters);
    }
    columns.forEach((col) => {
      filtersToRemove = _.difference(filtersToRemove, getFiltersOfTheAlertsTableColumn(col));
    });
    if (filtersToRemove.length) {
      dispatch(removeAlertsTableFilters({filters: filtersToRemove, mode: reduxMode}));
    }

  }, [columns, dispatch, filters, reduxMode]);

  return <>
    <Row justify={'space-between'} gutter={[16, 16]}>
      <Col>
        <Space>
          <ResetAlertsFiltersSorters mode={mode}/>
          <AlertsTableColumnsSelection mode={mode}/>
        </Space>
      </Col>
      {hasSelected && <Col>
        <Space wrap>
          {mode === 'managed' && (
            <>
              <DeleteAlertButton
                selectedAlerts={selectedRowKeys}
                afterDelete={resetAlertSelection}
              />
              {isAggregatePossible && <ChooseConnectAlertsToEventMode
                evenTypeUuid={eventTypeAggregate}
                selectedAlerts={selectedRowKeys}/>}
            </>
          )}
          {mode === 'toManage' && (
            <>
              <DeleteAlertButton
                selectedAlerts={selectedRowKeys}
                afterDelete={resetAlertSelection}
              />
              {isArchivePossible && <ArchiveAlertButton
                selectedAlerts={selectedRowKeys}
                afterArchive={resetAlertSelection}
              />}
              {isAggregatePossible && (
                <ChooseConnectAlertsToEventMode
                  evenTypeUuid={eventTypeAggregate}
                  selectedAlerts={selectedRowKeys}
                  button={{disabled: isAggregateDisabled}}
                />
              )}
              {isAggregatePossible && isAggregateDisabled && (
                <HelpTooltip
                  tooltip={{
                    title: t('alerts.assignTypeError'),
                  }}
                />
              )}
              {isAssignPossible && (
                <AssignEventTypeToAlert
                  selectedAlerts={selectedRowKeys}
                  afterAssignment={resetAlertSelection}
                />
              )}
            </>
          )}
        </Space>
      </Col>}
    </Row>
    <Table
      <Alert>
      columns={antdColumns}
      rowSelection={rowSelection}
      scroll={{x: true}}
      dataSource={data?.results}
      loading={isFetching || isUninitialized}
      rowKey={'uuid'}
      onRow={(record) => (
        {
          onClick: (e) => {
            navigate(`/alerts/${record.uuid}`);
          },
          style: {
            cursor: 'pointer',
          },
        })
      }
      pagination={false}
    />
    <Pagination
      disabled={!data?.count}
      showSizeChanger={true}
      total={data?.count}
      showTotal={(total, range) => t('table.pageSizeOfTotal', {
        rangeStart: range[0],
        rangeEnd: range[1],
        total,
      })}
      onChange={(current, pageSize) => {
        dispatch(setAlertsTablePagination({pagination: {current, pageSize}, mode: reduxMode}));
      }}
      style={{textAlign: 'center'}}
      {...pagination}
    />
  </>;
}