// FILTERS
import {Sorter} from './sorters';
import {OptionLabelValue} from '../OptionLabelValue';
import {GetAlertsParams} from '../../api/alerts';
import dayjs from 'dayjs';

const alertsBooleanFilters = [
  'is_assets_count_null',
  'is_end_datetime_null',
  'is_start_datetime_null',
] as const;
export type AlertsBooleanFilters = (typeof alertsBooleanFilters)[number];
const isAlertsBooleanFilters = (x: any): x is AlertsBooleanFilters => alertsBooleanFilters.includes(x);

const alertsStringFilters = [
  'end_datetime_after',
  'end_datetime_before',
  'start_datetime_after',
  'start_datetime_before',
  'search_by_source_url',
  'search_by_title',
] as const;
export type AlertsStringFilters = (typeof alertsStringFilters)[number];
const isAlertsStringFilters = (x: any): x is AlertsStringFilters => alertsStringFilters.includes(x);

const alertsOptionLabelValueFilters = [
  'event',
  'event_type',
  'is_forecast',
  'is_user_created',
] as const;
export type AlertsOptionLabelValueFilters = (typeof alertsOptionLabelValueFilters)[number];
export const isAlertsOptionLabelValueFilters = (x: any): x is AlertsOptionLabelValueFilters => alertsOptionLabelValueFilters.includes(x);

const alertsNumberFilters = [
  'assets_count_max',
  'assets_count_min',
] as const;
export type AlertsNumberFilters = (typeof alertsNumberFilters)[number];
const isAlertsNumberFilters = (x: any): x is AlertsNumberFilters => alertsNumberFilters.includes(x);

export type AlertsFiltersFE = Partial<
  Record<AlertsBooleanFilters, boolean> &
  Record<AlertsStringFilters, string> &
  Record<AlertsOptionLabelValueFilters, OptionLabelValue[]> &
  Record<AlertsNumberFilters, number>
>

export type AlertsFilters = (
  typeof alertsBooleanFilters |
  typeof alertsStringFilters |
  typeof alertsOptionLabelValueFilters |
  typeof alertsNumberFilters)[number];
export const isAlertsFilters = (x: any): x is AlertsFilters => [
  ...alertsBooleanFilters,
  ...alertsStringFilters,
  ...alertsOptionLabelValueFilters,
  ...alertsNumberFilters].includes(x);

export function fromFeToBeAlertsTableFilters(tableFilters: AlertsFiltersFE) {
  let parsedFilters: GetAlertsParams = {};
  for (const [key, value] of Object.entries(tableFilters)) {
    if (
      isAlertsBooleanFilters(key) ||
      isAlertsStringFilters(key) ||
      isAlertsNumberFilters(key)) {
      switch (key) {
        case 'start_datetime_before':
        case 'end_datetime_before':
          if (typeof value === 'string') {
            parsedFilters = {
              ...parsedFilters,
              [key as keyof GetAlertsParams]: dayjs(value, 'YYYY-MM-DD').endOf('day').toISOString(),
            };
          }
          break;
        case 'start_datetime_after':
        case 'end_datetime_after':
          if (typeof value === 'string') {
            parsedFilters = {
              ...parsedFilters,
              [key as keyof GetAlertsParams]: dayjs(value, 'YYYY-MM-DD').startOf('day').toISOString(),
            };
          }
          break;
        default:
          parsedFilters = {...parsedFilters, [key as keyof GetAlertsParams]: value};
      }
    } else if (isAlertsOptionLabelValueFilters(key)) {
      const parsedValue = (value as OptionLabelValue[]).map(el => el.value).join(',');
      parsedFilters = {...parsedFilters, [key as keyof GetAlertsParams]: parsedValue};
    }
  }
  return parsedFilters;
}

export function getFiltersOfTheAlertsTableColumn(column: AlertsColumns) {
  const filters: AlertsFilters[] = [];
  switch (column) {
    case 'assets_count':
      filters.push(`${column}_min`, `${column}_max`, `is_${column}_null`);
      break;
    case 'start_datetime':
    case 'end_datetime':
      filters.push(`${column}_after`, `${column}_before`, `is_${column}_null`);
      break;
    case 'geographic_area_list':
      break;
    case 'title':
    case 'source_url':
      filters.push(`search_by_${column}`);
      break;
    default:
      filters.push(column);
      break;
  }
  return filters;
}

// COLUMNS
export const alertsColumns = [
  'assets_count',
  'end_datetime',
  'event',
  'event_type',
  'geographic_area_list',
  'is_forecast',
  'is_user_created',
  'source_url',
  'start_datetime',
  'title',
] as const;

export type AlertsColumns = (typeof alertsColumns)[number]
export const isAlertsColumns = (x: any): x is AlertsColumns => alertsColumns.includes(x);

export type AlertTableMode = 'managed' | 'toManage'
export const DEFAULT_ALERT_TO_MANAGE_COLUMNS: AlertsColumns[] = [
  'title',
  'event_type',
  'assets_count',
  'start_datetime',
];

// SORTERS
export type AlertsSortersFE = Partial<{
  [K in AlertsColumns]: Sorter
}>
