import {EyeInvisibleOutlined, EyeOutlined} from '@ant-design/icons';
import {Button, ButtonProps} from 'antd';
import {useState} from 'react';
import {capitalize} from 'lodash';
import ModalMessage from '../../common/documents/modals/ModalMessage';
import {useTranslation} from 'react-i18next';
import {useUpdateLayerMutation} from '../../../redux/api/layers';

export default function LayerVisibility({isActive, button, uuid}: {
  uuid: string,
  isActive: boolean,
  button?: ButtonProps
}) {

  const [isModalVisible, setIsModalVisible] = useState(false);
  const {t} = useTranslation();
  const [updateLayer] = useUpdateLayerMutation();
  const handleConfirm = () => {
    updateLayer({
      uuid,
      is_active: !isActive,
    });
    setIsModalVisible(false);
  };

  return <>
    <ModalMessage
      title={{
        children: capitalize(t('layers.hideLayerTitle')),
      }}
      paragraph={{
        children: t('layers.hideLayerDescription'),
      }}
      confirmButton={{
        danger: true,
        children: capitalize(t('layers.hideLayerConfirm')),
        onClick: handleConfirm,
      }}
      modal={{
        open: isModalVisible && isActive,
        onCancel: () => setIsModalVisible(false),
      }}
    />

    <ModalMessage
      title={{
        children: capitalize(t('layers.showLayerTitle')),
      }}
      paragraph={{
        children: t('layers.showLayerDescription'),
      }}
      confirmButton={{
        children: capitalize(t('layers.showLayerConfirm')),
        onClick: handleConfirm,
      }}
      modal={{
        open: isModalVisible && !isActive,
        onCancel: () => setIsModalVisible(false),
      }}
    />
    <Button
      size={'large'}
      type={'text'}
      icon={isActive ? <EyeOutlined/> : <EyeInvisibleOutlined/>}
      onClick={() => setIsModalVisible(true)}
      {...button}/>
  </>;
}