import {ConfigProvider, ThemeConfig} from 'antd';
import {PropsWithChildren} from 'react';

// set common style for the ant components
export const THEME: ThemeConfig = {
  components: {
    Layout: {
      headerHeight: 50,
      headerPadding: '0 24px',
      zeroTriggerHeight: 83,
      zeroTriggerWidth: 26,
    },
    Typography: {
      titleMarginTop: 0,
      titleMarginBottom: 0,
    },
    /*Note that to maintain the style proposed in the mockups, the "light" style of the menu has been modified to use dark colors. The analogous thing was done with the submenu configured as "dark" to avoid style conflicts.*/
    Menu: {
      itemBg: 'transparent',
      itemColor: '#fff',
      itemHoverColor: 'rgba(255, 255, 255, 0.80)',
      itemSelectedColor: '#5da4ff',
      itemSelectedBg: 'transparent',
      horizontalItemSelectedColor: '#5da4ff',
      darkItemColor: 'rgba(0, 0, 0, 0.88)',
      darkItemHoverColor: '#5da4ff',
      darkItemBg: '#fff',
      darkSubMenuItemBg: '#fff',
      iconSize: 16,
      darkItemSelectedBg: '#e6f4ff',
      darkItemSelectedColor: '#1677ff',
      itemMarginInline: 0,
      itemPaddingInline: 0,
    },
    Notification: {
      zIndexPopup: 100000,
    },
    Message: {
      zIndexPopup: 100000,
    },
    Steps: {
      iconSizeSM: 21,
    },
    Image: {
      zIndexPopup: 100000,
    },
  },
  token: {
    // colorPrimary: '#00b96b'
    marginLG: 8,
    colorBgContainerDisabled: 'rgba(0,0,0,0.04)',
    zIndexPopupBase: 100000,
  },
};
export default function AntTheme(props: PropsWithChildren) {
  return <ConfigProvider theme={THEME} children={props.children}/>;
}