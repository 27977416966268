import {PropsWithChildren} from 'react';
import {AssetDetails} from './AssetDetails';
import {AdditionalAssetDataContext} from './AdditionalAssetDataContext';

export const AssetContext = (props: PropsWithChildren) => {
  return (
    <AssetDetails>
      <AdditionalAssetDataContext>
        {props.children}
      </AdditionalAssetDataContext>
    </AssetDetails>
  );
};