import {Col, Form, FormProps, Row} from "antd";
import CommunicationSubject from "./form/CommunicationSubject";
import CommunicationTag from "./form/CommunicationTag";
import CommunicationBody from "./form/CommunicationBody";

export default function CommunicationForm(form: FormProps) {

  return <>
    <Form
      layout={'vertical'}
      {...form}
    >
      <Row gutter={12}>
        <Col xs={24} sm={12}>
          <CommunicationSubject/>
        </Col>
        <Col xs={24} sm={12}>
          <CommunicationTag/>
        </Col>
      </Row>
      <CommunicationBody/>
    </Form>
  </>
}