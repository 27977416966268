import {OptionLabelValue} from '../OptionLabelValue';
import {Sorter} from './sorters';

export type AlertLogsTableColumns =
  'alert' |
  'date_creation' |
  'alert_not_created' |
  'outcome';

export type AlertLogsTableSorters =
  'date_creation' |
  'alert_not_created' |
  'outcome';

export type AlertLogsTableSearchFilters = Pick<Record<AlertLogsTableColumns, string | null>,
  'alert_not_created'>;

export type AlertLogsTableDateRangeFilters = Pick<Record<AlertLogsTableColumns, [string | undefined, string | undefined]>,
  'date_creation'>

export type AlertLogsTableCheckboxFilters = Pick<Record<AlertLogsTableColumns, OptionLabelValue[] | null>,
  'outcome'>;

export type AlertsLogTableFilters =
  AlertLogsTableSearchFilters
  & AlertLogsTableCheckboxFilters
  & AlertLogsTableDateRangeFilters;
export type AlertsLogTableSorters = { [K in AlertLogsTableSorters]: Sorter }

export const DEFAULT_ALERT_LOGS_TABLE_FILTERS: AlertsLogTableFilters = {
  date_creation: [undefined, undefined],
  alert_not_created: null,
  outcome: ["OK", "NUT", "NNA", "KO", "NAE"].map(el => ({label: "", value: el})),
};

export const DEFAULT_ALERT_LOGS_TABLE_SORTERS: AlertsLogTableSorters = {
  date_creation: null,
  alert_not_created: null,
  outcome: null,
};