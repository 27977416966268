import {createContext, PropsWithChildren, useContext, useEffect} from 'react';
import {useGetAssetMapQuery} from '../../redux/api/assetsMap';
import {useSelector} from 'react-redux';
import {MarkerOrNull} from '../../types/internal/Map';
import {selectMapFilters} from '../../redux/slices/mapFilters';
import {useMapLoadingContext} from '../baseMap/MapLoadingContext';
import {selectAssets, selectAssetsTableFilters} from '../../redux/slices/assets';
import {fromFeToBeAssetsFilters} from '../../types/internal/tables/assetsTable';

export type MarkersContextType = {
  markers: MarkerOrNull[] | undefined,
};

const AssetMapMarkersContext = createContext<MarkersContextType>({
  markers: undefined,
});

export const useMarkersContext = () => useContext(AssetMapMarkersContext);
export const MarkersContext = (props: PropsWithChildren) => {
  
  const filters = useSelector(selectAssetsTableFilters);
  const excludeFilters = useSelector(selectAssets).assetsTable.filtersInExcludeMode;
  const polygonFilter = useSelector(selectMapFilters).polygons;
  
  const [, setIsMapLoading] = useMapLoadingContext();
  
  const {
    data,
    isFetching,
    isLoading,
  } = useGetAssetMapQuery({
    filters: {
      ...fromFeToBeAssetsFilters(filters, excludeFilters),
      ...!!polygonFilter?.length && {polygon_uuid: polygonFilter.map(polygon => polygon.globalpolygon).join(',')},
    },
  });
  
  useEffect(() => {
    setIsMapLoading(isFetching || isLoading);
  }, [isFetching, isLoading, setIsMapLoading]);
  
  return (
    <AssetMapMarkersContext.Provider
      value={
        {
          markers: data,
        }
      }
    >
      {props.children}
    </AssetMapMarkersContext.Provider>
  );
};