import StandardFilter from '../../../common/filters/StandardFilter';
import {OptionLabelValue} from '../../../../types/internal/OptionLabelValue';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';

export default function IsUserCreatedFilter({activeFilters, handleFilterChange}: {
  handleFilterChange: (values: OptionLabelValue[]) => void,
  activeFilters?: OptionLabelValue[],
}) {
  const {t} = useTranslation();

  const OPTIONS: OptionLabelValue[] = [
    {label: capitalize(t('assets.isUserCreated')), value: 'true'},
    {label: capitalize(t('assets.isNotUserCreated')), value: 'false'},
  ];

  return <StandardFilter
    selectedFilters={activeFilters}
    filters={OPTIONS}
    onChange={handleFilterChange}
  />;
}
