import {Checkbox, Input, Tag, Typography} from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useEffect, useState} from 'react';
import {OptionLabelValue} from '../../../types/internal/OptionLabelValue';
import {CustomLoading} from '../CustomLoading';
import {SearchProps} from 'antd/lib/input';
import {useTranslation} from 'react-i18next';
import _, {capitalize} from 'lodash';

export default function DEPRECATEDListWithSearchFilter({
                                                         getOptions,
                                                         fetchedOptions,
                                                         selectedOptions,
                                                         setSelectedOptions,
                                                         total,
                                                         isLoadingOptions,
                                                         search,
                                                         hasNullOption,
                                                       }:
                                                         {
                                                           getOptions: (page: number, q?: string) => void,
                                                           fetchedOptions: OptionLabelValue[],
                                                           selectedOptions: OptionLabelValue[],
                                                           setSelectedOptions: (so: OptionLabelValue[]) => void,
                                                           total: number,
                                                           isLoadingOptions: boolean,
                                                           search?: SearchProps,
                                                           hasNullOption?: boolean,
                                                         }) {

  const {t} = useTranslation();
  const [currentOptions, setCurrentOptions] = useState<OptionLabelValue[]>(hasNullOption ? [{
    label: capitalize(t('misc.nullValue')),
    value: 'null',
  }] : []);
  const [page, setPage] = useState<number>(1);
  const [querySearch, setQuerySearch] = useState<string>('');

  useEffect(() => {
    setCurrentOptions(prevState => [...prevState, ...fetchedOptions]);
  }, [fetchedOptions]);

  const onSearch = (value: string) => {
    if (value !== querySearch) {
      setCurrentOptions(hasNullOption ? [{label: capitalize(t('misc.nullValue')), value: 'null'}] : []);
      setPage(1);
      setQuerySearch(value);
      if (value) {
        getOptions(1, value);
      } else {
        getOptions(1);
      }
    }
  };

  const debouncedSearch = _.debounce(onSearch, 400);

  const removeItem = (value: string | number | boolean) => {
    setSelectedOptions(selectedOptions.filter(opt => opt.value !== value));
  };

  const insertItem = (opt: OptionLabelValue) => {
    setSelectedOptions([...selectedOptions, opt]);
  };

  return <>

    <Input.Search onSearch={onSearch} onChange={(e) => debouncedSearch(e.target.value)} {...search}/>
    <div
      style={{display: 'flex', flexWrap: 'wrap', width: '15rem', gap: '0.5rem', padding: '0.5rem 0'}}
    >
      {selectedOptions.map((so, idx) => (
        <Tag
          style={{margin: 0}}
          color={'processing'}
          key={so.value.toString()}
          closable
          onClose={() => removeItem(so.value)}
        >
          <Typography.Text ellipsis style={{maxWidth: '10rem'}}>{so.label}</Typography.Text>
        </Tag>
      ))}
    </div>
    <InfiniteScroll
      dataLength={currentOptions.length} //This is important field to render the next data
      next={() => {
        if (currentOptions.length) {
          const newPage = page + 1;
          querySearch ? getOptions(newPage, querySearch) : getOptions(newPage);
          setPage(newPage);
        }
      }}
      hasMore={currentOptions.length < total}
      scrollableTarget={'scrollableDiv'}
      loader={null}
      height={'10rem'}
    >
      {currentOptions.map((opt, idx) => (
        <div key={opt.value}>
          <Checkbox
            checked={selectedOptions.some((el => el.value === opt.value))}
            onChange={event => {
              const isChecked = event.target.checked;
              isChecked ? insertItem(opt) : removeItem(opt.value);
            }}
          >
            <Typography.Text
              ellipsis={{
                tooltip: true,
              }}
              style={{maxWidth: '10rem'}}
            >
              {opt.label}
            </Typography.Text>
          </Checkbox>
        </div>))
      }
      <div style={{display: 'flex', justifyContent: 'center', margin: '0.5rem 0'}}>
        {isLoadingOptions && <CustomLoading/>}
        {!isLoadingOptions && !currentOptions.length &&
          <Typography.Text>{capitalize(t('form.noResults'))}</Typography.Text>}
      </div>
    </InfiniteScroll>
  </>;

}