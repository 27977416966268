import {useEventContext} from "../../../context/event/EventContext";
import React from "react";
import {ClusterOption, NO_CLUSTER_VALUE} from "../importAlertsAreas/AssignCluster";
import {Form, Select, Typography} from "antd";
import {AssetsTableRecord} from "../../../types/api/assets";
import {getAssetAddress} from "../../../utils/assets";


export default function RemovePolygonAsset({assets, props}: { assets: AssetsTableRecord[], props?: any }) {

  const {clusters} = useEventContext()

  return <>{
    assets.map(asset => (
          <Form.Item
            name={asset.uuid}
            label={<Typography.Text strong>{getAssetAddress({
              'uuid': asset.uuid,
              'sigi_code': asset.sigi_code,
              'country': asset.country || "",
              'admin_level_1': asset.admin_level_1 || "",
              'admin_level_2': asset.admin_level_2 || "",
              'admin_level_3': asset.admin_level_3 || "",
              'address': asset.address || ""
            })}</Typography.Text>}
            key={asset.uuid}
          >
            <Select
              style={{width: '100%'}}
            >
              {
                clusters?.map(cluster => <Select.Option
                  key={cluster.uuid || 'noValue'}
                  value={cluster.uuid}
                >
                  <ClusterOption cluster={cluster}/>
                </Select.Option>)
              }
              <Select.Option value={NO_CLUSTER_VALUE}>
                <ClusterOption cluster={null}/>
              </Select.Option>
            </Select>
          </Form.Item>
    ))
  }</>
}