import {createContext, PropsWithChildren, useContext, useEffect} from 'react';
import {MarkerOrNull} from '../../types/internal/Map';
import {useMapLoadingContext} from '../baseMap/MapLoadingContext';
import {useParams} from 'react-router-dom';
import {useLazyGetAlertAssetsQuery} from '../../redux/api/alerts';

export type AlertMarkersContextType = {
  markers: MarkerOrNull[] | undefined,
};

const AlertMapMarkersContext = createContext<AlertMarkersContextType>({
  markers: undefined,
});

export const useAlertMarkersContext = () => useContext(AlertMapMarkersContext);
export const AlertMarkersContext = (props: PropsWithChildren) => {

  const {uuid} = useParams();
  const [, setIsMapLoading] = useMapLoadingContext();

  const [getData, {
    data,
    isFetching,
    isLoading,
  }] = useLazyGetAlertAssetsQuery();

  useEffect(() => {
    if (uuid) {
      getData({uuid});
    }
  }, [getData, uuid]);

  useEffect(() => {
    if (isFetching || isLoading) {
      setIsMapLoading(true);
    } else {
      setIsMapLoading(false);
    }
  }, [isFetching, isLoading, setIsMapLoading]);

  return (
    <AlertMapMarkersContext.Provider
      value={
        {
          markers: data,
        }
      }
    >
      {props.children}
    </AlertMapMarkersContext.Provider>
  );
};