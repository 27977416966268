import {AssetModel} from '../../types/internal/AssetModel';
import {Button, Col, Empty, List, Row, Skeleton, Switch, Typography} from 'antd';
import _, {capitalize} from 'lodash';
import {ReloadOutlined, SyncOutlined} from '@ant-design/icons';
import {useCallback, useEffect, useState} from 'react';
import {useGetHazardTypesQuery} from '../../redux/api/hazardTypes';
import {useTranslation} from 'react-i18next';
import {useGetAssetHazardsQuery, useHazardsReadyQuery, useRequestHazardsUpdateMutation} from '../../redux/api/asset';
import {HazardListItem} from './cards/Hazard';
import {skipToken} from '@reduxjs/toolkit/query';

const {Title, Text} = Typography;
export const IndentifiedHazard = ({asset}: { asset: AssetModel }) => {

  const {t} = useTranslation();

  const [
    isIndetailedView,
    setIsIndetailedView,
  ] = useState(false);
  const [
    isPolling,
    setIsPolling,
  ] = useState(false);

  const {
    data: hazards,
    isLoading: isLoadingAssetHazards,
    isFetching: isFetchingAssetHazards,
    refetch: refetchHazard,
  } = useGetAssetHazardsQuery({assetUuid: asset.uuid});

  const {
    data: hazardTypes,
    isLoading: isLoadingHazardTypes,
    isFetching: isFetchingHazardTypes,
  } = useGetHazardTypesQuery();

  const
    {
      data: hazardsStatus,
      isError: hazardStatusFailed,
    } = useHazardsReadyQuery((isPolling) ? {assetUuid: asset.uuid} : skipToken, {pollingInterval: 1500});

  const [
    requestHazardsUpdate,
    {isError: hasHazardRequestFailed},
  ] = useRequestHazardsUpdateMutation();

  useEffect(() => {
    if (hazardsStatus?.status === 'OK') {
      setIsPolling(false);
      refetchHazard();
    }
    if (hazardsStatus?.status === 'ERROR' || hazardStatusFailed || hasHazardRequestFailed) {
      setIsPolling(false);
    }
  }, [refetchHazard, hazardsStatus?.status, hazardStatusFailed, hasHazardRequestFailed]);

  const updateHazards = useCallback((assetUuid: string) => {
    setIsPolling(true);
    requestHazardsUpdate({uuid: assetUuid});
  }, [requestHazardsUpdate]);

  const loading =
    isLoadingAssetHazards ||
    isFetchingAssetHazards ||
    isLoadingHazardTypes ||
    isFetchingHazardTypes;

  return (
    <>
      <Row justify={'space-between'} align={'middle'} style={{marginBottom: '1rem'}}>
        <Col>
          <Title level={5} style={{marginTop: 0}}>
            {capitalize(t('hazards.analyzedHazards'))}
          </Title>
        </Col>
        <Col>
          <Switch
            checkedChildren={capitalize(t('misc.detailed'))}
            unCheckedChildren={capitalize(t('misc.base'))}
            onChange={setIsIndetailedView}
            checked={isIndetailedView}
          />
        </Col>
      </Row>
      {loading && <Skeleton active={true}/>}
      {isPolling && (
        <Empty
          image={<SyncOutlined spin={true} style={{fontSize: 20, color: '#c9c9c9'}}/>}
          imageStyle={{height: '100%'}}
          description={
            <Text type={'secondary'}>
              {capitalize(t('hazards.updatingHazards'))}
            </Text>
          }
        />
      )}
      {!loading && !isPolling && (
        <Row>
          <Col flex={1}>
            <List
              grid={{gutter: 16, column: 1}}
              dataSource={hazards}
              renderItem={hazard => (
                <List.Item>
                  <HazardListItem
                    hazard={hazard}
                    hazardType={hazardTypes?.find(hazardType => hazardType.uuid === hazard.hazard_type)}
                    isIndetailedView={isIndetailedView}
                  />
                </List.Item>
              )}
            />
          </Col>
        </Row>
      )}
      <Row justify={'end'} style={{marginTop: '1.5rem'}}>
        <Col>
          <Button
            type={'primary'}
            disabled={isPolling || _.isEmpty(asset.position)}
            onClick={() => updateHazards(asset.uuid)}
            icon={<ReloadOutlined spin={isPolling}/>}
          >
            {capitalize(t('assets.reloadHazards'))}
          </Button>
        </Col>
      </Row>
    </>
  );
};