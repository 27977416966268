import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Marker, Popup, useMap} from 'react-leaflet';
import L, {Marker as LeafletMarker} from 'leaflet';
import {CheckOutlined, DeleteOutlined, DragOutlined} from '@ant-design/icons';
import {Button, Space} from 'antd';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
import {useEventContext} from "../../../context/event/EventContext";
import {useGetEventTypeQuery} from "../../../redux/api/eventTypes";
import {skipToken} from "@reduxjs/toolkit/query";
import {usePatchEventMutation} from "../../../redux/api/events";
import {useMessageContext} from "../../../context/message/MessageContext";
import {useMapLoadingContext} from "../../../context/baseMap/MapLoadingContext";
import EpicenterInfo from "../../common/map/EpicenterInfo";

const src = process.env.REACT_APP_MEDIA;
const defaultIcon = {
  iconUrl: src,
  iconSize: new L.Point(30, 30),
};
export default function EpicenterMarker({isEdit = false}: {isEdit?: boolean}) {

  const markerRef = useRef<LeafletMarker | null>(null);

  const {t} = useTranslation();
  const map = useMap();
  const {event} = useEventContext()
  const {data} = useGetEventTypeQuery(event?.event_type ? {uuid: event?.event_type} : skipToken);
  const [icon, setIcon] = useState(defaultIcon)
  const [patchEvent, {isSuccess, isLoading, isError}] = usePatchEventMutation()
  const setMessage = useMessageContext()
  const [isLoadingMap, setIsLoadingMap] = useMapLoadingContext()
  const [
    isMarkerDraggable,
    setIsMarkerDraggable,
  ] = useState(false);
  const [
    coordinates,
    setCoordinates,
  ] = useState<{ lat: number, lng: number } | null>(null);

  useEffect(() => {
    if (isSuccess) {
      setMessage({success: capitalize(t('form.editSuccessMessage'))})
      setIsLoadingMap(false)
    }
  }, [isSuccess, setIsLoadingMap, setMessage, t]);

  useEffect(() => {
    if (isError) {
      setMessage({error: capitalize(t('form.editFailMessage'))})
      setIsLoadingMap(false)
    }
  }, [setIsLoadingMap, isError, setMessage, t]);

  const handleRemoveEpicenter = () => {
    if (event?.uuid) {
      setIsLoadingMap(true)
      setCoordinates(null)
      map.closePopup();
      patchEvent({epicenter: null, uuid: event?.uuid})
    }
  }
  const handleInsertEpicenter = ({lat, lng}: { lat: number, lng: number }) => {
    if (event?.uuid) {
      setIsLoadingMap(true)
      patchEvent({epicenter: {lat, lng}, uuid: event?.uuid})
    }
  }

  const onConfirmDrag = () => {
    if (coordinates) {
      setIsLoadingMap(true)
      setIsMarkerDraggable(draggable => !draggable);
      map.closePopup();
      handleInsertEpicenter(coordinates)
    }
  }

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        if (markerRef.current) {
          setCoordinates({lat: markerRef.current.getLatLng().lat, lng: markerRef.current.getLatLng().lng});
        }
      },
      contextmenu() {
        markerRef.current?.openPopup();
      },
    }),
    [],
  );

  useEffect(() => {
    if (data?.icon) {
      setIcon({
        ...defaultIcon,
        iconUrl: src + data.icon.svg,
      })
    }
  }, [data?.icon]);

  useEffect(() => {
    setCoordinates(event?.epicenter || null)
  }, [event?.epicenter]);

  return (
    <>
      {coordinates && (
        <Marker
          icon={new L.Icon(icon)}
          position={coordinates}
          eventHandlers={eventHandlers}
          ref={markerRef}
          draggable={isMarkerDraggable}
        >
          <Popup className={'asset-position-preview-marker-popup'}>
            {!!event?.epicenter && <EpicenterInfo lat={event?.epicenter?.lat} lng={event?.epicenter?.lng} />}
            {isEdit && <Space style={{marginTop: '3rem'}}>
              {!isMarkerDraggable && (
                <Button
                  type={'default'}
                  disabled={isMarkerDraggable}
                  onClick={() => {
                    setIsMarkerDraggable(draggable => !draggable);
                    map.closePopup();
                  }}
                  icon={<DragOutlined/>}
                >
                  {capitalize(t('misc.correctPosition'))}
                </Button>)}
              {isMarkerDraggable && (
                <Button
                  type={'primary'}
                  disabled={!isMarkerDraggable || !coordinates}
                  onClick={onConfirmDrag}
                  icon={<CheckOutlined/>}
                >
                  {capitalize(t('misc.confirmPosition'))}
                </Button>)}

              <Button
                type={'default'}
                danger={true}
                onClick={handleRemoveEpicenter}
                icon={<DeleteOutlined/>}
              >
                {capitalize(t('form.delete'))}
              </Button>
            </Space>}
          </Popup>
        </Marker>
      )}
    </>
  );
}