import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import ResponsiveContent from '../../../layout/ResponsiveContent';
import {capitalize} from 'lodash';
import LayerForm from '../../../components/settings/layers/LayerForm';
import {useCreateExtLayerMutation} from '../../../redux/api/layers';
import {Alert, Typography} from 'antd';
import {useMessageContext} from '../../../context/message/MessageContext';

export default function CreateLayer() {

  const {t} = useTranslation();
  const navigate = useNavigate();
  const [createExtLayer, {
    isLoading: extLoading,
    isSuccess: extSuccess,
    isError: extError,
    error: extErrorDescription,
  }] = useCreateExtLayerMutation();
  const alertText = (extErrorDescription && 'status' in extErrorDescription && extErrorDescription.status === 400 && (extErrorDescription.data as {
    status: string,
    text: string
  }).text) || '';

  const setAntdMessage = useMessageContext();

  useEffect(() => {
    if (extSuccess) {
      navigate('/settings/layers/');
      setAntdMessage({
        success: capitalize(t('form.creationSuccessMessage')),
      });
    }
  }, [extSuccess, navigate, setAntdMessage, t]);

  useEffect(() => {
    if (extError) {
      setAntdMessage({
        error: capitalize(t('form.creationFailMessage')),
      });
    }
  }, [extError, setAntdMessage, t]);

  return <>
    {!!alertText && <Alert
      message={alertText}
      type="error"
      showIcon
    />}
    <ResponsiveContent
      goBack={() => navigate('/settings/layers')}
      goBackText={capitalize(t('layers.layersManagement'))}
    >
      <LayerForm
        card={{
          title: <Typography.Title
            level={3}
            style={{margin: '1rem 0'}}
          >{capitalize(t('actions.createItem', {item: t('layers.layer', {count: 1})}))}</Typography.Title>,
        }}
        button={{
          children: capitalize(t('actions.createItem', {item: t('layers.layer', {count: 1})})),
        }}
        form={{
          disabled: extLoading || extSuccess,
          onFinish: (values) => {
            const {upload_mode, ...params} = values;
            if (upload_mode === 'ext') {
              createExtLayer(params);
            } else {
              navigate('/settings/layers/upload-file', {state: {...params, type: 'creation'}});
            }
          },
        }}
      />

    </ResponsiveContent>
  </>;
}