import {Button, ButtonProps} from 'antd';
import {EditOutlined} from '@ant-design/icons';
import {capitalize} from 'lodash';
import React from 'react';
import {useTranslation} from 'react-i18next';

export default function CreateButton(button?: ButtonProps) {

  const {t} = useTranslation();

  return <Button
    icon={<EditOutlined/>}
    type={'primary'}
    children={capitalize(t('actions.create'))}
    {...button}
  />;
}