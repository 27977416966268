import { Radio, Space} from "antd";
import InsertEntitySelect from "./InsertEntitySelect";
import CutEntitySelect from "./CutEntitySelect";
import {Cluster} from "../../../types/api/clusters";
import {useState} from "react";
import {PlusOutlined, ScissorOutlined} from "@ant-design/icons";
import {capitalize} from "lodash";
import {useTranslation} from "react-i18next";

export default function ClusterEntities({cluster}: { cluster: Cluster }) {

  const [type, setType] = useState<'cut' | 'insert'>('insert')
  const {t} = useTranslation()

  return <>
    <Radio.Group
      value={type}
      onChange={e => setType(e.target.value)}
      style={{marginBottom: '0.5rem'}}
      buttonStyle="solid"
    >
      <Radio.Button
        value={'insert'}
      >
        <Space align={'center'}>
          <PlusOutlined/>
          {capitalize(t('events.insertEntity'))}
        </Space>
      </Radio.Button>
      <Radio.Button
        type={'primary'}
        value={'cut'}
      >
        <Space align={'center'}>
          <ScissorOutlined/>
          {capitalize(t('events.cutEntity'))}
        </Space>
      </Radio.Button>
    </Radio.Group>
    {type === 'insert' && <InsertEntitySelect cluster={cluster}/>}
    {type === 'cut' && <CutEntitySelect cluster={cluster}/>}
  </>
}