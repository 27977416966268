import dayjs, {Dayjs} from 'dayjs';
import DateRangeFilter from '../../../common/filters/DateRangeFilter';

export const DocumentsEndDateFilter = ({activeFilters, handleFilterChange}: {
  activeFilters: [string, string] | null,
  handleFilterChange: (values: [dayjs.Dayjs, dayjs.Dayjs]) => void,
}) => {

  let start: Dayjs | null = null;
  let end: Dayjs | null = null;
  if (activeFilters) {
    start = dayjs(activeFilters[0]);
    end = dayjs(activeFilters[1]);
  }

  return (
    <DateRangeFilter
      selectedFilters={start && end ? [start, end] : null}
      onChange={handleFilterChange}
    />
  );
};