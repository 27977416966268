import {Button, Pagination, Popover, Space, Table, Tag, Typography} from 'antd';
import React, {useEffect, useMemo} from 'react';
import {ColumnsType} from 'antd/lib/table';
import AssetsTableColumnHeader from './AssetsTableColumnHeader';
import {useGetAssetsOptionsQuery, useLazyGetAssetsQuery} from '../../../redux/api/assets';
import {Link, useNavigate} from 'react-router-dom';
import {AssetsTableRecord, BIODIVERSITY_WITHIN, ContactPeople} from '../../../types/api/assets';
import AssetsTableColumnsSelection from './AssetsTableColumnsSelection';
import {HomeOutlined, RightOutlined} from '@ant-design/icons';
import {useControlsAction} from '../../../context/baseMap/ControlContext';
import {ASSET_MAP_ACTIONS} from '../../common/map/constants/map';
import {useTranslation} from 'react-i18next';
import _, {capitalize} from 'lodash';
import {useMapDrawerContext} from '../../../context/assetsMap/MapDrawerContext';
import {CustomLoading} from '../../common/CustomLoading';
import {fromFeToBeSorter} from '../../../utils/parser';
import {
  removeFilters, selectAssets,
  selectAssetsTableColumns,
  selectAssetsTableFilters,
  selectAssetsTablePagination,
  selectAssetsTableSorters,
  setPagination,
} from '../../../redux/slices/assets';
import {useDispatch, useSelector} from 'react-redux';
import ResponsiveContent from '../../../layout/ResponsiveContent';
import DateFormat from '../../../locales/DateFormat';
import {selectMapFilters} from '../../../redux/slices/mapFilters';
import ResetFiltersSortersAssetsTable from './ResetFiltersSortersAssetsTable';
import {
  AssetsTableStaticColumn,
  fromFeToBeAssetsFilters,
  getFiltersOfTheAssetsTableStaticColumn,
  isAssetsTableStaticColumn,
} from '../../../types/internal/tables/assetsTable';
import {useGetHazardTypeClassesQuery} from '../../../redux/api/hazardTypes';
import DownloadAssetsAndHazardsReport from './DownloadAssetsAndHazardsReport';
import {isReportsCreation, isUserCreatedAssetCreation} from '../../Authentication/permissions';
import PermissionRoute from '../../Authentication/PermissionRoute';
import {VALUE_NOT_PRESENT} from "../../../utils/translation";

export default function AssetsTable() {

  const {t} = useTranslation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useMapDrawerContext();
  const dispatch = useDispatch();

  const [getAssets, {data: assets, isFetching, isUninitialized}] = useLazyGetAssetsQuery();
  //const {data: statistics} = useGetImportReportsStatisticsQuery()
  const {data: assetsOptions} = useGetAssetsOptionsQuery();

  const {changeAction} = useControlsAction();

  const filters = useSelector(selectAssetsTableFilters);
  const sorters = useSelector(selectAssetsTableSorters);
  const pagination = useSelector(selectAssetsTablePagination);
  const columns = useSelector(selectAssetsTableColumns);

  const polygonFilter = useSelector(selectMapFilters).polygons;
  const excludeFilters = useSelector(selectAssets).assetsTable.filtersInExcludeMode;


  const {data: options, isLoading} = useGetAssetsOptionsQuery();
  //const errors = statistics?.errors || 0
  //const warnings = (statistics?.warnings || 0)

  const {
    data: hazardTypes,
    isFetching: isFetchingHazardTypes,
  } = useGetHazardTypeClassesQuery();

  const getDefaultColumnProps = (column: AssetsTableStaticColumn) => ({
    dataIndex: column,
    key: column,
    sorter: false,
    filter: false,
    title: <AssetsTableColumnHeader column={column}/>,
    width: 200,
  });

  const hazardColumns: ColumnsType<AssetsTableRecord> = useMemo(() => hazardTypes ? hazardTypes.map(ht => ({
    dataIndex: ['hazards', ht.code],
    key: ht.code,
    title: <AssetsTableColumnHeader column={ht.code}/>,
    sorter: false,
    filter: false,
    width: 20,
    render: (v: number) => {
      switch (v) {
        case (-2):
          return VALUE_NOT_PRESENT;
        case (-1):
          return 'n.d.';
        default:
          return v;
      }
    },
  })) : [], [hazardTypes]);

  const allTheColumns: ColumnsType<AssetsTableRecord> = useMemo(() => [
    {
      ...getDefaultColumnProps('address'),
      render: (value) => <div style={{textTransform: 'capitalize'}}>{value ? value.toLowerCase() : ''}</div>,
    },
    {
      ...getDefaultColumnProps('admin_level_1'),
      render: (value) => <div style={{textTransform: 'capitalize'}}>{value ? value.toLowerCase() : ''}</div>,
    },
    {
      ...getDefaultColumnProps('admin_level_2'),
    },
    {
      ...getDefaultColumnProps('admin_level_3'),
      render: (value) => <div style={{textTransform: 'capitalize'}}>{value ? value.toLowerCase() : ''}</div>,
    },
    {
      ...getDefaultColumnProps('building_type'),
    },
    {
      ...getDefaultColumnProps('building_type_detail'),
    },
    {
      ...getDefaultColumnProps('closing_date'),
      render: (value) => {
        return <DateFormat>{value}</DateFormat>;
      },
    },
    {
      ...getDefaultColumnProps('companies'),
      render: (companies: string[] | null) => {
        if (companies) {
          return <Space size={10} wrap>
            {companies.map((c, idx) => (
              <Tag
                style={{width: '12rem', textAlign: 'center'}}
                key={idx}
              >
                <Typography.Text
                  ellipsis={{tooltip: true}}
                >
                  {c}
                </Typography.Text>
              </Tag>),
            )}
          </Space>;
        }
      },
    },
    {
      ...getDefaultColumnProps('contact_people'),
      render: (cp: ContactPeople[] | null) => {
        if (cp) {
          if (cp.length === 1) {
            return <Popover
              content={
                <div style={{display: 'flex', flexDirection: 'column', gap: '0.25rem'}}>
                  <Typography.Text strong>{cp[0].full_name}</Typography.Text>
                  <Typography.Text>{cp[0].email}</Typography.Text>
                  <Typography.Text>{cp[0].phone_number}</Typography.Text>
                </div>
              }
            >
              <span style={{textTransform: 'capitalize'}}>{cp[0].full_name.toLowerCase()}</span>
            </Popover>;
          } else {
            return <ul
              style={{padding: 0, listStyleType: 'none'}}
              onClick={e => e.stopPropagation()}
            >
              {cp.map((c, idx) => <Popover
                key={idx}
                content={
                  <div style={{display: 'flex', flexDirection: 'column', gap: '0.25rem'}}>
                    <Typography.Text strong>{c.full_name}</Typography.Text>
                    <Typography.Text>{c.email}</Typography.Text>
                    <Typography.Text>{c.phone_number}</Typography.Text>
                  </div>
                }
              >
                <li
                  key={idx}
                  style={{textTransform: 'capitalize'}}
                >
                  {c.full_name.toLowerCase()}
                </li>
              </Popover>)}
            </ul>;
          }
        }
      },
    },
    {
      ...getDefaultColumnProps('country'),
    },
    {
      ...getDefaultColumnProps('is_user_created'),
      render: (value: boolean | null) => {
        if (typeof value === 'boolean') {
          return capitalize(t(value ? 'assets.isUserCreated' : 'assets.isNotUserCreated'));
        }
      },
    },
    {
      ...getDefaultColumnProps('ownership'),
      render: (v: string) => {
        return capitalize(assetsOptions?.ownership.find(option => option.value === v)?.label || '');
      },
    },
    {
      ...getDefaultColumnProps('map_view'),
      width: 20,
      render: (_, record) => {
        if (record.position) {
          return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <Button
                type={'primary'}
                size={'small'}
                shape={'circle'}
                icon={<RightOutlined/>}
                onClick={(event) => {
                  event.stopPropagation();
                  changeAction(ASSET_MAP_ACTIONS.SELECT_MARKER, record.uuid);
                }}
              />
            </div>);
        }
      },
    },
    {
      ...getDefaultColumnProps('master_asset'),
    },
    {
      ...getDefaultColumnProps('opening_date'),
      render: (value) => {
        return <DateFormat>{value}</DateFormat>;
      },
    },
    {
      ...getDefaultColumnProps('ownership'),
      render: (value: string) => capitalize(options?.ownership.find(el => el.value === value)?.label || ''),
    },
    {
      ...getDefaultColumnProps('postal_code'),
    },
    {
      ...getDefaultColumnProps('realestate_garrison'),
    },
    {
      ...getDefaultColumnProps('realestate_hub'),
    },
    {
      ...getDefaultColumnProps('sigi_code'),
    },
    {
      ...getDefaultColumnProps("biodiversity_within"),
      render: (v, record) => {
        let parsedValue = "";
        switch (record.biodiversity_within) {
          case BIODIVERSITY_WITHIN.OUT:
            parsedValue = capitalize(t('misc.no'))
            break;
          case BIODIVERSITY_WITHIN.NA:
            parsedValue = VALUE_NOT_PRESENT
            break;
          case BIODIVERSITY_WITHIN.IN:
            parsedValue = capitalize(t('misc.yes'))
            break;
        }
        return parsedValue
      }
    },
    {
      ...getDefaultColumnProps('surface_basement'),
    },
    {
      ...getDefaultColumnProps('surface_total'),
    },
    {
      ...getDefaultColumnProps('total_resources'),
    },
    ...hazardColumns,
  ], [assetsOptions?.ownership, changeAction, hazardColumns, options?.ownership, t]);

  const antdColumns: ColumnsType<AssetsTableRecord> = useMemo(() => columns.reduce(
      (prev, curr) => {
        const columnToInsert = allTheColumns.find(el => el.key === curr);
        if (columnToInsert) {
          return [...prev, columnToInsert];

        } else {
          return [...prev];
        }
      }, [] as ColumnsType<AssetsTableRecord>)
    , [columns, allTheColumns]);

  //todo
  // get preferences from BE and set columns / filters / sorters
  // get data from columns / filters / sorters set

  useEffect(() => {
    getAssets({
      page: pagination.current,
      page_size: pagination.pageSize,
      filters: {
        ...fromFeToBeAssetsFilters(filters, excludeFilters),
        ...!!polygonFilter?.length && {polygon_uuid: polygonFilter.map(polygon => polygon.globalpolygon).join(',')},
      },
      sorters: fromFeToBeSorter(sorters),
    });
  }, [excludeFilters, filters, getAssets, pagination, polygonFilter, sorters]);

  useEffect(() => {
    // remove filters if the column is not present

    let filtersToRemove: string[] = [];
    for (const [key, value] of Object.entries(filters)) {
      if (key === 'hazards') {
        if (filters.hazards) {
          const hazardsFilters = Object.keys(filters.hazards);
          filtersToRemove.push(...hazardsFilters);
        }
      } else {
        filtersToRemove.push(key);
      }
    }
    columns.forEach((col) => {
      if (isAssetsTableStaticColumn(col)) {
        filtersToRemove = _.difference(filtersToRemove, getFiltersOfTheAssetsTableStaticColumn(col));
      } else {
        filtersToRemove = _.difference(filtersToRemove, [col]);
      }
    });
    if (filtersToRemove.length) {
      dispatch(removeFilters(filtersToRemove));
    }
  }, [columns, dispatch, filters]);

  return (
    <>
      {/*!!errors && <Alert
            message={
              <Space>
                {capitalize(t('dataImport.reportAlert', {numberAssets: errors + warnings})) + ':'}
                <Typography.Text type={'danger'}
                                 strong>{t('dataImport.reportError', {number: errors})}</Typography.Text>
                -
                <Typography.Text>{t('dataImport.reportWarning', {number: warnings})}</Typography.Text>
                <Button
                    type={'link'}
                    onClick={() => navigate('/settings/import-report')}
                >
                  {capitalize(t('dataImport.viewErrors'))}...
                </Button>
              </Space>
            }
            type="warning"
            showIcon
            icon={<WarningOutlined/>}
        />*/}
      <ResponsiveContent
        title={t('assets.assetsList')}
        extra={
          <PermissionRoute isAuthorized={isUserCreatedAssetCreation}>
            <Link to={'/assets/create'}>
              <Button
                type={'primary'}
                icon={<HomeOutlined/>}
              >
                {capitalize(t('assets.addAsset'))}
              </Button>
            </Link>
          </PermissionRoute>
        }
      >
        <div style={{display: 'flex', flexDirection: 'column', gap: '2rem'}}>
          <Space wrap>
            <ResetFiltersSortersAssetsTable/>
            <AssetsTableColumnsSelection/>
            {!isOpen && (
              <Button
                onClick={() => {
                  changeAction(ASSET_MAP_ACTIONS.OPEN_MAP_DRAWER);
                  setIsOpen(true);
                }}
              >
                {capitalize(t('map.openMap'))}
              </Button>
            )}
            {isOpen && (
              <Button
                onClick={() => {
                  changeAction(ASSET_MAP_ACTIONS.CLOSE_MAP_DRAWER);
                  setIsOpen(false);
                }}
              >
                {capitalize(t('map.closeMap'))}
              </Button>
            )}
            <PermissionRoute isAuthorized={isReportsCreation}>
              <DownloadAssetsAndHazardsReport/>
            </PermissionRoute>
          </Space>

          <Table
            <AssetsTableRecord>
            columns={antdColumns}
            dataSource={assets?.results}
            rowKey={'uuid'}
            size={'middle'}
            scroll={{x: true}}
            pagination={false}
            loading={
              {
                spinning: isFetching || isUninitialized || isFetchingHazardTypes,
                indicator: <CustomLoading/>,
              }
            }
            onRow={(record: AssetsTableRecord) => (
              {
                onClick: (e) => {
                  navigate(`${record.uuid}`);
                },
                style: {
                  cursor: 'pointer',
                },
              })
            }
          />
          <Pagination
            disabled={!assets?.count}
            showSizeChanger={true}
            total={assets?.count}
            showTotal={(total, range) => t('table.pageSizeOfTotal', {
              rangeStart: range[0],
              rangeEnd: range[1],
              total,
            })}
            onChange={(current, pageSize) => {
              dispatch(setPagination({current, pageSize}));
            }}
            {...pagination}
          />
        </div>
      </ResponsiveContent>
    </>
  );
}