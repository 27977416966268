import {OptionLabelValue} from '../OptionLabelValue';
import {GetAssetStatusParams} from '../../api/getEventsResult';

// FILTERS
const involvedAssetsTableOptionLabelValueFilters = [
  'address',
  'admin_level_1',
  'admin_level_2',
  'admin_level_3',
  'building_type',
  'country',
  'sigi_code',
  'state'
] as const;
export type InvolvedAssetsOptionLabelValueFilters = (typeof involvedAssetsTableOptionLabelValueFilters)[number];
export const isInvolvedAssetsOptionLabelValueFilters = (x: any): x is InvolvedAssetsOptionLabelValueFilters => involvedAssetsTableOptionLabelValueFilters.includes(x);

export type InvolvedAssetsFiltersFE = Partial<Record<InvolvedAssetsOptionLabelValueFilters, OptionLabelValue[]>> & {excludedFilters: InvolvedAssetsOptionLabelValueFilters[]}

export function fromFeToBeInvolvedAssetsTableFilters(tableFilters: InvolvedAssetsFiltersFE) {
  let parsedFilters: Omit<GetAssetStatusParams, 'eventUuid'> = {};
  for (const [key, value] of Object.entries(tableFilters)) {
    if (isInvolvedAssetsOptionLabelValueFilters(key)) {
      const parsedValue = (value as OptionLabelValue[]).map(el => el.value).join(key === 'address' ? '|' : ',');
      parsedFilters = {...parsedFilters, [!tableFilters.excludedFilters.includes(key) ? key : `${key}_not`]: parsedValue};
    }
  }
  return parsedFilters;
}

// COLUMNS
export const involvedAssetsColumnsTable = [
  'sigi_code',
  'country',
  'admin_level_1',
  'admin_level_2',
  'admin_level_3',
  'address',
  'building_type',
  'state'
] as const;

export type InvolvedAssetsTableColumn = (typeof involvedAssetsColumnsTable)[number]
