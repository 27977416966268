import {useTranslation} from "react-i18next";
import {Button, Col, Collapse, CollapseProps, ConfigProvider, Row, Skeleton, Space, theme, Typography} from "antd";
import {useNavigate} from "react-router-dom";
import {useAssetDetails} from "../../../context/assetDetails/AssetDetails";
import {useAdditionalAssetData} from "../../../context/assetDetails/AdditionalAssetDataContext";
import {useGetAssetsOptionsQuery} from "../../../redux/api/assets";
import {useGetUserProfileQuery} from "../../../redux/api/locales";
import {isAssetDocumentView, isAssetImageView} from "../../Authentication/permissions";
import {capitalize} from "lodash";
import {AssetLocation} from "../AssetLocation";
import {AssetDetail} from "../AssetDetail";
import {RealEstateData} from "../RealEstateData";
import {IndentifiedHazard} from "../IndentifiedHazard";
import {AssetsDocumentsListInAlert} from "../AssetsDocumentsListInAlert";
import {AssetsImagesListInAlert} from "../AssetsImagesListInAlert";
import {CaretRightOutlined} from "@ant-design/icons";
import {dateToLocaleString} from "../../../utils/dateParsers";
import DownloadAssetReport from "../DownloadAssetReport";
import BiodiversityTable from "../cards/BiodiversityTable";
import ClimateChangeContent from "../cards/climateChange/ClimateChangeContent";

const {Title, Text} = Typography;
export type GoBack = {
  path: string,
  label: string,
}

const GoToAssetButton = ({uuid, goBack}: { uuid: string, goBack?: GoBack  }) => {

  const {t} = useTranslation();
  const navigate = useNavigate();

  return <Button
    type={"primary"}
    onClick={() => {
      navigate(
        `/assets/${uuid}`,
        {
          state: goBack
        }
      )
    }}>
    {capitalize(t('misc.goToDetail'))}
  </Button>
}

export default function AssetDrawerContent({goBack}: {goBack?: GoBack}) {
  const {t} = useTranslation();
  const {token} = theme.useToken();

  const [, {asset: assetData, loading: isLoadingAssetData}] = useAssetDetails();
  const [additionalAssetData, isLoadingAdditionalData] = useAdditionalAssetData();

  const {
    data: options,
  } = useGetAssetsOptionsQuery();

  const {
    data: userData,
  } = useGetUserProfileQuery();

  const panelStyle: React.CSSProperties = {
    marginBottom: 12,
    background: "#FFF",
    borderRadius: token.borderRadiusLG,
    border: 'none',
  };

  const isAssetImages = userData?.type ? isAssetImageView(userData?.type) : false
  const isAssetDocuments = userData?.type ? isAssetDocumentView(userData?.type) : false
  const labelTitle = (label: string) => {
    return <Typography.Title level={4} style={{margin: 0}}>{label}</Typography.Title>
  }

  const items: CollapseProps["items"] = [
    {
      key: "assetLocation",
      label: labelTitle(capitalize(t("assets.assetLocation"))),
      children: (isLoadingAssetData ? <Skeleton active={true}/> : assetData && <AssetLocation asset={assetData}/>),
      style: panelStyle
    },
    {
      key: "assetDetails",
      label: labelTitle(capitalize(t("assets.assetsDetail"))),
      children: (isLoadingAssetData || isLoadingAdditionalData ?
        <Skeleton active={true}/> :
        assetData && additionalAssetData &&
        <AssetDetail asset={assetData} details={additionalAssetData}/>),
      style: panelStyle
    },
    {
      key: "realEstateDetails",
      label: labelTitle(capitalize(t("misc.realEstateData"))),
      children: (isLoadingAssetData || isLoadingAdditionalData ? <Skeleton active={true}/> : additionalAssetData &&
        <RealEstateData details={additionalAssetData}/>),
      style: panelStyle
    },
    {
      key: "identifiedHazards",
      label: labelTitle(capitalize(t("hazards.risksAndHazards"))),
      children: (isLoadingAssetData ? <Skeleton active={true}/> : assetData && <IndentifiedHazard asset={assetData}/>),
      style: panelStyle
    },
    {
      key: "biodiversity",
      label: labelTitle(capitalize(t('assets.biodiversity'))),
      children: (<BiodiversityTable/>),
      style: panelStyle
    },
      ...process.env.REACT_APP_SHOW_CLIMATE_CHANGE  === 'show' ? [{
      key: "climateChange",
      label: labelTitle(capitalize(t('assets.climateChange'))),
      children: (<ClimateChangeContent assetUuid={assetData.uuid} missingCoordinates={assetData.position === null}/>),
      style: panelStyle
    }] : [],
    ...isAssetDocuments ? [
      {
        key: "documents",
        label: labelTitle(capitalize(t('documents.document', {count: 2}))),
        children: (isLoadingAssetData ? <Skeleton active={true}/> : assetData &&
          <AssetsDocumentsListInAlert asset={assetData}/>),
        style: panelStyle
      }
    ] : [],
    ...isAssetImages ? [
      {
        key: "images",
        label: labelTitle(capitalize(t("documents.image", {count: 2}))),
        children: (isLoadingAssetData ? <Skeleton active={true}/> : assetData &&
            <AssetsImagesListInAlert asset={assetData}/>
        ),
        style: panelStyle
      }
    ] : [],
  ];

  return (
    <>
      <Row style={{marginBottom: "0.5rem"}} justify={'space-between'} gutter={[8, 8]}>
        <Col>
          <Title level={3} style={{margin: 0}}>
            {capitalize(t("assets.buildingDetails"))}
          </Title>
        </Col>
        <Col>

          <Space direction={"horizontal"}>
            <DownloadAssetReport/>
            <GoToAssetButton uuid={assetData.uuid} goBack={goBack}/>
          </Space>
        </Col>
      </Row>
      <Space direction={"vertical"} size={0} style={{marginBottom: '1rem'}}>
        <Text>
          {assetData.sigi_code}
        </Text>
        <Text style={{textTransform: "capitalize"}}>
          {`${[assetData.address, assetData.admin_level_3, assetData.admin_level_1, options?.country.find(country => country.value === assetData.country)?.label].join(", ").toLocaleLowerCase()}`}
        </Text>
        {assetData.date_edit && userData && <Text type={"secondary"}>
          {`${(capitalize(t('events.lastUpdated')))}: ${dateToLocaleString(assetData.date_edit, userData.language)}`}
        </Text>}
      </Space>
      <ConfigProvider
        theme={{
          components: {
            Collapse: {
              headerPadding: '32px',
              contentPadding: '32px',
            },
          },
        }}
      >
        <Collapse
          accordion={true}
          bordered={false}
          style={{background: "#F5F5F5"}}
          expandIconPosition={'end'}
          expandIcon={({isActive}) => <CaretRightOutlined style={{fontSize: '20px'}} rotate={isActive ? 270 : 90}/>}
          items={items}/>
      </ConfigProvider>
    </>
  );
}