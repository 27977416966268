import {PHASE} from "../../types/api/getEventsResult";
import {useTranslation} from "react-i18next";
import {EditOutlined, SyncOutlined} from "@ant-design/icons";
import {Tag} from "antd";
import React from "react";

export default function PhaseTag({phase}: {
  phase?: PHASE
}) {

  const {t} = useTranslation()
  let color = ''
  let icon = <></>

  switch (phase) {
    case PHASE.EDIT:
      color = 'warning'
      icon = <EditOutlined/>
      break;
    case PHASE.LIVE:
      break;
    case PHASE.CREATE:
      color = 'processing'
      icon = <SyncOutlined/>
      break;
  }

  return (!phase || phase === PHASE.LIVE) ? <></> : <Tag
    style={{width: '7rem', textAlign: 'center'}}
    color={color}
    icon={icon}>
    {t(`events.${phase?.toLowerCase()}Phase`).toUpperCase()}
  </Tag>
}