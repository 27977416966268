import AlertsEventTable from './AlertsEventTable';
import {useLazyGetAlertsPaginatedQuery} from '../../../../redux/api/alerts';
import {GetAlertsParams} from '../../../../types/api/alerts';
import React, {useCallback} from 'react';
import {useEventContext} from '../../../../context/event/EventContext';
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function AlertsAssociatedToEventTable({selectedAlerts, setSelectedAlerts}:
                                                       {
                                                         selectedAlerts?: React.Key[],
                                                         setSelectedAlerts?: React.Dispatch<React.SetStateAction<React.Key[]>>,
                                                       }) {
  const [getData, {data, isFetching}] = useLazyGetAlertsPaginatedQuery();
  const {event} = useEventContext();
  const navigate = useNavigate()
  const {t} = useTranslation()

  const handleGetData = useCallback((params: GetAlertsParams) => {
    if (event?.uuid) {
      getData({...params, event: event.uuid});
    }
  }, [event?.uuid, getData]);

  return <AlertsEventTable
    tableProps={{
      dataSource: data?.results,
      loading: isFetching,
      onRow: (record) => ({
          onClick: (e) => {
            navigate(`/alerts/${record.uuid}`, {state: {path: `/events/${event?.uuid}`, label: `${t('events.event', {count: 1})}`}});
          },
          style: {
            cursor: 'pointer',
          },
        })
    }}
    paginationProps={{
      total: data?.count,
    }}
    getData={handleGetData}
    selectedAlerts={selectedAlerts}
    setSelectedAlerts={setSelectedAlerts}

  />;
}