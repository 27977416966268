import {Button, Col, Row, StepProps, Steps} from "antd";
import React, {ReactElement, useEffect, useState} from "react";
import {capitalize} from "lodash";
import {useTranslation} from "react-i18next";
import AlertDetailForm from "../form/AlertDetailForm";
import {useForm} from "antd/lib/form/Form";
import AlertStepMap from "./AlertStepMap";
import AlertGeoshapesSelectAndList from "./AlertGeoshapesSelectAndList";
import {useAlertStepsContext} from "../../../context/alertSteps/AlertStepsContext";
import {
  useGetAlertPolygonsNotPaginatedQuery,
  useGetAlertQuery,
  usePatchEditAlertMutation
} from "../../../redux/api/alerts";
import {useNavigate, useParams} from "react-router-dom";
import {useMessageContext} from "../../../context/message/MessageContext";
import {skipToken} from "@reduxjs/toolkit/query";
import {parseAlertForm, parseAlertFormForBe} from "../../../utils/parser";
import {AlertCreationForm} from "../../../types/internal/Alerts";
import {
  Entity,
  GlobalPolygon,
  useLazyGetGeoshapesEntityQuery,
  useLazyGetGlobalGeoshapesQuery
} from "../../../redux/api/geoshapes";

export default function AlertStepsEdit() {

  const {t} = useTranslation();
  const navigate = useNavigate();
  const setMessage = useMessageContext();
  const [form] = useForm();
  const [formValues, setFormValues] = useState<AlertCreationForm>()
  const {drawnGeometries, setDrawnGeometries} = useAlertStepsContext()

  const [step, setStep] = useState(0);
  const {uuid} = useParams();
  const [edit, {isLoading,},] = usePatchEditAlertMutation();
  const {data: alertData, isFetching: isFetchingAlertData} = useGetAlertQuery(uuid ? {uuid} : skipToken);
  const {
    data: alertPolygons,
    isFetching: isFetchingAlertPolygons
  } = useGetAlertPolygonsNotPaginatedQuery(uuid ? {uuid} : skipToken)
  const [getEntity] = useLazyGetGeoshapesEntityQuery();
  const [getGeoshapes] = useLazyGetGlobalGeoshapesQuery();
  const {setFieldsValue} = form

  useEffect(() => {
    if (alertData) {
      const parsedValues = parseAlertForm(alertData)
      setFormValues(parsedValues)
      setFieldsValue(parsedValues)
    }
  }, [alertData, setFieldsValue]);

  useEffect(() => {
    const initialize = async () => {
      try {
        if (alertPolygons) {
          const entityPromises: Promise<Entity>[] = []
          const shapesPromises: Promise<GlobalPolygon>[] = []
          alertPolygons.forEach(el => {
            if (el.entity_uuid) {
              entityPromises.push(getEntity({uuid: el.entity_uuid}).unwrap())
            }
            if (el.globalpolygon) {
              shapesPromises.push(getGeoshapes({uuid: el.globalpolygon}).unwrap())
            }
          })
          const responses = await Promise.all([Promise.all(entityPromises), Promise.all(shapesPromises)])


          setDrawnGeometries(alertPolygons.map(alertPolygon => {

            const entity = responses[0].find(entity => alertPolygon.entity_uuid === entity.uuid)
            const geoshape = responses[1].find(geoshape => alertPolygon.globalpolygon === geoshape.properties.uuid)

            return {
              uuid: alertPolygon.globalpolygon,
              coordinates: geoshape?.geometry.coordinates || [[[]]],
              ...!!entity?.uuid && {entity: entity?.uuid},
              name: entity ? entity.name : capitalize(t('map.manualPolygon')),
            }
          }))
        }
      } catch {

      }

    }
    initialize()
  }, [alertPolygons, getEntity, getGeoshapes, setDrawnGeometries]);


  const next = () => {
    setStep(curr => curr + 1);
  };
  const prev = () => {
    setStep(curr => curr - 1);
  };

  const isButtonDisabled = isLoading || !drawnGeometries.length || !formValues;

  const handleFinish = async () => {
    try {
      if (formValues && drawnGeometries.length && uuid) {
        await edit({
          uuid,
          ...parseAlertFormForBe(formValues),
          alert_polygons: drawnGeometries.map(el => {
            if (el.drawCount) {
              return {
                'data': null,
                'geoshape': {type: 'MultiPolygon', coordinates: el.coordinates},
                'globalpolygon': null,
              }
            } else {
              return {
                'data': null,
                'geoshape': null,
                'globalpolygon': el.uuid,
              }
            }
          }),
        }).unwrap();
        setMessage({success: capitalize(t('form.editSuccessMessage'))});
        navigate(`/alerts/${uuid}/`)
      }
    } catch {
      setMessage({error: capitalize(t('form.editFailMessage'))});
    }
  }

  const steps: (StepProps & { content: ReactElement })[] = [
    {
      title: capitalize(t('alerts.detail')),
      content: <AlertDetailForm
        form={{
          form: form,
          disabled: isFetchingAlertData
        }}
      />
    },
    {
      title: capitalize(t('alerts.geoArea')),
      content: <>
        <AlertStepMap/>
        <AlertGeoshapesSelectAndList/>
      </>
    },
  ]

  const handleFormValidation = () => {
    form.validateFields()
      .then((values) => {
        setFormValues(values)
        next()
      })
      .catch(() => console.log('form validation error'))
  }

  return <>
    <Steps
      current={step}
      items={steps}
      onChange={v => {
        if (v === 1) {
          handleFormValidation()
        } else {
          setStep(v)
        }
      }}
    />
    {steps[step].content}
    <Row
      justify={'end'}
    >
      {step > 0 && (
        <Col>
          <Button
            style={{margin: '0 8px'}} onClick={() => prev()}>
            {capitalize(t('form.previous'))}
          </Button>
        </Col>
      )}
      {step < steps.length - 1 && (
        <Col>
          <Button
            type="primary"
            onClick={() => {
              if (step === 0) {
                handleFormValidation()
              } else {
                next()
              }
            }}>
            {capitalize(t('form.next'))}
          </Button>
        </Col>
      )}
      {step === steps.length - 1 && (
        <Col>
          <Button
            loading={isLoading}
            disabled={isButtonDisabled}
            type={'primary'}
            onClick={handleFinish}
          >
            {capitalize(t('actions.editItem', {item: t('alerts.alert')}))}
          </Button>
        </Col>
      )}
    </Row>
  </>
}