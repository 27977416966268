import DeleteModalMessage from "../../common/documents/modals/DeleteModalMessage";
import RemoveButton from "../../common/buttons/RemoveButton";
import {useState} from "react";
import {capitalize} from "lodash";
import {useTranslation} from "react-i18next";

export default function DeleteHappenedDate({onConfirm}: {onConfirm: () => void}) {

  const [isModalOpen, setIsModalOpen] = useState(false)
  const {t} = useTranslation()

  return <>
    <DeleteModalMessage
      modal={{
        open: isModalOpen,
        onCancel: () => setIsModalOpen(false)
      }}
      title={{
        children: capitalize(t('events.deleteHappenedDate'))
      }}
      paragraph={{
        children: t('events.deleteHappenedDateDescription')
      }}
      confirmButton={{
        onClick: () => {
          setIsModalOpen(false)
          onConfirm()
        }
      }}
    />
    <RemoveButton
      onClick={() => setIsModalOpen(true)}
      type={'text'}
      children={null}/>
  </>
}