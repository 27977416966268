import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useLazyGetDocumentsForInfiniteScrollingQuery} from '../../../../redux/api/documents';
import {OptionLabelValue} from '../../../../types/internal/OptionLabelValue';
import NEWListWithSearchFilter from '../../../common/filters/NEWListWithSearchFilter';

export const DocumentsFileNameFilter = ({activeFilters, handleFilterChange, viewType}: {
  activeFilters: OptionLabelValue[] | null,
  handleFilterChange: (values: OptionLabelValue[]) => void,
  viewType?: 'assets' | 'events' | 'event_types' | 'alerts' | 'none'
}) => {

  const [
    getDocuments,
    {
      data,
      isLoading,
      isFetching,
      isUninitialized,
      originalArgs,
    },
  ] = useLazyGetDocumentsForInfiniteScrollingQuery();

  const [querySearch, setQuerySearch] = useState<string>('');
  const options: OptionLabelValue[] = useMemo(() => data?.results.map(el => ({
    label: el.name,
    value: el.uuid,
  })) || [], [data?.results]);

  const handleGetNext = useCallback(() => {
    getDocuments({
      ordering: 'name',
      ...!!querySearch && {search_by_name: querySearch},
      page: (originalArgs?.page || 0) + 1,
      entity: viewType ? viewType : 'none',
      type: 'T',
      page_size: 15,
    });
  }, [getDocuments, originalArgs?.page, querySearch, viewType]);

  useEffect(() => {
    getDocuments({
      ordering: 'name',
      ...!!querySearch && {search_by_name: querySearch},
      page: 1,
      entity: viewType ? viewType : 'none',
      type: 'T',
      page_size: 15,
    });
  }, [getDocuments, querySearch, viewType]);

  return (
    <NEWListWithSearchFilter
      getNext={handleGetNext}
      options={options}
      selectedOptions={activeFilters || []}
      setSelectedOptions={handleFilterChange}
      total={data?.count || 0}
      isLoadingOptions={isLoading || isFetching || isUninitialized}
      querySearch={querySearch}
      setQuerySearch={setQuerySearch}
    />
  );
};