import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {Button} from 'antd';
import {capitalize} from 'lodash';

export default function ToAssetsListButton() {
  const {t} = useTranslation();
  return <Link to={'/assets'}>
    <Button type={'primary'}>
      {capitalize(t('actions.goToDestination', {destination: t('assets.assetsList')}))}
    </Button>
  </Link>;
}