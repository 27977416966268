import Icon from '@ant-design/icons';

const OffsetLineSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg" width="20px" height="20px"
    viewBox="0 0 20 20" version="1.1"
  >
    <g id="surface1">
      <path
        d="M 17.5 10 C 17.5 10.34375 17.21875 10.625 16.875 10.625 L 3.125 10.625 C 2.78125 10.625 2.5 10.34375 2.5 10 C 2.5 9.65625 2.78125 9.375 3.125 9.375 L 16.875 9.375 C 17.21875 9.375 17.5 9.65625 17.5 10 Z M 7.5 4.375 L 9.375 4.375 L 9.375 7.5 C 9.375 7.84375 9.65625 8.125 10 8.125 C 10.34375 8.125 10.625 7.84375 10.625 7.5 L 10.625 4.375 L 12.5 4.375 C 12.753906 4.375 12.980469 4.222656 13.078125 3.988281 C 13.175781 3.753906 13.121094 3.488281 12.941406 3.308594 L 10.441406 0.808594 C 10.199219 0.5625 9.800781 0.5625 9.558594 0.808594 L 7.058594 3.308594 C 6.878906 3.488281 6.824219 3.753906 6.921875 3.988281 C 7.019531 4.222656 7.246094 4.375 7.5 4.375 Z M 12.5 15.625 L 10.625 15.625 L 10.625 12.5 C 10.625 12.15625 10.34375 11.875 10 11.875 C 9.65625 11.875 9.375 12.15625 9.375 12.5 L 9.375 15.625 L 7.5 15.625 C 7.246094 15.625 7.019531 15.777344 6.921875 16.011719 C 6.824219 16.246094 6.878906 16.511719 7.058594 16.691406 L 9.558594 19.191406 C 9.800781 19.4375 10.199219 19.4375 10.441406 19.191406 L 12.941406 16.691406 C 13.121094 16.511719 13.175781 16.246094 13.078125 16.011719 C 12.980469 15.777344 12.753906 15.625 12.5 15.625 Z M 12.5 15.625 "
      />
    </g>
  </svg>

);

export const OffsetLineIcon = () => (
  <Icon
    component={OffsetLineSvg}
    className={'custom-icon'}
  />
);