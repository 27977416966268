import {Slider} from 'antd';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';

type TableFilterProps =
  {
    min: number,
    max: number,
    selectedFilters: [number, number] | null,
    onChange: (values: [number, number]) => void
    step?: number,
    formatValue?: (value: number) => string,
  }

export default function NumberRangeFilter(props: TableFilterProps) {

  const [range, setRange] = useState<[number, number] | undefined>(undefined);
  const {t} = useTranslation();

  useEffect(() => {
    setRange(props.selectedFilters || undefined);
  }, [props.selectedFilters]);

  const content = <>
    {!props.selectedFilters && capitalize(t('table.notSetFilter'))}
    {!!props.selectedFilters && !!props.formatValue && `${props.formatValue(props.selectedFilters[0])} - ${props.formatValue(props.selectedFilters[1])}`}
    {!!props.selectedFilters && !props.formatValue && `${props.selectedFilters[0]} - ${props.selectedFilters[1]}`}
    <Slider
      range
      step={props.step}
      min={props.min}
      max={props.max}
      value={range}
      onChange={values => {
        setRange([values[0], values[1]]);
      }}
      onAfterChange={values => {
        props.onChange([values[0], values[1]]);
      }}
    />
  </>;

  return (
    <div
      style={{width: '10rem'}}
    >
      {content}
    </div>);
}