import {Button, ButtonProps, Form, FormProps, Modal, ModalProps} from 'antd';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import AntFormItem from '../../common/AntFormItem';
import CustomLatitudeNumericFormat from '../../common/customNumeric/CustomLatitudeNumericFormat';
import CustomLongitudeNumericFormat from '../../common/customNumeric/CustomLongitudeNumericFormat';

export default function EpicenterModal({modalProps, formProps, modalButton, submitButton}: {
  modalProps?: ModalProps,
  formProps?: FormProps,
  modalButton?: ButtonProps,
  submitButton?: ButtonProps
}) {

  const {t} = useTranslation();

  return <>
    <Modal
      footer={<Form
        style={{'textAlign': 'right'}}
        {...formProps}
      >
        <Form.Item>
          <Button
            htmlType={'submit'}
            type={'primary'}
            {...submitButton}
          />
        </Form.Item>
      </Form>}
      {...modalProps}
    >
      <Form
        layout={'vertical'}
        {...formProps}
      >
        <AntFormItem
          name={'lat'}
          label={capitalize(t('address.latitude'))}
          trigger={'onValueChange'}
          getValueFromEvent={e => e.floatValue}
          rules={[
                {
                  required: true,
                  message: capitalize(t('form.requiredField', {field: t('address.latitude')})),
                  validator: (_, value) => {
                    if (!(typeof value === 'number')) {
                      return Promise.reject();
                    }
                    return Promise.resolve();
                  },
                }]}
        >
          <CustomLatitudeNumericFormat/>
        </AntFormItem>
        <AntFormItem
          name={'lng'}
          label={capitalize(t('address.longitude'))}
          trigger={'onValueChange'}
          getValueFromEvent={e => e.floatValue}
          rules={[
                {
                  required: true,
                  message: capitalize(t('form.requiredField', {field: t('address.longitude')})),
                  validator: (_, value) => {
                    if (!(typeof value === 'number')) {
                      return Promise.reject();
                    }
                    return Promise.resolve();
                  },
                }]}
        >
          <CustomLongitudeNumericFormat/>
        </AntFormItem>
      </Form>

    </Modal>
    <Button
      {...modalButton}
    />
  </>;
}