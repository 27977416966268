import {useLazyGetInvolvedAssetsRelatedOptionsForInfiniteScrollingQuery} from "../../../redux/api/events";
import {Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {OptionLabelValue} from "../../../types/internal/OptionLabelValue";
import {parseOptions} from "../../../redux/api/assets";
import {titleCase} from "../../../utils/parser";
import {useTranslation} from "react-i18next";
import {capitalize, cloneDeep} from "lodash";
import {getTranslationInvolvedAssetTableColumnKey} from "../../../utils/translation";
import {
  fromFeToBeInvolvedAssetsTableFilters,
  InvolvedAssetsFiltersFE
} from "../../../types/internal/tables/involvedAssetsTable";
import NEWListWithSearchFilter from "../../common/filters/NEWListWithSearchFilter";

export default function InvolvedAssetRelatedOptionsFilter({field, eventUuid, filters, setFilters}: {
  eventUuid: string,
  field: 'address'
    | 'admin_level_1'
    | 'admin_level_2'
    | 'admin_level_3'
    | 'building_type'
    | 'country'
    | 'sigi_code'
    | 'state',
  filters: InvolvedAssetsFiltersFE,
  setFilters: Dispatch<SetStateAction<InvolvedAssetsFiltersFE>>
}) {

  const [getData, {
    data,
    isLoading,
    isFetching,
    isUninitialized,
    originalArgs,
  }] = useLazyGetInvolvedAssetsRelatedOptionsForInfiniteScrollingQuery()
  const [querySearch, setQuerySearch] = useState<string>('');
  const options: OptionLabelValue[] = useMemo(() => parseOptions(data?.results || []).map(el =>
      ['address', 'admin_level_1', 'admin_level_3'].includes(field) ?
        {...el, label: el.label ? titleCase(el.label) : ''}
        : el),
    [data?.results, field]);
  const total = data?.count || 0;
  const {t} = useTranslation();
  const placeholder = capitalize(t('table.filterPlaceholder', {column: t(getTranslationInvolvedAssetTableColumnKey(field))}));
  const selectedFilters = filters[field] || [];

  const isExclude = filters.excludedFilters.some(el => el === field)

  const {[field]: currentFilter, ...assetsTableFiltersWithoutCurrentFilter} = filters
  const assetsTableFiltersWithoutCurrentFilterRef = useRef<InvolvedAssetsFiltersFE>(assetsTableFiltersWithoutCurrentFilter)

  useEffect(() => {
    const {[field]: currentFilter, ...assetsTableFiltersWithoutCurrentFilter} = filters
    assetsTableFiltersWithoutCurrentFilterRef.current = assetsTableFiltersWithoutCurrentFilter
  }, [field, filters]);

  const handleGetNext = useCallback(() => {
    getData({
      column: field,
      ...!!querySearch && {column_search: querySearch},
      ...fromFeToBeInvolvedAssetsTableFilters(assetsTableFiltersWithoutCurrentFilterRef.current),
      page: (originalArgs?.page || 0) + 1,
      page_size: 15,
      eventUuid
    });
  }, [eventUuid, field, getData, originalArgs?.page, querySearch]);

  useEffect(() => {
    getData({
      column: field,
      ...!!querySearch && {column_search: querySearch},
      ...fromFeToBeInvolvedAssetsTableFilters(assetsTableFiltersWithoutCurrentFilterRef.current),
      page: 1,
      page_size: 15,
      eventUuid
    });
  }, [eventUuid, field, getData, querySearch]);

  return <>
    <NEWListWithSearchFilter
      getNext={handleGetNext}
      options={options}
      selectedOptions={selectedFilters}
      setSelectedOptions={values => {
        if (values.length) {
          setFilters(prevState => ({...prevState, [field]: values}));
        } else {
          setFilters(prevState => {
            const copyState = cloneDeep(prevState)
            delete copyState[field]
            return copyState
          })
        }
      }}
      total={total}
      isLoadingOptions={isLoading || isFetching || isUninitialized}
      querySearch={querySearch}
      setQuerySearch={setQuerySearch}
      search={{placeholder}}

      isExcludeFeature={true}
      isExclude={isExclude}
      setExcludeMode={() => {
        setFilters(prevState => {
          const copyState = cloneDeep(prevState)
          delete copyState[field]
          return {
            ...copyState,
            excludedFilters: [...prevState.excludedFilters, field]
          }
        })
      }}
      setDeclarativeMode={() => {
        setFilters(prevState => {
          const copyState = cloneDeep(prevState)
          delete copyState[field]
          return {
            ...copyState,
            excludedFilters: prevState.excludedFilters.filter(el => el !== field)
          }
        })
      }}
    />
  </>;
}