import React, {useState} from "react";
import {Button, Space} from "antd";
import {useEventContext} from "../../../context/event/EventContext";
import {useTranslation} from "react-i18next";
import {ConfigurationHistory} from "../../../types/api/getEventsResult";
import {getAssetStatusTranslationKey} from "../../../utils/translation";

export default function ExpandableClusterMovingGroup({detail}: { detail: ConfigurationHistory['detail'] }) {

  const {getClusterFromUuid} = useEventContext()

  const [
    isExpanded,
    setIsExpand,
  ] = useState(false);

  const {t} = useTranslation()

  return (
    <>
      <Button
        type={'link'}
        onClick={() => setIsExpand((expanded) => !expanded)}
      >
        {isExpanded ? 'riduci' : 'espandi'}
      </Button>
      {isExpanded && (
        <div>
          <Space direction={'vertical'} size={'small'}>
            {detail.map(clusterDetail => {
              const currentCluster = getClusterFromUuid(clusterDetail.cluster_template)
              return <div key={currentCluster?.uuid} style={{display: 'flex', gap: '0.5rem'}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <div
                    style={{
                      backgroundColor: currentCluster?.color_code,
                      width: 12,
                      height: 12,
                      borderRadius: '50%',
                      margin: '0 4px',
                    }}
                  />
                  <span>
                      {`${currentCluster?.name}:`}
                    </span>
                </div>
                <span>
                  {clusterDetail.assets_count} {t('assets.asset', {count: clusterDetail.assets_count})}
                </span>
                {clusterDetail.assets.map(el =>
                  <span
                    key={el.state}>
                    {el.count} {t(getAssetStatusTranslationKey({state: el.state, reduced: true}), {count: el.count})}
                  </span>)}
              </div>
            })}
          </Space>
        </div>
      )}
    </>
  );
}