import EditButton from "../../common/buttons/EditButton";
import PermissionRoute from "../../Authentication/PermissionRoute";
import {isDataSourcesEdit} from "../../Authentication/permissions";
import {Modal, Typography} from "antd";
import {useState} from "react";
import CustomNumericFormat from "../../common/customNumeric/CustomNumericFormat";
import {useMessageContext} from "../../../context/message/MessageContext";
import {capitalize} from "lodash";
import {useTranslation} from "react-i18next";
import {useGetThresholdInfoQuery, useSetThresholdAlertSourceMutation} from "../../../redux/api/alertSource";
import {skipToken} from "@reduxjs/toolkit/query";

export default function DataSourceThresholdEdit({initialValue, alertSourceUuid}: {
  initialValue: null | number,
  alertSourceUuid: string
}) {

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [editThreshold, {isLoading}] = useSetThresholdAlertSourceMutation()
  const {data: thresholdInfo} = useGetThresholdInfoQuery(isModalOpen ? {alertSourceUuid} : skipToken)

  const [value, setValue] = useState<number | null>(initialValue)
  const setMessage = useMessageContext()
  const {t} = useTranslation()

  const handleOk = async () => {
    try {
      if (typeof value === 'number' && value !== initialValue) {
        await editThreshold({alertSourceUuid, threshold: value}).unwrap()
      }
      setMessage({success: capitalize(t('form.editSuccessMessage'))});
    } catch {
      setMessage({error: capitalize(t('form.editFailMessage'))});
    } finally {
      setIsModalOpen(false)
    }
  }

  const isMin = typeof thresholdInfo?.min_value === 'number'
  const isMax = typeof thresholdInfo?.max_value === 'number'


  return <PermissionRoute
    isAuthorized={isDataSourcesEdit}>
    <Modal
      open={isModalOpen}
      onCancel={() => {
        setValue(initialValue)
        setIsModalOpen(false)
      }}
      onOk={handleOk}
      okButtonProps={{
        loading: isLoading
      }}
      title={`${capitalize(t('actions.edit'))} ${t('settings.threshold')}`}
    >
      {isMin && isMax && <Typography.Paragraph>{capitalize(t('settings.editThreshold', {
        min: thresholdInfo.min_value,
        max: thresholdInfo.max_value
      }))}</Typography.Paragraph>}
      {isMin && !isMax && <Typography.Paragraph>{capitalize(t('settings.editThresholdMin', {
        min: thresholdInfo.min_value
      }))}</Typography.Paragraph>}
      {!isMin && isMax && <Typography.Paragraph>{capitalize(t('settings.editThresholdMax', {
        max: thresholdInfo.max_value
      }))}</Typography.Paragraph>}
      <CustomNumericFormat
        allowNegative={false}
        fixedDecimalScale={true}
        suffix={` ${thresholdInfo?.unit || ""}`}
        isAllowed={({floatValue}) => {
          let condition = true
          const valueIsNumeric = typeof floatValue === 'number'

          condition = valueIsNumeric && typeof thresholdInfo?.min_value === 'number' ? floatValue >= thresholdInfo.min_value : condition
          condition = valueIsNumeric && typeof thresholdInfo?.max_value === 'number' ? floatValue <= thresholdInfo.max_value : condition

          return condition
        }}
        placeholder={capitalize(t('table.filterPlaceholder', {column: t('settings.threshold')}))}
        value={typeof value === 'number' ? value : ""}
        onValueChange={v => setValue(typeof v.floatValue === 'number' ? v.floatValue : null)}
      />
    </Modal>
    <EditButton
      onClick={() => setIsModalOpen(true)}
      type={'text'}
      children={null}
    />
  </PermissionRoute>
}