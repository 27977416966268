import dayjs from 'dayjs';
import React from 'react';
import {OptionLabelValue} from './OptionLabelValue';

export enum SigiStatus {
  loading,
  valid,
  not_valid,
  to_be_checked
}

export type AddAssetForm = {
  isFirstStepComplete: () => boolean,
  isSecondStepComplete: () => boolean,
  isThirdStepComplete: () => boolean,
  submit: () => void,
  isSigiValid: SigiStatus,
  checkSigi: (sigi: string) => void,
};

export type AssetLocation = {
  country: string | null,
  admin_level_1: string | null,
  admin_level_2: string | null,
  admin_level_3: string | null,
  locality: string | null,
  address: string | null,
  postal_code: string | null,
  position: [number, number] | null,
  geocoding_position: [number, number] | null,
};

export type AssetLocationContextType = [
  location: AssetLocation,
  setLocation: React.Dispatch<React.SetStateAction<AssetLocation>>,
];

export type BuildingDetails = {
  sigi_code: string | null,
  building_type: string | null,
  building_type_detail: string | null,
  surface_total: number | null,
  surface_basement: number | null,
  opening_date: dayjs.Dayjs | null,
  closing_date: dayjs.Dayjs | null,
  ownership: string | null,
  companies: OptionLabelValue[],
  total_resources: number | null,
  master_asset: OptionLabelValue | null
};

export type AssetDetailsContextType = [
  BuildingDetails,
  React.Dispatch<React.SetStateAction<BuildingDetails>>,
];

export type AssetRealEstate = {
  realestate_hub: { label: string, value: string } | null,
  contact_people: OptionLabelValue[],
};

export type RealEstateContextType = [
  AssetRealEstate,
  React.Dispatch<React.SetStateAction<AssetRealEstate>>,
];