import {createContext, PropsWithChildren, useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {AlertContextType, AlertModel} from '../../types/api/alerts';
import {useLazyGetAlertQuery} from '../../redux/api/alerts';
import {useMessageContext} from '../message/MessageContext';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';

const AlertDetailsContext = createContext<AlertContextType>([
  null,
  () => null,
  false,
]);

export const useAlertContext = () => useContext(AlertDetailsContext);

export const AlertDetails = (props: PropsWithChildren & {alertUuid: string | undefined}) => {

  const {t} = useTranslation();
  const navigate = useNavigate();

  const setMessage = useMessageContext();

  const [
    fetchAlert,
    {
      isFetching,
      isLoading,
      isSuccess,
      isError,
      data,
    },
  ] = useLazyGetAlertQuery();

  const [
    alert,
    setAlert,
  ] = useState<AlertModel | null>(null);

  useEffect(() => {
    if (props.alertUuid) {
      fetchAlert({uuid: props.alertUuid});
    }
  }, [props.alertUuid, fetchAlert]);

  useEffect(() => {
    if (isSuccess && data) {
      setAlert(data);
    } else {
      if (isError) {
        setMessage({
          error: capitalize(t('form.notFoundMessage')),
        });
        navigate('/alerts');
      }
    }
  }, [data, isError, isSuccess, navigate, setMessage, t]);

  return (<AlertDetailsContext.Provider value={[alert, setAlert, isFetching || isLoading]}>
    {props.children}
  </AlertDetailsContext.Provider>);
};