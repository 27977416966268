import ReactQuill, {Quill, ReactQuillProps} from 'react-quill';
import {useCallback, useEffect, useMemo, useRef} from 'react';

interface OnChangeHandler {
  (e: any): void;
}

export const TemplateMailEditor = ({value, onChange, placeholder, disabled = false, reactQuillProps}: {
  value?: string,
  placeholder?: string,
  onChange?: OnChangeHandler,
  disabled?: boolean,
  reactQuillProps?: ReactQuillProps
}) => {

  const editorRef = useRef<ReactQuill | null>(null);

  const icons = Quill.import('ui/icons');
  icons['undo'] = `<svg viewbox="0 0 18 18">
    <polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
    <path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
  </svg>`;
  icons['redo'] = `<svg viewbox="0 0 18 18">
    <polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
    <path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
  </svg>`;

  const handleUndo = useCallback(() => {
    if (editorRef.current) {
      // @ts-ignore
      editorRef.current?.getEditor()?.history?.undo();
    }
  }, []);

  const handleRedo = useCallback(() => {
    if (editorRef.current) {
      // @ts-ignore
      editorRef.current?.getEditor()?.history?.redo();
    }
  }, []);

  const handleVariableAddition = useCallback((value: string) => {
    if (editorRef.current && value) {
      const editor = editorRef.current?.getEditor();
      const unprivilegedEditor = editorRef.current?.makeUnprivilegedEditor(editor);
      const cursorPosition = unprivilegedEditor?.getSelection()?.index;
      if (cursorPosition !== undefined) {
        editorRef.current?.getEditor()?.insertText(cursorPosition || 0, `{{${value}}}`);
        editorRef.current?.getEditor()?.setSelection((cursorPosition || 0) + value.length + 4, 0);
      }
    }
  }, []);

  useEffect(() => {
    if (value) {
      // @ts-ignore
      editorRef.current?.getEditor()?.clipboard.dangerouslyPasteHTML(value);
    }
    // eslint-disable-next-line
  }, []);

  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{header: [1, 2, false]}],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [
          {list: 'ordered'},
          {list: 'bullet'},
          {indent: '-1'},
          {indent: '+1'},
        ],
        ['link', 'code'],
        ['clean'],
        ['undo', 'redo'],
        [{variables: ['event_name', 'event_id', 'event_date', 'event_position']}],
      ],
      handlers: {
        undo: handleUndo,
        redo: handleRedo,
        'variables': handleVariableAddition,
      },
    },
  }), [handleRedo, handleUndo, handleVariableAddition]);

  const formats = useMemo(() => (
    [
      'header',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'list',
      'bullet',
      'indent',
      'link',
      'code',
    ]
  ), []);

  const handleOnChange = (newVal: string) => {
    if (newVal.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
      newVal = ''; // that's for handling empty tags
    }
    if (onChange)
      onChange(newVal);
  };

  return (
    <ReactQuill
      ref={(editor) => editorRef.current = editor}
      theme="snow"
      value={value || ''}
      modules={modules}
      formats={formats}
      onChange={handleOnChange}
      placeholder={placeholder}
      readOnly={disabled}
      preserveWhitespace={true}
      bounds={document.getElementById('template-mail-drawer') || undefined}
      {...reactQuillProps}
    />
  );

};