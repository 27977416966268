import React, {createContext, PropsWithChildren, useContext, useEffect, useState} from 'react';
import {AssetDetailsContextType, BuildingDetails} from '../../types/internal/AddAssetForm';
import {useAssetDetails} from '../assetDetails/AssetDetails';
import dayjs from 'dayjs';
import {useAdditionalAssetData} from '../assetDetails/AdditionalAssetDataContext';

const DEFAULT_ASSET_DETAILS = {
  sigi_code: null,
  building_type: null,
  building_type_detail: null,
  surface_total: null,
  surface_basement: null,
  opening_date: null,
  closing_date: null,
  ownership: null,
  companies: [],
  total_resources: null,
  master_asset: null,
};

const EditDetailsContext = createContext<AssetDetailsContextType>(
  [
    DEFAULT_ASSET_DETAILS,
    () => null,
  ]);

export const useEditAssetDetailsContext = () => useContext(EditDetailsContext);

export const EditAssetDetailsContext = (props: PropsWithChildren) => {

  const [, {asset, loading: isAssetLoading}] = useAssetDetails();
  const [additionalData, isAdditionalDataLoading] = useAdditionalAssetData();

  const [details, setDetails] = useState<BuildingDetails>(DEFAULT_ASSET_DETAILS);

  useEffect(() => {
    if (!isAssetLoading && !isAdditionalDataLoading && asset) {
      setDetails(details => ({
        sigi_code: asset.sigi_code,
        building_type: asset.building_type || details.building_type,
        building_type_detail: asset.building_type_detail || details.building_type_detail,
        surface_total: asset.surface_total || details.surface_total,
        surface_basement: asset.surface_basement || details.surface_basement,
        opening_date: asset.opening_date ? dayjs(asset.opening_date) : details.opening_date,
        closing_date: asset.closing_date ? dayjs(asset.closing_date) : details.closing_date,
        ownership: asset.ownership || details.ownership,
        companies: asset.companies.map(companyUuid => ({
          label: additionalData.companies.find(company => company.uuid === companyUuid)?.name || '',
          value: companyUuid,
        })) || details.companies,
        total_resources: asset.total_resources || details.total_resources,
        master_asset: additionalData.master_asset ? {
          label: additionalData.master_asset?.sigi_code,
          value: additionalData.master_asset?.uuid,
        } : null,
      }));
    }
  }, [additionalData.companies, additionalData.master_asset, asset, isAdditionalDataLoading, isAssetLoading]);

  return (
    <EditDetailsContext.Provider value={[details, setDetails]}>
      {props.children}
    </EditDetailsContext.Provider>
  );
};