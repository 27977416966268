import {Button, Col, Drawer, Row, Skeleton} from "antd";
import {useAssetDrawerContext} from "../../../context/alertDetails/AssetDrawerContext";
import AssetDrawerContent, {GoBack} from "./AssetDrawerContent";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {capitalize} from "lodash";
import {useTranslation} from "react-i18next";
import {useAssetDetails} from "../../../context/assetDetails/AssetDetails";
import React from "react";

export const AssetDetailsDrawer = ({goBack}: {goBack?: GoBack}) => {
  const [open, setOpen] = useAssetDrawerContext();
  const [, {loading: isFetchingAssetDetail}] = useAssetDetails();
  const {t} = useTranslation()

  return (
    <Drawer title={null}
            size={"large"}
            destroyOnClose={true}
            styles={{header: {display: "none"}, content: {borderRadius: "16px 0 0 16px", backgroundColor: "#F5F5F5"}}}
            open={open}
            placement={"right"}
            onClose={() => setOpen(false)}>
      <Row gutter={[16, 16]}>
        <Col>
          <Button
            style={{padding: 0, marginBottom: '0.5rem'}}
            type={"text"}
            size={"small"}
            icon={<ArrowLeftOutlined/>}
            onClick={() => setOpen(false)}>
            {capitalize(t("actions.closeItem", {item: t("assets.buildingDetails")}))}
          </Button>
        </Col>
      </Row>
      {isFetchingAssetDetail && <Skeleton title={false} paragraph={{rows: 10}}/>}
      {!isFetchingAssetDetail && <AssetDrawerContent goBack={goBack}/>}
    </Drawer>
  );
}