import React, {useCallback, useMemo, useState} from 'react';
import {useGetAlertsPaginatedQuery, useUngroupAlertGroupMutation} from "../../../redux/api/alerts";
import {Alert} from "../../../types/api/alerts";
import {
  AlertTableColumn,
  AlertTableFilters,
  AlertTableSorters,
  DEFAULT_ALERT_TABLE_FILTERS,
  DEFAULT_ALERT_TABLE_SORTERS
} from "../../../types/internal/Alerts";
import {PaginationConfig} from "antd/lib/pagination";
import _, {capitalize} from "lodash";
import {getTranslationAlertToManageTableColumnKey} from "../../../utils/translation";
import dayjs, {Dayjs} from "dayjs";
import {DocumentsTableColumnHeader} from "../../documents/TablesViews/DocumentsTableColumnHeader";
import TableSorter from "../../common/TableSorter";
import SearchFilter from "../../common/filters/SearchFilter";
import IsForecastFilter from "../table/filters/IsForecastFilter";
import NumberRangeFilter from "../../common/filters/NumberRangeFilter";
import DateRangeFilter from "../../common/filters/DateRangeFilter";
import DateTimeFormat from "../../../locales/DateTimeFormat";
import {Trans, useTranslation} from "react-i18next";
import {Button, Pagination, Table, Tag, Typography} from "antd";
import {MinusSquareOutlined} from "@ant-design/icons";
import DeleteModalMessage from "../../common/documents/modals/DeleteModalMessage";
import {fromFeToBeAlertsFilter, fromFeToBeSorter} from "../../../utils/parser";
import {useMessageContext} from "../../../context/message/MessageContext";
import {ColumnsType} from "antd/lib/table";
import EventFilter from "../table/filters/EventFilter";
import {GenericPaginatedParamsApi} from "../../../types/api/paginatedApi";

function RemoveAlert({onUngroup}: { onUngroup: () => Promise<void> }) {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const {t} = useTranslation();
  const setMessage = useMessageContext()
  const [isLoading, setIsLoading] = useState(false)

  const handleUngroup = async () => {
    setIsLoading(true)
    try {
      await onUngroup()
      setMessage({success: capitalize(t('form.deleteSuccessMessage'))});
    } catch {
      setMessage({error: capitalize(t('form.deleteFailMessage'))});
    } finally {
      setIsModalOpen(false)
      setIsLoading(false)
    }
  }


  return <>
    <DeleteModalMessage
      title={{
        children: capitalize(t('alerts.removeConfirm')),
      }}
      paragraph={{
        children: <Trans
          i18nKey={'alerts.removeAlertDescription'} components={{p: <Typography.Paragraph/>}}
        />,
      }}
      modal={{
        open: isModalOpen,
        onCancel: () => setIsModalOpen(false),
      }}
      confirmButton={{
        loading: isLoading,
        children: capitalize(t('alerts.removeConfirm')),
        onClick: handleUngroup
      }}
    />
    <Button
      icon={<MinusSquareOutlined/>}
      type={'text'}
      danger
      onClick={() => setIsModalOpen(true)}
    />
  </>;
}

export default function AlertsSuggestionTable({alertGroup, alterGroupParams}:
                                                {
                                                  alertGroup: string,
                                                  alterGroupParams: GenericPaginatedParamsApi
                                                }) {
  const [filters, setFilters] = useState<AlertTableFilters>(DEFAULT_ALERT_TABLE_FILTERS);
  const [sorters, setSorters] = useState<AlertTableSorters>(DEFAULT_ALERT_TABLE_SORTERS);
  const [pagination, setPagination] = useState<PaginationConfig>({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    showTotal: (total, range) => t('table.pageSizeOfTotal', {
      rangeStart: range[0],
      rangeEnd: range[1],
      total,
    }),
    onChange: (page, pageSize) => {
      setPagination(prevPag => ({...prevPag, current: page, pageSize}))
    },
    style: {textAlign: 'center', marginTop: '40px'},
  });

  const {data, isFetching, refetch} = useGetAlertsPaginatedQuery({
    alert_group: alertGroup,
    page: pagination.current,
    page_size: pagination.pageSize,
    ...fromFeToBeAlertsFilter(filters),
    ...(!_.isEmpty(fromFeToBeSorter(sorters)) ? {
      ordering: fromFeToBeSorter(sorters),
    } : {}),
  });

  const [ungroupAlert] = useUngroupAlertGroupMutation()
  const {t} = useTranslation()

  const handleChangeFilter = (filters: Partial<AlertTableFilters>) => {
    setFilters(prevState => ({...prevState, ...filters}))
    setPagination(prevState => ({...prevState, current: 1}))
  }

  const getDefaultProps = useCallback((column: AlertTableColumn) => ({
    title: capitalize(t(getTranslationAlertToManageTableColumnKey(column))),
    key: column,
    dataIndex: column,
    width: 200
  }), [t]);

  const getDates = (dates: [a: string, b: string] | null): [Dayjs, Dayjs] | null => {
    let start: Dayjs | null = null;
    let end: Dayjs | null = null;
    if (dates) {
      start = dayjs(dates[0]);
      end = dayjs(dates[1]);
      return [start, end];
    } else {
      return null;
    }
  };

  const startFilters = getDates(filters.start_datetime);
  const endFilters = getDates(filters.end_datetime);
  const antdColumns: ColumnsType<Alert> = useMemo(() => [
    {
      key: 'delete',
      render: (value, record, index) => <RemoveAlert onUngroup={async () => {
        try {
          const res = await ungroupAlert({
            alertUuid: record.uuid,
            alertGroupUuid: alertGroup,
            paramsOfListToUpdate: alterGroupParams
          }).unwrap()
          if (res.is_manageable) {
            await refetch()
          }
        } catch {
        }
      }
      }/>,
      width: 50
    },
    {
      ...getDefaultProps('title'),
      width: 350,
      title: <DocumentsTableColumnHeader
        title={capitalize(t(getTranslationAlertToManageTableColumnKey('title')))}
        sorter={<TableSorter
          value={sorters.title}
          onChange={type => setSorters(prevState => ({...prevState, title: type}))}
        />}
        filter={
          <SearchFilter
            onChange={value => handleChangeFilter({title: value})}
            placeholder={capitalize(t('table.filterPlaceholder', {column: t(getTranslationAlertToManageTableColumnKey('title'))}))}
            selectedFilter={filters.title || undefined}
          />
        }
        onFilterReset={() => handleChangeFilter({title: null})}
        isFilterDisabled={_.isEmpty(filters.title)}
      />,
    },
    {
      ...getDefaultProps('event'),
      title: <DocumentsTableColumnHeader
        title={capitalize(t(getTranslationAlertToManageTableColumnKey('event')))}
        sorter={<TableSorter
          value={sorters.event}
          onChange={type => setSorters(prevState => ({...prevState, event: type}))}
        />}
        filter={
          <EventFilter
            activeFilters={filters['event'] || undefined}
            handleFilterChange={(values) => {
              handleChangeFilter({event: values})
            }}
          />
        }
        onFilterReset={() => handleChangeFilter({event: null})}
        isFilterDisabled={_.isEmpty(filters.event)}
      />,
      render: (v: string | null) => {
        if (v) {
          return <Tag color={'geekblue'}>
            #{v}
          </Tag>;
        } else {
          return <Tag color={'error'}>
            {capitalize(t('alerts.eventNotRelated'))}
          </Tag>;
        }
      },
    },
    {
      ...getDefaultProps('is_forecast'),
      title: <DocumentsTableColumnHeader
        title={capitalize(t(getTranslationAlertToManageTableColumnKey('is_forecast')))}
        sorter={<TableSorter
          value={sorters['is_forecast']}
          onChange={type => setSorters(prevState => ({...prevState, is_forecast: type}))}
        />}
        filter={<IsForecastFilter
          activeFilters={filters['is_forecast'] || undefined}
          handleFilterChange={values => handleChangeFilter({is_forecast: values})}

        />}
        onFilterReset={() => handleChangeFilter({is_forecast: null})}
        isFilterDisabled={_.isEmpty(filters.is_forecast)}
      />,
      render: (v: boolean) => {
        return capitalize(t(v ? 'alerts.isForecast' : 'alerts.isNotForecast'));
      },
    },
    {
      ...getDefaultProps('assets_count'),
      title: <DocumentsTableColumnHeader
        title={capitalize(t(getTranslationAlertToManageTableColumnKey('assets_count')))}
        sorter={<TableSorter
          value={sorters.assets_count}
          onChange={type => setSorters(prevState => ({...prevState, assets_count: type}))}
        />}
        filter={<NumberRangeFilter
          onChange={values => handleChangeFilter({assets_count: values})}
          min={0}
          max={10000}
          step={10}
          selectedFilters={filters['assets_count']}
          formatValue={value => `${value.toString()}`}
        />}
        onFilterReset={() => handleChangeFilter({assets_count: null})}
        isFilterDisabled={_.isEmpty(filters.assets_count)}
      />,
    },
    {
      ...getDefaultProps('source_url'),
      title: <DocumentsTableColumnHeader
        title={capitalize(t(getTranslationAlertToManageTableColumnKey('source_url')))}
        sorter={<TableSorter
          value={sorters['source_url']}
          onChange={type => setSorters(prevState => ({...prevState, source_url: type}))}
        />}
        filter={
          <SearchFilter
            onChange={value => handleChangeFilter({source_url: value})}
            placeholder={capitalize(t('table.filterPlaceholder', {column: t(getTranslationAlertToManageTableColumnKey('source_url'))}))}
            selectedFilter={filters['source_url'] || undefined}
          />
        }
        onFilterReset={() => handleChangeFilter({source_url: null})}
        isFilterDisabled={_.isEmpty(filters.source_url)}
      />,
    },
    {
      ...getDefaultProps('start_datetime'),
      title: <DocumentsTableColumnHeader
        title={capitalize(t(getTranslationAlertToManageTableColumnKey('start_datetime')))}
        sorter={<TableSorter
          value={sorters['start_datetime']}
          onChange={type => setSorters(prevState => ({...prevState, start_datetime: type}))}
        />}

        filter={<DateRangeFilter
          selectedFilters={startFilters}
          onChange={value => handleChangeFilter({
            start_datetime: [
              value[0].format('YYYY-MM-DD'),
              value[1].format('YYYY-MM-DD'),
            ]
          })}
        />}
        onFilterReset={() => handleChangeFilter({start_datetime: null})}
        isFilterDisabled={_.isEmpty(filters['start_datetime'])}
      />,
      render: (v: string | null) => {
        if (v) {
          return <DateTimeFormat>{v}</DateTimeFormat>;
        }
      },
    },
    {
      ...getDefaultProps('end_datetime'),
      title: <DocumentsTableColumnHeader
        title={capitalize(t(getTranslationAlertToManageTableColumnKey('end_datetime')))}
        sorter={<TableSorter
          value={sorters['end_datetime']}
          onChange={type => setSorters(prevState => ({...prevState, end_datetime: type}))}
        />}

        filter={<DateRangeFilter
          selectedFilters={endFilters}
          onChange={value => handleChangeFilter({
            end_datetime: [
              value[0].format('YYYY-MM-DD'),
              value[1].format('YYYY-MM-DD'),
            ]
          })}
        />}
        onFilterReset={() => handleChangeFilter({end_datetime: null})}
        isFilterDisabled={_.isEmpty(filters['end_datetime'])}
      />,
      render: (v: string | null) => {
        if (v) {
          return <DateTimeFormat>{v}</DateTimeFormat>;
        }
      },
    },
  ], [alertGroup, alterGroupParams, endFilters, filters, getDefaultProps, refetch, sorters, startFilters, t, ungroupAlert]);

  return <>
    <Table
      <Alert>
      scroll={{x: true}}
      rowKey={'uuid'}
      columns={antdColumns}
      pagination={false}
      dataSource={data?.results}
      loading={isFetching}
    />
    <Pagination
      disabled={isFetching}
      total={data?.count}
      {...pagination}
    />
  </>;
}