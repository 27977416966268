import {createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState} from "react";

type StepsContextType = {
  step: number,
  setStep: Dispatch<SetStateAction<number>>
}

const EditSteps = createContext<StepsContextType>({
  step: 0,
  setStep: () => undefined
});

export const useEditStepsContext = () => useContext(EditSteps)

export const EditStepsContext = (props: PropsWithChildren) => {
  const [step, setStep] = useState(0);
  return (
    <EditSteps.Provider value={{step, setStep}}>
      {props.children}
    </EditSteps.Provider>
  );
};