import ModalMessage from './ModalMessage';
import {TitleProps} from 'antd/lib/typography/Title';
import {ParagraphProps} from 'antd/lib/typography/Paragraph';
import {ButtonProps, ModalProps} from 'antd';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';

export default function DeleteModalMessage({title, paragraph, modal, confirmButton}: {
  title: TitleProps,
  paragraph: ParagraphProps,
  modal?: ModalProps,
  confirmButton?: ButtonProps
}) {

  const {t} = useTranslation();

  return <ModalMessage
    title={title}
    paragraph={paragraph}
    confirmButton={{danger: true, children: capitalize(t('actions.delete')), ...confirmButton}}
    modal={modal}
  />;
}