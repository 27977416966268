import {Button, ButtonProps} from 'antd';
import {FilterOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';

export default function FilterColumnsButton(button: ButtonProps) {

  const {t} = useTranslation();

  return <Button
    type={'link'}
    icon={<FilterOutlined/>}
    style={{padding: 0, alignSelf: 'flex-start'}}
    {...button}
  >
    {capitalize(t('table.filterColumns'))}
  </Button>;
}