import React, {useState} from "react";
import {Button} from "antd";
import {useTranslation} from "react-i18next";
import {useArchiveAlertGroupMutation} from "../../../redux/api/alerts";
import {useMessageContext} from "../../../context/message/MessageContext";
import {capitalize} from "lodash";
import PermissionRoute from "../../Authentication/PermissionRoute";
import {isAlertArchive} from "../../Authentication/permissions";
import DeleteModalMessage from "../../common/documents/modals/DeleteModalMessage";

export default function ArchiveAlertGroup({alertGroupUuid, afterArchive}: {
  alertGroupUuid: string,
  afterArchive?: () => void,
}) {

  const {t} = useTranslation();
  const [archiveAlertGroup] = useArchiveAlertGroupMutation();
  const setMessage = useMessageContext();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleArchiveAlerts = async () => {
    try {
      await archiveAlertGroup({alertGroupUuid}).unwrap();
      setMessage({success: capitalize(t('form.archiveSuccessMessage'))});
    } catch {
      setMessage({error: capitalize(t('form.archiveFailMessage'))});
    } finally {
      if (afterArchive)
        afterArchive();
    }
  };

  return <PermissionRoute
    isAuthorized={isAlertArchive}
  >
    <DeleteModalMessage
      title={{
        children: capitalize(t('alerts.archiveAlert')),
      }}
      paragraph={{
        children: t('alerts.archiveAlertGroup'),
      }}
      modal={{
        open: isModalOpen,
        onCancel: () => setIsModalOpen(false),
      }}
      confirmButton={{
        children: capitalize(t('alerts.archiveAlert')),
        onClick: handleArchiveAlerts,
        danger: false,
      }}
    />
    <Button
      onClick={() => setIsModalOpen(true)}
    >
      {capitalize(t('alerts.archiveAlert'))}
    </Button>
  </PermissionRoute>;
}