import {Button} from "antd";
import {
  useLazyGetAlertSourceTaskStatusQuery,
  useLazyRefreshAlertSourceDataQuery,
} from "../../../redux/api/alertSource";
import {useEffect, useState} from "react";
import {SyncOutlined} from "@ant-design/icons";
import {useMessageContext} from "../../../context/message/MessageContext";
import {capitalize} from "lodash";
import {useTranslation} from "react-i18next";

export default function RefreshAlertSource({alertSourceUuid, onFinish}: {
  alertSourceUuid: string,
  onFinish: () => void
}) {

  const [refresh, {isLoading}] = useLazyRefreshAlertSourceDataQuery()
  const [getTaskStatus] = useLazyGetAlertSourceTaskStatusQuery()
  const [pendingTaskUuid, setPendingTaskUuid] = useState<string[]>([])
  const isPending = pendingTaskUuid.length > 0 || isLoading
  const setMessage = useMessageContext()
  const {t} = useTranslation()

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isPending) {
        getTaskStatus({task_id: pendingTaskUuid[0]})
          .then(res => {
            if (res.data?.status === 200) {
              setPendingTaskUuid([])
              onFinish()
            } else {
              res.data?.task_id ? setPendingTaskUuid([res.data.task_id]) : onFinish()
            }
          })
          .catch(() => {
            setPendingTaskUuid([])
            onFinish()
            setMessage({error: capitalize(t('form.fetchingFailMessage'))})
          })
      }
    }, 5000)

    return () => {
      clearTimeout(timeout)
    }
  }, [getTaskStatus, isPending, onFinish, pendingTaskUuid, setMessage, t]);
  const handleRefresh = async () => {
    try {
      const response = await refresh({alertSourceUuid}).unwrap()
      if (response.status === 200) {
        setPendingTaskUuid([response.data.task_id])
      }
    } catch {
      setMessage({error: capitalize(t('form.fetchingFailMessage'))})
    }
  }

  return <>
    <Button
      icon={<SyncOutlined/>}
      type={'primary'}
      disabled={isPending}
      loading={isPending}
      onClick={handleRefresh}
    >
      {capitalize(t('actions.updateItem', {item: t('settings.dataSource', {count: 1})}))}
    </Button>
  </>
}