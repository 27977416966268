import {useTranslation} from "react-i18next";
import {Popup, useMap} from "react-leaflet";
import {MapDetailsContext} from "../../../context/assetsMap/AssetMapDetailsContext";
import {Button, Col, Divider, Row, Space, Typography} from "antd";
import {capitalize} from "lodash";
import {AssetDetail} from "../../assets/assetsMap/customPopup/AssetDetail";
import {useEventContext} from "../../../context/event/EventContext";
import {SelectCluster} from "../../events/importAlertsAreas/AssignCluster";
import {AlertOutlined} from "@ant-design/icons";
import {useCallback} from "react";
import {useMapLoadingContext} from "../../../context/baseMap/MapLoadingContext";
import {useAssetDrawerContext} from "../../../context/alertDetails/AssetDrawerContext";
import {useAssetDetails} from "../../../context/assetDetails/AssetDetails";


export const EventAssetDetailsPopup = ({markerUuid, isAssignCluster = false}: {
  markerUuid: string,
  isAssignCluster?: boolean
}) => {

  const {t} = useTranslation();
  const map = useMap();
  const {clusters, assets, updateClusterAsset} = useEventContext()
  const [isLoadingMap, setIsLoadingMap] = useMapLoadingContext()
  const currentCluster = assets.find(el => el.uuid === markerUuid)?.cluster_template
  const [, setIsOpen] = useAssetDrawerContext()
  const [setAssetUuid] = useAssetDetails();

  const handleSelect = useCallback((v: string | null) => {
    setIsLoadingMap(true)
    updateClusterAsset({cluster: v || null, asset: markerUuid, note: null})
      .finally(() => setIsLoadingMap(false))
  }, [markerUuid, setIsLoadingMap, updateClusterAsset])

  return (
    <Popup
      className={'asset-position-marker-popup'}
      autoPan={true}
      autoPanPadding={[(map.getSize().x - 300) / 2, (map.getSize().y - 226) / 2]}
    >
      <MapDetailsContext>
        <AssetDetail markerUuid={markerUuid}/>
        {isAssignCluster && <>
          <Divider/>
          <Row align={'middle'} style={{margin: '8px 0'}}>
            <Col>
              <Space
                size={'small'}
                wrap={false}
                direction={'horizontal'}
              >
                <AlertOutlined/>
                <Typography.Title level={5} style={{marginTop: 0}}>
                  {capitalize(t('clusters.cluster'))}
                </Typography.Title>
              </Space>
            </Col>
          </Row>
          <SelectCluster
            clusters={clusters}
            select={{
              disabled: isLoadingMap,
              value: currentCluster,
              onSelect: handleSelect
            }}
          />
        </>}
        <Row justify={'center'} style={{marginTop: '1rem'}}>
          <Col>
            <Button
              type={'primary'}
              onClick={() => {
                map.closePopup()
                setAssetUuid(markerUuid)
                setIsOpen(true)
              }}
            >
              {capitalize(t('misc.goToFullDetails'))}
            </Button>
          </Col>
        </Row>
      </MapDetailsContext>
    </Popup>
  );
};