import {Button} from 'antd';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
import {DownloadOutlined} from '@ant-design/icons';
import {useLazyGetDocumentsUrlQuery} from '../../redux/api/documents';
import {useEffect} from 'react';
import {useMessageContext} from '../../context/message/MessageContext';

export const DownloadMultipleDocuments = ({uuids}: { uuids: string[] }) => {
  const {t} = useTranslation();

  const setMessage = useMessageContext();

  const [
    getDocumentsUrl,
    {
      isLoading: isLoadingDocumentsUrl,
      isFetching: isFetchingDocumentsUrl,
      isSuccess: isGetDocumentsUrlSuccess,
      isError: isGetDocumentsUrlError,
      data: getUrlResponse,
    },
  ] = useLazyGetDocumentsUrlQuery();

  useEffect(() => {
    if (isGetDocumentsUrlSuccess && getUrlResponse) {
      getUrlResponse.forEach(amazonUrl => {
        fetch(amazonUrl.url, {
          method: 'GET',
          headers: {},
        })
          .then(response => {
            response.arrayBuffer().then(function (buffer) {
              const url = URL.createObjectURL(new Blob([buffer]));
              console.log('url: ', getUrlResponse);
              const responseContentDisposition = ((new URL(amazonUrl.url)).searchParams.get('response-content-disposition')) || '';
              const filename = responseContentDisposition.substring(responseContentDisposition.indexOf('"') + 1, responseContentDisposition.lastIndexOf('"'));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `${filename}`); //or any other extension
              document.body.appendChild(link);
              link.click();
              URL.revokeObjectURL(url);
            });
          })
          .catch(err => {
            console.log(err);
          });
      });
    }
  }, [getUrlResponse, isGetDocumentsUrlSuccess]);

  useEffect(() => {
    if (isGetDocumentsUrlError) {
      setMessage({error: capitalize(t('form.fetchingFailMessage'))});
    }
  }, [isGetDocumentsUrlError, setMessage, t]);

  return <>
    <Button
      disabled={!uuids.length}
      icon={<DownloadOutlined/>}
      loading={isLoadingDocumentsUrl || isFetchingDocumentsUrl}
      onClick={() => getDocumentsUrl({documentUuids: uuids})}
    >
      {capitalize(t('actions.download'))}
    </Button>
  </>;
};