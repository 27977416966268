import {Col, Radio, Row, Space} from 'antd';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import React from 'react';
import EventTypesDocumentsTableView from './TablesViews/EventTypesDocumentsTableView';
import AssetsDocumentsTableView from './TablesViews/AssetsDocumentsTableView';
import EventsDocumentsTableView from './TablesViews/EventsDocumentsTableView';
import NotAssociatedDocumentsTableView from './TablesViews/NotAssociatedDocumentsTableView';
import {AlertsDocumentsTableView} from './TablesViews/AlertsDocumentsTableView';
import ResetDocumentsFiltersSorters from './TablesViews/filters/ResetDocumentsFiltersSorters';
import {useGetUserProfileQuery} from '../../redux/api/locales';
import {
  isAlertDocumentView,
  isAssetDocumentView,
  isEventDocumentView,
  isEventTypeDocumentView,
  isGenericDocumentView,
} from '../Authentication/permissions';

export type DocumentTableViews = 'event_types' | 'assets' | 'events' | 'alerts' | 'none'
export default function DocumentsTable({selectedView, setSelectedView}: {
  selectedView: DocumentTableViews,
  setSelectedView: React.Dispatch<React.SetStateAction<DocumentTableViews>>
}) {

  const {t} = useTranslation();

  const {
    data: userData,
  } = useGetUserProfileQuery();

  const isEventTypeDocs = userData?.type ? isEventTypeDocumentView(userData?.type) : false;
  const isAssetsDocs = userData?.type ? isAssetDocumentView(userData?.type) : false;
  const isEventsDocs = userData?.type ? isEventDocumentView(userData?.type) : false;
  const isAlertsDocs = userData?.type ? isAlertDocumentView(userData?.type) : false;
  const isGenericDocs = userData?.type ? isGenericDocumentView(userData?.type) : false;

  return <>
    <Row justify={'space-between'} align={'middle'} gutter={[16, 16]}>
      <Col>
        <Space align={'center'} wrap>
          <ResetDocumentsFiltersSorters view={selectedView}/>
          <Radio.Group
            onChange={(e) => setSelectedView(e.target.value)}
            value={selectedView}
          >
            {[
              ...isEventTypeDocs ? [{
                label: capitalize(t('documents.general')),
                key: 'event_types',
              }] : [],
              ...isAssetsDocs ? [{
                label: capitalize(t('documents.assetSpecific')),
                key: 'assets',
              }] : [],
              ...isEventsDocs ? [{
                label: capitalize(t('documents.emergencySpecific')),
                key: 'events',
              }] : [],
              ...isAlertsDocs ? [{
                label: capitalize(t('documents.alertsSpecific')),
                key: 'alerts',
              }] : [],
              ...isGenericDocs ? [{
                label: capitalize(t('documents.notAssociedDocuments')),
                key: 'none',
              }] : [],
            ].map(el => <Radio.Button
              key={el.key}
              value={el.key}>
              {el.label}
            </Radio.Button>)
            }
          </Radio.Group>
        </Space>
      </Col>
      {isEventTypeDocs && selectedView === 'event_types' && <EventTypesDocumentsTableView/>}
      {isAssetsDocs && selectedView === 'assets' && <AssetsDocumentsTableView/>}
      {isEventsDocs && selectedView === 'events' && <EventsDocumentsTableView/>}
      {isAlertsDocs && selectedView === 'alerts' && <AlertsDocumentsTableView/>}
      {isGenericDocs && selectedView === 'none' && <NotAssociatedDocumentsTableView/>}
    </Row>
  </>;
}