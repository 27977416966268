import ResponsiveContent from '../../layout/ResponsiveContent';
import EventCreationForm from '../../components/events/creation/EventCreationForm';
import {EVENT_STATES} from '../../types/api/getEventsResult';
import {EventCreationFormType} from '../../types/internal/events';
import {useCreateEventMutation} from '../../redux/api/events';
import {useEffect} from 'react';
import {useMessageContext} from '../../context/message/MessageContext';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {Card, Steps} from 'antd';

export default function EventCreationFromScratch() {

  const [createEvent, {data, isSuccess, isError, isLoading}] = useCreateEventMutation();
  const setAntdMessage = useMessageContext();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const steps = [
      {
        title: capitalize(t('events.stepCreationData')),
      },
      {
        title: capitalize(t('events.stepCreationAreas')),
      },
      {
        title: capitalize(t('events.stepCreationSummary')),
      },
    ]
  ;

  useEffect(() => {
    if (isSuccess && data) {
      setAntdMessage({
        success: capitalize(t('form.creationSuccessMessage')),
      });
      navigate(`/events/${data.uuid}`);

    }
  }, [data, isSuccess, navigate, setAntdMessage, t]);

  useEffect(() => {
    if (isError) {
      setAntdMessage({
        error: capitalize(t('form.creationFailMessage')),
      });
    }
  }, [isError, setAntdMessage, t]);

  return <ResponsiveContent
    title={capitalize(t('actions.createItem', {item: t('events.event', {count: 1})}))}
  >
    <Steps
      current={0}
      items={steps}
    />
    <Card>
      <EventCreationForm
        form={{
          initialValues: {
            has_ancestor_event: false,
          },
          onFinish: ({
                       state,
                       name,
                       event_type,
                       description,
                       has_ancestor_event,
                       ancestor_event,
                       forecast_start_datetime,
                       start_datetime,
                     }: EventCreationFormType) => {
            if (state === EVENT_STATES.Forecast) {
              createEvent({
                state,
                name,
                event_type,
                description,
                ...has_ancestor_event && {ancestor_event},
                start_datetime: forecast_start_datetime?.toISOString() || '',
              });
            } else {
              createEvent({
                state,
                name,
                event_type,
                description,
                ...has_ancestor_event && {ancestor_event},
                start_datetime: start_datetime.toISOString(),
              });
            }
          },
        }}
        confirmButton={{
          disabled: isLoading,
          loading: isLoading
        }}
      />
    </Card>
  </ResponsiveContent>;
}