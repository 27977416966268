import {Button, List, Modal, Typography} from "antd";
import {useState} from "react";
import {useGetEventNotesOfTheAssetPaginatedQuery} from "../../../redux/api/assets";
import {skipToken} from "@reduxjs/toolkit/query";
import {capitalize} from "lodash";
import {useTranslation} from "react-i18next";
import DateTimeFormat from "../../../locales/DateTimeFormat";
import {VALUE_NOT_PRESENT} from "../../../utils/translation";
import {PaginationConfig} from "antd/lib/pagination";

export default function EventAssetNotesModal({eventUuid, assetUuid}: { eventUuid: string, assetUuid: string }) {

  const {t} = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [pagination, setPagination] = useState<PaginationConfig>({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    showTotal: (total, range) => t('table.pageSizeOfTotal', {
      rangeStart: range[0],
      rangeEnd: range[1],
      total,
    }),
    onChange: (page, pageSize) => setPagination(prevPag => ({...prevPag, current: page, pageSize})),
    style: {textAlign: 'center'},
  });
  const {data, isFetching, isUninitialized} = useGetEventNotesOfTheAssetPaginatedQuery(isModalOpen ? {
    eventUuid,
    assetUuid,
    page_size: pagination.pageSize,
    page: pagination.current,
  } : skipToken)


  return <>
    <Modal
      title={capitalize(t('documents.notes'))}
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      footer={false}
    >
      <List
        pagination={{
          ...pagination,
          total: data?.count
        }}
        loading={isFetching || isUninitialized}
        rowKey={'uuid'}
        dataSource={data?.results}
        renderItem={item => <List.Item>
          <List.Item.Meta
            title={<DateTimeFormat>{item.date_creation}</DateTimeFormat>}
            description={`${capitalize(t('user.user', {count: 1}))}: ${item.user_edit || VALUE_NOT_PRESENT}`}
          />
          <Typography.Text>{capitalize(t('events.communicationBody'))}: </Typography.Text>{item.text}
        </List.Item>}
      />

    </Modal>
    <Button
      onClick={() => setIsModalOpen(true)}
      type={'link'}
    >
      {capitalize(t('documents.viewDetails'))}...
    </Button>
  </>
}