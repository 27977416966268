import {useLazyGetAssetsQuery} from '../../../../redux/api/assets';
import {OptionLabelValue} from '../../../../types/internal/OptionLabelValue';
import {useCallback, useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
import {getTranslationAssetTableColumnKey} from '../../../../utils/translation';
import DEPRECATEDListWithSearchFilter from '../../../common/filters/DEPRECATEDListWithSearchFilter';

export const DocumentsAssetFilters = ({activeFilters, handleFilterChange}: {
  activeFilters: OptionLabelValue[] | null,
  handleFilterChange: (values: OptionLabelValue[]) => void,
}) => {

  const {t} = useTranslation();

  const [
    getAssets,
    {
      data,
      isLoading,
      isFetching,
      isUninitialized,
    },
  ] = useLazyGetAssetsQuery();

  const options: OptionLabelValue[] = useMemo(() => data?.results.map(el => ({
    label: el.sigi_code,
    value: el.uuid,
  })) || [], [data?.results]);

  const placeholder = capitalize(t('table.filterPlaceholder', {column: t(getTranslationAssetTableColumnKey('sigi_code'))}));

  const handleGetOptions = useCallback((page: number, q?: string) => {
    if (q) {
      getAssets({filters: {search_by_sigi: q}, page_size: 30, page});
    } else {
      getAssets({filters: {}, page_size: 30, page});
    }
  }, [getAssets]);

  useEffect(() => {
    handleGetOptions(1);
  }, [handleGetOptions]);

  return <DEPRECATEDListWithSearchFilter
    getOptions={handleGetOptions}
    fetchedOptions={options}
    selectedOptions={activeFilters || []}
    setSelectedOptions={handleFilterChange}
    isLoadingOptions={isLoading || isFetching || isUninitialized}
    total={data?.count || 0}
    search={{placeholder}}
  />;
};