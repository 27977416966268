import {useControlsAction} from '../../../../context/baseMap/ControlContext';
import {useLazyGetGeoCodeQuery} from '../../../../redux/api/geocoding';
import _, {capitalize} from 'lodash';
import {CustomLoading} from '../../CustomLoading';
import {AutoComplete, Input, Typography} from 'antd';
import {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ASSET_MAP_ACTIONS} from '../constants/map';
import L from 'leaflet';
import {useMap} from 'react-leaflet';
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

const {Text} = Typography;
export const GeoSearch = (props: { id?: string }) => {

  const {t} = useTranslation();

  const map = useMap();
  const {
    action: {
      currentAction,
      actionEvent,
    },
    changeAction,
  } = useControlsAction();

  const [
    getGeocode,
    {
      isError,
      isLoading,
      isFetching,
    },
  ] = useLazyGetGeoCodeQuery();

  const [
    searchedLocation,
    setSearchedLocation,
  ] = useState<string>('');
  const [
    suggestedLocations,
    setSuggestedLocations,
  ] = useState<google.maps.GeocoderResult[]>([]);

  const debouncedSearch = useMemo(() =>
    _.debounce(
      async (value) => {
        if (value) {
          const res = await getGeocode({address: value}).unwrap();
          if (res.status === 'OK' && res.results) {
            setSuggestedLocations(res.results);
          }
        }
      }, 400), [getGeocode]);

  useEffect(() => {
    switch (currentAction) {
      case ASSET_MAP_ACTIONS.SEARCH_LOCATION:
        map.fitBounds(L.latLngBounds(
          [actionEvent.bounds[0]?.lat, actionEvent.bounds[0]?.lng],
          [actionEvent.bounds[1]?.lat, actionEvent.bounds[1]?.lng],
        ));
        map.setView(L.latLng(actionEvent.position?.lat, actionEvent.position?.lng));
        break;
      default:
        break;
    }
  }, [actionEvent?.bounds, actionEvent?.position?.lat, actionEvent?.position?.lng, currentAction, map]);

  const {md, xl, xxl} = useBreakpoint()
  const isSmallScreen = !(md || xl || xxl)

  return (
    <AutoComplete
      style={{display: 'flex', borderRadius: '16px !important'}}
      options={suggestedLocations?.map((item, index) => (
        {
          key: index,
          label: item.formatted_address,
          value: item.formatted_address,
          ...item,
        }
      ))}
      value={searchedLocation}
      onChange={setSearchedLocation}
      id={props.id || 'search'}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore wrong type declaration from antd (autocomplete is a base inputComponent)
      autoComplete={'off'}
      // getPopupContainer={() => document.getElementById('map-controls') || document.body}
      filterOption={false}
      notFoundContent={(
        <div style={{padding: 5}}>
          {
            suggestedLocations.length === 0 &&
            !isError &&
            !isFetching &&
            !isLoading && (
              searchedLocation ? (
                <Text type={'secondary'}>
                  {`${capitalize(t('form.noResults'))}...`}
                </Text>
              ) : (
                <Text type={'secondary'}>
                  {`${capitalize(t('form.typeToSearch'))}...`}
                </Text>
              ))}
          {
            (isLoading || isFetching) && (
              <CustomLoading/>
            )}
          {
            isError && (
              <Text type={'secondary'}>
                {`${capitalize(t('form.somethingWentWrongDuringFetch'))}}...`}
              </Text>
            )}
        </div>
      )}
      onSearch={debouncedSearch}
      onSelect={(value, option) => {
        if (option.geometry) {
          changeAction(ASSET_MAP_ACTIONS.SEARCH_LOCATION, {
            // @ts-ignore wrong type delared from @types/google.maps
            bounds: [option.geometry.bounds?.southwest || option.geometry.viewport.southwest, option.geometry.bounds?.northeast || option.geometry.viewport.northeast],
            position: {
              // @ts-ignore wrong type delared from @types/google.maps
              lat: option.geometry.location.lat as number,
              // @ts-ignore wrong type delared from @types/google.maps
              lng: option.geometry.location.lng as number,
            },
          });
        }
      }}
    >
      <Input.Search
        size="middle"
        placeholder={`${capitalize(t('misc.searchLocation'))}...`}
        enterButton
        style={{
          ...!isSmallScreen && {width: '25rem'}
        }}
      />
    </AutoComplete>
  );
};