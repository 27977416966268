import {useEffect, useMemo, useRef, useState} from 'react';
import {Popup, useMap, useMapEvents} from 'react-leaflet';
import {useLocationContext} from '../../../context/addAsset/AssetLocationContext';
import {Marker as LeafletMarker} from 'leaflet';
import {CheckOutlined, DeleteOutlined, DragOutlined} from '@ant-design/icons';
import {Button, Col, Divider, Row, Typography} from 'antd';
import {useTranslation} from 'react-i18next';
import _, {capitalize} from 'lodash';
import {CustomMarker} from '../../common/map/CustomMarker';
import {useLazyGetReverseGeocodeQuery} from '../../../redux/api/geocoding';

const {Paragraph, Title} = Typography;
export const AssetPositionMarker = () => {
  
  const markerRef = useRef<LeafletMarker | null>(null);
  
  const {t} = useTranslation();
  const map = useMap();
  
  const [
    location,
    setLocation,
  ] = useLocationContext();
  
  const [
    isMarkerDraggable,
    setIsMarkerDraggable,
  ] = useState(false);
  
  const [
    coordinates,
    setCoordinates,
  ] = useState<[number, number] | null>(null);
  
  const [
    getReverseGeocoding,
  ] = useLazyGetReverseGeocodeQuery();
  
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        if (markerRef.current) {
          setCoordinates([markerRef.current.getLatLng().lat, markerRef.current.getLatLng().lng]);
        }
      },
      contextmenu() {
        markerRef.current?.openPopup();
      },
    }),
    [],
  );
  
  useMapEvents({
    dblclick(e) {
      if (!location.position) {
        getReverseGeocoding({lat: e.latlng.lat, lon: e.latlng.lng})
          .unwrap()
          .then(res => {
            if (!_.isEmpty(res.results)) {
              const localityName = (res.results[0].address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('locality'))?.long_name)?.toUpperCase() || null;
              const municipalityName = (res.results[0].address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('administrative_area_level_3'))?.long_name)?.toUpperCase() || null;
              setLocation({
                country: res.results[0].address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('country'))?.short_name || null,
                admin_level_1: res.results[0].address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('administrative_area_level_1'))?.long_name || null,
                admin_level_2: res.results[0].address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('administrative_area_level_2'))?.short_name || null,
                admin_level_3: res.results[0].address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('administrative_area_level_3'))?.long_name || null,
                locality: (localityName !== municipalityName ? res.results[0].address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('locality'))?.long_name : null) || null,
                address: [
                  res.results[0].address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('route'))?.long_name || null,
                  res.results[0].address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('street_number'))?.long_name || null,
                ].filter(item => item !== null).join(', ') || null,
                postal_code: res.results[0].address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('postal_code'))?.long_name || null,
                position: [e.latlng.lat, e.latlng.lng],
                geocoding_position: [e.latlng.lat, e.latlng.lng],
              });
            }
            
          })
          .catch((e) => console.error(e));
      }
    },
  });
  
  useEffect(() => {
    if (location.position) {
      setCoordinates(location.position);
      map.setView(location.position, 18);
    }
  }, [location.position, map]);
  
  return (
    <>
      {coordinates && (
        <CustomMarker
          position={coordinates}
          eventHandlers={eventHandlers}
          ref={markerRef}
          key={'asset_location'}
          draggable={isMarkerDraggable}
        >
          <Popup className={'asset-position-preview-marker-popup'}>
            <>
              <Row>
                <Col>
                  <Title level={4}>{capitalize(t('misc.newAsset'))}</Title>
                </Col>
              </Row>
              <Row>
                <Col flex={1}>
                  <Paragraph type={'secondary'}>
                    {capitalize(t('misc.buildingWillBePlacedHere'))}.
                  </Paragraph>
                </Col>
              </Row>
              <Divider/>
              <Row justify={'center'} gutter={[16, 16]}>
                <Col>
                  {!isMarkerDraggable && (
                    <Button
                      type={'default'}
                      disabled={isMarkerDraggable}
                      onClick={() => {
                        setIsMarkerDraggable(draggable => !draggable);
                        map.closePopup();
                      }}
                      icon={<DragOutlined/>}
                    >
                      {capitalize(t('misc.correctPosition'))}
                    </Button>)}
                  {isMarkerDraggable && (
                    <Button
                      type={'primary'}
                      disabled={!isMarkerDraggable}
                      onClick={() => {
                        setIsMarkerDraggable(draggable => !draggable);
                        map.closePopup();
                        setLocation(location => (
                          {
                            ...location,
                            position: coordinates,
                          }));
                      }}
                      icon={<CheckOutlined/>}
                    >
                      {capitalize(t('misc.confirmPosition'))}
                    </Button>)}
                </Col>
                {(
                  isMarkerDraggable &&
                  location.geocoding_position &&
                  location.position
                ) && (
                  <Col>
                    <Button
                      onClick={() => {
                        if (location.position) {
                          getReverseGeocoding({lat: coordinates[0], lon: coordinates[1]})
                            .unwrap()
                            .then(res => {
                              if (!_.isEmpty(res.results)) {
                                const localityName = (res.results[0].address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('locality'))?.long_name)?.toUpperCase() || null;
                                const municipalityName = (res.results[0].address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('administrative_area_level_3'))?.long_name)?.toUpperCase() || null;
                                setLocation(location => (
                                  {
                                    ...location,
                                    country: res.results[0].address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('country'))?.short_name || null,
                                    admin_level_1: res.results[0].address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('administrative_area_level_1'))?.long_name || null,
                                    admin_level_2: res.results[0].address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('administrative_area_level_2'))?.short_name || null,
                                    admin_level_3: res.results[0].address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('administrative_area_level_3'))?.long_name || null,
                                    locality: (localityName !== municipalityName ? res.results[0].address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('locality'))?.long_name : null) || null,
                                    address: [
                                      res.results[0].address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('route'))?.long_name || null,
                                      res.results[0].address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('street_number'))?.long_name || null,
                                    ].filter(item => item !== null).join(', ') || null,
                                    postal_code: res.results[0].address_components.find((address_component: google.maps.GeocoderAddressComponent) => address_component.types.includes('postal_code'))?.long_name || null,
                                    geocoding_position: coordinates,
                                  }
                                ));
                              }
                              setIsMarkerDraggable(false);
                            })
                            .catch((e) => console.error(e));
                        }
                        
                      }}
                    >
                      Conferma e ricalcola
                    </Button>
                  </Col>
                
                )}
                <Col>
                  {isMarkerDraggable && (
                    <Button
                      type={'default'}
                      onClick={() => {
                        setCoordinates(location.geocoding_position);
                        setIsMarkerDraggable(false);
                        map.closePopup();
                      }}
                    >
                      {capitalize(t('form.cancel'))}
                    </Button>
                  )}
                  {!isMarkerDraggable && (
                    <Button
                      type={'default'}
                      danger={true}
                      onClick={() => {
                        setCoordinates(null);
                        setLocation(location => (
                          {
                            country: null,
                            admin_level_1: null,
                            admin_level_2: null,
                            admin_level_3: null,
                            locality: null,
                            address: null,
                            postal_code: null,
                            position: null,
                            geocoding_position: null,
                          }));
                        map.closePopup();
                      }}
                      icon={<DeleteOutlined/>}
                    >
                      {capitalize(t('form.delete'))}
                    </Button>
                  )}
                </Col>
              </Row>
            </>
          </Popup>
        </CustomMarker>
      )}
    </>
  );
};