import {capitalize} from "lodash";
import {Form, Select, SelectProps, Tag} from "antd";
import {useGetAllTagsQuery} from "../../../../redux/api/tags";
import chroma from "chroma-js";
import {useTranslation} from "react-i18next";


type TagRender = SelectProps['tagRender'];
const MailTag: TagRender = (props) => {
  const {label, value, closable, onClose} = props;
  const {
    data: tags,
  } = useGetAllTagsQuery();
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const color = tags?.find(tag => tag.uuid === value)?.color

  return (
    <Tag
      color={color ? chroma(color).alpha(0.2).hex() : ""}
      onMouseDown={onPreventMouseDown}
      onClose={onClose}
      style={{
        color: color ? chroma(color).darken(0.75).hex() : "",
        border: color ? `1px solid ${chroma(color).alpha(0.5).hex()}` : "",
      }}
      bordered={true}
    >
      {label}
    </Tag>
  );
};
export default function CommunicationTag() {

  const {t} = useTranslation()
  const {
    data: tags,
    isFetching: areTagsFetching,
    isUninitialized: areTagsUninitialized
  } = useGetAllTagsQuery();

  const isLoadingTags = areTagsUninitialized || areTagsFetching;

  return <Form.Item
    label={capitalize((t('mailTemplate.tag')))}
    name={'tags'}
  >
    <Select
      tagRender={MailTag}
      loading={isLoadingTags}
      placeholder={capitalize((t('actions.selectItem', {item: t('mailTemplate.tag')})))}
      mode={'multiple'}
      options={!isLoadingTags ? tags?.map(tag => ({
        label: tag.frontend_name,
        value: tag.uuid,
      })) : []}
    />
  </Form.Item>
}