import {Card, Col, Row} from 'antd';
import {useAssetRealEstateContext} from '../../context/addAsset/AssetRealEstateContext';
import {RealEstateHubSearchSelect} from '../common/searchSelect/RealEstateHubSearchSelect';
import {ContactPeopleSearchSelect} from '../common/searchSelect/ContactPeopleSearchSelect';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';

export const AssetRealEstateFormCard = () => {
  
  const {t} = useTranslation();
  const [
    realestateDetails,
    setRealestateDetails,
  ] = useAssetRealEstateContext();
  
  return (
    <Card title={capitalize(t('assets.realEstateData'))}>
      <Row gutter={[40, 0]}>
        <Col xs={24} lg={12}>
          <RealEstateHubSearchSelect
            value={realestateDetails.realestate_hub}
            onClear={() => {
              setRealestateDetails(details => ({
                ...details,
                realestate_hub: null,
              }));
            }}
            onSelect={(value, option) => {
              setRealestateDetails(details => ({
                ...details,
                realestate_hub: option,
              }));
            }}
          />
        </Col>
      </Row>
      <Row gutter={[40, 0]}>
        <Col xs={24} lg={12}>
          <ContactPeopleSearchSelect
            values={realestateDetails.contact_people}
            onClear={() => {
              setRealestateDetails(details => ({
                ...details,
                contact_people: [],
              }));
            }
            }
            onChange={(optionValue, options) => {
              setRealestateDetails(details => ({...details, contact_people: optionValue}));
            }}
            afterCreation={(contactToAdd) => {
              setRealestateDetails(details => ({
                ...details,
                contact_people: [
                  ...realestateDetails.contact_people,
                  {
                    label: contactToAdd.full_name,
                    value: contactToAdd.uuid,
                  },
                ],
              }));
            }}
          />
        </Col>
      </Row>
    </Card>
  );
};