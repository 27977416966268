import {usePatchEventMutation} from "../../redux/api/events";
import {Col, Row, Switch, Typography} from "antd";
import {useTranslation} from "react-i18next";
import PermissionRoute from "../Authentication/PermissionRoute";
import {isEventEdit} from "../Authentication/permissions";
import {useMessageContext} from "../../context/message/MessageContext";
import {capitalize} from "lodash";
import {useEventContext} from "../../context/event/EventContext";
import {EVENT_STATES} from "../../types/api/getEventsResult";

const {Text} = Typography;
export default function GrecimEdit() {

  const [updateEvent, {isLoading, isUninitialized}] = usePatchEventMutation()
  const {t} = useTranslation()
  const setMessage = useMessageContext()
  const {event} = useEventContext()
  const onGRECIMSelectionChange = (checked: boolean) => {
    if (event?.uuid) {
      updateEvent({uuid: event?.uuid, is_special_management: checked})
        .then(res => setMessage({success: capitalize(t('form.editSuccessMessage'))}))
        .catch(err => setMessage({error: capitalize(t('form.editFailMessage'))}))
    }
  };

  return (event?.state === EVENT_STATES.Happened || event?.state === EVENT_STATES.Forecast) ?
    <PermissionRoute isAuthorized={isEventEdit}>
      <Row gutter={[16, 16]} style={{marginTop: '1rem'}}>
        <Col>
          <Switch
            checked={event?.is_special_management}
            onChange={onGRECIMSelectionChange}
            loading={isLoading}
            disabled={isLoading}
          />
        </Col>
        <Col>
          <Text>
            {t('events.handleWithGRECIM')}
          </Text>
        </Col>
      </Row>
    </PermissionRoute> : null
}