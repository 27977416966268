import {Card} from "antd";
import ClimateChangeContent from "./ClimateChangeContent";
import {capitalize} from "lodash";
import {useTranslation} from "react-i18next";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import {useAssetDetails} from "../../../../context/assetDetails/AssetDetails";
import ClimateChangeReportDownload from "./ClimateChangeReportDownload";

export default function ClimateChangeCard() {

  const {t} = useTranslation()
  const [, {asset, loading: isLoadingAssetData}] = useAssetDetails();
  const {xs} = useBreakpoint()

  return <Card
    title={capitalize(t('assets.climateChange'))}
    bodyStyle={{
      ...xs && {padding: 0}
    }}
    extra={<ClimateChangeReportDownload
      assetUuid={asset.uuid}
      buttonProps={{
        disabled: asset.position === null
      }}
    />}
  >
    <ClimateChangeContent assetUuid={asset.uuid} missingCoordinates={asset.position === null}/>
  </Card>
}