import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState
} from "react";
import {DrawableGeometry} from "../../components/common/map/controls/newDrawControls/NewDrawControls";
import {BaseMapContext} from "../baseMap/BaseMapContext";

export type DrawableGeometryExtend  = DrawableGeometry & {drawCount?: number, entity?: string, name: string}

type AlertStepsContextType = {
  drawnGeometries: DrawableGeometryExtend[],
  setDrawnGeometries: Dispatch<SetStateAction<DrawableGeometryExtend[]>>,
  drawCount: number,
  setDrawCount: Dispatch<SetStateAction<number>>
}

const AlertSteps = createContext<AlertStepsContextType>({
  drawnGeometries: [],
  setDrawnGeometries: () => [],
  drawCount: 0,
  setDrawCount: () => undefined
});

export const useAlertStepsContext = () => useContext(AlertSteps)

export const AlertStepsContext = (props: PropsWithChildren) => {
  const [drawnGeometries, setDrawnGeometries] = useState<DrawableGeometryExtend[]>([])
  const [drawCount, setDrawCount] = useState(0)

  return (
    <AlertSteps.Provider value={{
      drawnGeometries,
      setDrawnGeometries,
      drawCount,
      setDrawCount
    }}>
      <BaseMapContext>
        {props.children}
      </BaseMapContext>
    </AlertSteps.Provider>
  );
};