import NewDrawControls, {
  CutGeometries,
  DrawableGeometry,
} from '../../common/map/controls/newDrawControls/NewDrawControls';
import L from 'leaflet';
import {useCallback, useEffect, useRef, useState} from 'react';
import {
  NewGlobalPolygon,
  useLazyNewGetGlobalGeoshapesQuery,
  useNewCustomPolygonCreationMutation,
  useNewDeleteCustomPolygonMutation,
} from '../../../redux/api/geoshapes';
import {
  addAssetsMapGeographicalFilter,
  removeAssetsMapGeographicalFilter,
  selectMapFilters,
  setAssetsMapGeographicalFilters,
} from '../../../redux/slices/mapFilters';
import {useDispatch, useSelector} from 'react-redux';
import {setPagination} from '../../../redux/slices/assets';
import _, {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import {useControlsAction} from '../../../context/baseMap/ControlContext';
import {ASSET_MAP_ACTIONS} from '../../common/map/constants/map';
import {Feature, multiPolygon, MultiPolygon, Position} from '@turf/helpers';
import {Modal} from 'antd';
import {WarningOutlined} from '@ant-design/icons';
import {flipTurfFeatureCoordinates} from '../../../utils/polygons';

const AssetsMapDrawControlWrapper = () => {
  
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {action: {currentAction, actionEvent}, changeAction} = useControlsAction();
  
  const [modal, contextHolder] = Modal.useModal();
  
  const [createCustomPolygon] = useNewCustomPolygonCreationMutation();
  const [deletePolygon] = useNewDeleteCustomPolygonMutation();
  const [getGlobalGeoShape] = useLazyNewGetGlobalGeoshapesQuery();
  
  const polygonFilter = useSelector(selectMapFilters).polygons;
  
  const [
    shapes,
    setShapes,
  ] = useState<DrawableGeometry[]>([]);
  
  const shapesRef = useRef<DrawableGeometry[]>([]);
  const polygonFilterRef = useRef<{ uuid: string, globalpolygon: string }[]>([]);
  
  useEffect(() => {
    shapesRef.current = shapes;
  }, [shapes]);
  
  useEffect(() => {
    polygonFilterRef.current = polygonFilter;
  }, [polygonFilter]);
  
  const fetchGlobalPolygonHandler = useCallback(() => {
    if (!_.isEmpty(polygonFilter) && _.isEmpty(shapes)) {
      const promises: Promise<{ uuid: string, coordinates: Position[][][] } | null>[] = [];
      
      polygonFilter.forEach(polygon => {
        const promise = getGlobalGeoShape(polygon.globalpolygon)
          .unwrap()
          .then((result: NewGlobalPolygon) => ({
            uuid: polygon.uuid,
            coordinates: result.geometry.coordinates,
          }))
          .catch(error => {
            console.error(capitalize(t('form.fetchingFailMessage')));
            return null; // o gestisci l'errore in modo appropriato
          });
        promises.push(promise);
      });
      
      Promise.all(promises)
        .then(results => {
          // @ts-ignore
          setShapes(results.filter(result => result !== null));
        });
    }
    
  }, [getGlobalGeoShape, polygonFilter, shapes, t]);
  const shapeCreationHandler = useCallback((createdFeature: Feature<MultiPolygon, {}>, map: L.Map) => {
    return new Promise<void>((onResolve, onReject) => {
      createCustomPolygon(createdFeature)
        .unwrap()
        .then(result => {
          dispatch(addAssetsMapGeographicalFilter({
            uuid: result.uuid,
            globalpolygon: result.globalpolygon,
          }));
          dispatch(setPagination({current: 1}));
          setShapes(shapes => [...shapes, {
            uuid: result.uuid,
            coordinates: createdFeature.geometry.coordinates,
          }]);
          map.fitBounds(new L.Polygon(L.GeoJSON.coordsToLatLngs(result.geoshape.coordinates, 2)).getBounds());
          onResolve();
        })
        .catch((error) => {
          console.error(`shape creation failed`);
          onReject();
        });
    });
  }, [createCustomPolygon, dispatch]);
  const shapeDeletionHandler = useCallback((uuid: string, map: L.Map) => {
    return new Promise<void>((onResolve, onReject) => {
      modal.confirm({
        title: capitalize(t('actions.delete')),
        content: capitalize(t('map.deleteGeoFilterConfirm')),
        icon: <WarningOutlined/>,
        onOk: () => {
          deletePolygon(uuid)
            .unwrap()
            .then(() => {
              dispatch(removeAssetsMapGeographicalFilter(uuid));
              dispatch(setPagination({current: 1}));
              setShapes(shapes => shapes.filter(shape => shape.uuid !== uuid));
              onResolve();
            })
            .catch((error) => {
              console.error(capitalize(t('form.deleteFailMessage')));
            });
        },
        onCancel: () => {
          onReject(new Error('operation cancelled'));
        },
      });
      
    });
    
  }, [deletePolygon, dispatch, modal, t]);
  const shapeEditHandler = useCallback((editedFeature: Feature<MultiPolygon, {}>, uuid: string, map: L.Map) => {
    return new Promise<void>((onResolve, onReject) => {
      deletePolygon(uuid)
        .unwrap()
        .then(() => {
          createCustomPolygon(editedFeature)
            .unwrap()
            .then((result) => {
              dispatch(setAssetsMapGeographicalFilters([
                ...polygonFilter.filter(filter => filter.uuid !== uuid),
                {
                  uuid: result.uuid,
                  globalpolygon: result.globalpolygon,
                }]));
              dispatch(setPagination({current: 1}));
              setShapes(shapes => [...shapes.filter(shape => shape.uuid !== uuid), {
                uuid: result.uuid,
                coordinates: editedFeature.geometry.coordinates,
              }]);
              map.fitBounds(new L.Polygon(L.GeoJSON.coordsToLatLngs(result.geoshape.coordinates, 2)).getBounds());
              onResolve();
            })
            .catch(error => {
              console.error('creazione non andata a buon fine');
              onReject();
            });
        })
        .catch((error) => {
          console.error(capitalize(t('form.deleteFailMessage')));
          onReject();
        });
      
    });
  }, [createCustomPolygon, deletePolygon, dispatch, polygonFilter, t]);
  
  const shapeCutHandler = useCallback((cutter: Feature<MultiPolygon, {}>, affectedGeometries: CutGeometries[], map: L.Map) => {
    return new Promise<void>((onResolve, onReject) => {
      modal.confirm({
        title: capitalize(t('map.cutOperation')),
        content: capitalize(t('map.cutGeoFilterConfirm')),
        icon: <WarningOutlined/>,
        onOk: () => {
          const uuids = affectedGeometries.map(geometry => geometry.uuid);
          const deletePromises = uuids.map(shapeUuid => deletePolygon(shapeUuid).unwrap());
          if (affectedGeometries.length) {
            Promise
              .all(deletePromises)
              .then(() => {
                const creationPromises = affectedGeometries
                  .filter(geometry => geometry.coordinates !== null)
                  // @ts-ignore
                  .map(geometry => createCustomPolygon(multiPolygon(geometry.coordinates)).unwrap());
                Promise
                  .all(creationPromises)
                  .then((responses) => {
                    const filteredFilters =
                      polygonFilterRef.current
                        .filter(shape => !affectedGeometries.map(geometry => geometry.uuid).includes(shape.uuid));
                    const newFilters =
                      responses.map(response => ({uuid: response.uuid, globalpolygon: response.globalpolygon}));
                    dispatch(setAssetsMapGeographicalFilters([...filteredFilters, ...newFilters]));
                    dispatch(setPagination({current: 1}));
                    const filteredShapes =
                      shapesRef.current
                        .filter(shape => !affectedGeometries.map(geometry => geometry.uuid).includes(shape.uuid));
                    const newShapes = responses.map(response => ({
                      uuid: response.uuid,
                      coordinates: response.geoshape.coordinates,
                    }));
                    setShapes([...filteredShapes, ...newShapes]);
                    const bBoxes = responses.map(response => L.polygon(flipTurfFeatureCoordinates(multiPolygon(response.geoshape.coordinates)).geometry.coordinates as L.LatLngExpression[][][]).getBounds());
                    if (bBoxes.length) {
                      const completeBBoxes = bBoxes.reduce((acc, curr) => acc.extend(curr));
                      map.fitBounds(completeBBoxes);
                    }
                    onResolve();
                  })
                  .catch((error) => {
                    console.error('errore eliminazione');
                    onReject(error);
                  });
              });
          } else {
            onResolve();
          }
        },
        onCancel: () => {
          onReject(new Error('operation cancelled'));
        },
      });
    });
  }, [createCustomPolygon, deletePolygon, dispatch, modal, t]);
  const sendToBackHandler = useCallback((uuid: string) => {
    setShapes(shapes => {
      const shapeToMove = shapes.find(shape => shape.uuid === uuid);
      const filteredShapes = shapes.filter(shape => shape.uuid !== uuid);
      if (shapeToMove) {
        return [shapeToMove, ...filteredShapes];
      } else return shapes;
    });
  }, []);
  const bringToFrontHandler = useCallback((uuid: string) => {
    setShapes(shapes => {
      const shapeToMove = shapes.find(shape => shape.uuid === uuid);
      const filteredShapes = shapes.filter(shape => shape.uuid !== uuid);
      if (shapeToMove) {
        return [...filteredShapes, shapeToMove];
      } else return shapes;
    });
  }, []);
  const resetAssetMapFilters = useCallback(() => {
    const promises = shapes.map(shape => deletePolygon(shape.uuid).unwrap());
    Promise
      .all(promises)
      .then(() => {
        console.log('deleted all polygon filters');
        return;
      })
      .catch(() => {
        console.error(capitalize(t('form.deleteFailMessage')));
      })
      .finally(() => {
        setShapes([]);
      });
  }, [deletePolygon, shapes, t]);
  
  useEffect(() => {
    switch (currentAction) {
      case ASSET_MAP_ACTIONS.RESET_ASSET_MAP_FILTERS:
        resetAssetMapFilters();
        changeAction(ASSET_MAP_ACTIONS.PAN);
        break;
      case ASSET_MAP_ACTIONS.ADD_GEOMETRY_FROM_LAYER_QUERY:
        shapeCreationHandler(multiPolygon(actionEvent.geometry), actionEvent.map);
        changeAction(ASSET_MAP_ACTIONS.PAN);
        break;
      default:
        break;
    }
  }, [actionEvent, changeAction, currentAction, resetAssetMapFilters, shapeCreationHandler]);
  
  return (
    <>
      {contextHolder}
      <NewDrawControls
        onComponentMount={fetchGlobalPolygonHandler}
        drawnGeometries={shapes}
        onAfterShapeCreation={shapeCreationHandler}
        onPolygonsDelete={shapeDeletionHandler}
        onAfterShapeCut={shapeCutHandler}
        onSendToBack={sendToBackHandler}
        onBringToFront={bringToFrontHandler}
        onAfterShapeEdit={shapeEditHandler}
        canEditShapes={true}
      />
    </>
  );
};

export default AssetsMapDrawControlWrapper;