import {BaseMapContainer} from '../common/map/BaseMapContainer';
import {useBaseWMSLayerContext} from '../../context/baseMap/BaseWMSLayerContext';
import {BaseWMSLayer} from '../common/map/BaseWMSLayer';
import {GoogleLayers} from '../../types/internal/Map';
import EventMarkers from './filters/EventMarker';
import MapLoading from '../common/map/MapLoading';
import {useEventsContext} from '../../context/emergenciesMap/EventsContext';
import EventContext, {useEventContext} from '../../context/event/EventContext';
import EventPolygons from '../event/map/EventPolygons';
import React, {useEffect} from 'react';
import EventAssetMarkers from '../event/map/EventAssetMarkers';
import {useEventCenterMap} from '../../customHooks/useCenterMap';
import {useMapLoadingContext} from '../../context/baseMap/MapLoadingContext';
import {CHANGES} from '../../types/api/getEventsResult';

const SelectedEventMap = () => {
  
  const [loading] = useMapLoadingContext();
  useEventCenterMap(loading);
  
  const {setPolygonsAndAssetsForTheMap} = useEventContext();
  const [, setIsLoading] = useMapLoadingContext();
  
  useEffect(() => {
    setIsLoading(true);
    setPolygonsAndAssetsForTheMap(CHANGES.WITHOUT)
      .then()
      .catch()
      .finally(() => setIsLoading(false));
  }, [setIsLoading, setPolygonsAndAssetsForTheMap]);
  
  return <>
    <EventPolygons/>
    <EventAssetMarkers type={'onlyEvent'}/>
  </>;
};

export const EventsBaseMap = () => {
  
  const [
    layers,
    currentWMSLayer,
  ] = useBaseWMSLayerContext();
  const {eventsInMap} = useEventsContext();
  
  const WMSLayer = layers?.find(layer => layer.uuid === currentWMSLayer);
  
  return (
    <BaseMapContainer
      withLayerPicker={true}
      withBaseLayers={[GoogleLayers.roadmap, GoogleLayers.hybrid]}
      withPopoverConfiguration={{overlayInnerStyle: {width: 320}}}
      withWMSLayers={layers || []}
      withSoftLoading={true}
      withZoom={true}
      withGeoSearch={true}
      withStyle={{borderRadius: 0, height: '100%'}}
      withLegend={true}
      withQuerableLayers={true}
      isStandAlone={true}
    >
      <MapLoading/>
      {!!WMSLayer && (
        <BaseWMSLayer
          url={WMSLayer.wms_baseurl}
          layers={WMSLayer.name}
          isInternal={WMSLayer.is_internal || undefined}
          key={WMSLayer.uuid}
        />
      )}
      <EventMarkers/>
      {eventsInMap.map(el => (
        <EventContext
          key={el}
          eventUuid={el}
        >
          <SelectedEventMap/>
        </EventContext>
      ))}
    </BaseMapContainer>
  );
};