import StandardFilter from '../../../common/filters/StandardFilter';
import {OptionLabelValue} from '../../../../types/internal/OptionLabelValue';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';

export default function IsForecastFilter({activeFilters, handleFilterChange}: {
  activeFilters?: OptionLabelValue[],
  handleFilterChange: (values: OptionLabelValue[]) => void,
}) {
  const {t} = useTranslation();

  const OPTIONS: OptionLabelValue[] = [
    {label: capitalize(t('alerts.isForecast')), value: 'true'},
    {label: capitalize(t('alerts.isNotForecast')), value: 'false'},
  ];

  return <StandardFilter
    selectedFilters={activeFilters || undefined}
    filters={OPTIONS}
    onChange={handleFilterChange}
  />;
}
