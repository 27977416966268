import {GetEventHistory} from "../../../types/api/getEventsResult";
import {useGetUserProfileQuery} from "../../../redux/api/locales";
import {Col, Divider, Row, Typography} from "antd";
import {dateToLocaleString} from "../../../utils/dateParsers";
import React from "react";
import MovingHistoryItemDrawer from "./MovingHistoryItemDrawer";
import ExpandableClusterMovingGroup from "./ExpandableClusterMovingGroup";
import {useTranslation} from "react-i18next";
import {capitalize} from "lodash";
import {useEventContext} from "../../../context/event/EventContext";
import {AssetContext} from "../../../context/assetDetails/AssetContext";
import {AssetDrawerContext} from "../../../context/alertDetails/AssetDrawerContext";

export default function EventHistoryTimelineItem({
                                                   uuid,
                                                   operation_date,
                                                   user_edit,
                                                   changes,
                                                   configuration
                                                 }: GetEventHistory) {

  const {
    data: userData,
  } = useGetUserProfileQuery();
  const {t} = useTranslation()
  const {event} = useEventContext()

  return (
    <>
      <Row align={'top'} justify={'space-between'}>
        <Col xs={24} sm={3}>
          {operation_date && userData?.language ? dateToLocaleString(operation_date, userData.language, 'HH:mm') : 'n/a'}
        </Col>
        <Col xs={24} sm={3}>
          {capitalize(t('user.user', {count: 1}))}:
          <div>{user_edit}</div>
        </Col>
        <Col xs={24} sm={5}>
          {capitalize(t('events.change', {count: 2}))}:
          {!!changes.asset_state && <div>
            <Typography.Text strong>{changes.asset_state} </Typography.Text>
            <Typography.Text>{capitalize(t('events.changeAssetsState', {count: changes.asset_state}))}</Typography.Text>
          </div>}
          {!!changes.assets && <div>
            <Typography.Text strong>{changes.assets} </Typography.Text>
            <Typography.Text>{capitalize(t('events.changeAssetsCluster', {count: changes.assets}))}</Typography.Text>
          </div>}
          {!!changes.clusters && <div>
            <Typography.Text strong>{changes.clusters} </Typography.Text>
            <Typography.Text>{capitalize(t('events.changeCluster', {count: changes.clusters}))}</Typography.Text></div>}
        </Col>
        <Col xs={24} sm={12}>
          {capitalize(t('events.configuration'))}:
          <div>
            {configuration.assets_count} {t('assets.asset', {count: configuration.assets_count})}, {configuration.clusters_count} {t('clusters.cluster', {count: configuration.clusters_count})}
            <ExpandableClusterMovingGroup detail={configuration.detail}/>
          </div>
        </Col>
        <Col xs={24} sm={1}>
          {!!event && <AssetDrawerContext>
            <AssetContext>
              <MovingHistoryItemDrawer historyUuid={uuid}/>
            </AssetContext>
          </AssetDrawerContext>
          }
        </Col>
      </Row>
      <Divider/>
    </>
  );
}