import {DeleteOutlined} from '@ant-design/icons';
import {Button} from 'antd';
import React, {useState} from 'react';
import {capitalize} from 'lodash';
import {Trans, useTranslation} from 'react-i18next';
import DeleteModalMessage from '../../common/documents/modals/DeleteModalMessage';
import {useDeleteTemplateEmailMutation} from '../../../redux/api/templateEmails';
import {useMessageContext} from '../../../context/message/MessageContext';
import {EmailTemplate} from '../../../types/api/templateEmails';

export const DeleteTemplate = ({template}: { template: EmailTemplate }) => {

  const {t} = useTranslation();

  const setMessage = useMessageContext();

  const [
    isModalOpen,
    setIsModalOpen,
  ] = useState(false);

  const [
    deleteTemplate,
  ] = useDeleteTemplateEmailMutation();

  return (<>
    <DeleteModalMessage
      title={{children: capitalize(t('actions.remove'))}}
      paragraph={{
        children: <Trans
          i18nKey={'mailTemplate.delete'}
          values={{item: template.title}}
        />,
      }}
      modal={{
        open: isModalOpen,
        onCancel: () => setIsModalOpen(false),
      }}
      confirmButton={{
        onClick: () => {
          deleteTemplate({templateUuid: template.uuid}).then(
            () => {
              setMessage({success: capitalize(t('form.deleteSuccessMessage'))});
            },
            (err) => {
              setMessage({error: capitalize(t('form.deleteFailMessage'))});
            });
          setIsModalOpen(false);
        },
      }
      }
    />
    <Button
      icon={<DeleteOutlined/>}
      type={'text'}
      onClick={() => setIsModalOpen(true)}
    />
  </>);
};