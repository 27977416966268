import {Card, Col, Row, Typography} from 'antd';
import {capitalize} from 'lodash';
import {UploadDocumentModal} from '../../common/documents/modals/UploadDocumentModal';
import DocumentsList from '../../common/documents/DocumentsList';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {
  useAddDocumentToAlertMutation,
  useLazyGetAlertDocumentsQuery,
  useRemoveDocumentToAlertMutation,
} from '../../../redux/api/alerts';
import {useGetUserProfileQuery} from '../../../redux/api/locales';
import PermissionRoute from '../../Authentication/PermissionRoute';
import {
  isAlertDocumentCreation,
  isAlertDocumentDelete,
  isAlertDocumentEdit,
  isAlertDocumentView,
} from '../../Authentication/permissions';

export default function AlertDocumentsForm() {

  const {t} = useTranslation();
  const {uuid} = useParams();

  const [getData, {data, isFetching, isUninitialized}] = useLazyGetAlertDocumentsQuery();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [addDocument] = useAddDocumentToAlertMutation();
  const [removeDocument] = useRemoveDocumentToAlertMutation();

  const {
    data: userData,
  } = useGetUserProfileQuery();

  const isEditing = userData?.type ? isAlertDocumentEdit(userData?.type) : false;
  const isRemoving = userData?.type ? isAlertDocumentDelete(userData?.type) : false;
  const isDownload = userData?.type ? isAlertDocumentView(userData?.type) : false;

  const handleRemove = (documentUuid: string) => {
    if (uuid) {
      return removeDocument({alertUuid: uuid, documentUuid: documentUuid}).unwrap();
    } else {
      return Promise.reject();
    }
  };

  useEffect(() => {
    if (uuid) {
      getData({uuid});
    }
  }, [getData, uuid]);

  return <Card>
    <Row justify={'space-between'}>
      <Col>
        <Typography.Title
          level={4}
          style={{margin: 0, marginBottom: '1rem'}}
        >
          {capitalize(t('documents.document_other', {count: 2}))}
        </Typography.Title>
      </Col>
      <PermissionRoute
        isAuthorized={isAlertDocumentCreation}
      >
        <Col>
          <UploadDocumentModal
            onAfterUpload={(uploadedDocumentUuid) => {
              if (uuid) {
                addDocument({
                  alertUuid: uuid,
                  documentUuid: uploadedDocumentUuid,
                });
              }
            }}
          />
        </Col>
      </PermissionRoute>
    </Row>
    <DocumentsList
      documents={data}
      total={data?.length}
      page={page}
      pageSize={pageSize}
      setPage={setPage}
      setPageSize={setPageSize}
      loading={isFetching || isUninitialized}
      {...isRemoving && {onRemove: handleRemove}}
      withEdit={isEditing}
      withDownload={isDownload}
    />
  </Card>;
}