import NewDrawControls, {CutGeometries} from '../../../common/map/controls/newDrawControls/NewDrawControls';
import {useTranslation} from 'react-i18next';
import {useEventContext} from '../../../../context/event/EventContext';
import {useCallback, useEffect, useRef, useState} from 'react';
import {Feature, MultiPolygon} from '@turf/helpers';
import L from 'leaflet';
import {capitalize} from 'lodash';
import chroma from 'chroma-js';
import {EventContextType} from "../../../../context/event/EventContextTypes";


export default function EventEditMapRecapDrawControlsWrapper() {

  const {t} = useTranslation();
  const [isCutMode, setIsCutMode] = useState(false)

  const context = useEventContext();
  const {
    getClusterFromUuid,
    getEventPolygonFromGlobalPolygonUuid,
    onChangePolygonOrder,
    globalPolygons,
    onRemovePolygon,
    onEditPolygon,
  } = context


  const contextRef = useRef<EventContextType>();

  useEffect(() => {
    contextRef.current = context;
  }, [context]);

  const shapeDeletionHandler = useCallback((uuid: string, map: L.Map) => {
    return new Promise<void>((onResolve, onReject) => {
      onRemovePolygon(uuid)
        .then(() => {
          onResolve();
        })
        .catch((error) => {
          console.error(capitalize(t('form.deleteFailMessage')));
          onReject(error);
        });
    });
  }, [onRemovePolygon, t]);

  const shapeCutHandler = useCallback((cutterGeometry: Feature<MultiPolygon, {}>, affectedGeometries: CutGeometries[], map: L.Map) => {
    return new Promise<void>((onResolve, onReject) => {
      if (contextRef.current?.onCutPolygons) {
        contextRef.current?.onCutPolygons({
          cutterGeometry,
          affectedGeometries: affectedGeometries,
          involvedClusters: 'all'
        })
          .then(() => {
            onResolve();
          })
          .catch((error) => {
            console.error(capitalize(t('form.deleteFailMessage')));
            onReject(error);
          })
          .finally(() => setIsCutMode(false))
      } else {
        onReject()
      }
    });
  }, [t]);

  const shapeEditHandler = useCallback((editedFeature: Feature<MultiPolygon, {}>, uuid: string, map: L.Map) => {
    return new Promise<void>((onResolve, onReject) => {
      onEditPolygon(editedFeature, uuid)
        .then(() => onResolve())
        .catch(() => onReject())
    });
  }, [onEditPolygon]);

  const drawnGeometriesWithPathOptions = globalPolygons.map(el => {

    const eventPolygon = getEventPolygonFromGlobalPolygonUuid(el.properties.uuid);
    const cluster = eventPolygon ? getClusterFromUuid(eventPolygon?.cluster) : null;

    return {
      coordinates: el.geometry.coordinates,
      uuid: el.properties.uuid,
      pathOptions: cluster?.color_code ?
        {color: chroma(cluster?.color_code || 'blue').alpha(0.7).hex()} :
        {},
    };
  });

  return <NewDrawControls
    drawsPolygons={isCutMode}
    drawsCircles={isCutMode}
    drawsCirclesWithCompass={isCutMode}
    drawsOffsettableLines={isCutMode}
    drawnGeometries={drawnGeometriesWithPathOptions}
    onPolygonsDelete={shapeDeletionHandler}
    onAfterCutButtonClick={() => setIsCutMode(prevState => !prevState)}
    onAfterShapeCut={shapeCutHandler}
    onSendToBack={(polygonUuid) => onChangePolygonOrder(polygonUuid, 'back')}
    onBringToFront={(polygonUuid) => onChangePolygonOrder(polygonUuid, 'front')}
    canEditShapes
    onAfterShapeEdit={shapeEditHandler}
  />;
}