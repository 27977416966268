import L from 'leaflet';
import {Feature, MultiPolygon} from '@turf/helpers';
import {Polygon as PolygonHelper} from '@turf/helpers/dist/js/lib/geojson';

class CustomLeafletCircleLayer extends L.Circle {
  private uuid: string;
  private feature_index: number;
  
  constructor(latlng: L.LatLngExpression, options: L.CircleOptions, uuid: string, feature_index: number) {
    super(latlng, options);
    this.uuid = uuid;
    this.feature_index = feature_index;
  }
  
  getProperties(): { uuid: string, feature_index: number, geometry: Feature<PolygonHelper | MultiPolygon, any> } {
    return {uuid: this.uuid, feature_index: this.feature_index, geometry: super.toGeoJSON(6)};
  }
  
}

export default CustomLeafletCircleLayer;