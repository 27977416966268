import {Select, SelectProps} from 'antd';
import {useGetEventTypesNotPaginatedQuery} from '../../../redux/api/eventTypes';

export default function SelectEventTypes({value, ...select}: SelectProps) {

  const {data, isFetching} = useGetEventTypesNotPaginatedQuery();

  const options = data?.map(eventType => ({label: eventType.name, value: eventType.uuid}));

  return <Select
    options={options}
    loading={isFetching}
    value={options ? value : undefined}
    {...select}
  />
}