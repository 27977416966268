import {Button, Card, Col, Pagination, Row, Table, Tag} from 'antd';
import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';
import {useGetAssetEventsQuery} from "../../../redux/api/asset";
import {useAssetDetails} from "../../../context/assetDetails/AssetDetails";
import {skipToken} from "@reduxjs/toolkit/query";
import {PaginationConfig} from "antd/lib/pagination";
import {GetEventsResult} from "../../../types/api/getEventsResult";
import EventDetailDrawer from "../../events/activeEventsList/EventDetailDrawer";
import DateTimeFormat from "../../../locales/DateTimeFormat";
import {useGetEventTypesNotPaginatedQuery} from "../../../redux/api/eventTypes";
import {ColumnsType} from "antd/lib/table";
import {RoundActionButton} from "../../event/eventMovingHistory/MovingHistoryItemDrawer";
import EventAssetNotesModal from "./EventAssetNotesModal";

export const AssetEventsHistoryCard = () => {

  const {t} = useTranslation();
  const [_, {asset}] = useAssetDetails()
  const [pagination, setPagination] = useState<PaginationConfig>({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    showTotal: (total, range) => t('table.pageSizeOfTotal', {
      rangeStart: range[0],
      rangeEnd: range[1],
      total,
    }),
    onChange: (page, pageSize) => setPagination(prevPag => ({...prevPag, current: page, pageSize})),
    style: {textAlign: 'right'},
  });

  const {
    data: events,
    isFetching: isFetchingAssetEvents,
    isUninitialized: isUninitializedAssetEvents
  } = useGetAssetEventsQuery(asset.uuid ? {
      assetUuid: asset.uuid,
      page: pagination.current,
      page_size: pagination.pageSize,
    }
    : skipToken)
  const {data: eventTypes, isFetching: isFetchingEventTypes} = useGetEventTypesNotPaginatedQuery();
  const [eventDrawer, setEventDrawer] = useState<GetEventsResult | null>(null);

  const columns: ColumnsType<GetEventsResult> = useMemo(() => [
    {
      title: capitalize(t('events.code', {count: 1})),
      dataIndex: 'code',
      key: 'code',
      render: v => `#${v}`
    },
    {
      title: capitalize(t('events.name', {count: 1})),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: capitalize(t('events.startDateEvent')),
      dataIndex: 'start_datetime',
      key: 'start_datetime',
      render: value => <DateTimeFormat>{value}</DateTimeFormat>,
    },
    {
      title: capitalize(t('events.eventType', {count: 1})),
      dataIndex: 'event_type',
      key: 'event_type',
      render: (value) => {
        const eventType = eventTypes?.find(ev => ev.uuid === value);
        if (eventType) {
          return <Tag>{eventType.name}</Tag>;
        }
      },
    },
    {
      title: capitalize(t('documents.notes')),
      dataIndex: 'uuid',
      key: 'notes',
      render: value => {
        return <EventAssetNotesModal assetUuid={asset.uuid} eventUuid={value}/>
      },
    },
    {
      dataIndex: 'uuid',
      key: 'uuid',
      render: (_, record) => (
        <RoundActionButton
          onClick={() => setEventDrawer(record)}
        />
      ),
      width: 64,
    },
  ], [eventTypes, t])

  useEffect(() => {
    return () => {
      setEventDrawer(null);
    };
  }, []);


  return (
    <>
      {!!eventDrawer && <EventDetailDrawer
        event={eventDrawer}
        drawer={{
          open: !!eventDrawer,
          onClose: () => setEventDrawer(null),
        }}
      />}
      <Card title={capitalize(t('events.historyEvent'))}>
        <Table
          <GetEventsResult>
          loading={isFetchingAssetEvents || isUninitializedAssetEvents || isFetchingEventTypes}
          dataSource={events?.results}
          rowKey={'uuid'}
          columns={columns}
          bordered={false}
          scroll={{x: 576}}
          pagination={false}
        />
        <Row
          justify={'center'}
          style={{marginTop: '1rem'}}
        >
          <Col>
            <Pagination
              total={events?.count}
              {...pagination}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};