import {Form, Space} from 'antd';
import {capitalize} from 'lodash';
import CustomLatitudeNumericFormat from '../../../../customNumeric/CustomLatitudeNumericFormat';
import CustomLongitudeNumericFormat from '../../../../customNumeric/CustomLongitudeNumericFormat';
import CustomNumericFormat from '../../../../customNumeric/CustomNumericFormat';
import React from 'react';
import {useTranslation} from 'react-i18next';

const DrawCircleWithCompassFormItems = () => {
  
  const {t} = useTranslation();
  
  return (
    <>
      <Space
        direction={'horizontal'}
        size={'small'}
      >
        <Form.Item
          name={'latitude'}
          label={capitalize(t('address.latitude'))}
          rules={[
            {required: true, message: capitalize(t('map.latitudeRequired'))},
            ({getFieldValue}) => ({
              validator(_, value) {
                if (!value || (value >= -90 && value <= 90)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(capitalize(t('map.invalidLatitude'))));
              },
            }),
          ]}
          trigger={'onValueChange'}
          getValueFromEvent={e => e.floatValue}
        >
          <CustomLatitudeNumericFormat/>
        </Form.Item>
        <Form.Item
          name={'longitude'}
          label={capitalize(t('address.longitude'))}
          rules={[
            {required: true, message: capitalize(t('map.longitudeRequired'))},
            ({getFieldValue}) => ({
              validator(_, value) {
                if (!value || (value >= -90 && value <= 90)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(capitalize(t('map.invalidLongitude'))));
              },
            }),
          ]}
          trigger={'onValueChange'}
          getValueFromEvent={e => e.floatValue}
        >
          <CustomLongitudeNumericFormat/>
        </Form.Item>
      </Space>
      <Form.Item
        name={'radius'}
        label={`${capitalize(t('map.radius'))} (m)`}
        rules={[
          {required: true, message: capitalize(t('map.radiusRequired'))},
          ({getFieldValue}) => ({
            validator(_, value) {
              if (!value || value > 0) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(`${capitalize(t('map.radiusInvalid'))}`));
            },
          }),
        ]}
        trigger={'onValueChange'}
        getValueFromEvent={e => e.floatValue}
      >
        <CustomNumericFormat/>
      </Form.Item>
    </>
  );
};
export default DrawCircleWithCompassFormItems;