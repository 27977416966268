import ResetFiltersSortersButton from '../../common/filters/ResetFiltersSortersButton';
import {useDispatch, useSelector} from 'react-redux';
import {resetFiltersSorters, selectAssetsTableFilters, selectAssetsTableSorters} from '../../../redux/slices/assets';
import {resetAssetsMapGeographicalFilter, selectMapFilters} from '../../../redux/slices/mapFilters';
import {useControlsAction} from '../../../context/baseMap/ControlContext';
import {ASSET_MAP_ACTIONS} from '../../common/map/constants/map';
import _ from 'lodash';

export default function ResetFiltersSortersAssetsTable() {

  const dispatch = useDispatch();

  const {
    changeAction,
  } = useControlsAction();

  const filters = useSelector(selectAssetsTableFilters);
  const polygonFilter = useSelector(selectMapFilters).polygons;
  const sorters = useSelector(selectAssetsTableSorters);
  const handleConfirm = () => {
    dispatch(resetFiltersSorters());
    changeAction(ASSET_MAP_ACTIONS.RESET_ASSET_MAP_FILTERS);
    dispatch(resetAssetsMapGeographicalFilter());
  };

  const isDisabled = _.isEmpty(filters) && _.isEmpty(sorters) && !polygonFilter.length;

  return <ResetFiltersSortersButton
    isDisabled={isDisabled}
    onConfirm={handleConfirm}
  />;
}