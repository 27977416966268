import React, {createContext, PropsWithChildren, useContext, useState} from 'react';
import {AssetDetailsContextType, BuildingDetails} from '../../types/internal/AddAssetForm';
import dayjs from 'dayjs';

const DEFAULT_ASSET_DETAILS = {
  sigi_code: null,
  building_type: null,
  building_type_detail: null,
  surface_total: null,
  surface_basement: null,
  opening_date: dayjs(),
  closing_date: null,
  ownership: null,
  companies: [],
  total_resources: null,
  master_asset: null,
};

const DetailsContext = createContext<AssetDetailsContextType>(
  [
    DEFAULT_ASSET_DETAILS,
    () => null,
  ]);

export const useAssetDetailsContext = () => useContext(DetailsContext);

export const AssetDetailsContext = (props: PropsWithChildren) => {
  
  const [
    details,
    setDetails,
  ] = useState<BuildingDetails>(DEFAULT_ASSET_DETAILS);
  
  return (
    <DetailsContext.Provider value={[details, setDetails]}>
      {props.children}
    </DetailsContext.Provider>
  );
};