/*Handmade translations to keep them working offline*/
import {notification} from 'antd';
import {useCallback, useEffect} from 'react';
import {ConnectionStatus, useConnectionCheck} from '../../customHooks/useConntectionCheck';

const online_notification_description = {
  it: 'Connessione nuovamente disponibile.',
  en: 'Connection restored.',
};

const offline_notification_description = {
  it: 'Connessione non disponibile. Si prega di controllare la connessione e riprovare.',
  en: 'Connection not available. Please check your connection and try again.',
};

const connection_notification_message = {
  it: 'Stato connessione:',
  en: 'Connection status:',
};

export const ConnectionStatusNotifier = () => {
  const status = useConnectionCheck();

  const [api, contextHolder] = notification.useNotification();

  const connectionIsBack = useCallback(() => {
    api.destroy('connectionIsLost');
    api.success({
      message: connection_notification_message['it'],
      description: online_notification_description['it'],
      key: 'connectionIsBack',
      duration: 4.5,
    });
  }, [api]);

  const connectionLost = useCallback(() => {
    api.destroy('connectionIsBack');
    api.warning({
      message: connection_notification_message['it'],
      description: offline_notification_description['it'],
      duration: 4.5,
      key: 'connectionIsLost',
    });
  }, [api]);

  useEffect(() => {
    if (status === ConnectionStatus.wentOnline) {
      connectionIsBack();
    }
    if (status === ConnectionStatus.wentOffline) {
      connectionLost();
    }
  }, [api, connectionIsBack, connectionLost, status]);

  return (
    <>
      {contextHolder}
    </>
  );
};