import L, {LatLng} from 'leaflet';

export const INITIAL_CENTER: LatLng = L.latLng([41.9027835, 12.4963655]);
export const INITIAL_ZOOM = 6;
export const MIN_ZOOM = 3;
export const MAX_ZOOM = 21;

/*This constant exposes all the actions that the map in the drawer shown in assets/ must be able to handle.*/
export enum ASSET_MAP_ACTIONS {
  PAN,//this action is used as default/nullish value to clear the assetsMap from previous actions
  OPEN_MAP_DRAWER, //this action opens the assetsMap drawer
  CLOSE_MAP_DRAWER, //this action closes the assetsMap drawer
  SET_MAP_VIEW, //this action only sets the assetsMap view to the specified coordinates
  SELECT_MARKER, //this action sets the assetsMap view to the specified marker location, and then opens the location popup
  SEARCH_LOCATION, //this action sets the map view in the given position
  RESET_ASSET_MAP_FILTERS, //this action notifies that polygon filters have been resetted
  ADD_GEOMETRY_FROM_LAYER_QUERY,
  SELECT_GLOBAL_POLYGON,
}

export enum DRAW_ACTIONS {
  NULL,
  DRAW_POLYGON,
  DRAW_CIRCLE,
  DELETE_SHAPE,
  CUT_SHAPE,
}