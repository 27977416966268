import {Button, Popconfirm} from 'antd';
import {TableOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';

export default function ResetFiltersSortersButton({isDisabled, onConfirm}: {
  onConfirm: () => void
  isDisabled: boolean,
}) {

  const {t} = useTranslation();

  return <Popconfirm
    title={capitalize(t('table.resetFilterSorters'))}
    placement={'topRight'}
    onConfirm={onConfirm}
  >
    <Button
      size={'large'}
      icon={<TableOutlined/>}
      disabled={isDisabled}
      type={'dashed'}
    />
  </Popconfirm>;
}