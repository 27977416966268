import {Button, Col, ColorPicker, Form, Input, Modal, Row} from 'antd';
import {capitalize} from 'lodash';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import AntFormItem from '../../common/AntFormItem';
import {ClusterEditParams} from '../../../types/api/clusters';
import {useForm} from 'antd/lib/form/Form';
import {useEditClusterTemplateMutation, useLazyGetClusterTranslationQuery} from '../../../redux/api/clusters';
import {useMessageContext} from '../../../context/message/MessageContext';
import {useParams} from 'react-router-dom';
import {EditOutlined} from '@ant-design/icons';
import {ClusterForm} from '../../../types/internal/clusters';
import {hexString} from '../../../utils/colors';

export default function EditCluster({clusterUuid}: { clusterUuid: string }) {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const {t} = useTranslation();
  const [form] = useForm();
  const [editCluster, {isSuccess, isLoading: isUpdating, isError}] = useEditClusterTemplateMutation();
  const setMessage = useMessageContext();
  const {uuid: eventUuid} = useParams();

  const [getClusterTranslations, {
    data,
    isFetching,
    isUninitialized,
    isError: isFetchingDataError,
  }] = useLazyGetClusterTranslationQuery();
  const isDisabled = isFetching || isUninitialized || isFetchingDataError;

  useEffect(() => {
    if (eventUuid) {
      getClusterTranslations({uuid: clusterUuid, eventUuid});
    }
  }, [clusterUuid, eventUuid, getClusterTranslations]);

  const handleFinish = (values: ClusterForm) => {
    if (eventUuid) {
      const parsedValues: ClusterEditParams = {
        ...values,
        color_code: hexString(values.color_code),
        eventUuid,
        uuid: clusterUuid,
      };
      editCluster(parsedValues);
    }
  };

  useEffect(() => {
    if (isFetchingDataError) {
      setMessage({error: capitalize(t('form.fetchingFailMessage'))});
    }
  }, [isFetchingDataError, setMessage, t]);

  useEffect(() => {
    if (isSuccess) {
      setMessage({success: capitalize(t('form.editSuccessMessage'))});
      setIsModalOpen(false);
    }
  }, [isSuccess, setMessage, t]);

  useEffect(() => {
    if (isError) {
      setMessage({error: capitalize(t('form.editFailMessage'))});
    }
  }, [isError, setMessage, t]);

  return <>
    <Button
      icon={<EditOutlined/>}
      type={'text'}
      size={'large'}
      onClick={() => setIsModalOpen(true)}
    />
    <Modal
      open={isModalOpen}
      footer={<Row justify={'end'}>
        <Col>
          <Form
            <ClusterForm>
            form={form}
            onFinish={handleFinish}
          >
            <Button
              htmlType={'submit'}
              type={'primary'}
              disabled={isDisabled || isUpdating}
              loading={isUpdating}
            >
              {capitalize(t('actions.saveChanges'))}
            </Button>
          </Form>
        </Col>
      </Row>}
      onCancel={() => setIsModalOpen(false)}
      title={capitalize(t('actions.editItem', {item: t('clusters.cluster', {count: 1})}))}
      styles={{
        header: {
          padding: '0.875rem 0',
          margin: 0,
          borderBottom: '1px solid #D9D9D9',
        },
        body: {
          padding: '1rem 0',
        },
      }}
      closeIcon={false}
    >
      <Form
        <ClusterForm>
        form={form}
        onFinish={handleFinish}
        initialValues={data}
        layout={'vertical'}
      >
        <AntFormItem
          name={'name_it'}
          label={capitalize(t('clusters.name_it'))}
          rules={[
            {
              required: true,
              validator: (_, value) => {
                if (!value || value.trim() === '') {
                  return Promise.reject();
                }
                return Promise.resolve();
              },
            }]}
        >
          <Input/>
        </AntFormItem>
        <AntFormItem
          name={'name_en'}
          label={capitalize(t('clusters.name_en'))}
          rules={[
            {
              required: true,
              validator: (_, value) => {
                if (!value || value.trim() === '') {
                  return Promise.reject();
                }
                return Promise.resolve();
              },
            }]}
        >
          <Input/>

        </AntFormItem>
        <AntFormItem
          name={'description_it'}
          label={capitalize(t('clusters.description_it'))}
        >
          <Input.TextArea/>
        </AntFormItem>
        <AntFormItem
          name={'description_en'}
          label={capitalize(t('clusters.description_en'))}
        >
          <Input.TextArea/>
        </AntFormItem>
        <AntFormItem
          name={'color_code'}
          label={capitalize(t('clusters.color'))}
          rules={[{required: true}]}
        >
          <ColorPicker
            size={'large'}
            showText
            disabledAlpha
            format={'hex'}
            onChange={v => v.toHexString()}
          />
        </AntFormItem>
      </Form>
    </Modal>
  </>;
}