import {CSSProperties} from 'react';

export default function PreviewEventTypeIconSmall({src, style, selected, onClick}: {
  src?: string,
  style?: CSSProperties,
  selected?: boolean,
  onClick?: () => void
}) {
  return <img
    onClick={onClick}
    src={src}
    alt={''}
    style={{
      width: '32px',
      height: '32px',
      padding: '10px',
      borderRadius: '50%',
      border: selected ? '3px solid var(--primary-6, #1890FF)' : '1px solid var(--neutral-5, #D9D9D9)',
      background: selected ? 'var(--primary-1, #E6F7FF)' : '',
      overflow: 'visible',
      ...style,
    }}
  />;
}