import {Button, ButtonProps} from 'antd';
import {DeleteOutlined} from '@ant-design/icons';
import {capitalize} from 'lodash';
import React from 'react';
import {useTranslation} from 'react-i18next';

export default function RemoveButton(button?: ButtonProps) {

  const {t} = useTranslation();

  return <Button
    icon={<DeleteOutlined/>}
    danger
    children={capitalize(t('actions.remove'))}
    {...button}
  />
}