import ResponsiveContent from '../../../layout/ResponsiveContent';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {Typography} from 'antd';
import {capitalize} from 'lodash';
import {OptionLabelValue} from '../../../types/internal/OptionLabelValue';
import AlertStepsCreation from "../alertSteps/AlertStepsCreation";
import {AlertStepsContext} from "../../../context/alertSteps/AlertStepsContext";

const {Title} = Typography;

export function CardTitle({text}: { text: string }) {
  return (
    <Title
      level={4}
      style={{marginBottom: '1rem', marginTop: 0}}
    >
      {capitalize(text)}
    </Title>
  );
}

export type OptionPolygon = OptionLabelValue & { globalpolygon: string }

export default function AlertCreation() {

  const {t} = useTranslation();
  const navigate = useNavigate();

  return <ResponsiveContent
      title={t('actions.createItem', {item: t('alerts.alert')})}
      goBack={() => navigate('/alerts')}
      goBackText={t('alerts.list')}
      containerStyle={{gap: '0.75rem'}}
    >
      <AlertStepsContext>
        <AlertStepsCreation />
      </AlertStepsContext>
    </ResponsiveContent>;
}