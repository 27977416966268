import {createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState} from 'react';

export type StreetViewContextType = [
  isInStreetViewMode: boolean,
  setIsInStreetViewMode: Dispatch<SetStateAction<boolean>>
]

const StreetView = createContext<StreetViewContextType>([
    false,
    () => null,
  ],
);

export const useStreetViewContext = () => useContext(StreetView);

export const StreetViewContext = (props: PropsWithChildren) => {
  const [
    isInStreetViewMode,
    setIsInStreetViewMode,
  ] = useState(false);

  return (
    <StreetView.Provider value={[isInStreetViewMode, setIsInStreetViewMode]}>
      {props.children}
    </StreetView.Provider>
  );
};