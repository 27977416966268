import {OptionLabelValue} from '../../../../types/internal/OptionLabelValue';
import DEPRECATEDListWithSearchFilter from '../../../common/filters/DEPRECATEDListWithSearchFilter';
import React, {useCallback, useEffect, useMemo} from 'react';
import {useLazyGetEventTypesPaginatedQuery} from '../../../../redux/api/eventTypes';
import {capitalize} from 'lodash';
import {getTranslationAlertToManageTableColumnKey} from '../../../../utils/translation';
import {useTranslation} from 'react-i18next';

export default function EventTypeFilter({activeFilters, handleFilterChange}: {
  activeFilters?: OptionLabelValue[],
  handleFilterChange: (values: OptionLabelValue[]) => void,
}) {

  const {t} = useTranslation();
  const [
    getData, {
      data,
      isLoading,
      isFetching,
      isUninitialized,
    },
  ] = useLazyGetEventTypesPaginatedQuery();

  const handleGetOptions = useCallback((page: number, q?: string) => {
    if (q) {
      getData({search_by_name: q, page});
    } else {
      getData({page});
    }
  }, [getData]);

  useEffect(() => {
    handleGetOptions(1);
  }, [handleGetOptions]);

  const options: OptionLabelValue[] = useMemo(() => data?.results.map(el => ({
    label: el.name,
    value: el.uuid,
  })) || [], [data?.results]);

  return (<DEPRECATEDListWithSearchFilter
    getOptions={handleGetOptions}
    fetchedOptions={options}
    selectedOptions={activeFilters || []}
    setSelectedOptions={handleFilterChange}
    total={data?.count || 0}
    isLoadingOptions={isLoading || isFetching || isUninitialized}
    hasNullOption
    search={
      {placeholder: capitalize(t('table.filterPlaceholder', {column: t(getTranslationAlertToManageTableColumnKey('event_type'))}))}
    }
  />);
}

