import {useEffect, useState} from 'react';
import _, {capitalize} from 'lodash';
import {Select, Spin, Typography} from 'antd';
import {OptionLabelValue} from '../../../types/internal/OptionLabelValue';
import {useTranslation} from 'react-i18next';
import Label from '../Label';
import {DefaultOptionType} from 'antd/es/select';
import {useLazyGetCompaniesNotPaginatedQuery} from '../../../redux/api/company';

const {Text} = Typography;
export const CompaniesSearchSelect = (props: {
  values: OptionLabelValue[],
  onClear: () => void,
  onChange: (value: OptionLabelValue[], option: DefaultOptionType | DefaultOptionType[]) => void,
  disabled?: boolean,
}) => {

  const {t} = useTranslation();

  const [getCompanies, {
    isLoading,
    isFetching,
    isError,
    isSuccess,
    data,
  }] = useLazyGetCompaniesNotPaginatedQuery();

  const [
    options,
    setOptions,
  ] = useState<OptionLabelValue[]>([]);

  const [
    searchedCompany,
    setSearchedCompany,
  ] = useState<string>('');

  const debouncedSearch = _.debounce((searchQuery) => {
    setSearchedCompany(searchQuery);
    searchQuery ? getCompanies({q: searchQuery}) : setOptions([]);
  }, 400);

  useEffect(() => {
    if (isError)
      setOptions([]);
    if (isSuccess && data) {
      setOptions(data?.map(option => ({
        label: `${option.name}`,
        value: option.uuid,
      })) || []);
    }
  }, [data, isError, isSuccess]);

  return (
    <>
      <Label label={capitalize(t('assets.companies'))} htmlFor={'companies'}/>
      <Select
        <OptionLabelValue[]>
        mode={'multiple'}
        placeholder={capitalize(t('assets.companies'))}
        id={'companies'}
        style={{marginBottom: 20, width: '100%'}}
        value={props.values}
        disabled={props.disabled === true}
        labelInValue={true}
        filterOption={false}
        onSearch={debouncedSearch}
        onChange={props.onChange}
        onClear={props.onClear}
        allowClear={true}
        showSearch={true}
        notFoundContent={<div style={{padding: 5}}>
          {(isFetching || isLoading) && (
            <Spin size="small"/>
          )}
          {!(isFetching || isLoading) && isError && (
            <Text type={'secondary'}>
              {capitalize(t('misc.somethingWentWrongDuringFetch'))}...
            </Text>
          )}
          {!(isFetching || isLoading) && !isError && searchedCompany !== '' && (
            <Text type={'secondary'}>
              {capitalize(t('companies.noCompaniesAvailable'))}
            </Text>
          )}
          {!(isFetching || isLoading) && !isError && !searchedCompany && (
            <Text type={'secondary'}>
              {capitalize(t('companies.startTypingToSearchCompany'))}...
            </Text>
          )}
        </div>}
        options={options}
      />
    </>
  );
};