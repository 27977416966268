import {createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState} from 'react';

export type QueryType = 'GET_FEATURE_INFO' | 'GET_POLYGON_FROM_LAYER';

export type QuerableLayerContextType = [
  queryMode: QueryType | null,
  setQueryMode: Dispatch<SetStateAction<QueryType | null>>
]

const QuerableContext = createContext<QuerableLayerContextType>([
    null,
    () => null,
  ],
);

export const useQuerableLayerContext = () => useContext(QuerableContext);

export const QuerableLayerContext = (props: PropsWithChildren) => {
  const [
    queryMode,
    setQueryMode,
  ] = useState<QueryType | null>(null);

  return (
    <QuerableContext.Provider value={[queryMode, setQueryMode]}>
      {props.children}
    </QuerableContext.Provider>
  );
};