import {Col, Empty, Pagination, Row, Skeleton, Timeline,} from 'antd';
import React, {useState} from 'react';
import {useGetEventHistoryPaginatedQuery} from "../../../redux/api/events";
import {useEventContext} from "../../../context/event/EventContext";
import {skipToken} from "@reduxjs/toolkit/query";
import EventHistoryTimelineItem from "./EventHistoryTimelineItem";
import {PaginationConfig} from "antd/lib/pagination";
import {useTranslation} from "react-i18next";

export const MovingHistoryGeneralView = (props: {}) => {

  const {event} = useEventContext()
  const {t} = useTranslation()
  const [pagination, setPagination] = useState<PaginationConfig>({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    showTotal: (total, range) => t('table.pageSizeOfTotal', {
      rangeStart: range[0],
      rangeEnd: range[1],
      total,
    }),
    onChange: (page, pageSize) => setPagination(prevPag => ({...prevPag, current: page, pageSize})),
    style: {textAlign: 'center'},
  });

  const isParamsOk = !!(event?.uuid && pagination.current && pagination.pageSize)

  const {data: history, isFetching} = useGetEventHistoryPaginatedQuery(isParamsOk ? {
    eventUuid: event.uuid,
    page_size: pagination.pageSize,
    page: pagination.current,
  } : skipToken)

  return (
    <div style={{marginTop: '1rem'}}>
      {isFetching && <Skeleton active/>}
      {!isFetching && !!history?.count && <>
        <Timeline
          items={history?.results.map(el => ({
              children: <EventHistoryTimelineItem {...el} />
            })
          )}
        />
        <Row justify={'center'}>
          <Col>
            <Pagination
              {...pagination}
              total={history?.count}
            />
          </Col>
        </Row>
      </>}
      {!isFetching && !history?.count && <Empty/>}
    </div>
  );
};