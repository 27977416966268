import {useEventContext} from '../../../context/event/EventContext';
import {CustomPolygon} from '../../common/map/CustomPolygon';
import {flipTurfFeatureCoordinates} from '../../../utils/polygons';
import type {InteractiveLayerProps} from '@react-leaflet/core/lib/layer';
import chroma from 'chroma-js';
import {multiPolygon} from '@turf/helpers';

export default function EventPolygons({selectedClustersUuids, eventHandlers}: InteractiveLayerProps & {
  selectedClustersUuids?: string[]
}) {
  
  const {
    eventPolygons,
    getClusterFromUuid,
    getGlobalPolygonFromUuid,
  } = useEventContext();
  const polygons = selectedClustersUuids ? eventPolygons?.filter(el => selectedClustersUuids.includes(el.cluster)) : eventPolygons;
  
  return (
    <>
      {polygons?.map(el => {
          const cluster = getClusterFromUuid(el.cluster);
          const globalPolygon = getGlobalPolygonFromUuid(el.globalPolygon);
          return cluster && globalPolygon ? <CustomPolygon
            eventHandlers={eventHandlers}
            key={el.globalPolygon}
            positions={flipTurfFeatureCoordinates(multiPolygon(globalPolygon.geometry.coordinates)).geometry.coordinates as L.LatLngExpression[][][]}
            pathOptions={{
              color: chroma(cluster.color_code).alpha(0.7).hex(),
            }}
          /> : null;
        },
      )
      }
    </>
  );
}