import {Button, Card, Col, Empty, Pagination, Row, Skeleton, Typography} from "antd";
import {capitalize} from "lodash";
import {useTranslation} from "react-i18next";
import {useGetCheckAssetsChangesPaginatedQuery} from "../../../redux/api/events";
import {useEventContext} from "../../../context/event/EventContext";
import {titleCase} from "../../../utils/parser";
import {ClusterOption, SelectCluster} from "../importAlertsAreas/AssignCluster";
import AntFormItem from "../../common/AntFormItem";
import {useAssetDrawerContext} from "../../../context/alertDetails/AssetDrawerContext";
import {useAssetDetails} from "../../../context/assetDetails/AssetDetails";
import {AssetDetailsDrawer} from "../../asset/assetDrawer/AssetDetailsDrawer";
import {skipToken} from "@reduxjs/toolkit/query";
import React, {useEffect, useState} from "react";
import {PaginationConfig} from "antd/lib/pagination";
import {CloseOutlined} from "@ant-design/icons";
import {useEditStepsContext} from "../../../context/event/EditSteps";
import InsertNote from "./InsertNote";

export default function AssetsToVerify() {
  const {t} = useTranslation()
  const {event, clusters, updateClusterAsset, getClusterFromUuid} = useEventContext()
  const eventUuid = event?.uuid
  const {step} = useEditStepsContext()
  const [, setIsDrawerOpen] = useAssetDrawerContext();
  const [setAssetUuid] = useAssetDetails();
  const [pagination, setPagination] = useState<PaginationConfig>({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    showTotal: (total, range) => t('table.pageSizeOfTotal', {
      rangeStart: range[0],
      rangeEnd: range[1],
      total,
    }),
    onChange: (page, pageSize) => setPagination(prevPag => ({...prevPag, current: page, pageSize})),
    style: {textAlign: 'center', marginTop: '40px'},
  });
  const {
    data: changes,
    isFetching: isFetchingChanges,
    refetch
  } = useGetCheckAssetsChangesPaginatedQuery(eventUuid ? {
    eventUuid,
    page: pagination.current,
    page_size: pagination.pageSize
  } : skipToken)

  const [isUpdatingItem, setIsUpdatingItem] = useState(false)
  const [isFetchingList, setIsFetchingList] = useState(true)

  const handleClusterChange = async ({cluster, asset, note, deleteRecord = false} : {cluster: string | null, asset: string, note: string | null, deleteRecord?: boolean}) => {
    setIsUpdatingItem(true)
    try {
      if (cluster) {
        await updateClusterAsset({cluster, asset, note})
      } else {
        await updateClusterAsset({cluster: null, asset, note})
      }
    } catch {

    } finally {
      let isToRefetch = true
      if (deleteRecord && pagination?.current && pagination.pageSize && changes) {
        const positionOfFirstElementOfTheCurrentPage = (pagination.current - 1) * pagination.pageSize + 1
        const isExceedPage = (changes.count - 1) < positionOfFirstElementOfTheCurrentPage
        const newPage = pagination.current - 1;
        if (isExceedPage && newPage >= 1) {
          setPagination(prevPag => ({...prevPag, current: newPage}))
          isToRefetch = false
          setIsUpdatingItem(false)
        }
      }
      if (isToRefetch) {
        refetch().finally(() => {
          setIsUpdatingItem(false)
        })
      }
    }
  }

  useEffect(() => {
    if (step === 2) {
      setIsFetchingList(true)
      refetch()
        .finally(() => setIsFetchingList(false))
    }
  }, [refetch, step]);

  return <>
    {eventUuid && <AssetDetailsDrawer
      goBack={{
        path: `/events/${eventUuid}`,
        label: t('events.event', {count: 1}),
      }}
    />}
    <Typography.Title
      level={4}
      style={{margin: 0}}>
      {capitalize(t('events.assetsToVerify'))}
    </Typography.Title>
    <Typography.Text type={'secondary'}></Typography.Text>
    <div style={{display: 'flex', flexDirection: 'column', gap: '1rem'}}>
      {!isFetchingChanges && changes?.count === 0 && <Card><Empty/></Card>}
      {isFetchingList && <Skeleton active/>}
      {!isFetchingList && changes?.results.map(change => {
        const {asset} = change
        return <Card
          key={asset.uuid}
          title={<div style={{marginTop: '1rem', width: '100%'}}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '1rem'}}>
              <Typography.Text
                ellipsis={true}>
                {[titleCase(asset.address), titleCase(asset.admin_level_3), asset.admin_level_2, titleCase(asset.admin_level_1)].join(', ')}
              </Typography.Text>

              <Button
                disabled={isUpdatingItem}
                onClick={() => handleClusterChange({cluster: change.old_cluster_template, asset: asset.uuid, note: null, deleteRecord: true})}
                type={'primary'}
                danger
                icon={<CloseOutlined/>}/>
            </div>
            <Button
              type={'link'}
              style={{padding: 0, margin: 0}}
              onClick={() => {
                setIsDrawerOpen(true);
                setAssetUuid(asset.uuid);
              }}
            >
              {capitalize(t('misc.goToDetail'))}...
            </Button>
          </div>}
        >
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8}>
              <AntFormItem
                labelCol={{span: 24}}
                wrapperCol={{span: 24}}
                label={capitalize(t('events.clusterPrevious'))}>
                <ClusterOption
                  cluster={change.old_cluster_template ? (getClusterFromUuid(change.old_cluster_template) || null) : null}/>
              </AntFormItem>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <AntFormItem
                labelCol={{span: 24}}
                wrapperCol={{span: 24}}
                label={capitalize(t('events.clusterCurrent'))}>
                <SelectCluster
                  clusters={clusters?.filter(cluster => cluster.uuid !== change.old_cluster_template)}
                  nullValue={change?.old_cluster_template !== null}
                  select={{
                    disabled: isUpdatingItem,
                    value: change.new_cluster_template,
                    onSelect: (v) => handleClusterChange({cluster: v, asset: asset.uuid, note: change.text_note})
                  }}
                />
              </AntFormItem>

            </Col>
            <Col xs={24} md={8}>
              <InsertNote text={change.text_note} onTextChange={newText => handleClusterChange({cluster: change.new_cluster_template, asset: asset.uuid, note: newText || null})} />
            </Col>
          </Row>
        </Card>
      })}
      {!!changes?.count && (
        <Pagination
          {...pagination}
          total={changes?.count}
        />)}
    </div>
  </>
}