import React from 'react';
import {OptionLabelValue} from '../../../../types/internal/OptionLabelValue';
import StandardFilter from '../../../common/filters/StandardFilter';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';

export const TemplateEmailLanguageFilter = ({activeFilters, handleFilterChange}: {
  activeFilters: OptionLabelValue[] | null,
  handleFilterChange: (values: OptionLabelValue[]) => void,
}) => {

  const {t} = useTranslation();

  return (
    <StandardFilter
      selectedFilters={activeFilters || undefined}
      filters={[
        {
          label: capitalize(t('misc.italian')),
          value: 'it',
        },
        {
          label: capitalize(t('misc.english')),
          value: 'en',
        },
      ]}
      onChange={handleFilterChange}
    />
  );
};