import AlertDetail from './AlertDetail';
import AlertMap from '../alertMap/AlertMap';
import AlertMapContext from '../../../../context/alertMap/AlertMapContext';
import React from 'react';
import {Col, Row} from 'antd';
import {GoogleLayers} from "../../../../types/internal/Map";
import {BaseMap} from "../../../common/map/BaseMap";

export default function AlertView() {

  return (
    <AlertMapContext>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={10}>
          <AlertDetail style={{height: 'calc(100vh - 12rem)', overflowY: 'scroll', borderRadius: '20px'}}/>
        </Col>
        <Col xs={24} sm={24} md={24} lg={14}>
          <BaseMap
            withZoom={true}
            withLayerPicker={true}
            withBaseLayers={[GoogleLayers.satellite, GoogleLayers.roadmap]}
            withStyle={{
              height: 'calc(100vh - 12rem)',
              width: '100%',
              position: 'sticky',
              top: 80,
              left: 0,
            }}
          >
            <AlertMap/>
          </BaseMap>
        </Col>
      </Row>
    </AlertMapContext>
  );
}