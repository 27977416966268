import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '../store';
import {PaginationConfig} from 'antd/lib/pagination';
import {
  AlertsColumns,
  AlertsFilters,
  AlertsFiltersFE,
  AlertsSortersFE,
  DEFAULT_ALERT_TO_MANAGE_COLUMNS,
} from '../../types/internal/tables/alertsTable';
import _ from 'lodash';

// Define a type for the slice state
interface AlertsSlice {
  alertsToManageTable: {
    filters: AlertsFiltersFE,
    sorters: AlertsSortersFE,
    pagination: PaginationConfig,
    columns: AlertsColumns[],
  },
  alertsManagedTable: {
    filters: AlertsFiltersFE,
    sorters: AlertsSortersFE,
    pagination: PaginationConfig,
    columns: AlertsColumns[],
  }
}

// Define the initial state using that type
const initialState = {
  alertsToManageTable: {
    filters: {},
    sorters: {},
    columns: DEFAULT_ALERT_TO_MANAGE_COLUMNS,
    pagination: {
      current: 1,
      pageSize: +(window.sessionStorage.getItem('alerts_to_manage_page_size') || 10),
    },
  },
  alertsManagedTable: {
    filters: {},
    sorters: {},
    columns: DEFAULT_ALERT_TO_MANAGE_COLUMNS,
    pagination: {
      current: 1,
      pageSize: +(window.sessionStorage.getItem('alerts_managed_page_size') || 10),
    },
  },
} as AlertsSlice;

export const alertsSlice = createSlice({
  name: 'alerts',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setAlertsTableFilters: (state, {payload}: PayloadAction<{
      filters: AlertsFiltersFE,
      mode: 'managed' | 'toManage'
    }>) => {
      const {mode, filters} = payload;
      state[mode === 'toManage' ? 'alertsToManageTable' : 'alertsManagedTable'].filters = filters;
      state[mode === 'toManage' ? 'alertsToManageTable' : 'alertsManagedTable'].pagination = {
        ...state[mode === 'toManage' ? 'alertsToManageTable' : 'alertsManagedTable'].pagination,
        current: 1,
      };
    },
    removeAlertsTableFilters: (state, {payload}: PayloadAction<{
      filters: AlertsFilters[] | AlertsFilters,
      mode: 'managed' | 'toManage'
    }>) => {
      const updatedFilters = {...state[payload.mode === 'toManage' ? 'alertsToManageTable' : 'alertsManagedTable'].filters};
      if (Array.isArray(payload.filters)) {
        payload.filters.forEach(el => {
          delete updatedFilters[el];
        });
      } else {
        delete updatedFilters[payload.filters];
      }
      state[payload.mode === 'toManage' ? 'alertsToManageTable' : 'alertsManagedTable'] = {
        ...state[payload.mode === 'toManage' ? 'alertsToManageTable' : 'alertsManagedTable'],
        filters: updatedFilters,
        pagination: {
          ...state[payload.mode === 'toManage' ? 'alertsToManageTable' : 'alertsManagedTable'].pagination,
          current: 1,
        },
      };
    },
    addAlertsTableFilters: (state, {payload}: PayloadAction<{
      filters: AlertsFiltersFE,
      mode: 'managed' | 'toManage'
    }>) => {
      state[payload.mode === 'toManage' ? 'alertsToManageTable' : 'alertsManagedTable'] = {
        ...state[payload.mode === 'toManage' ? 'alertsToManageTable' : 'alertsManagedTable'],
        filters: {
          ...state[payload.mode === 'toManage' ? 'alertsToManageTable' : 'alertsManagedTable'].filters,
          ...payload.filters,
        },
        pagination: {
          ...state[payload.mode === 'toManage' ? 'alertsToManageTable' : 'alertsManagedTable'].pagination,
          current: 1,
        },
      };
    },
    setAlertsTableSorters: (state, action: PayloadAction<{
      sorters: Partial<AlertsSortersFE>,
      mode: 'managed' | 'toManage'
    }>) => {
      const {mode, sorters} = action.payload;
      state[mode === 'toManage' ? 'alertsToManageTable' : 'alertsManagedTable'].sorters = sorters;
    },
    removeAlertsTableSorters: (state, {payload}: PayloadAction<{
      sorters: keyof AlertsSortersFE | (keyof AlertsSortersFE)[],
      mode: 'managed' | 'toManage'
    }>) => {
      if (Array.isArray(payload.sorters)) {
        payload.sorters.forEach(el => delete state[payload.mode === 'toManage' ? 'alertsToManageTable' : 'alertsManagedTable'].sorters[el]);
      } else {
        delete state[payload.mode === 'toManage' ? 'alertsToManageTable' : 'alertsManagedTable'].sorters[payload.sorters];
      }
    },
    addAlertsTableSorters: (state, {payload}: PayloadAction<{
      sorters: AlertsSortersFE,
      mode: 'managed' | 'toManage'
    }>) => {
      state[payload.mode === 'toManage' ? 'alertsToManageTable' : 'alertsManagedTable'].sorters = {...state[payload.mode === 'toManage' ? 'alertsToManageTable' : 'alertsManagedTable'].sorters, ...payload.sorters};
    },
    resetAlertsTableSortersAndFilters: (state, {payload}: PayloadAction<{ mode: 'managed' | 'toManage' }>) => {
      const {mode} = payload;
      state[mode === 'toManage' ? 'alertsToManageTable' : 'alertsManagedTable'].sorters = {};
      state[mode === 'toManage' ? 'alertsToManageTable' : 'alertsManagedTable'].filters = {};
      state[mode === 'toManage' ? 'alertsToManageTable' : 'alertsManagedTable'].pagination = {
        ...state[mode === 'toManage' ? 'alertsToManageTable' : 'alertsManagedTable'].pagination,
        current: 1,
      };
    },
    
    setAlertsTableColumns: (state, action: PayloadAction<{
      columns: AlertsColumns[],
      mode: 'managed' | 'toManage'
    }>) => {
      const {mode, columns} = action.payload;
      state[mode === 'toManage' ? 'alertsToManageTable' : 'alertsManagedTable'].columns = columns;
    },
    insertAlertsTableColumnsAtTheEnd: (state, action: PayloadAction<{
      columns: AlertsColumns[],
      mode: 'managed' | 'toManage'
    }>) => {
      const {mode, columns} = action.payload;
      state[mode === 'toManage' ? 'alertsToManageTable' : 'alertsManagedTable'].columns = [..._.difference(state[mode === 'toManage' ? 'alertsToManageTable' : 'alertsManagedTable'].columns, columns), ...columns];
    },
    removeAlertsTableColumn: (state, action: PayloadAction<{
      columns: AlertsColumns[],
      mode: 'managed' | 'toManage'
    }>) => {
      const {mode, columns} = action.payload;
      state[mode === 'toManage' ? 'alertsToManageTable' : 'alertsManagedTable'].columns = _.difference(state[mode === 'toManage' ? 'alertsToManageTable' : 'alertsManagedTable'].columns, columns);
    },
    setAlertsTablePagination: (state, action: PayloadAction<{
      pagination: Partial<PaginationConfig>,
      mode: 'managed' | 'toManage'
    }>) => {
      const {mode, pagination} = action.payload;
      state[mode === 'toManage' ? 'alertsToManageTable' : 'alertsManagedTable'].pagination = pagination;
      if (pagination.pageSize) {
        window.sessionStorage.setItem(mode === 'toManage' ? 'alerts_to_manage_page_size' : 'alerts_managed_page_size', `${pagination.pageSize}`);
      }
    },
  },
});

export const {
  setAlertsTablePagination,
  setAlertsTableSorters,
  addAlertsTableSorters,
  removeAlertsTableSorters,
  addAlertsTableFilters,
  removeAlertsTableFilters,
  resetAlertsTableSortersAndFilters,
  setAlertsTableColumns,
  insertAlertsTableColumnsAtTheEnd,
  removeAlertsTableColumn,
} = alertsSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAlerts = (state: RootState) => state.alerts;
export default alertsSlice.reducer;
