import {AssetsTableColumn} from '../types/internal/tables/assetsTable';
import {AssetAddress} from '../types/api/getEventsResult';
import {titleCase} from './parser';
import {capitalize} from 'lodash';

export const DEFAULT_COLUMNS: AssetsTableColumn[] = ['map_view', 'sigi_code', 'admin_level_1', 'admin_level_2', 'admin_level_3', 'address', 'building_type', 'closing_date'];
export const translateLevelToText = (level: 0 | 1 | 2 | 3) => {
  let key = '';
  switch (level) {
    case 0:
      key = 'address.country';
      break;
    case 1:
      key = 'address.region';
      break;
    case 2:
      key = 'address.province';
      break;
    case 3:
      key = 'address.municipality';
      break;
  }
  return key;
};

export const getAssetAddress = (asset: AssetAddress) => {
  return titleCase([
    asset.address ? capitalize(asset.address) : null,
    asset.admin_level_3 ? capitalize(asset.admin_level_3) : null,
    asset.admin_level_1 ? capitalize(asset.admin_level_1) : null,
  ].filter(string => string !== null)
    .join(', '));
};
