import {Polygon, PolygonProps} from 'react-leaflet';
import {ForwardedRef, forwardRef, useMemo, useState} from 'react';
import L from 'leaflet';
import {multiPolygon, point, Position} from '@turf/helpers';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';

export const CustomPolygon = forwardRef(((props: PolygonProps, ref: ForwardedRef<L.Polygon> | undefined) => {
  
  const [
    isHighlighted,
    setIsHighlighted,
  ] = useState(false);
  
  const handlers = useMemo(() => ({
    mouseout: () => {
      setIsHighlighted(false);
    },
    mouseover: (e: L.LeafletMouseEvent) => {
      const hoveredPoint = point([e.latlng.lng, e.latlng.lat]);
      const isHover = booleanPointInPolygon(hoveredPoint, multiPolygon(e.target.toGeoJSON().geometry.coordinates as unknown as Position[][][]));
      if (isHover) {
        setIsHighlighted(true);
      }
    },
    ...props.eventHandlers,
  }), [props.eventHandlers]);
  
  return (
    <Polygon
      {...props}
      ref={ref}
      eventHandlers={handlers}
      pathOptions={
        isHighlighted ? {
          opacity: 1,
          fillOpacity: 0.3,
          ...props.pathOptions,
        } : {
          opacity: 0.5,
          fillOpacity: 0.25,
          ...props.pathOptions,
        }}
    />
  );
}));