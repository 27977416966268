import {apiSlice} from './baseApi';
import {ContactPerson, GetContacts, GetContactsParams} from '../../types/api/contactPerson';

const realEstateHubApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getContactsNotPaginated: build.query<ContactPerson[], GetContactsParams>({
      query: (args) => ({
        url: '/contacts/',
        params: {...args, paginate: false},
      }),
      providesTags: ['Contacts'],
    }),
    getContactsPaginated: build.query<GetContacts, GetContactsParams & { page_size?: number, page?: number }>({
      query: (args) => ({
        url: '/contacts/',
        params: args,
      }),
      providesTags: ['Contacts'],
    }),
    getContactByUuid: build.query<ContactPerson, { uuid: string }>({
      query: ({uuid}) => `/contacts/${uuid}/`,
    }),
    getContactsByUuids: build.query<ContactPerson[], { uuids: string[] }>({
      query: ({uuids}) => ({
        url: '/contacts/',
        params: {
          uuids: uuids.join(','),
          paginate: false,
        },
      }),
      providesTags: ['Contacts'],
    }),
    createContact: build.mutation<ContactPerson, { full_name: string, phone_number: string, email: string }>({
      query: (args) => ({
        url: '/contacts/',
        method: 'POST',
        body: args,
      }),
      invalidatesTags: ['Contacts'],
    }),
  }),
});

export const {
  useLazyGetContactsNotPaginatedQuery,
  useLazyGetContactsPaginatedQuery,
  useLazyGetContactByUuidQuery,
  useLazyGetContactsByUuidsQuery,
  useCreateContactMutation,
} = realEstateHubApi;