import {CustomMarkerProps, MemoizedMarker} from '../../components/common/map/CustomMarker';
import { useRef} from 'react';
import {Marker as LeafletMarker} from 'leaflet';

export const AlertMapMarker = (props: CustomMarkerProps) => {

  const markerRef = useRef<LeafletMarker | null>(null);

  const {
    markerUuid,
    ...markerProps
  } = props;
  return (
    <MemoizedMarker
      key={markerUuid}
      ref={markerRef}
      {...markerProps}
    >
      {props.children}
    </MemoizedMarker>
  );
};