import {PropsWithChildren} from 'react';

export default function SimpleBackgroundLayout(props: PropsWithChildren) {
  return <div
    style={{
      height: '100vh',
      width: '100vw',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      backgroundColor: '#F0F2F5',
    }}
    {...props}
  />;
}