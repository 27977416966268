import {Divider, Typography} from 'antd';
import {capitalize} from 'lodash';
import PreviewEventTypeIconBig from '../../../common/icons/PreviewEventTypeIconBig';
import PreviewEventTypeIconSmall from '../../../common/icons/PreviewEventTypeIconSmall';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {EventTypeForm} from '../../../../types/internal/eventTypes';
import {useGetIconsNotPaginatedQuery} from '../../../../redux/api/icons';

export default function EventTypeIcons({eventValue, setEventValue}:
                                         {
                                           eventValue: EventTypeForm,
                                           setEventValue: React.Dispatch<React.SetStateAction<EventTypeForm>>
                                         }) {

  const {t} = useTranslation();
  const {data: icons} = useGetIconsNotPaginatedQuery();

  return <div style={{display: 'flex', flexDirection: 'column', gap: '1.25rem'}}>
    <Typography.Title level={3} style={{margin: 0}}>{capitalize(t('events.icon'))}</Typography.Title>
    <Divider/>
    <div style={{display: 'flex', gap: '40px'}}>
      <PreviewEventTypeIconBig
        src={!!process.env.REACT_APP_MEDIA && !!eventValue.icon ? `${process.env.REACT_APP_MEDIA}${icons?.find(el => el.uuid === eventValue.icon)?.svg}` : ''}
      />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center',
          gap: '1rem',
          flexWrap: 'wrap',
        }}
      >
        {icons?.map((i) => <PreviewEventTypeIconSmall
          key={i.uuid}
          src={!!process.env.REACT_APP_MEDIA && !!i.svg ? `${process.env.REACT_APP_MEDIA}${i.svg}` : undefined}
          selected={eventValue.icon === i.uuid}
          onClick={() => setEventValue(prevState => ({...prevState, icon: i.uuid}))}
        />)}
      </div>
    </div>
  </div>;
}