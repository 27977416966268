import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {OptionLabelValue} from '../../../../types/internal/OptionLabelValue';
import {useLazyGetTagsForInfiniteScrollingQuery} from '../../../../redux/api/tags';
import NEWListWithSearchFilter from "../../../common/filters/NEWListWithSearchFilter";

export const TemplateEmailTagFilter = ({activeFilters, handleFilterChange}: {
  activeFilters: OptionLabelValue[] | null,
  handleFilterChange: (values: OptionLabelValue[]) => void,
}) => {

  const [
    getTags,
    {
      data,
      isLoading,
      isFetching,
      isUninitialized,
      originalArgs
    },
  ] = useLazyGetTagsForInfiniteScrollingQuery();
  const [querySearch, setQuerySearch] = useState<string>('');
  const options: OptionLabelValue[] = useMemo(() => data?.results.map(el => ({
    label: el.frontend_name,
    value: el.uuid,
  })) || [], [data?.results]);


  const handleGetNext = useCallback(() => {
    getTags({
      ...!!querySearch && {search_by_name: querySearch},
      page: (originalArgs?.page || 0) + 1,
      page_size: 15,
    });
  }, [getTags, originalArgs?.page, querySearch]);

  useEffect(() => {
    getTags({
      ...!!querySearch && {search_by_name: querySearch},
      page: 1,
      page_size: 15,
    });
  }, [getTags, querySearch]);


  return (
    <NEWListWithSearchFilter
      getNext={handleGetNext}
      options={options}
      selectedOptions={activeFilters || []}
      setSelectedOptions={handleFilterChange}
      total={data?.count || 0}
      isLoadingOptions={isLoading || isFetching || isUninitialized}
      querySearch={querySearch}
      setQuerySearch={setQuerySearch}
    />
  );
};