import {Button, Card, Flex, Space} from 'antd';
import {useNavigate} from 'react-router-dom';
import SimpleBackgroundLayout from '../layout/SimpleBackgroundLayout';
import {useTranslation} from 'react-i18next';
import {WarningOutlined} from "@ant-design/icons";

export default function SSODenied() {

  const navigate = useNavigate();
  const {t} = useTranslation();

  return <SimpleBackgroundLayout>
    <Card
      title={<Space>
        <WarningOutlined/>
        {t('authentication.ssoDenied')}
      </Space>}
    >
      <Flex
        vertical
        style={{minHeight: '8rem'}}
        justify={'space-between'}
      >
        <p style={{textAlign: 'center'}}>
          {t('authentication.ssoDeniedDescription')}
        </p>
        <Button
          onClick={() => navigate('/logout')}
          type={'primary'}
          block
        >
          {(t('authentication.goToLogin'))}
        </Button>
      </Flex>

    </Card>
  </SimpleBackgroundLayout>;
}