import React, {ReactNode} from 'react';
import {Button, Col, Divider, Popover, Row, Space} from 'antd';
import {capitalize} from 'lodash';
import {FilterFilled} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';

export const DocumentsTableColumnHeader = ({title, sorter, filter, onFilterReset, isFilterDisabled}: {
  title: string,
  sorter?: ReactNode,
  filter?: ReactNode,
  onFilterReset?: () => void,
  isFilterDisabled?: boolean,
}) => {

  const {t} = useTranslation();

  return (
    <>
      <Row
        align={'middle'}
        wrap={false}
      >
        <Col
          flex={1}
          style={{marginRight: '0.5rem'}}
        >
          {title}
        </Col>
        <Col>
          <Space>
            {sorter}
            <Popover
              arrow={false}
              trigger={'click'}
              placement={'bottom'}
              destroyTooltipOnHide
              content={(
                <>
                  {filter}
                  <Divider/>
                  <Row justify={'end'}>
                    <Col>
                      <Button
                        disabled={isFilterDisabled}
                        type={'link'}
                        onClick={() => {
                          if (onFilterReset)
                            onFilterReset();
                        }}
                      >
                        {capitalize(t('table.removeFilter'))}
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            >
              <FilterFilled
                style={{
                  opacity: !isFilterDisabled ? '100%' : '25%',
                  color: !isFilterDisabled ? '#1677ff' : '',
                }}
              />
            </Popover>
          </Space>
        </Col>
      </Row>
    </>
  );
};