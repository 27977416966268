import {HeaderItem, menuItems} from '../types/internal/HeaderItems';

export default function getIconAccordingToId(id: string) {
  
  let currentHeaderIcon: HeaderItem | null = null;
  let isFound = false;
  
  for (let i = 0; !isFound && i < menuItems.length; i++) {
    const parsedId = id.substring(
      id.indexOf('|') + 1,
      id.lastIndexOf('|'),
    );
    
    if (parsedId.includes(menuItems[i])) {
      currentHeaderIcon = menuItems[i];
      isFound = true;
    }
  }
  
  return currentHeaderIcon;
}