import {Outlet, useNavigation} from 'react-router-dom';
import {Layout} from 'antd';
import CustomHeader from '../components/common/CustomHeader';
import {CustomLoading} from '../components/common/CustomLoading';
import Profile from '../components/Authentication/Profile';
import {useDispatch, useSelector} from 'react-redux';
import {selectLayoutSlice, setProfileVisibility} from '../redux/slices/layout';
import {PropsWithChildren, useEffect} from 'react';

export default function SimplifiedLayout(props: PropsWithChildren) {
  const navigation = useNavigation();
  const layout = useSelector(selectLayoutSlice);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setProfileVisibility({isVisible: false}));
    };
  }, [dispatch]);

  return (
    <Layout
      style={{height: '100vh', width: '100%', minHeight: '100vh', overflow: 'hidden'}}
      onClick={() => {
        if (layout.isProfileVisible)
          dispatch(setProfileVisibility({isVisible: false}));
      }}
    >
      <CustomHeader isSticky={true}/>
      {navigation.state !== 'loading' && layout.isProfileVisible && <Profile style={{top: 52}}/>}
      {navigation.state === 'loading' ? <CustomLoading/> : <><Outlet/>{props.children}</>}
    </Layout>
  );
}