import {Button, Card, Col, Row, Tabs, TabsProps, Typography} from 'antd';
import {useMemo, useState} from 'react';
import {DownloadOutlined} from '@ant-design/icons';
import {MovingHistoryGeneralView} from './MovingHistoryGeneralView';
import {MovingHistoryByAssetView} from './MovingHistoryByAssetView';
import {capitalize} from "lodash";
import {useTranslation} from "react-i18next";
import EventHistoryDownloadButton from "./EventHistoryDownloadButton";

const {Title} = Typography;

enum EventMovingHistoryTabs {
  general = 'general',
  singleAsset = 'singleAsset',
}

export const EventMovingHistoryCard = (props: {}) => {

  const {t} = useTranslation();
  const [
    currentActiveTab,
    setCurrentActiveTab,
  ] = useState<EventMovingHistoryTabs>(EventMovingHistoryTabs.general);

  const items: TabsProps['items'] = useMemo(() => [
    {
      key: EventMovingHistoryTabs.general,
      label: capitalize(t('events.historyGeneral')),
    },
    {
      key: EventMovingHistoryTabs.singleAsset,
      label: capitalize(t('events.historyAsset')),
    },
  ], [t]);

  return (
    <Card>
      <Row align={'middle'} justify={'space-between'}>
        <Col>
          <Title level={4} style={{marginTop: 0}}>
            {capitalize(t('events.changesHistory'))}
          </Title>
        </Col>
        <Col>
          <Tabs
            type={'card'}
            activeKey={currentActiveTab}
            items={items}
            onChange={(e) => setCurrentActiveTab(EventMovingHistoryTabs[e as keyof typeof EventMovingHistoryTabs])}
          />
        </Col>
      </Row>
      <Row style={{marginBottom: '2rem'}}>
        <Col>
          <EventHistoryDownloadButton />
        </Col>
      </Row>
      {currentActiveTab === EventMovingHistoryTabs.general && (
        <MovingHistoryGeneralView/>
      )}
      {currentActiveTab === EventMovingHistoryTabs.singleAsset && (
        <MovingHistoryByAssetView/>
      )}
    </Card>
  );
};