import DrawControlsButton, {DrawControlsButtonProps} from './DrawControlsButton';
import {Popover, PopoverProps} from 'antd';
import React, {createRef} from 'react';

const TooltippedDrawControlsButton = ({drawControlsButtonProps, popoverProps}: {
  drawControlsButtonProps: DrawControlsButtonProps,
  popoverProps?: PopoverProps
}) => {
  
  const forwardableRef = createRef<HTMLButtonElement>();
  
  return (
    <Popover
      {...popoverProps}
    >
      <DrawControlsButton
        ref={forwardableRef}
        {...drawControlsButtonProps}
      />
    </Popover>
  );
};
export default TooltippedDrawControlsButton;